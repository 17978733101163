import React from 'react';
import classes from "./index.module.scss";
import Slider from "react-slick";
import {Container} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import { loadTestimonials } from 'Services/api/TestimonialService';
import { useSelector } from 'react-redux';


const Testimonials = ({ detail }) => {
    const languageId = 1;
    // const [testimonialData, setTestimonialData] = useState();

    const testimonialData = useSelector(state => state.components.testimonials)
    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        dots: true,
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 639,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    // useEffect(() => {
    //     loadTestimonials(languageId)
    //     .then(setTestimonialData)
    // }, []);

  return(
      <React.Fragment>
          <section className={'section bg-light'}>
              <Container>
                  <SectionTitle textCenter description={detail?.heading?.description}>
                      {detail?.heading?.title?.map((title, index) => <span className={title.class} key={index}>{title.title}</span>)}
                  </SectionTitle>
                  <div className={classes.testimonialSlider}>
                      <Slider {...settings}>
                           {testimonialData && detail?.testimonials?.map((item, index) => {
                            if(JSON.stringify(testimonialData?.testimonials?.at(item)) === '{}') return;
                              return <div className={classes.col} key={index}>
                                      <div className={classes.testimonialBox}>
                                          {/* <div className={'ratings'}>
                                              <i className="fas fa-star rating-color"></i>
                                              <i className="fas fa-star rating-color"></i>
                                              <i className="fas fa-star rating-color"></i>
                                              <i className="fas fa-star rating-color"></i>
                                              <i className="fas fa-star"></i>
                                          </div> */}
                                          <h3 className={classes.subject}>{testimonialData?.testimonials?.at(item)?.title}</h3>
                                          <blockquote>
                                              {testimonialData?.testimonials?.at(item)?.description}
                                          </blockquote>
                                          <div className={classes.author}>{testimonialData?.testimonials?.at(item)?.author}</div>
                                          <time className="font-14">{testimonialData?.testimonials?.at(item)?.createDate}</time>
                                      </div>
                                  </div>
                            })}
                      </Slider>
                  </div>
                  {/*<div className={'text-center py-4'}>*/}
                  {/*    <Button*/}
                  {/*        text={'Leave a review'}*/}
                  {/*        icon={'far fa-arrow-right'}*/}
                  {/*        url={'#'}*/}
                  {/*        className={'m-2'}*/}
                  {/*    />*/}
                  {/*    <Button*/}
                  {/*        text={'View all reviews'}*/}
                  {/*        icon={'far fa-arrow-right'}*/}
                  {/*        url={'#'}*/}
                  {/*        variant={'border'}*/}
                  {/*        className={'m-2'}*/}
                  {/*    />*/}
                  {/*</div>*/}
              </Container>
          </section>
      </React.Fragment>
  )
}

export default Testimonials;