import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import data from '../data';
import {Container, Tabs, Tab, Alert} from "react-bootstrap";
import classes from './index.module.scss';
import SectionTitle from "../../SectionTitle";
import ContentWithImage from "../../ContentWithImage";
import CardList from "../../Card";
import DetailPopUp from "../../Card/Popup";

import Button from "../../Button";
import { getBankByTitle } from 'Services/api/Bank';
import draftToHtml from 'draftjs-to-html';
import { onError } from 'Services/api/Image';
import { getCreditCardData, getSecuredData, getUnsecuredData } from 'Services/api/Loan';
import { creditCardSettings, securedLoanSettings, unsecuredSettings } from 'Services/Settings';
import CompareSidebar from '../../ProductListing/CompareSidebar';
import Loading from "../../Loading";
import { useSelector } from 'react-redux';
import SortFilter from '../../ProductListing/SortFilter';

const BankDetail = () => {

    const { settings } = useSelector(state => state.settings);

    const { bankTitle } = useParams();
    const [bank, setBank] = useState();
    const id  = 1;
    const languageId = 1;
    const lenderData = data.find((item) => item.id === id);
    const [selectedDataType, setSelectedDataType] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [cardsLoading, setCardsLoading] = useState(false);
    const [securedCards, setSecuredCards] = useState([]);
    const [unsecuredCards, setUnsecuredCards] = useState([]);
    const [creditCards, setCreditCards] = useState([]);
    const [activeTab, setActiveTab] = useState('loanWithoutSecurity');
    const [page, setPage] = useState(1);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [compareItems, setCompareItems] = useState([]);
    const [allCards, setAllCards] = useState([]);
    const [loadingMore, setLoadingMore] = useState(false);
    const [loadingBanksCards, setLoadingBanksCards] = useState(false);

    const [currentSort, setCurrentSort] = useState({property: 'SortByEffectiveInterest', ascending: true});

    const handleModalClose = () => {
        setModalShow(false);
    };

    const _loadBankSecuredData = () => {
        const object = {
            languageId, loanTypeId: 1,
            loanAmount: securedLoanSettings.loanAmount,
            housingValue: securedLoanSettings.housingValue,
            repaymentPeriod: securedLoanSettings.repaymentPeriod,
            age: securedLoanSettings.age,
            bankTitle: bank?.title,
            IsRequestFromBank: true
        }
        if(securedCards.length !== 0) return;
        setCardsLoading(true);
        getSecuredData(object)
        .then(resp => {
            if(!resp) return;
            setSecuredCards(resp?.filter(item => {
                if(item?.LoanSchema?.CardType === 'Annonse') return false;
                else return true;
            }));
        })
        .finally(() => setCardsLoading(false));
    }
    const _loadBankUnsecuredData = ()=> {
        const object = {
            languageId, loanTypeId: 2,
            loanAmount: unsecuredSettings.loanAmount,
            loanType: 'forbruk',
            bankTitle: bank?.title,
            IsRequestFromBank: true
        }
        if(unsecuredCards.length !== 0) return;
        setCardsLoading(true);
        getUnsecuredData(object)
        .then(resp => {
            if(!resp) return;
            setUnsecuredCards(resp?.filter(item => {
                if(item?.LoanSchema?.CardType === 'Annonse') return false;
                else return true;
            }));
        })
        .finally(() => setCardsLoading(false));
    }
    const _loadBankCreditCards = () => {
        const object = {
            languageId, loanTypeId: 3,
            yourIncome : creditCardSettings.annualIncome,
            age: creditCardSettings.age,
            bankTitle: bank?.title,
            IsRequestFromBank: true
        }
        if(creditCards.length !== 0) return;
        setCardsLoading(true);
        getCreditCardData(object)
        .then(resp => {
            if(!resp) return;
            setCreditCards(resp?.filter(item => {
                if(item?.LoanSchema?.CardType === 'Annonse') return false;
                else return true;
            }));
        })
        .finally(() => setCardsLoading(false))
    }

    useEffect(() => {
        setLoadingBanksCards(true);
        if(activeTab === 'loanSecurity')
        {
            const object = {
                languageId, loanTypeId: 1,
                loanAmount: securedLoanSettings.loanAmount,
                housingValue: securedLoanSettings.housingValue,
                repaymentPeriod: securedLoanSettings.repaymentPeriod,
                age: securedLoanSettings.age,
                [currentSort.property]: true,
                IsAscendingOrder: currentSort.ascending
            }
            getSecuredData(object)
            .then(resp => {
                if(!resp) return;
                setAllCards(resp);
            })
            .finally(() => { setLoadingBanksCards(false)});
        }
        else if(activeTab === 'loanWithoutSecurity')
        {
            const object = {
                languageId, loanTypeId: 2,
                loanAmount: unsecuredSettings.loanAmount,
                loanType: 'forbruk',
                [currentSort.property]: true,
                IsAscendingOrder: currentSort.ascending
            }
            getUnsecuredData(object)
            .then(resp => {
                if(!resp) return;
                setAllCards(resp);
            })
            .finally(() => { setLoadingBanksCards(false)});
        }
        else if(activeTab === 'credit')
        {
            const object = {
                languageId, loanTypeId: 3,
                yourIncome : creditCardSettings.annualIncome,
                age: creditCardSettings.age,
                [currentSort.property]: true,
                IsAscendingOrder: currentSort.ascending
            }
            getCreditCardData(object)
            .then(resp => {
                if(!resp) return;
                setAllCards(resp);
            })
            .finally(() => { setLoadingBanksCards(false)})
        }
    }, [activeTab, currentSort]);

    useEffect(() => {
        getBankByTitle(bankTitle)
        .then(resp => {
            const details = JSON.parse(resp?.data?.detail ?? '{}');
            setBank(details);
        })
    }, [])

    const _loadMore = () => {
        setPage(page + 1);
    }
    const _handleSort = (property, ascending) => {
        setCurrentSort({property, ascending});
    }

    useEffect(() => {
        if(activeTab === 'loanSecurity')
        {
            setLoadingMore(true);
            const object = {
                pageNumber: page,
                languageId, loanTypeId: 1,
                loanAmount: securedLoanSettings.loanAmount,
                housingValue: securedLoanSettings.housingValue,
                repaymentPeriod: securedLoanSettings.repaymentPeriod,
                age: securedLoanSettings.age,
                [currentSort.property]: true,
                IsAscendingOrder: currentSort.ascending
            }
            getSecuredData(object)
            .then(resp => {
                if(!resp) return;
                setAllCards([...allCards, ...resp]);
            })
            .finally(() => {
                setLoadingMore(false);
            });
        }
        else if(activeTab === 'loanWithoutSecurity')
        {
            setLoadingMore(true);
            const object = {
                pageNumber: page,
                languageId, loanTypeId: 2,
                loanAmount: unsecuredSettings.loanAmount,
                loanType: 'forbruk',
                [currentSort.property]: true,
                IsAscendingOrder: currentSort.ascending
            }
            getUnsecuredData(object)
            .then(resp => {
                if(!resp) return;
                setAllCards([...allCards, ...resp]);
            })
            .finally(() => {
                setLoadingMore(false);
            });
        }
        else if(activeTab === 'credit')
        {
            setLoadingMore(true);
            const object = {
                pageNumber: page,
                languageId, loanTypeId: 3,
                yourIncome : creditCardSettings.annualIncome,
                age: creditCardSettings.age,
                [currentSort.property]: true,
                IsAscendingOrder: currentSort.ascending
            }
            getCreditCardData(object)
            .then(resp => {
                if(!resp) return;
                setAllCards([...allCards, ...resp]);
            })
            .finally(() => {
                setLoadingMore(false);
            })
        }
    }, [page])

    useEffect(() => {
        if(!bank?.title) return;
        if(activeTab === 'loanWithoutSecurity')
            _loadBankUnsecuredData();
        else if(activeTab === 'loanSecurity')
            _loadBankSecuredData();
        else if(activeTab === 'credit')
            _loadBankCreditCards();
    }, [activeTab, bank]);

    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };

    const sortingOptions = {
        1: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },  
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)',isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByMonthlyCostASC', label: 'Kost/mnd (lav-høy)', ascending: true, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByMonthlyCostDESC', label: 'Kost/mnd (høy-lav)', ascending: false, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByTotalCostASC', label: 'Est. totalkost (lav-høy)', ascending: true, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
            { value: 'SortByTotalCostDESC', label: 'Est. totalkost (høy-lav)', ascending: false, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
        ],
        2: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },  
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)', isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByMonthlyCostASC', label: 'Kost/mnd (lav-høy)', ascending: true, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByMonthlyCostDESC', label: 'Kost/mnd (høy-lav)', ascending: false, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByTotalCostASC', label: 'Est. totalkost (lav-høy)', ascending: true, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
            { value: 'SortByTotalCostDESC', label: 'Est. totalkost (høy-lav)', ascending: false, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
        ],
        3: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByCreditASC', label: 'Maks kreditt (lav-høy)', ascending: true, property: 'SortByCredit', bannerLabel: 'Maks kreditt' },
            { value: 'SortByCreditDESC', label: 'Maks kreditt (høy-lav)', ascending: false, property: 'SortByCredit', bannerLabel: 'Maks kreditt' },
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)', isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByAnnualFeeASC', label: 'Årsgebyr (lav-høy)', ascending: true, property: 'SortByAnnualFee', bannerLabel: 'Årsgebyr' },
            { value: 'SortByAnnualFeeDESC', label: 'Årsgebyr (høy-lav)', ascending: false, property: 'SortByAnnualFee', bannerLabel: 'Årsgebyr' },
            { value: 'InterestFreeDaysASC', label: 'Rentefrie dager (lav-høy)', ascending: true, property: 'InterestFreeDays', bannerLabel: 'Rentefrie dager' },
            { value: 'InterestFreeDaysDESC', label: 'Rentefrie dager (høy-lav)', ascending: false, property: 'InterestFreeDays', bannerLabel: 'Rentefrie dager' },
        ],
        4: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)', isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByReturnASC', label: 'Avkastning 1 år (lav-høy)', ascending: true, property: 'SortByReturn', bannerLabel: 'Avkastning 1 år' },
            { value: 'SortByReturnDESC', label: 'Avkastning 1 år (høy-lav)', ascending: false, property: 'SortByReturn', bannerLabel: 'Avkastning 1 år' },
            { value: 'WithdrawalFeeASC', label: 'Frie uttak pr. år (lav-høy)', ascending: true, property: 'WithdrawalFee', bannerLabel: 'Frie uttak pr. år' },
            { value: 'WithdrawalFeeDESC', label: 'Frie uttak pr. år (høy-lav)', ascending: false, property: 'WithdrawalFee', bannerLabel: 'Frie uttak pr. år' },
        ]
    }


    return (
        <React.Fragment>
            <section className={'section bg-light-half-bottom border-bottom-none'}>
                <Container>
                    <div className={classes.cto}>
                        <div className={classes.col}>
                            <div className={`${classes.box} ${classes.logoHolder}`}>
                                <div className={classes.logoBox}>
                                    <div className={classes.logo}>
                                        <img src={bank?.bankImg} loading="lazy" onError={onError} alt={bank?.title} />
                                    </div>
                                    {/*<h2 className={'text-center'}>{bank?.title}</h2>*/}
                                </div>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.box}>
                                <h2 className={classes.boxTitle}>{bank?.title}</h2>
                                <div className={classes.innerColHolder}>
                                    <div className={classes.innerCol}>
                                        <h3>
                                            <i className="icon-Contactinformationcon" style={{marginTop: '-4px'}}></i>
                                            {bank?.contactUsTitle}
                                        </h3>
                                        <dl>
                                            <dt>{bank?.addressLabel}:</dt>
                                            <dd>{bank?.address}</dd>
                                            <dt>{bank?.phoneLabel}:</dt>
                                            <dd>{bank?.phone}</dd>
                                            <dt>{bank?.websiteLabel}:</dt>
                                            <dd>{bank?.website}</dd>
                                        </dl>
                                    </div>
                                    <div className={classes.innerCol}>
                                    <h3>
                                        <i className="icon-Oppeninghoursicon"></i>
                                        {bank?.openingHoursTitle}
                                    </h3>
                                    <dl>
                                        <dt>{bank?.mondayToThursdayHeading}:</dt>
                                        <dd>{bank?.mondayToThursday}</dd>
                                        <dt>{bank?.fridayLabel}:</dt>
                                        <dd>{bank?.friday}</dd>
                                        <dt>{bank?.saturdayLabel}:</dt>
                                        <dd>{bank?.saturday}</dd>
                                        <dt>{bank?.sundayLabel}:</dt>
                                        <dd>{bank?.sunday}</dd>
                                    </dl>
                                </div>
                                </div>
                                <div className={'text-center w-100'}>
                                    <Button
                                        text={bank?.getStartedButtonLabel}
                                        url={bank?.website}
                                        icon={'far fa-arrow-right'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className={'section bg-light border-top-none'}>
                <Container>
                    <SectionTitle description={bank?.generalInformation?.description}>
                        {bank?.generalInformation?.title?.map(title => <span key={title.id} className={title.class}>{title.title}</span>)}
                    </SectionTitle>
                    <div className={classes.tabsBox}>
                        <Tabs defaultActiveKey="about" className={classes.verTabs}>
                            {[
                                {key: 'about', label: bank?.aboutTabLabel , icon: 'icon-icon31'},
                                {key: 'benefits', label: bank?.benefitsTabLabel , icon: 'icon-icon32'},
                                {key: 'cons', label: bank?.consTabLabel , icon: 'icon-icon13'},
                                {key: 'conditionsForApplying', label: bank?.conditionsForApplyingTabLabel , icon: 'icon-icon14'},

                            ].map((tab, index) => {
                                return <Tab
                                    key={index}
                                    eventKey={tab.key}
                                    title={
                                        <>
                                            {tab.icon && <i className={tab.icon} />}
                                            {tab.label ? tab.label : ''}
                                        </>
                                    }>
                                    {bank && <div dangerouslySetInnerHTML={{__html: renderFilteredHTML(bank[tab?.key])}} />}
                                </Tab>
                            })}
                        </Tabs>
                    </div>
                </Container>
            </section>
            <ContentWithImage
                imageRight
                detail={{
                    component: {
                        featuredImage: bank?.historyImage
                    },
                    heading: {
                        title: bank?.history?.title,
                        description: bank?.historyDescription
                    }
                }}
            />
            <section className={'section'}>
                <Container>
                    <SectionTitle textCenter description={bank?.bankProducts?.description}>
                        {bank?.bankProducts?.title?.map(title => <span key={title.id} className={title.class}>{title.title}</span>)}
                    </SectionTitle>
                    <Tabs activeKey={activeTab} className={classes.tabsHorizenral} onSelect={key => {
                        setActiveTab(key);
                    }}>
                        <Tab eventKey="loanWithoutSecurity" title="Lån uten sikkerhet">

                            <div className={classes.productList}>
                                {!cardsLoading && unsecuredCards.length === 0 ?
                                    <Alert variant={'danger w-100'}>Ingen data funnet mot denne banken.</Alert> : null}
                                {unsecuredCards.map((item, index) => {
                                        return <CardList
                                            splitTwo
                                            key={index}
                                            item={item}
                                            index={index}
                                            displayDetails={false}
                                            handleCompare={e => {
                                                if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                                setCompareItems([...compareItems, e])
                                            }}
                                            // data={cards}
                                            setSelectedDataTypeProp={setSelectedDataType}
                                            setModalShow={setModalShow}
                                        />
                                    })}
                                 {/*Show more button*/}
                            </div>
                        </Tab>
                        <Tab eventKey="loanSecurity" title="Lån med sikkerhet">
                            <div className={classes.productList}>
                            {!cardsLoading && securedCards.length === 0 ?
                                <Alert variant={'danger w-100'}>Ingen data funnet mot denne banken.</Alert> : null}
                                    {securedCards.map((item, index) => (
                                        <CardList
                                            splitTwo
                                            displayDetails={false}
                                            key={index}
                                            item={item}
                                            index={index}
                                            handleCompare={e => {
                                                if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                                setCompareItems([...compareItems, e])
                                            }}
                                            setSelectedDataTypeProp={setSelectedDataType}
                                            setModalShow={setModalShow}
                                        />
                                    ))}
                                 {/*Show more button*/}
                            </div>
                        </Tab>
                        <Tab eventKey="credit" title="Kredittkort">
                            <div className={classes.productList}>
                            {!cardsLoading && creditCards.length === 0 ?
                                <Alert variant={'danger w-100'}>Ingen data funnet mot denne banken.</Alert> : null}
                                {creditCards.map((item, index) => (
                                        <CardList
                                            splitTwo
                                            displayDetails={false}
                                            key={index}
                                            item={item}
                                            index={index}
                                            data={lenderData?.credit}
                                            handleCompare={e => {
                                                if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                                setCompareItems([...compareItems, e])
                                            }}
                                            setSelectedDataTypeProp={setSelectedDataType}
                                            setModalShow={setModalShow}
                                        />
                                    ))}
                            </div>
                        </Tab>
                    </Tabs>
                    {cardsLoading && <Loading />}
                </Container>
            </section>
            <section className={'section'}>
                <Container>
                    <SectionTitle textCenter description={bank?.compareProducts?.description}>
                        {bank?.compareProducts?.title?.map(title => <span className={title.class} key={title.id}>{title.title}</span>)}
                    </SectionTitle>
                    <div className={classes.contentSection}>
                        <SortFilter handleSort={_handleSort} currentSorting={currentSort} sorting={sortingOptions[ activeTab === 'credit' ? '3' : activeTab === 'loanSecurity' ? '1' : '2' ]}  />
                    </div>
                    <div className={classes.productList}>
                            {allCards && !loadingBanksCards &&
                                allCards?.map((item, index) => {
                                    if(item?.LoanSchema?.CardType === 'Annonse') return;
                                    if(item?.LoanSchema?.Property?.filter( p => p?.Name === 'LeverandorTekst' && p?.Value === bank?.title ).length > 0) return;
                                    return <CardList
                                        //splitTwo
                                        displayDetails={false}
                                        key={index}
                                        item={item}
                                        index={index}
                                        // data={allC}
                                        handleCompare={e => {
                                            if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                            setCompareItems([...compareItems, e])
                                        }}
                                        setSelectedDataTypeProp={setSelectedDataType}
                                        setModalShow={setModalShow}
                                    />
                                })}
                            {loadingBanksCards && <h1>Laster...</h1>}
                            {/* Show more button */}
                        </div>
                        <div className='text-center pt-3'>
                            <Button variant={' w-100'} icon={'far fa-arrow-right'} text="Vis mer" onClick={_loadMore} disabled={loadingMore}/>
                        </div>
                        {compareItems.length > 0 && (
                            <Button onClick={() => setSidebarOpen(true)} text={`${settings?.find(s => s?.variableName === 'compareBannerText')?.value} ${compareItems.length}`}  iconLeft variant={'verticalFixed'} />
                        )}
                        {sidebarOpen && <CompareSidebar
                            selectedData={compareItems}
                            onClose={() => setSidebarOpen(false)}
                            handleDelete={e => setCompareItems(compareItems.filter((_, i) => i !== e))}
                        />}

                </Container>
            </section>


            {modalShow &&
                <DetailPopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    dataType={selectedDataType}
                    selectedDataType={selectedDataType}
                    handleClose={handleModalClose}
                />
            }
        </React.Fragment>
    )
}

export default BankDetail;
