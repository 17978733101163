import React, { useEffect } from "react";
import classes from "../index.module.scss";
import { Container } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import Button from "../../Button";
import { getBlogsByCategory } from "Services/api/Blog";
import { useState } from "react";
import SectionTitle from "../../SectionTitle";

const SingleCategory = ({detail}) => {
    const { categoryId } = useParams();
    const languageId = 1;

    const [posts, setPosts] = useState([]);
    const [categoryTitle, setCategoryTitle] = useState('');

    useEffect(() => {
        // Fetch posts by category ID
        getBlogsByCategory(categoryId, languageId)
            .then((resp) => setPosts(resp.data));

        // Extract category title from categoryId (Assuming URL structure is '/category/:categoryId/:categoryTitle')
        const titleFromId = categoryId.split('-').join(' '); // Assuming the title is hyphen-separated
        setCategoryTitle(titleFromId);
    }, [categoryId, languageId]);

    return (
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <SectionTitle textCenter>
                        <span className={'font-bold text-capitalize'}>{categoryTitle}</span>
                    </SectionTitle>
                    <div className={classes.colPost}>
                        {posts.map((post, index) => {
                            const contents = JSON.parse(post?.postContents ?? '{}')
                            return <div className={classes.col} key={index}>
                                <div className={classes.box}>
                                    <a
                                        href={`/blogger/${encodeURIComponent(post?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} // Use the correct route for post details
                                        className={classes.imgBox}
                                        style={{
                                            backgroundImage: `url(${contents?.image})`
                                        }}
                                    ></a>
                                    <div className={classes.description}>
                                        <h3 className={classes.title}><a href={`/blogger/${encodeURIComponent(post?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{post.title}</a></h3>
                                        <p>{post.summary}</p>
                                        <Button
                                            text={'Les mer'}
                                            url={`/blogger/${encodeURIComponent(post?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} // Use the correct route for post details
                                            variant={'link'}
                                            icon={'far fa-arrow-right'}
                                        />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default SingleCategory;
