import React, { useState, useEffect } from 'react';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import classes from './index.module.scss';
import Button from '../Button';
import Form from 'react-bootstrap/Form';

const RangeSlider = ({
                         param,
                         stepValue,
                         valueMin,
                         valueMax,
                         defaultValue,
                         btnShow,
                         btnText,
                         btnUrl,
                         type,
                         label,
                         unit,
                         showMinMax,
                         value,
                         onChange,
                         loanType
                     }) => {
    const [sliderValue, setSliderValue] = useState(defaultValue);
    const step = parseInt(stepValue, 10); // Convert stepValue to an integer
    const minValue = parseInt(valueMin, 10); // Convert valueMin to an integer
    const maxValue = parseInt(valueMax, 10); // Convert valueMax to an integer
    const [inputValue, setInputValue] = useState(defaultValue?.toString()); // State for input value

    useEffect(() => {
        setSliderValue(defaultValue);
        setInputValue(defaultValue?.toString());
    }, [defaultValue]);

    useEffect(() => {
        // Update the slider value whenever the input value changes
        const newValue = parseInt(inputValue, 10);
        if (!isNaN(newValue) && newValue >= minValue && newValue <= maxValue) {
            setSliderValue(newValue);
        }
    }, [inputValue, minValue, maxValue]);


    const handleSliderChange = (value) => {
        onChange(value);
        setSliderValue(value);
        setInputValue(value.toString()); // Update the input value as well
    };
    const handleInputChange = (e) => {

        setInputValue(e.target.value?.replaceAll(' ', ''));
    };
    const formatter = Intl.NumberFormat('no-NO');
    const format = value => isNaN(value) ? 0 : formatter.format(value)



    return (
        <React.Fragment>
            {type === 'input' ? (
                <React.Fragment>
                    <div className={`${classes.sliderBox} ${classes.inputRange}`}>
                        <div className={classes.labelBox}>
                            <Form.Label>{label}</Form.Label>
                            <div className={classes.tooltip}>
                                <Form.Control
                                    type="text"
                                    value={value === 0 ? '':  format(value)}
                                    onKeyDown={e => {
                                        if(['Control', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key) || e.ctrlKey) return;
                                        if(isNaN(Number(e.target.value.replaceAll(' ', '') + e.key)) || Number(e.target.value.replaceAll(' ', '') + e.key) > Number(maxValue))
                                            e.preventDefault();
                                    }}
                                    onChange={e => {
                                        onChange(Number(e.target.value.replaceAll(' ', '')))
                                    }}
                                />
                                <span className={classes.unit}>{unit}</span>
                            </div>
                        </div>
                        <Slider
                            min={minValue}
                            max={maxValue}
                            value={value}
                            onChange={onChange}
                            step={step}
                            trackStyle={{
                                background: `linear-gradient(90deg, ${classes.trackColor} 0%, ${classes.trackColor} ${(
                                    (sliderValue - minValue) /
                                    (maxValue - minValue)
                                )}%, #E0E0E0 ${((sliderValue - minValue) / (maxValue - minValue))}% , #E0E0E0 100%)`,
                            }}
                        />
                        {showMinMax &&
                            <div className="d-flex justify-content-between font-12">
                                <small>{format(minValue)} {unit}</small>
                                <small>{format(maxValue)} {unit}</small>
                            </div>
                        }
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <div className={classes.sliderBox}>
                        <Slider
                            min={minValue}
                            max={maxValue}
                            value={sliderValue}
                            onChange={handleSliderChange}
                            step={step}
                            handleStyle={{
                                position: 'absolute',
                                transform: 'translate(-50%, -50%)',
                                cursor: 'pointer',
                                zIndex: 1,
                            }}
                        />
                        {showMinMax &&
                            <div className="d-flex justify-content-between font-12">
                                <small>{format(minValue)} {unit}</small>
                                <small>{format(maxValue)} {unit}</small>
                            </div>
                        }
                        <div
                            className={`${classes.tooltip} ${classes.slidToolTip}`}
                            style={{
                                left: `calc(${((sliderValue - minValue) / (maxValue - minValue)) * 100}% - 1px)`,
                            }}
                        >
                            <Form.Control
                                onKeyDown={e => {
                                    if(['Control', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key)) return;
                                    if(/[a-z]/.test(e.key) && !e.ctrlKey) {
                                        e.preventDefault();
                                    }
                                    if(/[0-9]/.test(e.key) && inputValue + e.key > maxValue)
                                        e.preventDefault();
                                }}
                                type="text"
                                value={format(inputValue)}
                                onChange={handleInputChange}
                            />
                            <span className={classes.unit}>{unit}</span>
                        </div>
                    </div>

                    {btnShow && (
                        <div className={'pt-3'}>
                            <Button
                                text={btnText}
                                variant={'large w-100'}
                                url={`${btnUrl}?${param ? param : 'loanAmount'}=${sliderValue}${loanType === 'security' ? `&propertyValue=${Math.round(sliderValue/0.85)+1}` : ''}`}
                            />
                        </div>
                    )}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default RangeSlider;
