import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const PageHeader = ({ title, value, color = '#f9f9f9', text = '#000' }) => {
    const styles = StyleSheet.create({
        row: {
            padding: 5,
            flex: '1 0 50%',
        },
        text1: {
            fontWeight: 'bold',
            fontSize: 18,
            color: text,
        },
        text: {
            fontSize: 8,
        },
        col : {
            backgroundColor: color,
            padding: 10,
        },
    });

    return (
        <View style={styles.row}>
            <View style={styles.col}>
                <Text style={styles.text1}>{value}</Text>
                <Text style={styles.text}>{title}</Text>
            </View>
        </View>
    );
};

export default PageHeader;
