import { applyMiddleware  } from "@reduxjs/toolkit";
import rootReducer from "../reducers/rootReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createStore } from "redux";
import rootSaga from "../saga/rootSaga";

const sagaMiddleware = createSagaMiddleware()
const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)))
sagaMiddleware.run(rootSaga)
store.dispatch({ type: 'APPLICATION_LOAD' })

export default store;