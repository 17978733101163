import { Validator } from "fluentvalidation-ts";

type Email = {
    email: string;
}

class EmailValidator extends Validator<Email> {
    constructor(settings: any){
        super();
        this.ruleFor('email')
        .notEmpty()
        .withMessage(settings?.find((s: any) => s?.variableName === 'invalidEmailError')?.value)
        .emailAddress()
        .withMessage(settings?.find((s: any) => s?.variableName === 'invalidEmailError')?.value)
    }
}

export default EmailValidator;