import React from "react";
import classes from "./index.module.scss";
import {Link} from "react-router-dom";
import data from "./data";

const Tags = ({ detail }) => {
  return(
      <React.Fragment>
        <h1 id={detail?.heading?.toLowerCase().replaceAll(' ', '-')}>{detail?.heading}</h1>
        <p>{detail?.description}</p>
          <ul className={classes.tags}>
              {detail?.tags?.map((item, index) => (
                  <li><Link to={item.url}>{item.content}</Link></li>
              ))}
          </ul>
      </React.Fragment>
  )
}

export default Tags;