import unofinans from "../../../../Images/unofinans.svg";
import axo from "../../../../Images/logo3.svg";
import lendoLogo from "../../../../Images/lendoLogo.svg";
import tfBank from '../../../../Images/tf-bank-mastercard.png';
import remember from '../../../../Images/remember.jpg';
import morrowBank from '../../../../Images/morrowbank_mastercard1.svg';
import instaBank from '../../../../Images/instabank_instapay.png';
import bankNorwegian from '../../../../Images/banknorwegian.svg';
import creditcardcollection from '../../../../Images/creditcardcollection.svg';
import spareBank from "../../../../Images/SpareBank.svg";
import nordea from "../../../../Images/nordeaLogo.svg";

const data = [
    {
        dataType: 'Boliglån',
        averageRent: "5,60",
        childData: [
            {
                name: "",
                image: spareBank,
                leverandorTekst: 'EasyFinance anbefaler Sparebank 1 SR-Bank for boliglån grunnet deres pålitelighet og dekning av medlemmers behov. De tilbyr konkurransedyktige vilkår, spesielt gunstige for LO-medlemmer og førstegangskjøpere, noe som gjør dem til et ideelt valg.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '5,60%',
                featured: false,
                loanExample: 'Låneeksempel: Effektiv rente 5.97% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 788 775.',
                btnUrl: "https://www.sparebank1.no/nb/smn/privat/lan/boliglan.html ",
                affiliateType: "",
            },
            {
                name: "",
                image: nordea,
                leverandorTekst: 'EasyFinance anbefaler Nordea for boliglån, da de er en landsdekkende og pålitelig bank som dekker våre medlemmers varierte behov. Nordea tilbyr konkurransedyktige vilkår og fleksibilitet, samt moderne tjenester som ApplePay, noe som gjør dem til et godt valg for våre medlemmer.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '6.05%',
                featured: false,
                loanExample: ' Låneeksempel: Effektiv rente 5.70% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 703 643.',
                btnUrl: "https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/sok-om-boliglan.html",
                affiliateType: "",
            },
        ]
    },
    {
        dataType: 'Forbrukslån',
        averageRent: "9,59",
        defaultActive: true,
        childData: [
            {
                name: "",
                image: unofinans,
                leverandorTekst: 'EasyFinance samarbeider med Uno Finans for at våre medlemmer skal få de beste lånevilkårene. Unofinans tilbyr en svært enkel og brukervennlig lånesøknadsprosess, hvor du med bare én søknad kan motta flere tilbud.',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '7.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 12.71% ved lån på 150 000 kr over 5 år. Totalt: kr 199 237.',
                btnUrl: "https://ormedbyte.com/click.track?CID=430720&AFID=529108",
            },
            {
                name: "",
                image: axo,
                leverandorTekst: 'Vi anbefaler Axo Finans for dine lånebehov. Gjennom Axo Finans kan du søke lån hos 16 banker samtidig og velge det beste tilbudet. Dette er gratis og uforpliktende, og du får raskt svar. Med Axo Finans kan du også spare penger ved refinansiering.Spar tid og penger med Axo Finans!',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 13,33% ved lån på 150 000 kr over 5 år. Totalt: kr 202 496.',
                btnUrl: "https://go.axofinans.no/aff_c?offer_id=834&aff_id=1527",
            },
            {
                name: "",
                image: lendoLogo,
                leverandorTekst: "Vi anbefaler Lendo fordi de gjør det enkelt å finne det beste lånet. Med Lendo kan du søke én gang og få oversikt over lånebeløp og renter fra flere banker. Velg det lånet som passer best for deg.",
                rating: '4.5',
                loanAmount:'10 000 kr - 600 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 12,73% ved lån på 130 000 kr over 5 år. Totalt: kr 173 382.',
                btnUrl: "https://track.adtraction.com/t/t?a=1081817788&as=1746558926&t=2&tk=1",

            },
        ]
    },
    {
        dataType: 'Kredittkort',
        averageRent: "21,66",
        childData: [
            {
                name: "Axo finans",
                image: creditcardcollection,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'150 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: '100 000 kr',
                effectiveInterestRate: '24,47 %',
                maxiRepaymentPeriod: '43 dager',
                nominalInterestRate: '4.46%',
                featured: false,
                title: "Flere valgmuligheter - søk om  fem kredittkort samtidig",
                moreInfo: ["Få flere tilbud med én søknad","Gratis og uforpliktende","Se hva som passer ditt behov"],
                loanExample: 'Eff. Rente 26,69%, kr 25.000 o/12 mnd. Kostnad kr 3.337. Totalt kr 28.337. Ulike kort har ulike renter.',
                btnUrl: "https://go.axofinans.no/aff_c?offer_id=940&aff_id=1527",
                affiliateType: "Annonse",
                moreDetail: [
                    {
                        name: "Morrow Bank Mastercard",
                        image: morrowBank,
                        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                        rating: '4.5',
                        loanAmount:'100 000 kr',
                        pricePerMonths: 'NOK 16,607',
                        age: '18 år',
                        estTotalCost: '100 000 kr',
                        effectiveInterestRate: '24,42 %',
                        maxiRepaymentPeriod: '50 dager',
                        nominalInterestRate: '4.46%',
                        featured: false,
                        loanExample: "Eff. rente 24,42 %, kr 12.000/12 mnd. Kostnad: 1.481 kr. Totalt: 13.481 kr. ",
                        moreInfo: ["Gebyrfritt  ", "Ingen årsavgift", "Tilgang til Apple Pay", "Reise- og avbestillingsforsikring, kjøpsforsikring og ID-tyveriforsikring inkludert"],
                        tagLine: "Bonus på alle kjøp",
                        btnUrl:"https://track.adtraction.com/t/t?a=1746519962&as=1746558926&t=2&tk=1",
                        affiliateType: "Annonse",
                    },
                    {
                        name: " TF Bank Mastercard ",
                        image: tfBank,
                        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                        rating: '4.5',
                        loanAmount:'150 000 kr',
                        pricePerMonths: 'NOK 16,607',
                        age: '18 år',
                        estTotalCost: '150 000 kr',
                        effectiveInterestRate: '26,69 %',
                        maxiRepaymentPeriod: '43 dager',
                        nominalInterestRate: '4.46%',
                        featured: false,
                        loanExample: 'Eff. rente 26,69 %, kr 25.000/12 mnd. Kostnad: 3.337 kr. Totalt: 28.337 kr. ',
                        moreInfo: ["Gebyrfritt", "Ingen årsavgift", "Fordelsprogram med unike rabatter ", "Reise- og avbestillingsforsikring, kjøpsforsikring og ID-tyveriforsikring inkludert"],
                        tagLine: "Gebyrfri",
                        btnUrl:"https://aslinkhub.com/?cid=9510&pid=43699&productGroup=Creditcard&media_id=100963 ",
                        affiliateType: "Annonse",
                    },
                    {
                        name: "Bank Norwegian",
                        image: bankNorwegian,
                        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                        rating: '4.5',
                        loanAmount:'150 000 kr',
                        pricePerMonths: 'NOK 16,607',
                        age: '18 år',
                        estTotalCost: '150 000 kr',
                        effectiveInterestRate: '24,4 %',
                        maxiRepaymentPeriod: '45 dager',
                        nominalInterestRate: '4.46%',
                        featured: false,
                        loanExample: ' Eff. rente 24,4 %, kr 15.000/12 mnd. Kostnad: 1.849 kr. Totalt: 16.849 kr. ',
                        moreInfo: ["Gebyrfritt", "Ingen årsavgift", "Cashpoints eller cashback på alle kjøp", "Reise- og avbestillingsforsikring"],
                        tagLine: "Cashpoint på alle kjøp",
                        btnUrl:"https://go.axofinans.no/aff_c?offer_id=940&aff_id=1527",
                        affiliateType: "Annonse",
                    },
                    {
                        name: "re:member",
                        image: remember,
                        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                        rating: '4.5',
                        loanAmount:'150 000 kr',
                        pricePerMonths: 'NOK 16,607',
                        age: '18 år',
                        estTotalCost: '150 000 kr',
                        effectiveInterestRate: '30,33 %',
                        maxiRepaymentPeriod: '45 dager',
                        nominalInterestRate: '4.46%',
                        featured: false,
                        loanExample: 'Eff. rente 30,33 %, kr 15.000/12 mnd. Kostnad: 1717 kr. Totalt: 16.717kr. ',
                        moreInfo: ["Ingen årsavgift og inntil 45 dagers rentefri kreditt ", "Kontaktløs betaling med Apple Pay og Google Pay ", "Rabatter i over 200 nettbutikker ","Reise- og avbestillingsforsikring  "],
                        tagLine: "Rabatter i 200 butikker",
                        btnUrl:"https://aslinkhub.com/?cid=2166&pid=43699&productGroup=creditcard&media_id=100963",
                        affiliateType: "Annonse",
                    },

                    {
                        name: "Instapay",
                        image: instaBank,
                        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                        rating: '4.5',
                        loanAmount:'100 000 kr',
                        pricePerMonths: 'NOK 16,607',
                        age: '18 år',
                        estTotalCost: '100 000 kr',
                        effectiveInterestRate: '21,66 %',
                        maxiRepaymentPeriod: '0 dager',
                        nominalInterestRate: '4.46%',
                        featured: false,
                        loanExample: 'Eff.rente 21,66%, 45 000, o/1 år, kostnad: 4 963 kr. Totalt: 49 963 kr. ',
                        moreInfo: ["Ny rabatt hver måned ", "Betal litt og litt hver måned ","En av markedets laveste rente", "Få eksklusive tilbud og rabbetter "],
                        tagLine: "Laveste rente",
                        btnUrl:"https://go.axofinans.no/aff_c?offer_id=940&aff_id=1527",
                        affiliateType: "Annonse",
                    },

                ]
            },
        ]
    },
]

export default data;