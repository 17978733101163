import React from "react";
import ReactDOM from "react-dom/client";
import App from "Containers/App";
import { BrowserRouter, Route, Router } from "react-router-dom";
import CookiesPopup from "Components/Cookies/CookiesPopup";
import store from "./Containers/App/redux/store";
import { Provider } from "react-redux";
const root = ReactDOM.createRoot(document.getElementById("root"));



root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
      {/*<CookiesPopup />*/}
    </BrowserRouter>
  </Provider>
);
