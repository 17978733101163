import { Validator } from "fluentvalidation-ts";

type User = {
    firstName: string;
    lastName: string;
    email: string;
    userName: string;
    password: string;
    confirmPassword: string;
    roles: string[];
    phoneNo: string;
    dob: string;
    address: string;
    postalCode:string;
    country: string;
    gender: string;
}

class UserValidator extends Validator<User> {
    constructor(){
        super();
        this.ruleFor('userName')
        .notEmpty()
        .withMessage('User name must not be empty.')

        this.ruleFor('firstName')
        .notEmpty()
        .withMessage('First name must not be empty.')

        this.ruleFor('lastName')
        .notEmpty()
        .withMessage('Last name must not be empty.')

        this.ruleFor('email')
        .emailAddress()
        .withMessage('Email address must be valid.')
        .notEmpty()
        .withMessage('Email address must not be empty.')
    }
}

export default UserValidator;