import React, { useEffect, useState } from 'react';
import { Alert, Col, Form, Row, Spinner } from 'react-bootstrap';
import SectionTitle from '../../../../../Components/SectionTitle';
import FormInput from '../../../../../Components/FormInput';
import Button from '../../../../../Components/Button';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SelectPackagePopup from './SelectPackagePopup';
import classes from './index.module.scss';
import { getConsumerReport, getCurrentUser } from '../../../../../Services/api/Auth';
import { saveDefaultCompany, searchCompanies, userCompanyDetails } from '../../../../../Services/api/Company';
import Loader from "../../../../../Components/Loader";

const CompanyList = ({ absolute, isButtonClicked, onClose, fromDashboard, loadReport, otherCompanies, onSelect }) => {
    const [modalShow, setModalShow] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState({});
    const [businessButton, setBusinessButton] = useState(localStorage.getItem('btnRoute') === 'bHeader')
    const handlePackageButtonClick = () => {
        onClose(); // Close the first modal
        setModalShow(true); // Open the second modal
    };
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [saveCompanyError, setSaveCompanyError] = useState(false);
    const [currentUser, setCurrentUser] = useState({});
    const [consumerReport, setConsumerReport] = useState({});
    const [loading, setLoading] = useState(false);
    const [filterText, setFilterText] = useState('');
    const [subscriptionError, setSubscriptionError] = useState(false);
    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        setLoading(true)
        getCurrentUser()
            .then(user => {
                if (!user) {
                    localStorage.removeItem('auth');
                    window.location.href = '/login';
                }
                else {
                    setCurrentUser(user);
                }
            });
        getConsumerReport()
            .then(report => {
                setConsumerReport(report);
            })
            .finally(() => setLoading(false));
    }, [])
    function getInitials(name) {
        var parts = name.split(" ");
        if (parts.length === 1) {
            return name.substring(0, Math.min(name.length, 2)).toUpperCase();
        }
        else {
            var firstCharacters = parts.map(part => part.charAt(0));
            return firstCharacters.slice(0, 2).join("").toUpperCase();
        }
    }
    function saveCompany(companyNumber) {
        setLoading(true);
        saveDefaultCompany(companyNumber)
            .then(() => {
                try {
                    const redirect = searchParams.get('redirect') || localStorage.getItem('redirect');
                    localStorage.removeItem('redirect');
                    const auth = JSON.parse(localStorage.getItem('auth'))
                    localStorage.removeItem('btnRoute');
                    localStorage.setItem('auth', JSON.stringify({ ...auth, hasDefaultCompany: true }))
                    navigate(redirect && redirect !== '' ? redirect : '/bedriftsdashbord');
                }
                catch (err) {
                    setSaveCompanyError(true);
                }
            })
            .catch(() => setSaveCompanyError(true))
            .finally(() => setLoading(false))
    }
    function getCompanyReport(companyNumber, company) {
        setSelectedCompany(company);
        setLoading(true);
        userCompanyDetails(companyNumber)
            .then((resp) => {
                if (resp?.subscribed === false) {
                    setSubscriptionError(true);
                }
                else {
                    loadReport(resp);
                }
            })
            .finally(() => setLoading(false))
    }

    const loadCompanies = () => {
        if(!filterText || filterText === '') return;
        searchCompanies(filterText)
            .then(resp => setCompanies(resp?.companies))
            .catch(console.log);
    }
    const _loadCompanyData = (id) => {
        setLoading(true);
        userCompanyDetails(undefined, id)
            .then((resp) => {
                if (resp?.subscribed === false) {
                    setSubscriptionError(true);
                }
                else {
                    onClose();
                    onSelect(resp);
                }
            })
            .finally(() => setLoading(false))
    }
    useEffect(() => {
        loadCompanies();
    }, [filterText])

    useEffect(() => {
        setBusinessButton(localStorage.getItem('btnRoute') === 'bHeader')
        localStorage.removeItem('btnRoute');
    }, [])


    const _handleLoad = () => {
        alert('Load');
    }
    const _handleSave = () => {
        saveCompany(selectedCompany?.companyNumber)
    }
    return (
        <React.Fragment>
            <div className={`${classes.findCompany} ${absolute ? classes.absolute : ''} ${isButtonClicked && absolute ? classes.visible : ''}`}>
                <div className={classes.boxHolder}>
                    {isButtonClicked && (
                        <Button
                            icon={'fal fa-times'}
                            variant={'transparent circle absolute right top m-2 font-16'}
                            onClick={onClose}
                        />
                    )}
                    <Row>
                        <Col lg={12}>
                            <SectionTitle textCenter
                                          description={businessButton ? 'Vi oppretter ditt selskaps \'dashboard\'. Selskapet du registrerer nå, blir ditt primære selskap, og vi vil samle inn informasjon om dette selskapet på dine vegne.Vennligst merk at det ikke er mulig å endre det primære selskapet senere hvis du velger Basic-pakken.' : 'Vi oppretter ditt selskaps \'dashboard\'. Selskapet du registrerer nå, blir ditt primære selskap, og vi vil samle inn informasjon om dette selskapet på dine vegne.Vennligst merk at det ikke er mulig å endre det primære selskapet senere hvis du velger Basic-pakken.'}>
                                <span className={'fw-normal'}>Velg ditt</span>
                                <span className={'fw-semibold'}>firma</span>
                            </SectionTitle>
                            {currentUser?.isUserSubscribed && <Alert color={'info'} className={'text-center'}>Du har {currentUser?.remainingCreditReports} kredittsjekker igjen</Alert>}
                            {consumerReport?.companyEngagements?.length === 0 && <Alert color='danger' className={'text-center'}>Ingen selskaper tilknyttet deg.
                                <hr />
                                <div className="d-flex justify-content-end">
                                    <Button url={'/privatdashbord'} variant="outline-success" text={'Go to dashboard'} />
                                </div>
                            </Alert>}
                            {
                                subscriptionError && <Alert color='danger' className={'text-center'}>Du abonnerer ikke på et abonnement. Vennligst abonner ved å klikke på knappen nedenfor.</Alert>
                            }
                            {(loading || consumerReport?.companyEngagements?.length > 0) && <div className={classes.searchBox}>
                                <Form>
                                    {/* <FormInput
                                        type={'search'}
                                        placeholder={'Søk etter din bedrift'}
                                        value={filterText}
                                        onChange={e => setFilterText(e.target.value)}
                                    /> */}
                                    {loading ?
                                        <ul className={classes.companyList}>
                                            <Loader style={{ minHeight: '100px', position: 'relative' }} />
                                        </ul> :
                                        <ul className={classes.companyList}>
                                            {!otherCompanies ? consumerReport?.companyEngagements?.filter(company => filterText && filterText !== '' ? company?.companyName?.toLowerCase().includes(filterText?.toLowerCase()) : true).map((company, index) =>
                                                <li key={index} className={company?.companyNumber === selectedCompany?.companyNumber ? classes.active : ''}>
                                                    <a onClick={() => fromDashboard ? getCompanyReport(company?.companyNumber, company) : setSelectedCompany(company)}>
                                                        <div className={classes.title}>
                                                            <div className={classes.iconBox}>{getInitials(company?.companyName)}</div>
                                                            {company?.companyName}
                                                        </div>
                                                        <div className={'text-muted font-12'}>Org.Nr: {company?.companyNumber}</div>
                                                    </a>
                                                </li>
                                            ) : <>
                                                {companies?.map((company, index) => {
                                                    return <li key={index} className={''}>
                                                        <a onClick={() => _loadCompanyData(company?.id)}>
                                                            <div className={classes.title}>
                                                                <div className={classes.iconBox}>{getInitials(company?.name)}</div>
                                                                {company?.name}
                                                            </div>
                                                            <div className={'text-muted font-12'}>Org.Nr: {company?.regNo}</div>
                                                        </a>
                                                    </li>
                                                })}
                                            </>}
                                        </ul>
                                    }
                                    {!fromDashboard &&
                                        <div className={'text-center pt-3'}>
                                            <Button variant={' w-50'} disabled={!(selectedCompany && selectedCompany?.companyNumber && selectedCompany?.companyNumber !== '')}  text={'VELG'} onClick={fromDashboard ? _handleLoad : _handleSave} />
                                        </div>
                                    }
                                </Form>
                            </div>}
                        </Col>
                    </Row>

                    {subscriptionError && <Row className={'pt-4'}>
                        <Col lg={12}>
                            <Button
                                text={'Select your package'}
                                variant={' w-100 mb-3'}
                                onClick={handlePackageButtonClick}
                            />
                        </Col>
                    </Row>}
                </div>
            </div>
            <SelectPackagePopup
                show={modalShow}
                companyInfo={selectedCompany}
                onHide={() => setModalShow(false)}
            />
        </React.Fragment>
    );
};

const FindYourCompany = ({ company, useTitle, fromDashboard, loadReport, opened, otherCompanies, onSelect }) => {
    const [isButtonClicked, setButtonClicked] = useState(false || opened);

    const handleButtonClick = () => {
        setButtonClicked(!isButtonClicked);
    };

    const handleCloseButtonClick = () => {
        setButtonClicked(false);
    };
    const setReport = (report) => {
        loadReport(report);
        setButtonClicked(false);
    }

    return (
        <React.Fragment>
            {useTitle && (
                <Button

                    text={company?.report?.companySummary?.businessName ?? company?.basicInformation?.name ?? ''}
                    icon={'fal fa-search'}
                    variant={'title font-16 fw-medium'}
                // TODO: PRODUCTION
                // onClick={handleButtonClick}
                />
            )}
            {useTitle ? (
                <CompanyList otherCompanies={otherCompanies} fromDashboard absolute={true} isButtonClicked={isButtonClicked} onClose={handleCloseButtonClick} loadReport={setReport} onSelect={onSelect} />
            ) : (
                <CompanyList />
            )}
        </React.Fragment>
    );
};

export default FindYourCompany;
