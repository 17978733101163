import img1 from '../../../Images/img64.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "10 000 opptil ", class: "fw-noraml" },
                { id: 2, title: "2 millioner", class: "fw-bold" },
            ]
        },
        content: 'EasyFinance tilbyr tilpassede bedriftslån ideelle for små til mellomstore bedrifter. Med støtte fra EasyFinance, kan du effektivt adressere både mindre og større utfordringer din bedrift måtte ha.\n' +
            'Vi kan hjelpe deg med lån fra 10 000 kr til 2 millioner kr, noe som gir deg fleksibiliteten til å oppnå dine mål og fremme veksten i din virksomhet!',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#fff'

    },
];

export default contentWithImgData;
