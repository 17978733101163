import React, {useState, useEffect} from 'react';
import { Col, Form, InputGroup, Row, } from 'react-bootstrap';
import FormInput from '../../FormInput';
import Button from '../../Button';
import classes from '../index.module.scss';
import CircularProgressBar from "../../CircularProgressBar";
import Loader from '../../Loader';
import { getComponentVersionByListOfIds } from 'Services/api/LoanFilter';
import Loading from "../../Loading";
import { useSelector } from 'react-redux';

const LoanWithSecurity = ({ detail, hideRadio, values, setLoanObject, loanObject }) => {
    
    const { settings } = useSelector(state => state.settings)

    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState({});
    const [selectedLoanType, setSelectedLoanType] = useState(0);
    const languageId = 1;
    const [percentage, setPercentage] = useState(0);

    const [purchaseAmount, setPurchaseAmount] = useState(values?.purchaseAmount);
    const [propertyValue, setPropertyValue] = useState(values?.housingValue);
    const [loanAmount, setLoanAmount] = useState(values?.loanAmount);
    const [age, setAge] = useState(values?.age);
    const [repaymentPeriod, setRepaymentPeriod] = useState(values?.repaymentPeriod);

    const [productType, setProductType] = useState(values?.productType);
    useEffect(() => {
        if(!detail) return;
        const ids = detail?.map(d => d.tabVersion);
        getComponentVersionByListOfIds(languageId, ids)
        .then(resp => {
            setVersions(resp);
            setSelectedLoanType(resp[0]?.loanCategory);
            if(!values){
                setPropertyValue(values?.housingValue ??resp[0]?.state?.defaultProperyValue);
                setAge(values?.age ??resp[0]?.state?.ageDefaultValue);
                setLoanAmount(values?.loanAmount ??resp[0]?.state?.defaultLoanAmount);
                setRepaymentPeriod(values?.repaymentPeriod ??resp[0]?.state?.defaultRepaymentPeriod);
            }
        });
    }, [])

    useEffect(() => {
        const nowSelected = versions.find(version => version.loanCategory == selectedLoanType);
        setSelectedVersion(nowSelected);
        setPropertyValue(values?.housingValue ?? nowSelected?.state?.defaultProperyValue);
        setAge(values?.age ?? nowSelected?.state?.ageDefaultValue);
        setLoanAmount(values?.loanAmount ?? nowSelected?.state?.defaultLoanAmount);
        setRepaymentPeriod(values?.repaymentPeriod ?? nowSelected?.state?.defaultRepaymentPeriod);
        setPurchaseAmount(values?.purchaseAmount ?? nowSelected?.state?.defaultPurchaseAmount);
    }, [selectedLoanType])
    
    useEffect(() => {
        setPercentage( Math.round((loanAmount / propertyValue ) * 100))
    }, [loanAmount, propertyValue, selectedLoanType]);
    

    const handleLoanTypeChange = event => setSelectedLoanType(event.target.value);

    return(
        <React.Fragment>
            {!hideRadio && <Row>
                <Col md={12}>
                    <InputGroup className={`p-0 ${classes.radioList}`}>
                        {versions.map((version, index) => {
                            return <FormInput
                            key={index}
                            type={'radio'}
                            label={version?.state?.tabTilte}
                            variant={'radio'}
                            id={`${version?.loanCategory}_${version?.loanType}_radio`}
                            name={`${version?.loanType}_radio`}
                            value={version?.loanCategory}
                            checked={selectedLoanType == version?.loanCategory}
                            onChange={handleLoanTypeChange}
                        />})}
                    </InputGroup>
                </Col>
            </Row>}

            {selectedVersion?.loanCategory === 6 && selectedVersion?.state && <React.Fragment>
                <div className={classes.contentHolder}>
                    <div className={classes.imgBox}>
                        <img src={selectedVersion?.state?.tabImage} loading="lazy" alt={'#'} />
                    </div>
                    <div className={classes.contentBox}>
                        <div className={classes.inputRow}>
                            <Col>
                                <FormInput
                                    fieldLabel={selectedVersion?.state?.productBorrowLabel}
                                    type={'select'}
                                    placeHolder={selectedVersion?.state?.products?.find(option => option.value === productType)?.label ?? selectedVersion?.state?.productsDropdownPlaceholder}
                                    options={selectedVersion?.state?.products}
                                    value={selectedVersion?.state?.products?.find(option => option.value === productType)}
                                    onChange={e => {
                                        setProductType(e?.value);
                                    }}
                                />
                            </Col>
                            <Col>
                                <FormInput
                                    fieldLabel={selectedVersion?.state?.repaymentPeriodLabel}
                                    type={'select'}
                                    placeHolder={ repaymentPeriod ? `${repaymentPeriod} ${selectedVersion?.state?.yearsLabel}` : selectedVersion?.state?.yearsDropdownPlaceholder}
                                    options={Array.from({ length: Number(selectedVersion?.state?.maxRepaymentPeriod) - Number(selectedVersion?.state?.minRepaymentPeriod) + 1 }, (_, index) => index + Number(selectedVersion?.state?.minRepaymentPeriod)).map(val => ({ value: val, label: `${val} ${selectedVersion?.state?.yearsLabel}` }))}
                                    onChange={e => setRepaymentPeriod(e?.value)}
                                    value={{ value: repaymentPeriod, label: `${repaymentPeriod} ${selectedVersion?.state?.yearsLabel}`}}
                                />
                            </Col>
                            <Col>
                                <FormInput
                                    fieldLabel={selectedVersion?.state?.purchaseAmountLabel}
                                    type={'text'}
                                    formatValue
                                    numericOnly
                                    valueMax={99999999}
                                    value={purchaseAmount}
                                    icon={selectedVersion?.state?.currencyLabel}
                                    onChange={e => setPurchaseAmount(e.target.value?.replaceAll(' ', ''))}
                                    textlg
                                />
                            </Col>
                            <Col>
                                <FormInput
                                    fieldLabel={selectedVersion?.state?.loanAmountLabel}
                                    type={'text'}
                                    formatValue
                                    numericOnly
                                    value={loanAmount}
                                    valueMax={99999999}
                                    onChange={e => {
                                        setLoanAmount(e.target.value?.replaceAll(' ', ''))
                                    }}
                                    icon={selectedVersion?.state?.currencyLabel}
                                    textlg
                                />
                            </Col>

                        </div>
                        <div className={classes.btnRow}>
                            <Button
                                disabled={!productType || !repaymentPeriod || productType === '' || repaymentPeriod === '' || Number(loanAmount) > Number(purchaseAmount)}
                                text={selectedVersion?.state?.buttonLabel}
                                icon={'fas fa-arrow-right'}
                                {...(loanObject && setLoanObject ? {onClick: () => {
                                    if(loanObject && setLoanObject){
                                        setLoanObject({ ...loanObject, loanAmount, repaymentPeriod: repaymentPeriod * 12, loanType: productType, purchasePrice: purchaseAmount })
                                    }
                                }} : {url: `${selectedVersion?.state?.buttonUrl}?loanAmount=${loanAmount}&repaymentPeriod=${repaymentPeriod}&productType=${productType}&purchaseAmount=${purchaseAmount}`})}
                            />
                            { Number(loanAmount) > Number(purchaseAmount) ? <div className={`${classes.loanExample} text-danger`}>{ settings?.find(s => s?.variableName === 'billanLoanAmountError')?.value }</div> : <div className={classes.loanExample}>{selectedVersion?.state?.loanExample}</div>}
                        </div>
                    </div>
                </div>
            </React.Fragment>}

           {selectedVersion?.loanCategory !== 6 && (selectedVersion?.state ? <div className={classes.contentHolder}>
                <div className={classes.barBox}>
                    <CircularProgressBar percentageValue={percentage}/>
                </div>
                <div className={classes.contentBox}>
                    <div className={classes.inputRow}>
                        <Col>
                            <FormInput
                                formatValue
                                numericOnly
                                fieldLabel={selectedVersion?.state?.propertyValueLabel}
                                type={'text'}
                                value={propertyValue}
                                valueMax={99999999}
                                onChange={e => setPropertyValue(e.target.value)}
                                icon={selectedVersion?.state?.currencyLabel}
                                textlg
                                tabIndex={'1'}
                                // toolTipDescription={'hello description'}
                                // toolTipTitle={'hello'}
                            /> 
                        </Col>
                        <Col>
                            <FormInput
                                numericOnly
                                formatValue
                                fieldLabel={selectedVersion?.state?.loanAmountLabel}
                                type={'text'}
                                value={loanAmount}
                                valueMax={99999999}
                                onChange={e => setLoanAmount(e.target.value)}
                                icon={selectedVersion?.state?.currencyLabel}
                                textlg
                                tabIndex={'2'}
                            />
                        </Col>
                        <Col>
                            <FormInput
                            numericOnly
                                formatValue
                                fieldLabel={selectedVersion?.state?.ageLabel}
                                type={'text'}
                                value={age}
                                onChange={e => setAge(e.target.value)}
                                icon={selectedVersion?.state?.yearsLabel}
                                textlg
                                tabIndex={'3'}
                            />
                        </Col>
                        <Col>
                            <FormInput
                                numericOnly
                                formatValue
                                fieldLabel={selectedVersion?.state?.repaymentPeriodLabel}
                                type={'text'}
                                value={repaymentPeriod}
                                onChange={e => setRepaymentPeriod(e.target.value)}
                                icon={selectedVersion?.state?.yearsLabel}
                                textlg
                                tabIndex={'4'}
                            />
                        </Col>
                    </div>
                    <div className={classes.btnRow}>
                        <Button
                            disabled={Number(repaymentPeriod) > 40 || Number(repaymentPeriod) < 1 || Number(age) < 18 || Number(age) > 150 || ((Number(loanAmount) / Number(propertyValue))*100 > 85)}
                            text={selectedVersion?.state?.buttonLabel}
                            icon={'fas fa-arrow-right'}
                            {...(loanObject && setLoanObject ? {onClick: () => {
                                if(loanObject && setLoanObject){
                                    setLoanObject({ ...loanObject, loanAmount, repaymentPeriod, housingValue: propertyValue, age })
                                }
                            }} : {url: `${selectedVersion?.state?.buttonUrl}?loanAmount=${loanAmount}&propertyValue=${propertyValue}&repaymentPeriod=${repaymentPeriod}&age=${age}`})}

                        />
                        { Number(age) < 18 || Number(age) > 150 ? <div className={`${classes.loanExample} text-danger`}>{ settings?.find(s => s?.variableName === 'loanWithSecurityAgeError')?.value }</div> : Number(repaymentPeriod) > 40 || Number(repaymentPeriod) < 1 ? <div className={`${classes.loanExample} text-danger`}>{ settings?.find(s => s?.variableName === 'loanWithSecurityRepaymentPeriodError')?.value }</div> : percentage > 85 ? <div className={`${classes.loanExample} text-danger`}>{ settings?.find(s => s?.variableName === 'loanWithSecurityLoanToValueRatioTooHighError')?.value }</div> : <div className={classes.loanExample}>{selectedVersion?.state?.loanExample}</div> }
                    </div>
                </div>
            </div> : <Loading />)}
        </React.Fragment>
    )
}

export default LoanWithSecurity;