import React from "react";
import {Container} from "react-bootstrap";
import SectionTitle from "../../SectionTitle";
import Button from "../../Button";
import data from "../data";
import Banks from "../Banks";
import {Link} from "react-router-dom";


const PopularBanks = ({ detail }) => {
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <SectionTitle textCenter description={<>
                      {detail?.heading?.description + ' '}
                      { detail?.linkText && <Button
                          url={detail?.buttonUrl}
                          text={detail?.buttonText}
                          icon={'far fa-arrow-right'}
                          variant={'link'}
                      />}
                  </>}>
                      {detail?.heading?.title?.map((title, index) => <span className={title.class} key={index}>{title.title}</span>)}
                  </SectionTitle>
                  <Banks detail={detail} count={18} showDisplayFilter/>
                  { detail?.buttonText && <div className={'text-center pt-2'}>
                      <Button
                          url={detail?.buttonUrl}
                          text={detail?.buttonText}
                          icon={'far fa-arrow-right'}
                          variant={'border'}
                      />
                  </div>}
              </Container>
          </section>
      </React.Fragment>
  )
}

export default PopularBanks;