import { takeEvery, put, call, all } from 'redux-saga/effects';
import { getAllComponents } from '../../../../Services/api/Component';
import { loadComponents, settingsLoaded, testimonialsLoaded } from '../actions/component';
import { versionsLoaded } from '../actions/componentVersion';
import { loadSettings, loadSettingsByComponentId } from '../../../../Services/api/SettingsService';
import { getAllCategories }  from '../../../../Services/api/Blog'
import { loadedCategories } from '../actions/blogs';
import { getAllBanks } from '../../../../Services/api/Banks';
import { banksLoaded } from '../actions/banks';
import { getTestimonialsByComponentId } from '../../../../Services/api/TestimonialService';
import { loadAllAppSettings } from '../../../../Services/api/ApplicationSettings';
import { appSettingsLoaded } from '../actions/appSettings';

export function* applicationLoad(action){
    const components = yield call(getAllComponents);
    yield put(loadComponents(components));

    const settings = yield call(() => loadSettingsByComponentId(1, components?.find(component => component.name === 'Settings')?.id))
    yield put(settingsLoaded(settings));

    const testimonials = yield call(() => getTestimonialsByComponentId(components?.find(component => component.name === 'Testimonial Section')?.id))
    yield put(testimonialsLoaded(testimonials));

    const blogsCategories = yield call(() => getAllCategories(1))
    yield put(loadedCategories(blogsCategories?.data));

    const appSettings = yield call(loadAllAppSettings);
    yield put(appSettingsLoaded(appSettings));

    // const allBanks = yield call(getAllBanks);
    // yield put(banksLoaded(allBanks));

}