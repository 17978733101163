import img1 from "../../Images/checkyourselfNew.svg";
import img2 from "../../Images/CheckYourDebtNew.svg";
import img3 from "../../Images/homeValueNew.svg";

const data = [
    {
        withOutImg: false,
        imgOnTop: true,
        heading: {
            description: "Du kan enkelt logge inn med Vipps eller BankID og få full oversikt over din økonomi. Du vil ha tilgang til din kredittscore, oversikt over all usikret gjeld som kredittkort og forbrukslån, samt eventuelle betalingsanmerkninger. I tillegg vil du få estimater på verdien av din bolig, informasjon om hva du kjøpte den for, og hvor mye den har økt i verdi – alt dette og mye mer, helt gratis!",
            title: [
                { id: 1, title: "Få mer kontroll over", class: "fw-normal" },
                { id: 2, title: "økonomien din", class: "fw-bold" }
                // Add more title objects as needed
            ]
        },
        colData: [
            {
                title: "Kredittsjekk deg selv",
                description: "",
                featuredImage: img1,
                url: "/easyscore/kredittsjekk"
            },
            {
                title: "Gjeldsoversikt",
                description: "",
                featuredImage: img2,
                url: "/easyscore/gjeldsoversikt"
            },
            {
                title: "Boligverdi",
                description: "",
                featuredImage: img3,
                url: "/easyscore/sjekk-boligverdi"
            },
        ]
    },
    // Add more data objects as needed
];

export default data;
