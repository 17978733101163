import React, { useEffect, useState } from 'react';
import classes from '../index.module.scss';
import SectionTitle from "../../SectionTitle";
import Button from "../../Button";
import {Col, Container, Row} from 'react-bootstrap';
import { getLatestBlogPost } from 'Services/api/Blog';
import Loader from "../../Loader";

const LatestPosts = ({ detail, BusinessBlog }) => {
    const [blogDetails, setBlogDetails] = useState([]);
    const languageId = 1;
    const count = 2;
    useEffect(() => {
        getLatestBlogPost(count, languageId)
        .then(resp => {
            setBlogDetails(resp.data);
        });
    }, [])

    return (
        <React.Fragment>
            {BusinessBlog ? (
                <div className={`${classes.blogSection} position-relative`} style={{backgroundColor: '#ebf3fb'}}>
                    <Container>
                        <Row className={BusinessBlog && classes.businessBlog}>
                            <Col lg={3}>
                                {detail.map(detail => (
                                    <SectionTitle className={'pb-0'} description={detail?.heading?.description}>
                                        { detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>) }
                                    </SectionTitle>
                                ))}
                            </Col>
                            <Col lg={9}>
                                {blogDetails.length === 0 && <div className={classes.latestBlogSkeleton} />}
                                <div className={`${classes.colPost} ${classes.latest}`}>
                                    {blogDetails.map((item, index) => {
                                        const details = JSON.parse(item.postContents);
                                        return <div className={classes.col} key={index}>
                                            <div className={classes.box}>
                                                <a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} className={classes.imgBox} style={{ backgroundImage: `url(${details.image})` }}></a>
                                                <div className={classes.description}>
                                                    <h4 className={classes.title}><a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{item.title}</a></h4>
                                                    {index !== 0 && (
                                                        <>
                                                            <p>{item.summary}</p>
                                                            <Button
                                                                text={'Fortsett å lese'}
                                                                url={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                                                variant={'link'}
                                                                icon={'far fa-arrow-right'}
                                                            />
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            ) : (
                <div className={classes.blogSection}>
                    <Container>
                        <SectionTitle className={'pb-0'} description={detail?.heading?.description}>
                            { detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>) }
                        </SectionTitle>
                        {blogDetails.length === 0 && <div className={classes.latestBlogSkeleton} />}
                        <div className={`${classes.colPost} ${classes.latest}`}>
                            {blogDetails.map((item, index) => {
                                const details = JSON.parse(item.postContents);
                                return <div className={classes.col} key={index}>
                                    <div className={classes.box}>
                                        <a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} className={classes.imgBox} style={{ backgroundImage: `url(${details.image})` }}></a>
                                        <div className={classes.description}>
                                            <h4 className={classes.title}><a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{item.title}</a></h4>
                                            {index !== 0 && (
                                                <>
                                                    <p>{item.summary}</p>
                                                    <Button
                                                        text={'Les mer'}
                                                        url={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                                        variant={'link'}
                                                        icon={'far fa-arrow-right'}
                                                    />
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>

                    </Container>
                </div>
            ) }

        </React.Fragment>
    )
}

export default LatestPosts;
