import React, { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import Button from "../../Components/Button";
import classes from './index.module.scss';
import { Alert } from "react-bootstrap";
import { signicatLogin, vippsLogin } from "../../Services/api/Login";

const LoanApplicationLogin = () => {
    const [authObject, setAuthObject] = useState({});
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const authString = localStorage.getItem('auth');
        const auth = JSON.parse(authString !== '' && authString ? authString : '{}');
        setAuthObject(auth);
        if(auth?.jwToken && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()){
            window.location.href = '/privatdashbord';
        }
    }, []);

    const _handleVippsLogin = () => {
        vippsLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }
    const _handleBankId = () => {
        signicatLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }
    return (
        <React.Fragment>

            <div className={`${classes.rowHolder} ${classes.smallForm}`}>
                <div className={classes.col}>
                    <React.Fragment>
                        <div className={'text-center mb-5'}>
                            <h3>Start lånesøknaden</h3>
                        </div>
                        <FormGroup className={'text-center'}>
                            <Button
                                text={'Start søknaden med med Vipps'}
                                variant={'vipps  w-100'}
                                onClick={_handleVippsLogin}
                            />
                        </FormGroup>
                        {authObject.errorResponse && authObject.errorResponse !== '' ? <Alert variant='danger'>{authObject?.errorResponse}</Alert> : <></>}
                    </React.Fragment>
                </div>
            </div>

            <div className={classes.authFooter}>

                <p>Spar tid og start søknaden med Vipps eller BankID. Når du starter søknaden på EasyFinance.no, vil EasyFinance automatisk oppdatere dine data fra Creditsafe. Vi oppretter en bedriftsprofil for deg, slik at du enkelt kan holde oversikt over din bedriftsøkonomi.</p>
                   <p> Jeg bekrefter også at jeg har lest og forstått EasyFinances brukervilkår</p>
                 <p>
                    Logg inn med
                    <Button
                        onClick={_handleBankId}
                        variant={'link'}
                        icon={'icon-BankID'}
                        underline
                    />
                </p>
            </div>
        </React.Fragment>
    );
}

export default LoanApplicationLogin;

