import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';
import {Container} from "react-bootstrap";
import UserDashboardHeader from "../../Components/Header/UserDashboardHeader";
import {useLocation} from "react-router-dom";

const PlainLayout = ({ children }) => {
    const location = useLocation();
    const hide = () => {
        const { pathname } = location;
        return pathname === "/recommened-offers" ||
                pathname === "/find-your-company" ||
                pathname === "/house-value-refinance" ||
                pathname === "/refinancing-recommendation";
    };

    return (
        <React.Fragment>
            <div id={classes.dashboardWrapper} className={(hide() ? ` ${classes.offerPage}` : '')}>
                <div className={classes.wrapperHolder}>
                    <UserDashboardHeader hideBottomHeader={hide()}  />
                    <main id={classes.main}>
                        <Container>
                            <div className={`${classes.dashBoardHolder} ${hide() ? 'p-0' : ''}`}>
                                {children}
                            </div>
                        </Container>
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PlainLayout;
