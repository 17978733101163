import img1 from "../../../../Images/img60.svg";
import img2 from "../../../../Images/img61.svg";
import img3 from "../../../../Images/img62.svg";

const data = [
    {
        id: 1,
        title: "Refinansiere",
        description: "Samle dyre lån og kreditter til ett lån.",
        imgSrc: img1,
        url: "/house-value-refinance",
    },
    {
        id: 2,
        title: "Pusse opp",
        description: "Øk verdien på boligen din med enkle grep.",
        imgSrc: img2,
        url: "/house-value-renovation",
    },
    {
        id: 3,
        title: "Slik får du beste rente boliglån",
        description: "5 gode tips for å få billigere rente.",
        imgSrc: img3,
        url: "/house-value-mortgage",
    }
];

export default data;
