import React from "react";
import { Container } from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import Slider from "react-slick";
import AnimatedImage from "../AnimatedImg";
import { getToken } from "../../Services/api/Auth";

const ColSection = ({ detail }) => {
    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 579,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <React.Fragment>
            {Array.isArray(detail) ? (
                detail.map((item, index) => (
                    <React.Fragment key={index}>
                        {renderSection(item)}
                    </React.Fragment>
                ))
            ) : (
                renderSection(detail)
            )}
        </React.Fragment>
    );

    function renderSection(sectionData) {
        return (
            <section className={`section ${sectionData.withOutImg ? 'topOverlap' : 'bg-light-half-top border-top-none'}`}>
                <Container>
                    {sectionData.heading && (
                        <SectionTitle textCenter description={sectionData.heading.description}>
                            {sectionData.heading.title.map(title => <span className={title.class} key={title.id}>{title.title} </span>)}
                        </SectionTitle>
                    )}
                    {sectionData.colData && (
                        <Slider {...settings} className={`colList col3  
                            ${sectionData.imgOnTop ? 'imgOnTop collapsed shadow-none' : 'fullCollapsed innerArrow'}
                        `}>
                            {sectionData.colData.map((item, index) => (
                                <div className={'col'} key={index}>
                                    <div className={'box'}>
                                        {sectionData.imgOnTop ? (
                                            <>
                                                <div className={'imgBox'}>
                                                    <AnimatedImage
                                                        image={item.featuredImage}
                                                        loop={true}
                                                        autoplay={true}
                                                    />
                                                </div>
                                                <h3 className={'title'} style={{minHeight: 'auto'}}><a href={getToken() && getToken() !== '' ? item.url : `/login?redirect=${item.url}`}>{item.title}</a> </h3>
                                                {item.description && <p style={{minHeight: 'auto'}}>{item.description}</p> }
                                            </>
                                        ) : (
                                            <>
                                                <h3 className={'title'}>{item.title}</h3>
                                                <p>{item.description}</p>
                                                <div className={'imgBox text-right'}>
                                                    <img src={item.featuredImage} loading="lazy" alt={item.title} />
                                                </div>
                                            </>
                                        )}

                                    </div>
                                </div>
                            ))}
                        </Slider>
                    )}
                </Container>
            </section>
        );
    }
};

export default ColSection;
