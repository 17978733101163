import React, { useState } from "react";
import classes from './index.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import Button from "../Button";
import { Link } from "react-router-dom";
import { onError } from "Services/api/Image";
import draftToHtml from "draftjs-to-html";

const DescriptionSection = ({ detail }) => {
    //const item = data[0];
    //const detailDescriptions = item.detailDescription;

    const [activeDetail, setActiveDetail] = useState(0);

    const handleListItemClick = (index) => {
        setActiveDetail(index);
    };

    const [showAllDescriptions, setShowAllDescriptions] = useState(false);

    const handleReadMoreClick = () => {
        setShowAllDescriptions(!showAllDescriptions);
    };

    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };


    return (
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <SectionTitle textCenter>
                        {
                            detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                        }
                    </SectionTitle>
                    <Row>
                        <Col md={8}>

                            {detail?.questions?.map((item, index) => (
                                <div className={classes.description} key={index}>
                                    {(showAllDescriptions || index === activeDetail) && (
                                        <React.Fragment>
                                            <div className={classes.imgBox}>
                                                <img src={detail?.image} onError={onError} loading="lazy" alt={detail?.title} />
                                            </div>
                                            <h3 id={item?.question}>{item?.question}</h3>
                                            {/* <p>{item?.description}</p> */}
                                            <div className={classes.textBox} dangerouslySetInnerHTML={{ __html: renderFilteredHTML(item?.editor) }}/>
                                        </React.Fragment>
                                    )}
                                </div>
                            ))}
                            <Button
                                text={showAllDescriptions ? 'Vis mindre' : 'Les mer'}
                                onClick={handleReadMoreClick}
                                icon={'far fa-arrow-right'}
                            />
                        </Col>
                        <Col md={4}>
                            <ul className={classes.list}>
                                {detail?.questions?.map((item, index) => (
                                    <li
                                        className={index === activeDetail ? classes.active : ''}
                                        key={index}
                                        onClick={() => handleListItemClick(index)}>
                                        {item.question}
                                    </li>
                                ))}
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default DescriptionSection;
