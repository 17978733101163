import React from "react";
import {Text, View, StyleSheet, Link} from '@react-pdf/renderer';
import InnerPageLayout from "../../../PDFComponent/InnerPageLayout";
import moment from "moment/moment";
import AboutEasyFinance from "../../../PDFComponent/AboutEasyFinance";
import PageHeader from "./PageHeader";




const HouseValueList = ({userData, houseData, totalProperties}) => {

  const format = (value) => {
    const formatter = Intl.NumberFormat("no-NO");
    if (isNaN(value) || !isFinite(value)) return 0;
    else return formatter.format(value);
  };

  const styles = StyleSheet.create({
    section: {marginBottom: 20,},
    section1: {marginBottom: 15, fontSize: 8,},
    row: {flexDirection: 'row', justifyContent: 'space-between',marginBottom: 2},
    text: {flexDirection: 'column',},
    text1: {flexDirection: 'column', fontWeight: "700", fontSize: 11,},
    creditSafeBox: {paddingTop: 10, marginLeft: 0, fontSize: 7,},
    link: {color: '#000',},
    header: {flexDirection: 'row',fontSize: 9},
    title: {width:'50%'},
    total: {fontSize: 9, textAlign: 'right', fontWeight: "black"},
    secTitle: {color: '#013367', fontSize:11,marginBottom:5},
    contentList:{flexDirection: 'column', width: '100%', color: '#013367', marginBottom: 10, fontSize:7,},
    list:{flexDirection: 'row',width: '100%',borderLeft: '3px solid #DEE2E5',marginBottom: '1px',backgroundColor: '#f9f9f9'},
    col:{padding:'5px 10px 2px'},
    bgGray: {backgroundColor: '#F8F8F7',color: '#4B4B4B', width:'150px'},
    rowTwoCol: { flexDirection: 'row', flexWrap: 'wrap', margin: '0 -5px 10px' },
  });

  return (
      <React.Fragment>
        <InnerPageLayout  userData={userData} title={'Boligverdi'}>
          <View>
            <View style={[styles.section, styles.rowTwoCol]}>
              <PageHeader title={'Dine eiendommer'} value={totalProperties} color={'#f2faf6'} text={'#47b881'} />
              <PageHeader title={'Estimert boligverdi ink. fellesgjeld'} value={'1 921 817 kr'} color={'#FFF1DF'} text={'#FF8B00'} />
              <PageHeader title={'Ubrukt verdi du har i boligen'} value={'1 921 817 kr'} color={'#f9f9f9'} text={'#000000'} />
            </View>
            <AboutEasyFinance />
          </View>
        </InnerPageLayout>
        {houseData.map((item, index) => (
            <InnerPageLayout
                userData={userData}
                key={index}
                title={`${item?.propertyAttribs?.address?.streetName} ${item?.propertyAttribs?.address?.streetNumber} ${item?.propertyAttribs?.address?.postOffice?.code} ${item?.propertyAttribs?.address?.postOffice?.name}`}
            >
              <View style={styles.section1} >
                <View style={styles.section1}>
                  <Text style={styles.header}>Oversikt</Text>
                  <View  style={styles.contentList}>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Estimert boligverdi</Text>
                      <Text style={styles.col}>{format(item?.marketeEstimates?.approvedNetMarketValue)} kr</Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Min/maks pris</Text>
                      <Text style={styles.col}>{format(item?.marketeEstimates?.approvedNetMarketValue - item?.marketeEstimates?.approvedNetMarketValue * 0.05) + " kr"}{" "}
                        -{" "}
                        {format(Number(item?.marketeEstimates?.approvedNetMarketValue) + item?.marketeEstimates?.approvedNetMarketValue * 0.05) + " kr"}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Fellesgjeld idag</Text>
                      <Text style={styles.col}>{format(item?.marketeEstimate?.propertyEvEstimateBasis?.currentCommondebt)}{" "}
                        kr</Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Kvadratmeter pris</Text>
                      <Text style={styles.col}>
                        {format(
                            (
                                Number(
                                    item?.marketeEstimates?.approvedNetMarketValue
                                ) /
                                Number(
                                    item?.propertyAttribs?.attributes?.primaryArea
                                        ?.value
                                )
                            ).toFixed(0)
                        )}{" "}
                        kr
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Kommune</Text>
                      <Text style={styles.col}>{item?.propertyAttribs?.address?.municipality ?? "-"}</Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Bydel</Text>
                      <Text style={styles.col}>{item?.propertyAttribs?.cityDistrict ?? "-"}</Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Område score</Text>
                      <Text style={styles.col}>{item?.propertyAttribs?.marketScore?.area ?? "-"} av 20</Text>
                    </View>
                  </View>
                </View>
                <View style={styles.section1}>
                  <Text style={styles.header}>Nøkkelinfo</Text>
                  <View  style={styles.contentList}>
                    {item?.propertyOwner?.map(({ owner, index }) => (
                        <View style={styles.list} key={index}>
                          <Text style={[styles.col, styles.bgGray]}>Hjemmelshaver</Text>
                          <Text style={styles.col}>
                            {owner?.name ?? " - "} ({" "}
                            {owner?.ownerShare?.numerator ?? "-"}/
                            {owner?.ownerShare?.denominator ?? "-"} )
                          </Text>
                        </View>
                    ))}
                    {item?.propertyOwner?.length === 0 && (
                        <View style={styles.list}>
                          <Text style={[styles.col, styles.bgGray]}>Hjemmelshaver</Text>
                          <Text style={styles.col}>-</Text>
                        </View>
                    )}
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Kjøpt bolig for</Text>
                      <Text style={styles.col}>
                        {format(item?.propertyEvEstimateBasis?.valuation?.price)}{" "}kr
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Fellesgjeld</Text>
                      <Text style={styles.col}>
                        {format(
                            item?.propertyEvEstimateBasis?.valuation?.commonDebt
                        )}{" "}
                        kr
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Totalt boligverdi</Text>
                      <Text style={styles.col}>
                        {format(
                            item?.propertyEvEstimateBasis?.valuation?.totalValue
                        )}{" "}
                        kr
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Kjøpt eiendom</Text>
                      <Text style={styles.col}>
                        {item?.propertyEvEstimateBasis?.valuation?.date &&
                        item?.propertyEvEstimateBasis?.valuation?.date !== "" &&
                        !item?.propertyEvEstimateBasis?.valuation?.date?.includes(
                            "0001-01-01"
                        )
                            ? moment(
                                item?.propertyEvEstimateBasis?.valuation?.date
                            ).format("DD/MM/YYYY")
                            : "-"}{" "}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Steget i verdi siden kjøpt</Text>
                      <Text style={styles.col}>
                        {format(
                            (
                                (((isNaN(
                                            Number(
                                                item?.marketeEstimates?.approvedNetMarketValue
                                            )
                                        )
                                            ? 0
                                            : Number(
                                                item?.marketeEstimates?.approvedNetMarketValue
                                            )) +
                                        (isNaN(
                                            item?.propertyEvEstimateBasis?.currentCommondebt
                                        )
                                            ? 0
                                            : Number(
                                                item?.propertyEvEstimateBasis?.currentCommondebt
                                            )) -
                                        (isNaN(
                                            Number(
                                                item?.propertyEvEstimateBasis?.valuation
                                                    ?.totalValue
                                            )
                                        )
                                            ? 0
                                            : Number(
                                                item?.propertyEvEstimateBasis?.valuation
                                                    ?.totalValue
                                            ))) /
                                    (isNaN(
                                        Number(
                                            item?.propertyEvEstimateBasis?.valuation
                                                ?.totalValue
                                        )
                                    )
                                        ? 0
                                        : Number(
                                            item?.propertyEvEstimateBasis?.valuation
                                                ?.totalValue
                                        ))) *
                                100
                            ).toFixed(2)
                        )}
                        %
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Boligtype</Text>
                      <Text style={styles.col}>
                        {item?.propertyAttribs?.attributes?.estateSubType ??
                            "-"}{" "}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Boligstrørrelse (bruksareal)</Text>
                      <Text style={styles.col}>
                        {item?.propertyAttribs?.attributes?.primaryArea
                            ?.value
                            ? item?.propertyAttribs?.attributes?.primaryArea
                            ?.value + " kvm"
                            : "-"}{" "}
                      </Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Etasje</Text>
                      <Text style={styles.col}>
                        {item?.propertyAttribs?.attributes?.floor
                            ? item?.propertyAttribs?.attributes?.floor + " etg"
                            : "-"}{" "}</Text>
                    </View>
                    {item?.propertyAttribs?.housingCooperativeShareIdentity
                        ?.housingCooperative?.organizationNumber &&
                    item?.propertyAttribs?.housingCooperativeShareIdentity
                        ?.shareNumber ? (
                        <>
                          <View style={styles.list}>
                            <Text style={[styles.col, styles.bgGray]}>Organisasjonsnummer</Text>
                            <Text style={styles.col}>
                              {item?.propertyAttribs
                                      ?.housingCooperativeShareIdentity
                                      ?.housingCooperative?.organizationNumber ??
                                  "-"}
                            </Text>
                          </View>
                          <View style={styles.list}>
                            <Text style={[styles.col, styles.bgGray]}>Borretslag</Text>
                            <Text style={styles.col}>
                              {item?.propertyAttribs
                                  ?.housingCooperativeShareIdentity
                                  ?.housingCooperative?.name ?? "-"}
                            </Text>
                          </View>
                          <View style={styles.list}>
                            <Text style={[styles.col, styles.bgGray]}>Andel</Text>
                            <Text style={styles.col}>
                              {item?.propertyAttribs
                                  ?.housingCooperativeShareIdentity
                                  ?.shareNumber ?? "-"}
                            </Text>
                          </View>
                        </>
                    ) : (
                        <View style={styles.list}>
                          <Text style={[styles.col, styles.bgGray]}>Matrikkel</Text>
                          <Text style={styles.col}>
                            {item?.propertyAttribs?.cadastre?.kNr ?? "-"}/
                            {item?.propertyAttribs?.cadastre?.gNr ?? "-"}/
                            {item?.propertyAttribs?.cadastre?.bNr ?? "-"}/
                            {item?.propertyAttribs?.cadastre?.fNr ?? "-"}/
                            {item?.propertyAttribs?.cadastre?.sNr ?? "-"}{" "}
                          </Text>
                        </View>
                    )}
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Byggeår</Text>
                      <Text style={styles.col}>{item?.propertyAttribs?.attributes?.buildYear ?? "-"}{" "}</Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Kommune</Text>
                      <Text style={styles.col}>{item?.propertyAttribs?.address?.postOffice?.name}</Text>
                    </View>
                    <View style={styles.list}>
                      <Text style={[styles.col, styles.bgGray]}>Eieform</Text>
                      <Text style={styles.col}>{item?.propertyAttribs?.attributes?.realEstateOwnership ?? "-"}</Text>
                    </View>
                  </View>
                </View>
              </View>
            </InnerPageLayout>
        ))}

      </React.Fragment>
  );
};

export default HouseValueList;
