import React from 'react';
import {useLocation} from "react-router-dom";
import bannerData from "./bannerData";
import BusinesStaticPageBanner from "../../../Components/Banner/BusinesStaticPageBanner";
import ColSection from "../../../Components/ColSection/ColSectionStatic";
import data from "./ColSectionData"
import AdSectionRightImg from "../../../Components/AdSectionRightImg";
import adSectionRightImgData from "./adSectionRightImgData";
import contentWithImgData from "./ContentWithImgData";
import StaticContentWithImg from "../../../Components/ContentWithImage/StaticContentWithImg";
import tagList from "../tagList";
import StaticTagList from "../../../Components/TagList/StaticTagList";
import callToActionData from "./callToActionData";
import CallToAction from "../../../Components/CallToAction";
import {MetaTags} from "react-meta-tags";
import pageImg from "../../../Images/imgA02.svg";




const DoesYourBusinessNeedBost = () => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = decodedPathname === '/bedrift/trenger-din-bedrift-et-løft';

  return(
      <React.Fragment>
          <MetaTags>
              <title>Bedriftsforbedring: Invester i Nytt Utstyr og Utvid Lokalene Dine | Easyfinance.no</title>
              <meta name="description" content="Ønsker din bedrift et løft? Utforsk hvordan investering i nytt utstyr, utvidelse av lokaler, og styrking av daglige operasjoner kan transformere din virksomhet. Finn ut mer hos | Easyfinance.no" />
              <meta name="keywords" content="Bedriftsforbedring, invester i bedriften, utvide bedriften, øk lagerbeholdningen, styrk daglig drift" />
              <meta property="og:title" content="Bedriftsforbedring | Easyfinance.no" />
              <meta property="og:image" content={pageImg} />
          </MetaTags>
          <BusinesStaticPageBanner data={bannerData} isBusiness={isBusiness} vippsBtnHide />
          <ColSection detail={data}  />
          <AdSectionRightImg detail={adSectionRightImgData} />
          <StaticContentWithImg detail={contentWithImgData} />
          <StaticTagList data={tagList} />
          <CallToAction data={callToActionData} />
      </React.Fragment>
  )
}

export default DoesYourBusinessNeedBost;