import React from 'react';
import Banner from "../../../Components/Banner";
import FilterDictionary from "./FilterDictionary";


const Dictionary = () => {
  return(
      <React.Fragment>
          {/* <Banner
              title={
                  <>
                      <span className={'fw-normal'}>Lorem ipsum dolor </span>
                      <span className={'fw-semibold'}> sit amet</span>
                  </>
              }
              description={'Lorem ipsum dolor consectetur adipiscing tempor incididunt labore dolore magna aliqua. Sed augue lacus viverra vitae congue consequat felis.'}
          /> */}
          <FilterDictionary />

      </React.Fragment>
  )
}

export default Dictionary;