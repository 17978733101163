import img1 from '../../../Images/img65.svg';
import img2 from '../../../Images/img66.svg';
import img3 from '../../../Images/img67.svg';
import img4 from '../../../Images/img68.svg';

const tabsData = {
    sectionTitle: {
        textCenter: true,
        description: "Å ha tilgang til foretaksopplysninger gir deg en rimelig og enkel måte å unngå dyre kreditttap, samtidig som du får pålitelige data for beslutningstaking. Få oppdatert og omfattende kredittinformasjon om alle foretak.",
        titles: [
            { id: 1, title: "Kredittvurdering ", class: "fw-light" },
            { id: 2, title: "av foretak", class: "fw-samibold" }
        ]
    },
    tabs: [
        {
            key: "ScoreLimit",
            title: "Score & Limit",
            heading: "Score & Limit",
            description: <>
                <p>Forstå risikoen: Kredittscore gir en vurdering av hvor sannsynlig det er at et foretak går konkurs innen 12 måneder, rangert fra 1 til 100. Jo høyere score, jo lavere risiko. Vi anbefaler også et maksimalt kredittbeløp, kalt Limit, til enhver tid for å begrense risiko.</p>
                <p>Score og limit inngår i alle våre rapporter.</p>
            </>,
            checklist: [],
            imgSrc: img1,
            btnText: "",
            learnMoreUrl: "#"
        },
        {
            key: "Betalingsanmerkninger",
            title: "Betalingsanmerkninger",
            heading: "Betalingsanmerkninger",
            description: <>
                <p>Betalingshistorikk gir deg innsikt i et foretaks økonomiske situasjon. EasyFinance gir deg en oversikt over betalingsanmerkninger og frivillig pantstillelse, slik at du lettere kan avgjøre om du vil tilby kreditt, og hvilken risiko det innebærer.</p>
                <p>Sjekk betalingsanmerkninger for å identifisere dårlige betalere og opprettholde sunn likviditet.</p>
            </>,
            checklist: [],
            imgSrc: img2,
            learnMoreUrl: "#"
        },
        {
            key: "Rollehavere",
            title: "Rollehavere",
            heading: "Rollehavere",
            description: <>
                <p>Det er vanlig kjent at det er en tilknytning mellom et foretak og dets administrerende direktør. Derfor inkluderer vi informasjon om alle rollehavere i våre kredittopplysninger. Du får innsikt i hvem som er beslutningstakere i bedriften og hvilke roller de innehar.</p>
            </>,
            checklist: [],
            imgSrc: img3,
            learnMoreUrl: "#"
        },
        {
            key: "FinansiellInformasjon",
            title: "Finansiell informasjon",
            heading: "Finansiell informasjon",
            description: <>
                <p>Vi gjør beslutningsprosessen enklere ved å bruke score- og limitmodeller, men noen ganger trenger du mer detaljert innsikt. Derfor inkluderer vi alltid årsregnskap fra opptil de fem siste årene, nøkkeltall og revisors merknader.</p>
            </>,
            checklist: [],
            imgSrc: img4,
            learnMoreUrl: "#"
        }
    ]
};

export default tabsData;
