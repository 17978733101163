import React, {useState} from "react";
import classes from "./index.module.scss";
import {Dropdown, Form} from "react-bootstrap";
import SelectDropDown from "../SelectDropDown";
import { onError } from "Services/api/Image";


const FormInput = ({type, placeholder, icon, valueMax, fieldIcon, children, fieldLabel, tabIndex, placeHolder, options, variant, textlg, value, defaultValue, id, label, name, checked, onChange, toolTipTitle, toolTipDescription, img, filled, disabled, formatValue, numericOnly, textCenter}) => {
    const backgroundImageStyle = {
        backgroundImage: `url(${img})`, // Provide the URL directly here
        // Add other background-related styles if needed, e.g., backgroundSize, backgroundRepeat, etc.
    };
    const [selectedOption, setSelectedOption] = useState(null); // For single select, use 'null'; for multi-select, use an empty array '[]'
    const handleOptionChange = (newValue) => {
        // Do something with the selected option(s)
        setSelectedOption(newValue);
    };
    const formatter = Intl.NumberFormat('no-NO', {

    });
    const format = value => {
        const newValue = String(value)?.replaceAll(String.fromCharCode(160), '')?.replaceAll(' ', '');
        return formatter.format(newValue);
    }
    // const format = value => isNaN(value) ? 0 : formatter.format(value);
    return(
        <React.Fragment>

            {type === 'checkbox' || type === 'radio' ?
                (
                    <React.Fragment>
                        <Form.Group className={`${classes.formGroup} m-2`}>
                            {variant === 'radio' ? (
                                <React.Fragment>
                                    <div className={classes.radioBox}>
                                        <Form.Check>
                                            <Form.Check.Input
                                                type={type}
                                                label={label}
                                                id={id}
                                                name={name}
                                                value={value}
                                                checked={checked}
                                                onChange={onChange}
                                                tabIndex={tabIndex}
                                            />
                                            <Form.Check.Label htmlFor={id}>
                                                {img && <div className={classes.iconBox}><img src={img} loading="lazy" onError={onError}/></div>}
                                                <span>{label}</span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </React.Fragment>
                            )  : variant === 'switch' ? (
                                <React.Fragment>
                                    <div className={classes.boxSwitch}>
                                        <Form.Check>
                                            <Form.Check.Input
                                                type={type}
                                                label={label}
                                                id={id}
                                                name={name}
                                                value={value}
                                                checked={checked}
                                                onChange={onChange}
                                                tabIndex={tabIndex}
                                            />
                                            <Form.Check.Label htmlFor={id}>
                                                {img && <div className={classes.iconBox}><img src={img} loading="lazy" onError={onError}/></div>}
                                                <span>{label}</span>
                                            </Form.Check.Label>
                                        </Form.Check>
                                    </div>
                                </React.Fragment>
                            ) : variant === 'checkboxSwitch' ? (
                                <React.Fragment>
                                    <Form.Check className={`form-switch ${classes.formSwitch}`}>
                                        <Form.Check.Input
                                            type={type}
                                            label={label}
                                            id={id}
                                            name={name}
                                            value={value}
                                            checked={checked}
                                            onChange={onChange}
                                            tabIndex={tabIndex}
                                        />
                                        <Form.Check.Label htmlFor={id}>
                                            <span>{label}</span>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </React.Fragment>
                            ) :  (
                                <React.Fragment>
                                    {fieldLabel && <Form.Label>{fieldLabel}</Form.Label>}
                                    <Form.Check
                                        className={classes.checkbox}
                                        type={type}
                                        label={label}
                                        id={id}
                                        name={name}
                                        value={value}
                                        checked={checked}
                                        onChange={onChange}
                                        tabIndex={tabIndex}
                                    />
                                </React.Fragment>
                            )}
                        </Form.Group>
                    </React.Fragment>
                ) : type === 'textarea' ? ( // Adding the condition for textarea
                    <React.Fragment>
                        <Form.Group className={classes.formGroup}>
                            {fieldLabel &&
                                <Form.Label className={'font-13 mb-1 form-label'}>
                                    {fieldLabel}
                                    {toolTipDescription &&
                                        <div className={classes.toolTip}>
                                            <i className={'fal fa-question'}></i>
                                            <div className={classes.toolTipContent}>
                                                <div><b>{toolTipTitle}</b></div>
                                                {toolTipDescription}
                                            </div>
                                        </div>
                                    }
                                </Form.Label>
                            }
                            <div className={classes.inputHolder}>
                                <Form.Control
                                    id={id}
                                    name={name}
                                    as="textarea" // Render as a textarea
                                    rows={4} // Specify the number of rows for the textarea
                                    placeholder={placeholder}
                                    tabIndex={tabIndex}
                                    onChange={onChange}
                                />
                                {icon && <i className={classes.icon}>{icon}</i>}
                            </div>
                        </Form.Group>
                    </React.Fragment>
                ) : type === 'select' ? (
                    <React.Fragment>
                        <Form.Group className={classes.formGroup}>
                            {fieldLabel &&
                                <Form.Label className={'font-13 mb-1 form-label'}>
                                    {fieldLabel}
                                    {toolTipDescription &&
                                        <div className={classes.toolTip}>
                                            <i className={'fal fa-question'}></i>
                                            <div className={classes.toolTipContent}>
                                                <div><b>{toolTipTitle}</b></div>
                                                {toolTipDescription}
                                            </div>
                                        </div>
                                    }
                                </Form.Label>
                            }
                            <div className={classes.inputHolder}>
                                <SelectDropDown
                                    placeHolder={placeHolder}
                                    options={options}
                                    onChange={onChange}
                                    value={value}
                                    tabIndex={tabIndex}
                                />
                                {icon && <i className={classes.icon}>{icon}</i>}
                            </div>
                        </Form.Group>
                    </React.Fragment>
                ) : type === 'dropdown' ? (
                    <React.Fragment>
                        <Form.Group className={classes.formGroup}>
                            {fieldLabel &&
                                <Form.Label className={'font-13 mb-1 form-label'}>
                                    {fieldLabel}
                                    {toolTipDescription &&
                                        <div className={classes.toolTip}>
                                            <i className={'fal fa-question'}></i>
                                            <div className={classes.toolTipContent}>
                                                <div><b>{toolTipTitle}</b></div>
                                                {toolTipDescription}
                                            </div>
                                        </div>
                                    }
                                </Form.Label>
                            }
                            <div className={classes.inputHolder}>
                                {icon && <i className={classes.icon}>{icon}</i>}
                                <Dropdown className={classes.formSelect}>
                                    <Dropdown.Toggle className={classes.btn}>
                                        {placeHolder}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className={classes.menu}>
                                        {children}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Form.Group>
                    </React.Fragment>
                ) :(
                    <React.Fragment>
                        <Form.Group className={classes.formGroup}>
                            {fieldLabel &&
                                <Form.Label className={'font-13 mb-1'}>
                                    {fieldLabel}
                                    {toolTipDescription &&
                                        <div className={classes.toolTip}>
                                            <i className={'fal fa-question'}></i>
                                            <div className={classes.toolTipContent}>
                                                <div><b>{toolTipTitle}</b></div>
                                                {toolTipDescription}
                                            </div>
                                        </div>
                                    }
                                </Form.Label>
                            }
                            <div className={`
                                ${classes.inputHolder} 
                                ${fieldIcon && `${classes.fieldIconInput}`}
                                ${textCenter && classes.center}
                                `}>
                                <Form.Control
                                    id={id}
                                    name={name}
                                    type={type}
                                    disabled={disabled}
                                    placeholder={placeholder}
                                    defaultValue={defaultValue}
                                    tabIndex={tabIndex}
                                    onKeyDown={e => {
                                        if(numericOnly){
                                            if(['Control', 'Backspace', 'ArrowLeft', 'ArrowRight'].includes(e.key)) return;
                                            if(/[a-z]/.test(e.key) && !e.ctrlKey) {
                                                e.preventDefault();
                                            }
                                            if(/[0-9]/.test(e.key) && value + e.key > valueMax)
                                                e.preventDefault();
                                        }
                                    }}
                                    style={textlg ? { fontSize: '22px', fontWeight: '600', color: '#003341' } : {}}
                                    onChange={e => onChange({target: {value: e.target.value?.replaceAll(String.fromCharCode(160), '')?.replaceAll(' ', '')}})}
                                    value={formatValue ? format(value) : value}
                                />
                                {icon && <i className={classes.icon}>{icon}</i>}
                                {fieldIcon && <i className={`${fieldIcon} ${classes.fieldIcon}`}></i> }
                            </div>
                        </Form.Group>
                    </React.Fragment>
                )
            }
        </React.Fragment>
    )
}

export default FormInput;