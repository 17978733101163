import React, { useEffect, useRef, useState } from "react";
import classes from "./index.module.scss";
import HouseValueChart from "../../../../../Components/Charts/HouseValueChart";
import { Row, Tabs, Tab, Nav, Col, Spinner } from "react-bootstrap";
import HouseValueSplineChart from "../../../../../Components/Charts/HouseValueSplineChart";
import electricityImg from "../../../../../Images/electricity.svg";
import {
  getEnergyConsumption,
  getMarketeEstimate,
  getPropertyAttributes,
  getPropertyEvEstimateBasis,
  getPropertyOwners,
  getRealEstateLog,
  saveRealEstateLog,
} from "../../../../../Services/api/RealEstate";
import moment from "moment/moment";
import { loadGjeldsregisteretData } from "../../../../../Services/api/Loan";

const HouseList = ({ data }) => {
  const sidebarRef = useRef(null);
  // const [tabData, setTabData] = useState({});
  const [loadingTabData, setLoadingTabData] = useState(false);
  const [marketeEstimate, setMarketeEstimate] = useState(null);
  const [propertyAttributes, setPropertyAttributes] = useState(null);
  const [propertyOwners, setPropertyOwners] = useState(null);
  const [propertyEstimateBasis, setPropertyEstimateBasis] = useState(null);
  const [activeAddress, setActiveAddress] = useState(0);
  const [energyConsumption, setEnergyConsumption] = useState(null);
  const [logData, setLogData] = useState([]);

  const [approvedCredit, setApprovedCredit] = useState(0);
  const [approvedLoan, setApprovedLoan] = useState(0);

  const colWidth = 255; // Set the width of your column here

  const scrollNext = () => {
    if (sidebarRef.current) {
      const currentScroll = sidebarRef.current.scrollLeft;
      sidebarRef.current.scrollTo({
        left: currentScroll + colWidth,
        behavior: "smooth",
      });
    }
  };

  const scrollPrevious = () => {
    if (sidebarRef.current) {
      const currentScroll = sidebarRef.current.scrollLeft;
      sidebarRef.current.scrollTo({
        left: currentScroll - colWidth,
        behavior: "smooth",
      });
    }
  };
  const stringifyNulls = (str) => {
    return str && str !== "" ? str : "";
  };
  const mergeLetterAndStreetNumber = (letter, streetNumber) => {
    if (letter && letter !== "")
      return `${stringifyNulls(streetNumber)} ${stringifyNulls(letter)}`;
    else return stringifyNulls(streetNumber);
  };

  useEffect(() => {
    setLoadingTabData(true);
    async function fetchData() {
      if (!data || data?.length === 0) return;
      const currentAddress = data[activeAddress];
      if (!currentAddress) return;
      const filterObj = {
        organizationNumber:
          currentAddress?.housingCooperativeShareIdentity?.housingCooperative
            ?.organizationNumber,
        shareNumber:
          currentAddress?.housingCooperativeShareIdentity?.shareNumber,
        knr: currentAddress?.cadastre?.kNr,
        gnr: currentAddress?.cadastre?.gNr,
        bnr: currentAddress?.cadastre?.bNr,
        fnr: currentAddress?.cadastre?.fNr,
        snr: currentAddress?.cadastre?.sNr,
      };
      const [
        marketeEstimates,
        propertyAttribs,
        propertyOwner,
        propertyEvEstimateBasis,
      ] = await Promise.all([
        getMarketeEstimate(filterObj)
          .then(({ data }) => data)
          .catch((err) => null),
        getPropertyAttributes(filterObj)
          .then(({ data }) => data)
          .catch(() => null),
        getPropertyOwners(filterObj)
          .then(({ data }) => data)
          .catch(() => null),
        getPropertyEvEstimateBasis(filterObj)
          .then(({ data }) => data)
          .catch((err) => null),
        getEnergyConsumption(filterObj)
          .then(({ data }) => setEnergyConsumption(data))
          .catch((err) => setEnergyConsumption(null)),
        _getTotalDebt(),
      ])
        .catch(() => {})
        .finally(() => setLoadingTabData(false));

      setMarketeEstimate(marketeEstimates);
      setPropertyAttributes(propertyAttribs);
      setPropertyOwners(propertyOwner);
      setPropertyEstimateBasis(propertyEvEstimateBasis);
      try {
        await saveRealEstateLog(filterObj, {
          marketeEstimate: marketeEstimates,
          propertyAttributes: propertyAttribs,
          propertyOwners: propertyOwner,
          propertyEstimateBasis: propertyEvEstimateBasis,
        });
        const resLog = await getRealEstateLog(filterObj);
        setLogData(resLog);
      } catch (ex) {
        console.log("AN ERROR OCCURED WHILE SAVING REAL ESTATE LOG: ", ex);
      }
    }
    fetchData();
    // eslint-disable-next-line
  }, [activeAddress]);

  const _getTotalDebt = async () => {
    const resp = await loadGjeldsregisteretData();
    const debt = resp?.debtInformation;
    const loans = debt?.loanTypes
      ?.filter(({ type }) => type === "repaymentLoan")
      .flatMap(({ loans }) => loans);
    const cards = debt?.loanTypes
      ?.filter(({ type }) => type === "chargeCard" || type === "creditFacility")
      .flatMap(({ loans }) => loans);
    const originalBalance = loans
      ?.map(({ balance }) => (isNaN(Number(balance)) ? 0 : Number(balance)))
      ?.reduce((a, b) => a + b, 0);
    const totalCredit = cards
      ?.map(
        ({ nonInterestBearingBalance, interestBearingBalance }) =>
          (isNaN(interestBearingBalance) ? 0 : Number(interestBearingBalance)) +
          (isNaN(nonInterestBearingBalance)
            ? 0
            : Number(nonInterestBearingBalance))
      )
      ?.reduce((a, b) => a + b, 0);
    setApprovedCredit(Math.round(totalCredit / 100));
    setApprovedLoan(Math.round(originalBalance / 100));
  };
  const format = (value) => {
    const formatter = Intl.NumberFormat("no-NO");
    if (isNaN(value) || !isFinite(value)) return 0;
    else return formatter.format(value);
  };

  return (
    <React.Fragment>
      <div className={classes.houseListHolder}>
        <Tab.Container
          id="left-tabs-example"
          activeKey={activeAddress}
          onSelect={(id) => setActiveAddress(id)}
        >
          <div className={classes.tabNavHolder}>
            <div
              className={`${classes.arrowButton} ${classes.prev}`}
              onClick={scrollPrevious}
            >
              <i className={"fas fa-angle-left"}></i>
            </div>
            <div
              className={`${classes.arrowButton} ${classes.next}`}
              onClick={scrollNext}
            >
              <i className={"fas fa-angle-right"}></i>
            </div>
            <Nav variant="pills" ref={sidebarRef} className={classes.tabs}>
              {data?.map(({ address }, index) => {
                return (
                  <Nav.Item style={{ minWidth: colWidth }} key={index}>
                    <Nav.Link eventKey={index}>
                      <span className={classes.iconBox}>
                        <i className={"fas fa-home"}></i>
                      </span>
                      {stringifyNulls(address?.streetName)}{" "}
                      {mergeLetterAndStreetNumber(
                        address?.streetLetter,
                        address?.streetNumber
                      )}
                      ,<br />
                      {stringifyNulls(
                        address?.postOffice?.code?.padStart(4, "0")
                      )}{" "}
                      {stringifyNulls(address?.postOffice?.name)}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
            </Nav>
          </div>
          {loadingTabData ? (
            <Spinner />
          ) : (
            <Tab.Content>
              <Row className={classes.houseList}>
                <div className={classes.col}>
                  <div className={classes.box}>
                    <HouseValueChart
                      estimate={
                        marketeEstimate &&
                        marketeEstimate?.approvedNetMarketValue
                      }
                      totalLoan={approvedCredit + approvedLoan}
                      totalLoanLimit={
                        (marketeEstimate?.approvedNetMarketValue ?? 0) * 0.85
                      }
                      commonDebt={propertyEstimateBasis?.currentCommondebt}
                    />
                  </div>
                </div>
                <div className={classes.col}>
                  <div className={`${classes.box} mb-3`}>
                    <HouseValueSplineChart
                      log={logData}
                      seriesFirst={propertyEstimateBasis?.valuation?.totalValue}
                      seriesFirstCategory={
                        propertyEstimateBasis?.valuation?.date
                      }
                    />
                  </div>
                  <div
                    className={`${classes.box} p-0`}
                    style={{ boxShadow: "none" }}
                  >
                    <Tabs defaultActiveKey={"tab1"} justify>
                      <Tab eventKey={"tab1"} title={"Oversikt"}>
                        <ul className={classes.dataList}>
                          <li>
                            {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/EvEstimate Endpoint for retrieving EvEstimate based on a cadastre. Request body overrides information from Eiendomsverdi*/}
                            <div className={classes.col}>
                              Estimert boligverdi
                            </div>
                            <div className={classes.col}>
                              {marketeEstimate
                                ? format(
                                    marketeEstimate?.approvedNetMarketValue
                                  ) + " kr"
                                : "-"}
                            </div>
                          </li>
                          <li>
                            {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/EvEstimate Endpoint for retrieving EvEstimate based on a cadastre. Request body overrides information from Eiendomsverdi*/}
                            <div className={classes.col}>Min/maks pris</div>
                            <div className={classes.col}>
                              {format(
                                marketeEstimate?.approvedNetMarketValue -
                                  marketeEstimate?.approvedNetMarketValue * 0.05
                              ) + " kr"}{" "}
                              -{" "}
                              {format(
                                Number(
                                  marketeEstimate?.approvedNetMarketValue
                                ) +
                                  marketeEstimate?.approvedNetMarketValue * 0.05
                              ) + " kr"}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Fellesgjeld idag</div>
                            <div className={classes.col}>
                              {format(propertyEstimateBasis?.currentCommondebt)}{" "}
                              kr
                              {/* <a href={" "} className={'text-blue'}>Endre</a> */}
                            </div>
                          </li>
                          <li>
                            {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/EvEstimate Endpoint for retrieving EvEstimate based on a cadastre. Request body overrides information from Eiendomsverdi*/}
                            {/*/RealEstates/{organizationNumber}/{shareNumber}/attributes Endpoint for retrieving information about a housing cooperative share. The share is identified by an organization number and a share number.*/}
                            <div className={classes.col}>Kvadratmeter pris</div>
                            <div className={classes.col}>
                              {format(
                                (
                                  Number(
                                    marketeEstimate?.approvedNetMarketValue
                                  ) /
                                  Number(
                                    propertyAttributes?.attributes?.primaryArea
                                      ?.value
                                  )
                                ).toFixed(0)
                              )}{" "}
                              kr
                            </div>
                          </li>
                          <li>
                            {/*/RealEstates/{organizationNumber}/{shareNumber}/attributes Endpoint for retrieving information about a housing cooperative share. The share is identified by an organization number and a share number.*/}
                            {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/attributes Endpoint for retrieving information about a property. The property is identitied by the complete cadastre.*/}
                            <div className={classes.col}>Kommune</div>
                            <div className={classes.col}>
                              {propertyAttributes?.address?.municipality ?? "-"}
                            </div>
                          </li>
                          <li>
                            {/*/RealEstates/{organizationNumber}/{shareNumber}/attributes Endpoint for retrieving information about a housing cooperative share. The share is identified by an organization number and a share number.*/}
                            {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/attributes Endpoint for retrieving information about a property. The property is identitied by the complete cadastre.*/}
                            <div className={classes.col}>Bydel</div>
                            <div className={classes.col}>
                              {propertyAttributes?.cityDistrict ?? "-"}
                            </div>
                          </li>
                          <li>
                            {/*/RealEstates/{organizationNumber}/{shareNumber}/attributes Endpoint for retrieving information about a housing cooperative share. The share is identified by an organization number and a share number.*/}
                            {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/attributes Endpoint for retrieving information about a property. The property is identitied by the complete cadastre.*/}
                            <div className={classes.col}>Område score</div>
                            <div className={classes.col}>
                              {propertyAttributes?.marketScore?.area ?? "-"} av
                              20
                            </div>
                          </li>
                        </ul>
                      </Tab>
                      <Tab eventKey={"tab2"} title={"Nøkkelinfo"}>
                        <ul className={classes.dataList}>
                          {/*/RealEstates/{organizationNumber}/{shareNumber}/owners Endpoint for retrieving information about owners registered on a given housing cooperative share. The share is identified by an organization number and a share number.*/}
                          {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/owners Endpoint for retrieving information about owners registered on a given property. The property is identified by the complete cadastre.*/}
                          {propertyOwners?.map(({ owner }) => (
                            <li>
                              <div className={classes.col}>Hjemmelshaver</div>
                              <div className={classes.col}>
                                {owner?.name ?? "-"} ({" "}
                                {owner?.ownerShare?.numerator ?? "-"}/
                                {owner?.ownerShare?.denominator ?? "-"} )
                              </div>
                            </li>
                          ))}
                          {propertyOwners?.length === 0 && (
                            <li>
                              <div className={classes.col}>Hjemmelshaver</div>
                              <div className={classes.col}>-</div>
                            </li>
                          )}
                          {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/EvEstimateBasis Endpoint for retriving an estimate basis based on a cadastre*/}
                          {/*/RealEstates/{organizationNumber}/{shareNumber}/EvEstimateBasis Endpoint for retrieving market estimate based on a organization number and share number.*/}
                          <li>
                            <div className={classes.col}>Kjøpt bolig for</div>
                            <div className={classes.col}>
                              {format(propertyEstimateBasis?.valuation?.price)}{" "}
                              kr
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Fellesgjeld</div>
                            <div className={classes.col}>
                              {format(
                                propertyEstimateBasis?.valuation?.commonDebt
                              )}{" "}
                              kr
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Totalt boligverdi</div>
                            <div className={classes.col}>
                              {format(
                                propertyEstimateBasis?.valuation?.totalValue
                              )}{" "}
                              kr
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Kjøpt eiendom</div>
                            <div className={classes.col}>
                              {propertyEstimateBasis?.valuation?.date &&
                              propertyEstimateBasis?.valuation?.date !== "" &&
                              !propertyEstimateBasis?.valuation?.date?.includes(
                                "0001-01-01"
                              )
                                ? moment(
                                    propertyEstimateBasis?.valuation?.date
                                  ).format("DD/MM/YYYY")
                                : "-"}{" "}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>
                              Steget i verdi siden kjøpt
                            </div>
                            <div className={classes.col}>
                              {format(
                                (
                                  (((isNaN(
                                    Number(
                                      marketeEstimate?.approvedNetMarketValue
                                    )
                                  )
                                    ? 0
                                    : Number(
                                        marketeEstimate?.approvedNetMarketValue
                                      )) +
                                    (isNaN(
                                      propertyEstimateBasis?.currentCommondebt
                                    )
                                      ? 0
                                      : Number(
                                          propertyEstimateBasis?.currentCommondebt
                                        )) -
                                    (isNaN(
                                      Number(
                                        propertyEstimateBasis?.valuation
                                          ?.totalValue
                                      )
                                    )
                                      ? 0
                                      : Number(
                                          propertyEstimateBasis?.valuation
                                            ?.totalValue
                                        ))) /
                                    (isNaN(
                                      Number(
                                        propertyEstimateBasis?.valuation
                                          ?.totalValue
                                      )
                                    )
                                      ? 0
                                      : Number(
                                          propertyEstimateBasis?.valuation
                                            ?.totalValue
                                        ))) *
                                  100
                                ).toFixed(2)
                              )}
                              %
                              {/* {(((Number(marketeEstimate?.approvedNetMarketValue) - Number(propertyEstimateBasis?.valuation?.totalValue)) / ) * 100).toFixed(2)} */}
                            </div>
                          </li>
                          {/* <li>
                                                        <div className={classes.col}>Pengeheftelser</div>
                                                        <div className={classes.col}>Se pant<a href={" "}
                                                                                               className={'text-blue'}>Download</a>
                                                        </div>
                                                    </li> */}

                          {/*/RealEstates/{organizationNumber}/{shareNumber}/attributes Endpoint for retrieving information about a housing cooperative share. The share is identified by an organization number and a share number.*/}
                          {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/attributes Endpoint for retrieving information about a property. The property is identitied by the complete cadastre.*/}
                          <li>
                            <div className={classes.col}>Boligtype</div>
                            <div className={classes.col}>
                              {propertyAttributes?.attributes?.estateSubType ??
                                "-"}{" "}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>
                              Boligstrørrelse (bruksareal)
                            </div>
                            <div className={classes.col}>
                              {propertyAttributes?.attributes?.primaryArea
                                ?.value
                                ? propertyAttributes?.attributes?.primaryArea
                                    ?.value + " kvm"
                                : "-"}{" "}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Etasje</div>
                            <div className={classes.col}>
                              {propertyAttributes?.attributes?.floor
                                ? propertyAttributes?.attributes?.floor + " etg"
                                : "-"}{" "}
                            </div>
                          </li>
                          {propertyAttributes?.housingCooperativeShareIdentity
                            ?.housingCooperative?.organizationNumber &&
                          propertyAttributes?.housingCooperativeShareIdentity
                            ?.shareNumber ? (
                            <>
                              <li>
                                <div className={classes.col}>
                                  Organisasjonsnummer
                                </div>
                                <div className={classes.col}>
                                  {propertyAttributes
                                    ?.housingCooperativeShareIdentity
                                    ?.housingCooperative?.organizationNumber ??
                                    "-"}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Borretslag</div>
                                <div className={classes.col}>
                                  {propertyAttributes
                                    ?.housingCooperativeShareIdentity
                                    ?.housingCooperative?.name ?? "-"}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Andel</div>
                                <div className={classes.col}>
                                  {propertyAttributes
                                    ?.housingCooperativeShareIdentity
                                    ?.shareNumber ?? "-"}
                                </div>
                              </li>
                            </>
                          ) : (
                            <li>
                              <div className={classes.col}>
                                Matrikkel
                              </div>
                              <div className={classes.col}>
                                {propertyAttributes?.cadastre?.kNr ?? "-"}/
                                {propertyAttributes?.cadastre?.gNr ?? "-"}/
                                {propertyAttributes?.cadastre?.bNr ?? "-"}/
                                {propertyAttributes?.cadastre?.fNr ?? "-"}/
                                {propertyAttributes?.cadastre?.sNr ?? "-"}{" "}
                              </div>
                            </li>
                          )}
                          <li>
                            <div className={classes.col}>Byggeår</div>
                            <div className={classes.col}>
                              {propertyAttributes?.attributes?.buildYear ?? "-"}{" "}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Kommune</div>
                            <div className={classes.col}>
                              {/*{propertyAttributes?.address?.postOffice?.code}*/}{" "}
                              {propertyAttributes?.address?.postOffice?.name}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Eieform</div>
                            <div className={classes.col}>
                              {propertyAttributes?.attributes
                                ?.realEstateOwnership ?? "-"}
                            </div>
                          </li>
                        </ul>
                      </Tab>
                      <Tab eventKey={"tab3"} title={"Energiforbruk"}>
                        {/*/Energy/Estimate Endpoint to get estimated energy data like savings and usage, based on data about the estate like living area, location, household information*/}
                        <ul className={classes.dataList}>
                          <li>
                            <div className={classes.col}>
                              {" "}
                              Årlig energikostnad
                            </div>
                            <div className={classes.col}>
                              {format(energyConsumption?.presentEnergyCost)} kr
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Årlig energibruk</div>
                            <div className={classes.col}>
                              {format(
                                energyConsumption?.presentEnergyConsumption
                              )}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>Energikarakter</div>
                            <div className={classes.col}>
                              {energyConsumption?.presentEnergyRatingScale &&
                              energyConsumption?.presentEnergyRatingScale !== ""
                                ? energyConsumption?.presentEnergyRatingScale
                                : "-"}
                            </div>
                          </li>
                          <li>
                            <div className={classes.col}>CO2/år</div>
                            <div className={classes.col}>
                              {energyConsumption?.presentCO2Emissions &&
                              energyConsumption?.presentCO2Emissions !== ""
                                ? energyConsumption?.presentCO2Emissions
                                : "-"}
                            </div>
                          </li>
                        </ul>
                        <Row className={"py-3 align-items-center"}>
                          <Col md={6} className={"mb-3"}>
                            <h4>Estimert energikostnad hvert hver måned</h4>
                            <div
                              className={
                                "bg-white text-center border p-2 overflow-hidden"
                              }
                              style={{ borderRadius: "5px" }}
                            >
                              {format(
                                (
                                  energyConsumption?.presentEnergyCost / 12
                                ).toFixed(2)
                              )}{" "}
                              kr
                            </div>
                          </Col>
                          <Col md={6} className={"text-right mb-3"}>
                            <img src={electricityImg} loading="lazy" alt="Electricity Img" />
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </Row>
            </Tab.Content>
          )}
        </Tab.Container>
      </div>
    </React.Fragment>
  );
};

export default HouseList;
