import React, {useState, useEffect} from 'react';
import classes from './index.module.scss';
import Button from "../../../../Components/Button";
import FormInput from "../../../../Components/FormInput";
import RecommendedCards from "./RecommendedCards";
import cardData from './data';
import SectionTitle from "../../../../Components/SectionTitle";
import {Col, Row} from "react-bootstrap";
import {getConsumerReport} from "../../../../Services/api/Auth";
import { loadGjeldsregisteretData } from '../../../../Services/api/Loan';


const BackButton = ({ onBack, url }) => (
    <div className={classes.backBtn}>
        <Button
            text={'Tilbake'}
            variant={' light'}
            icon={"fas fa-angle-left"}
            iconLeft
            {...(url ? { url } : { onClick: onBack })}
        />
    </div>
);


const WelCome = ({onNextStep}) => {

    const user = JSON.parse(localStorage.getItem('auth') ?? '{}');
    const handleYesClick = () => {
        onNextStep('paymentRemarks');
    };
    const handleNoClick = () => {
        onNextStep("/privat/betalingsanmerkninger");
    };
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton url={"/privat/betalingsanmerkninger"} />
                <h5>Hei {user?.firstName},</h5>
                <p>Det er flott å høre at du ønsker å ta tak i din økonomi. Vi forstår at det kan være utfordrende til tider, og det er ingen som ønsker å havne i en uoversiktlig økonomi.</p>
                <p>EasyFinance er her for å støtte deg med kunnskap innenfor personlig økonomi, slik at du kan ta velinformerte beslutninger.</p>
                <p>La oss begynne prosessen med å rydde opp.</p>
                <div><b>Er du klar?</b></div>
                <div className={classes.btnRow}>
                    <Button
                        text={'Nei'}
                        variant={'border'}
                        url={"/privat/betalingsanmerkninger"}
                    />
                    <Button
                        text={"Ja"}
                        onClick={handleYesClick}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const PaymentRemarks = ({data, onNextStep}) => {
    const format = (value, divideBy100 = false) => {
        const formatter = Intl.NumberFormat('no-NO');
        if(isNaN(value)) return '0';
        if(divideBy100) return formatter.format(value / 100);
        return formatter.format(value);
    };
    const totalRemarks = data?.paymentRemarkDetails?.remarksOfPayment?.length || 0;
    const totalAmount = data?.paymentRemarkDetails?.remarksOfPayment?.reduce((total, remark) => total + parseFloat(remark.amount || 0), 0);

    const handleYesClick = () => {
        onNextStep('unsecuredDebtYes');
    };
    const handleNoClick = () => {
        onNextStep('typeTheRightAmountPaymentRemarks');
    };
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton onBack={() => onNextStep(null)} />
                <p>Det ser ut som om du har <b>{format(totalAmount)} Kr</b> i betalingsanmerkninger.</p>
                <div><b>Stemmer det?</b></div>
                <div className={classes.btnRow}>
                    <Button
                        text={'Nei'}
                        variant={'border'}
                        onClick={handleNoClick}
                    />
                    <Button
                        text={"Ja"}
                        onClick={handleYesClick}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const UnSecuredDebtYes = ({data, onNextStep}) => {
    const [totalDebt, setTotalDebt] = useState();

    useEffect(() => {
        loadGjeldsregisteretData()
        .then(resp => {
            const debt = resp?.debtInformation;
            const loans = debt?.loanTypes?.filter(({ type }) => type === 'repaymentLoan').flatMap(({ loans }) => loans);
            const cards = debt?.loanTypes?.filter(({ type }) => type === 'chargeCard' || type === 'creditFacility').flatMap(({ loans }) => loans);
            const originalBalance = loans?.map(({ balance }) => isNaN(Number(balance)) ? 0 : Number(balance))?.reduce((a, b) => a + b, 0)
            const totalCredit = cards?.map(({ nonInterestBearingBalance, interestBearingBalance }) => (isNaN(interestBearingBalance) ? 0 : Number(interestBearingBalance)) + (isNaN(nonInterestBearingBalance) ? 0 : Number(nonInterestBearingBalance)))?.reduce((a, b) => a + b, 0);
            setTotalDebt(originalBalance + totalCredit);
        })
        .catch(() => {})
    }, []);
    const handleYesClick = () => {
        onNextStep('doYouOwnHome');
    };
    const handleNoClick = () => {
        onNextStep('typeTheRightAmountDebt');
    };
    const { format } = Intl.NumberFormat('no-NO')
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton onBack={() => onNextStep(null)} />
                <p>Det ser ut som du har <b>{format((isNaN(totalDebt) ? 0 : totalDebt) / 100)} kr</b> i usikret gjeld som forbrukslån og kredittkort.</p>
                <div><b>Stemmer det?</b></div>  
                <div className={classes.btnRow}>
                    <Button
                        text={'Nei'}
                        variant={'border'}
                        onClick={handleNoClick}
                    />
                    <Button
                        text={"Ja"}
                        onClick={handleYesClick}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const DoYouOwnHome = ({onNextStep}) => {
    const handleYesClick = () => {
        onNextStep('typeTheRightAmounHouseValue');
    };
    const handleNoClick = () => {
        onNextStep('askedSecuity');
    };
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton onBack={() => onNextStep("unsecuredDebtYes")} />
                <p>Eier du bolig?</p>
                <div className={classes.btnRow}>
                    <Button
                        text={'Nei'}
                        variant={'border'}
                        onClick={handleNoClick}
                    />
                    <Button
                        text={"Ja"}
                        onClick={handleYesClick}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const AskedSecuity = ({onNextStep}) => {
    const handleYesClick = () => {
        onNextStep('typeTheRightAmounHouseValue');
    };
    const handleNoClick = () => {
        onNextStep('askedSecuityNo');
    };

    return(
        <React.Fragment>
            <div className={classes.holder}>

                <p>Kjenner du noen som kan stille med sikkerhet i eiendom for deg?</p>
                <div className={classes.btnRow}>
                    <Button
                        text={'Nei'}
                        variant={'border'}
                        onClick={handleNoClick}
                    />
                    <Button
                        text={"Ja"}
                        onClick={handleYesClick}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const AskedSecuityNo = () => {

    return(
        <React.Fragment>
            <div className={classes.holder}>
               <div className={'text-left'}>
                   <p>For å kunne søke om Omstartslån, må du stille med sikkerhet.</p>
                   <p>Dette kan enten være ved å bruke din egen eiendom eller ved å få hjelp fra familie eller venner som er villige til å stille sin eiendom som sikkerhet for din gjeld.</p>
                   <p>Da du har svart nei på muligheten for å stille sikkerhet, kan vi dessverre ikke tilby bistand gjennom våre samarbeidende banker.</p>
               </div>
                <div className={classes.btnRow}>
                    <Button
                        text={'Tilbake'}
                        variant={'border'}
                        url={"/privat/betalingsanmerkninger"}
                    />
                    <Button
                        text={'Andre muligheter'}
                        url={"/nyttig-informasjon/trenger-du-hjelp"}
                        target={"_blank"}
                        variant={"link small"}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const TypeTheRightAmountPaymentRemarks = ({onNextStep}) => {
    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const handleYesClick = () => {
        onNextStep('unsecuredDebtYes');
    };
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton onBack={() => onNextStep(null)} />
                <p>Skriv inn riktig beløp for betalingsanmerkninger</p>
                <FormInput
                value={value}
                onChange={({ target }) => {
                    setValue(target.value);
                    setError(false);
                }}
                    type={'text'}
                />
                {error && <p className='text-danger'>Vennligst skriv inn en gyldig verdi</p>}
                <div className={classes.btnRow}>
                    <Button
                        text={"Neste"}
                        onClick={() => {
                            if(!value || value === '') setError(true);
                            else handleYesClick()
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

const TypeTheRightAmountDebt = ({onNextStep}) => {
    const [error, setError] = useState(false);
    const [value, setValue] = useState('');
    const handleYesClick = () => {
        onNextStep('doYouOwnHome');
    };
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton onBack={() => onNextStep("unsecuredDebtYes")} />
                <p>Skriv inn riktig beløp for gjeld</p>
                <FormInput
                    type={'text'}
                    onChange={({target}) => {
                        setError(false);
                        setValue(target.value)
                    }}
                    value={value}
                />
                {error && <p className='text-danger'>Vennligst skriv inn en gyldig verdi.</p>}
                <div className={classes.btnRow}>
                    <Button
                        text={"Neste"}
                        onClick={() => {
                            if(!value || value === '') setError(true);
                            else handleYesClick();
                        }}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}
const TypeTheRightAmounHouseValue = ({onNextStep}) => {
    const handleYesClick = () => {
        onNextStep('ourRecommendedCards');
    };
    return(
        <React.Fragment>
            <div className={classes.holder}>
                <BackButton onBack={() => onNextStep("doYouOwnHome")} />
                <p>Skriv inn riktig detalj for House</p>
                <div className={'text-left'}>
                    <FormInput
                        type={'text'}
                        fieldLabel={"Adresse"}
                    />
                    <FormInput
                        type={'text'}
                        fieldLabel={"Boliglån"}
                    />
                </div>

                <div className={classes.btnRow}>
                    <Button
                        text={"Neste"}
                        onClick={handleYesClick}
                    />
                </div>
            </div>
        </React.Fragment>
    )
}


const OurRecommendedCards = ({onNextStep}) => {
    return (
        <React.Fragment>
            <div className={classes.cardHolder}>
                <BackButton onBack={() => onNextStep("doYouOwnHome")} />
                <Row>
                    <Col md={6} className={"offset-md-3"}>
                        <SectionTitle textCenter description={"Basert på de opplysningene du har gitt, samt de vi har tilgjengelig, mener vi at disse bankene kan være best egnet til \n" +
                            "                    å hjelpe deg på en optimal måte. Her vil du motta personlig veiledning og rådgivning, samtidig som en profesjonell rådgiver \n" +
                            "                    vil sette seg grundig inn i din økonomiske situasjon og hjelpe deg med å løse gjeldsutfordringene dine på en effektiv måte."}>
                            <span className={'fw-normal'}>Våre</span>
                            <span className={'fw-samibold'}>anbefalinger</span>
                        </SectionTitle>
                    </Col>
                </Row>
                <RecommendedCards cardData={cardData} />
            </div>
        </React.Fragment>
    )
}


const QuestionnaireRecommendationSteps = () => {
    const [currentStep, setCurrentStep] = useState('welCome');
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        getConsumerReport()
            .then(setData)
            .finally(() => setLoading(false))
    }, [])


    const handleNextStep = (nextStep) => {
        setCurrentStep(nextStep);
    };



    let componentToRender;
    switch (currentStep) {

        case 'unsecuredDebtYes':
            componentToRender = <UnSecuredDebtYes onNextStep={handleNextStep} />;
            break;
        case 'typeTheRightAmountPaymentRemarks':
            componentToRender = <TypeTheRightAmountPaymentRemarks onNextStep={handleNextStep} />;
            break;
        case 'doYouOwnHome':
            componentToRender = <DoYouOwnHome onNextStep={handleNextStep} />;
            break;
        case 'ourRecommendedCards':
            componentToRender = <OurRecommendedCards onNextStep={handleNextStep} />;
            break;
        case 'typeTheRightAmountDebt':
            componentToRender = <TypeTheRightAmountDebt onNextStep={handleNextStep} />;
            break;
        case 'typeTheRightAmounHouseValue':
            componentToRender = <TypeTheRightAmounHouseValue onNextStep={handleNextStep} />;
            break;
        case 'askedSecuity':
            componentToRender = <AskedSecuity onNextStep={handleNextStep} />;
            break;
        case 'askedSecuityNo':
            componentToRender = <AskedSecuityNo onNextStep={handleNextStep} />;
            break;
        case 'paymentRemarks':
            componentToRender = <PaymentRemarks data={data} onNextStep={handleNextStep} />;
            break;
        default:
            componentToRender = <WelCome onNextStep={handleNextStep} />;
            break;
    }

  return(
      <React.Fragment>
         <div className={classes.boxHolder}>
             {componentToRender}
         </div>
          {/*<PaymentRemarks />*/}
          {/*<UnSecuredDebtYes />*/}
          {/*<DoYouOwnHome />*/}
          {/*<TypeTheRightAmountPaymentRemarks />*/}
          {/*<AskedSecuity />*/}
          {/*<AskedSecuityNo />*/}
          {/*<OurRecommendedCards />*/}
      </React.Fragment>
  )
}

export default QuestionnaireRecommendationSteps;