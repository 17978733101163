import React from 'react';
import classes from '../index.module.scss';
import {Accordion, Alert} from "react-bootstrap";

const CompanyBranches = ({ details }) => {
    return(
        <React.Fragment>
            <h5>Underavdelinger</h5>
            <Accordion defaultActiveKey="0Company">
                {details?.report?.additionalInformation?.branchOffices?.filter(office => office?.status?.toLowerCase() !== 'inactive').map((office, index) => <Accordion.Item eventKey={`${index}Company`} className={classes.borderRadius0}>
                    <Accordion.Header>
                        <span>{office?.companyName}</span>
                    </Accordion.Header>
                    <Accordion.Body className={'p-0'}>
                        <ul className={`${classes.dataList} ${classes.borderNone}`}>
                            <li>
                                <div className={classes.col}>Organisasjonsnummer</div>
                                <div className={classes.col}>{office?.registrationNumber}</div>
                            </li>
                            <li>
                                <div className={classes.col}>Bransje </div>
                                <div className={classes.col}>{office?.activityClassifications?.map(({ activities }) => activities?.map(activity => `${activity?.code} ${activity?.description}`))?.join(', ')} </div>
                            </li>
                            <li>
                                <div className={classes.col}>Ant. ansatte </div>
                                <div className={classes.col}>{office?.numberOfEmployees} </div>
                            </li>
                            <li>
                                <div className={classes.col}>Hovedadresse</div>
                                <div className={classes.col}>{office?.address?.simpleValue} </div>
                            </li>
                            <li>
                                <div className={classes.col}>Postnummer/Sted </div>
                                <div className={classes.col}>{`${office?.address?.postalCode ?? ''} ${office?.address?.city ?? ''}`} </div>
                            </li>
                            <li>
                                <div className={classes.col}>Telefon </div>
                                <div className={classes.col}>{office?.telephone && office?.telephone !== '' ? office?.telephone : '-'} </div>
                            </li>
                            <li>
                                <div className={classes.col}>Status </div>
                                <div className={classes.col}><span className={'text-success'}>Aktiv</span> </div>
                            </li>
                        </ul>
                    </Accordion.Body>
                </Accordion.Item>) }
            </Accordion>
            {!details?.report?.additionalInformation?.branchOffices || details?.report?.additionalInformation?.branchOffices?.length === 0 ?
            <Alert variant='warning'>Det er ingen firmafilialer tilgjengelig.</Alert>
            : null}
        </React.Fragment>
    )
}

export default CompanyBranches;