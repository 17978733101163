import React, { Fragment, useEffect, useState } from 'react';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Container } from "react-bootstrap";
import ScoreDocument from "./ScoreDocument";
import { getCurrentUser, getConsumerReport } from "../../../../Services/api/Auth";
import { loadGjeldsregisteretData } from "../../../../Services/api/Loan";
import { getCustomerRealEastate } from "../../../../Services/api/RealEstate";

const CreditCheckPDF = () => {
    const [userData, setUserData] = useState({ firstName: '', lastName: '', userName: '' });
    const [creditData, setCreditData] = useState({});
    const [totalDebt, setTotalDebt] = useState('');
    const [loading, setLoading] = useState(true);
    const [totalProperties, setTotalProperties] = useState();
    const [debtData, setDebtData] = useState(null);
    const [remainingConsumerLoan, setRemainingConsumerLoan] = useState(0);
    const [approvedConsumerLoan, setApprovedLoan] = useState(0);

    useEffect(() => {
        getCurrentUser().then(user => setUserData(user));
        getConsumerReport().then(data => setCreditData(data));
        loadGjeldsregisteretData().then(resp => setDebtData(resp.debtInformation)).catch(console.error);
        getCustomerRealEastate().then(({ data }) =>
            setTotalProperties(data?.length ?? 0)
        );
        _loadDebtData();
    }, []);

    const _loadDebtData = () => {
        const auth = JSON.parse(localStorage.getItem("auth"));
        localStorage.setItem("auth", JSON.stringify({ ...auth, loadDebt: true }));
        setLoading(true);

        loadGjeldsregisteretData()
            .then((resp) => {
                const loans = resp?.debtInformation?.loanTypes
                    ?.filter(({ type }) => type === "repaymentLoan")
                    .flatMap(({ loans }) => loans) || [];

                const cards = resp?.debtInformation?.loanTypes
                    ?.filter(({ type }) => type === "chargeCard" || type === "creditFacility")
                    .flatMap(({ loans }) => loans) || [];

                const originalBalance = loans
                    .map(({ balance }) => (isNaN(Number(balance)) ? 0 : Number(balance)))
                    .reduce((a, b) => a + b, 0);

                const totalCredit = cards
                    .map(({ nonInterestBearingBalance, interestBearingBalance }) =>
                        (isNaN(Number(interestBearingBalance)) ? 0 : Number(interestBearingBalance)) +
                        (isNaN(Number(nonInterestBearingBalance)) ? 0 : Number(nonInterestBearingBalance))
                    )
                    .reduce((a, b) => a + b, 0);

                setTotalDebt(format(originalBalance + totalCredit, true));

                const rcl = loans
                    .map(({ balance }) => (isNaN(Number(balance)) ? 0 : Number(balance)))
                    .reduce((a, b) => a + b, 0);
                setRemainingConsumerLoan(rcl);

                const al = loans
                    .map(({ originalBalance }) => (isNaN(Number(originalBalance)) ? 0 : Number(originalBalance)))
                    .reduce((a, b) => a + b, 0);
                setApprovedLoan(al);
            })
            .catch((err) => {
                console.error(err);
                setTotalDebt("N/A");
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const format = (value, divideBy100 = false) => {
        if (isNaN(value)) return "N/A";
        const formattedValue = Intl.NumberFormat("no-NO").format(divideBy100 ? value / 100 : value);
        return `${formattedValue} kr`;
    };

    const styles = StyleSheet.create({
        pdfView: {
            width: '100%',
            height: '1200px',
        }
    });

    return (
        <Container>
            <PDFViewer style={styles.pdfView}>
                <ScoreDocument
                    userData={userData}
                    creditData={creditData}
                    remainingConsumerLoan={remainingConsumerLoan}
                    approvedConsumerLoan={approvedConsumerLoan}
                    debtData={debtData}
                    totalDebt={totalDebt}
                    totalProperties={totalProperties}
                />
            </PDFViewer>
        </Container>
    );
}

export default CreditCheckPDF;
