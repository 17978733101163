import React from 'react';
import InnerPageLayout from "./InnerPageLayout";
import {Page, Text, View, Svg, Line, StyleSheet, Image} from '@react-pdf/renderer';
import graph1 from '../Images/scoreGraph.png';
import graph2 from '../Images/scoreGraph2.png';



const ScoreInfo = () => {
  const styles = StyleSheet.create({
    upperRow:{ fontSize : 9, flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', width: '100%', color: '#013367',marginBottom: 30,},
    graph1: {padding: 20, backgroundColor: '#FFFEFC', width: '50%', border: '1px solid #E9EBEF'},
    graphInfo: {flexDirection: 'row',alignItems: 'flex-start',flexWrap: 'wrap',width: '50%',paddingLeft:3},
    col: {padding:'0 2px 4px',width: '50%'},
    box: {padding:'17.4px 10px', fontSize: 9, border: '1px solid #E9EBEF',},
    title: {fontSize:20,fontWeight: "bold",},
    bg1: {backgroundColor: '#FDF6EC'},
    bg2: {backgroundColor: '#FFFCF2'},

  });
  return(
      <InnerPageLayout title={'0 2. Poengsum (vurdering)'}>
        <View style={styles.upperRow}>
          <View style={styles.graph1}>
            <Image src={graph1} />
          </View>
          <View style={styles.graphInfo}>
              <View style={styles.col}>
                  <View style={[styles.box, styles.bg1]}>
                      <Text style={styles.title}>D</Text>
                      <Text>International score</Text>
                  </View>
              </View>
              <View style={styles.col}>
                  <View style={[styles.box, styles.bg2]}>
                      <Text style={styles.title}>0</Text>
                      <Text>Credit limit</Text>
                  </View>
              </View>
              <View style={styles.col}>
                  <View style={[styles.box, styles.bg2]}>
                      <Text style={styles.title}>Yes</Text>
                      <Text>Payment remarks</Text>
                  </View>
              </View>
              <View style={styles.col}>
                  <View style={[styles.box, styles.bg1]}>
                      <Text style={styles.title}>C2</Text>
                      <Text>Financial Strength</Text>
                  </View>
              </View>
              <View style={styles.col}>
                  <View style={[styles.box, styles.bg1]}>
                      <Text style={styles.title}>Active</Text>
                      <Text>Status</Text>
                  </View>
              </View>
              <View style={styles.col}>
                  <View style={[styles.box, styles.bg2]}>
                      <Text style={styles.title}>No</Text>
                      <Text>Compliance alerts</Text>
                  </View>
              </View>
          </View>
        </View>
        <View>
          <Image src={graph2} />
        </View>
      </InnerPageLayout>
  )
}

export default ScoreInfo;