const blogData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Siste artikler ", class: "fw-normal" },
                { id: 1, title: "fra bloggen vår", class: "fw-bold" },
            ]
        },
    },
];

export default blogData;
