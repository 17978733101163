const bannerData =  [
    {
      id: 1,
      subTitle: "",
      title: [
        { id: 1, title: "Bedriftslån", class: "fw-normal" },
        { id: 2, title: "enkeltmannsforetak", class: "fw-bold" }
      ],
      description: "Hos EasyFinance tilbyr vi skreddersydde bedriftslån til enkeltmannsforetak, med fokus på enkelhet og rask prosess. Vår søknadsprosess er designet for å være rask og problemfri – det tar kun 60 sekunder å få et gratis og uforpliktende tilbud. Dersom du er fornøyd med tilbudet, kan du enkelt gå videre og fullføre søknaden. Mange av våre kunder har midlene tilgjengelig på kontoen bare minutter etter at søknaden er godkjent og signert."
    }
  ]


export default bannerData;
