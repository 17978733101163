import * as _ from 'lodash';
import { VERSIONS_LOADED } from '../actions/componentVersion';

const initialState = {
    componentVersions: []
}


export default function componentVersionReducer(state = initialState, action){
    const newState = _.cloneDeep(state);
    switch(action.type){
        case VERSIONS_LOADED:
            newState.componentVersions = action.payload;
            return newState;
        default:
            return newState;
    }
}