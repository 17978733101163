import React, {useState } from "react";
import classes from './index.module.scss';
import {Col, Row, Dropdown} from "react-bootstrap";
import Button from "../../Button";
import {Link} from "react-router-dom";
import { onError } from "Services/api/Image";
import CreditScore from "../../Charts/CreditScore";
import { getToken } from "../../../Services/api/Auth";

const DetailDropdown = ({item, onClose, url, user, index}) => {
    const [showDropdown, setShowDropdown] = useState(true);
    const filtered = item?.LoanSchema?.Property;
    // const filtered = item?.LoanSchema?.Property?.filter(property => property.ShowInDetail === 'true');
    const sorted = filtered?.filter(property => property.Priority !== '')?.sort((first, second) => Number(first.Priority) - (second.Priority))?.concat(filtered?.filter(property => property.Priority === ''));
    // const [detail, setDetail] = useState(sorted?.filter(property => property.ShowInDetail === 'true'));
    const [detail, setDetail] = useState(sorted);


    //console.log(detail);
    const childItem = {};

    return(
        <React.Fragment>
            <div className={classes.rowDropdown}>
                <div className={classes.logoBox}>
                    <Link to={'#'}>
                        <img src={item.image} loading="lazy" onError={onError} alt={'#'} />
                    </Link>
                </div>
                <button className={classes.closeButton} onClick={onClose}>
                    <i className={'fal fa-times'}></i>
                </button>
                <div className={classes.holder}>
                    <Row>
                        <Col md={4}>
                            <div className={classes.box}>
                                {/*<h4>{childItem.produktpakke_tekst}</h4>*/}
                                <h6 className={classes.detailTitle}>{
                                    detail.find(d => d?.Name === 'Navn')?.Value /*||
                                    detail.find(d => d?.Name === 'Navn')?.value ||
                                    detail?.find(d => d?.Name === 'Title')?.value ||
                                    detail?.find(d => d?.Name === 'Title')?.value*/
                                }</h6>
                                <ul className={classes.list}>
                                    {detail?.filter((_, i) => i < 11)?.map((property, index) => {
                                        if(detail?.some(p => p.Name === 'MinBelop') && detail?.some(p => p.Name === 'MaksBelop')){
                                            if(property?.Name === 'MinBelop'){
                                                return <li key={index}>
                                                    <div className={classes.col}>Lån fra </div>
                                                    <div className={classes.col}>{property.Value} <i>{property?.Symbol}</i> - {detail?.find(p => p.Name === 'MaksBelop')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if( property.Name === 'MaksBelop' ) return null;
                                        }
                                        if(detail?.some(p => p.Name === 'MinAlder') && detail?.some(p => p.Name === 'MaksAlder')){
                                            if(property?.Name === 'MinAlder'){
                                                return <li key={index}>
                                                    <div className={classes.col}>Alder </div>
                                                    <div className={classes.col}>{property?.Value} <i>{property?.Symbol}</i> - {detail?.find(p => p.Name === 'MaksAlder')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if( property.Name === 'MaksAlder' ) return null;
                                        }
                                        if(detail?.some(p => p.Name === 'KredittkortUttakUtlandTransgebyr') && detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')){
                                            if(property?.Name === 'KredittkortUttakUtlandTransgebyr'){
                                                return  <li key={index}>
                                                    <div className={classes.col}>Kontantuttak i utlandet </div>
                                                    <div className={classes.col}>{property?.Value} <i>{property?.Symbol}</i> + {detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if(property?.Name === 'KredittkortUttakUtlandProsent') return null;
                                        }
                                        if(detail?.some(p => p?.Name === 'KredittkortUttakAnnenBankEtterApningstidTransgebyr') && detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')){
                                            if(property?.Name === 'KredittkortUttakAnnenBankEtterApningstidTransgebyr'){
                                                return  <li key={index}>
                                                    <div className={classes.col}>Kontantuttak i Norge i annen bank </div>
                                                    <div className={classes.col}>{property?.Value} <i>{property?.Symbol}</i> + {detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if(property?.Name === 'KredittkortUttakUtlandProsent') return null;
                                        }
                                        return property?.Name === 'Forbehold' ? null :
                                        <li key={index}>
                                            <div className={classes.col}>{!property?.LocaleName ? property?.Name : property?.LocaleName} </div>
                                            <div className={classes.col}>{!property?.Value ? 'N/A' : property?.Value} <i>{property?.Symbol}</i></div>
                                        </li>
                                    })}
                                </ul>

                            </div>

                        </Col>
                        <Col md={4}>
                            {detail?.length > 11 && <div className={classes.box}>
                            <ul className={classes.list}>
                                    {detail?.filter((_, i) => i >= 11)?.map((property, index) => {
                                        if(detail?.some(p => p.Name === 'MinBelop') && detail?.some(p => p.Name === 'MaksBelop')){
                                            if(property?.Name === 'MinBelop'){
                                                return <li key={index}>
                                                    <div className={classes.col}>Lån fra </div>
                                                    <div className={classes.col}>{property.Value} <i>{property?.Symbol}</i> - {detail?.find(p => p.Name === 'MaksBelop')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if( property.Name === 'MaksBelop' ) return null;
                                        }
                                        if(detail?.some(p => p.Name === 'MinAlder') && detail?.some(p => p.Name === 'MaksAlder')){
                                            if(property?.Name === 'MinAlder'){
                                                return <li key={index}>
                                                    <div className={classes.col}>Alder </div>
                                                    <div className={classes.col}>{property?.Value} <i>{property?.Symbol}</i> - {detail?.find(p => p.Name === 'MaksAlder')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if( property.Name === 'MaksAlder' ) return null;
                                        }
                                        if(detail?.some(p => p.Name === 'KredittkortUttakUtlandTransgebyr') && detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')){
                                            if(property?.Name === 'KredittkortUttakUtlandTransgebyr'){
                                                return  <li key={index}>
                                                    <div className={classes.col}>Kontantuttak i utlandet </div>
                                                    <div className={classes.col}>{property?.Value} <i>{property?.Symbol}</i> + {detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if(property?.Name === 'KredittkortUttakUtlandProsent') return null;
                                        }
                                        if(detail?.some(p => p?.Name === 'KredittkortUttakAnnenBankEtterApningstidTransgebyr') && detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')){
                                            if(property?.Name === 'KredittkortUttakAnnenBankEtterApningstidTransgebyr'){
                                                return  <li key={index}>
                                                    <div className={classes.col}>Kontantuttak i Norge i annen bank </div>
                                                    <div className={classes.col}>{property?.Value} <i>{property?.Symbol}</i> + {detail?.some(p => p?.Name === 'KredittkortUttakUtlandProsent')?.Value} <i>{property?.Symbol}</i></div>
                                                </li>
                                            }
                                            else if(property?.Name === 'KredittkortUttakUtlandProsent') return null;
                                        }
                                        return property?.Name === 'Forbehold' ? null :
                                        <li key={index}>
                                            <div className={classes.col}>{!property?.LocaleName ? property?.Name : property?.LocaleName} </div>
                                            <div className={classes.col}>{!property?.Value ? 'N/A' : property?.Value} <i>{property?.Symbol}</i></div>
                                        </li>
                                    })}
                                </ul>
                            </div>}
                            <div className={classes.powerByRow}>
                                <div className={classes.box}>
                                    <ul className={classes.list}>
                                        <li>
                                            <div className={classes.col}>Data provided by</div><div className={classes.col}><img src={process.env.REACT_APP_FINANS_PORTAL_LOGO} loading="lazy" onError={onError} alt={'Finans Portalen'} /> </div>
                                        </li>
                                    </ul>
                                </div>
                                {url &&
                                    <Button
                                        text={'Gå til banken'}
                                        url={url}
                                        variant={classes.urlBtn}
                                        icon={'fal fa-long-arrow-right'}
                                    />
                                }
                            </div>
                        </Col>
                        <Col md={4}>
                            {childItem.disclaimer && <div className={`${classes.box} p-0`}>
                                <Dropdown className={classes.openClose}>
                                    <Dropdown.Toggle variant={'transparent'}>
                                        Ansvarsfraskrivelse
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <p>{detail?.find(d => d?.Name === 'Forbehold')?.Value}</p>
                                        {childItem.disclaimer}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>}

                            { !(getToken() && getToken() !== '') ? <div className={classes.powerByRow}>
                                {url &&
                                    <Button
                                        text={'Check your Credit score'}
                                        url={`/login`}
                                        variant={classes.urlBtn}
                                        icon={'fal fa-long-arrow-right'}
                                    />
                                }
                            </div> : null}
                            {getToken() && getToken() !== '' && <div className={`${classes.box} p-0`}>
                                <Dropdown className={classes.openClose} show={showDropdown} onSelect={() => {}}>
                                    <Dropdown.Toggle variant={'transparent'}>
                                        Kredittscore
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <CreditScore id={index} small score={user?.score}/>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>}
                        </Col>


                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}

export default DetailDropdown;