import React, { useState, useEffect } from 'react';
import classes from "../../index.module.scss";
import { Accordion, Col, Row, Spinner, Table } from "react-bootstrap";
import YourDebt from "../../../../Components/Charts/YourDebt";
import Button from "../../../../Components/Button";
import ToolTip from "../../../../Components/ToolTip";
import InterestRate from "../../../../Components/Charts/InterestRate";
import DebtCheckConsentPopup from "../../DashboardComponent/DebtCheckConsent/DebtCheckConsentPopup";
import { loadGjeldsregisteretData } from '../../../../Services/api/Loan';
import moment from 'moment';
import Loader from "../../../../Components/Loader";
import {filter} from "lodash";
import adDataDebtCheck from "./adDataDebtCheck";
import AdAffiliatesBox from "../../DashboardComponent/AdAffiliatesBox";
import dummyDebt from "./dummtDebtCheck";
import { stubTrue } from 'lodash';
import debtChekImg from "../../../../Images/debtChekImg.svg"
import FAQs from "../../../../Components/FAQs";
import faqData from "./faqData";
import DebtCheckDataLoad from "../../DashboardComponent/DebyCheckDataLoad";
import SocialMediaShare from "../../../../Components/SocialMediaShare";
import Loading from "../../../../Components/Loading";
import DownLoadButtonDebtCheckPDF from "../../../../Components/PDF/Personal/DebtCheckPDF/DownLoadButtonDebtCheckPDF";
import PDFDownLoadButton from "../../../../Components/PDF/Personal/PDFDownLoadButton";


const DebtCheck = () => {
    const [showBankIDModal, setShowBankIDModal] = useState(true);
    //const { format } = Intl.NumberFormat('no-NO');
    const [debt, setDebt] = useState({});
    const [loggedInUser, setLoggedInUser] = useState({});
    const [updatedOn, setUpdatedOn] = useState('');
    const [loading, setLoading] = useState(false);
    const [usedCredit, setUsedCredit] = useState(0);
    const [approvedCredit, setApprovedCredit] = useState(0);
    const [remainingConsumerLoan, setRemainingConsumerLoan] = useState(0);
    const [approvedConsumerLoan, setApprovedLoan] = useState(0);
    const [loadingDebtData, setLoadingDebtData] = useState(false);
    const [debtDataLoaded, setDebtDataLoaded] = useState(false);
    const consent = JSON.parse(localStorage.getItem('auth'))?.consent

    useEffect(() => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if(auth?.consent && auth?.loadDebt) _loadDebtData();
    }, [])

    const _loadDebtData = () => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        localStorage.setItem('auth', JSON.stringify({...auth, loadDebt: true}))
        setLoggedInUser(auth);
        setLoadingDebtData(true);
        loadGjeldsregisteretData()
            .then(resp => {
                setDebtDataLoaded(true);
                setDebt(resp?.debtInformation)
                setUpdatedOn(moment(resp?.timestamp?.split('T')?.at(0)).format('DD-MM-YYYY'));

                const uc = resp?.debtInformation?.loanTypes?.find(({ type }) => type === 'chargeCard' || type === 'creditFacility')?.loans?.map(({ balance, interestBearingBalance }) => (!isNaN(Number(balance)) ? Number(balance) : 0) + (!isNaN(Number(interestBearingBalance)) ? Number(interestBearingBalance) : 0)).reduce((a, b) => {
                    if (a && !isNaN(Number(a)) && b && !isNaN(Number(b)))
                        return Number(a) + Number(b);
                    else if ((a && !isNaN(Number(a))) || (b && !isNaN(Number(b)))) {
                        if (!a || isNaN(Number(a)))
                            return Number(b)
                        else if (!b || isNaN(Number(b)))
                            return Number(a)
                    }
                    else return 0;
                }, 0);
                setUsedCredit(uc);

                const ac = resp?.debtInformation?.loanTypes?.find(({ type }) => type === 'chargeCard' || type === 'creditFacility')?.loans?.map(({ originalBalance }) => originalBalance).reduce((a, b) => {
                    if (a && !isNaN(Number(a)) && b && !isNaN(Number(b)))
                        return Number(a) + Number(b);
                    else if ((a && !isNaN(Number(a))) || (b && !isNaN(Number(b)))) {
                        if (!a || isNaN(Number(a)))
                            return Number(b)
                        else if (!b || isNaN(Number(b)))
                            return Number(a)
                    }
                    else return 0;
                }, 0);
                setApprovedCredit(ac);

                const rcl = resp?.debtInformation?.loanTypes?.filter(({type}) => type === 'repaymentLoan')?.flatMap(({loans}) => loans)?.map(({balance}) => isNaN(Number(balance)) ? 0 : Number(balance))?.reduce((a, b) => a + b, 0)
                setRemainingConsumerLoan(rcl);
                const al = resp?.debtInformation?.loanTypes?.filter(({type}) => type === 'repaymentLoan')?.flatMap(({loans}) => loans)?.map(({originalBalance}) => isNaN(Number(originalBalance)) ? 0 : Number(originalBalance))?.reduce((a, b) => a + b, 0)
                setApprovedLoan(al);
            })
            .catch(console.error)
            .finally(() => setLoadingDebtData(false));
    }


    const _handleDebtRegisterUpdate = () => {
        loadGjeldsregisteretData(true)
            .then(resp => {
                setDebt(resp?.debtInformation)
                setUpdatedOn(moment(resp?.timestamp?.split('T')?.at(0)).format('DD-MM-YYYY'));
            })
            .catch(console.error)
    }

    const closeBankIDModal = () => {
        setShowBankIDModal(false);
    };

    const format = (value, divideBy100 = true, round = false, appendZeros = true) => {
        if(isNaN(value)) return 0
        const formatter = Intl.NumberFormat('no-NO', {
            minimumFractionDigits: appendZeros ? 2 : 0,
            maximumFractionDigits: appendZeros ? 2 : 0
        });
        const roundedValue = round ? Math.round(divideBy100 ? value / 100 : value) : divideBy100 ? value / 100 : value
        if(isNaN(roundedValue)) return 0;
        return formatter.format(roundedValue);
    };



    return (
        <React.Fragment>
            {loading ? <Loading style={{position: 'absolute'}} /> :  <>
                {!consent && <DebtCheckConsentPopup onCloseModal={closeBankIDModal} />}
                {debtDataLoaded ? <Row className={`px-3`} style={{ filter: !consent ? 'blur(20px)' : 'none' }}>
                    <Col xl={8} className={'p-0'}>
                        <div className={`${classes.dashboardCards} ${classes.col100}`}>
                            <div className={classes.col}>
                                <div className={classes.box}>
                                    <PDFDownLoadButton />
                                    <div className={'font-12 text-right mb-2'} style={{right: 0}}> Oppdatert: <span className={''}>{updatedOn}</span></div>
                                    <Row className={classes.mobileData}>
                                        <Col md={8}>
                                            <p>På "Min gjeld" kan du se hvor mye penger du skylder uten at du har satt noe som sikkerhet. Dette inkluderer gjeld som kredittkort, forbrukslån og faktureringskort. Vi henter din gjeldsinformasjon direkte fra Gjeldsregisteret, og oppdateres hverdag.</p>
                                        </Col>
                                        <Col md={4}>
                                            <div className={classes.imgBox}>
                                                <img src={debtChekImg} loading="lazy" />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={'4'}>
                                            <div className={classes.debtChartBox}>
                                                <YourDebt debt={debt}/>
                                            </div>
                                        </Col>
                                        <Col lg={'8'}>
                                            <ul className={`${classes.dataList} ${classes.iconNone}`}>
                                                {/*<li>
                                                <div className={`${classes.col1} ${classes.timeStamp}`}>
                                                    Oppdatert: <span className={''}>{updatedOn}</span>
                                                </div>
                                                <div className={`${classes.col2} text-right`}>
                                                    <Button
                                                        text={'Update debt register'}
                                                        icon={'far fa-sync-alt'}
                                                        iconLeft
                                                        variant={'border small'}
                                                        onClick={_handleDebtRegisterUpdate}
                                                    />
                                                </div>
                                            </li>*/}
                                                <li>
                                                    <div className={classes.col1}>
                                                        Godkjent forbrukslån
                                                        {/*<ToolTip title={'Godkjent forbrukslån'} placement={'top'} trigger={'hover'}>
                                                        Betyr at lånesøknaden din er blitt godkjent av långiveren, og du har fått tillatelse til å ta opp et forbrukslån.
                                                    </ToolTip>*/}
                                                    </div>
                                                    <div className={`${classes.col2} text-right  fw-semibold`}>
                                                        <span className={'font-22'}>{approvedConsumerLoan && !isNaN(approvedConsumerLoan) ? format(approvedConsumerLoan, true, true, false) : '0'}</span>
                                                        <span className={'mx-2'}>kr</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={classes.col1}>
                                                        Gjenværende forbrukslån
                                                        {/*<ToolTip title={'Remaining consumer loan'} placement={'top'} trigger={'hover'}>
                                                        EasyFinance algorithm has analyzed your debt situation
                                                        and financial data. We conclude that you can save NOK
                                                        34.198.21 a year by refinancing your loans via our
                                                        recommended partner below
                                                    </ToolTip>*/}
                                                    </div>
                                                    <div className={`${classes.col2} text-right fw-semibold`}>
                                                        <span className={'font-22'}>{remainingConsumerLoan && !isNaN(remainingConsumerLoan) ? format(remainingConsumerLoan, true, true, false) : '0'}</span>
                                                        <span className={'mx-2'}>kr</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={classes.col1}>
                                                        Innvilget kredittramme
                                                        {/* <ToolTip title={'Approved credit'} placement={'top'} trigger={'hover'}>
                                                        EasyFinance algorithm has analyzed your debt situation
                                                        and financial data. We conclude that you can save NOK
                                                        34.198.21 a year by refinancing your loans via our
                                                        recommended partner below
                                                    </ToolTip>*/}
                                                    </div>
                                                    <div className={`${classes.col2} text-right fw-semibold`}>
                                                        <span className={'font-22'}>{format(debt?.loanTypes?.filter(({type}) => type === 'creditFacility' || type === 'chargeCard')?.flatMap(({loans}) => loans)?.map(({creditLimit}) => isNaN(Number(creditLimit)) ? 0 : Number(creditLimit))?.reduce((a, b) => a + b, 0), true, true, false)}</span>
                                                        <span className={'mx-2'}>kr</span>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className={classes.col1}>
                                                        Brukt kreditt
                                                        {/* <ToolTip title={'Used Credit'} placement={'top'} trigger={'hover'}>
                                                        EasyFinance algorithm has analyzed your debt situation
                                                        and financial data. We conclude that you can save NOK
                                                        34.198.21 a year by refinancing your loans via our
                                                        recommended partner below
                                                    </ToolTip>*/}
                                                    </div>
                                                    <div className={`${classes.col2} text-right fw-semibold`}>
                                                        <span className={'font-22'}>{format(debt?.loanTypes?.filter(({type}) => type === 'creditFacility' || type === 'chargeCard')?.flatMap(({loans}) => loans)?.map(({interestBearingBalance, nonInterestBearingBalance}) => (isNaN(Number(interestBearingBalance)) ? 0 : Number(interestBearingBalance)) + (isNaN(Number(nonInterestBearingBalance)) ? 0 : Number(nonInterestBearingBalance)))?.reduce((a, b) => a + b, 0), true, true, false)}</span>
                                                        <span className={'mx-2'}>kr</span>
                                                    </div>

                                                </li>
                                            </ul>
                                        </Col>
                                    </Row>
                                    <div className={'font-11'} style={{lineHeight: '14px'}}>Gjeldsopplysningene er levert av Gjeldsregisteret AS. Oversikt over finansforetak som leverer gjeldsopplysninger til Gjeldsregisteret AS finner du på <a href={'https://gjeldsregisteret.com/'} target={"_blank"}>gjeldsregisteret.com</a> </div>
                                </div>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="debt1" className={classes.accodiranWhite} style={{ padding: '0 15px 20px' }}>
                            {debt?.loanTypes?.find(({ type }) => type === 'repaymentLoan')?.loans?.length > 0 && <Accordion.Item eventKey="debt3">
                                <Accordion.Header>
                                    <div className={classes.colRow}>
                                        <div className={classes.col}><b>Forbrukslån:</b></div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className={''}>
                                    {debt?.loanTypes?.find(({ type }) => type === 'repaymentLoan')?.loans?.map((loan, index) => {
                                        return <Table key={index} className={classes.headingTable}>
                                            <thead>
                                            <tr className={classes.tableHeader}>
                                                <th colSpan={2} className={classes.header}>{loan?.financialInstitutionName}</th>
                                                <th colSpan={2} className={classes.loanAmount}>{format(loan?.balance)} kr</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={classes.hideMobile}>
                                                <th>Opprinnelig lånebeløp kr</th>
                                                <th>Rentebærende saldo kr (restlån)</th>
                                                <th>Gjenværende terminer</th>
                                                <th>Nominell rente %</th>
                                                <th>Gebyr kr</th>
                                                <th>Terminlengde</th>
                                                <th>Medlåntaker</th>
                                            </tr>
                                            <tr>
                                                <td data-label="Opprinnelig lånebeløp kr">{!isNaN(loan?.originalBalance) ? format(loan?.originalBalance) : '-'}</td>
                                                <td data-label="Rentebærende saldo kr (restlån)">{!isNaN(loan?.balance || loan?.interestBearingBalance) ? format(loan?.balance || loan?.interestBearingBalance) : '-'}</td>
                                                <td data-label="Gjenværende terminer">{!isNaN(loan?.terms) !== '' ? format(loan?.terms, false) : '-'}</td>
                                                <td data-label="Nominell rente %">{!isNaN(loan?.nominalInterestRate) ? format(loan?.nominalInterestRate) : '-'}</td>
                                                <td data-label="Gebyr kr">{!isNaN(loan?.installmentCharges) ? format(loan?.installmentCharges) : '-'}</td>
                                                <td data-label="Terminlengde">{loan?.installmentChargePeriod && loan?.installmentChargePeriod !== '' ? loan?.installmentChargePeriod?.replace('MONTHLY', 'Månedlig') : ''}</td>
                                                <td data-label="Medlåntaker">{!isNaN(loan?.coBorrower) ? Number(loan?.coBorrower) === 0 ? 'Ingen' : format(loan?.coBorrower, false) : '-'}</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>}

                            {debt?.loanTypes?.find(({ type }) => type === 'creditFacility')?.loans?.length > 0 && <Accordion.Item eventKey="debt1">
                                <Accordion.Header>
                                    <div className={classes.colRow}>
                                        <div className={classes.col}><b>Kredittkort:</b></div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className={''}>
                                    {debt?.loanTypes?.find(({ type }) => type === 'creditFacility')?.loans?.map((credit, index) => {
                                        return <Table key={index} className={classes.headingTable}>
                                            <thead>
                                            <tr className={classes.tableHeader}>
                                                <th colSpan={2} className={classes.header}>{credit?.financialInstitutionName}</th>
                                                <th colSpan={3} className={classes.loanAmount}>{format(Number(credit?.interestBearingBalance) + Number(credit?.nonInterestBearingBalance)) } kr</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={classes.hideMobile}>
                                                <th width={"20%"}>Kredittgrense</th>
                                                <th width={"25%"}>Rentebærende saldo</th>
                                                <th width={"25%"}>Ikke rentebærende saldo</th>
                                                <th width={"20%"}>Nominell rente</th>
                                                <th width={"10%"}>Gebyr</th>
                                            </tr>
                                            <tr>
                                                <td data-label="Kredittgrense">{credit?.creditLimit && credit?.creditLimit !== '' ? format(credit?.creditLimit) : '0'} kr</td>
                                                <td data-label="Rentebærende saldo">{credit?.interestBearingBalance && credit?.interestBearingBalance !== '' ? format(credit?.interestBearingBalance) : '0'} kr</td>
                                                <td data-label="Ikke rentebærende">{credit?.nonInterestBearingBalance && credit?.nonInterestBearingBalance !== '' ? format(credit?.nonInterestBearingBalance) : '0'} kr</td>
                                                <td data-label="Nominell rente">{!isNaN(credit?.nominalInterestRate) ? format(credit?.nominalInterestRate) : '0'} %</td>
                                                <td data-label="Gebyr">{!isNaN(credit?.installmentCharges) ? format(credit?.installmentCharges) : '0'} kr</td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    })}
                                </Accordion.Body>
                            </Accordion.Item>}

                            {debt?.loanTypes?.find(({ type }) => type === 'chargeCard')?.loans?.length > 0 && <Accordion.Item eventKey="debt4">
                                <Accordion.Header>
                                    <div className={classes.colRow}>
                                        <div className={classes.col}><b>Faktureringskort</b></div>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body className={''}>
                                    {debt?.loanTypes?.find(({ type }) => type === 'chargeCard')?.loans?.map((credit, index) => {
                                        return <Table className={classes.headingTable} key={index}>
                                            <thead>
                                            <tr className={classes.tableHeader}>
                                                <th colSpan={1} className={classes.header}>{credit?.financialInstitutionName}</th>
                                                <th colSpan={1} className={classes.loanAmount}>{format((isNaN(credit?.interestBearingBalance) ? 0 : Number(credit?.interestBearingBalance)) + (isNaN(credit?.nonInterestBearingBalance) ? 0 : Number(credit?.nonInterestBearingBalance)))} kr</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className={classes.hideMobile}>
                                                <th width={"40%"}>Rentebærende saldo (forfalt)</th>
                                                <th width={"60%"}>Ikke rentebærende saldo (utestående)</th>
                                            </tr>
                                            <tr>
                                                <td>{format(credit?.interestBearingBalance)} kr</td>
                                                <td>{format(credit?.nonInterestBearingBalance)} kr</td>
                                            </tr>
                                            </tbody>
                                        </Table>})}
                                </Accordion.Body>
                            </Accordion.Item>}
                        </Accordion>
                    </Col>
                    <Col xl={4} className={'p-0'}>
                        <div className={`${classes.dashboardCards} ${classes.col100}`}>
                            <div className={classes.col}>
                                <div className={`${classes.box} mb-4`}>
                                    <InterestRate debt={debt} />
                                    <div className={'text-center'} style={{ color: '#8E8E8E' }}>
                                        {adDataDebtCheck.map(item => (
                                            <React.Fragment key={item.id}>
                                                <Button
                                                    variant={'radius-medium mb-3'}
                                                    text={`Start søknad her`}
                                                    url={item.btnUrl}
                                                    target={'_blank'}
                                                />
                                                <p className={"font-10"} style={{color: "#2c3548"}}>Annonseringslenke<br />
                                                    {/*<a href={item.btnUrl} target={'_blank'}>{item.btnUrl}</a>*/} </p>
                                            </React.Fragment>
                                        ))}
                                    </div>
                                </div>
                                <AdAffiliatesBox data={adDataDebtCheck} />
                            </div>
                        </div>
                    </Col>
                    <Col xl={12}>
                        <Row>
                            <Col xl={8} className={'p-0'}>
                                <FAQs faqData={faqData} />
                            </Col>
                        </Row>
                    </Col>
                    <Col xl={12} className={'p-0'}>
                        <SocialMediaShare
                            PostUrl={'https://easyfinance.no'}
                            PostTitle={'Sjekk ut denne nettsiden! Gratis kredittsjekk, gjeldsoversikt og boligverdi. Få bedre forståelse av din økonomi!'}

                        />
                    </Col>
                </Row> : consent && !loggedInUser?.loadDebt ? <DebtCheckDataLoad absolute onClick={_loadDebtData} loadingDebtData={loadingDebtData} /> : null}
            </> }
        </React.Fragment>
    )
}

export default DebtCheck;