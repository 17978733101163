const bannerData =  [
    {
      id: 1,
      subTitle: "Bedriftslån",
      title: [
        { id: 1, title: "Lån for", class: "fw-normal" },
        { id: 2, title: "små og mellomstore", class: "fw-normal" },
        { id: 3, title: "bedrifter", class: "fw-bold" }
      ],
      description:
          "Hos EasyFinance forstår vi at hver bedrift er unik, og derfor tilbyr vi skreddersydde finansieringsløsninger som passer akkurat din virksomhets behov. Enten du ser etter midler til å vokse, investere i markedsføring, eller realisere et nytt prosjekt, står vi klare til å støtte deg med lån fra 10.000 kr til 2 millioner kr. Vår prosess er rask, transparent, og uten skjulte kostnader, slik at du kan fokusere på det som virkelig teller - din bedrifts suksess. Oppdag dine finansieringsmuligheter med EasyFinance i dag og ta et skritt nærmere dine mål."
    }
  ]


export default bannerData;
