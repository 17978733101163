import React from 'react';
import {PDFViewer, StyleSheet} from '@react-pdf/renderer';
import {Container} from "react-bootstrap";
import Invoice from "./ScoreDocument";
import ScoreDocument from "./ScoreDocument";

const CompanyPDF = () => {

    const styles = StyleSheet.create({
        pdfView: {
            width: '100%',
            height: '1200px',
        }
    });
    return(
        <Container>
            <PDFViewer style={styles.pdfView}>
                <ScoreDocument />
            </PDFViewer>
        </Container>
    )
};

export default CompanyPDF;