import React, { useEffect, useState } from 'react';
import classes from './index.module.scss';
import {Alert, Col, Container, FormGroup, Row, Spinner} from "react-bootstrap";
import FormWizard from "../FormWizard";
import FormInput from "../FormInput";
import SectionTitle from "../SectionTitle";
import cardImg from "../../Images/BoliglånDNB.jpg";
import Button from "../Button";
import { getConsumerReport, getCurrentUser, getToken } from '../../Services/api/Auth';
import { signicatLogin, vippsLogin } from '../../Services/api/Login';
import { defaultCompanyReport, searchCompanies } from '../../Services/api/Company';
import Loader from "../Loader";
import Thankyou from "../../Images/Thankyou.svg";
import { FirstStepValidator, SecondStepValidator, ThirdStepValidator } from '../FormWizard/validation';
import { object } from 'prop-types';
import notFound from "../../Images/companyNotFoundImg.svg";



const VerifyVipps = () => {
    const _handleVipps = () => {
        vippsLogin()
        .then(response => {
            window.location.href = response;
        })
    }
    const _handleBankId = () => {
        signicatLogin()
        .then(response => {
            window.location.href = response;
        })
    }
    return(
        <div className={classes.stepBoxHolder} >
            <div className={classes.boxHolder}>
                <SectionTitle textCenter>
                    <span className={'fw-normal'}>Verify  </span>
                    <span className={'fw-semibold'}>with</span>
                </SectionTitle>
                <FormGroup>
                    <Button
                        text={'Du kan også verifisere med'}
                        variant={'vipps large w-100'}
                        icon={'icon-vipps mx-2'}
                        onClick={_handleVipps}
                    />
                </FormGroup>
                <div className={'py-4 text-center'}>
                    Du kan også verifisere med
                    <Button
                        variant={'link'}
                        icon={'icon-BankID'}
                        onClick={_handleBankId}
                    />
                </div>
            </div>
        </div>
    )
}

const NotFindCompany = () => {
    return(
        <React.Fragment>
            <div className={classes.boxHolder}>
                <SectionTitle textCenter description={'Vi har ikke funnet noe selskap registrert mot dine opplysninger. Du kan ikke søke bedriftslån'}>
                    <span className={'fw-normal'}>Firma</span>
                    <span className={'fw-semibold'}>ikke funnet</span>
                </SectionTitle>
                <div className={classes.notFounfImg}>
                    <img src={notFound} loading="lazy" />
                </div>
            </div>
        </React.Fragment>
    )
}

const FirstStep = ({ application, setApplication, errors, setErrors }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [companyDetailsLoading, setCompanyDetailsLoading] = useState(false);
    const [companyDetailsError, setCompanyDetailsError] = useState(false);
    useEffect(() => {
        setLoading(true)
        getConsumerReport()
        .then(report => {
            setCompanies(report?.companyEngagements);
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false))
    }, []);
    
    const _handleChange = ({ value }) => {
        setErrors({});
        setCompanyDetailsLoading(true);
        searchCompanies(undefined, value)
        .then(({ companies }) => {
            if(companies){
                setCurrentCompany(companies?.at(0));
                setApplication({ ...(application || {}), organization: { ...(application?.organization || {}), nationalOrganizationNumber: value, orgName: companies?.at(0)?.name } });
                setCompanyDetailsError(false);
            }
            else{
                setCompanyDetailsError(true);
            }
        })
        .finally(() => setCompanyDetailsLoading(false))
    }
    return(
        <div className={classes.stepBoxHolder} style={{minHeight: "200px"}}>
           {loading ? (
               <div style={{ minHeight: "100px", maxWidth: "600px", margin: "0 auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                   <Spinner variant={"dark"} />
               </div>
           ) : companies?.length > 0 ? <div className={classes.boxHolder}>
                <SectionTitle textCenter>
                    <span className={'fw-normal'}>Næringslån innen </span>
                    <span className={'fw-semibold'}>60 sekunder</span>
                </SectionTitle>
                <FormInput
                    type={'select'}
                    fieldLabel={'Velg selskapet du ønsker å låne penger til.'}
                    id={'organizationNumber'}
                    placeholder={'XXX XXX XXX'}
                    options={companies?.map(({ companyNumber, companyName }) => ({ label: companyName, value: companyNumber}))}
                    onChange={_handleChange}
                />
                {errors?.orgNumber !== '' && errors?.orgNumber && <p className='text-danger'>{errors?.orgNumber}</p>}
                {error && <div className={'text-danger font-11'}>Det oppsto en feil mens du prøvde å logge inn. Vennligst prøv igjen.</div>}
                {companyDetailsError ? <Alert variant={'danger'}>Ingen bedriftsdata funnet. Vær så snill, prøv på nytt</Alert> : null}
                {companyDetailsLoading ? <div style={{minHeight: "100px", maxWidth: "600px", margin: "0 auto",display: "flex", alignItems: "center", justifyContent: "center"}}><Spinner variant={"success"} /> </div> : currentCompany && <dl className={classes.companyInfo}>
                    <dt>Selskap</dt>
                    <dd>{currentCompany?.name} <br /> {currentCompany?.address?.street} <br /> {`${currentCompany?.address?.postCode ?? ''} ${currentCompany?.address?.city ?? ''}`}</dd>
                    <dt>Org.nummer:</dt>
                    <dd>{currentCompany?.regNo}</dd>
                    <dt>Selskapstype:</dt>
                    <dd>{currentCompany?.type && currentCompany?.type !== '' ? currentCompany?.type?.toLowerCase()?.replace('nonltdnonreg', 'ENK')?.replace('ltd', 'AS') :null}</dd>
                </dl>}
            </div> : <NotFindCompany />}
        </div>
    )
}

const SecondStep = ({ application, setApplication, errors, setErrors }) => {
    useEffect(() => {
        if(getToken()){
            function normalizePhoneNumber(phoneNumber) {
                return phoneNumber?.length === 8 ? phoneNumber : phoneNumber.replace(/^(\+?47|0?47)/, '')
            }
            const parsed = JSON.parse(localStorage.getItem('auth'));
            setApplication({ ...(application || {}), applicant: {...(application?.applicant || {}), email: parsed?.email, phone: normalizePhoneNumber(parsed?.phoneNo ?? '')}, organization: {...(application?.organization || {}), email: parsed?.email ?? ''} })
        }
    }, []);
    return(
        <div className={classes.stepBoxHolder}>
            <div className={classes.boxHolder}>
                <SectionTitle textCenter>
                    <span className={'fw-semibold'}>Kontaktinformasjon</span>
                </SectionTitle>
                <FormInput
                    type={'email'}
                    fieldLabel={'E-mail'}
                    id={'personalEmail'}
                    value={application?.applicant?.email}
                    onChange={({ target }) => {
                        setErrors({...(errors || {}), email: ''});
                            setApplication({ ...(application || {}), applicant: {...(application?.applicant || {}), email: target.value} })
                    }}
                />
                {errors?.email && errors?.email !== '' && <p className='text-danger'>{errors?.email}</p>}
                <FormInput
                    type={'email'}
                    fieldLabel={'Firma e-mail'}
                    id={'comanyEmail'}
                    value={application?.organization?.email}
                    onChange={({ target }) => {
                        setErrors({...(errors || {}), companyEmail: ''});
                        setApplication({ ...(application || {}), organization: {...(application?.organization || {}), email: target.value} })
                    }}
                />
                {errors?.companyEmail && errors?.companyEmail !== '' && <p className='text-danger'>{errors?.companyEmail}</p>}
                <FormInput
                    type={'tel'}
                    fieldLabel={'Telephone'}
                    id={'telephone'}
                    value={application?.applicant?.phone}
                    onChange={({ target }) => {
                        setErrors({...(errors || {}), phone: ''});
                        setApplication({ ...(application || {}), applicant: {...(application?.applicant || {}), phone: target.value} })
                }}
                />
                {errors?.phone && errors?.phone !== '' && <p className='text-danger'>{errors?.phone}</p>}
            </div>
        </div>
    )
}

const ThirdStep = ({ application, setApplication, errors, setErrors }) => {
    const [purpose, setPurpose] = useState('');
    const purposes = {
        PurchaseOfEquipment: 'PurchaseOfEquipment',
        Refinancing: 'Refinancing',
        HireEmployees: 'HireEmployees',
        Marketing: 'Marketing',
        InvestInInventory: 'InvestInInventory',
        Growth: 'Growth',
        WorkingCapital: 'WorkingCapital',
        Other: 'Other'
    }
    return(
        <div className={classes.stepBoxHolder}>
            <div className={classes.boxHolder}>
                <SectionTitle textCenter>
                    <span className={'fw-semibold'}>Låneformål</span>
                </SectionTitle>
                <ul className={classes.questionList}>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Kjøp av utstyr'}
                                id={'PurchaseOfEquipment'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.PurchaseOfEquipment}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.PurchaseOfEquipment }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Refinansiering'}
                                id={'Refinancing'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.Refinancing}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.Refinancing }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Rekruttere ansatte'}
                                id={'HireEmployees'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.HireEmployees}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.HireEmployees }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Markedsføring'}
                                id={'Marketing'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.Marketing}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.Marketing }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Investere i varelager'}
                                id={'InvestInInventory'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.InvestInInventory}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.InvestInInventory }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Vekst'}
                                id={'Growth'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.Growth}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.Growth }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Driftskapital'}
                                id={'WorkingCapital'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.WorkingCapital}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.WorkingCapital }) : null}
                            />
                        </div>
                    </li>
                    <li>
                        <div className={classes.box}>
                            <FormInput
                                type={'radio'}
                                label={'Annet'}
                                id={'Other'}
                                name={'loanPurpose'}
                                variant={'radio'}
                                checked={application?.purposeOfLoan === purposes.Other}
                                onChange={e => e.target.checked ? setApplication({ ...(application || {}), purposeOfLoan: purposes.Other }) : null}
                            />
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    )
}

const FourthStep = () => {
    return(
        <div className={classes.stepBoxHolder}>
            <div className={classes.boxHolder}>
                <SectionTitle textCenter description={"Vi har sendt din søknad til vår samarbeidspartner, og informasjon om lånesøknaden din er også sendt til din e-postadresse. Når vår samarbeidspartner har gjennomgått søknaden din, vil vi kontakte deg med tilbakemelding fra banken vi samarbeider med."}>
                    <span className={'fw-light'}>Takk for din </span>
                    <span className={'fw-semibold'}>søknad</span>
                </SectionTitle>
                <div className={'p-3'}>
                    <div className={'position-relative text-center'}>
                        <div className={'text-center mb-5'}>
                            <img src={Thankyou} alt={''} loading="lazy" />
                        </div>
                        <Button
                            text={'Se over din søknad'}
                            url={'/bedriftsdashbord/mine-lånesøknader'}
                        />
                    </div>
                </div>
                {/*<Alert variant={'success mb-5'}>
                    Søknaden din er sendt inn
                </Alert>
                <div className={'text-center'}>
                    <Button
                        text={'Se over din søknad'}
                        url={'/business-dashboard/my-loan-application'}
                    />
                </div>*/}
            </div>
        </div>
    )
}


const LoanApplications = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const amount = queryParams.get('loanAmount');
    const [errors, setErrors] = useState({});

    const [application, setApplication] = useState({amount});
    const validate = (currentStep) => {
        if (currentStep === 0) {
            const validator = new FirstStepValidator();
            const result = validator.validate({ orgNumber: application?.organization?.nationalOrganizationNumber });
            setErrors(result)
            return Object.values(result).length === 0
        }
        else if (currentStep === 1) {
            const validator = new SecondStepValidator();
            const result = validator.validate({
                companyEmail: application?.organization?.email,
                email: application?.applicant?.email,
                phone: application?.applicant?.phone,
                terms: agreement
            });
            setErrors(result)
            return Object.values(result).length === 0
        }
        else if (currentStep === 2) {
            const validator = new ThirdStepValidator();
            const result = validator.validate({
                purpose: application?.purposeOfLoan
            });
            setErrors(result)
            return Object.values(result).length === 0
        }
    }

    const [agreement, setAgreement] = useState(false);
    const [userAuthenticated, setUserAuthenticated] = useState(false);
    useEffect(() => {
        const token = getToken();
        setUserAuthenticated( token && token !== '' );
    }, []);
    return(
        <React.Fragment>
            <div className={'py-5'}>
                <FormWizard darkTheme ButtonHideLastStep application={application} agreement={agreement} validate={validate}>
                    {userAuthenticated ? null : <VerifyVipps />}
                    <FirstStep application={application} setApplication={setApplication} errors={errors} setErrors={setErrors}/>
                    <SecondStep application={application} setApplication={setApplication} agreement={agreement} setAgreement={setAgreement} errors={errors} setErrors={setErrors}/>
                    <ThirdStep application={application} setApplication={setApplication} errors={errors} setErrors={setErrors}/>
                    <FourthStep application={application} setApplication={setApplication} errors={errors} setErrors={setErrors}/>
                </FormWizard>
            </div>
        </React.Fragment>
    )
}

export default LoanApplications;