import React, {useEffect, useState} from 'react';
import classes from "../BusinessDashboard/index.module.scss";
import {Accordion, Col, Row, Tab, Tabs, Alert, Spinner} from "react-bootstrap";
import FindYourCompany from "../BusinessDashboard/FindYourCompany";
import Button from "../../../../Components/Button";
import CreditScore from "../../../../Components/Charts/CreditScore";
import Cards from "../BusinessDashboard/Cards";
import RangeSlider from "../../../../Components/RangeSlider";
import Summary from "../BusinessDashboard/Summary";
import CompanyScore from "../BusinessDashboard/CompanyScore";
import PaymentHistory from "../BusinessDashboard/PaymentHistory";
import Accounts from "../BusinessDashboard/Accounts";
import DirectorOwnerShip from "../BusinessDashboard/DirectorOwnerShip";
import SelectCompany from "../../../../Components/PackagesPlan/SelectCompany";
import { ensureDefaultCompany, userCompanyDetails } from '../../../../Services/api/Company';
import { getConsumerReport } from '../../../../Services/api/Auth';
import { useNavigate } from "react-router-dom";
import CompanyNotExist from "../../DashboardComponent/CompanyNotExist";

const showReviews = [
    { label: 'LAFACIL AS', value: 'lafacilAS' },
    { label: 'LAFACIL ASR', value: 'lafacilASR' },
    { label: 'LAFACIL NUF', value: 'lafacilNUF' },
];

const CreditCheckForOtherCompany = () => {
    const [companyDetails, setCompanyDetails] = useState({});
    const [user, setUser] = useState();
    const [companyEngagements, setCompanyEngagements] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        getConsumerReport()
        .then(({ companyEngagements }) => {
            setCompanyEngagements(companyEngagements);
            const user = JSON.parse(localStorage.getItem('auth'));
            setUser(user);
            if(companyEngagements && companyEngagements.length > 0 && !user?.hasDefaultCompany){
                navigate('/find-your-company')
            }
        })
        .catch(() => {})
    }, [])

    return(
        <React.Fragment>
            {user?.hasDefaultCompany ? <Accordion className={classes.companyList}>
                <Accordion.Item className={`${classes.accordionItem} ${!companyDetails ? "blur" : ''}`}>
                    <div className={classes.accordionHeader}>
                        <div className={classes.title}>
                            <FindYourCompany
                                onSelect={setCompanyDetails}
                                otherCompanies
                                opened
                                useTitle
                                absolute
                                company={companyDetails}
                            />
                        </div>
                    </div>
                    <Accordion.Body>
                        <Row>
                            <Col lg={4}>
                                <div className={classes.box}>
                                    <CreditScore score={{ currentCreditScore: {score: companyDetails?.report?.creditScore?.currentCreditRating?.providerValue?.value ?? 0, decision: companyDetails?.report?.creditScore?.currentCreditRating?.commonDescription ?? ''} }}/>
                                </div>
                            </Col>
                            <Col lg={8}>
                                <Cards internationalScore={companyDetails?.report?.creditScore?.currentCreditRating?.commonValue} creditLimit={companyDetails?.report?.creditScore?.currentCreditRating?.creditLimit?.value} hasPaymentRemarks={companyDetails?.report?.negativeInformation?.paymentRemarks?.length > 0} financialStrength={companyDetails?.report?.indicators?.find(ind => ind?.type === 'fsi')?.value } status={companyDetails?.report?.companySummary?.companyStatus?.status}/>
                            </Col>

                        </Row>
                        <div className={classes.tabHolder}>
                            <Tabs
                                defaultActiveKey="Summary"
                                className={classes.tabs}
                            >
                                <Tab eventKey="Summary" title="Summary">
                                    <Summary details={companyDetails}/>
                                </Tab>
                                <Tab eventKey="CompanyScore" title="Company score">
                                    <CompanyScore details={companyDetails}/>
                                </Tab>
                                <Tab eventKey="PaymentHistory " title="Payment history ">
                                    <PaymentHistory details={companyDetails}/>
                                </Tab>
                                <Tab eventKey="Accounts " title="Accounts">
                                    <Accounts details={companyDetails}/>
                                </Tab>
                                <Tab eventKey="DirectorsOwnership" title="Directors & ownership">
                                    <DirectorOwnerShip details={companyDetails}/>
                                </Tab>
                            </Tabs>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> : !companyEngagements || companyEngagements.length === 0 ? <CompanyNotExist /> : <Spinner animation='border'/>}


        </React.Fragment>
    );
}

export default CreditCheckForOtherCompany;