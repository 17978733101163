import React, { useEffect, useState } from 'react';
import SectionTitle from "../../../../Components/SectionTitle";
import PaymentRemarksList from "../../DashboardComponent/PaymentRemarksList";
import { getConsumerReport } from '../../../../Services/api/Auth';
import Loader from "../../../../Components/Loader";
import classes from "../../index.module.scss";
import CreditScore from "../../../../Components/Charts/CreditScore";
import {Row} from "react-bootstrap";
import AdAffiliatesBox from "../../DashboardComponent/AdAffiliatesBox";
import adDataPaymentRemarks from "./adDataPaymentRemarks";
import QuestionnaireRecommendation from "../../DashboardComponent/QuestionnaireRecommendation";
import SocialMediaShare from "../../../../Components/SocialMediaShare";
import Loading from "../../../../Components/Loading";
import DownLoadButtonPaymentRemarksPDF
    from "../../../../Components/PDF/Personal/PaymentRemarksPDF/DownLoadButtonPaymentRemarksPDF";
import PDFDownLoadButton from "../../../../Components/PDF/Personal/PDFDownLoadButton";


const PaymentRemaks = () => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [isMobile, setIsMobile] = useState(false);


    useEffect(() => {
        setLoading(true);
        getConsumerReport()
            .then(setData)
            .finally(() => setLoading(false))
    }, [])



    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return(
        <React.Fragment>
            {loading ? <Loading style={{position: 'absolute'}} /> :  <>
                <Row className={`${classes.dashboardCards} ${classes.col1_2} ${classes.heightAuto}`}>
                    <div className={classes.col}>
                        <div className={`${classes.box} mb-4`}>
                            {loading ? <Loader style={{minHeight: '376px', position: 'relative'}} /> : <CreditScore score={data?.score} />}
                        </div>

                        {isMobile ? '' : <AdAffiliatesBox data={adDataPaymentRemarks} /> }
                    </div>
                    <div className={classes.col}>
                        <div className={`${classes.box} mb-4`}>
                            <PDFDownLoadButton />
                            <SectionTitle className={'pt-3 pb-3'}>
                                <span className={'fw-bold'}>Betalingsanmerkninger</span>
                            </SectionTitle>
                            <div style={{minHeight: '200px', position: "relative"}}>
                                {loading ? <Loader /> : <PaymentRemarksList data={data} />}
                            </div>
                        </div>
                        {data?.paymentRemarkDetails?.remarksOfPayment?.length > 0 && <QuestionnaireRecommendation />}
                        {isMobile ? <AdAffiliatesBox data={adDataPaymentRemarks} /> : '' }
                    </div>
                </Row>
                <SocialMediaShare
                    PostUrl={'https://easyfinance.no'}
                    PostTitle={'Sjekk ut denne nettsiden! Gratis kredittsjekk, gjeldsoversikt og boligverdi. Få bedre forståelse av din økonomi!'}
                />
            </> }
        </React.Fragment>
    )
}

export default PaymentRemaks;