import React from "react";
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import { onError } from "Services/api/Image";
import AnimatedImage from "../../Components/AnimatedImg";
import SectionTitle from "Components/SectionTitle";


const CompaignBanner = ({detail}) => {
    return(
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    {detail?.heading?.title && detail?.heading?.description &&
                        <SectionTitle textCenter description={detail?.heading?.description}>
                            {
                                detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                            }
                        </SectionTitle>
                    }
                    <div className={classes.imgBox}>
                        <a href={detail?.redirectUrl}>
                            <AnimatedImage
                                image={detail?.featuredImage}
                                loop={true}
                                autoplay={true}
                            />
                        </a>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default CompaignBanner;