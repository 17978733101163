import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';
import {Alert, Col, Form, Row, Spinner} from 'react-bootstrap';
import FormInput from '../../../../Components/FormInput';
import Button from '../../../../Components/Button';
import bankID from '../../../../Images/bankID.svg';
import SetPassword from './SetPassword';
import { deactivateAccount, getCurrentUser, updateUser } from '../../../../Services/api/Auth';
import moment from 'moment/moment';

const ProfileSetting = () => {
    const [editPassword, setEditPassword] = React.useState(false);
    const [deactivateError, setDeactivateError] = useState(false);
    const [deactivateAgreementAccepted, setDeactivateAgreementAccepted] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(false);
    const [deactivating, setDeactivating] = useState(false);
const incomeOptions = [{value: 'Under 500 000 kr', label: 'Under 500 000 kr'}, {value: 'Mellom 500 000 - 1 000 000 kr', label: 'Mellom 500 000 - 1 000 000 kr'}, {value: 'Over 1 000 000 kr', label: 'Over 1 000 000 kr'}];
const educationOptions = [{value: 'Grunnskole', label: 'Grunnskole'}, {value: 'Videregående skole', label: 'Videregående skole'}, {value: 'Høyskole 3 år', label: 'Høyskole 3 år'}, {value: 'Høyskole 3 år +', label: 'Høyskole 3 år +'}];
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        email: '',
        address: '',
        income: '',
        education: '',
        residence: '',
        verification: false,
        sms: false,
        newsletterEmail: false,
    });

    useEffect(() => {
        getCurrentUser()
        .then(user => {
            setUserData(user)
        })
    }, []);
    const _handleUpdate = () => {
        setUpdating(true);
        setUpdateError(false);
        updateUser({...userData, IsEmailSubscribed: userData?.emailSubscribed, IsSMSSubscribed: userData?.smsSubscribed})
        .then(resp => {
            
        })
        .catch(() => setUpdateError(true))
        .finally(() => setUpdating(false))
    }
    const _handleAccountDeactivation = () => {
        setDeactivating(true);
        setDeactivateError(false);
        deactivateAccount()
        .then(() => {
            setDeactivating(false);
            localStorage.removeItem('auth');
            window.location.href = '/'
        })
        .catch(() => {
            setDeactivateError(true);
        })
    }
    return (
        <React.Fragment>
            <div className={'contentBox'}>

                <div className={classes.sectionHeader}>
                    <h4>Personlig informasjon</h4>
                </div>
                {/* <ul className={classes.steps}>
                    {steps.map((stepNumber) => (
                        <li
                            key={stepNumber}
                            className={`step${isStepComplete(stepNumber) ? ` ${classes.done}` : ''}${
                                stepNumber === activeStep ? ` ${classes.active}` : ''
                            }`}
                        >
                            <div className={classes.iconBox}>
                                <i className={'fal fa-star'}></i>
                            </div>
                            {/*{stepNumber}/}
                        </li>
                    ))}
                </ul> */}
                <Row>
                    <Col lg={3}>
                        <FormInput
                            disabled
                            type={'text'}
                            fieldLabel={'First Name'}
                            value={userData.firstName}
                            onChange={e => setUserData({...userData, firstName: e.target.value})}
                            readonly
                        />
                    </Col>
                    <Col lg={3}>
                        <FormInput
                            disabled
                            type={'text'}
                            fieldLabel={'Last Name'}
                            value={userData.lastName}
                            onChange={e => setUserData({...userData, lastName: e.target.value})}
                            readonly
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormInput
                            disabled
                            type={'text'}
                            fieldLabel={'Fødselsdato'}
                            value={userData.dob && userData.dob !== '' ? moment(userData.dob).format('DD/MM/YYYY') : ''}
                            readonly
                        />
                    </Col>
                    <Col lg={6}>
                        <FormInput
                            type={'email'}
                            fieldLabel={'E-post'}
                            value={userData.email}
                            onChange={(e) => setUserData({ ...userData, email: e.target.value })}
                            readonly
                        />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <FormInput
                            type={'text'}
                            fieldLabel={'Adresse'}
                            value={userData.address}
                            onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                            readonly
                        />
                    </Col>

                    <Col lg={6}>
                        <Form.Label className={'font-13 mb-1 form-label'} style={{ color: '#4B4B4B' }}>
                            Boforhold
                        </Form.Label>
                        <div className={'d-flex'}>
                            <FormInput
                                type={'radio'}
                                label={'Eier'}
                                variant={'radio'}
                                id={'ownHome'}
                                name={'residence'}
                                checked={userData.livingCondition === 'Own home'}
                                onChange={() => setUserData({ ...userData, livingCondition: 'Own home' })}
                            />
                            <FormInput
                                type={'radio'}
                                label={'Leier'}
                                variant={'radio'}
                                id={'rental'}
                                name={'residence'}
                                checked={userData.livingCondition === 'Rental'}
                                onChange={() => setUserData({ ...userData, livingCondition: 'Rental' })}
                            />
                            <FormInput
                                type={'radio'}
                                label={'Bor med foreldre'}
                                variant={'radio'}
                                id={'liveWithParents'}
                                name={'liveWithParents'}
                                checked={userData.livingCondition === 'Live With Parents'}
                                onChange={() => setUserData({ ...userData, livingCondition: 'Live With Parents' })}
                            />
                        </div>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col lg={6}>
                        <FormInput
                            type={'select'}
                            fieldLabel={'Inntekt'}
                            options={incomeOptions}
                            value={{value: userData.income, label: userData.income}}
                            onChange={({value}) => {
                                setUserData({ ...userData, income: value })
                            }}
                        />
                    </Col>
                    <Col lg={6}>
                        <FormInput
                            type={'select'}
                            fieldLabel={'Utdannelse'}
                            options={educationOptions}
                            value={{value: userData.education, label: userData.education}}
                            onChange={({value}) => setUserData({ ...userData, education: value })}
                        />
                    </Col>
                    <Col lg={6}>
                        <Form.Label className={'font-13 mb-1 form-label'} style={{ color: '#4B4B4B' }}>
                            Markedsføring
                        </Form.Label>
                        <div className={'d-flex py-4'}>
                            <FormInput
                                type={'checkbox'}
                                label={'SMS'}
                                id={'sms'}
                                variant={'checkboxSwitch'}
                                name={'marketing'}
                                checked={userData.smsSubscribed ? userData.smsSubscribed : 'checked'}
                                onChange={() => setUserData({ ...userData, smsSubscribed: !userData.smsSubscribed })}
                            />
                            <FormInput
                                type={'checkbox'}
                                label={'E-post'}
                                id={'NewsletterEmail'}
                                variant={'checkboxSwitch'}
                                name={'marketing'}
                                checked={userData.emailSubscribed ? userData.emailSubscribed : 'checked'}
                                onChange={() =>
                                    setUserData({ ...userData, emailSubscribed: !userData.emailSubscribed })
                                }
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <div className={'text-right'}>
                            <Button text={updating ? <Spinner size={'sm'} /> : 'Oppdater'} onClick={_handleUpdate}/>
                        </div>
                        {updateError && <Alert variant={"danger"}>Det oppstod en feil under oppdatering av bruker. Prøv igjen senere.</Alert>}
                    </Col>
                </Row>
                <hr className={'my-5'} />
                <Row>
                    <Col md={6}>
                        <Form.Label className={'font-13 mb-1 form-label'} style={{ color: '#FF3030' }}>Slette konto</Form.Label>
                        <FormInput type={'dropdown'} placeHolder={'Slett kontoen din hos Easyfinance.no'}>
                            <p>Sletting av en konto vil fjerne personlig informasjon og historie.</p>
                            <ol>
                                <li>Kryss av for at du vil slette kontoen din, og klikk på "Slett konto"-knappen.</li>
                                <li>Trekk tilbake ditt samtykke fra Vipps</li>
                            </ol>
                            <FormInput
                                type={'checkbox'}
                                label={'Jeg ønsker å slette kontoen min'}
                                id={"deleteAccount"}
                                checked={deactivateAgreementAccepted}
                                onChange={() => setDeactivateAgreementAccepted(!deactivateAgreementAccepted)}
                            />
                            <Button
                                disabled={!deactivateAgreementAccepted || deactivating}
                                onClick={_handleAccountDeactivation}
                                type={'submit'}
                                variant={'small danger'}
                                text={deactivating ? <Spinner style={{width: 20, height: 20, fontSize: 10}}/> : 'Slett konto'}
                            />
                        </FormInput>
                        {deactivateError && <p className='text-danger'>Det oppstod en feil under deaktivering av kontoen.</p>}
                    </Col>
                </Row>
            </div>
            <SetPassword show={editPassword} onHide={() => setEditPassword(false)} />
        </React.Fragment>
    );
};

export default ProfileSetting;
