import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import classes from './index.module.scss';
import { Container } from 'react-bootstrap';

const Breadcrumbs = ({ timeStamp }) => {
    const location = useLocation();
    const [paths, setPaths] = useState([]);
    const currentDate = new Date(); // Get the current date

    useEffect(() => {
        const pathname = window.location.pathname;
        const pathSegments = getPathSegments(pathname);
        setPaths(pathSegments);
    }, [location]);

    function getPathSegments(path) {
        return path.split('/').filter((segment) => segment !== '').map((segment) => decodeURIComponent(segment));
    }

    function removeDashes(text) {
        return text.replace(/-/g, ' ');
    }

    function formatTimestamp(dateString) {
        let date;
        if (dateString) {
            if (!isNaN(Date.parse(dateString))) {
                date = new Date(dateString);
            } else {
                date = currentDate;
            }
        } else {
            date = currentDate;
        }
        return date.toLocaleDateString('nb-NO');
    }


    return (
        <React.Fragment>
            <div className={classes.breadcrumbsHolder}>
                <Container>
                    <ul className={classes.breadcrumbs}>
                        <li><Link to={'/'}>Easyfinance</Link></li>
                        {paths.map((path, index) => (
                            <li key={index} className={index === paths.length - 1 ? 'active' : ''}>
                                {index !== paths.length - 1 ? (
                                    <a href={`/${path}`}>{removeDashes(path)}</a>
                                ) : (
                                    <span>{removeDashes(path)}</span>
                                )}
                            </li>
                        ))}
                    </ul>
                    {timeStamp !== undefined ? (
                        <time>Oppdatert. {formatTimestamp(timeStamp)}</time>
                    ) : (
                        <time>Oppdatert. {formatTimestamp(currentDate)}</time>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Breadcrumbs;
