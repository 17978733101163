import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import UserDashboardHeader from "../../Components/Header/UserDashboardHeader";
import {useLocation} from "react-router-dom";
import ShortNav from "../../Components/Navigation/ShortNav";
import Logo from "../../Components/Logo";

const PlainLayoutWithoutHeader = ({ children }) => {
    const location = useLocation();
    const hide = () => {
        const { pathname } = location;
        return pathname === "/recommened-offers" || pathname === "/find-your-company";
    };

    return (
        <React.Fragment>
            <div id={classes.wrapper}>
                <header id={classes.header}>
                    <Logo />
                </header>
                <div className={classes.wrapperHolder}>
                    <main id={classes.main}>
                        <Container>
                            <section className={'section'}>
                                {children}
                                {/*<hr />*/}
                                {/*<ShortNav />*/}
                            </section>
                        </Container>
                    </main>
                </div>
            </div>
        </React.Fragment>
    );
};

export default PlainLayoutWithoutHeader;
