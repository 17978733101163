import React from 'react';
import classes from './index.module.scss';
import img from '../../../../Images/c3.svg';


const CompanyNotExist = () => {
  return(
      <React.Fragment>
          <div className={classes.holder}>
              <div className={classes.box}>
                  <div className={classes.imgBox}>
                      <img src={img} loading="lazy" />
                  </div>
                  <div className={classes.description}>
                      <h4>Beklager, men det ser ut til at du ikke har noe selskap registrert.</h4>
                      <p>Tilgang til vår bedriftsportal krever at tilgang til vår bedriftsportal krever at du har verv eller eierinteresser i et registrert selskap. Hvis du mener dette er en feil eller trenger hjelp, vennligst kontakt oss på post@easyfinance.no. Vi er her for å hjelpe deg med å løse eventuelle problemer.Hvis du mener dette er en feil eller trenger hjelp, vennligst kontakt oss på post@easyfinance.no.</p>
                  </div>
              </div>
          </div>
      </React.Fragment>
  )
}

export default CompanyNotExist;