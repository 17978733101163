import { getToken } from "./Auth";

export const saveSearch = (searchObject, token) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/LoanUserSearch/Post`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}`},
        body: JSON.stringify({...searchObject, createdBy: ""})
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else reject(resp);
    })
    .then(resolve)
    .catch(reject)
});

export const loadAllSearches = (languageId) => new Promise((resolve, reject) => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_API}/LoanUserSearch/GetAll?languageId=${languageId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json()
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject);
});