import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';
import Header from "../../Components/Header";
import Footer from "../../Components/Footer";

const PlainLayoutOnlyHeader = ({ children }) => {

    return (
        <React.Fragment>
            <div id={classes.wrapper}>
                <Header hideTopHeader hideNavigation />
                <main id={classes.main}>
                    {children}
                </main>
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default PlainLayoutOnlyHeader;
