import React from "react";
import data from "../data";
import SectionTitle from "../../SectionTitle";
import classes from "../index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../Button";
import {Badge} from "react-bootstrap";

const AllEditorChoice = ({ details }) => {
    const choicePosts = data?.filter(item => item.choice); // Filter and limit to 6 posts

    return (
        <React.Fragment>
            <section className={classes.blogSection}>
                <SectionTitle className={'pb-0'}>
                    <span className={'fw-light'}>Editor</span>
                    <span className={'fw-semibld'}>Choice</span>
                </SectionTitle>
                <div className={`${classes.colPost}`}>
                    {choicePosts.map((item) => (
                        <div className={classes.col} key={item.id}>
                            <div className={classes.box}>
                                <Link to={`/all-editor-choice/${item.id}`} className={classes.imgBox} style={{ backgroundImage: `url(${item.postImg})` }}></Link>
                                <div className={classes.description}>
                                    <div className={classes.infoRow}>
                                        <Badge>{item.postDate}</Badge>
                                        <Badge><i className={'fas fa-eye'}></i> {item.views}</Badge>
                                    </div>
                                    <h4 className={classes.title}><Link to={`/all-editor-choice/${item.id}`}>{item.title}</Link></h4>
                                    <p>{item.description}</p>
                                    <Button
                                        text={'Read More'}
                                        url={`/all-editor-choice/${item.id}`}
                                        variant={'link'}
                                        icon={'far fa-arrow-right'}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className={'py-3  border-bottom overflow-hidden'}>
                    <Button
                        url={'/all-editor-choice'}
                        text={'View More'}
                        icon={'far fa-arrow-right'}
                    />
                </div>
            </section>
        </React.Fragment>
    );
}

export default AllEditorChoice;
