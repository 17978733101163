import React from 'react';
import classes from './index.module.scss';
import {Container} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import Button from "../Button";


const CallToAction = ({data}) => {
  return(
      <React.Fragment>
          {data.map((item, index) => (
              <section key={index} className={`section ${classes.callToAction}`}>
                  <Container>
                      <SectionTitle className={'pb-0'} description={''}>
                          {item?.heading?.title?.map(title => (
                              <span key={title.id} className={title.class}>{title.title} </span>
                          ))}
                      </SectionTitle>
                      <div>{item.description}</div>
                      <Button
                          text={item.buttonText}
                          url={item.buttonUrl}
                          variant={'orange mt-4'}
                          icon={'far fa-arrow-right '}
                      />
                  </Container>
              </section>
          ))}
      </React.Fragment>
  )
}

export default CallToAction;