import React from 'react';
import classes from './index.module.scss';
import {
    EmailIcon,
    EmailShareButton, FacebookIcon, FacebookMessengerIcon, FacebookMessengerShareButton,
    FacebookShareButton,
    GabShareButton,
    HatenaShareButton,
    InstapaperShareButton,
    LineShareButton, LinkedinIcon,
    LinkedinShareButton,
    LivejournalShareButton,
    MailruShareButton,
    OKShareButton,
    PinterestShareButton,
    PocketShareButton,
    RedditShareButton, TelegramIcon,
    TelegramShareButton,
    TumblrShareButton, TwitterIcon,
    TwitterShareButton,
    ViberShareButton,
    VKShareButton, WhatsappIcon,
    WhatsappShareButton,
    WorkplaceShareButton, XIcon,

} from "react-share";

const SocialMediaShare = ({PostUrl, PostTitle}) => {
    // const shareUrl = 'https://easyfinance.no';
    // const title = 'Sjekk ut denne nettsiden! Gratis kredittsjekk, gjeldsoversikt og boligverdi. Få bedre forståelse av din økonomi! www.easyfinance.no';

    const shareUrl = PostUrl;
    const title = PostTitle;

  return(
      <React.Fragment>
          <div className={classes.shareBox}>
              <p>Del gjerne vår tjeneste med dine bekjente, slik at flere kan lære mer om sin egen privatøkonomi. Dette bidrar til vår vekst og muligheten til å tilby enda bedre tjenester tilpasset våre brukeres behov!</p>
              <div className={classes.iconList}>
                  <FacebookShareButton url={shareUrl} title={title}><FacebookIcon size={24} round={true} /></FacebookShareButton>
                  <FacebookMessengerShareButton url={shareUrl} title={title} round={true} size={24}><FacebookMessengerIcon size={24} round={true} /> </FacebookMessengerShareButton>
                  <WhatsappShareButton url={shareUrl} size={24} title={title} round={true}><WhatsappIcon size={24} round={true} /> </WhatsappShareButton>
                  <TwitterShareButton url={shareUrl} size={24} title={title} round={true}><XIcon size={24} round={true} /></TwitterShareButton>
                  <TelegramShareButton url={shareUrl} size={24} title={title} round={true}><TelegramIcon size={24} round={true} /></TelegramShareButton>
                  <EmailShareButton url={shareUrl} size={24} title={title} round={true}><EmailIcon size={24} round={true} /> </EmailShareButton>
                  <LinkedinShareButton url={shareUrl} size={24} title={title} round={true}><LinkedinIcon size={24} round={true} /> </LinkedinShareButton>
              </div>
          </div>
      </React.Fragment>
  )
}

export default SocialMediaShare;