const bannerData =  [
    {
      id: 1,
      subTitle: "",
      title: [
        { id: 1, title: "Fikk du ", class: "fw-normal" },
        { id: 2, title: "ikke bedriftslån??", class: "fw-bold" },
      ],
      description:
      <>
          <p>Når det kommer til å søke om bedriftslån, er det viktig å forstå kriteriene og mulighetene som er tilgjengelige for din bedrift. Her er noen nyttige punkter og ofte stilte spørsmål for potensielle låntakere:</p>
      </>,
        btnText: "Se hvor mye du kan lån",
        btnUrl: "/bedriftsdashbord",
        hideCalculator: true,
    }
  ]


export default bannerData;
