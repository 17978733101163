import React from 'react';
import {Modal} from "react-bootstrap";
import classes from './index.module.scss';
import PackagesPlan from "../../../../../Components/PackagesPlan";


const SelectPackagePopup = (props) => {
  return(
      <React.Fragment>
          <Modal
              {...props}
              size="xl"
              className={classes.selectPackagePopup}
          >
              <Modal.Header closeButton></Modal.Header>
              {/* TODO: PRODUCTION */}
              {/* <PackagesPlan companyInfo={props?.companyInfo}/> */}
          </Modal>
      </React.Fragment>
  )
}

export default SelectPackagePopup;