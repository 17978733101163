import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {Table} from "react-bootstrap";

const Results = ({ details }) => {
    const [dates, setDates] = useState([]);
    const [data, setData] = useState({}); // data from local financial statements
    const [fsData, setFsData] = useState({}); // data from financial statements => FS

    useEffect(() => {
        if(!details?.localFinancialStatements || details?.localFinancialStatements?.length === 0) return;
        setDates(details?.localFinancialStatements?.map(fs => moment(fs.yearEndDate).format('DD/MM/YYYY')));
        const transformedObject = details?.localFinancialStatements?.map(({profitAndLoss}) => profitAndLoss)?.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr[key]);
            });
            return acc;
        }, {});

        const fsObject = details?.financialStatements?.map(({profitAndLoss}) => profitAndLoss)?.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr[key]);
            });
            return acc;
        }, {});
        setFsData(fsObject);
        setData(transformedObject);

    }, [details])

    const getPercentage = (number, array, index, attachSymbol = false) => {
        if (index < 0 || index >= array.length - 1) {
            return null; // Index out of range or no next index
        }
    
        const currentValue = Math.abs(array[index]);
        const nextValue = Math.abs(array[index + 1]);
    
        let percentageValue = ((currentValue - nextValue) / nextValue) * 100;
        // const percentageValue = ((array[index - 1] - number) / number) * 100;
        if(isNaN(percentageValue) || percentageValue === 0 || percentageValue === Infinity || percentageValue === -Infinity)
        return '-'
        percentageValue = percentageValue.toFixed(1);
        if(Math.abs(percentageValue) > 1000) percentageValue = (percentageValue / 1000).toFixed(1) + (attachSymbol ? 'K' : 0)
        return attachSymbol ? percentageValue + ' %' : percentageValue;
    }
    const formatter = Intl.NumberFormat('no-NO');
    const format= (value, divideBy1000 = true) => isNaN(value) ? 0 : formatter.format(value / (divideBy1000 ? 1000 : 1));
    const getRow = (arr) => {
        if(!arr)
            return <>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </>
        return arr?.map((number, index) => {
            return <React.Fragment key={index}>
                <td key={`${index}-number`} colSpan={1}>
                    {format(number) ?? '-'}
                </td>
                {index < arr?.length - 1 && (
                    <td key={`${index}-percentage`} colSpan={1}>
                        <span className={isNaN(getPercentage(number, arr, index)) ? '' : getPercentage(number, arr, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, arr, index, true)}<i className={isNaN(getPercentage(number, arr, index)) ? '' : getPercentage(number, arr, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                    </td>
                )}
            </React.Fragment>
        })
    }

    return(
        <React.Fragment>
            <h5>Resultater</h5>
            <div className={'mb-5 table-responsive'}>
                <Table className={'border'} style={{minWidth: "850px"}}>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        {dates?.map((date, index) => (
                            <React.Fragment key={index}>
                                <th colSpan={1}>{date}</th>
                                {index < dates.length - 1 && <th colSpan={1}></th>}
                            </React.Fragment>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th data-label="Name">Sum salgsinntekter</th>
                        {getRow(data?.salesRevenue)}
                    </tr>
                    <tr>
                        <th data-label="Name">Annen driftsinntekt</th>
                        {getRow(data?.otherOperatingIncome)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum driftsinntekter</th>
                        {getRow(data?.totalOperatingIncome)}
                    </tr>
                    <tr>
                        <th data-label="Name">Varekostnad</th>
                        {getRow(data?.costOfGoods)}
                    </tr>
                    <tr>
                        <th data-label="Name">Endr. behold. varer under tilvirk./ferdige</th>
                        {getRow(data?.changeInStockOfWorkInProgress)}
                    </tr>
                    <tr>
                        <th data-label="Name">Beholdningsendringer</th>
                        {getRow(data?.changeInWorkInProgress)}
                    </tr>
                    <tr>
                        <th data-label="Name">Lønnskostnader</th>
                        {getRow(data?.wagesAndSalaries)}
                    </tr>
                    <tr>
                        <th data-label="Name">Avskrivninger</th>
                        {getRow(fsData?.depreciation)}
                    </tr>
                    <tr>
                        <th data-label="Name">Nedskrivninger</th>
                        {getRow(data?.writedownOfTangibleAndIntangibleFixed)}
                    </tr>
                    <tr>
                        <th data-label="Name">Andre driftskostnader</th>
                        {getRow(data?.otherOperatingExpenses)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum driftskostnader</th>
                        {getRow(data?.totalOperatingExpenses)}
                    </tr>
                    <tr>
                        <th data-label="Name">Driftsresultat</th>
                        {getRow(data?.operatingProfit)}
                    </tr>
                    <tr>
                        <th data-label="Name">Mottatt utbytte</th>
                        {getRow(data?.allocatedDividends)}
                    </tr>
                    <tr>
                        <th data-label="Name">Inntekt på investering i datterselskap</th>
                        {getRow(data?.incomeFromSubsidiaries)}
                    </tr>
                    <tr>
                        <th data-label="Name">Inntekt på investering i annet foretak i samme konsern</th>
                        {getRow(data?.incomeFromGroupCompanies)}
                    </tr>
                    <tr>
                        <th data-label="Name">Inntekt på investering i tilknyttet selskap</th>
                        {getRow(data?.incomeFromAssociatedCompanies)}
                    </tr>
                    <tr>
                        <th data-label="Name">Renteinntekt fra foretak i samme konsern</th>
                        {getRow(data?.interestIncomeFromGroupCompanies)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum annen renteinntekt</th>
                        {getRow(data?.otherInterestIncome)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum annen finansinntekt</th>
                        {getRow(data?.otherFinancialIncome)}
                    </tr>
                    <tr>
                        <th data-label="Name">Finansinntekt</th>
                        {getRow(data?.financialIncome)}
                    </tr>
                    <tr>
                        <th data-label="Name">Verdiendring markedsbaserte finansielle omløpsmidler</th>
                        {getRow(data?.changesInValueOfQuotedFinancialCurrentAssets)}
                    </tr>
                    <tr>
                        <th data-label="Name">Nedskrivning av andre finansielle omløpsmidler</th>
                        {getRow(data?.writeDownOnOtherAssets)}
                    </tr>
                    <tr>
                        <th data-label="Name">Nedskrivning finansielle anleggsmidler</th>
                        {getRow(data?.writeDownOnFinancialFixedAssets)}
                    </tr>
                    <tr>
                        <th data-label="Name">Renter til konsern</th>
                        {getRow(data?.otherInterestExpensesFromGroupComapnies)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum annen rentekostnad</th>
                        {getRow(data?.otherInterestExpenses)}
                    </tr>
                    <tr>
                        <th data-label="Name">Andre finanskostnader</th>
                        {getRow(data?.otherFinancialExpenses)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum annen finanskostnad</th>
                        {getRow(data?.otherFinancialCosts)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum finanskostnader</th>
                        {getRow(data?.financialExpenses)}
                    </tr>
                    <tr>
                        <th data-label="Name">Netto finans</th>
                        {getRow(data?.netFinancialItems)}
                    </tr>
                    <tr>
                        <th data-label="Name">Ordinært resultat før skattekostnad</th>
                        {getRow(data?.profitBeforeTax)}
                    </tr>
                    <tr>
                        <th data-label="Name">Skattekostnad på ordinært resultat</th>
                        {getRow(data?.tax)}
                    </tr>
                    <tr>
                        <th data-label="Name">Ordinært resultat</th>
                        {getRow(data?.profitAfterTax)}
                    </tr>
                    <tr>
                        <th data-label="Name">Netto ekstrardinære poster</th>
                        {getRow(data?.extraordinaryResult)}
                    </tr>
                    <tr>
                        <th data-label="Name">Årsresultat før minoritetsinteresser</th>
                        {getRow(data?.netProfitLossForYearBeforeMinorityInterests)}
                    </tr>
                    <tr>
                        <th data-label="Name">Skattekostnad på ekstraordinært resultat</th>
                        {getRow(data?.taxOnExtraordinaryResult)}
                    </tr>
                    <tr>
                        <th data-label="Name">Minoritetens andel før årsresultat</th>
                        {getRow(data?.minorityShareBeforeYearResult)}
                    </tr>
                    <tr>
                        <th data-label="Name">Årsresultat</th>
                        {getRow(data?.netResultAfterDividends)}
                    </tr>
                    <tr>
                        <th data-label="Name">Avsatt utbytte</th>
                        {getRow(data?.dividends)}
                    </tr>
                    <tr>
                        <th data-label="Name">Konsernbidrag</th>
                        {getRow(data?.groupContributions)}
                    </tr>
                    <tr>
                        <th data-label="Name">Overføringer til/fra annen egenkapital</th>
                        {getRow(data?.allocationOtherEquityOfPreviousUncoveredLoss)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum overføringer og disponeringer</th>
                        {getRow(data?.totalTransfersAndAllocations)}
                    </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default Results;