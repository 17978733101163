import React, { useEffect, useState, useRef } from 'react';
import classes from "./index.module.scss";
import { useLocation } from 'react-router-dom';
import {Alert, Col, Container, Form, Nav, Row} from "react-bootstrap";
import FormInput from "../../../Components/FormInput";
import FilterBox from "../../../Components/FilterBox";
import Button from "../../../Components/Button";
import ProductListing from "../../../Components/ProductListing";
import {NavLink} from "react-router-dom";
//import conusmerLoanImg from "../../../Images/consumerLoanImg.svg";
import conusmerLoanImg from "./Check.json";
import json2 from "../../../Images/IllustrationAnimation.json";
import json3 from "../../../Images/CreditCheckYourself.json";
import RangeSlider from "../../../Components/RangeSlider";
import SectionTitle from "../../../Components/SectionTitle";
import AnimatedImage from "../../../Components/AnimatedImg";



const TabsWithFilter = () => {

    const location = useLocation();
    const selectedLoanType = location.pathname;


    return(
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <div className={`${classes.tabsFilter}`}>
                        <Nav as="ul" className={classes.navTabs}>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/consumer-loans"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon23'}></i>
                                    </div>
                                    <span>Forbrukslån</span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/refinancing-loan"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon24'}></i>
                                    </div>
                                    <span>Refinansiering lån </span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/small-loans"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon25'}></i>
                                    </div>
                                    <span>Smålån</span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/micro-loans"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon28'}></i>
                                    </div>
                                    <span> Microlån</span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/travel-loans"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon29'}></i>
                                    </div>
                                    <span>Reiselån</span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/blank-loans"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon26'}></i>
                                    </div>
                                    <span>Blancolån</span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/loan-without-security/loan-for-renovation"}>
                                    <div className={classes.iconBox}>
                                        <i className={'icon-icon27'}></i>
                                    </div>
                                    <span>Lån til oppussing </span>
                                </NavLink>
                            </Nav.Item>
                        </Nav>
                        <Form>
                            <div className={classes.FilterBox}>
                                {selectedLoanType === '/loan-without-security/consumer-loans' && (
                                    <React.Fragment>
                                        <div className={`${classes.contentHolder}`}  >
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={conusmerLoanImg}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Lånebeløp'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'5'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <Row>
                                                    <Col md={6}>
                                                        <Alert variant={'primary'}>
                                                            Nedbetalingstid over 5 år kan kun tilbys om du skal bruke lånet til refinansiering.
                                                        </Alert>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className={'text-center'}>Skal lånet brukes til refinansiering?</div>
                                                        <div className={'d-flex align-items-center justify-content-center'}>
                                                            <FormInput
                                                                type={'radio'}
                                                                label={'Ja'}
                                                                variant={'switch'}
                                                                id={'ja'}
                                                                name={'used'}
                                                                value={'ja'}
                                                            />
                                                            <FormInput
                                                                type={'radio'}
                                                                label={'Nei'}
                                                                variant={'switch'}
                                                                id={'Nei'}
                                                                name={'used'}
                                                                value={'Nei'}
                                                            />
                                                        </div>

                                                    </Col>
                                                </Row>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                                {selectedLoanType === '/loan-without-security/refinancing-loan' && (
                                    <React.Fragment>
                                        <div className={classes.contentHolder}>
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={json2}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån har du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'350000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån trenger du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'5'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                                {selectedLoanType === '/loan-without-security/small-loans' && (
                                    <React.Fragment>
                                        <div className={classes.contentHolder}>
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={json3}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån har du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'350000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån trenger du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'5'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                                {selectedLoanType === '/loan-without-security/micro-loans' && (
                                    <React.Fragment>
                                        <div className={classes.contentHolder}>
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={conusmerLoanImg}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån har du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'350000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån trenger du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'5'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                                {selectedLoanType === '/loan-without-security/travel-loans' && (
                                    <React.Fragment>
                                        <div className={classes.contentHolder}>
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={conusmerLoanImg}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån har du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'350000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån trenger du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'5'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                                {selectedLoanType === '/loan-without-security/blank-loans' && (
                                    <React.Fragment>
                                        <div className={classes.contentHolder}>
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={conusmerLoanImg}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån har du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'350000'}
                                                            type={'input'}
                                                            label={'Hvor mye lån trenger du?'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'5'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                                {selectedLoanType === '/loan-without-security/loan-for-renovation' && (
                                    <React.Fragment>
                                        <div className={classes.contentHolder}>
                                            <div className={classes.imgBox}>
                                                <AnimatedImage
                                                    image={conusmerLoanImg}
                                                    loop={true}
                                                    autoplay={true}
                                                />
                                            </div>
                                            <div className={classes.contentBox}>
                                                <div className={classes.inputRow}>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'100000'}
                                                            valueMin={'100000'}
                                                            valueMax={'600000'}
                                                            defaultValue={'300000'}
                                                            type={'input'}
                                                            label={'Lånebeløp'}
                                                            unit={'kr'}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <RangeSlider
                                                            stepValue={'1'}
                                                            valueMin={'1'}
                                                            valueMax={'15'}
                                                            defaultValue={'7'}
                                                            type={'input'}
                                                            label={'Nedbetalingstid'}
                                                            unit={'år'}
                                                        />
                                                    </Col>
                                                </div>
                                                <div className={classes.btnRow}>
                                                    <Button
                                                        text={'Sammenlign'}
                                                        icon={'fas fa-arrow-right'}
                                                        url={'/loan-without-security/consumer-loans'}
                                                    />
                                                    <div className={classes.loanExample}>Låneeksempel: Effektiv rente 13,58% ved lån på 95 000 over 5 år. Totalt: kr 129 128</div>
                                                </div>
                                            </div>
                                        </div>
                                        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Semper quam eros sit id nunc quis risus tortor quis. Vehicula rutrum interdum sed sed iaculis donec aliquam.'}>
                                            <span className={'fw-normal'}>Norway's </span>
                                            <span className={'fw-semibold'}>cheapest mortgage</span>
                                            <span className={'fw-normal'}>Wednesday 7 June 2023</span>
                                        </SectionTitle>
                                    </React.Fragment>
                                )}
                            </div>
                        </Form>
                    </div>
                    <div className={classes.listingContainer}>
                        <div className={classes.listHolder}>
                            <FilterBox title={'Advance Search'} />
                            <ProductListing />
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default TabsWithFilter;