import React  from "react";


const SocialLinks=({url, icon, label})=>{

    return(
        <li><a href={url} target="_blank"><i label={label} className={icon}></i> </a></li>
    )
}

export default SocialLinks;



