import React, {useEffect, useState} from 'react';
import classes from './index.module.scss';
import SectionTitle from "../SectionTitle";
import {Col, Form, Row, Alert} from "react-bootstrap";
import FormInput from "../FormInput";
import data from './data.json';
import Package from "./Package";
import Slider from "react-slick";
import Button from "../Button";
import SelectPackagePopup from "../../Pages/UserDashboard/Pages/BusinessDashboard/FindYourCompany/SelectPackagePopup";
import StartPayment from "../Payment/StartPayment";
import { ensureDefaultCompany } from '../../Services/api/Company';
import { useSearchParams } from 'react-router-dom';







const options = [
    { label: 'Lafacil AS (81 819 819)', value: 'lafacilAS' },
    { label: 'Lafacil Oslo (81 819 820)', value: 'lafacilOslo' },
    { label: 'Lafacil PK (81 819 821)', value: 'lafacilPK' },
];

const SelectCompany = ({forAnotherCompany}) => {
    const [searchParams] = useSearchParams();
    const [activePackageId, setActivePackageId] = useState('');
    const [company, setCompany] = useState({});
    const [error, setError] = useState(false);
    
    const [modalShow, setModalShow] = useState(false);

    const handlePackageButtonClick = () => {
        setModalShow(true); // Open the second modal
    };

    const [selectedOption, setSelectedOption] = useState(null);
    const handleOptionChange = (newValue) => {
        // Do something with the selected option(s)
        setSelectedOption(newValue);
    };

    const settings = {
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
    };
    useEffect(() => {
        ensureDefaultCompany()
        .then(setCompany)
        .catch(() => setError(true))
        setActivePackageId(searchParams.get('planId'))
    }, [])
    return(
        <React.Fragment>
            {forAnotherCompany ? (
                <React.Fragment>
                    <div className={classes.formBox}>
                        <Form>
                            {/* <FormInput
                                fieldLabel={'Organization number or company name'}
                                type={'select'}
                                options={options}
                                onChange={handleOptionChange}
                                placeHolder={'xxx xxx xxx'}
                            /> */}
                            <Alert>Det oppstod en feil under forsøk på å få bedriftsinformasjon. Vær så snill, prøv på nytt.</Alert>
                            <small>Search the Brønnøysund registers for your company</small>
                            <hr />
                            <dl className={classes.companyInfo}>
                                <dt>Company name</dt><dd>Lafacil AS <br /> Strømsveien 323A <br /> 1081 OSLO</dd>
                                <dt>Organization number:</dt><dd>819823952</dd>
                                <dt>Type of business:</dt><dd>AS – Joint stock company</dd>
                            </dl>
                            <div className={'pt-4 text-center'}>
                                <Button
                                    text={'Select your package'}
                                    variant={' mb-3 w-50'}
                                    onClick={handlePackageButtonClick}
                                />
                            </div>
                        </Form>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <SectionTitle>
                        <span className={'fw-normal'}>Open</span>
                        <span className={'fw-semibold'}>Account</span>
                    </SectionTitle>
                    <div className={'font-18 mb-3'}>Company Information</div>
                    <div className={classes.formBox}>
                        <Row>
                            <Col lg={8}>
                                <Form>
                                    {/* <FormInput
                                        fieldLabel={'Organization number or company name'}
                                        type={'select'}
                                        options={options}
                                        onChange={handleOptionChange}
                                        placeHolder={'xxx xxx xxx'}
                                    /> */}
                                    {/* <small>Search the Brønnøysund registers for your company</small> */}
                                    { error && <><Alert variant='danger'>Det oppsto en feil under behandlingen av forespørselen din. Vær så snill, prøv på nytt.</Alert> <hr /></>}
                                    {!error && <><dl className={classes.companyInfo}>
                                        <dt>Company name</dt><dd>{company?.report?.companySummary?.businessName} <br /> {company?.report?.contactInformation?.mainAddress?.street}, <br /> {company?.report?.contactInformation?.mainAddress?.postalCode} {company?.report?.contactInformation?.mainAddress?.city} </dd>
                                        <dt>Organization number:</dt><dd>{company?.report?.companySummary?.companyRegistrationNumber}</dd>
                                        <dt>Type of business:</dt><dd>{company?.report?.companyIdentification?.activityClassifications?.map(activity => activity?.activities?.map(ac => ac?.description)?.join(','))?.join('')}</dd>
                                    </dl>
                                    <Row className={'pt-4'}>
                                        <Col lg={12}>
                                            <StartPayment packageId={activePackageId}/>
                                        </Col>
                                    </Row></>}
                                </Form>
                            </Col>
                            <Col lg={4}>
                                <Slider {...settings} className={classes.planList}>
                                    {data.monthlyPackages.map((packageItem, index) => (
                                        <Package selection single key={index} packageItem={packageItem} activePackageId={activePackageId} setActivePackageId={setActivePackageId} />
                                    ))}
                                </Slider>
                            </Col>
                        </Row>
                    </div>
                </React.Fragment>
            )}

            <SelectPackagePopup
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

        </React.Fragment>
    )
}

export default SelectCompany;