import React from 'react';
import classes from './index.module.scss';

const CircularProgressBar = ({percentageValue, houseValue}) => {
    const percentage = percentageValue;
    const deg = (percentage / 100) * 360;
    const filledBar = 'rgba(41, 171, 226, 1), rgba(30, 180, 233, 1), rgba(0, 207, 254, 1), rgba(0, 208, 255, 1), rgba(4, 204, 252, 1), rgba(16, 193, 243, 1), rgba(36, 175, 229, 1), rgba(41, 171, 226, 1)';
    const dangerBar = 'red';
    const trailColor = '#fff';

    return (
        <React.Fragment>
            {houseValue ? <>
                <div className={`${classes.circularProgressBarHolder} ${classes.houseValue}`}>
                    <div className={classes.circularProgressBar}>
                        <div
                            className={classes.circularProgress}
                            data-percentage={percentage}
                            data-bg-color="black"
                            style={{
                                background: `conic-gradient(${percentageValue > 85 ? dangerBar : filledBar} ${deg}deg, ${trailColor} 0deg)`,
                            }}
                        >
                            <div className={classes.innerCircle}>
                                {percentage <= 100 && <div className={classes.percentage}>
                                    {Math.round(percentage)}%
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={classes.text}>Utregnet belåningsgrad</div>
                </div>
                </> : <>
                <div className={classes.circularProgressBarHolder}>
                    <div className={classes.circularProgressBar}>
                        <div
                            className={classes.circularProgress}
                            data-percentage={percentage}
                            data-bg-color="black"
                            style={{
                                background: `conic-gradient(${percentageValue > 85 ? dangerBar : filledBar} ${deg}deg, ${trailColor} 0deg)`,
                            }}
                        >
                            <div className={classes.innerCircle}>
                                {percentage <= 100 && <div className={classes.percentage}>
                                    {percentage}%
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className={classes.text}>Belåningsgrad</div>
                </div>
            </>}


        </React.Fragment>
    );
};

export default CircularProgressBar;
