import React from 'react';
import {Modal, Table} from "react-bootstrap";

const EngagementPopup = (props) => {
  return(
      <React.Fragment>
          <Modal
              {...props}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title className={'mb-0'}>
                      Karlsen, Geir Magne
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <Table className={'border-0 mb-5'}>
                      <tbody>
                        <tr>
                            <th>Director Type</th>
                            <td>Person</td>
                        </tr>
                        <tr>
                            <th>Date of Birth</th>
                            <td>10/01/1965</td>
                        </tr>
                        <tr>
                            <th>Address</th>
                            <td>Midgarveien 4 1358 JAR</td>
                        </tr>
                        <tr>
                            <th>Current Engagements</th>
                            <td>10</td>
                        </tr>
                        <tr>
                            <th>Consumer Status</th>
                            <td>Resident</td>
                        </tr>
                      </tbody>
                  </Table>
                  <h4>Summery</h4>
                  <h6>Current Company Engagements</h6>
                  <Table className={'border responsive bg-light'}>
                      <thead>
                        <tr>
                            <th>Function</th>
                            <th>Company Name</th>
                            <th>Company Number</th>
                            <th>Start Date</th>
                            <th>Company Status</th>
                            <th>Companyscore</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                            <td data-label={"Function"}>Board member(s)</td>
                            <td data-label={"Company Name"}>LOYALTYCO AS</td>
                            <td data-label={"Company Number"}>932435888</td>
                            <td data-label={"Start Date"}>29/01/2024</td>
                            <td data-label={"Company Status"}>Active</td>
                            <td data-label={"Companyscore"}>41</td>
                        </tr>
                        <tr>
                            <td data-label={"Function"}>Board member(s)</td>
                            <td data-label={"Company Name"}>LOYALTYCO AS</td>
                            <td data-label={"Company Number"}>932435888</td>
                            <td data-label={"Start Date"}>29/01/2024</td>
                            <td data-label={"Company Status"}>Active</td>
                            <td data-label={"Companyscore"}>41</td>
                        </tr>
                        <tr>
                            <td data-label={"Function"}>Board member(s)</td>
                            <td data-label={"Company Name"}>LOYALTYCO AS</td>
                            <td data-label={"Company Number"}>932435888</td>
                            <td data-label={"Start Date"}>29/01/2024</td>
                            <td data-label={"Company Status"}>Active</td>
                            <td data-label={"Companyscore"}>41</td>
                        </tr>
                        <tr>
                            <td data-label={"Function"}>Board member(s)</td>
                            <td data-label={"Company Name"}>LOYALTYCO AS</td>
                            <td data-label={"Company Number"}>932435888</td>
                            <td data-label={"Start Date"}>29/01/2024</td>
                            <td data-label={"Company Status"}>Active</td>
                            <td data-label={"Companyscore"}>41</td>
                        </tr>
                        <tr>
                            <td data-label={"Function"}>Board member(s)</td>
                            <td data-label={"Company Name"}>LOYALTYCO AS</td>
                            <td data-label={"Company Number"}>932435888</td>
                            <td data-label={"Start Date"}>29/01/2024</td>
                            <td data-label={"Company Status"}>Active</td>
                            <td data-label={"Companyscore"}>41</td>
                        </tr>
                      </tbody>
                  </Table>
              </Modal.Body>
          </Modal>
      </React.Fragment>
  )
}

export default EngagementPopup;