import React from 'react';
import classes from './index.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import SocialLinks from "../SocialLinks";

const GetInTouch = ({ detail }) => {
    return(
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <Row className={'align-items-center'}>
                        <Col md={6}>
                            <div className={classes.mapBox}>
                                <iframe
                                    src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1998.7526618231427!2d${detail?.component?.longitude}!3d${detail?.component?.latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46416ff780b39ba3%3A0x65ac3153501fc9eb!2sStr%C3%B8msveien%20323A%2C%201081%20Oslo%2C%20Norway!5e0!3m2!1sen!2s!4v1701332329449!5m2!1sen!2s`}
                                    width="600" height="350"  allowFullScreen="" loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </Col>
                        <Col md={6}>
                            <SectionTitle>
                                {detail?.heading?.title?.map(title => <span className={title.class}>{title.title}</span>) }
                            </SectionTitle>
                            <p>{detail?.heading?.description}</p>
                            <SocialLinks filled detail={detail}/>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default GetInTouch;