export const getVersionById = id => new Promise((resolve, reject) => {
    if(!id) return;
    fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetById/${id}`)
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else reject(resp);
    })
    .then(resolve)
    .catch(reject);
})

export const getAllComponents = () => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/ReactComponent/GetAll`)
    .then(resp => {
        if(resp.status === 200) return resp.json();
        else return reject(resp);
    })
    .then(resp => resolve(resp.data))
    .catch(reject);
})

export const getAllComponentVersions = new Promise((resolve, reject) => {
    
})