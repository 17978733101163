import React from "react";
import Button from "../../../../Components/Button";
import classes from "./index.module.scss";

const RecommendedCards = ({ data, activeCategory }) => {
    const activeCategoryDataType = activeCategory?.dataType || '';

    return (
        <div className={`${classes.row}`}>
        {data && data.map(item => {

            return (
                <React.Fragment key={item.dataType}  id={item.dataType}>
                    <div className={`${classes.rowHolder} ${activeCategoryDataType === 'Kredittkort' ? classes.specialCard : ''}`} id={item.dataType}>
                        {item.featured && <div className={classes.featured}>Anbefalt</div>}
                        <div className={classes.rowHeader}>
                            <div className={classes.holder}>
                                <div className={classes.logoBox}>
                                    <a href={item.btnUrl}>
                                        <img src={item.image} loading="lazy" alt={'#'} />
                                    </a>
                                </div>
                                {activeCategoryDataType && <h4 className={'text-capitalize'}><a href={item.btnUrl}>{item.name}</a> </h4>}
                                {/*<p>{item.leverandorTekst}</p>*/}
                            </div>
                        </div>
                        <div className={classes.rowBody}>
                            {activeCategoryDataType === 'Kredittkort' && <>
                                <div className={classes.holder}>
                                    <ul className={classes.list}>
                                        {item.title && item.moreInfo &&
                                            <li className={'text-left py-3'}>
                                                <h6 className={'mb-3'} style={{wordBreak: "break-word"}}>{item.title}</h6>
                                                {item.moreInfo && item.moreInfo && item.moreInfo.length > 0 && (
                                                    <ul className={'checkList'}>
                                                        {item.moreInfo.map((info, index) => (
                                                            <li key={index}>{info}</li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </li>
                                        }
                                        <li>
                                            <div className={classes.col}>Maks kreditt</div>
                                            <div className={classes.col}>{item.loanAmount}</div>
                                        </li>
                                        <li>
                                            <div className={classes.col}>Eff. Rente</div>
                                            <div className={classes.col}>{item.effectiveInterestRate}</div>
                                        </li>
                                        <li>
                                            <div className={classes.col}>Kost/mnd</div>
                                            <div className={classes.col}>{item.pricePerMonths}</div>
                                        </li>
                                    </ul>
                                    <div className={classes.rowBtnCol}>
                                        <div className={'font-10 text-center'}>{item.affiliateType}</div>
                                        <Button
                                            text={'Søk nå'}
                                            icon={'far fa-arrow-right'}
                                            url={item.btnUrl}
                                            target={"_blank"}
                                        />
                                    </div>
                                </div>
                                <div className={classes.rowFooter}>
                                    <div className={classes.footerText}>
                                        {item.loanExample}
                                    </div>
                                </div>
                            </>  }
                            {activeCategoryDataType === 'Forbrukslån' && <>
                                <div className={`${classes.holder} ${classes.hoderExtra}`} style={{minHeight: "180px"}}>
                                    <ul className={classes.list}>
                                        <li>
                                            <div className={classes.col}>Lånebeløp</div>
                                            <div className={classes.col}>{item.loanAmount}</div>
                                        </li>
                                        <li>
                                            <div className={`${classes.col} text-left font-16`} style={{letterSpacing: '0.5px'}}>{item.leverandorTekst}</div>
                                        </li>
                                    </ul>
                                    <div className={classes.rowBtnCol}>
                                        <div className={'font-10 text-center fw-bold mb-2'}>{item.affiliateType}</div>
                                        <Button
                                            text={'Søk nå'}
                                            icon={'far fa-arrow-right'}
                                            url={item.btnUrl}
                                            target={"_blank"}
                                            variant={' mb-2'}
                                        />
                                        <div className={'font-11 py-2 text-center  fw-medium mb-2'} style={{lineHeight: '13px'}}>Rente fra:</div>
                                        <div className={classes.percentAgeBox}>{item.nominalInterestRate}</div>
                                    </div>
                                </div>
                                <div className={classes.rowFooter}>
                                    <div className={classes.footerText}>
                                        {item.loanExample}
                                    </div>
                                </div>
                            </>  }

                            {activeCategoryDataType === 'Boliglån' && <>
                                <div className={`${classes.holder} ${classes.hoderExtra}`} style={{minHeight: "180px"}}>
                                    <ul className={classes.list}>
                                        <li>
                                            <div className={classes.col}>Lånebeløp</div>
                                            <div className={classes.col}>{item.loanAmount}</div>
                                        </li>
                                        <li>
                                            <div className={`${classes.col} text-left font-16`} style={{letterSpacing: '0.5px'}}>{item.leverandorTekst}</div>
                                        </li>
                                    </ul>
                                    <div className={classes.rowBtnCol}>
                                        <div className={'font-10 text-center fw-bold mb-2'}>{item.affiliateType}</div>
                                        <Button
                                            text={'Søk nå'}
                                            icon={'far fa-arrow-right'}
                                            url={item.btnUrl}
                                            target={"_blank"}
                                            variant={' mb-2'}
                                        />
                                        <div className={'font-11 py-2 text-center  fw-medium mb-2'} style={{lineHeight: '13px'}}>Kredittscore-basert rente fra:</div>
                                        <div className={classes.percentAgeBox}>{item.nominalInterestRate}</div>
                                    </div>
                                </div>
                                <div className={classes.rowFooter}>
                                    <div className={classes.footerText}>
                                        {item.loanExample}
                                    </div>
                                </div>
                            </>  }
                        </div>
                    </div>

                    {activeCategoryDataType === 'Kredittkort' && <>
                        {item.moreDetail && (
                            <div className={`${classes.row} ${item.moreDetail && classes.vertical}`}>
                                {item.moreDetail && item.moreDetail.map(detailItem => (
                                    <div className={`${classes.rowHolder}`} key={detailItem.image}>
                                        {detailItem.featured && <div className={classes.featured}>Anbefale</div>}
                                        {detailItem.tagLine &&
                                            <div className={classes.tagLine}>{detailItem.tagLine}</div>}
                                        <div className={classes.rowHeader}>
                                            <div className={`${classes.holder}`}>
                                                <div className={classes.logoBox}>
                                                    <a href={'#'}>
                                                        <img src={detailItem.image} loading="lazy" alt={'#'}/>
                                                    </a>
                                                </div>
                                                <h6 className={'font-14'}>{detailItem.name}</h6>

                                                {/*<p>{detailItem.leverandorTekst}</p>*/}
                                            </div>
                                        </div>
                                        <div className={classes.rowBody}>
                                            <div className={classes.holder}>
                                                <ul className={classes.list}>
                                                    <li>
                                                        <div className={classes.col}>Eff. Rente:</div>
                                                        <div
                                                            className={classes.col}>{detailItem.effectiveInterestRate}</div>
                                                    </li>
                                                    <li>
                                                        <div className={classes.col}>Kredittgrense:</div>
                                                        <div className={classes.col}>{detailItem.loanAmount}</div>
                                                    </li>
                                                    <li>
                                                        <div className={classes.col}>Rentefri periode:</div>
                                                        <div
                                                            className={classes.col}>{detailItem.maxiRepaymentPeriod}</div>
                                                    </li>
                                                </ul>
                                                {item.moreDetail && detailItem.moreInfo && detailItem.moreInfo.length > 0 && (
                                                    <>
                                                        <div className={'font-12 p-3 fw-light'}>Flere fordeler</div>
                                                        <ul className={'checkList'}>
                                                            {detailItem.moreInfo.map((info, index) => (
                                                                <li key={index}>{info}</li>
                                                            ))}
                                                        </ul>
                                                    </>
                                                )}
                                                <div className={classes.rowBtnCol}>
                                                    <div
                                                        className={'font-10 text-center'}>{item.affiliateType}</div>
                                                    <Button
                                                        text={'Søk nå'}
                                                        icon={'far fa-arrow-right'}
                                                        url={detailItem.btnUrl}
                                                        target={"_blank"}
                                                    />
                                                </div>
                                            </div>
                                            <div className={classes.rowFooter}>
                                                <div className={classes.footerText}>
                                                    {detailItem.loanExample}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </>}

                </React.Fragment>
            )
        })}
    </div>
    );
};

export default RecommendedCards;
