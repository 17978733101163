import React, {useState, useEffect}  from 'react'
import {Document, StyleSheet } from '@react-pdf/renderer';
import FrontPage from "../../PDFComponent/FrontPage";
import CreditCheckList from "./CreditCheckList";

const ScoreDocument = ({userData, creditData, totalDebt, debtData, totalProperties, approvedConsumerLoan, remainingConsumerLoan}) => {
    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 40,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column',position: 'relative', },
        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },
        titleContainer: {flexDirection: 'row',marginTop: 24},
        logo: { width: 90 },
    });



    return (
        <Document style={styles.document}>
            <FrontPage userData={userData}  />
            <CreditCheckList
                userData={userData}
                creditData={creditData}
                debtData={debtData}
                totalDebt={totalDebt}
                totalProperties={totalProperties}
                approvedConsumerLoan={approvedConsumerLoan}
                remainingConsumerLoan={remainingConsumerLoan}
            />
        </Document>
          
    )
}

export default ScoreDocument