import React from "react";
import {  Container } from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import classes from "./index.module.scss";
import Button from "../Button";

const StaticAdSectionRightImg = ({detail, className, numberList}) => {

    return (
        <React.Fragment>
            <section className={`section ${className}`}>
                <Container>
                    <div className={classes.colList}>
                        <div className={classes.col}>
                            <SectionTitle description={detail.heading.description} textCenter={true}>
                                {detail.heading.title.map((title) => (
                                    <span className={title.class} key={title.id}>{title.title} </span>
                                ))}
                            </SectionTitle>
                            <ul className={`${numberList ? 'order-list' : 'checkList circle yellow mb-5'}`}>
                                {detail.content.map((item, index) => (
                                    <li key={index}>
                                        {item.title && <b>{item.title}: </b>}
                                        {item.description}
                                    </li>
                                ))}
                            </ul>
                            {detail.btnText && detail.btnText && <Button
                                text={detail.btnText}
                                url={detail.btnUrl}
                                variant={'dark'}
                            />}

                        </div>
                        <div className={classes.col}>
                            <div className={classes.imgBox} style={{maxWidth: '500px', margin: '0 auto'}}>
                                <img src={detail.featuredImage} loading="lazy" alt={'title'} />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default StaticAdSectionRightImg;
