import React from "react";
import {Container} from "react-bootstrap";
import SectionTitle from "../../SectionTitle";
import Button from "../../Button";
import Lenders from "../Lenders";
import data from "../data";


const PopularLenders = ({ detail }) => {
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <SectionTitle textCenter>
                      {detail?.heading?.title?.map((title, i) => <span className={title.class} key={i}>{title.title}</span>)}
                  </SectionTitle>
                        {/* <p>{detail?.heading?.description}</p> */}
                  <Lenders detail={detail} count={4} />
                  {detail?.buttonText && <div className={'text-center pt-4'}>

                      <Button
                          url={detail?.buttonUrl}
                          text={detail?.buttonText}
                          icon={'far fa-arrow-right'}
                          variant={'border'}
                      />
                  </div>}
              </Container>
          </section>
      </React.Fragment>
  )
}

export default PopularLenders;