import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import data from './data.json';
import classes from '../index.module.scss';
import Button from '../../Button';
import FormInput from "../../FormInput";
import CircularProgressBar from "../../CircularProgressBar";

HighchartsMore(Highcharts);

const HouseValueChart = ({small, estimate, totalLoan, totalLoanLimit, property, commonDebt}) => {
    const [currentLoanValue, setCurrentLoanValue] = useState(0);
    const { title, subtitle, debtLimit, usedDebt } = data;
    const [totalNumber, setTotalNumber] = useState(Math.floor(usedDebt)); // Use state to set totalNumber
    const [chartId] = useState(`houseValue-${Math.random().toString(36).substr(2, 9)}`); // Generate unique chart id
    const availableLimit = ((isNaN(Number(estimate)) ? 0 : Number(estimate))  + (isNaN(Number(commonDebt)) ? 0 : Number(commonDebt))) * 0.85 - (isNaN(Number(commonDebt)) ? 0 : Number(commonDebt)) - (isNaN(Number(currentLoanValue)) ? 0 : Number(currentLoanValue))

    const percentageValue = ((((isNaN(currentLoanValue) ? 0 : Number(currentLoanValue)) + (isNaN(Number(commonDebt)) ? 0 : Number(commonDebt))) / ((isNaN(estimate) ? 0 : Number(estimate)) + (isNaN(commonDebt) ? 0 : Number(commonDebt)))) * 100)
    useEffect(() => {
        localStorage.setItem('savedLoanValue', currentLoanValue);
    }, [currentLoanValue])
    useEffect(() => {
        setCurrentLoanValue(localStorage.getItem('savedLoanValue'));
    }, [])
    useEffect(() => {
        const grapheIcon = () => {
            return `
                <div class="textBox">
                    <div class="iconBox">
                        <i class="fas fa-home-lg"></i>
                    </div>
                </div>
            `;
        };

        Highcharts.setOptions({
            colors: ['#F65857'], // Set a base color for the pie chart
            legend: {
                itemStyle: {
                    color: '#333333', // Set the text color for the legend
                },
                symbolRadius: 0, // Hide the symbols in the legend
            },
        });

        const options = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: null,
                renderTo: chartId, // Use dynamic chartId
            },
            title: {
                text: subtitle,
                align: 'center',
                verticalAlign: 'bottom',
            },
            subtitle: {
                useHTML: true,
                text: grapheIcon(),
                floating: true,
                verticalAlign: 'middle',
                y: 0,
                x: 0,
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false,
                    },
                    size: '100%',
                    borderWidth: 0,
                    borderRadius: 0,
                    colors: [
                        '#29ABE2', // Common Debt color
                        '#0C9D61', // Available for Use color
                        '#F65857', // Not Able to Use color
                        '#f7f7f7'  // Default color
                    ],
                    showInLegend: false, // Display legend
                },
            },
            series: [
                {
                    type: 'pie',
                    name: title,
                    innerSize: '85%',
                    data: [
                        ['Felles gjeld', (isNaN(commonDebt) ? 0 : Number(commonDebt))],
                        ['Mulig å belåne', (((isNaN(commonDebt) ? 0 : Number(commonDebt)) + (isNaN(estimate) ? 0 : Number(estimate))) * 0.85)-(isNaN(commonDebt) ? 0 : Number(commonDebt))],
                        ['Sikkerhet', ((isNaN(commonDebt) ? 0 : Number(commonDebt)) + (isNaN(estimate) ? 0 : Number(estimate))) * 0.15]
                    ],
                    // data: [['Used debt', totalLoan], ['Debt limit', totalLoanLimit - totalLoan]],
                },
            ],
        };

        let chart = new Highcharts.Chart(options);

        // Clear resources on unmount
        return () => {
            if (chart) {
                clearTimeout(chart.sequenceTimer);
                chart.destroy();
            }
        };
    }, [debtLimit, subtitle, title, totalNumber, chartId,estimate, property, commonDebt]); // Include chartId in the dependency array

    const format = value => {
        const formatter = Intl.NumberFormat('no-NO');
        if(isNaN(value)) return 0;
        return formatter.format(Math.round(value));
    }
    const stringifyNulls = str => {
        return str && str !== '' ? str : ''
    }
    const mergeLetterAndStreetNumber = (letter, streetNumber) => {
        if(letter && letter !== '')
            return `${stringifyNulls(streetNumber)} ${stringifyNulls(letter)}`
        else return stringifyNulls(streetNumber)
    }


    return (
        <React.Fragment>
            <div className={classes.chartBox}>
                <div className={classes.chartUpperBox}>
                    <div id={chartId} className={classes.container}></div>
                    {small ? <>
                        <h3>NOK {format(Number(estimate) + Number(commonDebt))} kr</h3>
                        <div className={'font-12 pb-3'}>{stringifyNulls(property?.address?.streetName)} {mergeLetterAndStreetNumber(property?.address?.streetLetter, property?.address?.streetNumber)}, {stringifyNulls(property?.address?.postOffice?.code?.padStart(4, 0))} {stringifyNulls(property?.address?.postOffice?.name)}</div>
                        <Button
                            text={'Se detaljer'}
                            url={'/privat/boligverdi'}
                            variant={'border w-100'}
                        />
                    </> : <>
                        <div className={'font-12 py-2'}>Estimert boligverdi ink. fellesgjeld</div>
                        {/*/RealEstates/{organizationNumber}/{shareNumber}/MarketEstimate Endpoint for retrieving market estimate based on a organization number and share number.*/}
                        {/*/RealEstates/{knr}/{gnr}/{bnr}/{fnr}/{snr}/MarketEstimate Endpoint for retrieving market estimate based on a cadastre.*/}
                        <h3>{format(Number(estimate) + Number(commonDebt))} kr</h3>
                        <FormInput
                            formatValue
                            fieldLabel={'Din boliglån'}
                            numericOnly={true}
                            type={'text'}
                            icon={'kr'}
                            placeholder={'Enter'}
                            textlg
                            textCenter
                            value={currentLoanValue}
                            onChange={({ target }) => {
                                setCurrentLoanValue(target.value)
                            }}
                        />
                        <div className={'font-38 py-2'}>=</div>
                        <div className={'font-12 py-2'}>Ubrukt verdi du har i boligen</div>
                        <h3>{format(availableLimit)} kr</h3>
                        <CircularProgressBar percentageValue={ percentageValue } houseValue />
                        <ul className={'checkList icon text-left font-12'}>
                            <li><i className={'fal fa-lightbulb-on'}></i> En lavere belåningsgrad gir vanligvis lavere boliglånsrente.</li>
                            <li><i className={'fal fa-comment-exclamation'}></i> Vår vurdering: 85% av boligverdien minus lånet ditt.</li>
                        </ul>
                    </>}

                </div>
                {/*<div className={classes.chartFooter}>
                    <Button text={'See your house value'} url={'/abc/'} variant={'border'} />
                </div>*/}
            </div>
        </React.Fragment>
    );
};

export default HouseValueChart;
