import React, {useEffect, useState} from 'react';
import {Alert, Col, Form, InputGroup, Row} from 'react-bootstrap';
import FormInput from '../../FormInput';
import Button from '../../Button';
import classes from '../index.module.scss';
import conusmerLoanImg from "../../../Images/consumerLoanImg.svg";
import RangeSlider from "../../RangeSlider";
import Loader from '../../Loader';
import { onError } from 'Services/api/Image';
import { getComponentVersionByListOfIds } from 'Services/api/LoanFilter';
import Loading from "../../Loading";



const CreditCardFilter = ({ detail, hideRadio, values, loanObject, setLoanObject }) => {
    const [versions, setVersions] = useState([]);
    const [selectedVersion, setSelectedVersion] = useState({});
    const languageId = 1;
    const [selectedLoanType, setSelectedLoanType] = useState(0);
    const [annualIncome, setAnnualIncome] = useState(values?.annualIncome);
    const [currentAge, setCurrentAge] = useState(values?.age);
    const [additional, setAdditional] = useState(values?.additional ?? []);

    const handleLoanTypeChange = (event) => setSelectedLoanType(event.target.value)

    useEffect(() => {
        if(!detail) return;
        const ids = detail?.map(d => d.tabVersion);
        getComponentVersionByListOfIds(languageId, ids)
        .then(resp => {
            setVersions(resp);
            setSelectedLoanType(resp[0]?.loanCategory);
            const first = resp[0];
            setAnnualIncome(values?.annualIncome ?? Number(first?.state?.annualIncomeDefaultValue));
            setCurrentAge(values?.age ?? Number(first?.state?.ageDefaultValue));
        });
    }, [])

    useEffect(() => {
        const nowSelected = versions.find(version => version.loanCategory == selectedLoanType);
        setSelectedVersion(nowSelected);
    }, [selectedLoanType])

    return(
        <React.Fragment>
            {!hideRadio && <Row>
                <Col md={12}>
                    <InputGroup className={'p-0'}>
                        {versions.map((version, index) => <FormInput
                            key={index}
                            type={'radio'}
                            label={version?.state?.tabTilte}
                            variant={'radio'}
                            id={`${version?.loanCategory}_${version?.loanType}_radio`}
                            name={`${version?.loanType}_radio`}
                            value={version?.loanCategory}
                            checked={selectedLoanType == version?.loanCategory}
                            onChange={handleLoanTypeChange}
                        />)}
                    </InputGroup>
                </Col>
            </Row>}
                {selectedVersion?.state ? <React.Fragment>
                    <div className={classes.contentHolder}>
                        <div className={classes.imgBox}>
                            <img src={selectedVersion?.state?.tabImage} loading="lazy" onError={onError} alt={'#'} />
                        </div>
                        <div className={classes.contentBox}>
                            <div className={classes.inputRow}>
                                <Col>
                                    <RangeSlider
                                        stepValue={'5000'}
                                        valueMin={selectedVersion?.state?.annualIncomeMinValue}
                                        valueMax={selectedVersion?.state?.annualIncomeMaxValue}
                                        value={annualIncome}
                                        onChange={setAnnualIncome}
                                        type={'input'}
                                        label={selectedVersion?.state?.annualIncomeLabel}
                                        unit={selectedVersion?.state?.currencyLabel}
                                        showMinMax
                                    />
                                </Col>
                                <Col>
                                    <RangeSlider
                                        stepValue={'1'}
                                        valueMin={selectedVersion?.state?.ageMinValue}
                                        valueMax={selectedVersion?.state?.ageMaxValue}
                                        value={currentAge}
                                        type={'input'}
                                        label={selectedVersion?.state?.ageLabel}
                                        unit={selectedVersion?.state?.yearsLabel}
                                        showMinMax
                                        onChange={setCurrentAge}
                                    />
                                </Col>
                            </div>
                            <Row className={'pt-3'}>
                                <Col md={12}>
                                    <InputGroup className={`${classes.inputCheckList} p-0`}>
                                        <FormInput
                                            type={'checkbox'}
                                            checked={additional?.includes('reiseforsikring')}
                                            label={selectedVersion?.state?.checkbox1Label}
                                            id={'Reiseforsikring'}
                                            name={'additional'}
                                            value={'reiseforsikring'}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setAdditional([...additional, e.target.value]);
                                                else
                                                    setAdditional(additional.filter(a => a !== e.target.value));
                                            }}
                                        />
                                        <FormInput
                                            type={'checkbox'}
                                            label={selectedVersion?.state?.checkbox2Label}
                                            checked={additional?.includes('ingenArsgebyr')}
                                            id={'Ingenårsgebyr'}
                                            name={'additional'}
                                            value={'ingenArsgebyr'}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setAdditional([...additional, e.target.value]);
                                                else
                                                    setAdditional(additional.filter(a => a !== e.target.value));
                                            }}
                                        />
                                        <FormInput
                                            type={'checkbox'}
                                            label={selectedVersion?.state?.checkbox3Label}
                                            id={'GebyrfribrukiNorge'}
                                            name={'additional'}
                                            checked={additional?.includes('gebyrfriBrukINorge')}
                                            value={'gebyrfriBrukINorge'}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setAdditional([...additional, e.target.value]);
                                                else
                                                    setAdditional(additional.filter(a => a !== e.target.value));
                                            }}
                                        />
                                        <FormInput
                                            type={'checkbox'}
                                            label={selectedVersion?.state?.checkbox4Label}
                                            id={'45dagersforsinkelse'}
                                            name={'additional'}
                                            value={'min45dagRentefri'}
                                            checked={additional?.includes('min45dagRentefri')}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setAdditional([...additional, e.target.value]);
                                                else
                                                    setAdditional(additional.filter(a => a !== e.target.value));
                                            }}
                                        />
                                        <FormInput
                                            type={'checkbox'}
                                            label={selectedVersion?.state?.checkbox5Label}
                                            id={'Andrefordeler'}
                                            name={'additional'}
                                            value={'andreFordeler'}
                                            onChange={e => {
                                                if(e.target.checked)
                                                    setAdditional([...additional, e.target.value]);
                                                else
                                                    setAdditional(additional.filter(a => a !== e.target.value));
                                            }}
                                        />
                                    </InputGroup>
                                </Col>
                            </Row>
                            <div className={classes.btnRow}>
                                <Button
                                    text={selectedVersion?.state?.buttonLabel}
                                    icon={'fas fa-arrow-right'}
                                    {...(loanObject && setLoanObject ? {
                                        onClick: () => {
                                            setLoanObject({ ...loanObject, age: currentAge, yourIncome: annualIncome, additional: String(additional) })
                                        }
                                    } : {
                                        url: `${selectedVersion?.state?.buttonUrl}?age=${currentAge}&annualIncome=${annualIncome}&additional=${String(additional)}`
                                    })}
                                />
                               <div className={classes.loanExample}>{selectedVersion?.state?.loanExample}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment> : <Loading />}
        </React.Fragment>
    )
}

export default CreditCardFilter;