import React, { useState } from 'react';
import classes from './index.module.scss';
import {Alert, Col, Container, Form, Row} from "react-bootstrap";
import FormInput from "../FormInput";
import Button from "../Button";
import contactImg from '../../Images/ContactUs.svg';
import SectionTitle from "../SectionTitle";
import { contactUs } from 'Services/api/ContactService';
import ContactValidator from './validator'


const ContactForm = ({ detail }) => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [formErrors, setFormErrors] = useState([]);
    const [form, setForm] = useState({
        name: '',
        email: '',
        phoneNo: '',
        message: ''
    });
    const _handleSubmit = () => {
        const validator  = new ContactValidator();
        const result = validator.validate(form)
        if(Object.values(result).length > 0){
            setFormErrors(Object.values(result));
            return;
        }
        contactUs(form)
        .then(() => setSuccess(true))
        .catch(() => setError(true));
    }
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <Row className={classes.formHolder}>
                      <Col md={5}>
                         {/* <div className={classes.imgBox}>
                              <img src={detail?.component?.image} loading="lazy" alt={'contact us'} />
                          </div>*/}
                          <ul className={classes.contactList}>
                              <li>
                                  <div className={classes.iconBox}><i className={'fal fa-envelope'}></i> </div>
                                  <h4><a href={'mailto:post@easyfinance.no'}>E-post</a> </h4>
                                  <p>Send oss en e-post, vi vil svare så snart som mulig.</p>
                              </li>
                              <li>
                                  <div className={classes.iconBox}><i className="far fa-map-marker-alt"></i></div>
                                  <h4>Postadresse</h4>
                                  <p>EasyFinance – En del av Lafacil AS<br />
                                      Postboks 6369 Etterstad<br />
                                      0604 Oslo</p>
                              </li>
                              <li>
                                  <div className={classes.iconBox}><i className={'fal fa-building'}></i> </div>
                                  <h4>EasyFinance.no</h4>
                                  <p>EasyFinance.no er en del av Lafacil AS<br /> Org.nr: 819 823 952 <br />
                                      Lafacil AS er en aktør innen utvikling, eierskap og drift av flere nyttige digitale tjenester som er skapt for å forenkle hverdagen. </p>
                              </li>
                          </ul>
                      </Col>
                      <Col md={7}>
                          <Form>
                              <div className={classes.box}>
                                  <SectionTitle textCenter description={detail?.heading?.description}>
                                      {detail?.heading?.title?.map((title, i) => <span key={i} className={title.class}>{title.title}</span>)}
                                  </SectionTitle>
                                    { formErrors && formErrors?.length > 0 ? formErrors.map((e,i) => <Alert key={i} variant='danger'>{e}</Alert>) : null}
                                  <FormInput
                                      type={'text'}
                                      fieldLabel={detail?.component?.nameLabel}
                                      placeholder={detail?.component?.namePlaceholder}
                                      value={form.name}
                                      onChange={e => setForm({ ...form, name: e.target.value})}
                                  />
                                  <Row>
                                      <Col md={6}>
                                          <FormInput
                                              type={'email'}
                                              fieldLabel={detail?.component?.emailLabel}
                                              placeholder={detail?.component?.emailPlaceholder}
                                              value={form.email}
                                              onChange={e => setForm({ ...form, email: e.target.value})}
                                          />
                                      </Col>
                                      <Col md={6}>
                                          <FormInput
                                              type={'tel'}
                                              fieldLabel={detail?.component?.phoneNumberLabel}
                                              placeholder={detail?.component?.phoneNumberPlaceholder}
                                              value={form.phoneNo}
                                              onChange={e => setForm({ ...form, phoneNo: e.target.value})}
                                          />
                                      </Col>
                                  </Row>


                                  <FormInput
                                      type={'textarea'}
                                      fieldLabel={detail?.component?.messageLabel}
                                      placeholder={detail?.component?.messagePlaceholder}
                                      value={form.message}
                                      onChange={e => setForm({ ...form, message: e.target.value})}
                                  />
                                  {success && <Alert variant='success'>{detail?.component?.successMessage}</Alert>}
                                  {error && <Alert variant='danger'>{detail?.component?.errorMessage}</Alert>}
                                  <div className={'text-center'}>
                                      <Button
                                          text={detail?.component?.buttonText}
                                          variant={' w-50'}
                                          onClick={_handleSubmit}
                                      />
                                  </div>
                              </div>
                          </Form>
                      </Col>
                  </Row>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default ContactForm;