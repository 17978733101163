import React, {useState} from 'react';
import { Table, Tabs, Tab } from "react-bootstrap";
import classes from "../index.module.scss";
import moment from 'moment';
import EngagementPopup from "./engagementPopup";

const DirectorOwnerShip = ({ details, soleProperty }) => {
    const [modalShow, setModalShow] = React.useState(false);


    return (
        <React.Fragment>
            {soleProperty ? <>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Nåværende styre</h5>
                    <h6>Styrehistorikk</h6>
                    <Table responsive className={'border responsive'}>
                        <thead>
                        <tr>
                            <th>Personnummer</th>
                            <th>Navn</th>
                            <th>Adresse</th>
                            <th>Fødselsdato</th>
                            <th>Funksjon</th>
                        </tr>
                        </thead>
                        <tbody>
                       {details?.directors?.map((director, index) => <tr key={index}>
                            <td data-label={"Personnummer"}>{director?.personalNumber && director?.personalNumber !== '' ? director?.personalNumber : '-'}</td>
                            <td data-label={"Navn"}>{director?.name && director?.name !== '' ? director?.name : '-'}</td>
                            <td data-label={"Adresse"}>{director?.address && director?.address !== '' ? director?.address : '-'}</td>
                            <td data-label={"Fødselsdato"}>{director?.dateOfBirth && director?.dateOfBirth !== '' ? moment(director?.dateOfBirth).format('DD-MM-YYYY') : '-'}</td>
                            <td data-label={"Funksjon"}>{director?.function && director?.function !== '' ? director?.function : '-'}</td>
                        </tr>)}
                        </tbody>
                    </Table>
                </div>
            </> : <>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Nåværende styre</h5>
                    <h6>Styremedlemmer</h6>
                    <Table responsive className={'border responsive'}>
                        <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Adresse</th>
                            <th>Land</th>
                            <th>Fødselsdato</th>
                            <th>Funksjon</th>
                            <th>Startdato</th>
                            <th>Næringsinteresser</th>
                            {/* <th>Handling</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {details?.report?.directors?.currentDirectors?.map((dir, index) => <tr key={index}>
                            <td data-label={"Navn"}>{dir?.name}</td>
                            <td data-label={"Adresse"}>{dir?.address?.simpleValue}</td>
                            <td data-label={"Land"}>{dir?.address?.country}</td>
                            <td data-label={"Fødselsdato"}>{moment(dir?.dateOfBirth).format('DD/MM/YYYY')}</td>
                            <td data-label={"Funksjon"}>{dir?.positions?.map(pos => pos?.positionName)?.join(', ')}</td>
                            <td data-label={"Startdato"}>{dir?.positions?.map(pos => pos?.dateAppointed && pos?.dateAppointed !== '' ? moment(pos?.dateAppointed).format('DD/MM/YYYY') : '-')}</td>
                            <td data-label={"Næringsinteresser"}>{dir?.additionalData?.currentDirectorships}</td>
                            {/* <td data-label={"Handling"}>
                                <a href={'#'} onClick={() => setModalShow(true)}>Vis</a>
                            </td> */}
                        </tr>)}
                        </tbody>
                    </Table>
                </div>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Revisorer og regnskapsførere</h5>
                    <Table responsive className={'border responsive'}>
                        <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Adresse</th>
                            <th>Land</th>
                            <th>Org.nummer / fødselsdato</th>
                            <th>Funksjon</th>
                            <th>Startdato</th>
                        </tr>
                        </thead>
                        <tbody>
                        {details?.report?.additionalInformation?.otherOfficials?.map((official, index) => <tr key={index}>
                            <td data-label={"Navn"}>{official?.name}</td>
                            <td data-label={"Adresse"}>{official?.address?.simpleValue}</td>
                            <td data-label={"Land"}>{official?.address?.country}</td>
                            <td data-label={"Org.nummer / fødselsdato"}>{official?.companyNumber}</td>
                            <td data-label={"Funksjon"}>{official?.position}</td>
                            <td data-label={"Startdato"}>{moment(official?.dateAppointed).format('DD/MM/YYYY')}</td>
                        </tr>)}
                        </tbody>
                    </Table>
                </div>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Styrehistorikk</h5>
                    <h6>Styremedlemmer</h6>
                    <Table responsive className={'border responsive'}>
                        <thead>
                        <tr>
                            <th>Navn</th>
                            <th>Adresse</th>
                            <th>Land</th>
                            <th>Fødselsdato</th>
                            <th>Funksjon</th>
                            <th>Startdato</th>
                            {/* <th>Handling</th> */}
                        </tr>
                        </thead>
                        <tbody>
                        {details?.report?.directors?.previousDirectors?.map((dir, index) => <tr key={index}>
                            <td data-label={"Navn"}>{dir?.name}</td>
                            <td data-label={"Adresse"}>{dir?.address?.simpleValue}</td>
                            <td data-label={"Land"}>{dir?.address?.country}</td>
                            <td data-label={"Fødselsdato"}>{moment(dir?.dateOfBirth).format('DD/MM/YYYY')}</td>
                            <td data-label={"Funksjon"}>{dir?.positions?.map(pos => pos?.positionName)?.join(', ')}</td>
                            <td data-label={"Startdato"}>{dir?.positions?.map(pos => pos?.dateAppointed && pos?.dateAppointed !== '' ? moment(pos?.dateAppointed).format('DD/MM/YYYY') : '-')}</td>
                            {/* <td data-label={"Handling"}>
                                <a href={'#'} onClick={() => setModalShow(true)}>Vis</a>
                            </td> */}
                        </tr>)}
                        </tbody>
                    </Table>
                </div>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Aksjonærer</h5>
                    <Table responsive className={'border responsive'}>
                        <thead>
                        <tr>
                            <th>Aksjonærnavn</th>
                            <th>Organisasjonsnummer</th>
                            <th>Land</th>
                            <th>Antall aksjer</th>
                            <th>Andel (%)</th>
                        </tr>
                        </thead>
                        <tbody>
                        {details?.report?.shareCapitalStructure?.shareHolders?.map((shareHolder, index) => <tr key={index}>
                            <td data-label={"Aksjonærnavn"}>{shareHolder?.name}</td>
                            <td data-label={"Organisasjonsnummer"}>{shareHolder?.id ?? '-'}</td>
                            <td data-label={"Land"}>{shareHolder?.address?.country}</td>
                            <td data-label={"Antall aksjer"}>{shareHolder?.totalNumberOfSharesOwned}</td>
                            <td data-label={"Andel (%)"}>{shareHolder?.percentSharesHeld}%</td>
                        </tr>)}
                        </tbody>
                    </Table>
                </div>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Signatur</h5>
                    <Table responsive className={'border'}>
                        <tbody>
                        {details?.report?.additionalInformation?.authorisedSignatures && details?.report?.additionalInformation?.authorisedSignatures?.signatures && details?.report?.additionalInformation?.authorisedSignatures?.signatures?.signatures && details?.report?.additionalInformation?.authorisedSignatures?.signatures?.signatures?.length > 0 ? details?.report?.additionalInformation?.authorisedSignatures?.signatures?.signatures?.map((signature, index) => <tr key={index}>
                            <td>{signature}</td>
                        </tr>) : <tr>
                            <td>No Signature information available for the company</td>
                        </tr>}
                        </tbody>
                    </Table>
                </div>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Prokura</h5>
                    <Table responsive className={'border'}>
                        <tbody>
                        {
                            details?.report?.additionalInformation?.authorisedSignatures && details?.report?.additionalInformation?.authorisedSignatures?.procurementHolders ?
                                <tr>
                                    <td>Prokura hver for seg</td>
                                    <td dangerouslySetInnerHTML={{__html: details?.report?.additionalInformation?.authorisedSignatures?.procurementHolders?.individualProcurements?.join('<br />')}}></td>
                                </tr> :
                                <tr>
                                    <td>Ingen anskaffelsesinformasjon tilgjengelig for selskapet</td>
                                </tr>
                        }
                        </tbody>
                    </Table>
                </div>
                <div className={'mb-3'}>
                    <h5 className={'p-2 bg-light'}>Revisorer og regnskapsførere</h5>
                    <Table responsive className={'border'}>
                        <tbody>
                        <tr>
                            <td>Ingen annen informasjon om selskapets tjenestemenn er tilgjengelig for selskapet</td>
                        </tr>
                        </tbody>
                    </Table>
                </div>
                <EngagementPopup
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </>}

        </React.Fragment>
    )
}

export default DirectorOwnerShip;