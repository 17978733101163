import React from 'react';
import classes from '../index.module.scss';
import {Col, Row} from "react-bootstrap";
import CompanyBranches from "../CompanyBranches";
import CompanyInfo from "../CompanyInfo";

const Summary = ({ details, soleProperty }) => {
    return(
        <React.Fragment>
            {soleProperty ?
                <Row>
                    <Col lg={6} className={'mb-3'}>
                        <CompanyInfo soleProperty details={details} />
                    </Col>
                    <Col lg={6} className={'mb-3'}>
                        <CompanyBranches soleProperty details={details} />
                    </Col>
                </Row>
                :
                <Row>
                    <Col lg={6} className={'mb-3'}>
                        <CompanyInfo details={details} />
                    </Col>
                    <Col lg={6} className={'mb-3'}>
                        <CompanyBranches details={details} />
                    </Col>
                </Row>}

        </React.Fragment>
    )
}

export default Summary;