import React from "react";
import classes from "./index.module.scss";
import {Link} from "react-router-dom";
import { onError } from "Services/api/Image";

const Button = ({ text, icon, image, iconLeft, url, variant, underline, className,  onClick, disabled, target}) => {
    return(
        <React.Fragment>
            {url ? (
                    <>
                        <a href={url} 

                        target={ target }
                        className={` ${classes.btn} 
                    ${variant ? `btn-${variant}` : ''}
                    ${iconLeft ? `iconRight` : ''}
                    ${className ? `${className}` : ''}
                    ${disabled ? `${classes.disabled}` : ''}
                `} style={{
                            textDecoration: underline ? 'underline' : ''
                        }}>
                            {text}
                            {image && <img onError={onError} style={{margin: '0 15px', maxHeight: '16px'}} src={image} alt={'image'} loading="lazy" /> }
                            {icon ? (<i className={`${icon} `}></i> ) : null}
                        </a>
                    </>
                ) :
                (
                                                                                // TODO: PRODUCTION (remove style)
                    <button disabled={disabled} type={'button'} onClick={onClick} style={{cursor: 'default'}} className={`${classes.btn} 
                    
                    ${variant ? `btn-${variant}` : ''}
                    ${iconLeft ? `iconRight` : ''}
                `}>
                        {text}
                        {icon ? (<i className={`${icon}`}></i> ) : null}
                    </button>
                )}
        </React.Fragment>
    )
}

export default Button;