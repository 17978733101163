import React, {useState, useEffect} from 'react';
import {Nav} from "react-bootstrap";
import {Link, NavLink, useLocation} from "react-router-dom";
import classes from './index.module.scss';
import FormInput from "../../../../Components/FormInput";
import { getConsumerReport } from '../../../../Services/api/Auth';


const NavSidebar = () => {

    const location = useLocation();
    const [companyEngagements, setCompanyEngagements] = useState([{}]);
    const [currentTheme, setCurrentTheme] = useState(() => {
        const savedTheme = localStorage.getItem('theme');
        return savedTheme || 'light';
    });

    // Function to toggle the theme
    const toggleTheme = () => {
        const newTheme = currentTheme === 'light' ? 'dark' : 'light';
        setCurrentTheme(newTheme);
        localStorage.setItem('theme', newTheme);
    };

    // Update the theme class when location or currentTheme changes
    useEffect(() => {
        const isDashboardPage = location.pathname.includes('dashboard') || location.pathname.includes('credit-check') || location.pathname.includes('payment-remarks') || location.pathname.includes('my-debt')
        document.body.classList.toggle('dark-theme', isDashboardPage && currentTheme === 'dark');
    }, [location.pathname, currentTheme]);

    // Listen for changes in location.pathname
    useEffect(() => {
        const isDashboardPage = location.pathname.includes('dashboard');
        if (!isDashboardPage) {
            document.body.classList.remove('dark-theme');
        }
        document.body.classList.remove('sidebarActive');
    }, [location.pathname]);
    useEffect(() => {
        getConsumerReport()
        .then(({ companyEngagements }) => setCompanyEngagements(companyEngagements))
    }, []);

    const isBusinessDashboard = location.pathname.includes('/bedriftsdashbord');
    const lastLocation = location.pathname.split('/').pop();
    return(
        <React.Fragment>
            <nav id={classes.navSidebar}>
                <Nav as="ul">
                    {isBusinessDashboard ? (
                        <>
                            <Nav.Item as="li">
                                <Link exact to={"/bedriftsdashbord"} className={lastLocation === 'bedriftsdashbord' ? 'active' : ''}>
                                    <i className={'icon-icon33'} style={{fontSize: '16px'}}></i>
                                    <span>Bedriftsdashboard</span>
                                </Link>
                            </Nav.Item>
                            { companyEngagements && companyEngagements?.length > 0 ? <>
                            {/* TODO: PRODUCTION */}
                            {/* <Nav.Item as="li">
                                <NavLink exact to={"/business-dashboard/business-company-credit-check"}>
                                    <i className={'icon-icon431'} style={{fontSize: '16px'}}></i>
                                    <span>Sjekk Selskaper</span>
                                </NavLink>
                            </Nav.Item> */}
                            <Nav.Item as="li">
                                <NavLink to={"/bedriftsdashbord/sett-selskaper"}>
                                    <i className={'fas fa-eye'} style={{ fontSize: '16px' }}></i>
                                    <span>Nylig sett</span>
                                </NavLink>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <NavLink to={"/bedriftsdashbord/mine-lånesøknader"}>
                                    <i className={'fas fa-eye'} style={{ fontSize: '16px' }}></i>
                                    <span>Lånesøknad</span>
                                </NavLink>
                            </Nav.Item></> : <></>}
                        </>
                    ) : <>
                        <Nav.Item as="li">
                            <NavLink to={"/privatdashbord"}>
                                <i className={'icon-icon33'} style={{fontSize: '16px'}}></i>
                                <span>Privatdashbord</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/privat/kredittsjekk"}>
                                <i className={'icon-icon34'}></i>
                                <span>Min Kredittscore</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li"><NavLink to={"/privat/min-gjeld"}>
                            <i className="icon-icon35"></i>
                            <span>Min gjeld</span>
                        </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li"><NavLink to={"/privat/betalingsanmerkninger"}>
                            <i className="icon-icon40"></i>
                            <span>Betalingsanmerkninger</span>
                        </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/privat/boligverdi"}>
                                <i className={'icon-icon36'}></i>
                                <span>Boligverdi</span>
                            </NavLink>
                        </Nav.Item>
                        {/* <Nav.Item as="li"><NavLink to={"/saved-searches"}>
                            <i className="fas fa-save" style={{fontSize: 16}}></i>
                            <span>Lagre søk</span>
                        </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li"><NavLink to={"/profile-setting"}>
                            <i className="fas fa-user-cog" style={{fontSize: 16}}></i>
                            <span>Profilinnstillinger</span>
                        </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li"><NavLink to={"/notifications"}>
                            <i className="icon-icon43"></i>
                            <span>Notification</span>
                        </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/house-value"}>
                                <i className={'icon-icon36'}></i>
                                <span>House value</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/house-rent"}>
                                <i className={'icon-icon37'}></i>
                                <span>House rent</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/budget"}>
                                <i className={'icon-icon38'}></i>
                                <span>Budget </span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/offer"}>
                                <i className={'icon-icon39'}></i>
                                <span>Offer</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li">
                            <NavLink to={"/payment-note"}>
                                <i className="icon-icon40"></i>
                                <span>Payment notes</span>
                            </NavLink>
                        </Nav.Item>
                        <Nav.Item as="li"><NavLink to={"/insurance"}><i className={'icon-icon41'}></i><span>Insurances</span></NavLink></Nav.Item>
                        <Nav.Item as="li"><NavLink to={"/quiz"}><i className={'icon-icon42'}></i><span>Quiz</span></NavLink></Nav.Item>*/}
                    </>}
                </Nav>
                {/*<div className={classes.navFooter}>
                    <FormInput
                        type={'checkbox'}
                        label={currentTheme === 'dark' ? 'Light Mode' : 'Dark Mode'}
                        id={'darkLight'}
                        variant={'checkboxSwitch'}
                        name={'darkLight'}
                        checked={currentTheme === 'dark'} // Use checked to reflect the current theme
                        onChange={toggleTheme} // Call the to
                    />
                </div>*/}

            </nav>
        </React.Fragment>
    )
}

export default NavSidebar;