import React, { useState, useEffect } from 'react';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Container } from "react-bootstrap";
import ScoreDocument from "./ScoreDocument";
import { getCurrentUser } from '../../../../Services/api/Auth';
import {loadGjeldsregisteretData} from "../../../../Services/api/Loan";

const DebtCheckPDF = () => {
    const [userData, setUserData] = useState({ firstName: '', lastName: '', userName: '' });
    const [debtData, setDebtData] = useState(null);

    useEffect(() => {
        getCurrentUser().then(user => {
            setUserData(user);
        });
        loadGjeldsregisteretData().then(resp => {
            setDebtData(resp.debtInformation);
        }).catch(console.error);
    }, []);

    const styles = StyleSheet.create({
        pdfView: {
            width: '100%',
            height: '1200px',
        }
    });

    return (
        <Container>
            <PDFViewer style={styles.pdfView}>
                <ScoreDocument userData={userData} debtData={debtData} />
            </PDFViewer>
        </Container>
    );
};

export default DebtCheckPDF;
