import React from "react";
import moment from "moment";
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import InnerPageLayout from "../../../PDFComponent/InnerPageLayout";
import PDFTable from "../../../PDFComponent/Tables";
import PageHeader from "./PageHeader";
import AboutEasyFinance from "../../../PDFComponent/AboutEasyFinance";

const DebtList = ({ debtData, userData }) => {
  const format = (value, divideBy100 = true, round = false, appendZeros = true) => {
    if (isNaN(value)) return 0;
    const formatter = Intl.NumberFormat('no-NO', {
      minimumFractionDigits: appendZeros ? 2 : 0,
      maximumFractionDigits: appendZeros ? 2 : 0
    });
    const roundedValue = round ? Math.round(divideBy100 ? value / 100 : value) : divideBy100 ? value / 100 : value;
    if (isNaN(roundedValue)) return 0;
    return formatter.format(roundedValue);
  };

  const styles = StyleSheet.create({
    section: { marginBottom: 20 },
    section1: { marginBottom: 15, fontSize: 8 },
    row: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 },
    text: { flexDirection: 'column' },
    text1: { flexDirection: 'column', fontWeight: "700", fontSize: 11 },
    creditSafeBox: { paddingTop: 10, marginLeft: 0, fontSize: 7 },
    link: { color: '#000' },
    header: { flexDirection: 'row' },
    title: { width: '50%' },
    total: { fontSize: 9, textAlign: 'right', fontWeight: "black" },
    secTitle: { color: '#013367', fontSize: 11, marginBottom: 5 },
    rowTwoCol: { flexDirection: 'row', flexWrap: 'wrap', margin: '0 -5px 10px' },
  });


  return (
      <React.Fragment>
        <InnerPageLayout userData={userData} title={'Min gjeld'}>
          <View>
            <View style={[styles.section, styles.rowTwoCol]}>
              <PageHeader title="Godkjent forbrukslån" value={`${debtData && debtData.approvedConsumerLoan ? format(debtData.approvedConsumerLoan, true, true, false) : '0'} kr`} color={'#fff1df'} text={'#ff8b00'} />
              <PageHeader title="Gjenværende forbrukslån" value={`${debtData && debtData.remainingConsumerLoan ? format(debtData.remainingConsumerLoan, true, true, false) : '0'} kr`} color={'#f9f9f9'} text={'#000000'} />
              <PageHeader title="Innvilget kredittramme" value={`${debtData ? format(debtData.loanTypes?.filter(({ type }) => type === 'creditFacility' || type === 'chargeCard')?.flatMap(({ loans }) => loans)?.map(({ creditLimit }) => isNaN(Number(creditLimit)) ? 0 : Number(creditLimit))?.reduce((a, b) => a + b, 0), true, true, false) : '0'} kr`} color={'#f2faf6'} text={'#47b881'} />
              <PageHeader title="Brukt kreditt" value={`${debtData ? format(debtData.loanTypes?.filter(({ type }) => type === 'creditFacility' || type === 'chargeCard')?.flatMap(({ loans }) => loans)?.map(({ interestBearingBalance, nonInterestBearingBalance }) => (isNaN(Number(interestBearingBalance)) ? 0 : Number(interestBearingBalance)) + (isNaN(Number(nonInterestBearingBalance)) ? 0 : Number(nonInterestBearingBalance)))?.reduce((a, b) => a + b, 0), true, true, false) : '0'} kr`} color={'#c8dffc'} text={'#014E9E'} />
            </View>
          </View>
          <AboutEasyFinance />
        </InnerPageLayout>
        <InnerPageLayout userData={userData} title={'Min gjeldsoversikt'}>
          <View wrap={false}>
            {debtData?.loanTypes?.find(({ type }) => type === 'repaymentLoan')?.loans?.length > 0 &&
                <View style={styles.section1} wrap={false}>
                  <Text style={styles.secTitle}>Forbrukslån</Text>

                  {debtData?.loanTypes?.find(({ type }) => type === 'repaymentLoan')?.loans?.map((loan, index) => {
                    const tableHeader = ['Opprinnelig lånebeløp kr', 'Rentebærende saldo kr (restlån)', 'Gjenværende terminer', 'Nominell rente %', 'Gebyr kr', 'Terminlengde', 'Medlåntaker'];
                    const tableData = [
                      !isNaN(loan?.originalBalance) ? format(loan?.originalBalance) : '-',
                      !isNaN(loan?.balance || loan?.interestBearingBalance) ? format(loan?.balance || loan?.interestBearingBalance) : '-',
                      !isNaN(loan?.terms) !== '' ? format(loan?.terms, false) : '-',
                      !isNaN(loan?.nominalInterestRate) ? format(loan?.nominalInterestRate) : '-',
                      !isNaN(loan?.installmentCharges) ? format(loan?.installmentCharges) : '-',
                      loan?.installmentChargePeriod && loan?.installmentChargePeriod !== '' ? loan?.installmentChargePeriod?.replace('MONTHLY', 'Månedlig') : '',
                      !isNaN(loan?.coBorrower) ? Number(loan?.coBorrower) === 0 ? 'Ingen' : format(loan?.coBorrower, false) : '-',
                    ];

                    console.log('Forbrukslån Table Data:', tableData);

                    return (
                        <View key={index}>
                          <View style={styles.header}>
                            <Text style={styles.title}>{loan?.financialInstitutionName}</Text>
                            <Text style={[styles.title, styles.total]}>{format(loan?.balance)} kr</Text>
                          </View>
                          <PDFTable headers={tableHeader} data={[tableData]} style={{ marginBottom: 15 }} />
                        </View>
                    )
                  })}
                </View>
            }

            {debtData?.loanTypes?.find(({ type }) => type === 'creditFacility')?.loans?.length > 0 &&
                <View style={styles.section1} wrap={false}>
                  <Text style={styles.secTitle}>Kredittkort</Text>
                  {debtData?.loanTypes?.find(({ type }) => type === 'creditFacility')?.loans?.map((credit, index) => {
                    const table2Header = ['Kredittgrense', 'Rentebærende saldo', 'Ikke rentebærende saldo', 'Nominell rente', 'Gebyr'];
                    const table2Data = [
                      credit?.creditLimit && credit?.creditLimit !== '' ? `${format(credit?.creditLimit)} kr` : '0 kr',
                      credit?.interestBearingBalance && credit?.interestBearingBalance !== '' ? `${format(credit?.interestBearingBalance)} kr` : '0 kr',
                      credit?.nonInterestBearingBalance && credit?.nonInterestBearingBalance !== '' ? `${format(credit?.nonInterestBearingBalance)} kr` : '0 kr',
                      !isNaN(credit?.nominalInterestRate) ? `${format(credit?.nominalInterestRate)} %` : '0 %',
                      !isNaN(credit?.installmentCharges) ? `${format(credit?.installmentCharges)} kr` : '0 kr',
                    ];

                    console.log('Kredittkort Table Data:', table2Data);

                    const colWidths = ['15%', '20%', '25%', '20%', '20%'];

                    return (
                        <View key={index}>
                          <View style={styles.header}>
                            <Text style={styles.title}>{credit?.financialInstitutionName}</Text>
                            <Text style={[styles.title, styles.total]}>{format(Number(credit?.interestBearingBalance) + Number(credit?.nonInterestBearingBalance))} kr</Text>
                          </View>
                          <PDFTable headers={table2Header} data={[table2Data]} style={{ marginBottom: 15 }} columnWidths={colWidths} />
                        </View>
                    )
                  })}
                </View>
            }

            {debtData?.loanTypes?.find(({ type }) => type === 'chargeCard')?.loans?.length > 0 &&
                <View style={styles.section1} wrap={false}>
                  <Text style={styles.secTitle}>Faktureringskort</Text>
                  {debtData?.loanTypes?.find(({ type }) => type === 'chargeCard')?.loans?.map((credit, index) => {
                    const tableHeader3 = ['Rentebærende saldo (forfalt)', 'Ikke rentebærende saldo (utestående)'];
                    const tableData3 = [
                      `${format(credit?.interestBearingBalance)} kr`,
                      `${format(credit?.nonInterestBearingBalance)} kr`,
                    ];

                    const colWidths = ['40%', '60%'];

                    return (
                        <View key={index}>
                          <View style={styles.header}>
                            <Text style={styles.title}>{credit?.financialInstitutionName}</Text>
                            <Text style={[styles.title, styles.total]}>{format((isNaN(credit?.interestBearingBalance) ? 0 : Number(credit?.interestBearingBalance)) + (isNaN(credit?.nonInterestBearingBalance) ? 0 : Number(credit?.nonInterestBearingBalance)))} kr</Text>
                          </View>
                          <PDFTable headers={tableHeader3} data={[tableData3]} style={{ marginBottom: 15 }} columnWidths={colWidths} />
                        </View>
                    )
                  })}
                </View>
            }

            <Text style={{ fontSize: 7, marginBottom: 30 }}>
              Oversikten er hentet fra Gjeldsregisteret.no og inneholder informasjon om alle dine usikrede lån og kreditter. Har du feil eller mangler i informasjonen om dine kreditter, må du selv rette opp i dette med det aktuelle kredittselskapet. Forbrukslån, rammekreditter og faktureringskort innhenter vi fra Gjeldsregisteret.
            </Text>
          </View>
        </InnerPageLayout>
      </React.Fragment>
  );
};

export default DebtList;
