import React from 'react';
import classes from '../index.module.scss';
import {Container, Nav} from "react-bootstrap";
import {NavLink, useNavigate} from "react-router-dom";
import Button from "../../Button";
import { getToken } from '../../../Services/api/Auth';

const SubHeader = ({data, isBusinessPage}) => {
const navigate = useNavigate();
const user = JSON.parse(getToken() ? localStorage.getItem('auth') : '{}')
    return(
        <React.Fragment>
            <div className={classes.subHeader}>
                <Container>
                    <Nav as="ul">
                        {data.map((item, index) => (
                            <Nav.Item as="li">
                                <a href={item.url} className={
                                    item.text === 'Bedrift' ?
                                        window.location.pathname.includes('bedrift') || window.location.pathname.includes('business') ? 'active' : ''
                                        : item.text === 'Privat' ?
                                            !window.location.pathname.includes('bedrift') && !window.location.pathname.includes('business') ? 'active' : ''
                                            : ''
                                }>
                                    <div className={classes.imgBox}>
                                        <i className={item.icon}></i>
                                    </div>
                                    {item.text}
                                </a>

                            </Nav.Item>
                        ))}
                    </Nav>
                    {isBusinessPage && !user?.hasDefaultCompany && <Button
                        onClick={() => {
                            if(getToken()){
                                navigate('/bedriftsdashbord');
                            }
                            else{
                                localStorage.setItem('btnRoute', 'bHeader');
                                localStorage.setItem('redirect', '/bedriftsdashbord')
                                window.location.href = '/register'
                            }
                        }}
                        text={'Opprett en gratis selskapskonto'}
                        variant={'orange small my-2'}
                        className={'hideMobile'}
                    />}

                </Container>
            </div>
        </React.Fragment>
    )
}

export default SubHeader;