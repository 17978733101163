import { getToken } from "./Auth";

export const getSecuredData = (loanObject ) => new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/LoanDetail/GetFinansPortalenSecuredData`;
    Object.entries(loanObject).map(([ key, value ], index) => {
        if(value === undefined || value === null || value === '' || value === 'undefined') return;
        if(index === 0) url = url + `?${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`
        else url = url + `&${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`;
    })
    fetch(url)
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resp => resolve(resp))
    .catch(err => reject(err))
})

export const loadInputSchema = (langId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/LoanSchema/GetAll?LanguageId=${langId}`)
    .then(response => {
        if(response.status === 200) return response.json()
        else reject(response)
    })
    .then(response => resolve(response.data))
    .catch(err => reject(err));
})

export const getCreditCardData = (loanObject) => new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/LoanDetail/GetFinansPortalenKredittkortData`;
    Object.entries(loanObject).map(([ key, value ], index) => {
        if(value === undefined || value === null || value === '' || value === 'undefined') return;
        if(index === 0) url = url + `?${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`
        else url = url + `&${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`;
    });
    fetch(url)
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resp => resolve(resp))
    .catch(err => reject(err))
});

export const getUnsecuredData = (loanObject) => new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/LoanDetail/GetFinansPortalenUnSecuredData`;
    Object.entries(loanObject).map(([ key, value ], index) => {
        if(value === undefined || value === null || value === '' || value === 'undefined') return;
        if(index === 0) url = url + `?${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`
        else url = url + `&${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`;
    });
    fetch(url)
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resp => resolve(resp))
    .catch(err => reject(err))
});

export const getBSUData = (loanObject) => new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/LoanDetail/GetFinansPortalenBSUData`;
    Object.entries(loanObject).map(([ key, value ], index) => {
        if(value === undefined || value === null || value === '' || value === 'undefined') return;
        if(index === 0) url = url + `?${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`
        else url = url + `&${key}=${typeof value === 'string' ? value?.replace(/^,|,$/g, '') : value}`;
    });
    fetch(url)
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resp => resolve(resp))
    .catch(err => reject(err))
});

export const getAllByLenderId = (lenderId) => new Promise((resolve, reject) => {
    const url = `${process.env.REACT_APP_API}/LoanDetail/GetAllByLenderId?EnabledOnly=true&LenderId=${lenderId}`;
    fetch(url)
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resp => resolve(resp))
    .catch(err => reject(err))
})

export const getAllCards = (loanTypeId) => new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/LoanDetail/GetAll?LanguageId=1`;
    if(loanTypeId) url += `&loanTypeId=${loanTypeId}`;
    fetch(url)
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else reject(resp);
    })
    .then(resp => resolve(resp?.data))
    .catch(reject);
})

export const loadGjeldsregisteretData = (forced) => new Promise((resolve, reject) => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_API}/Gjeldsregisteret/GetConsumerDataById${forced ? '?isForceUpdateDebitInformation=true' : ''}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resolve)
    .catch(reject)
})