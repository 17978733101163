import React, { useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ScoreDocument from './ScoreDocument';
import { getCurrentUser } from "../../../../Services/api/Auth";
import { loadGjeldsregisteretData } from "../../../../Services/api/Loan";

const DownLoadButtonDebtCheckPDF = ({ buttonName, fileName }) => {
    const [userData, setUserData] = useState(null);
    const [debtData, setDebtData] = useState(null);
    const [loading, setLoading] = useState(true); // State to track loading state

    useEffect(() => {
        const fetchData = async () => {
            try {
                const user = await getCurrentUser();
                setUserData(user);

                const debtResp = await loadGjeldsregisteretData();
                setDebtData(debtResp.debtInformation);

                setLoading(false); // Set loading to false when data is fetched
            } catch (err) {
                console.error("Error fetching data:", err);
                setLoading(false); // Ensure loading is set to false on error
            }
        };

        fetchData();
    }, []);

    const format = (value, divideBy100 = false) => {
        if (isNaN(value)) return "N/A";
        const formattedValue = Intl.NumberFormat("no-NO").format(divideBy100 ? value / 100 : value);
        return `${formattedValue} kr`;
    };

    const downloadPDF = async () => {
        if (!userData || !debtData) {
            console.error("Data is not ready yet");
            return;
        }

        try {
            setLoading(true); // Set loading to true before PDF generation
            const asBlob = await pdf(
                <ScoreDocument
                    userData={userData}
                    debtData={debtData}
                    format={format}
                />
            ).toBlob();
            saveAs(asBlob, `${fileName}.pdf`);
        } catch (error) {
            console.error("Error generating PDF:", error);
        } finally {
            setLoading(false); // Set loading to false after PDF generation
        }
    };
    return (
        <button onClick={downloadPDF}>
            {buttonName}
        </button>
    );
};

export default DownLoadButtonDebtCheckPDF;
