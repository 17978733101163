import { all, takeEvery } from "redux-saga/effects";
import { getComponents } from "./componentSaga";
import { COMPONENTS_LOAD, COMPONENTS_LOADED } from "../actions/component";
import { applicationLoad } from "./applicationLoadSaga";

function* rootSaga(){
    yield all([
        // takeEvery(COMPONENTS_LOAD, getComponents),
        takeEvery('APPLICATION_LOAD', applicationLoad)
    ])
}

export default rootSaga;