import React from 'react';
import classes from './index.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import imgA03 from '../../Images/imgA03.svg';
import Button from "../Button";
import ComparisonTabsBox from "../ComparisonTabsBox";


const GetStarted = () => {
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <div className={classes.colList}>
                      <div className={classes.col}>
                          <div className={classes.imgBox} style={{maxWidth: '500px'}}>
                              <img src={imgA03} loading="lazy" alt={'title'} />
                          </div>
                      </div>
                      <div className={classes.col}>
                          <SectionTitle description={'Er din bedrift i vekst og trenger kapital for å ekspandere? Drømmer du om nytt utstyr, ønsker finansiering for et spennende prosjekt, eller ser du etter midler til å investere i markedsføring? Uansett hva ditt forretningsbehov måtte være, tilbyr EasyFinance en enkel løsning for å skaffe deg de nødvendige midlene.'}>
                              <span className={'fw-normal'}>Hva kreves for å </span>
                              <span className={'fw-semibold'}>komme i gang?</span>
                          </SectionTitle>
                          <ul className={'checkList circle yellow mb-5'}>
                              <li>Selskapet er registert i Norge</li>
                              <li>Du driver ENK, AS, DA, ANS, eller ASA.</li>
                              <li>Grunnleggende detaljer om bedriften</li>
                              <li>Få svar innen 60 sekunder</li>
                          </ul>
                          <h4>Klar til å søke?</h4>
                          <div className={classes.btnRow}>
                                  {/*<Button*/}
                                  {/*    text={'Søk nå'}*/}
                                  {/*    variant={'dark w-100'}*/}
                                  {/*/>*/}
                                  <ComparisonTabsBox isSmall />
                          </div>
                          {/*<div className={'font-12 text-light'}>The document requirements may change according to the loan size and type of company</div>*/}
                      </div>
                  </div>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default GetStarted;