export const creditCardSettings = {
    loanTypeId: 3,
    age: 18,
    annualIncome: 50000
}

export const securedLoanSettings = {
    loanTypeId: 1,
    loanAmount: 1700000,
    housingValue: 2000000,
    repaymentPeriod: 25,
    age: 25
}

export const bsuSettings = {
    totalSavings: 1000,
    age: 18
}

export const unsecuredSettings = {
    loanAmount: 200000,
    repaymentPeriod: 24,
    purchasePrice: 300000,
    loanType: 'forbruk'
}