import React, { useEffect, useRef }  from "react";
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import ComparisonTabsBox from "../ComparisonTabsBox";
import Button from "../Button";
import { getToken } from "../../Services/api/Auth";
import { signicatLogin, vippsLogin } from "../../Services/api/Login";

const BusinesLandingPgaeBanner = ({isBusiness, vippsBtnHide}) => {
    const hideBreadcrumbs = window.location.pathname === '/';
    const token = getToken();
    const isUserLoggedIn = token && token !== '';
    const _handleVipps = () => {
        vippsLogin()
        .then(resp => {
            localStorage.setItem('redirect', '/bedriftsdashbord');
            window.location.href = resp;
        })
    }
    const _handleBankId = () => {
        signicatLogin()
        .then(resp => {
            localStorage.setItem('redirect', '/bedriftsdashbord');
            window.location.href = resp;
        })
    }

    return(
        <React.Fragment>
            <div className={`
                ${classes.banner}
                ${isBusiness && classes.businessBanner}
            `}>
                <Container>
                    <div className={classes.bannerHolder}>
                        <React.Fragment>
                            <div className={classes.col}>
                                <div className={`${classes.textHolder}`}>
                                    <h1 id="dynamicHeader">
                                        <span className={'fw-normal'}>Din bedrifts suksess begynner</span>
                                        <span className={'fw-normal'}>med riktig kredittscore</span><br />
                                        <span className={'fw-bold'}> - få den gratis nå!</span>
                                    </h1>
                                   {/* <p></p>*/}
                                    {vippsBtnHide ? '' : <>
                                        {!isUserLoggedIn ? <>
                                            <div className={'text-center'} style={{maxWidth: '300px', display: "inline-block"}}>
                                                <Button
                                                    variant={'vipps large w-100 my-2'}
                                                    text={'Logg inn med'}
                                                    icon={'icon-vipps mx-2'}
                                                    onClick={_handleVipps}
                                                />
                                                {/* TODO: PRODUCTION */}
                                                {/* <Button
                                                    variant={'large link my-2 text-white font-12'}
                                                    text={'Logg inn med'}
                                                    icon={'icon-BankID-white mx-2'}
                                                    onClick={_handleBankId}
                                                /> */}
                                            </div>
                                        </> : <>
                                            <Button
                                                variant={'orange-border large my-2'}
                                                text={'Gå til Dashboard'}
                                                url={'/bedriftsdashbord'}
                                            />
                                        </>
                                        }
                                    </> }

                                </div>
                            </div>
                            <div className={classes.col}>
                                {isBusiness && <ComparisonTabsBox isBusiness /> }
                            </div>
                        </React.Fragment>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default BusinesLandingPgaeBanner;