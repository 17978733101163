import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import { Col, Container, Row } from "react-bootstrap";
import SocialLinks from "../SocialLinks";
import QuickLinks from "../QuickLinks";
import { getActiveFooter } from "Services/api/Navigation";
import { loadSettings } from "Services/api/SettingsService";
import { useSelector } from "react-redux";
import CopyRight from "./CopyRight";
import {useLocation} from "react-router-dom";
import Logo from "../Logo";
import NewsLetter from "../NewsLetter";

const Footer = ({ detail, copyRightBar }) => {
    const location = useLocation();
    const [nav, setNav] = useState({});
    // const [settings, setSettings] = useState();
    const { settings } = useSelector(state => state.components);
    const appSettings = useSelector(state => state.settings.settings);
    const languageId = 1;

    useEffect(() => {
        getActiveFooter().then(setNav);
    }, []);

    // useEffect(() => {
    //     if(components?.length > 0)
    //     loadSettings(languageId, components)
    //     .then(setSettings)
    // }, [components])

    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = [
        '/bedrift/',
        '/bedrift/finansieringsguiden',
        '/bedrift/bedriftslån',
        '/bedrift/kredittsjekk',
        '/bedrift/who-can-get-a-business-loan',
        '/bedrift/fikk-du-ikke-bedriftslån',
        '/bedrift/grøntlån',
        "/bedrift/trenger-din-bedrift-et-løft",
        "/bedrift/hvem-kan-få-bedriftslån",
        "/bedrift/kredittsjekk-for-å-unngå-tap-på-dårlige-betalere",
    ].some(path => decodedPathname.includes(path));


    const navData = [
        {
            id:'1',
            name:'loanWithSecurity',
            navigation: [
                {title: 'Bedriftslån', url: '/bedrift/bedriftslån'},
                {title: 'Grønne lån', url: '/bedrift/grøntlån'},
                {title: 'Kredittsjekk', url: '/bedrift/kredittsjekk'},
            ],
        },
        {
            id:'2',
            name:'loanWithoutSecurity',
            navigation: [
                {title: 'Om oss', url: '/om-oss'},
                {title: 'Kontakt oss', url: '/kontakt'},
                {title: 'Blogger', url: '/blogger'},
                {title: 'Finansieringsguiden', url: '/bedrift/finansieringsguiden'},
                {title: 'Spørsmål og svar', url: '/bedrift#faqs'},
                {title: 'Våre partnere', url: '/bedrift#partner'},
                {title: 'Bli vår partner', url: '/kontakt'},
                {title: 'Book en prat', url: '/kontakt'},
            ],
        },
    ]


    return (
        <React.Fragment>
            <footer id={classes.footer} className={`${isBusiness && classes.darkFooter} ${copyRightBar && classes.copyRightBar}`}>
                {isBusiness ? (
                    <Container>
                        <div className={classes.colRow}>
                            <Row>
                                <Col lg={4}>
                                    <div className={'overflow-hidden mb-3'}>
                                        <Logo darkFooter={true}  />
                                    </div>
                                    <p>Få kontroll over din økonomi med EasyFinance. Vi tilbyr skreddersydde finansielle løsninger for små og mellomstore bedrifter,
                                        inkludert kredittsjekk, regnskapsinformasjon og mulighet for finansiering.
                                        Forenkle økonomistyringen og ta velinformerte beslutninger med EasyFinance i dag.</p>
                                </Col>
                                <Col lg={2}>
                                    <QuickLinks
                                        title={'Lån'}
                                        navigation={navData[0].navigation}
                                        name="loanWithSecurity"
                                    />
                                </Col>
                                <Col lg={2}>
                                    <QuickLinks
                                        title={'EasyFinance'}
                                        navigation={navData[1].navigation}
                                        name="loanWithoutSecurity"
                                    />
                                </Col>
                                <Col lg={4}>
                                    <NewsLetter  isBusiness={isBusiness} />
                                </Col>
                            </Row>
                        </div>
                        <CopyRight />
                    </Container>
                ):(
                    <Container>
                        {copyRightBar ? <>
                            <CopyRight />
                        </> : <>
                            <div className={classes.colRow}>
                                {
                                    nav?.items?.map((navItem, i) => {
                                        return <div className={classes.col} key={i}>
                                            <QuickLinks
                                                title={navItem.title}
                                                navigation={navItem.children}
                                            />
                                        </div>
                                    })
                                }
                            </div>
                            <Row>
                                <Col md={12}>
                                    {settings?.site?.copyrightText}
                                </Col>
                            </Row>
                            <CopyRight />
                        </>}

                    </Container>
                )}


            </footer>
        </React.Fragment>
    );
}

export default Footer;
