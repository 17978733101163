import React from 'react';
import FindYourCompany from "../BusinessDashboard/FindYourCompany";

const FindCompany = () => {
  return(
      <React.Fragment>
          <FindYourCompany />
      </React.Fragment>
  )
}

export default FindCompany;