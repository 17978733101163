import { processSignicatLogin } from "Services/api/Login";
import { useEffect, useState } from "react";
import { Spinner, Alert } from "react-bootstrap";
import Button from "../../Components/Button";
import { processVippsLogin, sendErrorMail } from "../../Services/api/Login";
import classes from "./index.module.scss";
import { defaultCompanyReport } from "../../Services/api/Company";
import { useNavigate } from "react-router-dom";
import {
  getConsumerReport,
  resendConfirmationEmail,
} from "../../Services/api/Auth";
import Loader from "../../Components/Loader";

function LoginProcess() {
  const [activationError, setActivationError] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [user, setUser] = useState({});
  const [error, setError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [emailSendError, setEmailSendError] = useState(false);
  const [loadingEmailSend, setLoadingEmailSend] = useState(false);
  const navigate = useNavigate();

  const sessionId = new URLSearchParams(window.location.search).get(
    "sessionId"
  );
  const code = new URLSearchParams(window.location.search).get("code");
  const errorDescription = new URLSearchParams(window.location.search).get(
    "error_description"
  );
  if (errorDescription && errorDescription !== "") {
    navigate("/login");
  }

  useEffect(() => {
    if (sessionId) {
      processSignicatLogin(sessionId)
        .then((resp) => {
          setUser(resp);
          if (
            resp?.errorResponse &&
            resp?.errorResponse !== "" &&
            resp?.errorResponse !== null
          ) {
            setActivationError(true);
            setErrorResponse(resp?.errorResponse);
          } else if (resp?.userRegistered == true && resp?.authorized == true) {
            let redirect = localStorage.getItem("redirect");
            redirect =
              redirect && redirect !== "" && redirect !== "null"
                ? redirect
                : "/privatdashbord";
            localStorage.setItem("auth", JSON.stringify(resp));
            getConsumerReport()
              .then(({ companyEngagements }) => {
                if (
                  companyEngagements &&
                  companyEngagements.length > 0 &&
                  !resp.hasDefaultCompany &&
                  redirect === "/bedriftsdashbord"
                ) {
                  navigate("/find-your-company");
                } else {
                  localStorage.removeItem("btnRoute");
                  localStorage.removeItem("redirect");
                  navigate(redirect);
                }
              })
              .catch(() => navigate("/privatdashbord"));
          } else if (
            resp.userRegistered == false &&
            resp?.nin &&
            resp?.nin !== ""
          ) {
            localStorage.setItem("auth", JSON.stringify(resp));
            navigate("/signup");
          } else navigate("/login");
        })
        .catch((err) => {
          setError(true);
        });
    }
  }, [sessionId]);
  useEffect(() => {
    if (code) {
      processVippsLogin(code)
        .then((resp) => {
          setUser(resp);
          if (
            resp?.errorResponse &&
            resp?.errorResponse !== "" &&
            resp?.errorResponse !== null
          ) {
            setActivationError(true);
            setErrorResponse(resp?.errorResponse);
          } else if (resp?.userRegistered == true && resp?.authorized == true) {
            let redirect = localStorage.getItem("redirect");
            redirect =
              redirect && redirect !== "" && redirect !== "null"
                ? redirect
                : "/privatdashbord";
            localStorage.setItem("auth", JSON.stringify(resp));
            getConsumerReport()
              .then(({ companyEngagements }) => {
                if (
                  companyEngagements &&
                  companyEngagements.length > 0 &&
                  !resp?.hasDefaultCompany &&
                  redirect === "/bedriftsdashbord"
                ) {
                  navigate("/find-your-company");
                } else {
                  localStorage.removeItem("btnRoute");
                  localStorage.removeItem("redirect");
                  navigate(redirect);
                }
              })
              .catch((err) => {
                navigate("/privatdashbord");
                sendErrorMail({
                  error: "getConsumerReport() throwing an error.",
                  err,
                });
              });
          } else if (
            resp.userRegistered == false &&
            resp?.nin &&
            resp?.nin !== ""
          ) {
            localStorage.setItem("auth", JSON.stringify(resp));
            navigate("/signup");
          } else navigate("/login");
        })
        .catch((err) => {
          setError(true);
          sendErrorMail({
            error: "processVippsLogin() throwing an error",
            err,
          });
        });
    }
  }, [code]);

  const _handleEmailResend = () => {
    setLoadingEmailSend(true);
    resendConfirmationEmail(user?.nin)
      .then(() => {
        setEmailSent(true);
        setEmailSendError(false);
      })
      .catch(() => {
        setEmailSendError(true);
        setEmailSent(false);
      })
      .finally(() => {
        setActivationError(false);
        setLoadingEmailSend(false);
      });
  };
  return (
    <div className={classes.loginLoader}>
      {emailSent && (
        <Alert variant="success">
          E-postbekreftelse er sendt. Vennligst sjekk innboksen din.
        </Alert>
      )}
      {emailSendError && (
        <Alert variant="danger">
          Det oppsto en feil mens du prøvde å logge inn. Vennligst prøv igjen.
        </Alert>
      )}
      {activationError && (
        <Alert>
          <Alert.Heading>Feil ved pålogging</Alert.Heading>
          <p>{errorResponse}</p>
          {/* <hr />
            <Button text={loadingEmailSend ? <Spinner /> : 'Resend Email'} onClick={!loadingEmailSend ? _handleEmailResend : () => { }} disabled={loadingEmailSend} /> */}
        </Alert>
      )}
      {!(errorDescription && errorDescription !== "") &&
        !activationError &&
        !emailSendError &&
        !emailSent &&
        (error ? (
          <Alert variant="danger">
            Det oppstod en feil under forsøk på å logge på. Vær så snill, prøv
            på nytt.
          </Alert>
        ) : (
          <>
            <Loader
              style={{
                minHeight: "330px",
                marginBottom: "30px",
                marginTop: "30px",
                backgroundColor: "#fff",
              }}
              variant="success"
            />
            <span>Pålogging Pågår</span>
          </>
        ))}
    </div>
  );
}
export default LoginProcess;
