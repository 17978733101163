import React from "react";
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import ComparisonTabsBox from "../ComparisonTabsBox";
import Button from "../Button";
import { signicatLogin, vippsLogin } from "../../Services/api/Login";
import { getToken } from "../../Services/api/Auth";

const BusinesStaticPageBanner = ({isBusiness, data, loginBtn, vippsBtnHide}) => {
    const hideBreadcrumbs = window.location.pathname === '/';
    const isUserLoggedIn = getToken() && getToken() !== '';
    const _handleVipps = () => {
        vippsLogin()
            .then(resp => {
                localStorage.setItem('redirect', '/bedriftsdashbord')
                window.location.href = resp;
            })
    }
    const _handleBankId = () => {
        signicatLogin()
            .then(resp => {
                localStorage.setItem('redirect', '/bedriftsdashbord')
                window.location.href = resp;
            })
    }


    return(
        <React.Fragment>
            <div className={`
                ${classes.banner}
                ${isBusiness && classes.businessBanner}
            `}>
                <Container>
                    <div className={classes.bannerHolder}>
                        <React.Fragment>
                            <div className={classes.col}>
                                {data.map((item) => (
                                    <div className={`${classes.textHolder} ${item.hideCalculator && 'text-center pt-5'}`} key={item.id}>
                                        {item.subTitle && <span className={classes.subTitle}>{item.subTitle}</span>}
                                        <h1 id="dynamicHeader" className={`${item.hideCalculator && 'text-center'}`}>
                                            {item.title.map((title) => (
                                                <span key={title.id} className={title.class}>{title.title}{" "}</span>
                                            ))}
                                        </h1>
                                        <div className={"text-white font-14 mb-5"}>{item.description}</div>
                                        {item.btnText &&
                                            <Button
                                                text={item.btnText}
                                                url={isUserLoggedIn ? item.btnUrl : `/login?redirect=${item.btnUrl}`}
                                                variant={'orange-border large m-2'}
                                            />
                                        }
                                        {vippsBtnHide ? '' : <>
                                            {!isUserLoggedIn ? <>
                                                <div className={'text-center'} style={{maxWidth: '300px', display: "inline-block"}}>
                                                    <Button
                                                        variant={'vipps large w-100 my-2'}
                                                        text={'Logg inn med'}
                                                        icon={'icon-vipps mx-2'}
                                                        onClick={_handleVipps}
                                                    />
                                                    {/* TODO: PRODUCTION */}
                                                    {/* <Button
                                                    variant={'large link my-2 text-white font-12'}
                                                    text={'Logg inn med'}
                                                    icon={'icon-BankID-white mx-2'}
                                                    onClick={_handleBankId}
                                                /> */}
                                                </div>
                                            </> : <>
                                                <Button
                                                    variant={'orange-border large my-2'}
                                                    text={'Gå til Dashboard'}
                                                    url={'/bedriftsdashbord'}
                                                />
                                            </>
                                            }
                                        </> }

                                    </div>
                                ))}
                            </div>
                            {data.map((item) =>
                                !item.hideCalculator ? (
                                    <div className={classes.col}>
                                        <ComparisonTabsBox isBusiness={isBusiness} />
                                    </div>
                                ) : null
                            )}

                        </React.Fragment>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default BusinesStaticPageBanner;