import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row, Spinner } from "react-bootstrap";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import classes from './index.module.scss';
import AnimatedImage from "../../Components/AnimatedImg";
import json3 from "../../Images/Gjeldsoversikt.svg";
import json2 from "../../Images/Boligverdi.svg";
import json1 from "../../Images/Kredittsjekk.svg";
import SectionTitle from "../../Components/SectionTitle";
import Thankyou from "../../Images/Thankyou.svg";
import { onError } from "Services/api/Image";
import { LoginService, signicatLogin } from "Services/api/Login";
import { Alert } from "react-bootstrap";
import { vippsLogin } from "../../Services/api/Login";

const LoginButtons = () => {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [authObject, setAuthObject] = useState({});
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const authString = localStorage.getItem('auth');
        const auth = JSON.parse(authString !== '' && authString ? authString : '{}');
        setAuthObject(auth);
        if(auth?.jwToken && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()){
            window.location.href = '/privatdashbord';
        }
    }, []);

    const _handleBankId = () => {
        signicatLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }
    const _handleVippsLogin = () => {
        vippsLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }

    if (showThankYou) {
        return (
            <React.Fragment>
                <SectionTitle textCenter description={'You have successfully registered. Please Check your email and verify your email address.'}>
                    <span className={'fw-normal'}>Congratulation</span>
                </SectionTitle>

                <div className={'p-3'}>
                    <div className={'position-relative text-center'}>
                        <div className={'text-center mb-5'}>
                            <img src={Thankyou} loading="lazy" onError={onError} alt={''} />
                        </div>
                        <Button
                            text={'Go to Dashboard'}
                            url={'/privatdashbord'}
                            variant={'darkBorder'}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>

            <div className={`${classes.rowHolder} ${classes.smallForm}`}>
                <div className={classes.col}>
                    {!showResetForm ? (
                        <React.Fragment>
                            <FormGroup>
                                <Button
                                    text={'Logg inn med Vipps'}
                                    variant={'vipps'}
                                    onClick={_handleVippsLogin}
                                />
                            </FormGroup>
                            {authObject.errorResponse && authObject.errorResponse !== '' ? <Alert variant='danger'>{authObject?.errorResponse}</Alert> : <></>}
                        </React.Fragment>
                    ) : null}
                </div>
            </div>

            <div className={classes.authFooter}>
                <p>
                Logg inn med
                    <Button
                        onClick={_handleBankId}
                        variant={'link'}
                        icon={'icon-BankID'}
                        underline
                    />
                </p>
            </div>
        </React.Fragment>
    );
}

export default LoginButtons;

