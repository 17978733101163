import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import {Accordion, Container} from "react-bootstrap";
import faqsData from './faqsData';
import SectionTitle from "../SectionTitle";
import { loadFaqsData } from "Services/api/FAQsService";

const FAQs = ({ detail, faqData }) => {
    const languageId = 1;
    const [faqsData, setFaqsData] = useState();
    useEffect(() => {
        loadFaqsData(languageId)
            .then(setFaqsData);
    }, []);

    return(
        <React.Fragment>
            <section className={'section'} id={"faqs"}>
                <Container>
                    {faqData ? <>
                        <React.Fragment>
                            {faqData.map(item => (
                                <SectionTitle textCenter description={item?.heading?.description}>
                                    {item?.heading?.title?.map((title, i) => (
                                        <span className={title.class} key={i}>{title.title}</span>
                                    ))}
                                </SectionTitle>
                            ))}
                            <div className={classes.faqContainer}>
                                {faqData.map(item => (
                                    <div className={`${classes.col} ${item.singleCol && classes.singleCol}`} key={item.id}>
                                        <Accordion defaultActiveKey={'0'}>
                                            {item.questions.map(question => (
                                                <Accordion.Item eventKey={question.id} key={question.id}>
                                                    <Accordion.Header>{question.title}</Accordion.Header>
                                                    <Accordion.Body><p dangerouslySetInnerHTML={{ __html: question.description }} /></Accordion.Body>

                                                </Accordion.Item>
                                            ))}
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    </> : <>
                        <React.Fragment>
                            <SectionTitle textCenter description={detail?.heading?.description}>
                                { detail?.heading?.title?.map((title, i) => <span className={title.class} key={i}>{title.title}</span>) }
                            </SectionTitle>
                            <div className={classes.faqContainer}>
                                {detail?.questions?.map(faq => JSON.stringify(faqsData?.questions[faq]) === '{}' || faqsData?.questions[faq]?.category === '' ? null :(
                                    <div className={classes.col}>
                                        <Accordion key={faq.question} defaultActiveKey={'0'}>
                                            <Accordion.Item eventKey={faq.id}>
                                                <Accordion.Header>{faqsData?.questions[faq]?.title}</Accordion.Header>
                                                <Accordion.Body>
                                                    {faqsData?.questions[faq]?.description}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    </>}

                </Container>
            </section>
        </React.Fragment>
    )
}

export default FAQs;