import React, { useState, useMemo, useEffect } from 'react';
import DataTable from "react-data-table-component";
import FormInput from "../../../../Components/FormInput";
import { recentlyViewedCompanies } from '../../../../Services/api/Company';
import moment from 'moment';
import { getConsumerReport } from '../../../../Services/api/Auth';
import {Spinner, Alert} from 'react-bootstrap'
import { useNavigate } from "react-router-dom";
import CompanyNotExist from "../../DashboardComponent/CompanyNotExist";
import Loading from "../../../../Components/Loading";

const ViewedCompanies = () => {
    const [filterText, setFilterText] = useState('');
    const [data, setData] = useState([]);
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [companyEngagements, setCompanyEngagements] = useState();
    const filteredData = useMemo(() => {
        return data.filter(item => {
            const { country, createdOn, companyName, companyNumber } = item;
            const searchTerm = filterText.toLowerCase();
            return (
                country?.toLowerCase()?.includes(searchTerm) ||
                createdOn?.toLowerCase()?.includes(searchTerm) ||
                companyName?.toLowerCase()?.includes(searchTerm) ||
                companyNumber?.toLowerCase()?.includes(searchTerm)
            );
        });
    }, [data, filterText]);

    const columns = [
        {
            name: 'Land',
            selector: 'country',
        },
        {
            name: 'Dato',
            selector: item => moment(item?.createdOn).format('DD/MM/YYYY')
        },
        {
            name: 'Firmanavn',
            selector: 'companyName',
        },
        {
            name: 'firmanummer',
            selector: 'companyNumber',
        },
        {
            name: 'Sist sett bedriftsresultat',
            selector: 'lastViewedCompaniesScore',
        },
    ];

    const paginationOptions = {
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const customStyles = {
        rows: {
            spacing: '20px',
        },
    };

    const handleFilterChange = e => {
        setFilterText(e.target.value);
    };

    useEffect(() => {
        setLoading(true);
        getConsumerReport()
        .then(({ companyEngagements }) => {
            setCompanyEngagements(companyEngagements);
            const user = JSON.parse(localStorage.getItem('auth'));
            setUser(user);
            if(companyEngagements && companyEngagements.length > 0 && !user?.hasDefaultCompany)
                navigate('/find-your-company')
            recentlyViewedCompanies()
            .then(resp => setData(resp?.data))
            .finally(() => setLoading(false))
        })
        .catch(() => {
            setLoading(false);
        })

    }, [])

    return (
        loading ? <Loading style={{position: 'absolute'}} /> : <React.Fragment>
            {user?.hasDefaultCompany ? <div className={'contentBox'}>
                <h4>Nylig viste selskaper</h4>
                <FormInput
                    type={'search'}
                    placeholder="Firmanavn / firmanummer / safenummer"
                    value={filterText}
                    onChange={handleFilterChange}
                />
                <DataTable
                    columns={columns}
                    data={filteredData}
                    selectableRows
                    pagination
                    paginationComponentOptions={paginationOptions}
                    persistTableHead
                    customStyles={customStyles}
                />
            </div> : !companyEngagements || companyEngagements.length === 0 ? <CompanyNotExist />  : <Spinner animation='border'/>}
        </React.Fragment>
    );
};

export default ViewedCompanies;
