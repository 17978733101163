import React from 'react';
import classes from './index.module.scss';
import Button from "../../../../Components/Button";
import StarRating from "../../../../Components/StarRating";


const AdAffiliatesBox = ({data}) => {
  return(
      <React.Fragment>
          {data.map(item => (
              <div className={classes.cardBox} key={item.id}>
                  <div className={classes.box}>
                      <div className={classes.logo}>
                          <a href={item.btnUrl}>
                              <img src={item.img} loading="lazy" alt={item.title} />
                          </a>
                      </div>
                      <p>{item.description}</p>
                      <div className={'mb-4'}>
                          <StarRating rating={item.rating} initialRating={item.rating} />
                      </div>
                      <div className={'font-10'}>Annonse</div>
                      <Button text={'Søk nå'} variant={'radius-medium'} url={item.btnUrl} target={'_blank'} icon={'fal fa-arrow-right '} />
                      <div className={'font-11 pt-3'} style={{lineHeight: '15px'}}>{item.loanExample}</div>
                  </div>
              </div>
          ))}
      </React.Fragment>
  )
}

export default AdAffiliatesBox;