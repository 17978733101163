const faqData = [
    {
        id: 1,
        heading: {
            description: "Here are some questions our customers ask. Check our FAQ for anything we haven't covered.",
            title: [
                { id: 1, title: "Frequently asked ", class: "fw-normal" },
                { id: 1, title: "questions", class: "fw-bold" },
            ]
        },
        singleCol: true,
        questions: [
            {
                id: 1,
                title: "Hva er et Grønt Lån fra EasyFinance?",
                description: " Et Grønt Lån fra EasyFinance er en finansieringsløsning rettet mot bedrifter som ønsker å investere i miljøvennlige prosjekter. Det støtter bedrifter i deres bærekraftige initiativer og bidrar til den grønne omstillingen i Norge.",
            },
            {
                id: 2,
                title: "Hvilke fordeler gir et Grønt Lån?",
                description: "Med et Grønt Lån kan din bedrift bidra til å nå FNs bærekraftsmål, styrke merkevarebyggingen, og tiltrekke seg miljøbevisste kunder og ansatte. I tillegg kan det gi bedre vilkår enn ordinære bedriftslån.",
            },
            {
                id: 3,
                title: "Hva kan jeg bruke et Grønt Lån til?",
                description: "Du kan bruke Grønne Lån til investeringer som miljøvennlig transport, fornybar energi, energieffektiviseringstiltak eller prosjekter som reduserer forurensning.",
            },
            {
                id: 4,
                title: "Hvordan kvalifiserer min bedrift seg for et Grønt Lån hos EasyFinance?",
                description: "Din bedrift må vise til tiltak eller investeringsplaner som støtter miljøvennlige prosjekter, som kjøp av elbil eller installasjon av solceller.",
            },
            {
                id: 5,
                title: "Hvordan støtter EasyFinance bedrifter som vil investere grønt?",
                description: "Vi tilbyr skreddersydde Grønne Lån med konkurransedyktige vilkår, rask behandlingstid, og veiledning gjennom hele søknadsprosessen for å realisere dine grønne prosjekter.",
            },
            {
                id: 6,
                title: "Hvilken dokumentasjon kreves for å søke om et Grønt Lån?",
                description: "Vi trenger informasjon om bedriftens omsetning og dokumentasjon som viser dine planer eller tiltak for miljøvennlige investeringer.",
            },
            {
                id: 7,
                title: "Hva skiller et Grønt Lån hos EasyFinance fra andre lån?",
                description: "Et Grønt Lån hos EasyFinance kommer med potensielt bedre betingelser, støtter direkte dine bærekraftige initiativer, og viser både markedet og dine kunder at du er forpliktet til en grønnere fremtid.",
            },
            {
                id: 8,
                title: "Er det raskt å få utbetalt et Grønt Lån?",
                description: "Ja, hos EasyFinance tilbyr vi like rask utbetaling på Grønne Lån som våre standard lån, ofte samme dag som lånet er godkjent.",
            },
            {
                id: 9,
                title: " Kan jeg betale tilbake et Grønt Lån tidligere uten ekstra kostnader?",
                description: "Absolutt, du kan tilbakebetale lånet før tiden uten ekstra kostnader, akkurat som med våre andre låneprodukter.",
            },
            {
                id: 10,
                title: " Hvordan bidrar et Grønt Lån fra EasyFinance til den grønne omstillingen?",
                description: "Ved å velge et Grønt Lån støtter du direkte prosjekter som har en positiv miljøeffekt, noe som er et viktig skritt mot en mer bærekraftig og grønnere fremtid for alle.",
            },

        ],
    },
];

export default faqData;
