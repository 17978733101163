import React from "react";
import classes from './index.module.scss';
import { Col, Container, Row } from "react-bootstrap";
import Button from "../Button";
import { onError } from "Services/api/Image";
import draftToHtml from "draftjs-to-html";

const StaticContentWithImg = ({ detail, bgColor }) => {
    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };

    return (
        <React.Fragment>
            {detail.map((item) => (
                <section key={item.id} className={`section ${classes.section}`} style={{ backgroundColor: item.bgColor }}>
                    <Container>
                        <Row className={`${classes.colList} ${item?.horizontal ? classes.alignRightImage : ''} align-items-center`}>
                            {item?.featuredImage ?
                                <Col md={5}>
                                    <div className={classes.imgBox}>
                                        <img src={item?.featuredImage} loading="lazy" onError={onError} alt={"Section Image"} />
                                    </div>
                                </Col> : ''}
                            <Col md={item?.featuredImage ? 7 : 12}>
                                <h3>
                                    {item?.heading?.title?.map(title => (
                                        <span key={title.id} className={title.class}>{title.title} </span>
                                    ))}
                                </h3>
                                <div className={classes.text}>
                                    {item.content &&  <p>{item.content}</p>}
                                    <ul>
                                        {item.dataList && item.dataList.map((data, index) => ( // Check if dataList exists
                                            <li key={index}> {/* Use a unique key */}
                                                <h6>{data.title}</h6>
                                                <p>{data.description}</p>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {item?.btnText && // Check if redirectUrl exists
                                    <Button
                                        variant={'border'}
                                        text={item.btnText} // Change to desired text
                                        icon={'far fa-arrow-right'}
                                        url={item.redirectUrl}
                                    />
                                }
                            </Col>
                        </Row>
                    </Container>
                </section>
            ))}
        </React.Fragment>
    )
}

export default StaticContentWithImg;
