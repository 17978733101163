import React from "react";
import { Modal } from "react-bootstrap";
import classes from "./index.module.scss";
import DataShow from "./dataComponent";

const DetailPopUp = (props) => {
    const { dataType } = props;

    return (
        <React.Fragment>
            <Modal size="lg" centered {...props} className={classes.detailPopup}>
                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body>
                    <DataShow dataTypeFilter={dataType} />
                </Modal.Body>
                {/*<Modal.Footer>*/}
                {/*</Modal.Footer>*/}
            </Modal>
        </React.Fragment>
    );
};

export default DetailPopUp;