import React from 'react';
import {useLocation} from "react-router-dom";
import BusinesStaticPageBanner from "../../../Components/Banner/BusinesStaticPageBanner";
import contentWithImgData from "./ContentWithImgData";
import StaticContentWithImg from "../../../Components/ContentWithImage/StaticContentWithImg";
import bannerData from "./bannerData";
import CallToAction from "../../../Components/CallToAction";
import callToActionData from "./callToActionData";
import StaticTagList from "../../../Components/TagList/StaticTagList";
import tagList from "../tagList";

const WhoGetBusinessLoan = () => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = decodedPathname === '/bedrift/hvem-kan-få-bedriftslån';

    return(
        <React.Fragment>
            <BusinesStaticPageBanner data={bannerData} isBusiness={isBusiness} vippsBtnHide />
            <StaticContentWithImg detail={contentWithImgData} />
            <StaticTagList data={tagList} />
            <CallToAction data={callToActionData} />
        </React.Fragment>
    )
}

export default WhoGetBusinessLoan;