import React, {useState} from 'react';
import {Alert, Col, Container, Form, FormGroup, InputGroup, Row,} from 'react-bootstrap';
import FormInput from '../../FormInput';
import Button from '../../Button';
import classes from '../index.module.scss';
import RangeSlider from "../../RangeSlider";
import { onError } from 'Services/api/Image';
import { useEffect } from 'react';
import { getComponentVersionByListOfIds } from 'Services/api/LoanFilter';
import Loader from "../../Loader";
import Loading from "../../Loading";



const LoanWithoutSecurityFilter = ({ detail, hideRadio, values, setLoanObject, loanObject }) => {
    const [versions, setVersions] = useState([]);
    const [selectedLoanType, setSelectedLoanType] = useState();
    const [selectedVersion, setSelectedVersion] = useState({});
    const languageId = 1;

    const [loanAmount, setLoanAmount] = useState(values?.loanAmount);
    const [repaymentPeriod, setRepaymentPeriod] = useState(values?.repaymentPeriod);
    const [currentLoanAmount, setCurrentLoanAmount] = useState(values?.currentLoanAmount);
    const [selectedAnswer, setSelectedAnswer] = useState();

    const handleLoanTypeChange = ({target}) => setSelectedLoanType(target.value);

    useEffect(() => {
        if(!detail) return;
        const ids = detail?.map(d => d.tabVersion);
        getComponentVersionByListOfIds(languageId, ids)
        .then(resp => {
            setVersions(resp);
            setSelectedLoanType(resp[0]?.loanCategory);
            const first = resp[0]?.state;
            setLoanAmount(loanAmount ?? first?.defaultLoanAmount);
            setRepaymentPeriod(repaymentPeriod ?? first?.defaultRepaymentPeriod);
            setCurrentLoanAmount(currentLoanAmount ?? first?.currentLoanDefaultAmount);
        });
    }, [])

    useEffect(() => {

        const nowSelected = versions.find(version => version.loanCategory == selectedLoanType);
        const first = nowSelected?.state;
        setLoanAmount(values?.loanAmount ?? first?.defaultLoanAmount);
        setRepaymentPeriod(values?.repaymentPeriod ?? first?.defaultRepaymentPeriod);
        setCurrentLoanAmount(values?.currentLoanAmount ?? first?.currentLoanDefaultAmount);
        setSelectedVersion(nowSelected);
    }, [selectedLoanType])

  return(
      <React.Fragment>
            {!hideRadio && <Row>
                <Col md={12}>
                    <InputGroup className={'p-0'}>
                        {versions.map((version, index) => <FormInput
                            key={index}
                            type={'radio'}
                            label={version?.state?.tabTilte}
                            variant={'radio'}
                            id={`${version?.loanCategory}_${version?.loanType}_radio`}
                            name={`${version?.loanType}_radio`}
                            value={version?.loanCategory}
                            checked={selectedLoanType == version?.loanCategory}
                            onChange={handleLoanTypeChange}
                        />)}
                    </InputGroup>
                </Col>
            </Row>}
            {[7, 13].includes(selectedVersion?.loanCategory) && selectedVersion?.state ? <React.Fragment>
                <div className={classes.contentHolder}>
                    <div className={classes.imgBox}>
                        <img src={selectedVersion?.state?.tabImage} loading="lazy" onError={onError} alt={'#'} />
                    </div>
                    <div className={classes.contentBox}>
                        <div className={classes.inputRow}>
                            <Col>
                              <FormGroup className={'mb-4'}>
                                  <RangeSlider
                                      stepValue={'5000'}
                                      valueMin={selectedVersion?.state?.minLoanAmount}
                                      valueMax={selectedVersion?.state?.maxLoanAmount}
                                      // defaultValue={selectedVersion?.state?.defaultLoanAmount ?? ''}
                                      type={'input'}
                                      value={loanAmount}
                                      onChange={setLoanAmount}
                                      label={selectedVersion?.state?.loanAmountLabel}
                                      unit={selectedVersion?.state?.currencyLabel}
                                  />
                                 </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className={'mb-4'}>
                                <RangeSlider
                                    stepValue={'1'}
                                    valueMin={selectedVersion?.state?.minRepaymentPeriod}
                                    valueMax={selectedVersion?.state?.maxRepaymentPeriod}
                                    // defaultValue={selectedVersion?.state?.defaultRepaymentPeriod ?? ''}
                                    value={repaymentPeriod}
                                    onChange={setRepaymentPeriod}
                                    type={'input'}
                                    label={selectedVersion?.state?.repaymentPeriodLabel}
                                    unit={selectedVersion?.state?.yearsLabel}
                                />
                                </FormGroup>
                            </Col>
                        </div>
                        { selectedVersion?.loanCategory !== 13 && Number(repaymentPeriod) > 5 && <Row>
                            <Col md={6}>
                                <Alert variant={selectedVersion?.state?.alertType}>
                                    {selectedVersion?.state?.alertText}
                                </Alert>
                            </Col>
                            <Col md={6}>
                                <div className={'text-center'}>{selectedVersion?.state?.questionText}</div>
                                <div className={'d-flex align-items-center justify-content-center'}>
                                    <FormInput
                                        type={'radio'}
                                        label={selectedVersion?.state?.questionYesLabel}
                                        onChange={e => setSelectedAnswer(e.target.checked ? 'ja' : 'nei')}
                                        variant={'switch'}
                                        id={'ja'}
                                        name={'used'}
                                        value={'ja'}
                                    />
                                    <FormInput
                                        type={'radio'}
                                        onChange={e => setSelectedAnswer(e.target.checked ? 'nei' : 'ja')}
                                        label={selectedVersion?.state?.questionNoLabel}
                                        variant={'switch'}
                                        id={'nei'}
                                        name={'used'}
                                        value={'nei'}
                                    />
                                </div>

                            </Col>
                        </Row>}
                        <div className={classes.btnRow}>
                            <Button
                                text={selectedVersion?.state?.buttonLabel}
                                icon={'fas fa-arrow-right'}
                                disabled={selectedVersion?.loanCategory !== 13 && repaymentPeriod > 5 && (selectedAnswer !== 'ja' && selectedAnswer !== 'nei')}
                                {...(loanObject && setLoanObject ? {
                                    onClick: () => {
                                        setLoanObject({ ...loanObject, loanAmount, repaymentPeriod: repaymentPeriod * 12 })
                                    }
                                } : {
                                    url: `${selectedAnswer === 'ja' ? selectedVersion?.state?.questionYesUrl : selectedVersion?.state?.buttonUrl}?loanAmount=${loanAmount}&repaymentPeriod=${repaymentPeriod}`
                                })}
                                
                            />
                            <div className={classes.loanExample}>{selectedVersion?.state?.loanExample}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment> : null}
            {[8, 9, 10, 11, 12].includes(selectedVersion?.loanCategory) && selectedVersion?.state ? <React.Fragment>
                <div className={classes.contentHolder}>
                    <div className={classes.imgBox}>
                        <img src={selectedVersion?.state?.tabImage} loading="lazy" onError={onError} alt={'#'} />
                    </div>
                    <div className={classes.contentBox}>
                        <div className={classes.inputRow}>
                            <Col>
                                <FormGroup className={'mb-4'}>
                                <RangeSlider
                                    stepValue={'5000'}
                                    valueMin={selectedVersion?.state?.currentLoanMinAmount}
                                    valueMax={selectedVersion?.state?.currentLoanMaxAmount}
                                    value={currentLoanAmount}
                                    onChange={setCurrentLoanAmount}
                                    type={'input'}
                                    label={selectedVersion?.state?.currentLoanAmountLabel}
                                    unit={selectedVersion?.state?.currencyLabel}
                                />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className={'mb-4'}>
                                <RangeSlider
                                    stepValue={'5000'}
                                    valueMin={selectedVersion?.state?.minLoanAmount}
                                    // valueMax={selectedVersion?.state?.maxLoanAmount}
                                    valueMax={currentLoanAmount}
                                    value={loanAmount}
                                    onChange={setLoanAmount}
                                    type={'input'}
                                    label={selectedVersion?.state?.loanAmountLabel}
                                    unit={selectedVersion?.state?.currencyLabel}
                                />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup className={'mb-4'}>
                                <RangeSlider
                                    stepValue={'1'}
                                    valueMin={selectedVersion?.state?.minRepaymentPeriod}
                                    valueMax={selectedVersion?.state?.maxRepaymentPeriod}
                                    onChange={setRepaymentPeriod}
                                    value={repaymentPeriod}
                                    type={'input'}
                                    label={selectedVersion?.state?.repaymentPeriodLabel}
                                    unit={selectedVersion?.state?.yearsLabel}
                                />
                                </FormGroup>
                            </Col>
                        </div>
                        <div className={classes.btnRow}>
                            <Button
                                text={selectedVersion?.state?.buttonLabel}
                                icon={'fas fa-arrow-right'}
                                {...(loanObject && setLoanObject ? {
                                    onClick: () => {
                                        setLoanObject({ ...loanObject, loanAmount, repaymentPeriod: repaymentPeriod * 12 })
                                    }
                                } : {
                                    url: `${selectedVersion?.state?.buttonUrl}?loanAmount=${loanAmount}&repaymentPeriod=${repaymentPeriod}&currentLoanAmount=${currentLoanAmount}`
                                })}
                            />
                            <div className={classes.loanExample}>{selectedVersion?.state?.loanExample}</div>
                        </div>
                    </div>
                </div>
            </React.Fragment> : null}
            {selectedVersion?.state ? null : <Loading /> }
      </React.Fragment>
  )
}

export default LoanWithoutSecurityFilter;