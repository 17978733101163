import React, {useEffect, useState} from 'react';
import {Alert, Col, Form, InputGroup, Row,} from 'react-bootstrap';
import FormInput from '../../FormInput';
import Button from '../../Button';
import classes from '../index.module.scss';
import RangeSlider from "../../RangeSlider";
import conusmerLoanImg from '../../../Images/consumerLoanImg.svg';
import { onError } from 'Services/api/Image';
import { getComponentVersionByListOfIds } from 'Services/api/LoanFilter';


const Savings = ({ detail, hideRadio, values, loanObject, setLoanObject }) => {
    const [versions, setVersions] = useState([]);
    const [selectedLoanType, setSelectedLoanType] = useState();
    const [selectedVersion, setSelectedVersion] = useState({});
    const languageId = 1;

    const [age, setAge] = useState(values?.age);
    const [totalSavings, setTotalSavings] = useState(values?.totalSavings);


    const handleLoanTypeChange = ({target}) => setSelectedLoanType(target.value);

    useEffect(() => {
        if(!detail) return;
        const ids = detail?.map(d => d.tabVersion);
        getComponentVersionByListOfIds(languageId, ids)
        .then(resp => {
            setVersions(resp);
            setSelectedLoanType(resp[0]?.loanCategory);
            const first = resp[0];
            setAge(age ?? first?.state?.ageDefaultValue);
            setTotalSavings(totalSavings ?? first?.state?.savingsDefaultValue);
        });
    }, [])

    useEffect(() => {
        const nowSelected = versions.find(version => version.loanCategory == selectedLoanType);
        setSelectedVersion(nowSelected);
    }, [selectedLoanType])


    return(
        <React.Fragment>
            {!hideRadio && <Row>
                <Col md={12}>
                    <InputGroup className={'p-0'}>
                        {versions.map((version, index) => <FormInput
                            key={index}
                            type={'radio'}
                            label={version?.state?.tabTilte}
                            variant={'radio'}
                            id={`${version?.loanCategory}_${version?.loanType}_radio`}
                            name={`${version?.loanType}_radio`}
                            value={version?.loanCategory}
                            checked={selectedLoanType == version?.loanCategory}
                            onChange={handleLoanTypeChange}
                        />)}
                    </InputGroup>
                </Col>
            </Row>}
                <React.Fragment>
                    <div className={classes.contentHolder}>
                        <div className={classes.imgBox}>
                            <img src={selectedVersion?.state?.tabImage} loading="lazy" onError={onError} alt={'#'} />
                        </div>
                        <div className={classes.contentBox}>
                            <div className={classes.inputRow}>
                                <Col>
                                    <FormInput
                                        type={'text'}
                                        formatValue
                                        fieldLabel={selectedVersion?.state?.totalSavingsLabel}
                                        icon={selectedVersion?.state?.currencyLabel}
                                        value={totalSavings}
                                        onChange={e => setTotalSavings(e.target.value)}
                                        valueMax={99999999}
                                        textlg
                                    />
                                </Col>
                                <Col>
                                    <FormInput
                                        type={'text'}
                                        formatValue
                                        numericOnly
                                        fieldLabel={selectedVersion?.state?.ageLabel}
                                        icon={selectedVersion?.state?.yearsLabel}
                                        value={age}
                                        valueMax={selectedLoanType == 16 ? 120 : 100}
                                        onChange={e => setAge(e.target.value)}
                                        textlg
                                    />
                                </Col>

                            </div>
                            {Number(totalSavings) > 300000 && Number(age) < 34 && <Row>
                                <Col md={6}>
                                    <Alert variant={selectedVersion?.state?.alertType}>
                                        {selectedVersion?.state?.alertText}
                                    </Alert>
                                </Col>
                            </Row>}
                            <div className={classes.btnRow}>
                                <Button
                                    disabled={age==0}
                                    text={selectedVersion?.state?.buttonLabel}
                                    icon={'fas fa-arrow-right'}
                                    {...(loanObject && setLoanObject ? {
                                        onClick: () => {
                                            setLoanObject({ ...loanObject, 
                                                totalSaved: selectedVersion?.loanCategory == 15 ? totalSavings : undefined,
                                                totalOutstanding: selectedVersion?.loanCategory == 16 ? totalSavings : undefined,
                                                age
                                            })
                                        }
                                    } : {
                                        url: `${selectedVersion?.state?.buttonUrl}?${selectedVersion?.loanCategory === 15 ? 'totalSaved' : 'totalOutstanding'}=${totalSavings}&age=${age}`
                                    })}
                                />
                                <div className={classes.loanExample}>{selectedVersion?.state?.loanExample}</div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
        </React.Fragment>
    )
}

export default Savings;