import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { confirmEmail } from "../../Services/api/Auth";
import { Spinner } from "react-bootstrap";
import Welcome from "./Welcome";

function EmailConfirm(){
    const [loading, setLoading] = useState();
    const [params] = useSearchParams();
    useEffect(() => {

        const userId = params.get('userId');
        const code = params.get('code');
        if(!userId || !code){
            window.location.href = '/login';
            return;
        }
        setLoading(true);
        confirmEmail(userId, code)
        .then(resp => {
        })
        .catch(() => {})
        .finally(() => setLoading(false));
    }, []);
    return loading ? <Spinner color="success"></Spinner> : <Welcome message={'Gratulerer! Du er nå en offisiell deltaker i vårt fellesskap.'} />
}

export default EmailConfirm;