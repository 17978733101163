import img1 from '../../Images/rent.svg';
import img2 from '../../Images/budget.svg';
import img3 from '../../Images/loanCalculator.svg';
import img4 from '../../Images/dictonry.svg';
import img5 from '../../Images/contract.svg';
import img6 from '../../Images/insurance.svg';
import img7 from '../../Images/frinendLoan.svg';
import img8 from '../../Images/help.svg';

const data = [
    {
        id: '1',
        title: 'Rent',
        image: img1,
    },
    {
        id: '2',
        title: 'Budget',
        image: img2,
    },
    {
        id: '3',
        title: 'Loan calculator',
        image: img3,
    },
    {
        id: '4',
        title: 'Dictionary',
        image: img4,
    },
    {
        id: '5',
        title: 'Cohabitation contract',
        image: img5,
    },
    {
        id: '6',
        title: 'Insurances',
        image: img6,
    },
    {
        id: '7',
        title: 'Friend loan',
        image: img7,
    },
    {
        id: '8',
        title: 'Do you need help',
        image: img8,
    },
]

export default data;