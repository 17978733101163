import React from 'react';
import classes from "./index.module.scss";
import SectionTitle from "../../../../Components/SectionTitle";
import Button from "../../../../Components/Button";

const QuestionnaireRecommendation = () => {
  return(
      <React.Fragment>
          <div className={`${classes.box} mb-4 text-center py-5`}>
              <SectionTitle className={'pt-3 pb-3'}>
                  <span className={'fw-bold'}>Få orden på økonomien med refinansiering!</span>
              </SectionTitle>
              <Button
                  text={'Sjekk dine muligheter'}
                  url={"/refinancing-recommendation"}
                  variant={"border radius-medium"}
              />
          </div>
      </React.Fragment>
  )
}

export default QuestionnaireRecommendation