import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const CompanyEngagement = ({ company }) => {
    const styles = StyleSheet.create({
        section1: {
            marginBottom: 15,
            fontSize: 8,
        },
        contentList: {
            flexDirection: 'column',
            width: '100%',
            color: '#013367',
            marginBottom: 5,
            fontSize: 8,
        },
        list: {
            flexDirection: 'row',
            width: '100%',
            borderLeft: '3px solid #DEE2E5',
            marginBottom: '1px',
            backgroundColor: 'rgba(249,249,249,0.50)',
        },
        col: {
            padding: '5px 10px 2px',
        },
        bgGray: {
            backgroundColor: '#F8F8F7',
            color: '#4B4B4B',
            width: '100px',
        },
    });

    return (
        <View style={styles.section1}>
            <Text>{company.companyName}</Text>
            <View style={styles.contentList}>
                <View style={styles.list}>
                    <Text style={[styles.col, styles.bgGray]}>Rolle</Text>
                    <Text style={styles.col}>{company.function}</Text>
                </View>
                <View style={styles.list}>
                    <Text style={[styles.col, styles.bgGray]}>Kredittscore</Text>
                    <Text style={styles.col}>
                        Merk! Få fullstendig oversikt over selskapet ditt. Sjekk firma score, regnskap, roller og eiere i selskapet ditt.
                    </Text>
                </View>
            </View>
        </View>
    );
};

export default CompanyEngagement;
