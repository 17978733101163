import React from 'react';
import classes from "./index.module.scss";
import draftToHtml from 'draftjs-to-html';
import { onError } from 'Services/api/Image';

const TwoColTextWithLeftRightImg = ({ detail }) => {
    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };
  return(
      <React.Fragment>
          <div className={`${classes.colPost}`}>
              {detail.map((item, index) => (
                  <div className={classes.col} key={index}>
                      <div className={classes.box}>
                          <div className={classes.imgBox} style={{backgroundImage: `url(${item.image})`}}>
                              {/*<img src={item.image} onError={onError} loading="lazy" alt={item.title} />*/}
                          </div>
                      </div>
                      <div className={classes.box}>
                          <div className={classes.description}>
                              <h4 className={classes.title}>{item.title}</h4>
                              <div className={classes.editorText}>
                                  <p dangerouslySetInnerHTML={{__html: renderFilteredHTML(item.content)}}/>

                              </div>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </React.Fragment>
  )
}

export default TwoColTextWithLeftRightImg;