const data = [
    {
        title : "Email us",
        description : "Send us an email, we will respond as soon as possible.",
        icon : 'fal fa-envelope',
    },
    {
        title : "Postal address",
        description : (
            <>
                EasyFinance AS<br />
                Strømsveien 323 a, Oslo, Norway, 1081, NO
            </>
        ),
        icon : 'far fa-map-marker-alt',
    },
    {
        title : "EasyFinance AS",
        description : (
            <>
                org.nr 819 823 952,<br /> EasyFinance AS is a part of the Urooj Group AS,<br />
                org.nr 819 823 952
            </>
        ),
        icon : 'fal fa-building',
    },
];

export default data;
