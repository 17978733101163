import React from 'react';
import classes from './index.module.scss';
import {Link} from "react-router-dom";
import { onError } from 'Services/api/Image';


const BannerImg = ({ detail }) => {
    return (
        <React.Fragment>
            <div className={classes.bannerImg}>
                <h1>{detail?.heading}</h1>
                <p>{detail?.description}</p>
                <a href={detail?.redirectUrl} target={'_blank'}>
                    <img src={detail?.imageUrl} loading="lazy" onError={onError} alt={detail?.altText} />
                </a>
            </div>
        </React.Fragment>
    );
};

export default BannerImg;