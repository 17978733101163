import _ from "lodash"
import { BANKS_LOADED } from "../actions/banks";

const initialState = {
    banks: []
}

export default function banksReducer(state = initialState, action) {
    const newState = _.cloneDeep(state);
    switch(action.type) {
        case BANKS_LOADED:
            newState.banks = action.payload;
            return newState;
        default:
            return newState;
    }
}