import React from 'react';
import { Document, StyleSheet } from '@react-pdf/renderer';
import FrontPage from "../../PDFComponent/FrontPage";
import HouseValueList from "./HouseValueList";

const ScoreDocument = ({ userData, houseData, totalProperties }) => {

    const styles = StyleSheet.create({

    });

    return (
        <Document style={styles.document}>
            <FrontPage userData={userData} />
            <HouseValueList userData={userData} houseData={houseData} totalProperties={totalProperties} />
        </Document>
    );
}

export default ScoreDocument;
