const TagList = [
    {
        heading: {
            description: "Her er noen eksempler på hvordan bedriftslån kan brukes, samt relatert informasjon om produktet.",
            title: [
                { id: 1, title: "Relaterte ", class: "fw-normal" },
                { id: 2, title: "emner", class: "fw-bold" },
            ]
        },
        tag: [
            {
                title: "Finansieringsguiden",
                url : "/bedrift/finansieringsguiden",
            },
            {
                title: "Grønt lån",
                url : "/bedrift/grøntlån",
            },
            {
                title: "Kredittsjekk selskapet ditt",
                url : "/bedrift/kredittsjekk",
            },
            {
                title: "Start lånesøknaden",
                url : "/bedrift/lån-fra",
            },
            {
                title: "Bedriftslån for små og mellomstore bedrifter",
                url : "/bedrift/lån-fra",
            },
            {
                title: "Hvem kan få bedriftslån?",
                url : "/bedrift/hvem-kan-få-bedriftslån",
            },
            {
                title: "Fikk du ikke bedriftslån?",
                url : "/bedrift/fikk-du-ikke-bedriftslån",
            },
            {
                title: "Bedriftslån enkeltmannsforetak",
                url : "/bedrift/bedriftslån-enkeltmannsforetak",
            },
            {
                title: "Søk om bedriftslån",
                url : "/bedrift/lån-fra",
            },
            {
                title: "Oppstartslån til bedrift",
                url : "/bedrift/lån-fra",
            },
        ]
    },

];

export default TagList;