import React from 'react';
import { Modal} from "react-bootstrap";
import FormInput from "../../../../../Components/FormInput";
import Button from "../../../../../Components/Button";

const SetPassword = (props) => {
  return(
      <React.Fragment>
          <Modal
              {...props}
              size="md"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title className={'my-2'}>
                      Set password
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <FormInput
                      type={'password'}
                      fieldLabel={'Old assword'}
                      placeholder={'********'}
                  />
                  <FormInput
                      type={'password'}
                      fieldLabel={'New password'}
                      placeholder={'********'}
                  />
                  <FormInput
                      type={'password'}
                      fieldLabel={'Re-type new password'}
                      placeholder={'********'}
                  />
                  <Button
                      text={'save'}
                      variant={' w-100'}
                      onClick={props.onHide}
                  />
              </Modal.Body>
          </Modal>
      </React.Fragment>
  )
}

export default SetPassword;