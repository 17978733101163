import React from 'react';
import {Col, Row} from "react-bootstrap";
import classes from "./index.module.scss";
import moment from 'moment';



const Cards = ({ internationalScore, creditLimit, hasPaymentRemarks, status, financialStrength, soleProperty, solePropertyData }) => {
    const formatter = Intl.NumberFormat('no-NO');
    const format = (value, divideBy100 = true) => {
        if(isNaN(value)) return 0;
        if(divideBy100){
            return formatter.format(value / 100);
        }
        else{
            return formatter.format(value);
        }
    }
    return (
        <React.Fragment>
            <Row className={`${classes.dashboardCards} ${classes.col_2} mb-3`}>
                {soleProperty ? <>
                    <Col className={classes.col}>
                        <div className={classes.box}>
                            <div className={classes.description}>
                                <h3 className={'font-30 mb-2'}>{(solePropertyData?.basicInformation?.registrationDate && solePropertyData?.basicInformation?.registrationDate !== '' ? moment(solePropertyData?.basicInformation?.registrationDate).format('DD-MM-YYYY') : '-')}</h3>
                                <div className={'font-13'}>Registration date</div>
                            </div>
                        </div>
                    </Col>
                    <Col className={classes.col}>
                        <div className={classes.box}>
                            <div className={classes.description}>
                                <h3 className={'font-30 mb-2'}>{solePropertyData?.basicInformation?.legalForm && solePropertyData?.basicInformation?.legalForm !== '' ? solePropertyData?.basicInformation?.legalForm : '-'}</h3>
                                <div className={'font-13'}>Legal form</div>
                            </div>
                        </div>
                    </Col>
                </> : <>
                    <Col className={classes.col}>
                        <div className={classes.box}>
                            <div className={classes.description}>
                                <h3 className={'font-30 mb-2'}>{internationalScore ?? '-'}</h3>
                                <div className={'font-13'}>Internasjonal Score</div>
                            </div>
                        </div>
                    </Col>
                    <Col className={classes.col}>
                        <div className={classes.box}>
                            <div className={classes.description}>
                                <h3 className={'font-30 mb-2'}>{ creditLimit && creditLimit !== '' ? format(creditLimit, false) : '-' }</h3>
                                <div className={'font-13'}>Kredittlimit</div>
                            </div>
                        </div>
                    </Col>
                </>}

                <Col className={classes.col}>
                    <div className={classes.box}>
                        <div className={classes.description}>
                            <h3 className={'font-30 mb-2'}>{ hasPaymentRemarks ? 'Ja' : 'Nei' }</h3>
                            <div className={'font-13'}>Betalingsanmerkninger</div>
                        </div>
                    </div>
                </Col>
                {soleProperty ? <>
                    <Col className={classes.col}>
                        <div className={classes.box}>
                            <div className={classes.description}>
                                <h3 className={'font-30 mb-2'}>{solePropertyData?.basicInformation?.noVoluntaryPledges}</h3>
                                <div className={'font-13'}>Voluntary pledges</div>
                            </div>
                        </div>
                    </Col>
                </> : <>
                    <Col className={classes.col}>
                        <div className={classes.box}>
                            <div className={classes.description}>
                                <h3 className={'font-30 mb-2'}>{ financialStrength ?? '-' }</h3>
                                <div className={'font-13'}>Finansiell Styrke</div>
                            </div>
                        </div>
                    </Col>
                </>}

                <Col className={classes.col}>
                    <div className={classes.box}>
                        <div className={classes.description}>
                            <h3 className={'font-30 mb-2 text-success'}>{status ? status?.replace('Active', 'Aktivt') : '-'}</h3>
                            <div className={'font-13'}>Status</div>
                        </div>
                    </div>
                </Col>
            </Row>


        </React.Fragment>
    );
};

export default Cards;
