import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import classes from '../index.module.scss';
import { Alert } from 'react-bootstrap';
import data from './data.json';
import Button from "../../Button";

HighchartsMore(Highcharts);

const CreditScore = ({ legend, score, small, id}) => {
    const { title, subtitle, subData } = data;
    const [creditScore, setCreditScore] = useState(0);
    //const chartId = id || `creditScore-${Math.random().toString(36).substr(2, 9)}`; // Use provided ID or generate a random one
    const [chartId] = useState(`creditScore-${Math.random().toString(36).substr(2, 9)}`); // Generate unique chart id


    useEffect(() => {
        setCreditScore(score?.currentCreditScore?.score);
        const totalVal = () => {
            const totalNumber = Math.floor(creditScore);
            return `
                <div class="textBox">
                    <div>
                        <span style="font-size: 40px">
                            <b> ${totalNumber}</b>
                        </span>/
                        <span style="font-size: 14px">100</span>
                    </div>
                    <h6>${title}</h6>
                </div>
            `;
        };

        Highcharts.setOptions({
            colors: subData?.map(item => item.color), // Set colors from subData
        });

        const options = {
            chart: {
                type: 'gauge',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: null,
                height: '100%',
                //renderTo: 'creditScore',
                renderTo: chartId,
            },
            pane: {
                startAngle: -110,
                endAngle: 110,
                background: null,
                center: ['50%', '40%'],
                size: '75%',
                dataLabels: {
                    enabled: true,
                },
            },
            title: {
                text: '',
                y: 0,
            },
            tooltip: {
                enabled: true,
            },
            yAxis: {
                min: 0,
                max: 100,
                tickPositions: [0, ...subData?.map(item => parseInt(item.to, 10))], // Include 0 as the starting point
                tickPixelInterval: null,
                tickPosition: 'inside',
                tickColor: Highcharts.defaultOptions.chart.backgroundColor || '#FFFFFF',
                tickLength: 12,
                tickWidth: 1,
                minorTickInterval: 5,
                minorTickWidth: 1,
                minorTickLength: 12,
                minorTickColor: '#fff',
                labels: {
                    distance: 10,
                    style: {
                        fontSize: '11px',
                        color:'#003341'
                    }
                },
                lineWidth: 0,
                plotBands: subData?.map(item => ({
                    from: parseInt(item.from, 10), // Ensure numeric values
                    to: parseInt(item.to, 10), // Ensure numeric values
                    color: item.color,
                    thickness: 12
                })),
            },
            series: [{
                //name: title,
                data: [Math.floor(score?.currentCreditScore?.score)],
                tooltip: {
                    valueSuffix: ' /100',
                },
                dial: {
                    radius: '80%',
                    backgroundColor: '#808080',
                    baseWidth: 10,
                    baseLength: '5%',
                    rearLength: '0%'
                },
                pivot: {
                    backgroundColor: '#808080',
                    radius: 6
                }
            }],
        };
        let chart = new Highcharts.Chart(options);

        // Clear resources on unmount
        return () => {
            if (chart) {
                clearTimeout(chart.sequenceTimer);
                chart.destroy();
            }
        };
    }, [subtitle, title, legend, score, chartId]);

    const DataTitle = () => {
        const percentage = Math.floor(creditScore);
      return(
          <React.Fragment>
              {small ?  <>
                  <div className={`${classes.dataTitle} text-center`} style={{marginTop: '-50px'}}>
                      <div><b style={{fontSize: '22px' }}>{percentage}</b>/<span style={{ fontSize: '12px' }}>100</span></div>
                  </div>
              </> :  <>
                  <div className={`${classes.dataTitle} text-center`} style={{marginTop: '-50px'}}>
                      <div><b style={{fontSize: '50px' }}>{percentage}</b>/<span style={{ fontSize: '14px' }}>100</span></div>
                      <div className={classes.title}>{title}</div>
                  </div>
              </>}


          </React.Fragment>
      )
    }

    return (
        <React.Fragment>
            <div className={classes.chartBox}>
                {small ? <>
                    <div id={chartId} className={`${classes.chartContainer} ${small ? classes.small : classes.creditScore}`}></div>
                    <DataTitle small />
                    <Button
                        text={"See your detail"}
                        url={'/privat/kredittsjekk'}
                        variant={'link '}
                    />
                    <div className={'font-11 text-light pt-3'}>This information is provided by Creditsafe.</div>
                </> : <>
                    <div id={chartId} className={`${classes.chartContainer} ${classes.creditScore}`}></div>
                    <DataTitle />
                    {/*<ul className={classes.legendList}>*/}
                    {/*    {subData.map((item, index) => (*/}
                    {/*        <li key={index} style={{ '--background': item.color }}>*/}
                    {/*            <span>{`${item.from}/${item.to}`}</span>*/}
                    {/*        </li>*/}
                    {/*    ))}*/}
                    {/*</ul>*/}
                    <Alert
                        // variant={'warning'}
                        className={classes.alert}
                        style={{
                            backgroundColor: subData?.find(
                                item => creditScore <= parseInt(item.to)
                            )?.color,
                            color: subData?.find(
                                item => creditScore <= parseInt(item.to)
                            )?.textColor
                        }}
                    >
                        {`${score?.currentCreditScore?.decision} - ${subData?.find(item => creditScore <= parseInt(item.to))?.description}`}
                    </Alert>
                </>}

            </div>
        </React.Fragment>
    );
};

export default CreditScore;
