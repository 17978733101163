import React, { Fragment } from 'react';
import InnerPageLayout from "./InnerPageLayout";
import {Image, StyleSheet, Text, View} from "@react-pdf/renderer";
import PDFTable from "./Tables";


const ScoreDescription = () => {
    const styles = StyleSheet.create({
        title: {color: '#013367', fontSize:13,marginBottom:5},

    });
    const table1 = ['Date', 'Company Score', 'Description'];
    const table1Data = [
        ['13/11/2023', 23, 'High risk'],
        ['09/08/2023', 55, 'Low risk'],
        ['01/12/2022', 51, 'Low risk'],
        ['04/11/2022', 40, 'Medium risk'],
        ['10/11/2021', 45, 'Medium risk']
    ];
    const table2 = [' ', 'Company Score', 'International score', 'Score description'];
    const table2Data = [
        ['', '71 to 100', 'A', 'Very low risk'],
        ['', '51 to 70', 'B', 'Low risk'],
        ['', '31 to 50', 'C', 'Medium risk'],
        ['', '16 to 30', 'D', 'High risk'],
        ['', '0 to 15', 'E', 'Very High risk'],
        ['', '0', 'F', 'Not scored'],
    ];

    const table3 = ['Name', 'Address', 'Country', 'Date of birth', 'Function', 'Start date', 'Company Engagements'];
    const table3Data = [['Al, Khhuram Shehzad','Martin Borrebekkens Vei 7 D 0584 OSLO','No','0','Chairman of the board','31/10/2017','6',],];
    const colWidths = ['20%', '30%', '10%', '10%', '10%', '10%', '10%'];


    return(
      <InnerPageLayout title={'0 3. Poenghistorie og forklaring av poengsum'}>
          <PDFTable headers={table1} data={table1Data} style={{marginBottom:30}} />
          <Text style={styles.title}>Legend</Text>
          <PDFTable headers={table2} data={table2Data} style={{marginBottom:30}} />
          <Text style={styles.title}>Current board</Text>
          <PDFTable headers={table3} data={table3Data} style={{marginBottom:30,}} columnWidths={colWidths} />
      </InnerPageLayout>
  )
}

export default ScoreDescription;