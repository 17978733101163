import kraftBank from "../../../../Images/kraftBank.svg";
import svea from "../../../../Images/svea.svg";

const data = [
    {
        name: "Kraft Bank",
        image: kraftBank,
        leverandorTekst: 'Kraft Bank vil hjelpe folk med økonomiske utfordringer på en god måte. Selv om du har problemer med økonomien, som rot og betalingsanmerkninger, har de løsninger som kan gjøre situasjonen din bedre. Når du er hos Kraft Bank, får du din egen rådgiver. Rådgiveren din vil ta seg tid til å forstå hvordan det ser ut for deg økonomisk, og sammen vil dere finne en løsning som passer for deg.',
        rating: '4.5',
        loanAmount:'100 000 kr - 20 000 000 kr',
        pricePerMonths: '19 153 kr',
        age: '18 år',
        estTotalCost: '6 895 080 kr',
        effectiveInterestRate: '8,80%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '8,80%',
        featured: false,
        loanExample: 'Nominell rente 8.0, Effektiv rente 8.80, lånebeløp 2 500 000 kr, nedbetalingstid 30 år, kostnad: 19 153 kr, totalt: 6 895 080 kr',
        btnUrl: "https://kraftbank.no/",
        affiliateType: "Annonse",

    },
    {
        name: "Svea",
        image: svea,
        rating: '4.5',
        loanAmount:'100 000 kr - 1 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '7.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '8,86%',
        featured: false,
        //affiliateType: "Annonse",
        loanExample: 'Nominell rente 8.1, Effektiv rente 8.86, lånebeløp 2 500 000 kr, nedbetalingstid 30 år, kostnad: 19 263 kr, totalt: 6 934 680 kr',
        btnUrl: "https://www.svea.com/nb-no/privat/l%C3%A5n",
        moreDetail: [
            {
                title: "Fordeler med lån med sikkerhet",
                moreInfo: ["Digital låneprosess – enkelt og greit", "Vår laveste rente", "Du kan beholde ditt eksisterende boliglån", "Egnet til både refinansiering og andre formål", "Tilbys også til deg som har betalingsanmerkninger", ]
            }
        ]
    },
]

export default data;