import React from 'react';
import classes from '../index.module.scss';
import {Col, Row, Table} from "react-bootstrap";
import CompanyScoreChart from "../../../../../Components/Charts/CompanyScoreChart";
import moment from 'moment';

const CompanyScore = ({ details }) => {
    return(
        <React.Fragment>
            <Row>
                <Col lg={6}>
                    <div className={'mb-3'}>
                        <Table className={'border responsive'}>
                            <thead>
                            <tr>
                                <th>Dato</th>
                                <th>Foretaksscore</th>
                                <th>Beskrivelse</th>
                            </tr>
                            </thead>
                            <tbody>
                                {!details?.report?.additionalInformation?.ratingHistory || details?.report?.additionalInformation?.ratingHistory?.length === 0 ? <td colSpan={3}>Ingen score for foretaket - Score er ikke tilgjengelig på grunn av bransjeform</td> : null}
                           {details?.report?.additionalInformation?.ratingHistory?.map((rating, index) => <tr key={index}>
                                <td data-label="Dato">{moment(rating?.date).format('DD/MM/YYYY')}</td>
                                <td data-label="Foretaksscore">{!isNaN(rating?.rating) ? Number(rating?.rating) < 0 ? 'Foretaksscore ikke vurdert' : rating?.rating : '-'}</td>
                                <td data-label="Beskrivelse">{rating?.description}</td>
                           </tr>)}
                            
                            </tbody>
                        </Table>
                    </div>
                    <div className={'mb-3'}>
                        <h5>Tegnforklaring</h5>
                        <Table className={'border responsive'}>
                            <thead>
                            <tr>
                                <th></th>
                                <th>Foretaksscore</th>
                                <th>Internasjonal Score</th>
                                <th>Beskrivelse</th>
                            </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td data-label="Color"><i className={"fas fa-circle fa-fw"} style={{color: "rgb(64, 154, 106)"}}></i> </td>
                                    <td data-label="Foretaksscore">71 to 100</td>
                                    <td data-label="Internasjonal Score" style={{color: "rgb(64, 154, 106)"}}>A</td>
                                    <td data-label="Beskrivelse">Veldig lav risiko</td>
                                </tr>
                                <tr>
                                    <td data-label="Color"><i className={"fas fa-circle fa-fw"} style={{color: "rgb(64, 154, 106)"}}></i> </td>
                                    <td data-label="Foretaksscore">51 to 70</td>
                                    <td data-label="Internasjonal Score" style={{color: "rgb(64, 154, 106)"}}>B</td>
                                    <td data-label="Beskrivelse">Lav risiko</td>
                                </tr>
                                <tr>
                                    <td data-label="Color"><i className={"fas fa-circle fa-fw"} style={{color: "rgb(109, 199, 151)"}}></i> </td>
                                    <td data-label="Foretaksscore">30 to 50</td>
                                    <td data-label="Internasjonal Score" style={{color: "rgb(109, 199, 151)"}}>C</td>
                                    <td data-label="Beskrivelse">Moderat risiko</td>
                                </tr>
                                <tr>
                                    <td data-label="Color"><i className={"fas fa-circle fa-fw"} style={{color: "rgb(247, 148, 29)"}}></i> </td>
                                    <td data-label="Foretaksscore">21 to 29</td>
                                    <td data-label="Internasjonal Score" style={{color: "rgb(247, 148, 29)"}}>D</td>
                                    <td data-label="Beskrivelse">Høy risiko</td>
                                </tr>
                                <tr>
                                    <td data-label="Color"><i className={"fas fa-circle fa-fw"} style={{color: "rgb(238, 46, 36)"}}></i> </td>
                                    <td data-label="Foretaksscore">1 to 20</td>
                                    <td data-label="Internasjonal Score" style={{color: "rgb(238, 46, 36)"}}>D</td>
                                    <td data-label="Beskrivelse">Veldig høy risiko</td>
                                </tr>
                                <tr>
                                    <td data-label="Color"><i className={"fas fa-circle fa-fw"} style={{color: "rgb(203, 203, 203)"}}></i> </td>
                                    <td data-label="Foretaksscore">Not Scored</td>
                                    <td data-label="Internasjonal Score" style={{color: "rgb(203, 203, 203)"}}>E</td>
                                    <td data-label="Beskrivelse">Score ikke beregnet</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </Col>
                <Col lg={6}>
                    <CompanyScoreChart details={details}/>
                </Col>
            </Row>
        </React.Fragment>
    )
}

export default CompanyScore;