import React, { useState } from 'react';
import classes from './index.module.scss';
import { Col, Row, Container, Form } from 'react-bootstrap';
import SectionTitle from '../../SectionTitle';
import StarRating from '../../StarRating';
import SelectDropDown from '../../SelectDropDown';
import Button from '../../Button';
import FormInput from '../../FormInput';
import Thankyou from '../../../Images/Thankyou.svg';
import { onError } from 'Services/api/Image';

const showReviews = [
    { label: 'General review', value: 'Generalreview' },
    { label: '4 Reviews', value: '4Reviews' },
    { label: '8 Reviews', value: '8Reviews' },
];

const PostReviews = () => {
    const [selectedOption, setSelectedOption] = useState(null); // For single select, use 'null'; for multi-select, use an empty array '[]'
    const [currentSection, setCurrentSection] = useState(1);

    const handleOptionChange = (newValue) => {
        // Do something with the selected option(s)
        setSelectedOption(newValue);
    };

    const handleSectionSubmit = () => {
        setCurrentSection(currentSection + 1);
    };

    const handleRatingSubmit = () => {
        setCurrentSection('thankYou');
    };

    return (
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <Row>
                        <Col md={6} className={'offset-md-3'}>
                            {currentSection === 1 && (
                                <React.Fragment>
                                    <SectionTitle textCenter>
                                        <span className={'fw-normal'}>Consider</span>
                                        <span className={'fw-semibold'}>Santander</span>
                                    </SectionTitle>
                                    <StarRating ratingBox rating={'5.0'} />
                                    <div className={'p-3 py-5 border mt-5'}>
                                        <div className={'position-relative'}>
                                            <SelectDropDown
                                                placeHolder="General review"
                                                options={showReviews}
                                                onChange={handleOptionChange}
                                            />
                                            <ul className={classes.ratingBar}>
                                                <li>
                                                    <div className={'text-light'}>Interest and Costs</div>
                                                    <StarRating  initialRating={'5.0'}  />
                                                </li>
                                                <li>
                                                    <div className={'text-light'}>Flexibility and Terms</div>
                                                    <StarRating initialRating={'5.0'}  />
                                                </li>
                                                <li>
                                                    <div className={'text-light'}>Website & Functionality</div>
                                                    <StarRating initialRating={'5.0'}  />
                                                </li>
                                                <li>
                                                    <div className={'text-light'}>Support & Customer Service</div>
                                                    <StarRating initialRating={'5.0'}  />
                                                </li>
                                            </ul>
                                            <div className={'pt-5'}>
                                                <Button
                                                    text={'Submit rating'}
                                                    variant={' w-100'}
                                                    onClick={handleSectionSubmit}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {currentSection === 2 && (
                                <React.Fragment>
                                    <SectionTitle textCenter>
                                        <span className={'fw-normal'}>Consider</span>
                                        <span className={'fw-semibold'}>Santander</span>
                                    </SectionTitle>
                                    <StarRating ratingBox rating={'5.0'} />
                                    <div className={'p-3 py-5 border mt-5'}>
                                        <div className={'position-relative'}>
                                            <div className={'text-center'}>
                                                <h4>Take a minute to write a review!</h4>
                                                <p>Share your experience and help others choose the right company.</p>
                                            </div>
                                            <Form>
                                                <FormInput type={'textarea'} as={'textarea'} placeholder={'Your assessment'} />
                                                <div className={'font-12 text-light'}>What do you think of this website?</div>
                                                <div className={'pt-5 text-center'}>
                                                    <Button
                                                        text={'Send review'}
                                                        variant={' w-100 mb-3'}
                                                        onClick={handleSectionSubmit}
                                                    />
                                                    <Button
                                                        text={'Not now, just send the rating'}
                                                        variant={'link'}
                                                        onClick={handleRatingSubmit}
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {currentSection === 3 && (
                                <React.Fragment>
                                    <SectionTitle textCenter>
                                        <span className={'fw-normal'}>Consider</span>
                                        <span className={'fw-semibold'}>Santander</span>
                                    </SectionTitle>
                                    <StarRating ratingBox rating={'5.0'} />
                                    <div className={'p-3 py-5 border mt-5'}>
                                        <div className={'position-relative'}>
                                            <div className={'text-center'}>
                                                <h4>Take a minute to write a review!</h4>
                                                <p>Share your experience and help others choose the right company.</p>
                                            </div>
                                            <Form>
                                                <FormInput type={'text'} placeholder={'Name'} />
                                                <FormInput type={'email'} placeholder={'Email'} />
                                                <div className={'pt-5 text-center'}>
                                                    <Button
                                                        text={'Send review'}
                                                        variant={' w-100 mb-3'}
                                                        onClick={handleSectionSubmit}
                                                    />
                                                    <Button
                                                        text={'Not now, just send the rating'}
                                                        variant={'link'}
                                                        onClick={handleRatingSubmit}
                                                    />
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}

                            {currentSection === 'thankYou' && (
                                <React.Fragment>
                                    <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Id quam fermentum nec viverra neque feugiat. Viverra velit lorem non sapien sed euismod blandit morbi sit. Enim nulla turpis fermentum'}>
                                        <span className={'fw-normal'}>Thank you</span>
                                        <span className={'fw-light'}>for your</span>
                                        <span className={'fw-semibold'}>review</span>
                                    </SectionTitle>

                                    <div className={'p-3'}>
                                        <div className={'position-relative text-center'}>
                                            <div className={'text-center mb-5'}>
                                                <img src={Thankyou} loading="lazy" onError={onError} alt={''} />
                                            </div>
                                            <Button
                                                text={'Back to Home'}
                                                url={'/'}
                                                variant={'darkBorder'}
                                            />
                                        </div>
                                    </div>
                                </React.Fragment>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default PostReviews;
