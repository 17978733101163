import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {Table} from "react-bootstrap";

const Assets = ({ details }) => {
    const formatter = Intl.NumberFormat('no-NO');
    const [dates, setDates] = useState([]);
    const [data, setData] = useState({});
    // const [fsData, setFsData] = useState({});

    const format = (value, divideBy1000 = true) => isNaN(value) ? 0 : formatter.format(value / (divideBy1000 ? 1000 : 1))
    useEffect(() => {
        setDates(details?.financialStatements?.map(fs => moment(fs?.yearEndDate).format('DD/MM/YYYY')));
        const transformedObject = details?.localFinancialStatements?.map(({balanceSheet}) => balanceSheet)?.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr[key]);
            });
            return acc;
        }, {});
        // const fsObject = details?.financialStatements?.map(({balanceSheet}) => balanceSheet)?.reduce((acc, curr) => {
        //     Object.keys(curr).forEach(key => {
        //         if (!acc[key]) {
        //             acc[key] = [];
        //         }
        //         acc[key].push(curr[key]);
        //     });
        //     return acc;
        // }, {});
        setData(transformedObject)
        // setFsData(fsObject)
    }, [details]);
    
    const getPercentage = (number, array, index, attachSymbol = false) => {
        if (index < 0 || index >= array.length - 1) {
            return null; // Index out of range or no next index
        }
    
        const currentValue = Math.abs(array[index]);
        const nextValue = Math.abs(array[index + 1]);
    
        let percentageValue = ((currentValue - nextValue) / nextValue) * 100;
        // const percentageValue = ((array[index - 1] - number) / number) * 100;
        if(isNaN(percentageValue) || percentageValue === 0 || percentageValue === Infinity || percentageValue === -Infinity)
        return '-'
        percentageValue = percentageValue.toFixed(1);
        if(Math.abs(percentageValue) > 1000) percentageValue = (percentageValue / 1000).toFixed(1) + (attachSymbol ? 'K' : 0)
        return attachSymbol ? percentageValue + ' %' : percentageValue;
    }
    const getRow = (arr) => {
        if(!arr)
            return <>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </>
        return arr?.map((number, index) => {
            return <React.Fragment key={index}>
                <td key={`${index}-number`} colSpan={1}>
                    {format(number) ?? '-'}
                </td>
                {index < arr?.length - 1 && (
                    <td key={`${index}-percentage`} colSpan={1}>
                        <span className={isNaN(getPercentage(number, arr, index)) ? '' : getPercentage(number, arr, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, arr, index, true)}<i className={isNaN(getPercentage(number, arr, index)) ? '' : getPercentage(number, arr, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                    </td>
                )}
            </React.Fragment>
        })
    }
    return(
        <React.Fragment>
            <h5>Egenkapital og Gjeld</h5>
            <div className={'table-responsive'}>
                <Table className={'border'} style={{minWidth: "850px"}}>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        {dates?.map((date, index) => (
                            <React.Fragment key={index}>
                                <th colSpan={1}>{date}</th>
                                {index !== dates?.length - 1 && <th colSpan={1}></th>}
                            </React.Fragment>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th data-label="Name">Aksjekapital/Selskapskapital</th>
                        {getRow(data?.shareCapital)}
                    </tr>
                    <tr>
                        <th data-label="Name">Egne aksjer</th>
                        {getRow(data?.treasuryStock)}
                    </tr>
                    <tr>
                        <th data-label="Name">Overkursfond</th>
                        {getRow(data?.sharePremium)}
                    </tr>
                    <tr>
                        <th data-label="Name">Annen innskutt egenkapital</th>
                        {getRow(data?.otherPaidInCapital)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum innskutt egenkapital</th>
                        {getRow(data?.totalEquityDeposits)}
                    </tr>
                    <tr>
                        <th data-label="Name">Fond for vurderingsforskjeller</th>
                        {getRow(data?.valuationReserves)}
                    </tr>
                    <tr>
                        <th data-label="Name">Annen egenkapital</th>
                        {getRow(data?.otherEquity)}
                    </tr>
                    <tr>
                        <th data-label="Name">Minoritetsinteresser</th>
                        {getRow(data?.minorityInterest)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum opptjent egenkapital</th>
                        {getRow(data?.totalRetainedEarnings)}
                    </tr>
                    <tr>
                        <th data-label="Name">Andre reserver</th>
                        {getRow(data?.otherReserves)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum egenkapital</th>
                        {getRow(data?.totalEquity)}
                    </tr>
                    <tr>
                        <th data-label="Name">Langsiktige pensjonsforpliktelser</th>
                        {getRow(data?.longTermPensionCommitments)}
                    </tr>
                    <tr>
                        <th data-label="Name">Utsatt skatteforpliktelse</th>
                        {getRow(data?.deferredTaxLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Annen avsetning</th>
                        {getRow(data?.otherProvisions)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum avsetninger for gjeld og kostnader</th>
                        {getRow(data?.totalProvisionsForLiabilitiesAndCharges)}
                    </tr>
                    <tr>
                        <th data-label="Name">Konvertible lån</th>
                        {getRow(data?.convertibleLoans)}
                    </tr>
                    <tr>
                        <th data-label="Name">Andre langsiktige lån</th>
                        {getRow(data?.otherLongTermLoans)}
                    </tr>
                    <tr>
                        <th data-label="Name">Langsiktig gjeld til finansinstitusjoner</th>
                        {getRow(data?.longTermLiabilitiesToFinancialInstitutions)}
                    </tr>
                    <tr>
                        <th data-label="Name">Langsiktige bidragsforpliktelser til konsernet</th>
                        {getRow(data?.longTermGroupContributionLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Ansvarlig lånekapital</th>
                        {getRow(data?.liableLoanCapital)}
                    </tr>
                    <tr>
                        <th data-label="Name">Annen langsiktig gjeld</th>
                        {getRow(data?.otherLongTermLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum annen langsiktig gjeld</th>
                        {getRow(data?.totalOtherLongTermLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum langsiktig gjeld</th>
                        {getRow(data?.totalLongTermLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Konvertibelt lån</th>
                        {getRow(data?.convertibleLoans)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sertifikatlån</th>
                        {getRow(data?.certificateLoans)}
                    </tr>
                    <tr>
                        <th data-label="Name">Kortsiktig gjeld til finansinstitusjoner</th>
                        {getRow(data?.shortTermLiabilitiesToFinancialInstitutions)}
                    </tr>
                    <tr>
                        <th data-label="Name">Leverandørgjeld</th>
                        {getRow(data?.tradePayables)}
                    </tr>
                    <tr>
                        <th data-label="Name">Skyldig skatt</th>
                        {getRow(data?.payableTax)}
                    </tr>
                    <tr>
                        <th data-label="Name">Spesielle offentlige avgifter</th>
                        {getRow(data?.specialGovernmentTaxes)}
                    </tr>
                    <tr>
                        <th data-label="Name">Foreslått utbytte</th>
                        {getRow(data?.suggestedDividends)}
                    </tr>
                    <tr>
                        <th data-label="Name">Kortsiktig gjeld til konsernet</th>
                        {getRow(data?.shortTermLiabilitiesToGroups)}
                    </tr>
                    <tr>
                        <th data-label="Name">Andre kreditorer</th>
                        {getRow(data?.otherCreditors)}
                    </tr>
                    <tr>
                        <th data-label="Name">Annen kortsiktig gjeld</th>
                        {getRow(data?.otherShortTermLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum kortsiktig gjeld</th>
                        {getRow(data?.totalCurrentLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum gjeld</th>
                        {getRow(data?.totalLiabilities)}
                    </tr>
                    <tr>
                        <th data-label="Name">Sum gjeld og egenkapital</th>
                        {getRow(data?.totalLiabilitiesAndEquity)}
                    </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default Assets;