import React from 'react';
import classes from './index.module.scss';
import {Row, Col, Container, Form, Alert} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import FormInput from "../FormInput";
import Button from "../Button";
import { subscribeNewsletter } from 'Services/api/NewsLetterService';
import { useState } from 'react';
import EmailValidator from './Validator';
import { useSelector } from 'react-redux';


const NewsLetter = ({ detail, isBusiness }) => {
    const { settings } = useSelector(state => state.settings);
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState('');
    const _handleSubscription = () => {
        setSuccess(false);
        setError(false);
        const validator = new EmailValidator(settings);
        const result  = validator.validate({ email });
        if(JSON.stringify(result) !== '{}'){
            setMessage(result?.email)
            return;
        }
        else{
            setMessage(false);
        }
        subscribeNewsletter(email)
        .then(() => setSuccess(true))
        .catch(err => {
            setError(true)
        })
    }

    return(
        <React.Fragment>
            {isBusiness ? (
                <div className={`${classes.newsLetter} ${classes.footerNewsLetter}`}>
                    <div className={classes.newsLetterBox}>
                        <h6>Nyhetsbrev!</h6>
                        <p>Bli med i vårt nyhetsbrev for å holde deg oppdatert!</p>
                        <Form>
                            <div className={classes.inputRow}>
                                <FormInput
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    placeholder={"E-post"}
                                />
                                <Button
                                    onClick={_handleSubscription}
                                    text={'Meld meg på!'}
                                    variant={'orange'}
                                />
                            </div>
                            {message !== '' && <span className='text-danger'><small>{message}</small></span>}
                            {success && <span className='text-success'><small>{detail?.successMessage ?? 'Du har blitt vellykket abonnent på nyhetsbrevet.'}</small></span>}
                            {error && <span className='text-danger'><small>{detail?.errorMessage ?? 'Det oppstod en feil under forsøket på å abonnere.'}</small></span>}
                            <p className={'pt-3'}>Ved å abonnere, samtykker du til vår personvernpolicy og gir samtykke til å motta oppdateringer fra vårt selskap.</p>
                        </Form>
                    </div>
                </div>
            ):(
                <div className={`section ${classes.newsLetter}`}>
                    <Container>
                        <div className={classes.newsLetterBox}>
                            <div className={classes.iconBox}>
                                <i className={'fal fa-paper-plane'}></i>
                            </div>
                            <Row className={'align-items-center'}>
                                <Col lg={6}>
                                    <SectionTitle description={detail.heading.description} className={'pb-0'}>
                                        {
                                            detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                                        }
                                    </SectionTitle>
                                </Col>
                                <Col lg={6}>
                                    <Form>
                                        <div className={classes.inputRow}>
                                            <FormInput
                                                value={email}
                                                onChange={e => setEmail(e.target.value)}
                                                placeholder={detail?.emailPlaceholder}
                                            />
                                            <Button
                                                onClick={_handleSubscription}
                                                text={detail?.buttonText}
                                            />
                                        </div>
                                        {message !== '' && <span className='text-danger'><small>{message}</small></span>}
                                        {success && <span className='text-success'><small>{detail?.successMessage}</small></span>}
                                        {error && <span className='text-danger'><small>{detail?.errorMessage}</small></span>}
                                    </Form>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
            )}

        </React.Fragment>
    )
}

export default NewsLetter;