import img1 from '../../../Images/img52.svg';
import img2 from '../../../Images/img53.svg';
import img3 from '../../../Images/img54.svg';
import img4 from '../../../Images/img55.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Fikk du ikke godkjent et bedriftslån?", class: "" },
            ]
        },
        content: <>
            <p>Personlige lån kan være en alternativ løsning. I mange tilfeller kan personlige lån uten sikkerhetskrav tilby den fleksibiliteten og tilgjengeligheten du trenger. Som personlig ansvarlig for lånet, likheten med bedriftslån hvor man også står personlig ansvarlig, kan dette være en praktisk måte å sikre nødvendig finansiering på. La oss hjelpe deg med å utforske og sammenligne ulike lånealternativer for å finne det som best møter dine behov.</p>
        </>,
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#EBF3FB',
        dataList: [
            {
                title: "",
                description: "",
            },
        ]
    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Avslag på bedriftslån kan skyldes ulike forhold.", class: "" },
            ]
        },
        content: '',
        btnText: '',
        redirectUrl: "",
        featuredImage: '',
        horizontal: true,
        dataList: [
            {
                title: "Her er tre mulige grunner til hvorfor din bedrift kan ha fått avslag:",
                description: "",
            },
            {
                title: "Nylig etablert selskap med for lite historikk:",
                description: "Det er vanlig for banker og långivere å ønske en litt lengre historikk for å bedre kunne vurdere hvordan bedriften har gjort det og dens evne til å generere inntekter.",
            },
            {
                title: "Lav kredittscore:",
                description: "En lav kredittscore kan signalisere til långivere at det er større risiko knyttet til å låne ut penger til bedriften. Du kan logge inn gratis hos oss og få scoren på deg personlig og din bedrift.",
            },
            {
                title: "Betalingsanmerkninger:",
                description: "Verken du eller selskapet ditt kan ha betalingsanmerkninger, da dette indikerer tidligere økonomiske mislighold og kan gjøre kreditorer skeptiske til å tilby lån. Du kan logge inn gratis hos oss og sjekke om du eller selskapet ditt har betalingsanmerkninger.",
            },
        ]
    },
];

export default contentWithImgData;
