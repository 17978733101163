import unofinans from "../../../../../Images/unofinans.svg";
import moty from "../../../../../Images/motty.svg";
import nanoFinance from "../../../../../Images/Nanofinans.svg";
import okidaLogo from "../../../../../Images/okidaLogo.svg";
import tfBank from '../../../../../Images/tf-bank-mastercard.png';
import remember from '../../../../../Images/remember.jpg';
import morrowBank from '../../../../../Images/morrowbank_mastercard1.svg';
import instaBank from '../../../../../Images/instabank_instapay.png';
import bankNorwegian from '../../../../../Images/banknorwegian.svg';
import creditcardcollection from '../../../../../Images/creditcardcollection.svg';
import spareBank from "../../../../../Images/SpareBank.svg";
import nordea from "../../../../../Images/nordeaLogo.svg";

const data = [
    {
        dataType: 'Boliglån',
        title: "Refinansier med sikkerhet i bolig",
        averageRent: "5,60",
        faqs: [
            {
                heading: "Slik får du bedre betingelser på lånet",
                list: [
                    {
                        title: "Forhandle med banken din",
                        description: "Ta kontakt med banken din og be om en gjennomgang av lånebetingelsene. Ofte er bankene villige til å senke renten for eksisterende kunder, spesielt hvis du har god betjeningsevne og har vært en lojal kunde over tid.",
                    },
                    {
                        title: "Forbedre betjeningsevnen din",
                        description: "Jo bedre betjeningsevne du har, desto lavere risiko tar banken ved å låne deg penger, og dermed kan du forhandle om bedre rentevilkår. Økningen av betjeningsevnen kan oppnås gjennom høyere inntekt, lavere gjeld eller økt egenkapitalandel.",
                    },
                    {
                        title: "Sjekk konkurrerende tilbud",
                        description: "Utforsk markedet og sammenlign renter og lånebetingelser fra ulike banker og långivere. Ved å hente inn flere tilbud kan du ha bedre forhandlingsposisjon når du diskuterer med din nåværende bank.",
                    },
                    {
                        title: "Vurder refinansiering",
                        description: "Hvis du finner bedre tilbud andre steder, kan refinansiering være et godt alternativ. Ved å flytte boliglånet ditt til en annen bank med lavere rente, kan du redusere dine lånekostnader betydelig på lang sikt.",
                    },
                    {
                        title: "Benytt deg av spesialordninger",
                        description: "Sjekk om det finnes spesialordninger som du er kvalifisert for, for eksempel BLU (Boliglån for unge) eller andre tilbud gjennom fagforbund eller andre organisasjoner. Disse spesialordningene kan tilby lavere renter eller gunstigere lånevilkår.",
                    }
                ]
            }
        ],
        childData: [
            {
                name: "",
                image: spareBank,
                leverandorTekst: 'EasyFinance anbefaler Sparebank 1 SR-Bank for boliglån grunnet deres pålitelighet og dekning av medlemmers behov. De tilbyr konkurransedyktige vilkår, spesielt gunstige for LO-medlemmer og førstegangskjøpere, noe som gjør dem til et ideelt valg.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '5,60%',
                featured: false,
                loanExample: 'Låneeksempel: Effektiv rente 5.97% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 788 775.',
                btnUrl: "https://www.sparebank1.no/nb/smn/privat/lan/boliglan.html ",
                affiliateType: "",
            },
            {
                name: "",
                image: nordea,
                leverandorTekst: 'EasyFinance anbefaler Nordea for boliglån, da de er en landsdekkende og pålitelig bank som dekker våre medlemmers varierte behov. Nordea tilbyr konkurransedyktige vilkår og fleksibilitet, samt moderne tjenester som ApplePay, noe som gjør dem til et godt valg for våre medlemmer.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '6.05%',
                featured: false,
                loanExample: ' Låneeksempel: Effektiv rente 5.70% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 703 643.',
                btnUrl: "https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/sok-om-boliglan.html",
                affiliateType: "",
            },
        ]
    },
    {
        dataType: 'Forbrukslån',
        title: "Refinansier dyre lån uten sikkerhet",
        averageRent: "9,59",
        faqs: [
            {
                heading: "Fordeler med refinansiering uten bolig.",
                list: [
                    {
                        title: "Reduserte lånekostnader",
                        description: "Ved å refinansiere til en lavere rente kan du betydelig redusere de totale lånekostnadene over tid. Dette frigjør mer penger i budsjettet ditt som kan brukes til andre formål eller for å øke sparepotensialet.",
                    },
                    {
                        title: "Bedre oversikt over økonomien",
                        description: "Ved å ha bare ett lån å forholde seg til, blir det lettere å holde oversikt over gjeldssituasjonen din. Dette kan bidra til bedre planlegging og styring av økonomien din på lang sikt.",
                    },
                    {
                        title: "Samling av flere lån til ett større lån",
                        description: "Å ha flere lån og kreditter kan være forvirrende og føre til høyere administrative kostnader. Ved å refinansiere og samle disse lånene til ett større lån, kan du forenkle økonomistyringen og redusere risikoen for å glemme eller forsinke betalinger.",
                    },
                    {
                        title: "Bedre fleksibilitet",
                        description: "Refinansiering kan gi deg muligheten til å justere nedbetalingstiden eller innføre avdragsfrihet, noe som kan være spesielt nyttig hvis du opplever midlertidige økonomiske utfordringer. Dette gir deg mer fleksibilitet til å tilpasse lånet til din nåværende økonomiske situasjon.",
                    },
                    {
                        title: "Mulighet for bedre lånevilkår",
                        description: "Når du søker om refinansiering, får du muligheten til å forhandle om bedre lånevilkår med långiveren din. Dette kan inkludere lavere rente, færre gebyrer og andre fordeler som kan gjøre lånet mer gunstig for deg på lang sikt.",
                    },
                    {
                        title: "Refinansier opptil 800 000 kr uten sikkerhet.",
                        description: "Du kan refinansiere forbrukslån på opptil 800 000 kr uten å måtte stille sikkerhet. Dette kan bidra til å spare deg mye penger og gjøre både økonomien og hverdagen din enklere.",
                    }
                ]
            }
        ],
        childData: [
            {
                name: "",
                image: unofinans,
                leverandorTekst: 'EasyFinance samarbeider med Uno Finans for at våre medlemmer skal få de beste lånevilkårene. Unofinans tilbyr en svært enkel og brukervennlig lånesøknadsprosess, hvor du med bare én søknad kan motta flere tilbud.',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '7.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 12.71% ved lån på 150 000 kr over 5 år. Totalt: kr 199 237.',
                btnUrl: "https://ormedbyte.com/click.track?CID=430720&AFID=529108",
            },
            {
                name: "",
                image: moty,
                leverandorTekst: 'Motty kobler deg med over 20 ledende banker for å finne de beste lånetilbudene, raskt og sikkert. Med erfaring siden 1985, garanterer Motty kvalitet og kundetilfredshet. Nyt gratis veiledning og en enkel prosess. For personlig rådgivning, kontakt Motty.',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 14.15% ved lån på 100 000 kr over 5 år. Totalt: kr 137 120.',
                btnUrl: "https://aslinkhub.com/?bid=2529826&media_id=100963",
            },
            {
                name: "",
                image: nanoFinance,
                leverandorTekst: "Nanofinans, del av Sambla og en av Norges ledende finansagenter, sender søknaden din til 23 långivere for å sikre deg beste rente og vilkår. Motta tilbud raskt og uforpliktende.",
                rating: '4.5',
                loanAmount:'10 000 kr - 600 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 13.14% ved lån på 200 000 kr over 5 år. Totalt: kr 269 078.',
                btnUrl: "https://corlmedi.com/click.track?CID=457217&AFID=529108",

            },
        ]
    },
]

export default data;