import img1 from './Corporate.json';
import img2 from './NonCorporate.json';
import img3 from './Corporate.json';

const data = [
    {
        dataType: 'Annonse',
        image: img1,
        text: `
            <h3>Easyfinance.no: Nøytral Sammenligningsplattform med Sikker Bestillingsfunksjonalitet</h3>
            <p>Easyfinance.no samarbeider med selskaper for å muliggjøre sikre bestillinger via plattformen deres, slik at forbrukere kan gjennomføre transaksjoner sømløst. Forbrukere kan få tilgang til tjenestene deres kostnadsfritt, da Easyfinance.no mottar gebyrer fra partnere for å bringe inn nye kunder. Viktigst av alt forblir upartiskheten i anmeldelser og bedømmelser av selskaper konsekvent, upåvirket av eventuelle kommersielle samarbeid. Dette engasjementet sikrer at Easyfinance.no forblir en nøytral og pålitelig plattform for forbrukere å sammenligne tjenester trygt.</p>
            <p>Plattformens vektlegging av å opprettholde nøytralitet understreker deres dedikasjon til å tilby upartiske sammenligninger. Ved å fremme tillit og pålitelighet i sammenligningene sine, ønsker Easyfinance.no å styrke forbrukerne i å ta informerte beslutninger. Denne modellen med samarbeid, der selskaper betaler for kundeintroduksjoner, muliggjør kontinuerlige forbedringer av tjenestene deres samtidig som plattformens forpliktelse til upartiskhet opprettholdes.</p>
        `,
    },
    {
        dataType: 'NonCorporate',
        image: img2,
        text: `
            <h3>Selskap Ikke Tilknyttet til Easyfinance.no for Direkte Bestillingstjenester</h3>
            <p>Dette selskapet deltar ikke i kommersielle samarbeid med Easyfinance.no, og som et resultat er alternativet for direkte bestilling fra deres nettsted utilgjengelig. Mens du fritt kan søke opp selskapet uavhengig, er det viktig å være klar over at produktinformasjon, priser og vilkår samsvarer med det som presenteres på Easyfinance.no.</p>
            <p>Ved å understreke plattformens forpliktelse til upartiskhet, forblir evalueringer og diskusjoner om alle selskaper og avtaler konsekvente og upartiske, uavhengig av kommersielle partnerskap. Dette engasjementet sikrer rettferdighet i vurderinger uansett samarbeidsstatus.</p>
            <p>For selskaper som er interessert i å aktivere funksjonalitet for direkte bestilling, inviterer vi deg til å utforske våre bedriftsløsninger. Klikk her for mer omfattende detaljer og informasjon.</p>
        `,
    },
    {
        dataType: 'Corporate',
        image: img3,
        text: `
            <h3>Easyfinance.no: Nøytral Sammenligningsplattform med Sikker Bestillingsfunksjonalitet</h3>
            <p>Easyfinance.no samarbeider med selskaper for å muliggjøre sikre bestillinger via plattformen deres, slik at forbrukere kan gjennomføre transaksjoner sømløst. Forbrukere kan få tilgang til tjenestene deres kostnadsfritt, da Easyfinance.no mottar gebyrer fra partnere for å bringe inn nye kunder. Viktigst av alt forblir upartiskheten i anmeldelser og bedømmelser av selskaper konsekvent, upåvirket av eventuelle kommersielle samarbeid. Dette engasjementet sikrer at Easyfinance.no forblir en nøytral og pålitelig plattform for forbrukere å sammenligne tjenester trygt.</p>
            <p>Plattformens vektlegging av å opprettholde nøytralitet understreker deres dedikasjon til å tilby upartiske sammenligninger. Ved å fremme tillit og pålitelighet i sammenligningene sine, ønsker Easyfinance.no å styrke forbrukerne i å ta informerte beslutninger. Denne modellen med samarbeid, der selskaper betaler for kundeintroduksjoner, muliggjør kontinuerlige forbedringer av tjenestene deres samtidig som plattformens forpliktelse til upartiskhet opprettholdes.</p>
        `,
    },

]

export default data;