import React, {useEffect, useState} from 'react';
import { useParams } from 'react-router-dom';
import {Container, Tabs, Tab} from "react-bootstrap";
import classes from './index.module.scss';
import SectionTitle from "../../SectionTitle";
import ContentWithImage from "../../ContentWithImage";
import CardList from "../../Card";
import DetailPopUp from "../../Card/Popup";
import Button from "../../Button";
import { getLender } from 'Services/api/Lender';
import draftToHtml from 'draftjs-to-html';
import { onError } from 'Services/api/Image';
import { getAllByLenderId} from 'Services/api/Loan';
import { getAllCards } from '../../../Services/api/Loan';
import CompareSidebar from '../../ProductListing/CompareSidebar';
import { useSelector } from 'react-redux';

const LenderDetail = () => {
    const { settings } = useSelector(state => state.settings);
    const languageId = 1;
    
    const { lenderTitle } = useParams();
    const lenderData = { title: undefined };
    const [selectedDataType, setSelectedDataType] = useState(null);
    const [modalShow, setModalShow] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [compareItems, setCompareItems] = useState([]);
    const [allCards, setAllCards] = useState([]);

    const [cardsLoading, setCardsLoading] = useState(false);
    const [cards, setCards] = useState([]);
    const [lender, setLender] = useState({});
    const [activeTab, setActiveTab] = useState('loanWithoutSecurity');

    const handleModalClose = () => {
        setModalShow(false);
    };

    const [lenderDetails, setLenderDetails] = useState({});

    useEffect(() => {
        getLender(lenderTitle).then(resp => {
            setLender(resp);
            const details = JSON.parse(resp.data.detail);
            setLenderDetails(details);
        })
    }, []);


    const _loadLenderCards = () => {
        if(cards.length !== 0) return;
        setCardsLoading(true);
        getAllByLenderId(lender?.data?.id)
        .then(resp => {
            if(!resp) return;
            setCards(resp?.data);
        })
        .finally(() => setCardsLoading(false));
    }
    const _loadAllCards = (loanTypeId) => {
        getAllCards(loanTypeId)
        .then(setAllCards);
    }
    useEffect(() => {
        if(!lender?.data?.id) return;
        _loadLenderCards();
    }, [activeTab, lender]);

    useEffect(() => {
        const obj = {
            loanSecurity: 1,
            loanWithoutSecurity: 2,
            credit: 3
        }
        _loadAllCards(obj[activeTab])
    }, [activeTab])

    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };

    return (
        <React.Fragment>
            <section className={'section bg-light-half-bottom border-bottom-none'}>
                <Container>
                    <div className={classes.cto}>
                        <div className={classes.col}>
                            <div className={`${classes.box} ${classes.logoHolder}`}>
                                <div className={classes.logoBox}>
                                    <div className={classes.logo}>
                                        <img src={lenderDetails.lenderImage} loading="lazy" onError={onError} alt="Lender Logo" />
                                    </div>
                                    {/*<h2 className={'text-center'}>{lenderDetails.title}</h2>*/}
                                </div>
                            </div>
                        </div>
                        <div className={classes.col}>
                            <div className={classes.box}>
                                <h2 className={classes.boxTitle}>{lenderDetails.title}</h2>
                                <div className={classes.innerColHolder}>
                                    <div className={classes.innerCol}>
                                        <h3>
                                            <i className="icon-Contactinformationcon" style={{marginTop: '-4px'}}></i>
                                            {lenderDetails?.contactUsLabel}
                                        </h3>
                                        <dl>
                                            <dt>{lenderDetails?.addressLabel}:</dt>
                                            <dd>{lenderDetails?.address}</dd>
                                            <dt>{lenderDetails?.phoneLabel}:</dt>
                                            <dd>{lenderDetails?.phone}</dd>
                                            <dt>{lenderDetails?.websiteLabel}:</dt>
                                            <dd><a href={lenderDetails?.website} target={'_blank'}>{lenderDetails.title}</a> </dd>
                                        </dl>
                                    </div>
                                    <div className={classes.innerCol}>
                                        <h3>
                                            <i className="icon-Oppeninghoursicon"></i>
                                            {lenderDetails?.openingHoursTitle}
                                        </h3>
                                        <dl>
                                            <dt>{lenderDetails?.mondayToThursdayHeading}:</dt>
                                            <dd>{lenderDetails?.mondayToThursday}</dd>
                                            <dt>{lenderDetails?.fridayLabel}:</dt>
                                            <dd>{lenderDetails?.friday}</dd>
                                            <dt>{lenderDetails?.saturdayLabel}:</dt>
                                            <dd>{lenderDetails?.saturday}</dd>
                                            <dt>{lenderDetails?.sundayLabel}:</dt>
                                            <dd>{lenderDetails?.sunday}</dd>
                                        </dl>
                                    </div>
                                </div>
                                <div className={'text-center w-100'}>
                                    <Button
                                        text={lenderDetails?.getStartedButtonLabel}
                                        url={lenderDetails?.website}
                                        target="_blank"
                                        icon={'far fa-arrow-right'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
            <section className={'section bg-light border-top-none'}>
                <Container>
                    <SectionTitle description={lenderDetails.generalInformation?.description}>
                        { lenderDetails.generalInformation?.title?.map(title => <span className={title.class}>{title.title}</span>) }
                    </SectionTitle>
                    <div className={classes.tabsBox}>
                        <Tabs defaultActiveKey="about" className={classes.verTabs}>
                            {[
                                { key: 'about', label: lenderDetails?.aboutLabel, icon: 'icon-icon31' },
                                { key: 'benefits', label: lenderDetails?.benefitsLabel, icon: 'icon-icon32' },
                                { key: 'cons', label: lenderDetails?.consLabel, icon: 'icon-icon13' },
                                { key: 'conditionsForApplying', label: lenderDetails?.conditionsForApplyingLabel, icon: 'icon-icon14' }
                            ].map((tab, index) => (
                                <Tab
                                    key={index}
                                    eventKey={tab.key}
                                    title={
                                        <>
                                            {tab.icon && <i className={tab.icon} />}
                                            {tab.label ? tab.label : ''}
                                        </>
                                    }
                                >
                                    <div dangerouslySetInnerHTML={{ __html: renderFilteredHTML(lenderDetails[tab.key]) }} />
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </Container>
            </section>
            <ContentWithImage
                detail={{
                    component: {
                        featuredImage: lenderDetails?.historyImage
                    },
                    heading: {
                        title: lenderDetails?.history?.title,
                        description: lenderDetails?.historyDescription
                    },
                    subHeading: lenderDetails?.history?.description
                }}
            />
            {/*<section className={'section'}>
                <Container>
                    <SectionTitle textCenter description={lenderDetails?.lenderProducts?.description}>
                        {lenderDetails?.lenderProducts?.title?.map(title => <span className={title.class} key={title.id}>{title.title}</span>)}
                    </SectionTitle>
                    <Tabs activeKey={activeTab} className={classes.tabsHorizenral} onSelect={key => setActiveTab(key)}>
                        <Tab eventKey="loanWithoutSecurity" title={lenderDetails?.loanWithoutSecurityTabLabel}>
                            <div className={classes.productList}>
                                {!cardsLoading && cards?.filter(c => c?.loanDetailCategories?.map(ldc => ldc?.loanCategory?.loanTypeId)?.includes(2))?.length === 0 ? <Alert variant={'danger w-100'}>{lenderDetails?.dataNotFound}</Alert> : null}
                                {cards?.filter(c => c?.loanDetailCategories?.map(ldc => ldc?.loanCategory?.loanTypeId)?.includes(2))?.map((item, index) => {
                                        const d = JSON.parse(item?.detail ?? '{}');
                                        return <CardList
                                            splitTwo
                                            key={index}
                                            item={d}
                                            index={index}
                                            displayDetails={false}
                                            handleCompare={e => {
                                                if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                                setCompareItems([...compareItems, e])
                                            }}
                                            setSelectedDataTypeProp={setSelectedDataType}
                                            setModalShow={setModalShow}
                                        />
                                })}
                                {lenderDetails?.loanWithSecurityImages?.map((url, index) => {
                                return <div id='imageee'>
                                    <image key={index} src={url} width={100} height={100} alt="Ali Ahmad"/>
                                </div>})}
                            </div>
                        </Tab>
                        <Tab eventKey="loanSecurity" title={lenderDetails?.loanWithSecurityTabLabel}>
                            <div className={classes.productList}>
                                {!cardsLoading && cards?.filter(c => c?.loanDetailCategories?.map(ldc => ldc?.loanCategory?.loanTypeId)?.includes(1)).length === 0 ? <Alert variant={'danger w-100'}>{lenderDetails?.dataNotFound}</Alert> : null}
                                    {cards?.filter(c => c?.loanDetailCategories?.map(ldc => ldc?.loanCategory?.loanTypeId)?.includes(1))?.map((item, index) => {
                                        const d = JSON.parse(item?.detail ?? '{}');
                                        return <CardList
                                            splitTwo
                                            key={index}
                                            item={d}
                                            index={index}
                                            displayDetails={false}
                                            handleCompare={e => {
                                                if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                                setCompareItems([...compareItems, e])
                                            }}
                                            setSelectedDataTypeProp={setSelectedDataType}
                                            setModalShow={setModalShow}
                                        />
                                    })}
                                 Show more button
                            </div>
                            {lenderDetails?.loanWithSecurityImages?.map((url, index) => {
                                return <div id='imageee'>
                                    <image key={index} src={url} width={100} height={100} alt="Ali Ahmad"/>
                            </div>})}
                        </Tab>
                        <Tab eventKey="credit" title={lenderDetails?.creditCardsTabLabel}>
                            <div className={classes.productList}>
                                {!cardsLoading && cards?.filter(c => c?.loanDetailCategories?.map(ldc => ldc?.loanCategory?.loanTypeId)?.includes(3))?.length === 0 ? <Alert variant={'danger w-100'}>{lenderDetails?.dataNotFound}</Alert> : null}
                                {cards?.filter(c => c?.loanDetailCategories?.map(ldc => ldc?.loanCategory?.loanTypeId)?.includes(3))?.map((item, index) => {
                                        const d = JSON.parse(item?.detail ?? '{}');
                                        return <CardList
                                            splitTwo
                                            key={index}
                                            item={d}
                                            index={index}
                                            displayDetails={false}
                                            handleCompare={e => {
                                                if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                                setCompareItems([...compareItems, e])
                                            }}
                                            setSelectedDataTypeProp={setSelectedDataType}
                                            setModalShow={setModalShow}
                                        />
                                })}
                                {lenderDetails?.creditCardImages?.map((url, index) => {
                                return <div id='imageee'>
                                    <image key={index} src={url} width={100} height={100} alt="Ali Ahmad"/>
                                </div>})}
                            </div>
                        </Tab>
                    </Tabs>
                    {cardsLoading && <Loading />}
                </Container>
            </section>*/}
            <section className={'section'}>
                <Container>
                    <SectionTitle textCenter description={lenderDetails?.compareProducts?.description}>
                        {lenderDetails?.compareProducts?.title?.map((title, index) => <span key={index} className={title.class}>{title.title}</span>)}
                    </SectionTitle>

                        <div className={classes.productList}>
                            {allCards && allCards?.filter((item, index) => {
                                if(item?.lenderId === lender?.data?.id) return false;
                                else return true;
                            }).length > 0 ? null : <h1>Ingen kort funnet</h1>}
                            {allCards &&
                                allCards?.map((item, index) => {
                                    if(item?.lenderId === lender?.data?.id) return;
                                    const d = JSON.parse(item?.detail ?? '{}');
                                    return <CardList
                                       // splitTwo
                                        key={index}
                                        item={d}
                                        index={index}
                                        displayDetails={false}
                                        // data={allC}
                                        handleCompare={e => {
                                            if(compareItems.map(i => JSON.stringify(i)).includes(JSON.stringify(e))) return;
                                            setCompareItems([...compareItems, e])
                                        }}
                                        setSelectedDataTypeProp={setSelectedDataType}
                                        setModalShow={setModalShow}
                                    />
                                })}
                            {/* Show more button */}
                        </div>
                        {compareItems.length > 0 && (
                            <Button onClick={() => setSidebarOpen(true)} text={`${settings?.find(s => s?.variableName === 'compareBannerText')?.value} ${compareItems.length}`}  iconLeft variant={'verticalFixed'} />
                        )}
                        {sidebarOpen && <CompareSidebar
                            selectedData={compareItems}
                            onClose={() => setSidebarOpen(false)}
                            handleDelete={e => setCompareItems(compareItems.filter((_, i) => i !== e))}
                        />}
                </Container>
            </section>

            {/* <section className={'section'}>
                <Container>
                    <Row>
                        <Col lg={7}>
                            <SectionTitle description={'MyBank is ranked 4 out of 54 companies in the overall ranking and received an average review score of 5 based on 1 review. Their weakness is Interest & Costs and their strengths are Support & Customer Service.'}>
                                <span className={'fw-normal'}>Reviews</span>
                                <span className={'fw-light'}>of</span>
                                <span className={'fw-semibold'}>{lenderData.name}</span>
                            </SectionTitle>
                            {lenderData.reviews.map((item, index) => (
                                <CustomerReviews key={index} item={item} data={lenderData.reviews} />
                            ))}
                        </Col>
                        <Col lg={5}>
                            <div className={'p-3 border mb-3'}>
                                <Row className={'align-items-center'}>
                                    <Col>
                                        <StarRating ratingBox rating={'5.0'}  />
                                    </Col>
                                    <Col className={'text-right'}>
                                        <span className={'text-muted'}>1 Review</span>
                                    </Col>
                                </Row>
                                <ul className={classes.ratingBar}>
                                    <li>
                                        <div className={classes.col1}>
                                            <span>5</span>
                                            <ProgressBar now={100} />
                                        </div>
                                        <div className={classes.col2}>
                                            <span>100%</span>
                                            <span>1</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.col1}>
                                            <span>4</span>
                                            <ProgressBar now={70} />
                                        </div>
                                        <div className={classes.col2}>
                                            <span>70%</span>
                                            <span>1</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.col1}>
                                            <span>3</span>
                                            <ProgressBar now={60} />
                                        </div>
                                        <div className={classes.col2}>
                                            <span>60%</span>
                                            <span>1</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.col1}>
                                            <span>2</span>
                                            <ProgressBar now={40} />
                                        </div>
                                        <div className={classes.col2}>
                                            <span>40%</span>
                                            <span>1</span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className={classes.col1}>
                                            <span>1</span>
                                            <ProgressBar now={0} />
                                        </div>
                                        <div className={classes.col2}>
                                            <span>0</span>
                                            <span>1</span>
                                        </div>
                                    </li>
                                </ul>
                                <ul className={classes.ratingBar}>
                                    <li>
                                        <div className={'text-light'}>Interest and Costs</div>
                                        <StarRating  initialRating={'5.0'}  />
                                    </li>
                                    <li>
                                        <div className={'text-light'}>Flexibility and Terms</div>
                                        <StarRating initialRating={'5.0'}  />
                                    </li>
                                    <li>
                                        <div className={'text-light'}>Website & Functionality</div>
                                        <StarRating initialRating={'5.0'}  />
                                    </li>
                                    <li>
                                        <div className={'text-light'}>Support & Customer Service</div>
                                        <StarRating initialRating={'5.0'}  />
                                    </li>
                                </ul>
                                <div className={'text-center py-3'}>
                                    <Button
                                        text={'</> Get company logo '}
                                        variant={'link font-20'}
                                    />
                                </div>

                            </div>
                            <div className={'text-center py-3 border'}>
                                <Button
                                    variant={'link font-20 text-dark mb-3'}
                                    url={'post-reviews'}
                                    text={`Rate & review ${lenderData.name}`}
                                    data={lenderData.name}
                                />
                                <StarRating initialRating={'5.0'}  />
                            </div>
                            <div className={'py-3'}>
                                <Button
                                    url={'abc.com'}
                                    text={'Go to company '}
                                    variant={' w-100'}
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className={'section bg-light'}>
                <Container>
                    <SectionTitle textCenter description={'Visi their official website and road more about axo finans'}>
                        <span className={'fw-normal'}>Not</span>
                        <span className={'fw-semibold'}>convinced?</span>
                    </SectionTitle>
                    <div className={'text-center'}>
                        <Button
                            text={'Go to company'}
                            url={'abc.com'}
                        />
                        <div className={'pt-3'}>Advertisement</div>
                    </div>
                </Container>
            </section> */}

            {modalShow &&
                <DetailPopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    dataType={selectedDataType}
                    selectedDataType={selectedDataType}
                    handleClose={handleModalClose}
                />
            }
        </React.Fragment>
    )
}

export default LenderDetail;
