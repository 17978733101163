import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import moment from 'moment';

const Remark = ({ remark }) => {
    const styles = StyleSheet.create({
        section1: {
            marginBottom: 15,
            fontSize: 8,
        },
        contentList: {
            flexDirection: 'column',
            width: '100%',
            color: '#013367',
            marginBottom: 5,
            fontSize: 8,
        },
        list: {
            flexDirection: 'row',
            width: '100%',
            borderLeft: '3px solid #DEE2E5',
            marginBottom: '1px',
            backgroundColor: 'rgba(249,249,249,0.50)',
        },
        col: {
            padding: '5px 10px 2px',
        },
        bgGray: {
            backgroundColor: '#F8F8F7',
            color: '#4B4B4B',
            width: '100px',
        },
    });

    const format = (value, divideBy100 = false) => {
        if (isNaN(value)) return "N/A";
        const formatter = Intl.NumberFormat("no-NO");
        if (divideBy100) return formatter.format(value / 100);
        return formatter.format(value);
    };

    return (
        <View style={styles.section1} wrap={false}>
            <View style={styles.contentList}>
                <View style={styles.list}>
                    <Text style={[styles.col, styles.bgGray]}>Dato</Text>
                    <Text style={styles.col}>{moment(remark.date).format('DD-MM-YYYY')}</Text>
                </View>
                <View style={styles.list}>
                    <Text style={[styles.col, styles.bgGray]}>Kreditor</Text>
                    <Text style={styles.col}>{remark.creditor}</Text>
                </View>
                <View style={styles.list}>
                    <Text style={[styles.col, styles.bgGray]}>Beløp</Text>
                    <Text style={styles.col}>{format(remark.amount)} kr</Text>
                </View>
                <View style={styles.list}>
                    <Text style={[styles.col, styles.bgGray]}>Saksnummer</Text>
                    <Text style={styles.col}>{remark.refNr && remark.refNr !== '' ? remark.refNr : '-'}</Text>
                </View>
            </View>
            <Text>Kontakt {remark.creditor} for å vite dagens restsaldo på kravet</Text>
        </View>
    );
};

export default Remark;
