import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row, Spinner } from "react-bootstrap";
import FormInput from "../../Components/FormInput";
import Button from "../../Components/Button";
import classes from './index.module.scss';
import AnimatedImage from "../../Components/AnimatedImg";
import json3 from "../../Images/Gjeldsoversikt.svg";
import json2 from "../../Images/Boligverdi.svg";
import json1 from "../../Images/Kredittsjekk.svg";
import SectionTitle from "../../Components/SectionTitle";
import Thankyou from "../../Images/Thankyou.svg";
import { onError } from "Services/api/Image";
import { LoginService, signicatLogin } from "Services/api/Login";
import { Alert } from "react-bootstrap";
import { vippsLogin } from "../../Services/api/Login";

const RegistorBusiness = () => {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [authObject, setAuthObject] = useState({});

    useEffect(() => {
        const authString = localStorage.getItem('auth');
        const auth = JSON.parse(authString !== '' && authString ? authString : '{}');
        setAuthObject(auth);
        if(auth?.jwToken && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()){
            window.location.href = '/privatdashbord';
        }
    }, []);

    const handleLogin = () => {
        LoginService(username, password)
        .then(resp => {
            if(resp.succeeded){
                localStorage.setItem('auth', JSON.stringify(resp.data));
                window.location.href = '/privatdashbord'
            }
        })
    };
    const _handleBankId = () => {
        signicatLogin()
        .then(response => {
            window.location.href = response;
        })
    }
    const _handleVippsLogin = () => {
        vippsLogin()
        .then(response => {
            window.location.href = response;
        })
    }

    const handleResetPassword = () => {
        // Your reset password logic goes here
        // After resetting the password, you can show the thank you screen
        setShowThankYou(true);
    };

    if (showThankYou) {
        return (
            <React.Fragment>
                <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Id quam fermentum nec viverra neque feugiat. Viverra velit lorem non sapien sed euismod blandit morbi sit. Enim nulla turpis fermentum'}>
                    <span className={'fw-normal'}>Thank you</span>
                    <span className={'fw-light'}>for</span>
                    <span className={'fw-semibold'}>Register</span>
                </SectionTitle>

                <div className={'p-3'}>
                    <div className={'position-relative text-center'}>
                        <div className={'text-center mb-5'}>
                            <img src={Thankyou} loading="lazy" onError={onError} alt={''} />
                        </div>
                        <Button
                            text={'Go to Dashboard'}
                            url={'/'}
                            variant={'darkBorder'}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>

            <div className={`${classes.rowHolder} ${classes.smallForm}`}>
                <div className={classes.col}>
                    {!showResetForm ? (
                        <React.Fragment>
                            <div className={classes.text}>
                                <p className={'mb-5'}>Registrere for å sjekke bedriftens poengsum</p>
                                <ul className={'checkList inline'}>
                                    <li>bedriftens poengsum</li>
                                    <li>betalingshistorikk</li>
                                    <li>kontoer</li>
                                </ul>
                            </div>
                            <FormGroup>
                                <Button
                                    text={'Register med vipps'}
                                    variant={'vipps large w-100'}
                                    onClick={_handleVippsLogin}
                                />
                            </FormGroup>
                            {authObject.errorResponse && authObject.errorResponse !== '' ? <Alert variant='danger'>{authObject?.errorResponse}</Alert> : <></>}
                            <div className={classes.divider}><span>eller</span></div>
                            <div className={'font-12 text-center'}>
                                Register med
                                <Button
                                    onClick={''}
                                    variant={'link'}
                                    icon={'icon-BankID'}
                                    underline
                                />
                            </div>
                        </React.Fragment>
                    ) : null}
                </div>
            </div>
        </React.Fragment>
    );
}

export default RegistorBusiness;

