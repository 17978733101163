import img1 from '../../../Images/img49.svg';
import img2 from '../../../Images/img50.svg';
import img3 from '../../../Images/img51.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hvordan kvalifiserer du deg til et Grønt Lån?", class: "" },
            ]
        },
        content: 'For å være kvalifisert for et miljøvennlig lån, må din bedrift vise til engasjement for bærekraft ved å dokumentere investeringer i grønne tiltak, som kjøp av elbil eller installasjon av solceller. Långiver vil vurdere disse initiativene sammen med bedriftens økonomi for å avgjøre tilbakebetalingsevne.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#EBF3FB'

    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hva er grønt lån?", class: "" },
            ]
        },
        content: 'Et grønt lån finansierer prosjekter som fremmer bærekraft, som kjøp av elbiler eller installasjon av solcellepaneler. Det er en investering i både din bedrifts og planetens fremtid. Søk om grønn finansiering for å utnytte miljøfordeler og forbedre din økonomiske bunnlinje.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img2,
        horizontal: true
    },
    {
        id: 3,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hva kreves av sikkerhet?", class: "" },
            ]
        },
        content: 'Ingen av våre partnere krever tradisjonell sikkerhet som pant i eiendom eller bedriftens aktiva for våre bedriftslån. I stedet krever vi at du, eller andre relevante personer i bedriften som daglig leder eller styremedlem, stiller som selvskyldnerkausjonist. Dette innebærer at kausjonisten personlig garanterer for tilbakebetaling av lånet. Flere personer kan stille som kausjonister for samme lån. En kredittsjekk vil bli utført for å vurdere lånesøknaden.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img3,
        horizontal: false
    },
];

export default contentWithImgData;
