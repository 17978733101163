import React from 'react';
import classes from './index.module.scss';
import { Form } from 'react-bootstrap';
import FormInput from '../../FormInput';

const FiltersBanks = ({ handleSearch, handleAlphabetClick, selectedAlfabet }) => {
    const handleResetFilter = () => {
        handleAlphabetClick('');
    };

    const generateAlphabetList = () => {
        const alphabetList = Array.from(Array(26), (_, i) => String.fromCharCode(65 + i)).map((char) => {
            return (
                <li key={char}>
                    <span onClick={() => handleAlphabetClick(char)} className={selectedAlfabet === char ? classes.active : ''}>
                        {char}
                    </span>
                </li>
            );
        });

        const allOption = (
            <li key="All">
                <span onClick={handleResetFilter} className={selectedAlfabet === '' ? classes.active : ''}>
                    All
                </span>
            </li>
        );

        return [allOption, ...alphabetList];
    };

    return (
        <React.Fragment>
            <div className={classes.filterbanks}>
                <Form className={classes.searchBox}>
                    <FormInput
                        type={'search'}
                        placeholder={'Søk etter banker'}
                        fieldIcon={'fal fa-search'}
                        onChange={e => handleSearch(e.target.value)}
                    />
                </Form>
                <ul className={classes.filterList}>
                    {generateAlphabetList()}
                </ul>
            </div>
        </React.Fragment>
    );
};

export default FiltersBanks;
