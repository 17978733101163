function BlockQuote({ detail }){
    return <div className={'blockQuoteBlog'}>
        <h1>{detail?.heading}</h1>
        <p>{detail?.description}</p>
        <ul>
            {detail?.list?.map((quote, index) => <li key={index}>{quote}</li>)}
        </ul>
    </div>
}

export default BlockQuote;