import React from 'react';
import {Modal} from "react-bootstrap";

const SearchDetail = (props) => {
    const { show, onHide, data } = props;
  return(
      <React.Fragment>
          <Modal
              {...props}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  Søk detaljer
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  <h4>Beskrivelse: {data.title}</h4>
                  <p>Dato: {data.date}</p>
              </Modal.Body>
              <Modal.Footer>
              </Modal.Footer>
          </Modal>
      </React.Fragment>
  )
}

export default SearchDetail;