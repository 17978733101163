import React, { useEffect, useState } from "react";
import data from "../data";
import SectionTitle from "../../SectionTitle";
import classes from "../index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../Button";
import {Badge} from "react-bootstrap";
import { getOtherBlogs, getTrendingBlogs } from "Services/api/Blog";

const AllTrendingPosts = () => {
    const sortedPosts = data.slice().sort((a, b) => parseInt(b.views) - parseInt(a.views)); // Sort by views
    const [posts, setPosts] = useState([]);
    const languageId = 1;
    const count = 10000
    useEffect(() => {
        getOtherBlogs(languageId, count)
    })

    return (
        <React.Fragment>
            <section className={classes.blogSection}>
                <SectionTitle className={'pb-0'}>
                    <span className={'fw-light'}>Other</span>
                    <span className={'fw-semibld'}>Blogs</span>
                </SectionTitle>
                <div className={`${classes.colPost}`}>
                    {sortedPosts.map((item, index) => (
                        <div className={classes.col} key={item.id}>
                            <div className={classes.box}>
                                <Link to={`/all-trending/${item.id}`} className={classes.imgBox} style={{ backgroundImage: `url(${item.postImg})` }}></Link>
                                <div className={classes.description}>
                                    <div className={classes.infoRow}>
                                        <Badge>{item.postDate}</Badge>
                                        <Badge><i className={'fas fa-eye'}></i> {item.views}</Badge>
                                    </div>
                                    <h4 className={classes.title}><Link to={`/all-trending/${item.id}`}>{item.title}</Link></h4>
                                    <p>{item.description}</p>
                                    <Button
                                        text={'Read More'}
                                        url={`/all-trending/${item.id}`}
                                        variant={'link'}
                                        icon={'far fa-arrow-right'}
                                    />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {/* <div className={'py-3  border-bottom overflow-hidden'}>
                    <Button
                        text={'View More'}
                        icon={'far fa-arrow-right'}
                    />
                </div> */}
            </section>
        </React.Fragment>
    )
}

export default AllTrendingPosts;
