import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';
import NavSidebar from "../../Pages/UserDashboard/DashboardComponent/NavSidebar";
import {Container} from "react-bootstrap";
import UserDashboardHeader from "../../Components/Header/UserDashboardHeader";
import {useLocation} from "react-router-dom";
import Footer from "../../Components/Footer";

const DashboardLayout = ({ children }) => {
    const location = useLocation();
    const hide = () => {
        const { pathname } = location;
        return pathname === "/recommened-offers" || pathname === "/find-your-company";
    };

    return (
        <React.Fragment>
            <div id={classes.dashboardWrapper} className={(hide() ? ` ${classes.offerPage}` : '')}>
                <div className={classes.wrapperHolder}>
                    <UserDashboardHeader hideBottomHeader={hide()} />
                    <main id={classes.main}>
                        <Container>
                            <div className={`${classes.dashBoardHolder} ${hide() ? 'p-0' : ''}`}>
                                {hide() ? '' : <NavSidebar />}
                                <div className={classes.dashBoardContent}>
                                    {children}
                                </div>
                            </div>
                        </Container>
                    </main>
                    <Footer copyRightBar />
                </div>
            </div>
        </React.Fragment>
    );
};

export default DashboardLayout;
