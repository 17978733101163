import React from 'react';
import classes from './index.module.scss';
import {Container} from "react-bootstrap";
import imgA01 from '../../Images/imgA01.svg';
import SectionTitle from "../SectionTitle";
import Button from "../Button";
import SlideBox from "../SlideBox";


const ToggleSection = () => {
  return(
      <React.Fragment>
          <section className={'section bg-light pb-0 pt-3'}>
              <Container>
                  <div className={classes.colList}>
                      <div className={classes.col}>
                          <div className={classes.imgBox}>
                              <img src={imgA01} loading="lazy" alt={''} />
                          </div>
                      </div>
                      <div className={classes.col}>
                          <div className={classes.description}>
                              <SectionTitle>
                                  <span className={'fw-normal'}>Se hvorfor bedriftseiere</span>
                                  <span className={'fw-semibold'}> velger oss.</span>
                              </SectionTitle>
                              <p>Vil du ha vekst i bedriften, men usikker på hvor du skal begynne. EasyFinance hjelper deg med å gi deg riktig verktøy for å sikre økonomisk vekst.</p>
                              <SlideBox
                                  openTitleText={'Les mindre'}
                                  closeTitleText={'Les mer'}
                                  titleAsButton
                                  closeIcon={'fas fa-chevron-down'}
                                  openIcon={'fas fa-chevron-up'}
                                  variant={'link'}
                              >
                                  <ul className={classes.boxList}>
                                      <li>
                                          <div className={classes.box}>
                                              <div className={classes.title}>
                                                  <div className={classes.iconBox}>
                                                      <i className={'icon-cogs'}></i>
                                                  </div>
                                                  <h5>Logg inn</h5>
                                              </div>
                                              <p>Logg inn og kredittsjekk selskapet ditt for å få bedre forståelse av din bedriftsøkonomi.</p>
                                          </div>
                                      </li>
                                      <li>
                                          <div className={classes.box}>
                                              <div className={classes.title}>
                                                  <div className={classes.iconBox}>
                                                      <i className={'icon-fundingIcon'}></i>
                                                  </div>
                                                  <h5>Søk finansiering</h5>
                                              </div>
                                              <p>Søk lån fra 10 000 kr til 2 millioner kr for selskapet.</p>
                                          </div>
                                      </li>
                                      <li>
                                          <div className={classes.box}>
                                              <div className={classes.title}>
                                                  <div className={classes.iconBox}>
                                                      <i className={'icon-NonBindingIcon'}></i>
                                                  </div>
                                                  <h5>Uforpliktende og gratis</h5>
                                              </div>
                                              <p>Tilbudet du mottar er uforpliktende og gratis.</p>
                                          </div>
                                      </li>
                                  </ul>
                              </SlideBox>
                          </div>
                      </div>
                  </div>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default ToggleSection;