import React, { useEffect, useState } from 'react';
import classes from './index.module.scss';
import Button from '../../../../Components/Button';
import DataTable from 'react-data-table-component';
import SearchDetail from './SearchDetail';
import data from './data';
import {loadAllSearches} from '../../../../Services/api/LoanSearch'

const SavedSearches = (props) => {
        const [searches, setSearches] = useState([]);
        const languageId = 1;
        useEffect(() => {
            loadAllSearches(languageId)
                .then(resp => {
                    setSearches(resp.data)
                })
                .catch(console.error)
        }, []);

    const [detailShow, setDetailShow] = React.useState(false);
    const [selectedData, setSelectedData] = React.useState(null);
    const [tableData, setTableData] = React.useState(data);

    const columns = [
        {
            name: 'Title',
            selector: (row) => row.title,
            cell: row => {
                const data = JSON.parse(row.searchParams ?? '{}');
                return <a href={data.pageUrl} onClick={() => {
                    localStorage.setItem('searchParams', data.loanObject)
                }}>{row.title}</a>
            }
        },
        {
            name: 'Date',
            selector: (row) => row.date,
        },
        {
            name: 'Action',
            cell: (row) => <Button onClick={() => handleView(row)} text="View" variant="link"  />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
        },
    ];

    const handleView = (row) => {
        setSelectedData(row);
        setDetailShow(true);
    };

    const paginationOptions = {
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const customStyles = {
        rows: {
            spacing: '20px',
        },
    };

    return (
        <React.Fragment>
            <div className={classes.saveSearches}>
                <div className={classes.searchHeader}>
                    <h4>Your saved searches</h4>
                    <Button text={'Delete all searches'} icon={'fal fa-trash-alt'} iconLeft variant={'link'} />
                </div>
                <div className={classes.searchBody}>
                    <DataTable columns={columns} data={searches} selectableRows  pagination paginationComponentOptions={paginationOptions} customStyles={customStyles}/>
                    {detailShow && <SearchDetail show={detailShow} onHide={() => setDetailShow(false)} data={selectedData} />}
                </div>
            </div>
        </React.Fragment>
    );
};

export default SavedSearches;
