import React from 'react';
import {useLocation} from "react-router-dom";
import PartnerBanks from "../../Components/PartnerBanks";
import ToggleSection from "../../Components/ToggleSection";
import TabsWithInnerImage from "../../Components/TabsWithInnerImage";
import PackagesPlan from "../../Components/PackagesPlan";
import GetStarted from "../../Components/GetStarted";
import SuccessStories from "../../Components/SuccessStories";
import BusinesLandingPgaeBanner from "../../Components/Banner/BusinesLandingPgaeBanner";
import BusinessThreeCol from "../../Components/ColSection/BusinessThreeCol";
import FAQs from "../../Components/FAQs";
import faqData from "./faqData";
import blogData from "./blogData";
import LatestPosts from "../../Components/Blogs/LatestPosts";
import CallToAction from "../../Components/CallToAction";
import { MetaTags } from "react-meta-tags";
import tagList from "./tagList";
import StaticTagList from "../../Components/TagList/StaticTagList";

const BusinessLanding = ({detail}) => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = decodedPathname === '/bedrift/';


    return(
        <React.Fragment>
            <BusinesLandingPgaeBanner isBusiness={isBusiness} />
            <BusinessThreeCol />
            <PartnerBanks />
            <ToggleSection />
            {/*<TabsWithInnerImage />*/}
            <GetStarted />
            {/* TODO: PRODUCTION */}
            {/* <PackagesPlan /> */}
            {/*<SuccessStories />*/}
            <LatestPosts BusinessBlog detail={blogData} />
            <FAQs faqData={faqData}  />
            <StaticTagList data={tagList} />
            {/*<CallToAction />*/}
        </React.Fragment>
    )
}

export default BusinessLanding;