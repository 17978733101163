import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionTitle from "../../../../Components/SectionTitle";
import classes from './index.module.scss';
import data from './data';
import RecommendedCards from "./RecommendedCards";
import { Button } from "react-bootstrap";

const RecommendedOffers = () => {
    const navigate = useNavigate();

    // Find the default active item
    const defaultActiveItem = data.find(item => item.defaultActive) || null;

    const [activeCategory, setActiveCategory] = useState(defaultActiveItem);
    const [isCardsVisible, setIsCardsVisible] = useState(!!defaultActiveItem);
    const [isSmallDevice, setIsSmallDevice] = useState(false);

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        setIsCardsVisible(true);
        //navigate(`/recommened-offers#${category.dataType}`);
    };

    useEffect(() => {
        const handleResize = () => {
            setIsSmallDevice(window.innerWidth < 768); // Adjust the width threshold as per your requirements
        };

        handleResize(); // Call once initially to set the correct state
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={classes.recommendedOffers}>
                <SectionTitle>
                    <span className={'fw-normal'}>Velg passende rente fra</span>
                    <span className={'fw-semibold'}>vår anbefaling</span>
                </SectionTitle>
                <ul className={classes.recommendedOffersList}>
                    {data.map(item => (
                        <li key={item.dataType} id={`${isSmallDevice && isCardsVisible && activeCategory === item && `card${item.dataType}`}`} className={`${isSmallDevice && isCardsVisible && activeCategory === item && classes.spaceOnTop}`}>
                            <a
                                onClick={() => handleCategoryClick(item)}
                                className={`${classes.box} ${activeCategory === item ? classes.active : ''}`}
                                href={`#card${item.dataType}`}
                            >
                                <div className={classes.col}>{item.dataType}</div>
                                <div className={classes.col}>
                                    <span className={'font-12 mx-3'}>Eff. rente fra</span>
                                    <span className={'font-18 fw-semibold'}>{item.averageRent}%</span>
                                </div>
                                <i className={`fas fa-angle-down ${classes.animatedArrow}`}></i>
                            </a>
                            {isSmallDevice && isCardsVisible && activeCategory === item &&
                                <>
                                    <RecommendedCards data={activeCategory.childData} activeCategory={activeCategory} active={isCardsVisible} />
                                </>
                            }
                        </li>
                    ))}
                </ul>
                {!isSmallDevice && isCardsVisible && activeCategory && <RecommendedCards data={activeCategory.childData} activeCategory={activeCategory} />}
            </div>
        </React.Fragment>
    );
};

export default RecommendedOffers;
