import React from "react";
import {Container} from "react-bootstrap";
import data from './data';
import Slider from "react-slick";
import classes from './index.module.scss';


const CompanyInfo = ({ detail }) => {
    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 579,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return(
        <React.Fragment>
            <section className={'section bg-light-half-bottom border-top-none'}>
                <Container style={{maxWidth: '1200px'}}>
                    <Slider {...settings} className={`colList col3 ${classes.companyInfoCol}`}>
                        {detail?.component?.map((item, index) => (
                            <div className={'col'} key={index}>
                                <div className={'box text-left'}>
                                    <div className={'imgBox'}>
                                        <i className={item.icon}></i>
                                    </div>
                                    <h4 className={'title'}>{item.heading}</h4>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </Container>
            </section>
            <style>
                {`
                    .slick-slider{
                        display:block;
                        margin: 0 -15px;
                        border: none !important;
                     }
                     
                     .slick-dots{
                        padding: 20px 0 0;
                     }
                `}
            </style>
        </React.Fragment>
    )
}

export default CompanyInfo;