import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import {Badge, Container} from "react-bootstrap";
import { getAllCategories } from '../../../Services/api/Blog'
import { useSelector } from "react-redux";

const BlogCategories = ({ detail }) => {

    // const [categories, setCategories] = useState([]);
    const languageId = 1;
    const categories = useSelector(state => state.blogs.categories);

    // useEffect(() => {
    //     getAllCategories(languageId)
    //     .then(resp => {
    //         setCategories(resp.data);
    //     })
    // }, []);


    return (
        <React.Fragment>
            <Container>
                <div className={`${classes.blogList}`}>
                    {categories.map((category, index) => {
                        const postsInCategory = category?.blogsCount;
                        const data = JSON.parse(category?.description ? category?.description : '{}');
                        return (
                            <div className={classes.col} key={index}>
                                <div className={classes.box}>
                                    <a
                                        href={`/bloggkategorier/${category?.title?.toLowerCase()?.replace(' ', '-')}`}
                                        className={classes.imgBox}
                                        style={{
                                            backgroundImage: `url(${data.imgUrl})`
                                        }}
                                    ></a>
                                    <div className={classes.description}>
                                        <h3 className={classes.title}>
                                            <a href={`/bloggkategorier/${category?.title?.toLowerCase().replace(' ', '-')}`}>
                                                {category.title}
                                                <i className={"fas fa-arrow-right"}></i>
                                            </a>
                                        </h3>
                                        <Badge>{`${postsInCategory} ${postsInCategory === 1 ? 'post' : 'posts'}`}</Badge>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Container>
        </React.Fragment>
    );
};

export default BlogCategories;
