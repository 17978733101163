export const COMPONENTS_LOAD = "COMPONENTS_LOAD";
export const COMPONENTS_LOADED = "COMPONENTS_LOADED";
export const SETTINGS_LOADED = "SETTINGS_LOADED";
export const TESTIMONIALS_LOADED = "TESTIMONIALS_LOADED";

export const loadComponents = payload => ({
    type: COMPONENTS_LOADED,
    payload
})

export const settingsLoaded = payload => ({
    type: SETTINGS_LOADED, 
    payload
})

export const testimonialsLoaded = payload => ({
    type: TESTIMONIALS_LOADED,
    payload
})