import React from 'react';
import {useLocation} from "react-router-dom";
import bannerData from "./bannerData";
import BusinesStaticPageBanner from "../../../Components/Banner/BusinesStaticPageBanner";
import ColSection from "../../../Components/ColSection/ColSectionStatic";
import data from "./ColSectionData"
import AdSectionRightImg from "../../../Components/AdSectionRightImg";
import adSectionRightImgData from "./adSectionRightImgData";
import contentWithImgData from "./ContentWithImgData";
import StaticContentWithImg from "../../../Components/ContentWithImage/StaticContentWithImg";
import tagList from "../tagList";
import StaticTagList from "../../../Components/TagList/StaticTagList";
import callToActionData from "./callToActionData";
import CallToAction from "../../../Components/CallToAction";
import {MetaTags} from "react-meta-tags";
import pageImg from "../../../Images/imgA02.svg";




const BusinessLoansSoleProprietorships = () => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = decodedPathname === '/bedrift/bedriftslån-enkeltmannsforetak';

  return(
      <React.Fragment>
          <BusinesStaticPageBanner data={bannerData} isBusiness={isBusiness} vippsBtnHide />
          <ColSection detail={data}  />
          <StaticContentWithImg detail={contentWithImgData} />
          <AdSectionRightImg detail={adSectionRightImgData} />
          <StaticTagList data={tagList} />
          <CallToAction data={callToActionData} />
      </React.Fragment>
  )
}

export default BusinessLoansSoleProprietorships;