import React, { useEffect, useState } from "react";
import {FormGroup } from "react-bootstrap";
import Button from "../../Components/Button";
import classes from './index.module.scss';
import SectionTitle from "../../Components/SectionTitle";
import Thankyou from "../../Images/Thankyou.svg";
import { onError } from "Services/api/Image";
import { LoginService, signicatLogin } from "Services/api/Login";
import { Alert } from "react-bootstrap";
import { vippsLogin } from "../../Services/api/Login";

const LoginPersonal = () => {
    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [showResetForm, setShowResetForm] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [authObject, setAuthObject] = useState({});
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const authString = localStorage.getItem('auth');
        const auth = JSON.parse(authString !== '' && authString ? authString : '{}');
        setAuthObject(auth);
        if(auth?.jwToken && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()){
            window.location.href = '/privatdashbord';
        }
    }, []);

    const _handleBankId = () => {
        signicatLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }
    const _handleVippsLogin = () => {
        vippsLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }

    if (showThankYou) {
        return (
            <React.Fragment>
                <SectionTitle textCenter description={'You have successfully registered. Please Check your email and verify your email address.'}>
                    <span className={'fw-normal'}>Congratulation</span>
                </SectionTitle>

                <div className={'p-3'}>
                    <div className={'position-relative text-center'}>
                        <div className={'text-center mb-5'}>
                            <img src={Thankyou} loading="lazy" onError={onError} alt={''} />
                        </div>
                        <Button
                            text={'Go to Dashboard'}
                            url={'/privatdashbord'}
                            variant={'darkBorder'}
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>

            <div className={`${classes.rowHolder} ${classes.smallForm}`}>
                <div className={classes.col}>
                    {!showResetForm ? (
                        <React.Fragment>
                            <h2>Logg inn</h2>
                            <p className={'mb-3'}>Logg inn og få enda mer oversikt over egen økonomi.</p>
                            <ul className={'checkList inline'}>
                                <li>Gratis kredittsjekk</li>
                                <li>Gratis Boligverdi</li>
                                <li>Gjeldsoversikt</li>
                            </ul>
                            <FormGroup className={classes.btnRow}>
                                <Button
                                    text={'Logg inn med Vipps'}
                                    variant={'vipps  w-100'}
                                    onClick={_handleVippsLogin}
                                />
                            </FormGroup>
                            {authObject.errorResponse && authObject.errorResponse !== '' ? <Alert variant='danger'>{authObject?.errorResponse}</Alert> : <></>}
                        </React.Fragment>
                    ) : null}
                </div>
            </div>

            <div className={classes.authFooter}>
                <p>Jeg samtykker til at EasyFinance.no kan innhente min gjeldsinformasjon fra Gjeldsregisteret for å analysere mitt sparepotensial. Dette samtykket er gyldig i tolv måneder. Jeg kan når som helst trekke tilbake mitt samtykke på gjeldsregisteret.com. Når jeg logger inn på min konto på EasyFinance.no, vil EasyFinance automatisk oppdatere mine data fra Creditsafe. Jeg bekrefter også at jeg har lest og forstått EasyFinances <a href={'/personvernpolicy'} target={"_blank"} className={'text-blue'}>brukervilkår</a>.</p>
                <p>
                Logg inn med
                    <Button
                        onClick={_handleBankId}
                        variant={'link'}
                        icon={'icon-BankID'}
                        underline
                    />
                </p>
            </div>
        </React.Fragment>
    );
}

export default LoginPersonal;

