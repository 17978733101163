import React, { useEffect, useState } from "react";
import { Alert, Row, Spinner } from "react-bootstrap";
import classes from "./index.module.scss";
import DashboardCards from "./DashboardComponent/DashboardCards";
import SavedSearches from "./DashboardComponent/SavedSearches";
import CreditScore from "../../Components/Charts/CreditScore";
import RecommendedOffers from "./DashboardComponent/RecommendedOffers";
import YourDebt from "../../Components/Charts/YourDebt";
import LoginWithBankID from "./DashboardComponent/DebtCheckConsent";
import { loadAllSearches } from "Services/api/LoanSearch";
import { getConsumerReport } from "../../Services/api/Auth";
import { loadGjeldsregisteretData } from "../../Services/api/Loan";
import moment from "moment";
import Loader from "../../Components/Loader";
import DebtCheckConsentPopup from "./DashboardComponent/DebtCheckConsent/DebtCheckConsentPopup";
import Button from "../../Components/Button";
import hiImg from "../../Images/hiImg.svg";
import houseNorFound from "../../Images/houseNorFound.svg";
import DebtCheckDataLoad from "./DashboardComponent/DebyCheckDataLoad";
import SocialMediaShare from "../../Components/SocialMediaShare";
import HouseValueChart from "../../Components/Charts/HouseValueChart";
import {
  getCustomerRealEastate,
  getMarketeEstimate,
  getPropertyEvEstimateBasis,
} from "../../Services/api/RealEstate";
import Loading from "../../Components/Loading";

const UserDashboard = () => {
  const languageId = 1;
  const [showBankIDModal, setShowBankIDModal] = useState(true);
  const [searches, setSearches] = useState([]);
  const [user, setUser] = useState({});
  const [debt, setDebt] = useState({});
  const [loggedInUser, setLoggedInUser] = useState({});
  const [updatedOn, setUpdatedOn] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingDebtData, setLoadingDebtData] = useState(false);
  const [debtDataLoaded, setDebtDataLoaded] = useState(false);
  const [allProperties, setAllProperties] = useState([]);
  const [propertyLoading, setPropertyLoading] = useState(false);

  const [defaultProperty, setDefaultProperty] = useState();
  const [marketeEstimate, setMarketeEstimate] = useState();
  const [propertyEstimateBasis, setPropertyEstimateBasis] = useState();
  const [approvedCredit, setApprovedCredit] = useState();
  const [approvedLoan, setApprovedLoan] = useState();

  useEffect(() => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    if (
        auth?.jwToken &&
        auth?.expiresOn &&
        new Date(auth?.expiresOn) > new Date()
    ) {
      if (auth?.loadDebt && auth?.consent) _loadDebtData();
      setLoggedInUser(auth);
      setLoading(true);

      Promise.all([
        loadAllSearches(languageId, auth?.jwToken)
            .then((resp) => {
              setSearches(resp.data);
            })
            .catch(console.error),
        getConsumerReport().then(setUser),
        getCustomerRealEastate().then(({ data }) => {
          const currentAddress = data[0];
          const filterObj = {
            organizationNumber:
            currentAddress?.housingCooperativeShareIdentity
                ?.housingCooperative?.organizationNumber,
            shareNumber:
            currentAddress?.housingCooperativeShareIdentity?.shareNumber,
            knr: currentAddress?.cadastre?.kNr,
            gnr: currentAddress?.cadastre?.gNr,
            bnr: currentAddress?.cadastre?.bNr,
            fnr: currentAddress?.cadastre?.fNr,
            snr: currentAddress?.cadastre?.sNr,
          };
          setAllProperties(data);
          setDefaultProperty(currentAddress);
          getMarketeEstimate(filterObj)
              .then(({ data }) => {
                setMarketeEstimate(data);
              })
              .catch((err) => console.log(err));
          getPropertyEvEstimateBasis(filterObj)
              .then(({ data }) => {
                setPropertyEstimateBasis(data);
              })
              .catch((err) => {
                setPropertyEstimateBasis(null);
              });
          _getTotalDebt();
        }),
      ]).finally(() => setLoading(false));
    } else {
      window.location.href = "/login";
    }
  }, []);
  const _loadDebtData = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem("auth", JSON.stringify({ ...auth, loadDebt: true }));
    loadGjeldsregisteretData()
        .then((resp) => {
          setDebtDataLoaded(true);
          setDebt(resp?.debtInformation);
          _getTotalDebt(resp?.debtInformation);
          setUpdatedOn(
              moment(resp?.timestamp?.split("T")?.at(0)).format("DD-MM-YYYY")
          );
        })
        .catch(console.error)
        .finally(() => setLoadingDebtData(false));
  };
  const closeBankIDModal = () => {
    setShowBankIDModal(false);
  };
  const format = (value) => {
    const formatter = Intl.NumberFormat("no-NO");
    if (isNaN(value)) return 0;
    return formatter.format(value);
  };
  const _getTotalDebt = async () => {
    const resp = await loadGjeldsregisteretData();
    const debt = resp?.debtInformation;
    const loans = debt?.loanTypes
        ?.filter(({ type }) => type === "repaymentLoan")
        .flatMap(({ loans }) => loans);
    const cards = debt?.loanTypes
        ?.filter(({ type }) => type === "chargeCard" || type === "creditFacility")
        .flatMap(({ loans }) => loans);
    const originalBalance = loans
        ?.map(({ balance }) => (isNaN(Number(balance)) ? 0 : Number(balance)))
        ?.reduce((a, b) => a + b, 0);
    const totalCredit = cards
        ?.map(
            ({ nonInterestBearingBalance, interestBearingBalance }) =>
                (isNaN(interestBearingBalance) ? 0 : Number(interestBearingBalance)) +
                (isNaN(nonInterestBearingBalance)
                    ? 0
                    : Number(nonInterestBearingBalance))
        )
        ?.reduce((a, b) => a + b, 0);
    setApprovedCredit(Math.round(totalCredit / 100));
    setApprovedLoan(Math.round(originalBalance / 100));
  };
  return (
      <React.Fragment>
        {loading ?
            <Loading style={{position: 'absolute'}} /> : <>
              <div className={"font-12 text-right mb-2"} style={{ right: 0 }}>
                {" "}
                Oppdatert{" "}
                {user?.lastFetchedDate && user?.lastFetchedDate !== ""
                    ? moment(user?.lastFetchedDate).format("DD/MM/YYYY")
                    : "-"}
              </div>
              <Row
                  className={`${classes.dashboardCards} ${classes.col2_1} ${classes.reverseRowMobile} pt-3`}
              >
                <div className={classes.col}>
                  <h5>
                    Hei {loggedInUser?.firstName} {loggedInUser?.lastName}{" "}
                  </h5>
                  <p>
                    EasyFinance gir deg en enkel oversikt over din personlige økonomi.
                    Med denne tjenesten kan du få innsikt i dine økonomiske data helt
                    gratis. Ved å forstå din egen økonomiske situasjon og hvordan banker
                    vurderer deg som kunde, kan du bedre forhandle frem gunstige
                    avtaler. Målet vårt er å gi deg verktøyene og ressursene du trenger
                    for å ta informerte beslutninger om din økonomi.
                  </p>
                </div>
                <div className={classes.col}>
                  <div className={classes.imgBox} style={{ textAlign: "center" }}>
                    <img src={hiImg} loading="lazy" />
                  </div>
                </div>
              </Row>
              <div className={classes.contentBox}>
                <Row className={`${classes.dashboardCards} ${classes.col_3}`}>
                  <div className={classes.col}>
                    <div className={classes.box}>
                      {!loading ? (
                          <>
                            <React.Fragment>
                              <CreditScore score={user?.score} />
                              <Button
                                  text={"Min Score"}
                                  variant={"border w-100"}
                                  url={"/privat/kredittsjekk"}
                              />
                            </React.Fragment>
                          </>
                      ) : (
                          <Loader style={{ minHeight: "200px", position: "relative" }} />
                      )}
                    </div>
                  </div>
                  <div className={classes.col}>
                    {!loggedInUser?.consent && (
                        <DebtCheckConsentPopup onCloseModal={closeBankIDModal} />
                    )}
                    <div
                        className={`${classes.box} ${
                            !loggedInUser?.consent && classes.blur
                        }`}
                    >
                      {!loading ? (
                          debtDataLoaded ? (
                              <React.Fragment>
                                <YourDebt debt={debt} />
                                <Button
                                    text={"Gjeldsoversikt"}
                                    variant={"border w-100"}
                                    url={"/privat/min-gjeld"}
                                />
                              </React.Fragment>
                          ) : (
                              <DebtCheckDataLoad
                                  onClick={_loadDebtData}
                                  loadingDebtData={loadingDebtData}
                              />
                          )
                      ) : (
                          <Loader style={{ minHeight: "200px", position: "relative" }} />
                      )}
                    </div>
                  </div>
                  {/* TODO: PRODUCTION */}
                  <div className={classes.col}>
                    <div className={classes.box}>
                      {!loading ? <>
                        {allProperties?.length > 0 ? (
                            <HouseValueChart
                                small
                                estimate={
                                    marketeEstimate && marketeEstimate?.approvedNetMarketValue
                                }
                                totalLoan={approvedCredit + approvedLoan}
                                totalLoanLimit={
                                    (marketeEstimate?.approvedNetMarketValue ?? 0) * 0.85
                                }
                                commonDebt={propertyEstimateBasis?.currentCommondebt}
                            />
                        ) : (
                            <div className={classes.houseNotFound}>
                              <div className={classes.imgBox}>
                                <img src={houseNorFound} loading="lazy" />
                              </div>
                              <h3>Ingen eiendom funnet</h3>
                              <p>Vi klarer dessverre ikke å finne at du står som eier av en bolig i Norge. Eller kan det være at du kanskje ikke har oppfylt boligdrømmen enda? </p>
                            </div>
                        )}
                      </> : <>
                        <Loader style={{ minHeight: "200px", position: "relative" }} />
                      </>}
                    </div>
                  </div>
                </Row>
                <RecommendedOffers />
                {/*<DashboardCards searchCount={searches?.length}/>*/}
                {/*<SavedSearches searches={searches}/>*/}
                <SocialMediaShare
                    PostUrl={"https://easyfinance.no"}
                    PostTitle={
                      "Sjekk ut denne nettsiden! Gratis kredittsjekk, gjeldsoversikt og boligverdi. Få bedre forståelse av din økonomi!"
                    }
                />
              </div>
            </>
        }
      </React.Fragment>
  );
};

export default UserDashboard;
