export const getAllLenders = language => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/Lender/GetAll?LanguageId=${language}`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp.json())
    })
    .catch(err => reject(err));
})

export const getLender = title => new Promise((resolve, reject) => 
    fetch(`${process.env.REACT_APP_API}/Lender/GetLenderByTitle?Title=${title}`)
    .then(resp => resp.status === 200 ? resolve(resp.json()) : reject(resp))
    .catch(err => reject(err))
)