import React from 'react';
import classes from './index.module.scss';
import { Container, Row, Tab, Tabs, Col } from 'react-bootstrap';
import SectionTitle from '../SectionTitle';
import Button from '../Button';


const TabsWithInnerImage = ({data}) => {
    const { sectionTitle, tabs } = data;

    return (
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <SectionTitle
                        textCenter={sectionTitle.textCenter}
                        description={sectionTitle.description}
                    >
                        {sectionTitle.titles.map(title => (
                            <span className={title.class} key={title.id}>{title.title}</span>
                        ))}
                    </SectionTitle>
                    <div className={classes.tabHolder}>
                        <Tabs defaultActiveKey={tabs[0].key} className={classes.navTabs}>
                            {tabs.map(tab => (
                                <Tab key={tab.key} eventKey={tab.key} title={tab.title}>
                                    <Row className={'align-items-center'}>
                                        <Col md={7}>
                                            <h2>{tab.heading}</h2>
                                            <p className={'mb-5'}>{tab.description}</p>
                                            {tab.checklist.length > 0 && (
                                                <ul className={'checkList circle yellow mb-5'}>
                                                    {tab.checklist.map((item, index) => (
                                                        <li key={index}>{item}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            {tab.btnText &&
                                                <Button
                                                    text={tab.btnText}
                                                    url={tab.learnMoreUrl}
                                                    variant={'dark'}
                                                    icon={'far fa-arrow-right'}
                                                />
                                            }
                                        </Col>
                                        <Col md={5}>
                                            <div className={classes.imgBox}>
                                                <img src={tab.imgSrc} loading="lazy" alt={tab.title} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default TabsWithInnerImage;
