import React, { useState } from "react";
import classes from "../index.module.scss";
import { Button } from "react-bootstrap";

const SortFilter = ({ handleSort, sorting, currentSorting }) => {
    const handleButtonClick = (property, isAscending) => {
        if(handleSort)
        handleSort(property, isAscending);
    };

    return (
        <React.Fragment>
            <div className={classes.filterBar}>
                {sorting?.filter(sort => sort.ascending)?.map((sort, index) => <div className={classes.col} key={index}>
                    <Button onClick={() => handleButtonClick(sort.property, currentSorting?.property === sort.property ? !currentSorting?.ascending : true)}>{sort?.bannerLabel} {sort?.property === currentSorting?.property && <i className={currentSorting.ascending ? 'far fa-arrow-up' : 'far fa-arrow-down'}></i>} </Button>
                </div>)}
            </div>
        </React.Fragment>
    );
};

export default SortFilter;
