import React from "react";
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import {Link} from "react-router-dom";
import data from "./data";

const TagList = ({ detail }) => {
  return(
      <React.Fragment>
          {detail &&
              <section className={'section'}>
                  <Container>
                      <ul className={classes.tags}>
                          {detail?.map((item, index) => (
                              <li key={index}><a href={item.url}>{item.content}</a></li>
                          ))}
                      </ul>
                  </Container>
              </section>
          }
      </React.Fragment>
  )
}

export default TagList;