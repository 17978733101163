import img from '../../../Images/img40.png'

const staticData = [
  {
    id: 1,
    heading: {
      description: "",
      title: [
        { id: 1, title: "Slik fungerer", class: "fw-normal" },
        { id: 2, title: "bedriftslån", class: "fw-semibold" }
      ]
    },
    content: [
      { title: "Rask og enkel søknadsprosess", description: "Fullfør søknaden på bare ett minutt og få rask respons." },
      { title: "Umiddelbar utbetaling", description: "Når du signerer med BankID, blir lånet utbetalt øyeblikkelig." },
      { title: "Ingen forpliktelser ", description: "Å søke er kostnadsfritt og du er ikke forpliktet til å akseptere tilbudet. " },
      { title: "Ingen etableringsgebyr", description: " u betaler ikke for oppstart av lånet. " },
      { title: "Full transparens ", description: "Det er ingen skjulte avgifter eller bindingstid med våre lån. " }
    ],
    redirectUrl: "https://example.com",
    featuredImage: img
  }
];

export default staticData;
