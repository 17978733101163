import React from 'react';
import classes from './index.module.scss';
import SocialLinks from "../SocialLinks";
import {useSelector} from "react-redux";

const CopyRight = ({detail}) => {
    const { settings } = useSelector(state => state.components);
    const appSettings = useSelector(state => state.settings.settings);

  return(
      <React.Fragment>
          <div className={classes.copyRight}>
              <div className={classes.col}>
                  {appSettings?.find(s => s?.variableName === 'footerLeftSideText')?.value}
              </div>
              <div className={classes.col}>
                  <SocialLinks detail={detail} />
              </div>
              <div className={classes.col}>
                  {appSettings?.find(s => s?.variableName === 'footerRightSideText')?.value}
              </div>
          </div>
      </React.Fragment>
  )
}

export default CopyRight;