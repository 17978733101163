import React, {useEffect, useState} from 'react';
import {Text, View, StyleSheet, Font} from '@react-pdf/renderer';
import MonaRegular from "../../../Fonts/Mona-Sans-Regular.woff";
import MonaBold from "../../../Fonts/Mona-Sans-Bold.woff";



Font.register({
    family: 'Mona',
    fonts: [
        {src: MonaRegular, fontWeight: 'normal'},
        {src: MonaBold,fontWeight: 'bold'}
    ]
})

// Create styles
const styles = StyleSheet.create({
    pageContent: {display: "flex", flexDirection: 'column', justifyContent: 'center',},
    title: {fontSize: 20, fontWeight: 'bold', color: '#013367', display: 'block', width: '100%',},
    ssnNumber: {fontSize: 14, padding: '10px 10px 2px', backgroundColor: '#DEE2E5', margin: '100px 0 20px', display: 'inline-block', borderRadius: 5, maxWidth: 250, fontWeight: 'bold',},
    date: {fontSize: 11, display: 'block',},
    small: {display: "flex", flexDirection: 'column', justifyContent: 'center',color: '#000', fontSize:7, marginBottom: 20},
    smallTitle:{fontSize:9},
    smallSsnNumber: {},
    smallDate: {}

});

// Create Document Component
const UserDetail = ({small, userData}) => {

    const formatSSN = (ssn) => {
        if (ssn.length <= 6) return ssn;
        return `${ssn.slice(0, 6)} ${ssn.slice(6)}`;
    };

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };
    const currentDate = formatDate(new Date());

    return(
        <React.Fragment>
            {small ?
                <View style={styles.small}>
                    <Text style={styles.smallTitle}>{`${userData.firstName} ${userData.lastName}`}</Text>
                    <Text style={styles.smallSsnNumber}>{`Fødselsnummer: ${formatSSN(userData.userName)}`}</Text>
                    <Text style={styles.smallDate}>Rapport produsert den {currentDate}</Text>
                </View>
                :
                <View style={styles.pageContent}>
                    <Text style={styles.title}>{`${userData.firstName} ${userData.lastName}`}</Text>
                    <Text style={styles.ssnNumber}>{`Fødselsnummer: ${formatSSN(userData.userName)}`}</Text>
                    <Text style={styles.date}>Rapport produsert den {currentDate}</Text>
                </View>
            }
        </React.Fragment>
    )
};

export default UserDetail;
