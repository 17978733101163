import React from "react";
import {Container} from "react-bootstrap";
import data from "./data";




const BusinessThreeCol = () => {

    return(

        <React.Fragment>
            <section className={'section topOverlap p-0'}>
                <Container>
                    <div className={`colList col3 round border-0 shadow`}>
                        {data.map((item, index) => (
                            <div className={'col'} key={index}>
                                <a href={item.url} className={'box p-4'}>
                                    <h2 className={'title font-22 mb-0'}>{item.title}</h2>
                                    <p className={'mb-0'}>{item.description}</p>
                                </a>
                            </div>
                        ))}
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default BusinessThreeCol;