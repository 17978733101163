import React from 'react';
import InnerPageLayout from "./InnerPageLayout";
import {Page, Text, View, Svg, Line, StyleSheet, Image} from '@react-pdf/renderer';



const Identification = () => {
  const styles = StyleSheet.create({
    contentList:{ fontSize : 9, flexDirection: 'column', width: '100%', color: '#013367'},
    list:{flexDirection: 'row',width: '100%',borderLeft: '3px solid #DEE2E5',marginBottom: '1px'},
    col:{padding:'10px 10px 7px',},
    bgGray: {backgroundColor: '#F8F8F7',color: '#4B4B4B', width:'40%'},
  });
  return(
      <InnerPageLayout title={'01. Identifikasjon'}>
        <View  style={styles.contentList}>
          <View style={styles.list}>
            <Text style={[styles.col, styles.bgGray]}>Company name</Text>
            <Text style={styles.col}>Lafacil AS</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Company number</Text>
            <Text style={styles.col}>819823952</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Safe number</Text>
            <Text style={styles.col}>N025962484</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Registration date</Text>
            <Text style={styles.col}>31/10/2017</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Foundation date</Text>
            <Text style={styles.col}>24/08/2017</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Legal form</Text>
            <Text style={styles.col}>Limited company</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Status</Text>
            <Text style={styles.col}>Active</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Share capital</Text>
            <Text style={styles.col}>50,000</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Register of business enterprises</Text>
            <Text style={styles.col}>Yes</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Registered for VAT</Text>
            <Text style={styles.col}>Yes</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>VAT number</Text>
            <Text style={styles.col}>N0819823952MVA</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Industry</Text>
            <Text style={styles.col}>62010 Computer programing activities</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Address</Text>
            <Text style={styles.col}>Strømsveien 323A</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Postal code</Text>
            <Text style={styles.col}>1081</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>City</Text>
            <Text style={styles.col}>Oslo</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Telephone</Text>
            <Text style={styles.col}>-</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Main industry (Code & Description)</Text>
            <Text style={styles.col}>62.010 Computer programming activities</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Secondary industry (Code & Description)</Text>
            <Text style={styles.col}>-</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Other industry (Code & Description)</Text>
            <Text style={styles.col}>-</Text>
          </View>
          <View style={[styles.list]}>
            <Text style={[styles.col, styles.bgGray]}>Sector (Code & Description)</Text>
            <Text style={styles.col}>2100 Private non-financial incorporated enterprises</Text>
          </View>
        </View>
      </InnerPageLayout>
  )
}

export default Identification;