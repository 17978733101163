import iconImg from '../../Images/er-logo-dagligbank-nordea.svg';


const data = [
    {
        title: 'nordea',
        image: iconImg,
        description: 'Hundreds of companies use our services, large and small. With over 10 years of experience in price and product comparison.',
        status: true,
        timeStamp: '17 hours ago',
    },
    {
        title: 'nordea',
        image: iconImg,
        description: 'Hundreds of companies use our services, large and small.',
        status: false,
        timeStamp: '17 hours ago',
    },
    {
        title: 'nordea',
        image: iconImg,
        description: 'Hundreds of companies use our services. With over 10 years of experience in price and product comparison.',
        status: true,
        timeStamp: '17 hours ago',
    },
    {
        title: 'nordea',
        image: iconImg,
        description: 'Hundreds of companies use our services, large and small. With over 10 years of experience ',
        status: true,
        timeStamp: '17 hours ago',
    },
    {
        title: 'nordea',
        image: iconImg,
        description: 'Hundreds of companies use our services, large and small. With over 10 years of experience in price and product comparison. Show all banks',
        status: true,
        timeStamp: '17 hours ago',
    },

]

export default data;