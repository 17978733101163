import React from "react";
import Button from "../../../../../../Components/Button";
import classes from "./index.module.scss";

const RecommendedCards = ({ data, activeCategory, faqsData }) => {
    const activeCategoryDataType = activeCategory?.dataType || '';

    return (
        <React.Fragment>
            <div className={`${classes.row}`}>
                {data && data.map(item => {

                    return (
                        <React.Fragment key={item.dataType}  id={item.dataType}>
                            <div className={`${classes.rowHolder} ${activeCategoryDataType === 'Kredittkort' ? classes.specialCard : ''}`} id={item.dataType}>
                                {item.featured && <div className={classes.featured}>Anbefale</div>}
                                <div className={classes.rowHeader}>
                                    <div className={classes.holder}>
                                        <div className={classes.logoBox}>
                                            <a href={item.btnUrl}>
                                                <img src={item.image} loading="lazy" alt={'#'} />
                                            </a>
                                        </div>
                                        {activeCategoryDataType && <h4 className={'text-capitalize'}><a href={item.btnUrl}>{item.name}</a> </h4>}
                                        {/*<p>{item.leverandorTekst}</p>*/}
                                    </div>
                                </div>
                                <div className={classes.rowBody}>
                                    {activeCategoryDataType === 'Forbrukslån' && <>
                                        <div className={`${classes.holder} ${classes.hoderExtra}`} style={{minHeight: "180px"}}>
                                            <ul className={classes.list}>
                                                <li>
                                                    <div className={classes.col}>Lånebeløp</div>
                                                    <div className={classes.col}>{item.loanAmount}</div>
                                                </li>
                                                <li>
                                                    <div className={`${classes.col} text-left font-16`} style={{letterSpacing: '0.5px'}}>{item.leverandorTekst}</div>
                                                </li>
                                            </ul>
                                            <div className={classes.rowBtnCol}>
                                                <div className={'font-10 text-center fw-bold mb-2'}>{item.affiliateType}</div>
                                                <Button
                                                    text={'Søk nå'}
                                                    icon={'far fa-arrow-right'}
                                                    url={item.btnUrl}
                                                    target={"_blank"}
                                                    variant={' mb-2'}
                                                />
                                                <div className={'font-11 py-2 text-center  fw-medium mb-2'} style={{lineHeight: '13px'}}>Kredittscore-basert rente fra:</div>
                                                <div className={classes.percentAgeBox}>{item.nominalInterestRate}</div>
                                            </div>
                                        </div>
                                        <div className={classes.rowFooter}>
                                            <div className={classes.footerText}>
                                                {item.loanExample}
                                            </div>
                                        </div>
                                    </>  }
                                    {activeCategoryDataType === 'Boliglån' && <>
                                        <div className={`${classes.holder} ${classes.hoderExtra}`} style={{minHeight: "180px"}}>
                                            <ul className={classes.list}>
                                                <li>
                                                    <div className={classes.col}>Lånebeløp</div>
                                                    <div className={classes.col}>{item.loanAmount}</div>
                                                </li>
                                                <li>
                                                    <div className={`${classes.col} text-left font-16`} style={{letterSpacing: '0.5px'}}>{item.leverandorTekst}</div>
                                                </li>
                                            </ul>
                                            <div className={classes.rowBtnCol}>
                                                <div className={'font-10 text-center fw-bold mb-2'}>{item.affiliateType}</div>
                                                <Button
                                                    text={'Søk nå'}
                                                    icon={'far fa-arrow-right'}
                                                    url={item.btnUrl}
                                                    target={"_blank"}
                                                    variant={' mb-2'}
                                                />
                                                <div className={'font-11 py-2 text-center  fw-medium mb-2'} style={{lineHeight: '13px'}}>Kredittscore-basert rente fra:</div>
                                                <div className={classes.percentAgeBox}>{item.nominalInterestRate}</div>
                                            </div>
                                        </div>
                                        <div className={classes.rowFooter}>
                                            <div className={classes.footerText}>
                                                {item.loanExample}
                                            </div>
                                        </div>
                                    </>  }
                                </div>
                            </div>
                        </React.Fragment>
                    )
                })}
            </div>
            <div className={classes.text}>
                {faqsData && faqsData.map((faq, index) => (
                    <>
                        <h4>{faq.heading}</h4>
                        <ul className={classes.faqList}>
                            {faq.list.map((faqItem, faqIndex) => (
                                <li key={faqIndex}>
                                    {faqItem.title && <h6>{faqItem.title}</h6> }
                                    <p>{faqItem.description}</p>
                                </li>
                            ))}
                        </ul>
                    </>
                ))}
            </div>
        </React.Fragment>

    );
};

export default RecommendedCards;
