const bannerData =  [
    {
      id: 1,
      subTitle: "Grønt lån",
      title: [
        { id: 1, title: "Lån for små og mellomstore", class: "fw-normal" },
        { id: 3, title: "bedrifter", class: "fw-bold" }
      ],
      description:
          "Hos EasyFinance støtter vi din bedrifts grønne ambisjoner med våre tilpassede Grønne Lån. Trenger du finansiering for å implementere bærekraftige løsninger? Vi tilbyr lån fra 10.000 kr til 2 millioner kr, designet for å hjelpe din bedrift å vokse på en miljøvennlig måte. Start reisen mot en grønnere fremtid med EasyFinance og realiser dine bærekraftige prosjekter i dag!"
    }
  ]


export default bannerData;
