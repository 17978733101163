import React from 'react';
import classes from "./index.module.scss";


const TwoColTextWithTopImg = ({ detail }) => {
  return(
      <React.Fragment>
          <div className={`${classes.colPost}`}>
              {detail.map((item, index) => (
                  <div className={classes.col} key={index}>
                      <div className={classes.box}>
                          <div className={classes.imgBox}>
                              <img src={item.image} alt={item.title} loading="lazy" alt={item.title} />
                          </div>
                          <div className={classes.description}>
                              {item.url ? <h4 className={classes.title}><a href={item.url}>{item.title}</a></h4> : <h4 className={classes.title}>{item.title}</h4> }
                              {/*<h4 className={classes.title}>{item.title}</h4>*/}
                              <p>{item.content}</p>
                              <a href={item.url}></a>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </React.Fragment>
  )
}

export default TwoColTextWithTopImg;