import unofinans from "../../../../../Images/unofinans.svg";
import moty from "../../../../../Images/motty.svg";
import nanoFinance from "../../../../../Images/Nanofinans.svg";
import okidaLogo from "../../../../../Images/okidaLogo.svg";
import tfBank from '../../../../../Images/tf-bank-mastercard.png';
import remember from '../../../../../Images/remember.jpg';
import morrowBank from '../../../../../Images/morrowbank_mastercard1.svg';
import instaBank from '../../../../../Images/instabank_instapay.png';
import bankNorwegian from '../../../../../Images/banknorwegian.svg';
import creditcardcollection from '../../../../../Images/creditcardcollection.svg';
import spareBank from "../../../../../Images/SpareBank.svg";
import nordea from "../../../../../Images/nordeaLogo.svg";

const data = [
    {
        dataType: 'Boliglån',
        title: "Oppussingslån med sikkerhet i bolig",
        averageRent: "5,60",
        faqs: [
            {
                heading: "Tips for verdiøkning",
                list: [
                    {
                        title: "Velg materialer og løsninger som passer din bolig",
                        description: "Det kan være lurt å bevare noe av den \"gamle sjarmen\", for eksempel.",
                    },
                    {
                        title: "Sørg for å gjennomføre oppussingen fullstendig",
                        description: "Fullfør oppussingen helt før du avslutter.",
                    },
                    {
                        title: "Utnytt arealet i boligen din.",
                        description: "Har du en kjeller eller loft som kan øke verdien?",
                    },
                    {
                        title: "Tilskuddsordninger for energieffektivisering?",
                        description: "Et energieffektivt hjem er bra når strømmen er dyr.",
                    },
                    {title: "Mal vegger/overflater", description: "Maling endrer rommets karakter. Lyse farger gir romslighet, mørkere skaper varme. En enkel og billig oppgradering.",},
                    {title: "Kan du lage flere soverom?", description: "Flere soverom kan øke verdien av boligen din og gjøre den mer attraktiv for flere kjøpere.",},
                    {title: "Ildsted", description: "Har du et godkjent pipeløp, kan installering av peis/vedovn øke boligens verdi enkelt og rimelig. Det krever ingen søknad, men må rapporteres til Feiervesenet. God investering når strømmen er så dyr.",},
                    {title: "Bytt ut skap- og skuffefronter", description: "Ved å oppgradere skap- og skuffefronter på bad, kjøkken og i garderoben, kan du fornye boligens utseende rimelig. Sliping, maling eller bare bytte av armatur er også gode alternativer.",},
                ]
            }
        ],
        childData: [
            {
                name: "",
                image: spareBank,
                leverandorTekst: 'EasyFinance anbefaler Sparebank 1 SR-Bank for boliglån grunnet deres pålitelighet og dekning av medlemmers behov. De tilbyr konkurransedyktige vilkår, spesielt gunstige for LO-medlemmer og førstegangskjøpere, noe som gjør dem til et ideelt valg.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '5,60%',
                featured: false,
                loanExample: 'Låneeksempel: Effektiv rente 5.97% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 788 775.',
                btnUrl: "https://www.sparebank1.no/nb/smn/privat/lan/boliglan.html ",
                affiliateType: "",
            },
            {
                name: "",
                image: nordea,
                leverandorTekst: 'EasyFinance anbefaler Nordea for boliglån, da de er en landsdekkende og pålitelig bank som dekker våre medlemmers varierte behov. Nordea tilbyr konkurransedyktige vilkår og fleksibilitet, samt moderne tjenester som ApplePay, noe som gjør dem til et godt valg for våre medlemmer.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '6.05%',
                featured: false,
                loanExample: ' Låneeksempel: Effektiv rente 5.70% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 703 643.',
                btnUrl: "https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/sok-om-boliglan.html",
                affiliateType: "",
            },
        ]
    },
    {
        dataType: 'Forbrukslån',
        title: "Oppussingslån uten bolig",
        averageRent: "9,59",
        faqs: [
            {
                heading: "Tips for verdiøkning",
                list: [
                    {title: "Velg materialer og løsninger som passer din bolig", description: "Det kan for eksempel lønne seg å beholde noe \"eldre sjarm\".",},
                    {title: "Undersøk tilskuddsordninger for energieffektivisering", description: "Et energieffektivt hjem er bra når strømmen er dyr.",},
                    {title: "Sørg for å gjennomføre oppussingen fullstendig", description: "Ikke gi deg med oppussingen før du er helt ferdig.",},
                    {title: "Kan du lage flere soverom?", description: "Flere soverom kan gjøre en bolig aktuell for flere kjøpere.",},
                    {title: "Er det mulig med utleiedel i boligen?", description: "Å leie ut rom eller hybel sikrer inntekt.",},
                    {title: "Bruk nøytrale farger og løsninger hvis målet er å selge", description: "Mange vil uansett sette et eget personlig preg på en bolig.",},
                    {title: "Installering av peis", description: "Peis vil mange ha, og varmer rimelig når strømmen er dyr.",},
                    {title: "Bygge balkong", description: "Omfattende, men kan være lønnsomt på sikt.",},
                    {title: "Har du en kjeller eller et loft som kan gi merverdi?", description: "Utnytt arealet i boligen din.",},
                    {title: "Velstelt hage", description: "En hage som gir nytteverdi og et godt førsteinntrykk.",},
                ]
            }
        ],
        childData: [
            {
                name: "",
                image: unofinans,
                leverandorTekst: 'EasyFinance samarbeider med Uno Finans for at våre medlemmer skal få de beste lånevilkårene. Unofinans tilbyr en svært enkel og brukervennlig lånesøknadsprosess, hvor du med bare én søknad kan motta flere tilbud.',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '7.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 12.71% ved lån på 150 000 kr over 5 år. Totalt: kr 199 237.',
                btnUrl: "https://ormedbyte.com/click.track?CID=430720&AFID=529108",
            },
            {
                name: "",
                image: moty,
                leverandorTekst: 'Motty kobler deg med over 20 ledende banker for å finne de beste lånetilbudene, raskt og sikkert. Med erfaring siden 1985, garanterer Motty kvalitet og kundetilfredshet. Nyt gratis veiledning og en enkel prosess. For personlig rådgivning, kontakt Motty.',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 14.15% ved lån på 100 000 kr over 5 år. Totalt: kr 137 120.',
                btnUrl: "https://aslinkhub.com/?bid=2529826&media_id=100963",
            },
            {
                name: "",
                image: nanoFinance,
                leverandorTekst: "Nanofinans, del av Sambla og en av Norges ledende finansagenter, sender søknaden din til 23 långivere for å sikre deg beste rente og vilkår. Motta tilbud raskt og uforpliktende.",
                rating: '4.5',
                loanAmount:'10 000 kr - 600 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.3%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 13.14% ved lån på 200 000 kr over 5 år. Totalt: kr 269 078.',
                btnUrl: "https://corlmedi.com/click.track?CID=457217&AFID=529108",

            },
        ]
    },
]

export default data;