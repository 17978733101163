const bannerData =  [
    {
      id: 1,
      subTitle: "",
      title: [
        { id: 1, title: "Kredittsjekk for å ", class: "fw-normal" },
        { id: 2, title: "unngå tap på dårlige betalere", class: "fw-bold" },
      ],
      description:
      <>
          <p>Gjør gode valg for vellykkede transaksjoner.</p>
      </>,
        btnText: "",
        btnUrl: "",
        hideCalculator: true,

    }
  ]


export default bannerData;
