import React from 'react';
import { Table } from "react-bootstrap";
import classes from './index.module.scss';

const Tables = ({ detail }) => {
    return (
        <React.Fragment>
            <h1 id={detail?.heading?.toLowerCase()?.replaceAll(' ', '-')}>{detail?.heading}</h1>
            <p>{detail?.description}</p>
            <div className={classes.tableHolder}>
                <Table>
                    <thead>
                    <tr>
                        {detail?.tableHeading?.map((header, index) => (
                            <th key={index}>{header}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {detail?.rows?.map((item, rowIndex) => (
                        <tr key={rowIndex}>
                            {Object.values(item).map((value, cellIndex) => (
                                <td key={cellIndex}>{value}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    );
};

export default Tables;
