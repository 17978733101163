import { getToken } from "./Auth"

export const createVippsPayment = (packageId) => new Promise((resolve, reject) => {
    const token = getToken();
    if(!token || token === ''){
        window.location.href = '/login';
        return;
    }
    fetch(`${process.env.REACT_APP_API}/vipps/CreatePayment?subscription=${packageId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            accept: '*/*'
        }
    })
    .then(resp => {
        if(resp.status === 200) return resp.json();
        else return reject();
    })
    .then(resolve)
    .catch(reject)
})

export const processVippsPayment = (orderId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/vipps/ReceiveCreatePayment?orderId=${orderId}`)
    .then(resp => {
        if(resp.status === 200) return resp.json();
        else return reject(resp)
    })
    .then(resolve)
    .catch(reject)
})

export const createNexiPayment = (packageId) => new Promise((resolve, reject) => {
    const token = getToken();
    if(!token || token === ''){
        window.location.href = '/login';
        return;
    }
    fetch(`${process.env.REACT_APP_API}/Nexi/CreatePayment?subscription=${packageId}`, {
        headers: {
            Authorization: `Bearer ${token}`,
            accept: '*/*'
        }
    })
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else return reject(resp)
    })
    .then(resolve)
    .catch(reject)
})