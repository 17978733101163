import React, { useState } from 'react';
import classes from './index.module.scss';
import Button from "../../../../Components/Button";
import gjeldsregisteret from '../../../../Images/Logo_Gjeldsregisteret.svg';
import {Modal, Alert} from "react-bootstrap";
import FormInput from "../../../../Components/FormInput";
import { consentGjeldsregisteret } from '../../../../Services/api/Auth';

const DebtCheckConsent = ({ onShowDebt }) => {
    const [checkbox, setCheckbox] = useState(false);
    const [checkboxError, setCheckboxError] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShowDebtClick = () => {
        if(checkbox){
            setCheckboxError(false);
            consentGjeldsregisteret()
                .then(() => {
                    onShowDebt();
                    const auth = JSON.parse(localStorage.getItem('auth'));
                    auth.consent = true;
                    localStorage.setItem('auth', JSON.stringify(auth));
                    window.location.reload();
                })
                .catch(() => {})
        }
        else setCheckboxError(true);
    };
  return(
      <React.Fragment>
          <div className={classes.loginBankID}>
              <h3 className={classes.title}>For å se gjelden din</h3>
              {checkboxError && <Alert variant='danger'>Vennligst godta vilkår og betingelser.</Alert>}
              <FormInput
                  type={'checkbox'}
                  id={'accept'}
                  checked={checkbox}
                  onChange={e => setCheckbox(e.target.checked)}
                  label={<span className={'font-11 text-left d-block'} style={{lineHeight: '14px'}}>
                      Jeg gir herved EasyFinance samtykke til å innhente informasjon om min gjeld fra Gjeldsregisteret. Jeg tillater at EasyFinance behandler mine gjeldsopplysningeri en periode på 12 måneder for å kunne tilby meg skreddersydde forslag til hvordan jeg effektivt kan nedbetale gjelden min. Du kan også se våre <a href={'#/'} onClick={handleShow}>vilkår og betingelser.</a> <br /><br />

                      Du kan til enhver tid tilbakekalle ditt samtykke hos <a href={'https://www.gjeldsregisteret.com/'} target={'_blank'}>Gjeldsregisteret AS</a>.

                  </span>}
              />
              <Button
                  text={"Hent min gjeld"}
                  variant={'border small my-3'}
                  onClick={handleShowDebtClick}
              />
              <div className={classes.logo}>
                  <a href={'#'}>
                      <img src={gjeldsregisteret} loading="lazy" alt={'gjeldsregisteret'} />
                  </a>
              </div>
              <Modal show={show} onHide={handleClose} className={classes.terms}>
                  <Modal.Header closeButton>
                      <h4 className={'mb-0'}>Terms and Conditions</h4>
                  </Modal.Header>
                  <Modal.Body>
                      <div><h3><span>Personvernerklæring</span></h3>
                          <p><span>Vi hos «Easy Finance» beskytter dine personvernrettigheter. Disse vilkårene og betingelsene regulerer din bruk av easyfinace.no.</span></p>
                          <p><span>Denne personvernerklæringen forteller hvordan vi samler inn og behandler dine personopplysninger. Hvordan vi håndterer dine individuelle rettigheter og hvordan du kan gjøre bruk av disse rettighetene.</span></p>
                          <p><span>Du har rett til å motsette deg vår behandling av personopplysninger i visse situasjoner. Vennligst les mer informasjon om dette under hvert formål for behandlingen. Det er viktig at du leser og forstår vår personvernerklæring før du bruker våre tjenester. Du kan alltid kontakte oss ved å sende en e-post til</span> <a href="mailto:post@easyfinance.no" target="_self"><span>post@easyfinance.no.</span></a></p>
                          <h3><span>Hva er personopplysninger?</span></h3>
                          <p><span>Personopplysninger er informasjon som kan knyttes til en fysisk person. Eksempler på slik informasjon er navn, adresse, telefonnummer, e-postadresse, IP-adresse og ditt digitale brukermønster, som kan betraktes som personopplysninger.</span></p>
                          <h3><span>Hvilke personopplysninger samler vi inn?</span></h3>
                          <p><span>De personopplysningene vi samler inn, kan bestå av:</span></p>
                          <ul>
                              <li><span>Navn</span></li>
                              <li><span>E-post</span></li>
                              <li><span>Telefonnummer</span></li>
                              <li><span>Geografisk område</span></li>
                              <li><span>IP-adresse</span></li>
                          </ul>
                          <h3><span>Hva er databehandling?</span></h3>
                          <p><span>Databehandling omfatter all behandling av personopplysninger, som innsamling, analyse, markedsføring og lagring. En databehandler er en juridisk enhet som behandler personopplysninger på vegne av den behandlingsansvarlige.</span></p>
                          <h3><span>Hvilke andre selskaper kan få tilgang til dine data?</span></h3>
                          <p><span>Som en databehandler kan andre selskaper få tilgang til dine personopplysninger. Disse selskapene er Adservice (tilknyttet nettverk), Adtraction (tilknyttet nettverk), Orion Media (tilknyttet nettverk), Axo Finans (tilknyttet nettverk), Google Adsense (annonsering).</span></p>
                          <p><span>Vi bruker også databehandlere som Hotjar Ltd., Google Analytics, Facebook-piksler, LinkedIn-piksler for disse formålene. Disse databehandlerne bruker informasjonskapsler og andre teknikker for å samle inn data om brukermønstre og enheter som brukes (enhetsens IP-adresse behandles og lagres bare i anonymisert form). Databehandlerne lagrer denne informasjonen selv. Vi har ingen databehandler som vil bruke denne informasjonen til å identifisere enkeltpersoner eller koble den til annen informasjon om en individuell bruker.</span></p>
                          <h3><span>Hvorfor samler vi inn personopplysninger?</span></h3>
                          <p><span>Vi samler inn data for å sende nyhetsbrev og e-postkorrespondanse etter å ha fått samtykke fra kunden. For markedsføringsformål bruker vi databehandlertjenester der vi ikke kan identifisere individuelle data. For å forbedre brukeropplevelsen vår, bruker vi Hotjar og Google Analytics.</span></p>
                          <h3><span>Lagringsperiode:</span></h3>
                          <p><span>Vi behandler dine personopplysninger for det angitte formålet og sletter dem innen 90 dager fra vårt system. Vi behandler ikke noen detaljer om lånesøknaden din hvis du har en aktiv/pågående lånesøknad hos en långiver eller bank. Historikk fra kundeservice via telefon, e-post og chat slettes kontinuerlig innen 3 måneder.</span></p>
                          <p><span>Hotjar Ltd. sletter kontinuerlig dataene etter 365 dager. Google Analytics sletter kontinuerlig dataene etter 26 måneder.</span></p>
                          <h3><span>Produktutvikling:</span></h3>
                          <p><span>Vi behandler dine personopplysninger for formål knyttet til produktutvikling basert på utviklingsinteresser. Vi har gjort en vurdering av berettiget interesse der vi har konkludert med at behandlingen av dataene for dette formålet ikke medfører noen vesentlig brudd på din integritet. For produktutvikling samler vi inn følgende informasjon:</span></p>
                          <ul>
                              <li><span>IP-adresse</span></li>
                              <li><span>Informasjon om enheten din</span></li>
                              <li><span>Informasjon om nettleseren din</span></li>
                              <li><span>Geografisk posisjon (land)</span></li>
                              <li><span>Spesifikk informasjon om bruken av nettsiden (relevante URL-adresser)</span></li>
                          </ul>
                          <h3><span>Hvor behandles dine personopplysninger?</span></h3>
                          <p><span>Vi arbeider alltid med å behandle informasjon om deg innenfor EU/EØS, og alle våre IT-systemer befinner seg innenfor EU/EØS. Under visse forhold kan vi være nødt til å overføre informasjonen til et land utenfor EU/EØS, for eksempel hvis vi deler dine personopplysninger med en databehandler som er etablert i eller har en underleverandør som er etablert i eller lagrer data i et land utenfor EU/EØS. I slike tilfeller kan databehandleren kun få tilgang til deler av informasjonen som er relevant for formålet (f.eks. loggfiler). I slike tilfeller har vi inngått en databehandleravtale som gir oss kontroll over våre data.</span></p>
                          <p><span>Vi deler personopplysninger med databehandlere og databehandlingsansvarlige utenfor EU/EØS i samsvar med kravene i Personvernforordningen («GDPR») kapittel 5. En databehandlingsansvarlig er et selskap som bruker data uavhengig og har fullt ansvar overfor brukeren for selskapets bruk av personopplysninger for det spesifikke formålet dataene deles for. De er også ansvarlige for tredjeparter som kan være involvert i behandlingen av brukerens personopplysninger. Vi vil kun dele dine data der det er nødvendig for å kunne tilby vår tjeneste, for eksempel å dele dine data med kreditorer for å tilby en sammenligningstjeneste eller når vi har ditt samtykke til å dele data for markedsføringsformål med databehandlingsansvarlige som Facebook og Google.</span></p>
                          <h3><span>Hvilke rettigheter har du?</span></h3>
                          <p><span>Du har rett til å få tilgang til dine personopplysninger. Vi er alltid åpne og transparente med hvordan vi behandler dine personopplysninger, og hvis du ønsker dypere innsikt i hvilke personopplysninger vi har om deg, kan du be om tilgang til dataene.</span></p>
                          <h3><span>Rett til retting:</span></h3>
                          <p><span>Det er din rett å få korrigert feilaktig personinformasjon om deg selv uten ytterligere grunn. Avhengig av formålet med behandlingen har du også rett til å få fullført ufullstendige personopplysninger.</span></p>
                          <h3><span>Be om dine rettigheter ved å kontakte oss:</span></h3>
                          <p><span>Send oss en e-post til</span> <a href="mailto:post@easyfinance.no" target="_self"><span>post@easyfinance.no</span></a> <span>og oppgi hvilke formål eller hvilken behandling du ønsker at vi skal avslutte.</span></p>
                      </div>
                  </Modal.Body>
              </Modal>
          </div>

      </React.Fragment>
  )
}

export default DebtCheckConsent;