import React from "react";
import moment from "moment";
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import InnerPageLayout from "../../../PDFComponent/InnerPageLayout";
import PageHeader from "./PageHeader";
import AboutEasyFinance from "../../../PDFComponent/AboutEasyFinance";

const PaymentRemarksList = ({ remarks, userData, totalRemarks, totalAmount }) => {
  const format = (value, divideBy100 = false) => {
    if (isNaN(value)) return 0;
    const formatter = Intl.NumberFormat("no-NO");
    if (isNaN(value)) return "N/A";
    if (divideBy100) return formatter.format(value / 100);
    return formatter.format(value);
  };

  const translateRemarkType = (type) => {
    if (!type || type === "") return type;
    return type
        ?.toLowerCase()
        ?.replace("debt collection", "Inkasso")
        ?.replace("debt arrangement", "Frivillig Gjeldsordning")
        ?.replace("forced debt settlement", "Tvungen Gjeldsordning")
        ?.replace("distraint", "Utlegg")
        ?.replace("nothing to distraint", "Intet til utlegg")
        ?.replace("attachment of earnings", "Lønnstrekk")
        ?.replace("enforcment proceedings", "Tvangsinnkreving");
  };

  const styles = StyleSheet.create({
    section: { marginBottom: 20 },
    section1: { marginBottom: 15, fontSize: 8 },
    row: { flexDirection: 'row', justifyContent: 'space-between' },
    text: { flexDirection: 'column' },
    text1: { flexDirection: 'column', fontWeight: "bold", fontSize: 11 },
    creditSafeBox: { paddingTop: 10, marginLeft: 0, fontSize: 7 },
    link: { color: '#000' },
    contentList: { flexDirection: 'column', width: '100%', color: '#013367', marginBottom: 5, fontSize: 8 },
    list: { flexDirection: 'row', width: '100%', borderLeft: '3px solid #DEE2E5', marginBottom: '1px', backgroundColor: 'rgba(249,249,249,0.50)',},
    col: { padding: '5px 10px 2px' },
    bgGray: { backgroundColor: '#F8F8F7', color: '#4B4B4B', width: '150px' },
    rowTwoCol: { flexDirection: 'row', flexWrap: 'wrap', margin: '0 -5px 10px' },
  });

  return (
     <React.Fragment>
       <InnerPageLayout userData={userData} title={'Betalingsanmerkninger'}>
         <View style={[styles.section, styles.rowTwoCol]}>
           <PageHeader title="Betalingsanmerkninger" value={totalRemarks} color={'#FEF2F2'} text={'#F64C4C'} />
           <PageHeader title="Totalbeløp betalingsanmerkninger" value={format(Math.round(totalAmount))} />
         </View>
         <AboutEasyFinance />
       </InnerPageLayout>
       <InnerPageLayout userData={userData} title={'Betalingsanmerkninger detalj'}>
         <View wrap={false}>
           {remarks?.paymentRemarkDetails?.remarksOfPayment?.map((remark, index) => (
               <View style={styles.section1} key={index} wrap={false}>
                 <View style={styles.contentList}>
                   <View style={styles.list}>
                     <Text style={[styles.col, styles.bgGray]}>Dato</Text>
                     <Text style={styles.col}>{moment(remark?.date).format("DD-MM-YYYY")}</Text>
                   </View>
                   <View style={styles.list}>
                     <Text style={[styles.col, styles.bgGray]}>Kreditor</Text>
                     <Text style={styles.col}>{remark?.creditor}</Text>
                   </View>
                   <View style={styles.list}>
                     <Text style={[styles.col, styles.bgGray]}>Beløp</Text>
                     <Text style={styles.col}>{format(remark?.amount)} kr</Text>
                   </View>
                   <View style={styles.list}>
                     <Text style={[styles.col, styles.bgGray]}>Saksnummer</Text>
                     <Text style={styles.col}>{remark?.refNr && remark?.refNr !== "" ? remark?.refNr : "-"}</Text>
                   </View>
                 </View>
                 <Text>Kontakt {remark?.creditor} for a vite dagens restsaldo pá kravet</Text>
               </View>
           ))}
           <Text style={styles.creditSafeBox}>
             Denne informasjonen tilbys av{" "}
             <Link style={styles.link} src={"https://www.creditsafe.com/us/en.html"} target={"_blank"}>
               Creditsafe
             </Link>
           </Text>
         </View>
       </InnerPageLayout>
     </React.Fragment>
  );
};

export default PaymentRemarksList;
