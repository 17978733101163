import { Validator } from "fluentvalidation-ts";

type ContactForm = {
    name: string;
    email: string;
    phoneNo: string;
    message: string;
}
class ContactValidator extends Validator<ContactForm>{
    constructor(){
        super();
        this.ruleFor('name')
        .notNull()
        .withMessage('Vennligst skriv inn navnet ditt.')
        .notEmpty()
        .withMessage('Vennligst skriv inn navnet ditt.');

        this.ruleFor('email')
        .notNull()
        .withMessage('Vennligst skriv inn E-postadressen din.')
        .emailAddress()
        .withMessage('Vennligst skriv inn en gyldig e-post adresse.')

        this.ruleFor('message')
        .notEmpty()
        .withMessage('Vennligst skriv inn meldingen din.')
        .notNull()
        .withMessage('Vennligst skriv inn meldingen din.')

        this.ruleFor('phoneNo')
        .notEmpty()
        .withMessage('Vennligst skriv inn telefonnummeret ditt.')
        .notNull()
        .withMessage('Vennligst skriv inn telefonnummeret ditt.')
        .must(phone => /^[2-9]\d{7}$/.test(phone))
    }
}
export default ContactValidator;