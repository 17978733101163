import React, { useEffect, useState } from 'react';
import classes from "./index.module.scss";
import DebtCheckConsent from "./index";
import {Container, Spinner} from "react-bootstrap";
import Button from "../../../../Components/Button";
import gjeldsregisteret from "../../../../Images/Logo_Gjeldsregisteret.svg";
import checkDebtScore from "../../../../Images/checkDebtScore.svg";

const DebtCheckDataLoad = ({ onCloseModal, style, onClick, loadingDebtData, absolute }) => {
    const [showBankIDModal, setShowBankIDModal] = useState(true);
    const closeBankIDModal = () => {
        setShowBankIDModal(false);
        onCloseModal();
    };

    return (
        <React.Fragment>
            {showBankIDModal && (
                <div className={`${classes.customPopup} ${absolute && classes.absolute}`} >
                    <Container className={'border-0'}>
                        <div className={classes.popupContent} style={style}>
                            <div className={classes.imgBox}>
                                <img src={checkDebtScore} loading="lazy" />
                            </div>
                            <h3 className={classes.title}>For å se gjelden din</h3>
                            <div className={classes.logo}>
                                <a href={'#'}>
                                    <img src={gjeldsregisteret} loading="lazy" alt={'gjeldsregisteret'} />
                                </a>
                            </div>
                            <Button
                                //text={"Hent min gjeld"}
                                text={loadingDebtData ? <Spinner size={"sm"} /> : 'Klikker her'}
                                variant={'border w-100'}
                                onClick={onClick}
                            />

                        </div>
                    </Container>
                </div>
            )}
        </React.Fragment>
    );
};

export default DebtCheckDataLoad;
