import React from "react";
import SectionTitle from "../../SectionTitle";
import classes from "../index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../Button";
import { useState } from "react";
import { useEffect } from "react";
import { getTrendingBlogs } from "Services/api/Blog";
import { Container } from "react-bootstrap";

const TrendingPosts = ({ detail }) => {
    const [trendingBlogs, setTrendingBlogs] = useState([]);
    const languageId = 1;
    const count = 4;

    useEffect(() => {
        getTrendingBlogs(languageId, count)
        .then(resp => setTrendingBlogs(resp.data));
    }, [])



    return (

        <React.Fragment>
            <div className={classes.blogSection}>
                <Container>
                    <SectionTitle className={'pb-0'}>
                        { detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>) }
                    </SectionTitle>
                    <div className={`${classes.colPost} ${classes.trending}`}>
                        {trendingBlogs.length === 0 && <div className={classes.trendingBlogSkeleton} />}
                        {trendingBlogs.map((item, index) => {
                            const details = JSON.parse(item.postContents);
                            return <div className={classes.col} key={item.id}>
                                <div className={classes.box}>
                                    <a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} className={classes.imgBox} style={{ backgroundImage: `url(${details?.image})` }}></a>
                                    <div className={classes.description}>
                                        <h4 className={classes.title}><a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{item.title}</a></h4>
                                        <p>{item.summary}</p>
                                        {index === 0 && (
                                            <>
                                                <Button
                                                    text={'Les mer'}
                                                    url={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                                    variant={'link'}
                                                    icon={'far fa-arrow-right'}
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {/* <div className={'py-3  border-bottom overflow-hidden text-center'}>
                        <Button
                            url={'/all-trending'}
                            text={'View More'}
                            icon={'far fa-arrow-right'}
                        />
                    </div> */}
                </Container>
            </div>

        </React.Fragment>
    )
}

export default TrendingPosts;
