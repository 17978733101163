import _ from "lodash"
import { APP_SETTINGS_LOADED } from "../actions/appSettings";

const initalState = {
    settings: []
}

export default function appSettingsReducer(state = initalState, action){
    const newState = _.cloneDeep(state);
    switch(action.type){
        case APP_SETTINGS_LOADED:
            newState.settings = action.payload;
            return newState;
        default:
            return newState;
    }
}