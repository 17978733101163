const faqData = [
    {
        id: 1,
        heading: {
            description: "Her er noen spørsmål kundene våre stiller. Sjekk vår FAQ for alt vi ikke har dekket.",
            title: [
                { id: 1, title: "Ofte stilte", class: "fw-normal" },
                { id: 1, title: "spørsmål", class: "fw-bold" },
            ]
        },
        singleCol: true,
        questions: [
            {
                id: 1,
                title: "Hvordan kan EasyFinance hjelpe min bedrift med finansieringsbehov?",
                description: "EasyFinance fungerer som en formidler som forenkler prosessen med å finne riktig finansiering for din bedrift ved å samarbeide med pålitelige finansinstitusjoner. Vi hjelper deg med å navigere gjennom ulike finansieringsalternativer for å finne en løsning som passer dine behov.",
            },
            {
                id: 2,
                title: "Hvilke typer bedrifter kan dra nytte av EasyFinances tjenester?",
                description: "Alle typer bedrifter kan dra nytte av våre tjenester, uavhengig av bransje eller bedriftsstørrelse. Vi fokuserer på å hjelpe bedrifter i ulike faser, spesielt de som er i oppstart- og vekstfasen, med å sikre nødvendig kapital.",
            },
            {
                id: 3,
                title: "Hvordan sikrer EasyFinance at prosessen er trygg og effektiv?",
                description: "Gjennom våre samarbeidspartnere som Vipps, Creditsafe og BankID, sørger vi for en trygg og rask søknadsprosess. Disse partnerskapene sikrer at dine data behandles konfidensielt og at finansieringsløsningen er skreddersydd for din bedrift.",
            },
            {
                id: 4,
                title: "Hva slags støtte kan jeg forvente fra EasyFinance under søknadsprosessen?",
                description: "EasyFinance tilbyr veiledning gjennom hele søknadsprosessen, fra å forstå dine finansieringsbehov til å matche deg med den beste finansieringsløsningen fra våre partnere. Vi står til disposisjon for å svare på spørsmål og gi råd for å sikre at du føler deg trygg og informert.",
            },
            {
                id: 5,
                title: "Hvordan kan jeg komme i gang med å søke om finansiering gjennom EasyFinance?",
                description: "For å starte søknadsprosessen med EasyFinance, følg disse enkle stegene:<br><br>" +
                    "Logg inn: Besøk EasyFinances nettside og logg inn ved hjelp av BankID eller Vipps for enkel og sikker identifisering.<br><br>" +
                    "Sjekk din kredittscore: Når du er logget inn, kan du raskt sjekke din kredittscore gjennom vår plattform. Dette gir deg en god indikasjon på din finansielle status og dine lånealternativer.<br><br>" +
                    "Søk om lån: Basert på din kredittscore, kan du enkelt søke om lån gjennom vårt system. Vi tilbyr en strømlinjeformet prosess for å koble deg med passende finansieringsmuligheter.<br><br>" +
                    "Viktig tips: Hvis du har betalingsanmerkninger, anbefaler vi at du tar skritt for å fjerne disse før du søker om lån. Betalingsanmerkninger kan påvirke din kredittscore og dine sjanser for å få lån, så det er best å adressere disse problemene på forhånd.",
            },
        ],
    },
];

export default faqData;
