import React, { useEffect, useRef }  from "react";
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import img from '../../Images/Financialinformation.svg';

const StaticBannerWithImg = ({ staticBanner }) => {

    return(
        <React.Fragment>
            <div className={`
                ${classes.banner}
                ${staticBanner && classes.staticBanner}
            `}>
                <Container>
                    <div className={classes.bannerHolder}>
                        <React.Fragment>
                            <div className={classes.col}>
                                <div className={`${classes.textHolder}`}>
                                    <h1 id="dynamicHeader">
                                        <span className={'fw-normal'}>Refinansier forbrukslån  </span>
                                        <span className={'fw-bold'}> opptil 800 000 kr og</span><br />
                                        <span className={'fw-normal'}>få bedre betingelser.</span>
                                    </h1>
                                    <p>Den enkleste måten å finne det beste lånet på <br /> Sammenlign renter</p>
                                    <ul>
                                        <li>Enkel søknad.</li>
                                        <li>Flere tilbud</li>
                                        <li>Egen rådgiver</li>
                                        <li>Bedre betingelser</li>
                                    </ul>

                                </div>
                            </div>
                            <div className={classes.col}>
                                <div className={classes.imgBox}>
                                    <img src={img} alt={''} />
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default StaticBannerWithImg;