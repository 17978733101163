import unofinans from "../../../../../Images/unofinans.svg";
import moty from "../../../../../Images/motty.svg";
import nanoFinance from "../../../../../Images/Nanofinans.svg";
import okidaLogo from "../../../../../Images/okidaLogo.svg";
import tfBank from '../../../../../Images/tf-bank-mastercard.png';
import remember from '../../../../../Images/remember.jpg';
import morrowBank from '../../../../../Images/morrowbank_mastercard1.svg';
import instaBank from '../../../../../Images/instabank_instapay.png';
import bankNorwegian from '../../../../../Images/banknorwegian.svg';
import creditcardcollection from '../../../../../Images/creditcardcollection.svg';
import spareBank from "../../../../../Images/SpareBank.svg";
import nordea from "../../../../../Images/nordeaLogo.svg";

const data = [
    {
        dataType: 'Boliglån',
        title: "Refinansier med sikkerhet i bolig",
        averageRent: "5,60",
        faqs: [
            {
                heading: "Boliglånsrenten har økt mye de siste årene. Sørg derfor for at du ikke betaler mer enn du må på boliglånet.",
                list: [
                    {title: "", description: "Forhandle med banken din",},
                    {title: "", description: "Innhent og sammenlign tilbud fra flere banker",},
                    {title: "", description: "Vis god betalingshistorikk og økonomisk stabilitet",},
                    {title: "", description: "Betal ned gjeld og reduser gjeldsgraden din",},
                    {title: "", description: "Oppdatert boligverdi med mindre belåningsgrad",},
                    {title: "", description: "Samle all gjeld hos én bank for bedre oversikt og betingelser",},
                    {title: "", description: "Vurder å flytte flere banktjenester til samme bank for bedre betingelser",},
                    {title: "", description: "Medlemsskapsfordeler - Sjekk om fagforbundet ditt har forhandlet frem gode avtaler med banker",},
                    {title: "Hva skal jeg gjøre hvis banken ikke vil gi meg bedre vilkår? ", description: "Det er enklere enn mange tror å forhandle med banken om lavere boliglånsrente. Faktisk kan fire av fem boliglånskunder spare penger på lånet ved å forhandle med banken eller bytte til en som tilbyr bedre vilkår.",},
                ]
            }
        ],
        childData: [
            {
                name: "",
                image: spareBank,
                leverandorTekst: 'EasyFinance anbefaler Sparebank 1 SR-Bank for boliglån grunnet deres pålitelighet og dekning av medlemmers behov. De tilbyr konkurransedyktige vilkår, spesielt gunstige for LO-medlemmer og førstegangskjøpere, noe som gjør dem til et ideelt valg.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '5,60%',
                featured: false,
                loanExample: 'Låneeksempel: Effektiv rente 5.97% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 788 775.',
                btnUrl: "https://www.sparebank1.no/nb/smn/privat/lan/boliglan.html ",
                affiliateType: "",
            },
            {
                name: "",
                image: nordea,
                leverandorTekst: 'EasyFinance anbefaler Nordea for boliglån, da de er en landsdekkende og pålitelig bank som dekker våre medlemmers varierte behov. Nordea tilbyr konkurransedyktige vilkår og fleksibilitet, samt moderne tjenester som ApplePay, noe som gjør dem til et godt valg for våre medlemmer.',
                rating: '4.5',
                loanAmount:'100 000 kr - 20 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '6.05%',
                featured: false,
                loanExample: ' Låneeksempel: Effektiv rente 5.70% ved lån på 2 000 000 kr over 25 år. Totalt: kr 3 703 643.',
                btnUrl: "https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/sok-om-boliglan.html",
                affiliateType: "",
            },
        ]
    },
]

export default data;