import React, { useEffect, useState } from 'react';
import { Document, StyleSheet } from '@react-pdf/renderer';
import FrontPage from "../../PDFComponent/FrontPage";
import DebtList from "./DebtList";

const ScoreDocument = ({ userData, debtData }) => {

    const formatDate = (date) => {
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    };

    const currentDate = formatDate(new Date());

    const styles = StyleSheet.create({
        document: {},
        page: {
            fontSize: 11,
            paddingTop: 40,
            paddingLeft: 40,
            paddingRight: 40,
            lineHeight: 1.5,
            flexDirection: 'column',
            position: 'relative'
        },
        spaceBetween: {
            flex: 1,
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            color: "#3E3E3E"
        },
        titleContainer: { flexDirection: 'row', marginTop: 24 },
        logo: { width: 90 },
    });

    return (
        <Document style={styles.document}>
            <FrontPage userData={userData} />
            <DebtList userData={userData} debtData={debtData} />
        </Document>
    );
};

export default ScoreDocument;
