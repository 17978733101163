import React, {useState, useEffect} from "react";

import ClientRoutes from "Routes";
import "Style/theme-setting.scss";
import "Style/global.scss";
// import MainLayout from "Layout/MainLayout";
// import { useSelector } from "react-redux";
// import { MetaTags } from "react-meta-tags";

const App = () => {
  // const scripts = useSelector(state => state?.components?.settings?.scripts);
  // const tags = useSelector(state => state?.components?.settings?.tags);
  // useEffect(() => {
  //   const analytics = document.createElement('script');
  //   const facebook = document.createElement('script');
  //   const hotjar = document.createElement('script');
  //   const bodyScript = document.createElement('script');

  //   const head = document.querySelector('head');
  //   const body = document.querySelector('body');

  //   analytics.innerText = scripts?.analytics;
  //   facebook.innerText = scripts?.facebook;
  //   hotjar.innerText = scripts?.hotjar;
  //   bodyScript.innerText = scripts?.body;

  //   head.appendChild(analytics);
  //   head.appendChild(facebook);
  //   head.appendChild(hotjar);
  //   body.appendChild(bodyScript);

  //  const metas = tags?.body?.map((bodyTag) => {
  //     const meta = document.createElement('meta');
  //     meta.name = bodyTag.name;
  //     meta.content = bodyTag.description;
  //     body.appendChild(meta);
  //     return meta;
  //   })

  //   return () => {
  //     head.removeChild(analytics);
  //     head.removeChild(facebook);
  //     head.removeChild(hotjar);
  //     body.removeChild(bodyScript);
  //     metas?.map(meta => body.removeChild(meta));
  //   }
  // }, [scripts])
    const [loading, setLoading] = useState(true);
  return (
    <>
      {/* <MetaTags>
          {tags?.header?.map((headerTag, index) => <meta key={index} name={headerTag.name} content={headerTag.description}/>)}
        </MetaTags> */}
      <ClientRoutes />
    </>
  );
};

export default App;
