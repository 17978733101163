export const contactUs = (object) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/ContactUs/Post`, {
        method: 'POST',
        headers: { 'Content-type': 'application/json' },
        body: JSON.stringify(object)
    })
    .then(resp => {
        if(resp.status === 200){
            return resp.json();
        }
        else reject(resp);
    })
    .then(resolve)
    .catch(reject);

});