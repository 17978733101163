import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import Button from "../Button";
import ComparisonTabsBox from "../ComparisonTabsBox";
import classes from './index.module.scss';

const LoanComparisonHome = ({ detail }) => {
  return(
      <React.Fragment>
          <section className={`section ${classes.circleBg}`}>
              <Container>
                  <Row className={classes.compareRow}>
                      <Col lg={'6'}>
                          <SectionTitle>
                            {detail?.heading?.title?.map((title, index) => <span className={title.class} key={index}>{title.title}</span>)}
                          </SectionTitle>
                          <ul className={'checkList mb-5'}>
                              {detail?.checkList?.map((list, index) => <li key={index}>{list}</li>)}
                          </ul>
                          <Button
                            url={detail?.linkUrl}
                            text={detail?.linkText}
                            icon={"far fa-arrow-right"}
                            variant={'link'}
                          />
                      </Col>
                      <Col lg={'6'}>
                          <ComparisonTabsBox detail={detail}/>
                      </Col>
                  </Row>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default LoanComparisonHome;