import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {Table} from "react-bootstrap";

const Miscellaneous = ({details}) => {
    const formatter = Intl.NumberFormat('no-NO');
    const format= (value, divideBy1000 = true) => isNaN(value) ? 0 : formatter.format(value / (divideBy1000 ? 1000 : 1));
    const [dates, setDates] = useState([]);
    const [workingCapital, setWorkingCapital] = useState([]);
    const [netWorth, setNetWorth] = useState([]);
    const [capitalEmployed, setCapitalEmployed] = useState([]);
    const [manLabourYear, setManLabourYear] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [exports, setExports] = useState([]);
    const [gmSalary, setGmSalary] = useState([]);
    const [auditorRemuneration, setAuditorRemuneration] = useState([]);
    useEffect(() => {
        setDates(details?.report?.localFinancialStatements?.map(fs => moment(fs.yearEndDate).format('DD/MM/YYYY')));
        setWorkingCapital(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.workingCapital));
        setNetWorth(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.netWorth));
        setCapitalEmployed(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.capitalEmployed));
        setManLabourYear(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.manLabourYear));
        setEmployees(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.numberOfEmployees));
        setExports(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.exports));
        setGmSalary(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.generalManagerSalary));
        setAuditorRemuneration(details?.report?.localFinancialStatements?.map(fs => fs?.otherFinancials?.auditorsRemuneration));
    }, [details]);

    const getPercentage = (number, array, index, attachSymbol = false) => {
        if (index < 0 || index >= array.length - 1) {
            return null; // Index out of range or no next index
        }
    
        const currentValue = Math.abs(array[index]);
        const nextValue = Math.abs(array[index + 1]);
    
        let percentageValue = ((currentValue - nextValue) / nextValue) * 100;
        // const percentageValue = ((array[index - 1] - number) / number) * 100;
        if(isNaN(percentageValue) || percentageValue === 0 || percentageValue === Infinity || percentageValue === -Infinity)
        return '-'
        percentageValue = percentageValue.toFixed(1);
        if(Math.abs(percentageValue) > 1000) percentageValue = (percentageValue / 1000).toFixed(1) + (attachSymbol ? 'K' : 0)
        return attachSymbol ? percentageValue + ' %' : percentageValue;
    }

    return(
        <React.Fragment>
            <h5>Øvrig Informasjon</h5>
            <div className={'table-responsive'}>
                <Table className={'border'} style={{minWidth: "850px"}}>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        {dates?.map((date, index) => (
                            <React.Fragment key={index}>
                                <th colSpan={1}>{date}</th>
                                {index < dates.length - 1 && <th colSpan={1}></th>}
                            </React.Fragment>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>Sum eksport</th>
                        {exports?.map((number, index) => {
                            return <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < exports?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, exports, index)) ? '' : getPercentage(number, exports, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, exports, index, true)}<i className={isNaN(getPercentage(number, exports, index)) ? '' : getPercentage(number, exports, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        })}
                    </tr>
                    <tr>
                        <th>Arbeidskapital</th>
                        {workingCapital?.map((number, index) => {
                            return <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < workingCapital?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, workingCapital, index)) ? '' : getPercentage(number, workingCapital, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, workingCapital, index, true)}<i className={isNaN(getPercentage(number, workingCapital, index)) ? '' : getPercentage(number, workingCapital, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        })}
                    </tr>
                    <tr>
                        <th>Netto formue</th>
                        {netWorth?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < netWorth?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, netWorth, index)) ? '' : getPercentage(number, netWorth, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, netWorth, index, true)}<i className={isNaN(getPercentage(number, netWorth, index)) ? '' : getPercentage(number, netWorth, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Sysselsatt kapital</th>
                        {capitalEmployed?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < capitalEmployed?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, capitalEmployed, index)) ? '' : getPercentage(number, capitalEmployed, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, capitalEmployed, index, true)}<i className={isNaN(getPercentage(number, capitalEmployed, index)) ? '' : getPercentage(number, capitalEmployed, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Antall årsverk</th>
                        {manLabourYear?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < manLabourYear?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, manLabourYear, index)) ? '' : getPercentage(number, manLabourYear, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, manLabourYear, index, true)}<i className={isNaN(getPercentage(number, manLabourYear, index)) ? '' : getPercentage(number, manLabourYear, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Ansatte</th>
                        {employees?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < employees?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, employees, index)) ? '' : getPercentage(number, employees, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, employees, index, true)}<i className={isNaN(getPercentage(number, employees, index)) ? '' : getPercentage(number, employees, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Lønn daglig leder</th>
                        {gmSalary?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < gmSalary?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, gmSalary, index)) ? '' : getPercentage(number, gmSalary, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, gmSalary, index, true)}<i className={isNaN(getPercentage(number, gmSalary, index)) ? '' : getPercentage(number, gmSalary, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Revisjonshonorar</th>
                        {auditorRemuneration?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < auditorRemuneration?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, auditorRemuneration, index)) ? '' : getPercentage(number, auditorRemuneration, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, auditorRemuneration, index, true)}<i className={isNaN(getPercentage(number, auditorRemuneration, index)) ? '' : getPercentage(number, auditorRemuneration, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default Miscellaneous;