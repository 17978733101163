import React from 'react';
import SectionTitle from "../../Components/SectionTitle";
import Thankyou from "../../Images/Thankyou.svg";
import Button from "../../Components/Button";


const ThankYou = ({onError}) => {
  return(
      <React.Fragment>
        <SectionTitle textCenter description={'Lorem ipsum dolor sit amet consectetur. Id quam fermentum nec viverra neque feugiat. Viverra velit lorem non sapien sed euismod blandit morbi sit. Enim nulla turpis fermentum'}>
          <span className={'fw-normal'}>Thank you</span>
          <span className={'fw-light'}>for</span>
          <span className={'fw-semibold'}>Register</span>
        </SectionTitle>

        <div className={'p-3'}>
          <div className={'position-relative text-center'}>
            <div className={'text-center mb-5'}>
              <img src={Thankyou} loading="lazy" onError={onError} alt={''} />
            </div>
            <Button
                text={'Go to Dashboard'}
                url={'/'}
                variant={'darkBorder'}
            />
          </div>
        </div>
      </React.Fragment>
  )
}

export default ThankYou;