import React, { useEffect, useState, useRef } from 'react';
import {Alert, Col, Container, Row} from "react-bootstrap";
import { useParams } from 'react-router-dom';
import data from '../data';
import classes from './index.module.scss';
import { getBlogById } from 'Services/api/Blog';
import Tags from '../BlogComponent/Tags';
import Tables from '../BlogComponent/Tables';
import Text from '../BlogComponent/Text';
import TwoColListWithCheckList from '../BlogComponent/TwoColListWithCheckList';
import LeftImgWithText from '../BlogComponent/LeftImgWithText';
import ThreeColText from '../BlogComponent/ThreeColText';
import TwoColTextWithTopImg from '../BlogComponent/TwoColTextWithTopImg';
import TwoColTextWithLeftRightImg from '../BlogComponent/TwoColTextWithLeftRightImg';
import BannerImg from '../BlogComponent/BannerImg';
import { onError } from 'Services/api/Image';
import BlockQuote from '../BlogComponent/BlockQuote';
import Affiliate from '../Affiliate';
import { getVersionById } from '../../../Services/api/Component';
import ComparisonTabsBox from '../../ComparisonTabsBox';
import Breadcrumbs from "../../Breadcrumbs";


const PostDetails = ({detail}) => {
    const { id } = useParams();
    const [blog, setBlog] = useState();
    const [contents, setContents] = useState();
    const [tabDetails, setTabDetails] = useState({});
    const [isFixed, setIsFixed] = useState(false);
    const contentRef = useRef(null);
    const sidebarRef = useRef(null);

    useEffect(() => {
        getBlogById(id)
        .then(resp => {
            setBlog(resp.data);
            const parsed = JSON.parse(resp.data.postContents);
            const tabs = parsed?.data?.find(p => p.type === 'sidebarTabs');
            getVersionById(tabs?.data?.versionId)
            .then(resp => {
                setTabDetails(JSON.parse(resp?.data?.versionDetail));
            })
            setContents(parsed);
        });
    }, []);

    const selectedPost = data.find((post) => post.id === id);

    // if (!selectedPost) {
    //     return <p>Post not found.</p>;
    // }


    useEffect(() => {
        let prevScrollPos = window.pageYOffset;

        function handleScroll() {
            const content = contentRef.current;
            const sidebar = sidebarRef.current;

            if (content && sidebar) {
                const contentRect = content.getBoundingClientRect();
                const sidebarRect = sidebar.getBoundingClientRect();
                const currentScrollPos = window.pageYOffset;

                const fitsInViewport = sidebarRect.height <= contentRect.height;

                if (currentScrollPos > prevScrollPos) {
                    // Scrolling down
                    if (sidebarRect.top <= 66 && fitsInViewport) {
                        const bottomOffset = contentRect.bottom - sidebarRect.height;

                        if (bottomOffset < 0) {
                            sidebar.style.position = 'absolute';
                            sidebar.style.top = `${contentRect.height - sidebarRect.height}px`;
                        } else {
                            sidebar.style.position = 'fixed';
                            sidebar.style.top = '66px'; // Adjust as needed
                        }
                    } else {
                        sidebar.style.position = 'static';
                        sidebar.style.top = 'auto';
                    }
                } else {
                    // Scrolling up
                    sidebar.style.position = 'static';
                    sidebar.style.top = 'auto';
                }

                prevScrollPos = currentScrollPos;
            }
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);





    return (
        <React.Fragment>
            <Breadcrumbs timeStamp={blog?.createdOn} />
            <section className={'section pt-3'}>
                <Container>
                    <Row>
                        <Col lg={9}>
                            <div className={classes.postDetail} ref={contentRef}>
                                <div className={classes.imgBox}>
                                    <img src={contents?.image} onError={onError} alt={blog?.title} loading="lazy" />
                                </div>
                                <h1>{blog?.title}</h1>
                                <p>{blog?.summary}</p>
                                {
                                    contents?.data?.map((content, index) => {
                                        if(content?.data?.useInSidebar || content?.type === 'sidebarTabs') return;
                                        if(content.type === 'alerts')
                                            return <Alert key={index} variant={content?.data?.color}>
                                                { content?.data?.title && <Alert.Heading>{content?.data?.title}</Alert.Heading> }
                                                { content?.data?.description }
                                            </Alert>
                                        else if(content.type === 'tags')
                                            return <Tags key={index} detail={content?.data} />
                                        else if(content.type === 'bannerImage')
                                            return <BannerImg key={index} detail={content?.data} />
                                        else if(content.type === 'table')
                                            return <Tables key={index} detail={content?.data} />
                                        else if(content.type === 'textEditor')
                                            return <Text key={index} detail={content?.data} />
                                        else if(content.type === 'twoColListWithChecklist')
                                            return <TwoColListWithCheckList key={index} detail={content?.data}/>
                                        else if(content.type === 'leftImageWithText')
                                            return <LeftImgWithText key={index} detail={content?.data} />
                                        else if(content.type === 'threeColorText')
                                            return <ThreeColText key={index} detail={content?.data}/>
                                        else if(content.type === 'twoColTextWithTopImage')
                                            return <TwoColTextWithTopImg key={index} detail={content?.data} />
                                        else if(content.type === 'twoColWithLeftAndRightImage')
                                            return <TwoColTextWithLeftRightImg key={index} detail={content?.data} />
                                        // else if(key === 'bannerImage')
                                        //     return <BannerImg detail={value} />
                                        else if(content.type === 'blockquote')
                                            return <BlockQuote key={index} detail={content?.data}/>
                                        else if(content.type === 'affiliateCard')
                                            return <Affiliate key={index} detail={content?.data}/>

                                        else return <h1>{content.type}</h1>
                                    })
                                }

                                {/* {selectedPost.detailData && (
                            selectedPost.detailData.map((item, index) => (
                                <div key={index}>{item}</div>
                            ))
                        )} */}

                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className={classes.blogDetailSidebar} >
                                <ul className={classes.titleList}>
                                    {contents?.data?.map((content, index) => {
                                        if(!content?.data?.heading) return;
                                        return <li key={index}><a href={`#${content?.data?.heading?.toLowerCase().replaceAll(' ', '-')}`}>{content?.data?.heading}</a></li>
                                    })}
                                </ul>
                                <div
                                    ref={sidebarRef}
                                    style={{
                                        position: isFixed ? 'fixed' : 'static',
                                        top: isFixed ? '66px' : 'auto',
                                        zIndex: '9',
                                        //height: '91vh',
                                        //overflowY: 'scroll'
                                        /* Add other styles here */
                                    }} >
                                    {contents?.data?.map((content, index) => {
                                        if(!content?.data?.useInSidebar && content.type !== 'sidebarTabs') return;
                                        if(content.type === 'sidebarTabs')
                                            return <ComparisonTabsBox key={index} sidebar detail={tabDetails}/>
                                        if(content.type === 'bannerImage')
                                            return <BannerImg sidebar key={index} detail={content.data}/>

                                    })}
                                </div>
                            </div>
                            {/*<CompaignBanner detail={detail} />*/}
                            {/*<CollectionList categories detail={detail} />*/}
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default PostDetails;
