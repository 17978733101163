import React, {useState, useEffect} from "react";
import classes from "./index.module.scss";
import {NavLink, useLocation} from "react-router-dom";
import {Container, Dropdown, Nav} from "react-bootstrap";
import Button from "../Button";
import UserDropDown from "../UserDropDown";
import Search from "../Search";
import Notification from "../Notification";
import { getActiveHeader } from "Services/api/Navigation";
import { onError } from "Services/api/Image";

const Navigation = ({ userDashboardNav, isBusinessPage, hideNavigation }) => {
    const [auth, setAuth] = useState({});
    const [showMenu, setShowMenu] = useState(false);
    const [navVisible, setNavVisible] = useState(true);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const location = useLocation();
    const [isHovered, setIsHovered] = useState(false);
    const [activeHeader, setActiveHeader] = useState({});

    const handleNavOpenerClick = () => {
        setShowMenu(!showMenu);
        if (!showMenu) {
            document.body.classList.add('navActive');
        } else {
            document.body.classList.remove('navActive');
        }
    };

    const toggleNav = () => {
        setNavVisible(!navVisible);
    };

    const toggleSidebar = () => {
        setSidebarVisible(!sidebarVisible);
        if (!sidebarVisible) {
            document.body.classList.add('sidebarActive');
        } else {
            document.body.classList.remove('sidebarActive');
        }
    };

    // Close the menu when a route change occurs
    useEffect(() => {
        const unlisten = () => {
            setShowMenu(false);
            document.body.classList.remove('navActive');
        };

        return () => {
            unlisten();
        };
    }, [location]);

    useEffect(() => {
        loadHeader();
        const authString = localStorage.getItem('auth');
        if(authString && authString !== '') {
            const obj = JSON.parse(authString);
            setAuth(obj);
        }
    }, []);

    const loadHeader = function () {
        getActiveHeader()
            .then(setActiveHeader);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
        document.body.classList.add(classes.hovered); // Add your desired class to the body
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
        document.body.classList.remove(classes.hovered); // Remove the class when mouse leaves
    };


    return (
        <>
            <nav id={`${classes.nav}`} className={userDashboardNav && `${classes.userDashboardNav} ${isBusinessPage && classes.businessNav}` }>
                {userDashboardNav || hideNavigation ? '' :
                <div className={classes.mainNavHolder}>
                    <Nav as="ul" className={classes.mainNavigation}>
                        {isBusinessPage ? (
                            <React.Fragment>
                                <Nav.Item as="li">
                                    <a href={'/bedrift/bedriftslån'}>Lån</a>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <a href={'/bedrift/bedriftslån'}>
                                                <div className={classes.imgBox}>
                                                    <i className={"icon-icon16"}></i>
                                                </div>
                                                Bedriftslån
                                            </a>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <a href={'/bedrift/grøntlån'}>
                                                <div className={classes.imgBox}>
                                                    <i className={"icon-GreenLoanIcon"}></i>
                                                </div>
                                                Grønt lån
                                            </a>
                                        </Nav.Item>
                                    </Nav>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <a href={'/bedrift/kredittsjekk'}>
                                        Kredittsjekk
                                    </a>
                                    <Nav as="ul">
                                        <Nav.Item as="li">
                                            <a href={'/bedrift/kredittsjekk'}>
                                                <div className={classes.imgBox}>
                                                    <i className={"icon-icon16"}></i>
                                                </div>
                                                Kredittsjekk
                                            </a>
                                        </Nav.Item>
                                        <Nav.Item as="li">
                                            <a href={'/bedrift/kredittsjekk-for-å-unngå-tap-på-dårlige-betalere'}>
                                                <div className={classes.imgBox}>
                                                    <i className={"icon-icon16"}></i>
                                                </div>
                                                Unngå tap på dårlige betalere
                                            </a>
                                        </Nav.Item>
                                    </Nav>
                                </Nav.Item>
                              {/*  <Nav.Item as="li">
                                    <a href={'#faqs'}>FAQ</a>
                                </Nav.Item>*/}
                                <Nav.Item as="li">
                                    <a href={'/om-oss'}>Om oss</a>
                                </Nav.Item>
                                <Nav.Item as="li">
                                    <a href={'/blogger'}>Blogg</a>
                                </Nav.Item>
                            </React.Fragment>

                        ):(
                            <React.Fragment>
                                {
                                    activeHeader?.items?.map((item, key) => {
                                        return(
                                            <React.Fragment>
                                                <Nav.Item key={key} as="li" className={`${item.isMega ? classes.megaMenu : ''}`} onMouseEnter={item.isMega ? handleMouseEnter : null} onMouseLeave={item.isMega ? handleMouseLeave : null} >
                                                    <a href={item.url}>{item.title}</a>
                                                    {item.isMega && <div className={classes.megamenu}>
                                                        <Container className={'clearfix'}>
                                                            <Nav as={'ul'}>
                                                                {item.children.map((child, index) => {
                                                                    return child.description ? <Nav.Item key={index} as="li">
                                                                        <div className={classes.box}>
                                                                            <a href={child.url} className={classes.imgBox}>
                                                                                <i className={child.icon}></i>
                                                                                {/*<img src={child.icon} onError={onError}/>*/}
                                                                            </a>
                                                                            <div className={classes.description}>
                                                                                <h5><a href={child.url}>{child.title}</a></h5>
                                                                                <p><a href={child.url}>{child.description}</a></p>
                                                                                <Button
                                                                                    url={child.url}
                                                                                    icon={"far fa-arrow-right"}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Nav.Item> : <Nav.Item as="li" key={index}>
                                                                        <a href={child.url}>
                                                                            <div className={classes.imgBox}>
                                                                                <i className={child.icon}></i>
                                                                                {/*<img src={child.icon} onError={onError}/>*/}
                                                                            </div>
                                                                            {child.title}
                                                                        </a>
                                                                    </Nav.Item>
                                                                })}


                                                            </Nav>
                                                        </Container>
                                                    </div>}
                                                    {!item.isMega && item.children.length > 0 &&
                                                        <Nav as="ul">
                                                            {item.children.map((child, i) => <Nav.Item key={i} as="li">
                                                                <a href={child.url}>
                                                                    <div className={classes.imgBox}>
                                                                        <i className={child.icon}></i>
                                                                    </div>
                                                                    {child.title}
                                                                </a>
                                                            </Nav.Item>)}
                                                        </Nav>}
                                                </Nav.Item>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </React.Fragment>
                        )}

                    </Nav>
                </div>}
                {userDashboardNav ? (
                    <React.Fragment>
                        {/*<Notification />*/}
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {/*<Search toggleNav={toggleNav}  />*/}
                    </React.Fragment>
                )}
                <UserDropDown userInfo={{ firstName: auth?.firstName, lastName: auth?.lastName }} isLoggedIn={auth?.jwToken && auth.jwToken !== '' && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()} handleLogout={() => {
                    localStorage.removeItem('auth');
                    window.location.href = '/';

                }}/>
                {userDashboardNav ? (
                    <Button
                        icon={'icon-icon44'}
                        variant={'navSidebarOpener'}
                        onClick={toggleSidebar}
                    />
                ) : (
                    <Button
                        icon={'icon-icon44'}
                        //icon={userDashboardNav ? '' : 'fal fa-bars'}
                        variant={'navOpener'}
                        onClick={handleNavOpenerClick}
                    />
                )}

            </nav >
        </>
    );
};

export default Navigation;
