import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {Table} from "react-bootstrap";

const Assets = ({ details }) => {
    const formatter = Intl.NumberFormat('no-NO');
    const [dates, setDates] = useState([]);
    const [data, setData] = useState({});
    const [fsData, setFsData] = useState({});
    const format = (value, divideBy1000 = true) => isNaN(value) ? 0 : formatter.format(value / (divideBy1000 ? 1000 : 1))
    useEffect(() => {
        setDates(details?.financialStatements?.map(fs => moment(fs.yearEndDate).format('DD/MM/YYYY')));
        const transformedObject = details?.localFinancialStatements?.map(({balanceSheet}) => balanceSheet)?.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr[key]);
            });
            return acc;
        }, {});
        const fsObject = details?.financialStatements?.map(({balanceSheet}) => balanceSheet)?.reduce((acc, curr) => {
            Object.keys(curr).forEach(key => {
                if (!acc[key]) {
                    acc[key] = [];
                }
                acc[key].push(curr[key]);
            });
            return acc;
        }, {});
        setData(transformedObject)
        setFsData(fsObject)
    }, [details]);

    const getPercentage = (number, array, index, attachSymbol = false) => {
        if (index < 0 || index >= array.length - 1) {
            return null; // Index out of range or no next index
        }
    
        const currentValue = Math.abs(array[index]);
        const nextValue = Math.abs(array[index + 1]);
    
        let percentageValue = ((currentValue - nextValue) / nextValue) * 100;
        // const percentageValue = ((array[index - 1] - number) / number) * 100;
        if(isNaN(percentageValue) || percentageValue === 0 || percentageValue === Infinity || percentageValue === -Infinity)
        return '-'
        percentageValue = percentageValue.toFixed(1);
        if(Math.abs(percentageValue) > 1000) percentageValue = (percentageValue / 1000).toFixed(1) + (attachSymbol ? 'K' : 0)
        return attachSymbol ? percentageValue + ' %' : percentageValue;
    }
    const getRow = (arr) => {
        if(!arr)
            return <>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
                <td>-</td>
            </>
        return arr?.map((number, index) => {
            return <React.Fragment key={index}>
                <td key={`${index}-number`} colSpan={1}>
                    {format(number) ?? '-'}
                </td>
                {index < arr?.length - 1 && (
                    <td key={`${index}-percentage`} colSpan={1}>
                        <span className={isNaN(getPercentage(number, arr, index)) ? '' : getPercentage(number, arr, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, arr, index, true)}<i className={isNaN(getPercentage(number, arr, index)) ? '' : getPercentage(number, arr, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                    </td>
                )}
            </React.Fragment>
        })
    }
    return(
        <React.Fragment>
            <h5>Eiendeler</h5>
            <div className={'mb-5 table-responsive'}>
                <Table className={'border'} style={{minWidth: "850px"}}>
                    <thead>
                    <tr>
                        <th>Name</th>
                        {dates?.map((date, index) => (
                            <React.Fragment key={index}>
                                <th colSpan={1}>{date}</th>
                                {index < dates.length - 1 && <th colSpan={1}></th>}
                            </React.Fragment>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>Forskning og utvikling</th>
                        {getRow(data?.researchAndDevelopment)}
                    </tr>
                    <tr>
                        <th>Konsesjoner, patenter, lisenser</th>
                        {getRow(data?.concessionsPatentsLicenses)}
                    </tr>
                    <tr>
                        <th>Utsatt skattefordel</th>
                        {getRow(data?.deferredTaxAsset)}
                    </tr>
                    <tr>
                        <th>Goodwill</th>
                        {getRow(data?.goodwill)}
                    </tr>
                    <tr>
                        <th>Andre immatrielle eiendeler</th>
                        {getRow(data?.otherIntangibleAssets)}
                    </tr>
                    <tr>
                        <th>Sum immaterielle aktiva</th>
                        {getRow(data?.totalIntangibleAssets)}
                    </tr>
                    <tr>
                        <th>Tomter, bygninger og annen fast eiendom</th>
                        {getRow(data?.landAndBuildings)}
                    </tr>
                    <tr>
                        <th>Maskiner og anlegg</th>
                        {getRow(data?.plantAndMachinery)}
                    </tr>
                    <tr>
                        <th>Skip, rigger, fly og lignende</th>
                        {getRow(data?.shipsRigsAircraft)}
                    </tr>
                    <tr>
                        <th>Driftsløsøre/inventar/verktøy/biler</th>
                        {getRow(data?.operatingEquipment)}
                    </tr>
                    <tr>
                        <th>Andre varige driftsmidler</th>
                        {getRow(data?.otherOperationFactors)}
                    </tr>
                    <tr>
                        <th>Sum varige driftsmidler</th>
                        {getRow(data?.totalTangibleAssets)}
                    </tr>
                    <tr>
                        <th>Aksjer/Investeringer i datterselskap</th>
                        {getRow(data?.investmentsInSubsidiaries)}
                    </tr>
                    <tr>
                        <th>Investeringer i annet selskap samme konsern</th>
                        {getRow(data?.investmentsInGroupCompanies)}
                    </tr>
                    <tr>
                        <th>Investeringer i tilknyttede selskap</th>
                        {getRow(data?.investmentsInAssociates)}
                    </tr>
                    <tr>
                        <th>Investeringer i aksjer og andeler</th>
                        {getRow(data?.sharesAndInterestsInGroup)}
                    </tr>
                    <tr>
                        <th>Obligasjoner og andre langsiktige fordr.</th>
                        {getRow(data?.accountReceivables)}
                    </tr>
                    <tr>
                        <th>Pensjonsmidler</th>
                        {getRow(data?.pensionFunds)}
                    </tr>
                    <tr>
                        <th>Andre finansielle anleggsmidler</th>
                        {getRow(data?.otherFinancialFixedAssets)}
                    </tr>
                    <tr>
                        <th>Obligasjoner</th>
                        {getRow(data?.bonds)}
                    </tr>
                    <tr>
                        <th>Sum finansielle anleggsmidler</th>
                        {getRow(data?.totalFinancialFixedAssets)}
                    </tr>
                    <tr>
                        <th>Andre anleggsmidler</th>
                        {getRow(fsData?.totalOtherFixedAssets)}
                    </tr>
                    <tr>
                        <th>Sum anleggsmidler</th>
                        {getRow(fsData?.totalFixedAssets)}
                    </tr>
                    <tr>
                        <th>Råvarer og innkjøpte halvfabrikata</th>
                        {getRow(fsData?.rawMaterials)}
                    </tr>
                    <tr>
                        <th>Ferdigvarer</th>
                        {getRow(fsData?.finishedGoods)}
                    </tr>
                    <tr>
                        <th>Sum varelager</th>
                        {getRow(fsData?.totalInventories)}
                    </tr>
                    <tr>
                        <th>Andre varer</th>
                        {getRow(fsData?.otherInventories)}
                    </tr>
                    <tr>
                        <th>Sum varer</th>
                        {getRow(fsData?.totalInventories)}
                    </tr>
                    <tr>
                        <th>Kundefordringer</th>
                        {getRow(data?.tradeReceivables)}
                    </tr>
                    <tr>
                        <th>Andre fordringer</th>
                        {getRow(data?.otherReceivables)}
                    </tr>
                    <tr>
                        <th>Konsernfordringer</th>
                        {getRow(fsData?.groupReceivables)}
                    </tr>
                    <tr>
                        <th>Sum fordringer</th>
                        {getRow(fsData?.totalReceivables)}
                    </tr>
                    <tr>
                        <th>Aksjer og andeler i samme konsern</th>
                        {getRow(data?.investmentsInGroup)}
                    </tr>
                    <tr>
                        <th>Markedsbaserte aksjer</th>
                        {getRow(data?.quotedInvestmentShares)}
                    </tr>
                    <tr>
                        <th>Markedsbaserte obligasjoner</th>
                        {getRow(data?.quotedBonds)}
                    </tr>
                    <tr>
                        <th>Andre markedsbaserte finansielle instr.</th>
                        {getRow(data?.otherMarketeBasedFinanialInstruments)}
                    </tr>
                    <tr>
                        <th>Andre investeringer</th>
                        {getRow(data?.otherInvestments)}
                    </tr>
                    <tr>
                        <th>Sum investeringer</th>
                        {getRow(data?.totalInvestments)}
                    </tr>
                    <tr>
                        <th>Kasse/Bank/Post</th>
                        {getRow(data?.cashAndBankDeposits)}
                    </tr>
                    <tr>
                        <th>Sum Kasse/Bank/Post</th>
                        {getRow(data?.totalFunds)}
                    </tr>
                    <tr>
                        <th>Andre omløpsmidler</th>
                        {getRow(fsData?.otherCurrentAssets)}
                    </tr>
                    <tr>
                        <th>Sum omløpsmidler</th>
                        {getRow(fsData?.totalCurrentAssets)}
                    </tr>
                    <tr>
                        <th>Sum eiendeler</th>
                        {getRow(fsData?.totalAssets)}
                    </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default Assets;