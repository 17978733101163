import React from 'react';
import {Link} from "react-router-dom";
import classes from '../index.module.scss';
import { useEffect } from 'react';
import { getAllLenders } from 'Services/api/Lender';
import { useState } from 'react';
import { Container } from 'react-bootstrap';
import SectionTitle from 'Components/SectionTitle';
import { onError } from 'Services/api/Image';


const Lenders = ({ detail }) => {
    const [lenders, setLenders] = useState([]);
    const languageId = 1;
    useEffect(() => {
        getAllLenders(languageId)
        .then(({data}) => {
            setLenders(data?.filter(lender => detail?.lenders?.includes(lender.id)))
        })
    }, []);
    return(
        <React.Fragment>
            <section>
                <Container style={{maxWidth: '1450px'}}>
                    <div className={classes.lenderList}>
                        {lenders.map((item, index) => {
                            const lenderDetail = JSON.parse(item.detail);
                            return <div className={classes.col} key={index}>
                                <a href={`/långivere/${encodeURIComponent(lenderDetail?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} className={classes.box}>
                                    <div className={classes.imgBox}>
                                        <img src={lenderDetail?.lenderImage} loading="lazy" onError={onError} alt={lenderDetail?.title ?? ""} />
                                    </div>
                                    <div className={classes.description}>
                                        {lenderDetail?.title}
                                    </div>
                                </a>
                            </div>
                        })}
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default Lenders;