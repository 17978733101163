import { Validator } from 'fluentvalidation-ts'

type SecondStep = {
    email?: string;
    companyEmail?: string;
    phone?: string;
    terms?: boolean;
}
type FirstStep = {
    orgNumber?: string;
}
type ThirdStep = {
    purpose?: string
}

export class SecondStepValidator extends Validator<SecondStep>{
    constructor(){
        super();
        this.ruleFor('email')
        .emailAddress()
        .withMessage('Vennligst oppgi en gyldig e-postadresse.')
        .notEmpty()
        .withMessage('Vennligst oppgi en gyldig e-postadresse.')
        .notNull()
        .withMessage('Vennligst oppgi en gyldig e-postadresse.');

        this.ruleFor('companyEmail')
        .emailAddress()
        .withMessage('Vennligst oppgi en firma-e-postadresse.')
        .notEmpty()
        .withMessage('Vennligst oppgi en firma-e-postadresse')
        .notNull()
        .withMessage('Vennligst oppgi en firma-e-postadresse')

        this.ruleFor('phone')
        .notEmpty()
        .withMessage('Vennligst oppgi et telefonnummer.')
        .notNull()
        .withMessage('Vennligst oppgi et telefonnummer.')
        .must((phone: any) => /^[2-9]\d{7}$/.test(phone))
        .withMessage('Vennligst skriv inn et gyldig norsk telefonnummer')
    }
}
export class FirstStepValidator extends Validator<FirstStep>{
    constructor(){
        super();
        this.ruleFor('orgNumber')
        .notEmpty()
        .withMessage('Velg din organisasjon.')
        .notNull()
        .withMessage('Velg din organisasjon.');
    }
}
export class ThirdStepValidator extends Validator<ThirdStep>{
    constructor(){
        super();
        this.ruleFor('purpose')
        .notEmpty()
        .withMessage('Velg låneformål.')
        .notNull()
        .withMessage('Velg låneformål.')
    }
}