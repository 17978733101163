import React from 'react';
import {Page, Text, View, Image, Document, StyleSheet, Font} from '@react-pdf/renderer';
import PDFLogo from "./Logo";
import upperCircle from '../Images/upperCircle.png';
import bottomCircle from '../Images/bottomCircle.png';
import waterMark from '../Images/Watermark.png';
import MonaRegular from "../../../Fonts/Mona-Sans-Regular.woff";
import MonaBold from "../../../Fonts/Mona-Sans-Bold.woff";
import UserDetail from "./UserDetail";
import PDFFooter from "./Footer";


Font.register({
    family: 'Mona',
    fonts: [
        {src: MonaRegular, fontWeight: 'normal'},
        {src: MonaBold,fontWeight: 'bold'}
    ]
})

// Create styles
const styles = StyleSheet.create({
    page: {fontSize: 11,paddingTop: 40,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column',position: 'relative', fontFamily: 'Mona' },
    frontPage: {position: 'relative',},
    pageContent: {display: "flex", flexDirection: 'column', justifyContent: 'center',},
    title: {fontSize: 20, fontWeight: 'bold', color: '#013367', display: 'block', width: '100%',},
    ssnNumber: {fontSize: 14, padding: '10px 10px 2px', backgroundColor: '#DEE2E5', margin: '100px 0 20px', display: 'inline-block', borderRadius: 5, maxWidth: 250, fontWeight: 'bold',},
    date: {fontSize: 11, display: 'block',},
    frontPageFooter: {position: 'absolute', bottom: 0,left: 0, right: 0, textAlign: 'right', fontSize: 11, letterSpacing: 2, padding: '40px 50px',},
    upperCircle: {width: '200px', height: '90px', position: "absolute", top: 0, right: 0,},
    bottomCircle: {width: 100, height: 100, position: "absolute", bottom: 0, left: 0, display: 'block'},
    waterMark: {width: 130, height: 528, position: "absolute", top: '30%', transform: 'translateY(-50%)', right: 0,}
});

// Create Document Component
const FrontPage = ({userData}) => {

    return(
        <Page size="A4" style={styles.page}>
            <Image style={styles.upperCircle} src={upperCircle} />
            <Image style={styles.bottomCircle} src={bottomCircle} />
            <Image  style={styles.waterMark} src={waterMark} alt={'#'} />
            <View style={styles.frontPage}>
                <PDFLogo />
                <UserDetail userData={userData} />
            </View>
            <PDFFooter />
        </Page>
    )
};

export default FrontPage;
