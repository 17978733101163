import img1 from '../../../Images/img46.svg';
import img2 from '../../../Images/img47.svg';
import img3 from '../../../Images/img48.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hvordan kan kredittvurdering forbedre mine finansieringsmuligheter?", class: "" },
            ]
        },
        content: 'En grundig kredittvurdering gir deg en dyp innsikt i risikoprofilen til kundene dine, noe som er avgjørende for å håndtere kundeporteføljen effektivt. Når du har nøyaktig og oppdatert informasjon, kan du treffe bedre beslutninger om ordre og betalinger, noe som styrker din bedrifts økonomiske helse og sikrer likviditet og fortjeneste. Dette posisjonerer bedriften din sterkere når du søker finansiering, da banker og investorer ser positivt på virksomheter som demonstrerer god kontroll over sine finansielle risikoer. Ved å opprette en profil hos EasyFinance, kan du få tilgang til disse viktige kredittvurderingene og dermed forbedre din evne til å tiltrekke deg finansiering.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#EBF3FB'

    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hvordan kan en kredittsjekk assistere min virksomhet ved ekspansjon til nye markeder?", class: "" },
            ]
        },
        content: 'Å ekspandere til nye markeder er både spennende og risikabelt. Kredittsjekk spiller en nøkkelrolle i å minimalisere risikoen for tap ved å gi deg innsikt i kredittverdigheten til potensielle kunder i disse nye markedene. Ved å identifisere og fokusere på kredittverdige kunder, kan din virksomhet unngå dårlige betalere og styrke sin markedsposisjon. EasyFinance gir deg verktøyene for å utføre disse kredittvurderingene, noe som hjelper deg å navigere og vokse trygt i nye markeder.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img2,
        horizontal: true
    },
    {
        id: 3,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hvorfor bør jeg opprette en profil hos EasyFinance for kredittvurdering?", class: "" },
            ]
        },
        content: 'Ved å opprette en profil hos EasyFinance, får du tilgang til en plattform som gjør kredittvurdering både enkel og tilgjengelig. Dette gir deg muligheten til å kontinuerlig overvåke og vurdere kredittverdigheten til både eksisterende og potensielle kunder. En sterk kredittvurderingspraksis kan øke bedriftens finansielle stabilitet, beskytte mot tap, og åpne for nye forretningsmuligheter. I tillegg, ved å være proaktiv med kredittvurderinger, signaliserer du til lånemarkedet at din bedrift er en ansvarlig lånetaker, noe som kan forbedre lånevilkårene. Med EasyFinance står du sterkere rustet til å gjøre informerte beslutninger som fremmer vekst og lønnsomhet.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img3,
        horizontal: false
    },
];

export default contentWithImgData;
