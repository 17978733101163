import React from 'react';
import { View, Image, StyleSheet, Text } from '@react-pdf/renderer';
import logo from "../Images/Efinance.png";

// Define styles for the child component
const styles = StyleSheet.create({
    logoBox: {
        flexDirection: 'column',
        marginBottom: 200,
    },
    logo: {
        width: 100,
    },
    smallLogo: {
        width: 70,
    },
    small: {
        marginBottom: 0
    }

});

// Define the child component
const PDFLogo = ({ small }) => (
    <View style={styles.logoBox}>
        <Image style={styles.logo} src={logo} />
    </View>
);

export default PDFLogo;
