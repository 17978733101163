import React from "react";
import { Accordion } from "react-bootstrap";
import classes from "../../index.module.scss";
import SectionTitle from "../../../../Components/SectionTitle";
import Button from "../../../../Components/Button";
import moment from "moment";

const PaymentRemarksList = ({ data }) => {
  const format = (value, divideBy100 = false) => {
    if (isNaN(value)) return 0;
    const formatter = Intl.NumberFormat("no-NO");
    if (isNaN(value)) return "N/A";
    if (divideBy100) return formatter.format(value / 100);
    return formatter.format(value);
  };
  const totalRemarks =
    data?.paymentRemarkDetails?.remarksOfPayment?.length || 0;
  const totalAmount = data?.paymentRemarkDetails?.remarksOfPayment?.reduce(
    (total, remark) => total + parseFloat(remark.amount || 0),
    0
  );

  const translateRemarkType = (type) => {
    if (!type || type === "") return type;
    return type
      ?.toLowerCase()
      ?.replace("debt collection", "Inkasso")
      ?.replace("debt arrangement", "Frivillig Gjeldsordning")
      ?.replace("forced debt settlement", "Tvungen Gjeldsordning")
      ?.replace("distraint", "Utlegg")
      ?.replace("nothing to distraint", "Intet til utlegg")
      ?.replace("attachment of earnings", "Lønnstrekk")
      ?.replace("enforcment proceedings", "Tvangsinnkreving");
  };

  return (
    <React.Fragment>
      <div className={classes.paymentNote}>
        <div className={"mb-5"}>
          <div className={classes.colRow}>
            <div className={classes.col}>Antall betalingsanmerkninger:</div>
            <div className={`${classes.col} font-20 fw-semibold text-right`}>
              {" "}
              {totalRemarks}
            </div>
          </div>
          <div className={classes.colRow}>
            <div className={classes.col}>Totalbeløp:</div>
            <div className={`${classes.col} font-20 fw-semibold text-right`}>
              {format(Math.round(totalAmount))} kr
            </div>
            {/*<div>Total Amount: NOK {format(totalAmount).replaceAll('.', ',')}</div>*/}
          </div>
        </div>
        <Accordion
          className={classes.accodiranWhite}
          defaultActiveKey={`paymentNote0`}
        >
          {data?.paymentRemarkDetails?.remarksOfPayment?.map(
            (remarks, index) => {
              return (
                <Accordion.Item eventKey={`paymentNote${index}`}>
                  <Accordion.Header>
                    <div className={classes.colRow}>
                      <i
                        className={
                          "far fa-exclamation-circle text-danger font-18"
                        }
                        style={{ marginRight: "10px" }}
                      ></i>
                      <div className={classes.col}>
                        {translateRemarkType(remarks?.type)}
                      </div>
                      <div className={classes.col}>
                        <b>{format(remarks?.amount)} kr</b>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className={classes.colRow}>
                      <div className={classes.col}>Dato:</div>
                      <div className={classes.col}>
                        <b>{moment(remarks?.date).format("DD-MM-YYYY")}</b>
                      </div>
                    </div>
                    <div className={classes.colRow}>
                      <div className={classes.col}>Kreditor:</div>
                      <div className={classes.col}>
                        <b>{remarks?.creditor}</b>
                      </div>
                    </div>
                    {/*<div className={classes.colRow}>
                                <div className={classes.col}>Tilstand:</div>
                                <div className={classes.col}>{remarks?.status}</div>
                            </div>*/}
                    {/* <div className={classes.colRow}>
                                <div className={classes.col}>Type:</div>
                                <div className={classes.col}><b>{remarks?.type}</b></div>
                            </div>*/}
                    <div className={classes.colRow}>
                      <div className={classes.col}>Beløp:</div>
                      <div className={classes.col}>
                        <b>{format(remarks?.amount)} kr</b>
                      </div>
                    </div>
                    <div className={classes.colRow}>
                      <div className={classes.col}>Saksnummer:</div>
                      <div className={classes.col}>
                        <b>
                          {remarks?.refNr && remarks?.refNr !== ""
                            ? remarks?.refNr
                            : "-"}
                        </b>
                      </div>
                    </div>
                    <div className={"font-15 p-2"}>
                      Kontakt {remarks?.creditor} for a vite dagens restsaldo pá
                      kravet
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              );
            }
          )}
        </Accordion>
        <div className={"font-11 pt-5"}>
          Denne informasjonen tilbys av{" "}
          <a href={"https://www.creditsafe.com/us/en.html"} target={"_blank"}>
            Creditsafe
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaymentRemarksList;
