import React from 'react';
import {Container} from "react-bootstrap";
import AllTrending from "../../Components/Blogs/TrendingPosts/AllTrending";


const AllTrendingPage = () => {
    return(
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <AllTrending />
                </Container>
            </section>
        </React.Fragment>
    )
}

export default AllTrendingPage;