import React from 'react';
import classes from '../index.module.scss';
import moment from 'moment';

const CompanyInfo = ({ details, soleProperty }) => {
    const formatter = Intl.NumberFormat('no-NO');
    const format = value => isNaN(value) ? 0 : formatter.format(value);
    return(
        <React.Fragment>
            <h5>Foretaksinformasjon</h5>
            {soleProperty ? <>
                <ul className={classes.dataList}>
                    <li>
                        <div className={classes.col}>Foretaksnavn</div>
                        <div className={classes.col}>{details?.basicInformation?.name && details?.basicInformation?.name !== '' ? details?.basicInformation?.name : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Organisasjonsnummer</div>
                        <div className={classes.col}>{details?.basicInformation?.companyNumber && details?.basicInformation?.companyNumber !== '' ? details?.basicInformation?.companyNumber : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Safenummer</div>
                        <div className={classes.col}>{details?.basicInformation?.safeNumber && details?.basicInformation?.safeNumber !== '' ? details?.basicInformation?.safeNumber : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Juridisk form</div>
                        <div className={classes.col}>{details?.basicInformation?.legalForm && details?.basicInformation?.legalForm !== '' ? details?.basicInformation?.legalForm : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Registreringstype</div>
                        <div className={classes.col}>{details?.basicInformation?.registerType && details?.basicInformation?.registerType !== '' ? details?.basicInformation?.registerType : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Statuskode</div>
                        <div className={classes.col}>{details?.basicInformation?.statusCode && details?.basicInformation?.statusCode !== '' ? details?.basicInformation?.statusCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Statustekst</div>
                        <div className={classes.col}>{details?.basicInformation?.statusText && details?.basicInformation?.statusText !== '' ? details?.basicInformation?.statusText : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Registreringsdato</div>
                        <div className={classes.col}>{details?.basicInformation?.registrationDate && details?.basicInformation?.registrationDate !== '' ? moment(details?.basicInformation?.registrationDate).format('DD-MM-YYYY') : ''}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Hovedbransjekode</div>
                        <div className={classes.col}>{details?.basicInformation?.mainIndustryCode && details?.basicInformation?.mainIndustryCode !== '' ? details?.basicInformation?.mainIndustryCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Hovedbransjebeskrivelse</div>
                        <div className={classes.col}>{details?.basicInformation?.mainIndustryDescription && details?.basicInformation?.mainIndustryDescription !== '' ? details?.basicInformation?.mainIndustryDescription : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Sekundær industrikode</div>
                        <div className={classes.col}>{details?.basicInformation?.secondaryIndustryCode && details?.basicInformation?.secondaryIndustryCode !== '' ? details?.basicInformation?.secondaryIndustryCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Sekundærbransjebeskrivelse</div>
                        <div className={classes.col}>{details?.basicInformation?.secondaryIndustryDescription && details?.basicInformation?.secondaryIndustryDescription !== '' ? details?.basicInformation?.secondaryIndustryDescription : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Annen bransjekode</div>
                        <div className={classes.col}>{details?.basicInformation?.otherIndustryCode && details?.basicInformation?.otherIndustryCode !== '' ? details?.basicInformation?.otherIndustryCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Annen bransjebeskrivelse</div>
                        <div className={classes.col}>{details?.basicInformation?.otherIndustryDescription && details?.basicInformation?.otherIndustryDescription !== '' ? details?.basicInformation?.otherIndustryDescription : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Sektorkode</div>
                        <div className={classes.col}>{details?.basicInformation?.sectorCode && details?.basicInformation?.sectorCode !== '' ? details?.basicInformation?.sectorCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Adresse</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.address && details?.basicInformation?.address?.address !== '' ? details?.basicInformation?.address?.address : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>By</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.city && details?.basicInformation?.address?.city !== '' ? details?.basicInformation?.address?.city : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Kommunekodeks</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.municipalityCode && details?.basicInformation?.address?.municipalityCode !== '' ? details?.basicInformation?.address?.municipalityCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Kommunenavn</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.municipalityName && details?.basicInformation?.address?.municipalityName !== '' ? details?.basicInformation?.address?.municipalityName : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Fylkeskode</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.countyCode && details?.basicInformation?.address?.countyCode !== '' ? details?.basicInformation?.address?.countyCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Fylkesnavn</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.countyName && details?.basicInformation?.address?.countyName !== '' ? details?.basicInformation?.address?.countyName : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Postnummer</div>
                        <div className={classes.col}>{details?.basicInformation?.address?.postCode && details?.basicInformation?.address?.postCode !== '' ? details?.basicInformation?.address?.postCode : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Registrerte eiendommer</div>
                        <div className={classes.col}>{details?.basicInformation?.noRegisteredProperties && details?.basicInformation?.noRegisteredProperties !== '' ? details?.basicInformation?.noRegisteredProperties : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Frivillige løfter</div>
                        <div className={classes.col}>{details?.basicInformation?.noVoluntaryPledges && details?.basicInformation?.noVoluntaryPledges !== '' ? details?.basicInformation?.noVoluntaryPledges : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Betalingsanmerkninger</div>
                        <div className={classes.col}>{details?.basicInformation?.noPaymentRemarks && details?.basicInformation?.noPaymentRemarks !== '' ? details?.basicInformation?.noPaymentRemarks : '-'}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Name</div>
                        <div className={classes.col}>{details?.basicInformation?.name && details?.basicInformation?.name !== '' ? details?.basicInformation?.name : '-'}</div>
                    </li>
                </ul>
            </> : <>
                <ul className={classes.dataList}>
                    <li>
                        <div className={classes.col}>Foretaksnavn</div>
                        <div className={classes.col}>{details?.report?.companySummary?.businessName}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Organisasjonsnummer</div>
                        <div className={classes.col}>{details?.report?.companySummary?.companyRegistrationNumber}</div>
                    </li>
                    <li>
                        <div className={classes.col}>Safenummer </div>
                        <div className={classes.col}>{details?.report?.companySummary?.companyNumber} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Registreringsdato</div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.companyRegistrationDate && details?.report?.companyIdentification?.basicInformation?.companyRegistrationDate !== '' ? moment(details?.report?.companyIdentification?.basicInformation?.companyRegistrationDate).format('DD/MM/YYYY') : '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Stiftelsesdato </div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.operationsStartDate && details?.report?.companyIdentification?.basicInformation?.operationsStartDate !== '' ? moment(details?.report?.companyIdentification?.basicInformation?.operationsStartDate).format('DD/MM/YYYY') : '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Foretaksform </div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.legalForm?.description} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Status </div>
                        <div className={classes.col}>{details?.report?.companySummary?.companyStatus?.status?.replace('Active', 'Aktivt')} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Aksjekapital </div>
                        <div className={classes.col}>{format(details?.report?.shareCapitalStructure?.issuedShareCapital?.value)} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Foretaksregisteret </div>
                        <div className={classes.col}>{details?.report?.additionalInformation?.misc?.registerOfBusiness === true || details?.report?.additionalInformation?.misc?.registerOfBusiness === false ? details?.report?.additionalInformation?.misc?.registerOfBusiness ? 'Ja' : 'Nei' : '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Registrert for MVA </div>
                        <div className={classes.col}>{details?.report?.additionalInformation?.misc?.registerOfVat === true || details?.report?.additionalInformation?.misc?.registerOfVat === false ? details?.report?.additionalInformation?.misc?.registerOfVat ? 'Ja' : 'Nei' : '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>MVA-nummer </div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.vatRegistrationNumber && details?.report?.companyIdentification?.basicInformation?.vatRegistrationNumber !== '' ? details?.report?.companyIdentification?.basicInformation?.vatRegistrationNumber : '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Bransje </div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.principalActivity?.description} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Adresse </div>
                        <div className={classes.col}>{details?.report?.contactInformation?.mainAddress?.simpleValue} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Postnummer </div>
                        <div className={classes.col}>{details?.report?.contactInformation?.mainAddress?.postalCode} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Sted </div>
                        <div className={classes.col}>{details?.report?.contactInformation?.mainAddress?.city} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Telefon </div>
                        <div className={classes.col}>{details?.report?.contactInformation?.mainAddress?.telephone && details?.report?.contactInformation?.mainAddress?.telephone !== '' ? details?.report?.contactInformation?.mainAddress?.telephone : '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Hovedbransje (kode og beskrivelse) </div>
                        <div className={classes.col}> {details?.report?.companyIdentification?.basicInformation?.principalActivity?.code} {details?.report?.companyIdentification?.basicInformation?.principalActivity?.description} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Sekundær bransje (kode og beskrivelse)</div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.secondaryActivity ? `${details?.report?.companyIdentification?.basicInformation?.secondaryActivity?.code} ${details?.report?.companyIdentification?.basicInformation?.secondaryActivity?.description}`: '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Annen bransje (kode og beskrivelse)</div>
                        <div className={classes.col}>{details?.report?.companyIdentification?.basicInformation?.otherActivity ? `${details?.report?.companyIdentification?.basicInformation?.otherActivity?.code} ${details?.report?.companyIdentification?.basicInformation?.otherActivity?.description}`: '-'} </div>
                    </li>
                    <li>
                        <div className={classes.col}>Sektor (kode og beskrivelse)</div>
                        <div className={classes.col}>{ details?.report?.additionalInformation?.misc?.sectorCode } - { details?.report?.additionalInformation?.misc?.sectorDescription }</div>
                    </li>
                </ul>

            </>}

        </React.Fragment>
    )
}

export default CompanyInfo;