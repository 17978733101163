import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import LatestPosts from "../../Components/Blogs/LatestPosts";
import TrendingPosts from "../../Components/Blogs/TrendingPosts";
import EditorChoice from "../../Components/Blogs/EditorChoicePosts";
import BestOfWeekPosts from "../../Components/Blogs/BestOfWeekPosts";
import OtherBlogs from "../../Components/Blogs/OtherBlogs";
import iamge from "../../Images/img05.jpg";
import CompaignBanner from "../../Components/CompaignBanner";
import ComparisonTabsBox from "../../Components/ComparisonTabsBox";
import CollectionList from "../../Components/Blogs/CollectionList";


const Blogs = () => {
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <Row>
                      <Col xl={9}>
                          <LatestPosts />
                          <TrendingPosts />
                          <EditorChoice />
                          <BestOfWeekPosts />
                          <OtherBlogs />
                      </Col>
                      <Col xl={3}>
                          {/* <CompaignBanner title={"Compaign Title"} image={iamge} />
                          <ComparisonTabsBox className={'p-0'} textSmall />
                          <CollectionList categories /> */}
                      </Col>
                  </Row>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default Blogs;