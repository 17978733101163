import React, { useState } from 'react';
import classes from './index.module.scss';

const QuickLinks = ({ title, navigation, name }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <React.Fragment>
            <h6 className={classes.title} onClick={toggleOpen}>
                {title}
            </h6>
            <ul className={`${classes.quickLinks} ${isOpen ? classes.open : ''}`}>
                {navigation.map((item, index) => (
                    <li key={index}>
                        <a href={item.url}>{item.title}</a>
                    </li>
                ))}
            </ul>
        </React.Fragment>
    );
};

export default QuickLinks;
