import React, { useState, useEffect } from 'react';
import classes from './index.module.scss';

const StarRating = ({ initialRating, rating, ratingBox }) => {
    const [currentRating, setCurrentRating] = useState(initialRating || rating || 0);
    const maxStars = 5;

    // Update currentRating state when rating prop changes
    useEffect(() => {
        if (rating !== undefined) {
            setCurrentRating(rating);
        }
    }, [rating]);

    const handleRatingChange = (newRating) => {
        setCurrentRating(newRating);
    };

    const renderStars = () => {
        const stars = [];
        const fullStars = Math.floor(currentRating);
        const hasHalfStar = currentRating % 1 !== 0;
        const emptyStars = maxStars - Math.ceil(currentRating);

        for (let i = 1; i <= maxStars; i++) {
            let starClass = 'star empty';

            if (i <= fullStars) {
                starClass = `${classes.ratingColor} fas fa-star`;
            } else if (i === fullStars + 1 && hasHalfStar) {
                starClass = `${classes.ratingColor} fas fa-star-half-alt`;
            }

            stars.push(
                <i key={i} onClick={() => handleRatingChange(i)} className={starClass}></i>
            );
        }

        // Add any remaining empty stars if the rating is less than 5
        for (let i = 1; i <= emptyStars; i++) {
            stars.push(
                <i key={`empty-${i}`} onClick={() => handleRatingChange(fullStars + i)} className="fas fa-star"></i>
            );
        }

        return stars;
    };

    return (
        <React.Fragment>
            <div className={classes.ratings}>
                {rating !== undefined && (
                    <span className={`${ratingBox ? `${classes.ratingBox}` : '' }`}>{rating}</span>
                )}
                {renderStars()}
            </div>
        </React.Fragment>
    );
};

export default StarRating;
