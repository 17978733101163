import icon1 from "../../Images/er-logo-bredband-homenet.svg";
import icon2 from "../../Images/er-logo-dagligbank-bulder-bank.svg";
import icon3 from "../../Images/er-logo-dagligbank-dnb.svg";
import icon4 from "../../Images/er-logo-dagligbank-nordea.svg";
import icon5 from "../../Images/er-logo-forsikring-fremtind.svg";
import icon6 from "../../Images/er-logo-forsikring-tryg.svg";
import icon7 from "../../Images/er-logo-kredittbank-bb-finans.svg";
import icon8 from "../../Images/er-logo-kredittbank-santander.svg";
import icon9 from "../../Images/er-logo-strom-agva.svg";
import icon10 from "../../Images/er-logo-strom-fjordkraft.svg";
import icon11 from "../../Images/er-logo-strom-fortum.svg";
import icon12 from "../../Images/er-logo-strom-motkraft.svg";
import icon13 from "../../Images/er-logo-strom-norges-energi.svg";
import icon14 from "../../Images/er-logo-telefoni-phonero.svg";
import icon15 from "../../Images/er-logo-telefoni-icenet.svg";
import icon16 from "../../Images/er-logo-telefoni-release.svg";
import icon17 from "../../Images/er-logo-telefoni-talkmore.svg";
import icon18 from "../../Images/er-logo-telefoni-unifon.svg";
import img12 from "../../Images/img12.svg";
import pdtImg from "../../Images/dnb.svg";
import finansportalen from "../../Images/logoFinansportalen.svg";
import TienesteTorget from "../../Images/TienesteTorget.svg";
import langivere from "../../Images/langivere.svg";
import unofinans from "../../Images/unofinans.svg";
import okidaLogo from "../../Images/okidaLogo.svg";
import ZenFinans from "../../Images/ZenFinans.svg";

const data = [
    {
        id : '1',
        name : "Santander",
        icon : icon1,
        url: "/santander",
        title: 'Easyfinance with santander',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabIcon:'icon-icon1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabIcon:'icon-icon2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabIcon:'icon-icon2',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabIcon:'icon-icon3',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabIcon:'icon-icon4',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '4.46%',
                childData: [
                    {
                        produktpakke_tekst: 'Mortgage within 60%',
                        Lånetype: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                        paymentNote: 'Godtar',
                        EffInterestFrom: '5,16 % ',
                        requireSecurity: 'Ja',
                        age: '18 år',
                        estTotalCost: 'NOK 4,982,081',
                        loanLimit: '500000 - 800000',
                        debtInsurance: 'Yes',
                        marketArea : 'Local',
                        termFee: '50kr',
                        disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '2',
        name : "Motty",
        icon : icon2,
        url: "/motty",
        title: 'Easyfinance with Motty',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '3',
        name : "Axo finance review",
        icon : icon3,
        url: "/axo-finance ",
        title: 'Easyfinance with Axo finance',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '4',
        name : "Thorn privat finans review",
        icon : icon4,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '5',
        name : "Thorn privat finans review",
        icon : icon5,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '6',
        name : "Thorn privat finans review",
        icon : icon6,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '7',
        name : "Thorn privat finans review",
        icon : icon7,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '8',
        name : "Thorn privat finans review",
        icon : icon8,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '9',
        name : "Thorn privat finans review",
        icon : icon9,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '10',
        name : "Thorn privat finans review",
        icon : icon10,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '11',
        name : "Thorn privat finans review",
        icon : icon11,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '12',
        name : "Thorn privat finans review",
        icon : icon12,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '13',
        name : "Thorn privat finans review",
        icon : icon13,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],
        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '14',
        name : "Thorn privat finans review",
        icon : icon14,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],

        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '15',
        name : "Thorn privat finans review",
        icon : icon15,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],

        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '16',
        name : "Thorn privat finans review",
        icon : icon16,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],

        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '17',
        name : "Thorn privat finans review",
        icon : icon17,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],

        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },
    {
        id : '18',
        name : "Thorn privat finans review",
        icon : icon18,
        url: "/thorn-privat-finans ",
        title: 'Easyfinance with Thorn',
        contactInfo: [
            {
                secTitle: 'Contact Info',
                address: 'Postbox 1600 Centrum 0021 Oslo',
                phone: '915 04 800',
                Website: 'https://www.dnb.no',
            }
        ],
        openingHours: [
            {
                secTitle: 'Opening Hours',
                mondayToThursday: '08.00 – 22.00',
                friday: '08.00 – 18.00',
                saturday: '09.00 – 17.00',
                sunday: '12.00 – 21.00',
            }
        ],

        generalInfo : [
            {
                tabID: 'tab1',
                tabTitle: 'About DNB',
                content: 'DNB is Norway\'s largest financial group, and the second largest in the Nordics (after Nordea). The history of DNB goes all the way back to 1822, which at the time was called Christiania Sparebank. The bank changed its name to Oslo Sparebank in 1826. Throughout the 19th century, the bank grew steadily, and formed the basis for Den Norske Banks (DNB) - which was founded in 2003.',
            },
            {
                tabID: 'tab2',
                tabTitle: 'Benefits',
                content: 'tab 2 Content',
            },
            {
                tabID: 'tab3',
                tabTitle: 'Cons',
                content: 'tab 3 Content',
            },
            {
                tabID: 'tab4',
                tabTitle: 'Conditions for applying',
                content: 'tab 4 Content',
            },
            {
                tabID: 'tab5',
                tabTitle: 'Reviews',
                content: 'tab 5 Content',
            },
        ],
        historyTitle: 'History',
        historyDescription: 'According to the UN: " Good health is a fundamental prerequisite for people\'s ability to reach their full potential and to contribute to the development of society. How our health is affected by the environment, economy and social conditions". That is why we develop services that contribute to the prevention of mental health problems among our customers.',
        historyImg: img12,
        loanWithoutSecurity: [
            {
                dataType: 'Annonse',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: pdtImg,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        loanSecurity: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        credit: [
            {
                dataType: 'Annonse',
                image: unofinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Not cooperate',
                image: okidaLogo,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: ZenFinans,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: TienesteTorget,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
            {
                dataType: 'Cooperation',
                image: langivere,
                description: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
                rating: '4.5',
                loanAmount:'100 000 kr - 10 000 000 kr',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                mineAge: '18 år',
                loanDescription: 'Låneeksempel: Effektiv rente 5,34 % ved lån på 2 mill. over 25 år, kostnad kr. 1 582 298, totalt kr 3 582 298',
                childData: [
                    {
                        title: 'Mortgage within 60%',
                        loanType: 'Standard mortgage',
                        membershipRequire: 'No',
                        otherProducts: 'No',
                        maxLoanValueRatio: '60%',
                        maxiRepaymentPeriod: '30 years',
                        maxiInterestFreeYears: '0 years',
                        ageRestricted: '18 - 100 years',
                        nominalInterestRate: '4.46%',
                        effectiveInterestRate: '4.55%',
                        interestType: 'Floating interest rate',
                        pricePerMonths: 'NOK 16,607',
                        estTotalCost: 'NOK 4,982,081',
                        establishmentFree: 'NOK 0',
                        custodyFee: 'NOK 0',
                        poweredBy: finansportalen,
                        poweredByTitle: 'finansportalen',
                    },
                ]
            },
        ],
        reviews: [
            {
                reviewerName: 'Anthony Bahringer',
                postedDate: '29 April 2023',
                comment: 'I am very satisfied with the solution you made for me, my finances have now become livable and there is money left in the account when the new salary comes in.',
                reviewRating: '2.5',
            }
        ],
    },


];

export default data;