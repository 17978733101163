import React from 'react';
import SectionTitle from "../SectionTitle";
import data from "./data";
import classes from "./index.module.scss";
import { onError } from 'Services/api/Image';



const NotificationList = () => {
  return(
      <React.Fragment>
          <SectionTitle>

          </SectionTitle>
          <ul className={classes.notificationList}>
              {data.map((item, index) => (
                  <li key={index}>
                      <div className={classes.box}>
                          <div className={classes.imgBox}>
                              <img src={item.image} loading="lazy" onError={onError} alt={item.title} />
                          </div>
                          <div className={classes.description}>
                              <p>{item.description}</p>
                              <time>{item.timeStamp}</time>
                              {item.status && <span className={classes.status}></span>}
                          </div>
                      </div>
                  </li>
              ))}
          </ul>
      </React.Fragment>
  )
}

export default NotificationList;