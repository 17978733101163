import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SectionTitle from "../../../../../Components/SectionTitle";
import classes from './index.module.scss';
import RecommendedCards from "./RecommendedCards";

const RecommendedOffers = ({data}) => {
    const navigate = useNavigate();
    const [activeCategory, setActiveCategory] = useState(data[0]); // Set the first item as active by default
    const [isCardsVisible, setIsCardsVisible] = useState(true);
    const [isSmallDevice, setIsSmallDevice] = useState(false);

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        setIsCardsVisible(true);
    };


    useEffect(() => {
        const handleResize = () => {
            setIsSmallDevice(window.innerWidth < 768); // Adjust the width threshold as per your requirements
        };

        handleResize(); // Call once initially to set the correct state
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <React.Fragment>
            <div className={classes.recommendedOffers}>
                {/*<SectionTitle>
                    <span className={'fw-normal'}>Velg passende rente fra</span>
                    <span className={'fw-semibold'}>vår anbefaling</span>
                </SectionTitle>*/}
                <ul className={classes.recommendedOffersList}>
                    {data.map(item => (
                        <li key={item.dataType} id={`${isSmallDevice && isCardsVisible && activeCategory === item && `card${item.dataType}` }`} className={`${isSmallDevice && isCardsVisible && activeCategory === item && classes.spaceOnTop }`}>
                            <a
                                onClick={() => handleCategoryClick(item)}
                                className={`${classes.box} ${activeCategory === item ? classes.active : ''}`}
                                href={`#card${item.dataType}`}
                                style={{paddingRight: "50px"}}
                            >
                                <div className={classes.col}>{item.title}</div>
                                <div className={classes.col}>
                                    <span className={'font-12 mx-3'}>Eff. rente fra</span>
                                    <span className={'font-18 fw-semibold'}>{item.averageRent}%</span>
                                    <i className={`fas fa-angle-down ${classes.animatedArrow}`} ></i>
                                </div>
                            </a>
                            {isSmallDevice && isCardsVisible && activeCategory === item &&
                                <div>
                                    <RecommendedCards data={activeCategory.childData} activeCategory={activeCategory} faqsData={activeCategory.faqs} active={isCardsVisible} />
                                </div>
                            }
                        </li>
                    ))}
                </ul>
                {!isSmallDevice && isCardsVisible && activeCategory && <RecommendedCards data={activeCategory.childData} faqsData={activeCategory.faqs}  activeCategory={activeCategory} />}
            </div>
        </React.Fragment>
    );
};

export default RecommendedOffers;
