export const loadSettings = (languageId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/ReactComponent/GetAll`)
    .then(resp => {
        if(resp.status === 200) return resp.json();
        else reject(resp);
    })
    .then(resp => {
        const id = resp?.data?.find(component => component.name === 'Settings')?.id;
        if(!id) return reject({ message: 'No component found' });
        fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetAll?LanguageId=${languageId}&ReactComponentId=${id}&PublishedOnly=true`)
        .then(resp => {
            if(resp.status === 200)
                return resp.json();
            else reject(resp);
        })
        .then(resp => {
            const details = resp?.data[0]?.versionDetail;
            resolve(JSON.parse(details ?? '{}'))
        })
        .catch(reject)
    })
    .catch(reject)
})

export const loadSettingsByComponentId = (languageId, componentId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetAll?LanguageId=${languageId}&ReactComponentId=${componentId}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else reject(resp);
    })
    .then(resp => {
        const details = resp?.data[0]?.versionDetail;
        resolve(JSON.parse(details ?? '{}'))
    })
    .catch(reject)
})