import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import { Badge, Container } from "react-bootstrap";
import Slider from "react-slick";
import { Link, useParams } from "react-router-dom";
import Button from "../../Button";
import { getAllCategories } from "../../../Services/api/Blog";
import SectionTitle from "Components/SectionTitle";
import { useSelector } from "react-redux";

const CollectionList = ({ detail, categories }) => {
    const { id } = useParams(); // Get the selected category from the route parameter

    // const [fetchedCategories, setFetchedCategories] = useState([]);
    const cats = useSelector(state => state.blogs.categories);
    const fetchedCategories = cats?.filter(c => detail?.categories?.includes(c?.id));
    const languageId = 1;

    // useEffect(() => {
    //     getAllCategories(languageId)
    //         .then((resp) => {
    //             setFetchedCategories(resp?.data?.filter(c => detail?.categories?.includes(c.id)));
    //         })
    //         .catch((error) => {
    //             // Handle error, log or show an error message
    //             console.error("Error fetching categories:", error);
    //         });
    // }, []);

    const categoryCounters = fetchedCategories.reduce((counters, item) => {
        if (counters[item.category]) {
            counters[item.category].count++;
        } else {
            counters[item.category] = { count: 1 };
        }
        return counters;
    }, {});

    const totalCategories = Object.keys(categoryCounters).length;
    const totalProducts = fetchedCategories.length;


    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        //rows: 2,
        slidesPerRow: 1,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 776,
                settings: {
                    //rows: 1,
                    slidesToShow: 3,
                    dots: true,
                }
            },
            {
                breakpoint: 639,
                settings: {
                    //rows: 1,
                    slidesToShow: 2,
                    dots: true,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    //rows: 1,
                    slidesToShow: 1,
                    dots: true,
                }
            }
        ]
    };


    return (
        <React.Fragment>
            {categories ? (
                <section className={"section pb-0 position-sticky"}>
                    <Container>
                        <SectionTitle textCenter>
                            {detail?.heading?.title?.map((title) => (
                                <span className={title.class} key={title.id}>
                                    {title.title}
                                </span>
                            ))}
                            <span className={"fw-normal font-16"}>({fetchedCategories?.length})</span>
                        </SectionTitle>
                        <div className={`${classes.blogList} ${classes.list}`}>
                            {fetchedCategories?.map((category, index) => (
                                <div className={classes.col} key={index}>
                                    <a href={`/bloggkategorier/${category?.title?.toLowerCase()?.replace(' ', '-')}`}>
                                        {category?.title} <span className={'fw-semibold'}>{category?.blogsCount}</span>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </Container>
                </section>
            ) : (
                <section className={"section"}>
                    <Container>
                        <SectionTitle textCenter>
                            {detail?.heading?.title?.map((title) => (
                                <span className={title.class} key={title.id}>
                                    {title.title}
                                </span>
                            ))}
                        </SectionTitle>
                        <div className={classes.blogList}>
                            <Slider {...settings}>
                                {fetchedCategories.map((category, index) => {
                                    const postsInCategory = category?.blogsCount;
                                    const data = JSON.parse(category?.description ? category?.description : "{}");
                                    return (
                                        <div className={classes.col} key={index}>
                                            <div className={classes.box}>
                                                <a
                                                    href={`/bloggkategorier/${category?.title?.toLowerCase()?.replace(' ', '-')}`}
                                                    className={classes.imgBox}
                                                    style={{
                                                        backgroundImage: `url(${data.imgUrl})`
                                                    }}
                                                ></a>
                                                <div className={classes.description}>
                                                    <h3 className={classes.title}>
                                                        <a href={`/bloggkategorier/${category?.title?.toLowerCase().replace(' ', '-')}`}>
                                                            {category.title}
                                                            <i className={"fas fa-arrow-right"}></i>
                                                        </a>
                                                    </h3>
                                                    <Badge>{`${postsInCategory} ${postsInCategory === 1 ? 'post' : 'posts'}`}</Badge>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </Slider>
                            <div className={'pt-5 text-center'}>
                                <Button
                                    text={'Vis alle'}
                                    url={'/bloggkategorier'}
                                />
                            </div>
                        </div>
                    </Container>
                </section>
            )}
        </React.Fragment>
    );
};

export default CollectionList;
