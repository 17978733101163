import React, { useEffect, useState, useRef } from 'react';
import { Accordion, Col, Row, Tabs, Tab, Spinner, Alert } from "react-bootstrap";
import classes from './index.module.scss';
import CreditScore from "../../../../Components/Charts/CreditScore";
import Button from "../../../../Components/Button";
import Cards from "./Cards";
import RangeSlider from "../../../../Components/RangeSlider";
import Summary from "./Summary";
import CompanyScore from "./CompanyScore";
import PaymentHistory from "./PaymentHistory";
import Accounts from "./Accounts";
import DirectorOwnerShip from "./DirectorOwnerShip";
import FindYourCompany from "./FindYourCompany";
import { defaultCompanyReport, ensureDefaultCompany, forceUpdateCompanyScore, userCompanyDetails } from '../../../../Services/api/Company';
import moment from 'moment';
import { getConsumerReport } from '../../../../Services/api/Auth';
import Loader from "../../../../Components/Loader";
import CompanyNotExist from "../../DashboardComponent/CompanyNotExist";
import soleImg from "../../../../Images/solePropertyImg.svg";
import Loading from "../../../../Components/Loading";

const BusinessDashboard = () => {

    const [companyDetails, setCompanyDetails] = useState({});
    const [loading, setLoading] = useState(false);
    const [companyEngagements, setCompanyEngagements] = useState();
    const [companyEngagementsFetched, setCompanyEngagementsFetched] = useState(false);
    const [soleProperty, setSoleProperty] = useState(false);
    const [user, setUser] = useState();
    const [fetchError, setFetchError] = useState(true);
    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('auth'));
        setUser(user);
        setLoading(true);
        setFetchError(false);
        getConsumerReport()
            .then(({ companyEngagements }) => {
                setCompanyEngagementsFetched(true);
                setCompanyEngagements(companyEngagements)
                if (companyEngagements && companyEngagements?.length > 0 && !user?.hasDefaultCompany) {
                    window.location.href = '/find-your-company';
                }
                else if(companyEngagements && companyEngagements?.length > 0)
                    ensureDefaultCompany(false)
                        .then(comp => {
                            setCompanyDetails(comp);
                            setSoleProperty(comp?.basicInformation ? true : false);
                        })
                        .catch(() => { setFetchError(true)})
                        .finally(() => setLoading(false))
                else{
                    setLoading(false)
                }
            })
            .catch(() => {
                setLoading(false)
                setFetchError(true);
                setCompanyEngagementsFetched(true)
            })
    }, [])

    const _updateCompanyReport = () => {
        setLoading(true);
        forceUpdateCompanyScore(companyDetails?.report?.companySummary?.companyRegistrationNumber)
            .then(resp => {
                setCompanyDetails(resp);
            })
            .catch((err) => {
                if (err?.status === 203) {
                    // TODO: user is not subscribed
                }
            })
            .finally(() => setLoading(false))
    }

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    return (
        loading ? <Loading style={{position: 'absolute'}} /> : <React.Fragment>
            {user?.hasDefaultCompany ? <Accordion className={classes.companyList}>
                <Accordion.Item className={`${classes.accordionItem}`}>
                    <div className={classes.accordionHeader}>
                        <div className={classes.title}>
                            <FindYourCompany
                                useTitle
                                absolute
                                fromDashboard={true}
                                loadReport={setCompanyDetails}
                                company={companyDetails}
                            />
                        </div>
                        <div className={'d-flex  align-items-center'}>
                            <div className={'text-dark mx-3'}>
                                <span className={''}>Sist oppdatert:</span> <time className={'fw-semibold'}>{moment(companyDetails?.lastFetchedDate).format('DD-MM-YYYY')}</time>
                            </div>
                            <Button
                                onClick={_updateCompanyReport}
                                text={'Oppdater'}
                                icon={'far fa-sync-alt'}
                                iconLeft
                                variant={'border small'}
                            />
                        </div>
                    </div>
                    <Accordion.Body>
                        <Row>
                            <Col lg={4} md={6}>
                                <div className={classes.box}>
                                    {soleProperty ? <>
                                        <div className={classes.soleImg}>
                                            <img src={soleImg} loading="lazy" />
                                        </div>
                                        <div className={classes.soleText}>Det er begrenset informasjon vi kan innhente på selskapet ditt på grunn av selskapsform.</div>
                                    </> : <>
                                        {!loading && isNaN(companyDetails?.report?.creditScore?.currentCreditRating?.providerValue?.value) ? <Alert variant='danger'>Ingen score for foretaket - Score er ikke tilgjengelig på grunn av bransjeform</Alert> :
                                            <CreditScore score={{ currentCreditScore: { score: companyDetails?.report?.creditScore?.currentCreditRating?.providerValue?.value ?? 0, decision: companyDetails?.report?.creditScore?.currentCreditRating?.commonDescription ?? '' } }} />}

                                    </> }
                                </div>
                            </Col>
                            <Col lg={4} md={6}>
                                {soleProperty ? <>
                                    <Cards soleProperty solePropertyData={companyDetails} hasPaymentRemarks={companyDetails?.basicInformation?.noPaymentRemarks > 0} status={companyDetails?.basicInformation?.statusText}/>
                                </> : <>
                                    <Cards internationalScore={companyDetails?.report?.creditScore?.currentCreditRating?.commonValue} creditLimit={companyDetails?.report?.creditScore?.currentCreditRating?.creditLimit?.value} hasPaymentRemarks={companyDetails?.report?.negativeInformation?.paymentRemarks?.length > 0} financialStrength={companyDetails?.report?.indicators?.find(ind => ind?.type === 'fsi')?.value} status={companyDetails?.report?.companySummary?.companyStatus?.status} />
                                </>}
                            </Col>
                            <Col lg={4}>
                                <div className={`${classes.box} px-5 py-4 d-flex align-items-center w-100`} >
                                    <div className={' w-100'}>
                                        <h3 className={'text-center mb-4'}>Hvor mye ønsker du å låne?</h3>
                                        <RangeSlider
                                            onChange={() => { }}
                                            stepValue={'5000'}
                                            valueMin={'10000'}
                                            valueMax={'2000000'}
                                            defaultValue={'300000'}
                                            unit={'kr'}
                                            btnShow={true}
                                            btnText={'Søk nå'}
                                            showMinMax
                                            btnUrl={'/loan-application'}
                                        />
                                        <div className={'text-center font-12 pt-3'}>Lån mellom 10 000 kr og 2 millioner kr.
                                            Raskt og enkelt, akkurat når din bedrift trenger det som mest</div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <div className={classes.tabHolder}>
                            {soleProperty ? <>
                                {isMobile ?
                                    <Accordion className={classes.mobileAccordian}>
                                        <Accordion.Item eventKey="Summary">
                                            <Accordion.Header>Sammendrag</Accordion.Header>
                                            <Accordion.Body>
                                                <Summary soleProperty details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="PaymentHistory">
                                            <Accordion.Header>Betalingshistorikk</Accordion.Header>
                                            <Accordion.Body>
                                                <PaymentHistory soleProperty details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="DirectorsOwnership">
                                            <Accordion.Header>Ledelse og Eierskap</Accordion.Header>
                                            <Accordion.Body>
                                                <DirectorOwnerShip soleProperty details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    :
                                    <Tabs
                                        defaultActiveKey="Summary"
                                        className={classes.tabs}
                                    >
                                        <Tab eventKey="Summary" title="Sammendrag">
                                            <Summary soleProperty details={companyDetails} />
                                        </Tab>
                                        <Tab eventKey="PaymentHistory " title="Betalingshistorikk">
                                            <PaymentHistory soleProperty details={companyDetails} />
                                        </Tab>
                                        <Tab eventKey="DirectorsOwnership" title="Ledelse og Eierskap">
                                            <DirectorOwnerShip soleProperty details={companyDetails} />
                                        </Tab>
                                    </Tabs>
                                }
                            </> : <>
                                {isMobile ?
                                    <Accordion className={classes.mobileAccordian}>
                                        <Accordion.Item eventKey="Summary">
                                            <Accordion.Header>Sammendrag</Accordion.Header>
                                            <Accordion.Body>
                                                <Summary details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="CompanyScore">
                                            <Accordion.Header>Foretaksscore</Accordion.Header>
                                            <Accordion.Body>
                                                <CompanyScore details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="PaymentHistory">
                                            <Accordion.Header>Betalingshistorikk</Accordion.Header>
                                            <Accordion.Body>
                                                <PaymentHistory details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="Accounts">
                                            <Accordion.Header>Regnskap</Accordion.Header>
                                            <Accordion.Body>
                                                <Accounts details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="DirectorsOwnership">
                                            <Accordion.Header>Ledelse og Eierskap</Accordion.Header>
                                            <Accordion.Body>
                                                <DirectorOwnerShip details={companyDetails} />
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                    :
                                    <Tabs
                                        defaultActiveKey="Summary"
                                        className={classes.tabs}
                                    >
                                        <Tab eventKey="Summary" title="Sammendrag">
                                            <Summary details={companyDetails} />
                                        </Tab>
                                        <Tab eventKey="CompanyScore" title="Foretaksscore">
                                            <CompanyScore details={companyDetails} />
                                        </Tab>
                                        <Tab eventKey="PaymentHistory " title="Betalingshistorikk">
                                            <PaymentHistory details={companyDetails} />
                                        </Tab>
                                        <Tab eventKey="Accounts " title="Regnskap">
                                            <Accounts details={companyDetails} />
                                        </Tab>
                                        <Tab eventKey="DirectorsOwnership" title="Ledelse og Eierskap">
                                            <DirectorOwnerShip details={companyDetails} />
                                        </Tab>
                                    </Tabs>
                                }
                            </>}
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion> : fetchError ? <Alert variant='danger'>Det oppstod en feil under forsøk på å laste inn rapporten.</Alert> : !companyEngagements || companyEngagements.length === 0 ? <div>
                <CompanyNotExist />
            </div> : <Loader variant={'info'}  />}
        </React.Fragment>
    )
}

export default BusinessDashboard;