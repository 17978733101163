import React, { useEffect, useState } from 'react';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Container } from "react-bootstrap";
import ScoreDocument from "./ScoreDocument";
import { getCurrentUser } from "../../../../Services/api/Auth";
import { getCompleteRealEstateData } from "../../../../Services/api/RealEstate";

const HouseValuePDF = () => {
    const [userData, setUserData] = useState({ firstName: '', lastName: '', userName: '' });
    const [user, setUser] = useState('');
    const [houseData, setHouseData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalProperties, setTotalProperties] = useState(0);

    useEffect(() => {
        getCurrentUser().then(user => setUserData(user));
        loadRealEstateData();
    }, []);

    const loadRealEstateData = async () => {
        setLoading(true);
        try {
            const completeRealEstateResp = await getCompleteRealEstateData();
            console.log('Fetched house data:', completeRealEstateResp); // Debugging: log fetched data
            setHouseData(completeRealEstateResp);
            calculateTotalProperties(completeRealEstateResp);
        } catch (error) {
            console.error("Failed to load real estate data:", error);
        } finally {
            setLoading(false);
        }
    };

    const calculateTotalProperties = (data) => {
        const total = Array.isArray(data) ? data.length : 0;
        console.log('Total properties calculated:', total); // Debugging: log calculated total
        setTotalProperties(total);
    };


    const styles = StyleSheet.create({
        pdfView: {
            width: '100%',
            height: '1200px',
        }
    });

    console.log('userData:', userData); // Log the userData state
    console.log('houseData:', houseData); // Log the houseData state
    console.log('totalProperties:', totalProperties); // Log the totalProperties state

    return (
        <Container>
            <PDFViewer style={styles.pdfView}>
                <ScoreDocument userData={userData} houseData={houseData} totalProperties={totalProperties} />
            </PDFViewer>
        </Container>
    );
}

export default HouseValuePDF;
