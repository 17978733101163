import React, { useEffect, useState } from 'react';
import classes from '../../index.module.scss';
import {Row, Spinner} from "react-bootstrap";
import houseValueImg from "../../../../Images/houseValueImg.svg";
import HouseList from "./HouseList";
import AnnonseList from "../../DashboardComponent/AnnonseList";
import HouseValueRecommendations from "../../DashboardComponent/HouseValueRecommendations";
import { getCustomerRealEastate } from '../../../../Services/api/RealEstate';
import Loader from "../../../../Components/Loader";
import notFound from "../../../../Images/propertyNotFound.svg";
import Loading from "../../../../Components/Loading";


const HouseValue = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        loadRealEstateData();
    }, []);
    const loadRealEstateData = () => {
        setLoading(true);
        getCustomerRealEastate()
        .then(resp => {
            setData(resp.data);
        })
        .finally(() => setLoading(false))
    }
  return(
      loading ? <Loading style={{position: 'absolute'}} /> : <React.Fragment>
          <div className={'font-12 text-right mb-2'} style={{right: 0}}> Oppdatert 22/04/2024</div>
          {data?.length > 0 ? <>
              <Row className={`${classes.dashboardCards} ${classes.col2_1} ${classes.reverseRowMobile} pt-3`}>
                  <div className={classes.col}>
                      <p>"Min eiendom" gir deg en oversikt over dine eiendommer og tilhørende informasjon. I denne tjenesten kan du se den estimerte verdien av boligen din, kjøpshistorikk, verdivekst over tid og lånekapasitet basert på eiendomsverdien. Vi henter data fra flere pålitelige databaser for å gi deg disse estimatene. Vær oppmerksom på at tallene som presenteres kun er estimater og ikke endelige priser. For en mer nøyaktig verdivurdering, anbefaler vi at du tar kontakt med en eiendomsmegler for å få en offisiell takst på din bolig.</p>
                  </div>
                  <div className={classes.col}>
                      <div className={classes.imgBox} style={{textAlign: "center"}}>
                          <img src={houseValueImg} loading="lazy" alt='House Value'/>
                      </div>
                  </div>
              </Row>
              <HouseList data={data} />
              <HouseValueRecommendations />
              {/*<AnnonseList />*/}
          </> : <div className={classes.notFound}>
                  <h5>Vi klarer dessverre ikke å finne at du står som eier av en bolig i Norge.</h5>
                  <div className={classes.imgBox}>
                      <img src={notFound} loading="lazy" />
                  </div>
                  <p>Eller kan det være at du kanskje ikke har oppfylt boligdrømmen enda? </p>
              </div>
          }
      </React.Fragment>
  )
}

export default HouseValue;