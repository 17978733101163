import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import { onError } from "Services/api/Image";

const AdSectionLeftImg = ({ detail }) => {
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <SectionTitle textCenter description={detail?.heading?.description}>
                      {
                        detail?.heading?.title?.map(heading => <span key={heading.id} className={heading.class}>{heading.title} </span>)
                      }
                  </SectionTitle>
                  <Row className={'colList adLeftImg'}>
                      <Col lg={5}>
                          <div className="box p-0">
                              <div className="img-box">
                                  <a href={detail?.redirectUrl} target="_blank">
                                    <img src={detail?.featuredImage} loading="lazy" onError={onError} alt="Ad" />
                                    </a>
                              </div>
                          </div>
                      </Col>
                      <Col lg={7}>
                          <div className="box">
                              <ul className="order-list">
                                  {
                                    detail?.content?.map((content, index) => <li key={index}>
                                                                                <h3 className={'fw-medium'}>{content?.title}</h3>
                                                                                {content?.description}
                                                                            </li>)
                                  }
                              </ul>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default AdSectionLeftImg;