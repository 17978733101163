import React from 'react';
import classes from './index.module.scss';

const Loader = ({style}) => {
  return(
      <React.Fragment>
          <div className={classes.loader} style={style}>
              <div className={classes.circle}></div>
          </div>
      </React.Fragment>
  )
}

export default Loader;