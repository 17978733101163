import React, { useState, useEffect } from 'react';
import Highcharts, { format } from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import data from './data.json';
import classes from '../index.module.scss';
import Button from '../../Button';

HighchartsMore(Highcharts);

const legendItems = [
    { name: 'Forbrukslån', color: '#FD6D02' },
    { name: 'Kredittkort', color: '#FFC700' },
    // Add more legend items as needed
];

const YourDebt = ({ debt }) => {

    const { title, subtitle, debtLimit, usedDebt } = data;
    const [approvedCredit, setApprovedCredit] = useState(0);
    const [approvedLoan, setApprovedLoan] = useState(0)

    const formatter = Intl.NumberFormat('no-NO');
    const format = value => isNaN(value) ? 0 : formatter.format(value)

    const grapheIcon = () => {
        return `
                <div class="textBox">
                    <div class="iconBox">
                        <i class="icon-handkr"></i>
                    </div>
                </div>
            `;
    };

    useEffect(() => {

        const loans = debt?.loanTypes?.filter(({ type }) => type === 'repaymentLoan').flatMap(({ loans }) => loans);
        const cards = debt?.loanTypes?.filter(({ type }) => type === 'chargeCard' || type === 'creditFacility').flatMap(({ loans }) => loans);
        const originalBalance = loans?.map(({ balance }) => isNaN(Number(balance)) ? 0 : Number(balance))?.reduce((a, b) => a + b, 0)
        const totalCredit = cards?.map(({ nonInterestBearingBalance, interestBearingBalance }) => (isNaN(interestBearingBalance) ? 0 : Number(interestBearingBalance)) + (isNaN(nonInterestBearingBalance) ? 0 : Number(nonInterestBearingBalance)))?.reduce((a, b) => a + b, 0);
        setApprovedCredit(Math.round(totalCredit / 100));
        setApprovedLoan(Math.round(originalBalance / 100));
        const options = {
            chart: {
                type: 'pie',
                plotBackgroundColor: null,
                plotBorderWidth: 0,
                plotShadow: false,
                backgroundColor: null,
                renderTo: 'debtScore',
            },
            title: {
                text: subtitle,
                align: 'center',
                verticalAlign: 'bottom',
            },
            subtitle: {

                useHTML: true,
                text: grapheIcon(),
                floating: true,
                verticalAlign: 'middle',
                y: 0,
                x: 0,
            },
            tooltip: {
                headerFormat: '<b></b><br>',
                formatter: function() {
                    return '<b>' + this.point.name + '</b>: ' + this.point.percentage.toFixed(1) + '%';
                }
            },
            plotOptions: {
                pie: {
                    dataLabels: {
                        enabled: false
                    },
                    size: '100%',
                    borderWidth: 0,
                    borderRadius: 0,
                    colors: [{
                        linearGradient: {
                            cx: 0.5,
                            cy: 0.3,
                            r: 0.7,
                        },
                        stops: [
                            [0, '#FD6D02'],
                        ],
                    }, '#FFC700'],
                   // showInLegend: true,
                },
            },
            series: [
                {
                    type: 'pie',
                    innerSize: '85%',
                    data: [{name: 'Forbrukslån', y: Math.round(originalBalance / 100)}, {name: 'Kredittkort', y: Math.round(totalCredit / 100)}],

                },
            ],
        };

        let chart = new Highcharts.Chart(options);

        return () => {
            if (chart) {
                clearTimeout(chart.sequenceTimer);
                chart.destroy();
            }
        };
    }, [debtLimit, subtitle, title, usedDebt, debt]);

    const DataTitle = ({ usedDebt, debtLimit }) => {
        const totalDebt = Math.floor(usedDebt);
        const totalLimit = Math.floor(debtLimit);

        return (
            <React.Fragment>
                <div className={`${classes.dataTitle} text-center mb-0`} >
                    <h3 className={'mb-0'}>NOK { format(approvedCredit + approvedLoan) }</h3>
                    {/*<div className={classes.title}>Debt Limit: NOK {totalLimit}</div>*/}
                </div>
            </React.Fragment>
        );
    };

    const RenderLegend = () => {
        return (
            <ul className={`${classes.legendList} mb-3`}>
                {legendItems.map((item, index) => (
                    <li key={index} style={{ '--background': item.color }}>
                        <span>{item.name}</span>
                    </li>
                ))}
            </ul>
        );
    };


    return (
        <React.Fragment>
            <div className={classes.chartBox}>
                <div className={classes.chartUpperBox}>
                    <div id={'debtScore'} className={classes.container}></div>
                    <DataTitle usedDebt={usedDebt} debtLimit={debtLimit} />
                    {RenderLegend()}
                </div>

                {/* <div className={classes.chartFooter}>
                    <Button
                        text={'See your debt details'}
                        url={'/abc/'}
                        variant={'border'}
                    />
                </div> */}
            </div>
        </React.Fragment>
    );
};

export default YourDebt;
