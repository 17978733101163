const bannerData =  [
    {
      id: 1,
      subTitle: "",
      title: [
        { id: 1, title: "Kredittsjekk", class: "fw-normal" },
        { id: 2, title: " selskapet ditt", class: "fw-bold" },
      ],
      description:
      <>
          <p>Klar for å få verdifull innsikt i din forretnings økonomiske fremtid? Med EasyFinance er ditt første skritt mot finansiell klarhet bare et klikk unna – og det er helt gratis! Enten du vil undersøke kredittverdigheten til en forretningspartner eller få en dypere forståelse av ditt eget selskaps finansielle status, gir vi deg muligheten til å utføre din første kredittsjekk uten kostnad.</p>
          <p>Benytt anledningen til å oppdage hvorfor EasyFinance er det foretrukne valget for bedrifter som ønsker innsikt og trygghet. Vårt tilbud om en gratis første kredittsjekk er den perfekte starten for å styrke din bedrifts økonomiske beslutninger. Ikke vent – ta kontroll over din finansielle fremtid med EasyFinance i dag!</p>
      </>,
        btnText: "Sjekk din score nå! - Gratis",
        btnUrl: "/bedriftsdashbord",
        hideCalculator: true,
    }
  ]


export default bannerData;
