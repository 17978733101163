export const getBySlug = (slug, language) => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API}/ReactPage/GetBySlug?languageId=${language}&pageSlug=${encodeURIComponent(slug)}`)
        .then(resp => {
            if(resp.status === 200)
                resolve(resp.json())
            else
                reject(resp.json())
        })
        .catch(err => reject(err))
    })
}