import React, { useEffect, useState, useRef } from 'react';
import classes from "./index.module.scss";
import SectionTitle from '../../../Components/SectionTitle';
import { useSearchParams } from 'react-router-dom';
import {Col, Container, Form, Nav, Row} from "react-bootstrap";
import FilterBox from "../../../Components/FilterBox";
import ProductListing from "../../../Components/ProductListing";
import { getBSUData, getCreditCardData, getSecuredData, getUnsecuredData, loadInputSchema } from 'Services/api/Loan';
import LoanWithSecurity from '../../../Components/HomeFilterTabs/Loan/LoanWithSecurity';
import LoanWithoutSecurityFilter from 'Components/HomeFilterTabs/Loan/LoanWithoutSecurityFilter';
import CreditCardFilter from 'Components/HomeFilterTabs/Loan/CreditCardFilter';
import Savings from 'Components/HomeFilterTabs/Saving';
import { bsuSettings, creditCardSettings, securedLoanSettings, unsecuredSettings } from 'Services/Settings';
import _ from 'lodash';
import FixedScrollBlock from "../../../Components/FixedScrollBlock";
import { getVersionById } from '../../../Services/api/Component';


const TabsWithFilter = ({ detail }) => {

    //#region Loan States
    const [searchParams] = useSearchParams();
    const age = searchParams.get('age');
    //const totalSavings = searchParams.get('totalSavings');
    const totalSaved = searchParams.get('totalSaved');
    const totalOutstanding = searchParams.get('totalOutstanding');
    const repaymentPeriod = searchParams.get('repaymentPeriod');
    const housingValue = searchParams.get('propertyValue');
    const loanAmount = searchParams.get('loanAmount');
    const  annualIncome = searchParams.get('annualIncome');
    const currentLoanAmount = searchParams.get('currentLoanAmount');
    const productType = searchParams.get('productType');
    const purchaseAmount = searchParams.get('purchaseAmount');
    const additional = searchParams.get('additional')?.replace(/^,|,$/g, '')?.split(',')
    const languageId = 1;
    const activeTab = detail?.tabs?.find(tab => tab.isActive);

    const [currentSort, setCurrentSort] = useState({});

    //#region scroll block
    const [isFixed, setIsFixed] = useState(false);
    const contentRef = useRef(null);
    const sidebarRef = useRef(null);
    //#endregion
    //#region Component States
    const [products, setProducts] = useState([]); // add all products listing here
    const [pageNumber, setPageNumber] = useState(1);    // handle page numbering
    const [loading, setLoading] = useState(false);  // handle loading
    const [productsLoading, setProductsLoading] = useState(false);  // handle if products loading or not
    const [inputSchema, setInputSchema] = useState({}); // handle filtering
    //#endregion

    const [loanObject, setLoanObject] = useState({
        ...(activeTab?.component == 4 ? { SortByEffectiveInterest: true, IsAscendingOrder: false } : {}),
        loanTypeId: activeTab?.component,
        loanCategoryId: activeTab?.subComponent,
        loanType: productType ?? unsecuredSettings.loanType,
        SortByEffectiveInterest: true,
        IsAscendingOrder: true,
        additional
    });

    const handleShowMoreClick = () => {setPageNumber(pageNumber + 1); setLoading(true);};

    useEffect(() => {
        if(localStorage.getItem('searchParams'))
            try{
                const parsedObject = JSON.parse(localStorage.getItem('searchParams'));
                if(parsedObject)
                    setLoanObject(parsedObject);
                localStorage.removeItem('searchParams')
            }
            catch(err){}
    }, [])

    const _loadCreditCardData = (page) => {
        getVersionById(detail?.activeTab?.componentVersion)
        .then(resp => {
            const versionDetail = JSON.parse(resp.data.versionDetail)
            const creditCardObject = {
                languageId,
                loanTypeId: 3,
                loanCategoryId: activeTab?.subComponent,
                age: age ?? (versionDetail?.state?.ageDefaultValue && versionDetail?.state?.ageDefaultValue !== '' ? versionDetail?.state?.ageDefaultValue : creditCardSettings.age),
                yourIncome: annualIncome ?? (versionDetail?.state?.annualIncomeDefaultValue && versionDetail?.state?.annualIncomeDefaultValue !== '' ? versionDetail?.state?.annualIncomeDefaultValue : creditCardSettings.annualIncome),
                ... _.cloneDeep(loanObject),
                pageNumber: page ?? pageNumber,
            }
            getCreditCardData(creditCardObject)
            .then(resp => setProducts(pageNumber === 1 ? resp : [...products, ...resp]))
            .finally(() => {
                setProductsLoading(false);
                setLoading(false);
            });
        });
    }
    const _loadSecuredLoanData = (page) => {
        if(activeTab?.subComponent === 6){
            _loadUnsecuredData(page);
            return;
        }
        getVersionById(detail?.activeTab?.componentVersion)
        .then(resp => {
            const versionDetail = JSON.parse(resp.data.versionDetail);
            const securedObject = {
                languageId,
                loanTypeId: 1,
                loanCategoryId: activeTab?.subComponent,
                age: age ?? (versionDetail?.state?.ageDefaultValue && versionDetail?.state?.ageDefaultValue !== '' ? versionDetail?.state?.ageDefaultValue : securedLoanSettings.age),
                loanAmount: loanAmount ?? (versionDetail?.state?.defaultLoanAmount && versionDetail?.state?.defaultLoanAmount !== '' ? versionDetail?.state?.defaultLoanAmount : securedLoanSettings.loanAmount),
                housingValue: housingValue ?? (versionDetail?.state?.defaultProperyValue && versionDetail?.state?.defaultProperyValue !== '' ? versionDetail?.state?.defaultProperyValue : securedLoanSettings.housingValue),
                repaymentPeriod: repaymentPeriod ?? (versionDetail?.state?.defaultRepaymentPeriod && versionDetail?.state?.defaultRepaymentPeriod !== '' ? versionDetail?.state?.defaultRepaymentPeriod : securedLoanSettings.repaymentPeriod),
                ... _.cloneDeep(loanObject),
                pageNumber: page ?? pageNumber,
            }
            if(securedObject.loanType) delete securedObject.loanType;
            getSecuredData({...securedObject, pageNumber})
            .then(resp => setProducts(pageNumber === 1 ? resp : [...products, ...resp]))
            .finally(() => {
                setProductsLoading(false);
                setLoading(false);
            });
        })
    }
    const _loadUnsecuredData = (page) => {
        getVersionById(detail?.activeTab?.componentVersion)
        .then(resp => {
            const versionDetail = JSON.parse(resp.data.versionDetail)
            const unsecuredObject = {languageId,
                purchasePrice: purchaseAmount,
                loanType: productType ?? unsecuredSettings.loanType,
                loanTypeId: 2,
                loanCategoryId: activeTab?.subComponent,
                repaymentPeriod: repaymentPeriod ? Number(repaymentPeriod) * 12 : versionDetail?.state?.defaultRepaymentPeriod && versionDetail?.state?.defaultRepaymentPeriod !== '' ? Number(versionDetail?.state?.defaultRepaymentPeriod) * 12 : unsecuredSettings.repaymentPeriod,
                loanAmount: loanAmount ?? (versionDetail?.state?.defaultLoanAmount && versionDetail?.state?.versionDetail?.state?.defaultLoanAmount !== '' ? versionDetail?.state?.defaultLoanAmount : unsecuredSettings.loanAmount),
                ..._.cloneDeep(loanObject),
                pageNumber: page ?? pageNumber,
            };
            getUnsecuredData(unsecuredObject)
            .then(resp => setProducts(pageNumber === 1 ? resp : [...products, ...resp]))
            .finally(() => {
                setProductsLoading(false);
                setLoading(false);
            });
        })
    }
    const _loadBSUData = (page) => {
        getVersionById(detail?.activeTab?.componentVersion)
        .then(resp => {
            const versionDetail = JSON.parse(resp.data.versionDetail);
            const bsuObject = {
                languageId,
                loanTypeId: 4,
                loanCategoryId: activeTab?.subComponent,
                totalSaved: activeTab?.subComponent == 15 ? totalSaved ?? (versionDetail?.state?.savingsDefaultValue && versionDetail?.state?.savingsDefaultValue !== '' ? versionDetail?.state?.savingsDefaultValue : bsuSettings.totalSavings) : undefined,
                totalOutstanding: activeTab?.subComponent == 16 ? totalOutstanding ?? (versionDetail?.state?.savingsDefaultValue && versionDetail?.state?.savingsDefaultValue !== '' ? versionDetail?.state?.savingsDefaultValue : bsuSettings.totalSavings) : undefined,
                age: age ?? (versionDetail?.state?.ageDefaultValue && versionDetail?.state?.ageDefaultValue !== '' ? versionDetail?.state?.ageDefaultValue : bsuSettings.age),
                ..._.cloneDeep(loanObject),
                pageNumber: page ?? pageNumber,
            };
            getBSUData(bsuObject)
            .then(resp => setProducts(pageNumber === 1 ? resp : [...products, ...resp]))
            .finally(() => {
                setProductsLoading(false);
                setLoading(false);
            });
        });
    }
    useEffect(() => {
        setProductsLoading(true);
        setPageNumber(1);
        if(activeTab?.component === 1) _loadSecuredLoanData(1);
        else if(activeTab?.component === 2) _loadUnsecuredData(1);
        else if(activeTab?.component === 3) _loadCreditCardData(1);
        else if(activeTab?.component === 4) _loadBSUData(1);
    }, [loanObject]);

    useEffect(() => {
        loadInputSchema(languageId)
        .then(resp => {
            const s = resp?.find(sc => sc.loanTypeId === activeTab?.component);
            setInputSchema(JSON.parse(s?.inputSchema ?? '{}'));
        });
        const defaultSort = sortingOptions[activeTab?.component]?.find(sort => sort.isDefault);
        if(defaultSort){
            setCurrentSort({ property: defaultSort.property, ascending: defaultSort.ascending })
        }
    }, [])

    useEffect(() => {
        if(pageNumber === 1) return;
        if(activeTab?.component === 1) _loadSecuredLoanData();
        else if(activeTab?.component === 2) _loadUnsecuredData();
        else if(activeTab?.component === 3) _loadCreditCardData();
        else if(activeTab?.component === 4) _loadBSUData();
    }, [pageNumber]);

    useEffect(() => {
        function handleScroll() {
            const content = contentRef.current;
            const sidebar = sidebarRef.current;

            if (content && sidebar) {
                const contentRect = content.getBoundingClientRect();
                const sidebarRect = sidebar.getBoundingClientRect();

                const fitsInViewport = sidebarRect.height <= contentRect.height;

                if (contentRect.top <= 68 && fitsInViewport && !productsLoading) {
                    const topPosition = 68; // Change this value according to your layout
                    sidebar.style.top = `${topPosition}px`;
                    setIsFixed(true);
                } else {
                    sidebar.style.top = 'auto';
                    setIsFixed(false);
                }

                if (contentRect.bottom <= window.innerHeight) {
                    sidebar.style.top = 'auto'; // Unfix the sidebar
                    setIsFixed(false);
                }
            }
        }

        function handleResize() {
            handleScroll(); // Recalculate positions on resize
        }


        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize); // Listen for resize events
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize); // Clean up event listeners
        };
    }, [productsLoading]);


    const sortingOptions = {
        1: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },  
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)',isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByMonthlyCostASC', label: 'Kost/mnd (lav-høy)', ascending: true, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByMonthlyCostDESC', label: 'Kost/mnd (høy-lav)', ascending: false, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByTotalCostASC', label: 'Est. totalkost (lav-høy)', ascending: true, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
            { value: 'SortByTotalCostDESC', label: 'Est. totalkost (høy-lav)', ascending: false, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
        ],
        2: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },  
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)', isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByMonthlyCostASC', label: 'Kost/mnd (lav-høy)', ascending: true, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByMonthlyCostDESC', label: 'Kost/mnd (høy-lav)', ascending: false, property: 'SortByMonthlyCost', bannerLabel: 'Kost/mnd' },
            { value: 'SortByTotalCostASC', label: 'Est. totalkost (lav-høy)', ascending: true, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
            { value: 'SortByTotalCostDESC', label: 'Est. totalkost (høy-lav)', ascending: false, property: 'SortByTotalCost', bannerLabel: 'Est. totalkost' },
        ],
        3: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByCreditASC', label: 'Maks kreditt (lav-høy)', ascending: true, property: 'SortByCredit', bannerLabel: 'Maks kreditt' },
            { value: 'SortByCreditDESC', label: 'Maks kreditt (høy-lav)', ascending: false, property: 'SortByCredit', bannerLabel: 'Maks kreditt' },
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)', isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByAnnualFeeASC', label: 'Årsgebyr (lav-høy)', ascending: true, property: 'SortByAnnualFee', bannerLabel: 'Årsgebyr' },
            { value: 'SortByAnnualFeeDESC', label: 'Årsgebyr (høy-lav)', ascending: false, property: 'SortByAnnualFee', bannerLabel: 'Årsgebyr' },
            { value: 'InterestFreeDaysASC', label: 'Rentefrie dager (lav-høy)', ascending: true, property: 'InterestFreeDays', bannerLabel: 'Rentefrie dager' },
            { value: 'InterestFreeDaysDESC', label: 'Rentefrie dager (høy-lav)', ascending: false, property: 'InterestFreeDays', bannerLabel: 'Rentefrie dager' },
        ],
        4: [
            { value: 'SortByBankNameASC', label: 'Bankens navn (lav-høy)', ascending: true, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByBankNameDESC', label: 'Bankens navn (høy-lav)', ascending: false, property: 'SortByBankName', bannerLabel: 'Bankens navn' },
            { value: 'SortByEffectiveInterestASC', label: 'Eff. rente (lav-høy)', isDefault: true, ascending: true, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByEffectiveInterestDESC', label: 'Eff. rente (høy-lav)', ascending: false, property: 'SortByEffectiveInterest', bannerLabel: 'Eff. rente' },
            { value: 'SortByReturnASC', label: 'Avkastning 1 år (lav-høy)', ascending: true, property: 'SortByReturn', bannerLabel: 'Avkastning 1 år' },
            { value: 'SortByReturnDESC', label: 'Avkastning 1 år (høy-lav)', ascending: false, property: 'SortByReturn', bannerLabel: 'Avkastning 1 år' },
            { value: 'WithdrawalFeeASC', label: 'Frie uttak pr. år (lav-høy)', ascending: true, property: 'WithdrawalFee', bannerLabel: 'Frie uttak pr. år' },
            { value: 'WithdrawalFeeDESC', label: 'Frie uttak pr. år (høy-lav)', ascending: false, property: 'WithdrawalFee', bannerLabel: 'Frie uttak pr. år' },
        ]
    }

    const handleSorting = (property, ascending) => {
        setCurrentSort({ property, ascending });
        const tempLoanObject = _.cloneDeep(loanObject);
        delete tempLoanObject['SortByTotalCost'];
        delete tempLoanObject['SortByMonthlyCost'];
        delete tempLoanObject['SortByEffectiveInterest'];
        delete tempLoanObject['SortByBankName'];
        delete tempLoanObject['WithdrawalFee'];
        delete tempLoanObject['SortByReturn'];
        delete tempLoanObject['InterestFreeDays'];
        delete tempLoanObject['SortByAnnualFee'];
        delete tempLoanObject['SortByCredit'];
        setPageNumber(1);
        setLoanObject({ ...tempLoanObject, [property]: true, IsAscendingOrder: ascending, pageNumber: 1 });
    }

    return(
        <React.Fragment>
            <section className={'section pt-0 '}>
                <Container>
                    <div className={`${classes.tabsFilter}`}>
                        <Nav as="ul" className={classes.navTabs}>
                            { detail?.tabs?.map((tab, index) => {
                                return <Nav.Item as="li" key={index}>
                                    <a href={tab.tabUrl} className={tab.isActive ? 'active' : ''}>
                                        <div className={classes.iconBox}>
                                            <i className={tab?.tabIcon}></i>
                                        </div>
                                        <span>{tab.tabTitle}</span>
                                    </a>
                                </Nav.Item>})}
                        </Nav>
                        <Form>
                            <div className={classes.FilterBox}>
                                <div className={classes.contentHolder}>
                                    {activeTab?.component === 1 && <LoanWithSecurity detail={ [{ tabVersion: detail?.activeTab?.componentVersion }] } loanObject={loanObject} setLoanObject={setLoanObject} hideRadio values={{ age, housingValue, loanAmount, repaymentPeriod, productType }}/>}
                                    {activeTab?.component === 2 && <LoanWithoutSecurityFilter detail={ [{ tabVersion: detail?.activeTab?.componentVersion }] } hideRadio loanObject={loanObject} setLoanObject={setLoanObject}  values={{ repaymentPeriod, loanAmount, currentLoanAmount }}/>}
                                    {activeTab?.component === 3 && <CreditCardFilter detail={ [{ tabVersion: detail?.activeTab?.componentVersion }] } hideRadio loanObject={loanObject} setLoanObject={setLoanObject}  values={{ age, annualIncome, additional }} />}
                                    {activeTab?.component === 4 && <Savings detail={ [{ tabVersion: detail?.activeTab?.componentVersion }] } hideRadio loanObject={loanObject} setLoanObject={setLoanObject}  values={{ age, totalSavings: totalSaved ?? totalOutstanding }}/>}
                                </div>
                            </div>
                        </Form>
                    </div>
                   {/* {!productsLoading && <SectionTitle description={detail?.heading?.description} textCenter>
                        {detail?.heading?.title?.map((title, index) => <span className={title.class} key={index}>{title.title}</span>)}
                    </SectionTitle>}*/}
                    <div className={classes.listingContainer}>
                        <div className={classes.listHolder}>

                           {/* <div className={classes.filterBoxSidebar}
                                 // ref={sidebarRef}
                                 // style={{
                                 //     position: isFixed ? 'fixed' : 'static',
                                 //     top: isFixed ? '10px' : 'auto',
                                 // }}
                            >
                                <FilterBox
                                    title={'Avansert søk'}
                                    schema={inputSchema}
                                    setLoanObject={setLoanObject}
                                    loanObject={loanObject}
                                    optionsList={sortingOptions}
                                    setCurrentSort={setCurrentSort}
                                    variant={'topBar'}
                                />
                            </div>*/}
                            <FilterBox
                                title={'Avansert søk'}
                                schema={inputSchema}
                                setLoanObject={setLoanObject}
                                loanObject={loanObject}
                                optionsList={sortingOptions}
                                setCurrentSort={setCurrentSort}
                                variant={'topBar'}
                            />
                            <div className={classes.filterContent}
                                 ref={contentRef}
                            >
                                { (productsLoading) ? <div className={classes.imageSkeleton} /> : <ProductListing  products={products} handleShowMoreClick={handleShowMoreClick} loading={loading} sorting={sortingOptions[activeTab?.component]} currentSort={currentSort} handleSort={handleSorting}/>}
                                {!productsLoading && age && loanAmount && repaymentPeriod && products?.length === 0 ? <h1>Ingen produkter funnet</h1> : null}
                            </div>

                        </div>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default TabsWithFilter;