import img1 from "../../../Images/img57.svg";
import img2 from "../../../Images/img58.svg";
import img3 from "../../../Images/img59.svg";

const data = [
    {
        withOutImg: false,
        imgOnTop: true,
        heading: {
            description: "La et bedriftslån løfte virksomheten din, enten det er for vekst eller for å takle utfordringer.",
            title: [
                { id: 1, title: "Hva kan du bruke et b", class: "fw-normal" },
                { id: 2, title: "edriftslån til?", class: "fw-bold" }
                // Add more title objects as needed
            ]
        },
        colData: [
            {
                title: "Kapitalinvesteringer",
                description: "Kjøp av utstyr, maskiner eller annen teknologi.",
                featuredImage: img1,
                url: "#"
            },
            {
                title: "Lokaleforbedringer eller utvidelser",
                description: "Oppussing eller utvidelse av forretningslokaler.",
                featuredImage: img2,
                url: "#"
            },
            {
                title: "Varelager",
                description: "Kjøp av lager for å møte kundenes etterspørsel.",
                featuredImage: img3,
                url: "#"
            },
        ]
    },
    // Add more data objects as needed
];

export default data;
