import img from '../../../Images/img45.png'

const staticData = [
  {
    id: 1,
    heading: {
      description: "",
      title: [
        { id: 1, title: "Slik fungerer", class: "fw-normal" },
        { id: 2, title: "Finansieringsguiden", class: "fw-semibold" }
      ]
    },
    content: [
      { title: "", description: "Oversikt over ulike finansieringsalternativer" },
      { title: "", description: "Veiledning for når og hvorfor man bør skaffe finansiering." },
      { title: "", description: "Prosessen for å søke om finansiering, inkludert nødvendig dokumentasjon og kvalifikasjonskrav." },
      { title: "", description: "Forklaring av kostnader knyttet til forskjellige finansieringsformer." },
      { title: "", description: "Verktøy og ressurser, som lånekalkulatorer, for å estimere finansieringskostnader." },
    ],
    redirectUrl: "https://example.com",
    featuredImage: img,
    btnText: "Sjekk din score nå! - Gratis",
    btnUrl: "/bedriftsdashbord",
  }
];

export default staticData;
