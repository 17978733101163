import React from 'react';
import {Container} from "react-bootstrap";
import classes from './index.module.scss';
import data from './data';
import { onError } from 'Services/api/Image';

const UseFulInfomation = () => {
  return(
      <React.Fragment>
          <section className={'section bg-light-quarter-top'}>
              <Container>
                  <div className={classes.infoCol}>
                      {data.map((item, index) => (
                          <div className={classes.col} key={item.index}>
                              <div className={classes.box}>
                                  <div className={classes.imgBox}>
                                      <img src={item.image} loading="lazy" alt={item.title} onError={onError} />
                                  </div>
                                  <div className={classes.description}>
                                      <h6 className={'mb-0'}>{item.title}</h6>
                                  </div>
                              </div>
                          </div>
                      ))}
                  </div>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default UseFulInfomation;