const faqData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "", class: "fw-normal" },
                { id: 1, title: "", class: "fw-bold" },
            ]
        },
        singleCol: true,
        questions: [
            {
                id: 1,
                title: "Hva gjør jeg hvis det er feil i opplysningene om gjelden min?",
                description: "Gjeldsinformasjonen vi henter på deg kommer fra Gjeldsregisteret. Dersom du mener at informasjonen ikke stemmer, må du kontakte det relevante finansforetaket. De er kilden til dataene vi viser. Vi har ingen mulighet til å slette eller endre dataene, det kan kun finansforetaket gjøre.",
            },
            {
                id: 2,
                title: "Hva er usikret gjeld?",
                description: "Usikret gjeld, som også kalles forbruksgjeld, er lån som ikke er sikret med pant. Ifølge gjeldsinformasjonsloven inkluderer dette nedbetalingslån/forbrukslån (uten pant), rammekreditter (kredittkort og kontokreditter) og faktureringskort/betalingskort.<br><br>" +
                    "Rammekreditter, for eksempel Visa og Mastercard, tillater deg å betale et minimumsbeløp ved forfall i stedet for hele beløpet. Faktureringskort krever at du betaler hele beløpet du har brukt ved forfall. Et typisk eksempel på et faktureringskort er et bensinkort fra Cirkle K.",
            },
        ],
    },
];

export default faqData;
