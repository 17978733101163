import React from "react";
import data from "./data"; // Assuming you've exported the data array from the provided file
import classes from './index.module.scss';
import AnimatedImage from "../../AnimatedImg";

const DataShow = ({ dataTypeFilter }) => {
    const filteredData = data?.filter((item) => item.dataType === dataTypeFilter);

    return (
        <React.Fragment>
            {filteredData.map((item, index) => (
                <>
                    <div className={classes.popUpBanner}>
                        <AnimatedImage image={item.image} autoplay={true} loop={true} />
                    </div>
                    <div
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item.text }}
                    />
                </>
            ))}
        </React.Fragment>
    );
};

export default DataShow;