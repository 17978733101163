import React from 'react';
import {Container} from "react-bootstrap";
import AllEditorChoice from "../../Components/Blogs/EditorChoicePosts/AllEditorChoice";



const AllEditorChoicePage = () => {
    return(
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <AllEditorChoice />
                </Container>
            </section>
        </React.Fragment>
    )
}

export default AllEditorChoicePage;