const bannerData =  [
    {
      id: 1,
      subTitle: "",
      title: [
        { id: 1, title: "Finansieringsguiden", class: "fw-bold" },
        { id: 2, title: "", class: "fw-bold" },
      ],
      description:
      <>
          <p>Finansieringsguiden er en ressurs for bedrifter som søker informasjon om ulike finansieringsmuligheter. Den gir innsikt i forskjellige typer finansieringsløsninger, som bedriftslån, kassakreditt og folkefinansiering, og veileder bedrifter i hvordan og når de bør søke finansiering. Guiden hjelper også med å forstå kostnadene knyttet til ulike finansieringsformer og tilbyr verktøy som lånekalkulatorer for å beregne potensielle kostnader. Den er rettet mot å hjelpe bedrifter med å ta informerte finansieringsbeslutninger.</p>
      </>,
        btnText: "Se hvor mye du kan lån",
        btnUrl: "/bedrift",
        hideCalculator: true,
    }
  ]


export default bannerData;
