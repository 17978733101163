import React, {useState} from "react";
import data from "../ProductListing/data";
import {Link} from "react-router-dom";
import Button from "../Button";
import classes from "./index.module.scss";
import DetailDropdown from "../ProductListing/DetailDropdown";
import { onError } from "Services/api/Image";
import { useSelector } from "react-redux";


const CardList = ({item, index, setSelectedDataTypeProp, setModalShow, splitTwo, handleCompare, displayDetails, user }) => {
    const { settings } = useSelector(state => state.settings);

    const [detailOpen, setDetailOpen] = useState(Array(data.length).fill(false));
    const toggleDiv = (index) => {
        setDetailOpen((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = !updatedState[index];
            return updatedState;
        });
    };
    const closeDetail = (index) => {
        setDetailOpen((prevState) => {
            const updatedState = [...prevState];
            updatedState[index] = false;
            return updatedState;
        });
    };

    return(
        <React.Fragment>
            <div className={`${classes.row} ${splitTwo ? `${classes.splitTwo}` : ``}`}>
                {splitTwo ?
                    (
                        <React.Fragment>
                            <div className={classes.rowHolder}>
                                <div className={classes.rowHeader}>
                                    <div className={classes.holder}>
                                        <div className={classes.logoBox}>
                                            <a href={item?.LoanSchema?.Url} target={(item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true') ? '_self' : '_blank'} className={(item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true') ? classes.disabled : ''}>
                                                <img onError={onError} src={item?.LoanSchema?.ImageSrc} loading="lazy" alt={'#'} />
                                            </a>
                                        </div>
                                        {item?.LoanSchema?.Property?.find(p => p?.Name === 'LeverandorTekst')?.Value && <p>{item?.LoanSchema?.Property?.find(p => p?.Name === 'LeverandorTekst')?.Value}</p>}
                                        {item?.LoanSchema?.CardType === 'Annonse' && item?.LoanSchema?.Property?.find(p => p?.Name === 'Title')?.Value && <p>{item?.LoanSchema?.Property?.find(p => p?.Name === 'Title')?.Value}</p>}
                                    </div>
                                </div>
                                <div className={classes.rowBody}>
                                    <div className={classes.holder}>
                                        <ul className={classes.list}>
                                            {/* {item?.LoanSchema?.Property?.map((property, index) => property?.Name === 'LeverandorTekst' || property?.Name === 'Forbehold' ? null : <li key={index}>
                                                <div className={classes.col}>{property?.LocaleName === '' ? property?.Name : property?.LocaleName}</div><div className={classes.col}>{property?.Value} {property?.Symbol}</div>
                                            </li>)} */}
                                            {item?.LoanSchema?.Property?.
                                            filter(property => property.ShowInDetail === '' || property.ShowInDetail === 'false')?.
                                            filter(property => property.Priority !== '')?.
                                            sort((first, second) => Number(first.Priority) - Number(second.Priority))?.
                                            concat(item?.LoanSchema?.Property?.filter(property => property.Priority === '' && (property.ShowInDetail === '' || property.ShowInDetail === 'false')))?.
                                            map((property, index) => property?.Name === 'LeverandorTekst' ? null : <li key={index}>
                                                <div className={classes.col}>{property?.LocaleName === '' || !property?.LocaleName ? property?.Name : property?.LocaleName}</div><div className={classes.col}>{property?.Value} {property?.Symbol}</div>
                                            </li>)}
                                        </ul>
                                        <div className={classes.rowBtnCol}>
                                            <div className={`${classes.dataType} ${item?.LoanSchema?.CardType === 'Annonse' ? classes.transparent : ''}`}
                                                 onClick={() => {
                                                     //if(item?.LoanSchema?.CardType !== 'Corporate') return;
                                                     setSelectedDataTypeProp(item?.LoanSchema?.CardType);
                                                     setModalShow(true);
                                                 }}>
                                                {item?.LoanSchema?.CardType === 'Annonse' ? settings?.find(s => s.variableName === 'cardsListingAnnonseText')?.value : item?.LoanSchema?.CardType === 'Corporate' ? settings?.find(s => s.variableName === 'cardsListingCorporateText')?.value : item?.LoanSchema?.CardType === 'NonCorporate' ? settings?.find(s => s.variableName === 'cardsListingNonCorporateText')?.value : item?.LoanSchema?.CardType}
                                            </div>
                                            <Button
                                                disabled={item?.LoanSchema?.CardType === 'NonCorporate'}
                                                variant={'small'}
                                                url={item?.LoanSchema?.Url}
                                                target={(item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true') ? '_self' : '_blank'}
                                                text={settings?.find(s => s?.variableName === 'cardsSearchButtonText')?.value}
                                                icon={'far fa-arrow-right'}
                                            />
                                            <Button
                                                text={settings?.find(s => s?.variableName === 'cardsCompareLinkText')?.value}
                                                variant={'link'}
                                                onClick={() => handleCompare(item)}
                                            />

                                        </div>
                                    </div>
                                    <div className={classes.rowFooter}>
                                        <div className={classes.footerText}>
                                            {item?.LoanSchema?.Example}
                                        </div>
                                        {displayDetails && <Button
                                            variant={'link'}
                                            text={settings?.find(setting => setting?.variableName === 'cardsListingDetailLinkText')?.value}
                                            icon={'far fa-angle-down mx-2'}
                                            onClick={() => toggleDiv(index)}
                                        />}
                                    </div>
                                </div>

                            </div>
                            { detailOpen[index] && <DetailDropdown item={item} onClose={() => closeDetail(index)} />}

                        </React.Fragment>
                    )
                    : (
                        <React.Fragment>
                            <div className={`${classes.rowHolder} ${detailOpen[index] ? classes.detailOpen : ''}`} style={item?.LoanSchema?.Featured?.toLowerCase() === 'true' ? { backgroundColor: 'rgba(255, 249, 238, 1)' } : null}>
                                {item?.LoanSchema?.Featured?.toLowerCase() === 'true' && <div className={classes.featured}>Anbefalt</div>}
                                <div className={classes.rowHeader} style={item?.LoanSchema?.Featured?.toLowerCase() === 'true' ?  { } : null}>
                                    <div className={classes.holder}>
                                        <div className={classes.logoBox}>
                                            <a href={item?.LoanSchema?.Url} target={(item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true') ? '_self' : '_blank'} className={(item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true') ? classes.disabled : ''}>
                                                <img onError={onError} src={item?.LoanSchema?.ImageSrc} loading="lazy" alt={'#'} />
                                            </a>
                                        </div>
                                        {item?.LoanSchema?.Property?.find(p => p?.Name === 'LeverandorTekst')?.Value && <p>{item?.LoanSchema?.Property?.find(p => p?.Name === 'LeverandorTekst')?.Value}</p>}
                                        {item?.LoanSchema?.CardType === 'Annonse' && item?.LoanSchema?.Property?.find(p => p?.Name === 'Title')?.Value && <p>{item?.LoanSchema?.Property?.find(p => p?.Name === 'Title')?.Value}</p>}
                                    </div>
                                </div>
                                <div className={classes.rowBody}>
                                    <div className={classes.holder}>
                                        <ul className={classes.list}>
                                            {item?.LoanSchema?.Property?.
                                            filter(property => property.ShowInDetail === '' || property.ShowInDetail === 'false')?.
                                            filter(property => property.Priority !== '')?.
                                            sort((first, second) => Number(first.Priority) - Number(second.Priority))?.
                                            concat(item?.LoanSchema?.Property?.filter(property => property.Priority === '' && (property.ShowInDetail === '' || property.ShowInDetail === 'false')))?.
                                            map((property, index) => {
                                            if(item?.LoanSchema?.Property?.some(p => p?.Name === 'MinAlder') && item?.LoanSchema?.Property?.some(p => p?.Name === 'MaksAlder')){
                                                if(property?.Name === 'MinAlder') return <li key={index}>
                                                    <div className={classes.col}>Alder</div>
                                                    <div className={classes.col}>{property?.Value} {property?.Symbol} - {item?.LoanSchema?.Property?.find(p => p.Name === 'MaksAlder')?.Value} {property?.Symbol}</div>
                                                </li>
                                                else if(property?.Name === 'MaksAlder') return null;
                                            }
                                            return property?.Name === 'LeverandorTekst' || (item?.LoanSchema?.CardType === 'Annonse' && property?.Name === 'Title') ? null : <li key={index}>
                                                <div className={classes.col}>{property?.LocaleName === '' || !property?.LocaleName ? property?.Name : property?.LocaleName}</div><div className={classes.col}>{property?.Value} {property?.Symbol}</div>
                                            </li>})}
                                        </ul>
                                        <div className={classes.rowBtnCol}>
                                            <Button
                                                text={settings?.find(s => s?.variableName === 'cardsCompareLinkText')?.value}
                                                variant={'link'}
                                                onClick={() => handleCompare(item)}
                                            />
                                            <Button
                                                text={settings?.find(s => s?.variableName === 'cardsSearchButtonText')?.value}
                                                icon={'far fa-arrow-right'}
                                                url={item?.LoanSchema?.Url}
                                                //target={(item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true') ? '_self' : '_blank'}
                                                target = {(item?.LoanSchema?.CardType === 'Annonse')
                                                    ? '_blank'
                                                    : (item?.LoanSchema?.CardType !== 'Corporate' && item?.LoanSchema?.Featured?.toLowerCase() !== 'true')
                                                        ? '_self'
                                                        : '_blank'}
                                                variant={`${item?.LoanSchema?.Featured?.toLowerCase() === 'true' ? 'bg-success' : ''} small`}
                                            />
                                            {item?.LoanSchema?.CardType &&
                                                <div className={`${classes.dataType} ${item?.LoanSchema?.CardType === 'Annonse' ? classes.transparent : ''}`}
                                                     onClick={() => {
                                                         setSelectedDataTypeProp(item?.LoanSchema?.CardType);
                                                         setModalShow(true);
                                                     }}>
                                                    { item?.LoanSchema?.CardType === 'Annonse' ? settings?.find(s => s.variableName === 'cardsListingAnnonseText')?.value : item?.LoanSchema?.CardType === 'Corporate' ? settings?.find(s => s.variableName === 'cardsListingCorporateText')?.value : item?.LoanSchema?.CardType === 'NonCorporate' ? settings?.find(s => s.variableName === 'cardsListingNonCorporateText')?.value : item?.LoanSchema?.CardType }
                                                </div>
                                            }

                                        </div>
                                    </div>
                                    <div className={classes.rowFooter}>
                                        <div className={classes.footerText}>{item?.LoanSchema?.Example}</div>
                                        {/* Rizwan bhai said "Annonse ki details drop down ani hain" */}
                                        {/* {(displayDetails !== false) && item?.LoanSchema?.CardType?.toLowerCase() !== 'annonse' && ( */}
                                            <Button
                                                variant={'link m-0'}
                                                icon={'far fa-angle-down mx-2'}
                                                text={settings?.find(setting => setting?.variableName === 'cardsListingDetailLinkText')?.value}
                                                onClick={() => toggleDiv(index)}
                                            />
                                        {/* )} */}
                                    </div>
                                </div>
                                {detailOpen[index] && (
                                    <DetailDropdown index={index} user={user} item={item} url={item?.LoanSchema?.Url} onClose={() => closeDetail(index)} />
                                )}
                            </div>
                        </React.Fragment>
                    )
                }

            </div>
        </React.Fragment>
    )
}

export default CardList;