import img from '../../../Images/img45.png'

const staticData = [
  {
    id: 1,
    heading: {
      description: "",
      title: [
        { id: 1, title: "Slik fungerer", class: "fw-normal" },
        { id: 2, title: "kredittsjekk", class: "fw-semibold" }
      ]
    },
    content: [
      { title: "Logg inn", description: "Start prosessen ved å logge deg inn med din BankID eller Vipps for å sikre en sikker og rask autentisering." },
      { title: "Opprett brukerprofil", description: "Vi oppretter en dedikert brukerprofil for din bedrift, noe som gir deg tilgang til alle nødvendige tjenester og informasjon." },
      { title: "Innhenting av data", description: "EasyFinance innhenter kredittopplysninger og kredittscoren for din bedrift fra kredittopplysningsbyrå som Creditsafe, noe som sikrer nøyaktig og oppdatert informasjon." },
      { title: "Analyser finansiell informasjon", description: "Du får tilgang til detaljert finansiell informasjon, inkludert kredittscore, betalingsanmerkninger, regnskapstall og annen viktig finansiell info som kan hjelpe deg med å ta informerte beslutninger." },
      { title: "Kredittsjekk av tredjeparter", description: "Benytt muligheten til å utføre kredittsjekker på potensielle partnere, kunder eller leverandører for å sikre at du gjør forretninger med økonomisk solide enheter, og utnytt denne informasjonen til å søke lån under mer informerte forhold." },
    ],
    redirectUrl: "https://example.com",
    featuredImage: img
  }
];

export default staticData;
