import React from 'react';
import StaticBannerWithImg from "../../Components/Banner/StaticBannerWithImg";
import RecommendedOffers from "./RecommendedOffers";
import data from "./ColSectionData";
import StaticColSection from "../../Components/ColSection/StaticColSection";

const RefinanceConsumerLoan = () => {

  return(
      <React.Fragment>
          <StaticBannerWithImg staticBanner />
          <RecommendedOffers />
          <StaticColSection detail={data} />
      </React.Fragment>
  )
}

export default RefinanceConsumerLoan;