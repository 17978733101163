import React from 'react';
import { Document, StyleSheet } from '@react-pdf/renderer';
import FrontPage from "../../PDFComponent/FrontPage";
import PaymentRemarksList from "./PaymentRemarksList";

const ScoreDocument = ({ remarks, userData, totalRemarks, totalAmount }) => {

    const styles = StyleSheet.create({
        page: {
            fontSize: 11,
            paddingTop: 40,
            paddingLeft: 40,
            paddingRight: 40,
            lineHeight: 1.5,
            flexDirection: 'column',
            position: 'relative',
        },
    });

    return (
        <Document style={styles.document}>
            <FrontPage userData={userData}/>
            <PaymentRemarksList
                userData={userData}
                remarks={remarks}
                totalRemarks={totalRemarks}
                totalAmount={totalAmount}
            />
        </Document>
    );
}

export default ScoreDocument;
