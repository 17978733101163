import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import data from "./data.json";
import classes from "../index.module.scss";
import Button from "../../Button";
import moment from "moment/moment";

HighchartsMore(Highcharts);

const HouseValueSplineChart = ({ log, seriesFirst, seriesFirstCategory }) => {
  const { title, subtitle, data: chartData } = data;
  const [chartId] = useState(
    `houseValue-${Math.random().toString(36).substr(2, 9)}`
  ); // Generate unique chart id
  //   const [allData] = useState([
  //     ...log?.map((dat) => {
  //       const parsed = JSON.parse(dat.responseData ?? "{}");
  //       return (
  //         (isNaN(parsed?.marketeEstimate?.approvedNetMarketValue)
  //           ? 0
  //           : Number(parsed?.marketeEstimate?.approvedNetMarketValue)) +
  //         (isNaN(parsed?.propertyEstimateBasis?.currentCommondebt)
  //           ? 0
  //           : Number(parsed?.propertyEstimateBasis?.currentCommondebt))
  //       );
  //     }),
  //   ]);
  //   const [allDates] = useState([
  //     ...log?.map((dat) => {
  //       return moment(dat?.createdOn).format("MM/YYYY");
  //     }),
  //   ]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    Highcharts.setOptions({
      colors: ["#014E9E"], // Set a base color for the pie chart
      legend: {
        itemStyle: {
          color: "#ccc", // Set the text color for the legend
        },
        symbolRadius: 0, // Hide the symbols in the legend
      },
    });

    const options = {
      chart: {
        type: "spline",
        plotBackgroundColor: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: null,
        renderTo: chartId, // Use dynamic chartId
      },
      title: {
        text: subtitle,
        align: "center",
        verticalAlign: "bottom",
      },
      subtitle: {
        useHTML: false,
        floating: true,
        verticalAlign: "middle",
        y: 0,
        x: 0,
      },
      xAxis: {
        categories: filteredData.map((dat) => dat.date), // Extract months from data
      },
      yAxis: {
        title: {
          text: "Verdi",
        },
      },
      series: [
        {
          //name: 'Amount',
          name: title,
          data: filteredData.map((dat) => dat.value), // Extract amounts from data
        },
      ],
    };

    let chart = new Highcharts.Chart(options);

    // Clear resources on unmount
    return () => {
      if (chart) {
        clearTimeout(chart.sequenceTimer);
        chart.destroy();
      }
    };
    //eslint-disable-next-line
  }, [chartData, subtitle, title, chartId, log, filteredData]); // Include chartId in the dependency array

  useEffect(() => {
    const fd = [{ value: Number(seriesFirst), date: seriesFirstCategory }];
    log?.forEach((dat) => {
      const parsed = JSON.parse(dat.responseData ?? "{}");
      const value =
        (isNaN(parsed?.marketeEstimate?.approvedNetMarketValue)
          ? 0
          : Number(parsed?.marketeEstimate?.approvedNetMarketValue)) +
        (isNaN(parsed?.propertyEstimateBasis?.currentCommondebt)
          ? 0
          : Number(parsed?.propertyEstimateBasis?.currentCommondebt));
      const date = dat?.createdOn;
      fd.push({ date, value });
    });
    setFilteredData(
      fd
        .filter(
          (f) =>
            selectedOption === null ||
            moment(f.date).isSameOrAfter(selectedOption)
        )
        .map((f) => ({
          value: f.value,
          date: moment(f.date).format("MM/YYYY"),
        }))
    );
  }, [selectedOption, log]);

  return (
    <React.Fragment>
      <div className={classes.filterBtnRow}>
        <Button
          text={"From takeover"}
          onClick={() => setSelectedOption(null)}
        />
        <Button
          text={"12 måneder"}
          onClick={() => setSelectedOption(moment().subtract(1, "years"))}
        />
        <Button
          text={"2 år"}
          onClick={() => setSelectedOption(moment().subtract(2, "years"))}
        />
        <Button
          text={"3 år"}
          onClick={() => setSelectedOption(moment().subtract(3, "years"))}
        />
      </div>
      <div className={classes.splineChart}>
        <div className={classes.chartUpperBox}>
          <div id={chartId}></div>
        </div>
        {/*<div className={classes.chartFooter}>
                    <Button text={'See your house value'} url={'/abc/'} variant={'border'} />
                </div>*/}
      </div>
    </React.Fragment>
  );
};

export default HouseValueSplineChart;
