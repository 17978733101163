import img1 from '../../../Images/img52.svg';
import img2 from '../../../Images/img53.svg';
import img3 from '../../../Images/img54.svg';
import img4 from '../../../Images/img55.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "", class: "" },
            ]
        },
        content: 'Bedriftslån er tilgjengelig for virksomheter registrert som ENK, AS, DA, ANS, eller ASA i Norge. Den som søker må ha norsk BankID og være signaturberettiget. Dette sikrer at låneavtalen kan signeres elektronisk og gjør prosessen raskere og mer sikker.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#EBF3FB',
        dataList: [
            {
                title: "Er nystartede bedrifter kvalifiserte for lån?",
                description: "Ja, nystartede bedrifter kan også søke om lån. Vi forstår at alle virksomheter starter et sted, og vi vurderer hver søknad individuelt. Vår vurdering fokuserer på forretningsplanen, markedspotensialet, og i noen tilfeller, gründerens tidligere erfaring.",
            },
            {
                title: "Kan bedriften få lån for internasjonale investeringer?",
                description: "Bedrifter som planlegger å utvide eller investere i utlandet kan søke om bedriftslån hos oss. Det er viktig at investeringen anses som levedyktig og at den kan vise til forventet avkastning som støtter tilbakebetaling av lånet.",
            },
            {
                title: "Hva hvis jeg har personlige betalingsanmerkninger?",
                description: "Personlige betalingsanmerkninger kan påvirke din evne til å få bedriftslån, da de reflekterer på din kredittverdighet. Vi gjennomfører en kredittsjekk som en del av søknadsprosessen, og det er viktig å være åpen om slike forhold i søknaden.",
            },
        ]
    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hva er fordelene med bedriftslån fra oss?", class: "" },
            ]
        },
        content: '',
        btnText: '',
        redirectUrl: "",
        featuredImage: img2,
        horizontal: true,
        dataList: [
            {
                title: "Ingen etableringsgebyr:",
                description: "Vi krever ikke etableringsgebyr, noe som gjør det enklere og rimeligere å få tilgang til finansiering.",
            },
            {
                title: "Fleksibel nedbetaling:",
                description: "Vi tilbyr opp til 24 måneders nedbetalingstid, men du står fritt til å innfri lånet tidligere uten ekstra kostnader. Dette gir deg fleksibilitet til å tilpasse tilbakebetalingen etter bedriftens kontantstrøm.",
            },
            {
                title: "Rask behandling og utbetaling:",
                description: "Søknader behandles innen en time, og ved godkjenning kan pengene være på kontoen innen få minutter.",
            },
        ]
    },
    {
        id: 3,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hvordan kan jeg forbedre sjansene mine for å få innvilget et lån?", class: "" },
            ]
        },
        content: '',
        btnText: '',
        redirectUrl: "",
        featuredImage: img3,
        horizontal: false,
        dataList: [
            {
                title: "Klar forretningsplan:",
                description: "En godt utformet forretningsplan viser potensialet i din virksomhet.",
            },
            {
                title: "Økonomiske dokumenter:",
                description: "Ha klare og oppdaterte økonomiske dokumenter. Dette kan omfatte regnskap, skattepapirer, og prognoser.",
            },
            {
                title: "Kreditthistorikk:",
                description: <>
                    Rydd opp i eventuelle kredittproblemer før du søker. <br />
                    Vi er her for å støtte din bedrifts vekst og stabilitet med tilpassede finansieringsløsninger. Kontakt oss for en uforpliktende samtale om hvordan vi kan hjelpe din virksomhet med å nå sine mål.
                </>,
            },
        ]
    },
    {
        id: 4,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Fikk du ikke bedriftslån?", class: "" },
            ]
        },
        content: '',
        btnText: 'Fikk du ikke bedriftslån?',
        redirectUrl: "/bedrift/fikk-du-ikke-bedriftslån",
        featuredImage: img4,
        horizontal: true,
    },
];

export default contentWithImgData;
