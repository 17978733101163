import React from "react";
import {Container} from "react-bootstrap";
import SectionTitle from "../../Components/SectionTitle";
import BlogCategories from "../../Components/Blogs/BlogCategories";

const BlogsCategory = () => {
    return(
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <SectionTitle textCenter>
                        <span className={'fw-light'}>Blogs</span>
                        <span className={'fw-semibold'}>Categories</span>
                    </SectionTitle>
                    <BlogCategories />
                </Container>
            </section>
        </React.Fragment>
    )
}

export default BlogsCategory;