import React from "react";
import Button from "../../../../../Components/Button";
import classes from "./index.module.scss";

const RecommendedCards = ({cardData}) => {

    return (
        <div className={`${classes.row}`}>
            {cardData && cardData.map(item => {
                return (
                    <React.Fragment key={item.dataType} id={item.dataType}>
                        <div className={`${classes.rowHolder}`}>
                            {item.featured && <div className={classes.featured}>Anbefale</div>}
                            <div className={classes.rowHeader}>
                                <div className={classes.holder}>
                                    <div className={classes.logoBox}>
                                        <a href={item.btnUrl}>
                                            <img src={item.image} loading="lazy" alt={'#'} />
                                        </a>
                                    </div>
                                    <h4 className={'text-capitalize'}><a href={item.btnUrl}>{item.name}</a> </h4>
                                </div>
                            </div>
                            <div className={classes.rowBody}>
                                <div className={`${classes.holder} ${classes.hoderExtra}`} style={{minHeight: "180px"}}>
                                    <ul className={classes.list}>
                                        <li>
                                            <div className={classes.col}>Lånebeløp</div>
                                            <div className={classes.col}>{item.loanAmount}</div>
                                        </li>
                                        <li>
                                            {item.leverandorTekst && <div className={`${classes.col} text-left font-16`} style={{letterSpacing: '0.5px'}}>{item.leverandorTekst}</div> }
                                            {item.moreDetail && item.moreDetail.map(detail => (
                                                <div className={'text-left py-3'} key={detail.title}>
                                                    <h6 className={'mb-3'} style={{wordBreak: "break-word"}}>{detail.title}</h6>
                                                    {detail.moreInfo && detail.moreInfo.length > 0 && (
                                                        <ul className={'checkList'}>
                                                            {detail.moreInfo.map((info, index) => (
                                                                <li key={index}>{info}</li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            ))}
                                        </li>
                                    </ul>
                                    <div className={classes.rowBtnCol}>
                                        <div className={'font-10 text-center fw-bold mb-2'}>{item.affiliateType}</div>
                                        <Button
                                            text={'Søk nå'}
                                            icon={'far fa-arrow-right'}
                                            url={item.btnUrl}
                                            target={"_blank"}
                                            variant={' mb-2'}
                                        />
                                        <div className={'font-11 py-2 text-center  fw-medium mb-2'} style={{lineHeight: '13px'}}>Kredittscore-basert <br /> rente fra:</div>
                                        <div className={classes.percentAgeBox}>{item.nominalInterestRate}</div>
                                    </div>
                                </div>
                                <div className={classes.rowFooter}>
                                    <div className={classes.footerText}>
                                        {item.loanExample}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })}
        </div>
    );
};

export default RecommendedCards;
