import img1 from "../../../../Images/unofinans.svg";
import okida from "../../../../Images/okidaLogo.svg";

const adDataDebtCheck = [
    {
        id: "1",
        title: "okida",
        description: "Samle kredittkort, forbrukslån, restskatt, inkasso, lønnstrekk, betalingsanmerkninger med mer. Tjenesten er uforpliktende og gratis.",
        img: okida,
        btnUrl: "https://aslinkhub.com/?bid=2729168&media_id=100963",
        rating: "5.0",
        loanExample: "Eksempelrente: Lån 2 millioner | Effektiv rente 6,58 % Nedbetalt over 25 år | Totalt 4 009 071 kr",
    },
    {
        id: "2",
        title: "uno finans",
        description: "Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!",
        img: img1,
        btnUrl: "https://ormarkmed.com/click.track?CID=430721&AFID=529108",
        rating: "4.8",
        loanExample: "Effektiv rente i henhold til Forbrukertilsynets retningslinjer for markedsføring av boliglån er 8,17% ved et lån på 2 millioner over 25 år, kost kr. 2.509.977, totalt 4.509.977",
    },

];

export default adDataDebtCheck;