import img1 from '../../../Images/img46.svg';
import img2 from '../../../Images/img47.svg';
import img3 from '../../../Images/img48.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Oversikt over ulike finansieringsalternativer", class: "" },
            ]
        },
        content: 'EasyFinance hjelper kundene sine med å finne og søke om en rekke finansieringsalternativer tilpasset deres behov. De tilrettelegger tilgang til bedriftslån, kassakreditt og grønne lån, alt designet for å støtte bedrifters ulike finansieringskrav og bidra til deres vekst og bærekraftige utvikling.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#EBF3FB'

    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Når bør du skaffe deg finansiering til bedriften din?", class: "" },
            ]
        },
        content: 'Når du skal vurdere når du bør skaffe finansiering til din bedrift, bør du tenke på behovet for vekst, investeringer, eller for å dekke løpende utgifter. Små bedrifter venter ofte for lenge med å søke om finansiering, noe som kan hemme vekstmulighetene. Det er viktig å være proaktiv og undersøke finansielle alternativer tidlig, så du kan utnytte muligheter og unngå finansielle utfordringer. Finansiering kan være nødvendig både for nye investeringer og for å håndtere daglige utgifter.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img2,
        horizontal: true
    },
    {
        id: 3,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Rask og brukervennlig støtte for din bedrift", class: "" },
            ]
        },
        content: 'Å søke om finansiering kan ofte være en utfordrende prosess, men det er viktig å være informert om de forskjellige alternativene som er tilgjengelige. Vår tjeneste sikter mot å gjøre denne prosessen enklere og mer tilgjengelig, ved å tilby en rask og brukervennlig tilnærming. Vi forstår at tiden er verdifull for bedriftseiere, og derfor tilbyr vi klare og forståelige vilkår, rask respons på søknader, og transparens rundt alle kostnader. La oss hjelpe deg med å finne den rette finansieringsløsningen som passer for din bedrifts behov.',
        btnText: 'Se hvor mye kan låne',
        redirectUrl: "/bedriftslanding",
        featuredImage: img3,
        horizontal: false
    },
];

export default contentWithImgData;
