import React from "react";
import classes from './index.module.scss';
import {Container} from "react-bootstrap";
import notFoundImg from '../../Images/error404.svg';
import Button from "../../Components/Button";
import { onError } from "Services/api/Image";

const NotFound = () => {
  return (
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <div className={classes.notFound}>
                      <div className={classes.imgBox}>
                          <img src={notFoundImg} loading="lazy" onError={onError} alt={'not Fount'} />
                      </div>
                      <div className={'text-center py-5'}>
                          <Button
                              text={'Go to home'}
                              url={'/'}
                          />
                      </div>
                  </div>
              </Container>
          </section>

      </React.Fragment>
  );
};

export default NotFound;
