import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Reusable function for generating the chart configuration
const getOperatingProfitChartConfig = (data, categories, internalText) => {
    return {
        chart: {
            type: 'areaspline'
        },
        title: {
            text: '',
            align: 'left'
        },
        subtitle: {
            text: '',
            align: 'left'
        },
        legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            x: 120,
            y: 70,
            floating: true,
            borderWidth: 1,
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF'
        },
        xAxis: {
            type: 'category',
            categories: categories,
            title: {
                text: 'Year'
            },
            // min: 2018,
            // max: 2023,
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            shared: true,
            headerFormat: `<b>${internalText} {point.x}</b><br>`
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            series: {
                // pointStart: categories[0]
            },
            areaspline: {
                fillOpacity: 0.5
            }
        },
        series: [{
            name: internalText,
            data: data,
            color: '#2CAFFE'
        }]
    };
};

const OperatingPofit = ({ data, categories, keyName, internalText }) => {
    const chartOptions = getOperatingProfitChartConfig(data, categories, internalText);

    useEffect(() => {
        // This will ensure that the chart is destroyed and re-rendered
        // whenever the 'data' prop changes
        Highcharts.chart(keyName, chartOptions);
    }, [data]);

    return (
        <div id={keyName} className={'mb-5'}>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
    );
};

export default OperatingPofit;