import React from "react";
import {Row, Col, Container} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import data from "./data";
import { onError } from "Services/api/Image";

const AdSectionRightImg = ({ detail }) => {
  return(
      <React.Fragment>
          <section className={'section bg-light border-none'}>
              <Container>
                  <SectionTitle textCenter description={detail?.heading?.description}>
                      {
                        detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                      }
                  </SectionTitle>
                  <Row className="colList adRightImg">
                      <Col lg={7}>
                          <div className="box">
                              <ul className="order-list">
                                  {detail?.content?.map((item, index) => (
                                      <li key={index}>
                                          <h3>{item.title}</h3>
                                          {item.description}
                                      </li>
                                  ))}
                              </ul>
                          </div>
                      </Col>
                      <Col lg={5}>
                          <div className="box p-0">
                              <div className="img-box">
                                <a href={detail?.redirectUrl} target="_blank">
                                  <img src={detail?.featuredImage} loading="lazy" onError={onError} alt="Ad" />
                                </a>
                              </div>
                          </div>
                      </Col>
                  </Row>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default AdSectionRightImg;