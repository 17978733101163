import { getDictionary } from 'Services/api/Dictionary';
import React from 'react';
import { Container } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import _ from 'lodash'
import { useEffect } from 'react';
import { useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import classes from './index.module.scss';
import Button from "../../../../Components/Button";
import Loader from "../../../../Components/Loader";

const DictionaryDetail = () => {
    const { title } = useParams();
    const languageId = 1;
    const [data, setData] = useState();

    const loadData = () => {
        getDictionary(languageId)
        .then(resp => {
            const dictionary = JSON.parse(_.first(resp?.data)?.versionDetail);
          const wrd = dictionary.words.find(word => word.title.toLowerCase()?.replaceAll(' ', '-') === title.toLowerCase())
            if(!wrd) setData(undefined);
            else{
                setData(wrd);
            }
        })
    }
    useEffect(loadData, []);

    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };


    if (!data) {
        return (
            <Loader />
        );
    }

    return (
        <React.Fragment>
            <section className={'section'}>
                <Container>
                    <div className={classes.dictionaryDetail}>
                        <div className={'mb-3'}>
                            <a href={'/nyttig-informasjon/ordboken'} className={'btn btn-light'}><i className={'far fa-arrow-left font-13'}></i> tilbake</a>
                        </div>
                        <h2>{data.title}</h2>
                        <div dangerouslySetInnerHTML={{ __html: renderFilteredHTML(data.definition)}} />
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default DictionaryDetail;
