import React from 'react';
import classes from './index.module.scss';
import loadingIcon from "./loading.json";
import AnimatedImage from "../AnimatedImg";

const Loading = ({style}) => {
  return(
      <React.Fragment>
          <div className={classes.loading} style={style}>
              <div className={classes.box}>
                  <AnimatedImage
                      image={loadingIcon}
                      loop={true}
                      autoplay={true}
                  />
              </div>

          </div>
      </React.Fragment>
  )
}

export default Loading;