import React from "react";
import TabsWithFilter from "./TabsWithFilter";
import ArticleList from "../../Components/Blogs/Articles/ArticleList";
import Blogs from "../../Components/Blogs/CollectionList";
import TagList from "../../Components/TagList";
import CompaignBanner from "../../Components/CompaignBanner";
import compaignImg from "../../Images/img06.jpg";
import DescriptionSection from "../../Components/DescriptionSection";
import { data1 } from "./dataDescriptionSection";
import Banner from "../../Components/Banner";
import CTOimg from "../../Images/CTO1.svg";
import ContentWithImage from "../../Components/ContentWithImage";



const LoanWithSecurity = () => {
    return(
        <React.Fragment>
            <Banner
                title={
                    <>
                        <span className={'fw-normal'}>Best mortgage </span>
                        <span className={'fw-semibold'}>interest rate </span>
                        <span className={'fw-normal'}>May 2023</span>
                    </>
                }
                description={'Compare interest rates and costs for all 1,015 mortgages on the market and switch to a better and cheaper bank'}
                timeStamp={'Oppdatert tirsdag 16. mai'}
            />
            <TabsWithFilter />
            <DescriptionSection data={data1}  />
            <ContentWithImage
                title={
                    <>
                        <span className={'fw-light'}>How much</span>
                        <span className={'fw-normal'}>can</span>
                        <span className={'fw-semibold'}>you save?</span>
                    </>
                }
                description={
                    <>
                        <b>Did you know that a family can save up to NOK 20,000 annually by switching their mortgage to a cheaper bank?</b> <br /><br />
                        Easyfinance is Norway's largest comparison service for products and services within personal finance, and we source all 1,016 mortgages directly from Finansportalen.no and combine the loans with thousands of verified user reviews.
                    </>
                }
                buttonText={'That’s how much you can borrow'}
                buttonURL={'abc.com'}
                image={CTOimg}
                bg={'bg-light'}
            />
            <ArticleList />
            <CompaignBanner title={"Compaign Title"} image={compaignImg} />
            <Blogs />
            <TagList />
        </React.Fragment>
    )
}

export default LoanWithSecurity;