import React, { useEffect, useState } from 'react';
import classes from "../../index.module.scss";
import DebtCheckConsent from "./index";

const DebtCheckConsentPopup = ({ onCloseModal, style }) => {
    const [showBankIDModal, setShowBankIDModal] = useState(true);
    const closeBankIDModal = () => {
        setShowBankIDModal(false);
        onCloseModal();
    };

    return (
        <React.Fragment>
            {showBankIDModal && (
                <div className={classes.customPopup} >
                    <div className={classes.popupContent} style={style}>
                        <DebtCheckConsent onShowDebt={closeBankIDModal} />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default DebtCheckConsentPopup;
