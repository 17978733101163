import { loadComponents, COMPONENTS_LOAD, COMPONENTS_LOADED, SETTINGS_LOADED, TESTIMONIALS_LOADED } from '../actions/component';
import * as _ from 'lodash';

const initialState = {
    components: [],
    settings: {},
    testimonials: []
}

const componentReducer = (state = initialState, action) => {
    const newState = _.cloneDeep(state);
    switch(action.type){
        case COMPONENTS_LOADED:
            newState.components = action.payload;
            return newState;
        case SETTINGS_LOADED:
            newState.settings = action.payload;
            return newState;
        case TESTIMONIALS_LOADED:
            newState.testimonials = action.payload;
            return newState;
        default:
            return newState; 
    }
}

export default componentReducer;