import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import RecommendedCards from "./RecommendedCards";
import data from './data';
import SectionTitle from "../../../../Components/SectionTitle";

function useHashParams() {
    const [hashParams, setHashParams] = useState({});

    useEffect(() => {
        const hash = window.location.hash.substring(1);
        const decodedHash = decodeURIComponent(hash);
        setHashParams({ categoryName: decodedHash });
    }, []);

    return hashParams;
}


const RecommendedOffersDetail = () => {

    const { categoryName } = useHashParams();
    const defaultActiveTabIndex = data.findIndex(category => category.dataType === categoryName);


    return (
        <React.Fragment>
            <SectionTitle>
                <span className={'fw-normal'}>Our</span>
                <span className={'fw-semibold'}>recommendations</span>
            </SectionTitle>
            <Tabs defaultActiveKey={defaultActiveTabIndex >= 0 ? defaultActiveTabIndex.toString() : null}>
                {data.map(category => (
                    <Tab key={category.dataType} eventKey={category.dataType} title={category.dataType}>
                        <RecommendedCards data={category.childData} />
                    </Tab>
                ))}
            </Tabs>
        </React.Fragment>
    );
};

export default RecommendedOffersDetail;
