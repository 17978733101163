import React from "react";
import { Link } from "react-router-dom";
import classes from "./index.module.scss";
import { Container } from "react-bootstrap";
import SectionTitle from "../../SectionTitle";
import Button from "../../Button";
import data from '../data';
import Slider from "react-slick";
import { useEffect } from "react";
import { getThisMonthArticles } from "Services/api/Blog";
import { useState } from "react";

const ArticleList = ({ detail }) => {

    const [thisMonthArticles, setThisMonthArticles] = useState([]);
    const languageId = 1;
    const count = 3;
    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 479,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    useEffect(() => {
        getThisMonthArticles(languageId, count)
        .then(resp => {
            setThisMonthArticles(resp.data);
        });
    }, [])

    if (!thisMonthArticles || thisMonthArticles.length === 0) {
        return null;
    }


    return (
        <React.Fragment>
            {detail &&
                <div className={classes.blogSection}>
                    <Container>
                        <SectionTitle textCenter>
                            {detail?.heading?.title?.map(title => <span className={title.class}
                                                                        key={title.id}>{title.title}</span>)}
                        </SectionTitle>
                        <div className={classes.colList}>
                            <Slider {...settings}>
                                {thisMonthArticles?.map((item, index) => {
                                    const details = JSON.parse(item.postContents);
                                    return <div className={classes.col} key={index}>
                                        <div className={classes.box}>
                                            <a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                               className={classes.imgBox}
                                               style={{backgroundImage: `url(${details?.image})`}}></a>
                                            <div className={classes.description}>
                                                <h4 className={classes.title}><a
                                                    href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{item.title}</a>
                                                </h4>
                                                <p>{item.summary}</p>
                                                <Button
                                                    url={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                                    icon={'far fa-arrow-right'}
                                                    text={'Se mer'}
                                                    variant={'link'}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                })}
                            </Slider>
                        </div>
                        <div className={'text-center'}>
                            <Button
                                url={`/blogger`}
                                text={'Vis mer'}
                                icon={'far fa-arrow-right'}
                            />
                        </div>
                    </Container>
                </div>
            }
        </React.Fragment>
    )
}

export default ArticleList;
