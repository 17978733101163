import React from 'react';
import {Page, Text, View, Svg, Line, StyleSheet, Image, Font} from '@react-pdf/renderer';
import upperCircle from '../Images/upperCircle.png';
import waterMark from '../Images/Watermark.png';
import PDFFooter from "./Footer";
import UserDetail from "./UserDetail";
// import MonaRegular from "../../../Fonts/Mona-Sans-Regular.woff";
// import MonaBold from "../../../Fonts/Mona-Sans-Bold.woff";
//
// Font.register({
//     family: 'Mona',
//     fonts: [
//         {src: MonaRegular, fontWeight: 'normal'},
//         {src: MonaBold,fontWeight: 'bold'}
//     ]
// })

// Create styles
const styles = StyleSheet.create({
    page: {fontSize: 9,paddingTop: 40,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column',position: 'relative',},
    pageContent: {
        position: "relative",
    },
    pageTitle: {
        fontSize: 13,
        marginBottom: 10,
        color: '#014E9E',
        fontWeight: "bold",
        display: 'block',
        padding: '0 0 15px',
    },
    borderLine: {
        width: 30,
        height: 3,
        backgroundColor: '#FFB760',
        display: 'block',
        marginTop: 0,
    },
    upperCircle: {
        width: '200px',
        height: '90px',
        position: "absolute",
        top: 0,
        right: 0,
    },
    waterMark: {
        width: 130,
        height: 528,
        position: "absolute",
        top: '30%',
        transform: 'translateY(-50%)',
        right: 0,
    },
    svgContainer: {
        width: '100%',
        height: '100%',
    },
    svgLine: {
        stroke: 'black',
        strokeWidth: 2,
    },
});

// Create Document Component
const InnerPageLayout = ({children, title, userData}) => (
    <Page style={styles.page}>
        <Image style={styles.upperCircle} src={upperCircle} />
        <Image  style={styles.waterMark} src={waterMark} alt={'#'} />
        <View style={styles.pageTitle}>
            <UserDetail userData={userData} small />
            <Text>{title}</Text>
            <Text style={styles.borderLine}></Text>
        </View>
        <View style={styles.pageContent}>
            {children}
        </View>
        <PDFFooter />
    </Page>
);

export default InnerPageLayout;
