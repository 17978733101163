import React from "react";
import classes from "../index.module.scss";
import { Accordion, Col, Row, Table, Tabs, Tab, Alert } from "react-bootstrap";
import moment from "moment";

const CompanyHistory = ({ details, soleProperty }) => {
  const formatter = Intl.NumberFormat("no-NO");
  const format = (value) => (isNaN(value) ? 0 : formatter.format(value));
  const translateRemarkType = (type) => {
    if (!type || type === "") return type;
    return type
      ?.toLowerCase()
      ?.replace("debt collection", "Inkasso")
      ?.replace("debt arrangement", "Frivillig Gjeldsordning")
      ?.replace("forced debt settlement", "Tvungen Gjeldsordning")
      ?.replace("distraint", "Utlegg")
      ?.replace("nothing to distraint", "Intet til utlegg")
      ?.replace("attachment of earnings", "Lønnstrekk")
      ?.replace("enforcment proceedings", "Tvangsinnkreving");
  };
  return (
    <React.Fragment>
      {soleProperty ? (
        <>
          <Row>
            <Col lg={6}>
              <h5>Betalingsanmerkninger</h5>
              {details?.paymentRemarkDetails?.remarksOfPayment?.length > 0 ? (
                <Accordion defaultActiveKey="Note_v0">
                  {details?.paymentRemarkDetails?.remarksOfPayment?.map(
                    (pledge, index) => (
                      <Accordion.Item
                        key={index}
                        eventKey={`Note_v${index}`}
                        className={classes.borderRadius0}
                      >
                        <Accordion.Header>
                          <span>
                            Saksnr: <b>{pledge?.refNr}</b>
                          </span>
                        </Accordion.Header>
                        <Accordion.Body className={"p-0"}>
                          <ul
                            className={`${classes.dataList} ${classes.borderNone}`}
                          >
                            <li>
                              <div className={classes.col}>Type</div>
                              <div className={classes.col}>
                                {pledge?.type && pledge?.type !== ""
                                  ? pledge?.type
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Dato</div>
                              <div className={classes.col}>
                                {pledge?.date && pledge?.date !== ""
                                  ? moment(pledge?.date).format("DD-MM-YYYY")
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Kilde</div>
                              <div className={classes.col}>
                                {pledge?.source && pledge?.source !== ""
                                  ? pledge?.source
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Beløp</div>
                              <div className={classes.col}>
                                {pledge?.amount && pledge?.amount !== ""
                                  ? format(pledge?.amount) + " kr"
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Kreditor</div>
                              <div className={classes.col}>
                                {pledge?.creditor && pledge?.creditor !== ""
                                  ? pledge?.creditor
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Status</div>
                              <div className={classes.col}>
                                {pledge?.status && pledge?.status !== ""
                                  ? pledge?.status
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Periodestart</div>
                              <div className={classes.col}>
                                {pledge?.periodStart &&
                                pledge?.periodStart !== ""
                                  ? pledge?.periodStart
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Periodeslutt</div>
                              <div className={classes.col}>
                                {pledge?.periodEnd && pledge?.periodEnd !== ""
                                  ? pledge?.periodEnd
                                  : "-"}
                              </div>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  )}
                </Accordion>
              ) : (
                <Alert variant="warning">
                  Ingen betalingsanmerkninger registrert.
                </Alert>
              )}
            </Col>
            <Col lg={6}>
              <h5>Frivillig pantstillelse</h5>
              {details?.paymentRemarkDetails?.voluntaryPledges?.length > 0 ? (
                <Accordion defaultActiveKey="Note_0">
                  {details?.paymentRemarkDetails?.voluntaryPledges?.map(
                    (pledge, index) => (
                      <Accordion.Item
                        key={index}
                        eventKey={`Note_${index}`}
                        className={classes.borderRadius0}
                      >
                        <Accordion.Header>
                          <span>
                            Saksnr: <b>{pledge?.refNr}</b>
                          </span>
                        </Accordion.Header>
                        <Accordion.Body className={"p-0"}>
                          <ul
                            className={`${classes.dataList} ${classes.borderNone}`}
                          >
                            <li>
                              <div className={classes.col}>Type</div>
                              <div className={classes.col}>
                                {pledge?.type && pledge?.type !== ""
                                  ? pledge?.type
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Date</div>
                              <div className={classes.col}>
                                {pledge?.date && pledge?.date !== ""
                                  ? moment(pledge?.date).format("DD-MM-YYYY")
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Source</div>
                              <div className={classes.col}>
                                {pledge?.source && pledge?.source !== ""
                                  ? pledge?.source
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Amount</div>
                              <div className={classes.col}>
                                {pledge?.amount && pledge?.amount !== ""
                                  ? format(pledge?.amount) + " kr"
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Creditor</div>
                              <div className={classes.col}>
                                {pledge?.creditor && pledge?.creditor !== ""
                                  ? pledge?.creditor
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Status</div>
                              <div className={classes.col}>
                                {pledge?.status && pledge?.status !== ""
                                  ? pledge?.status
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Period start</div>
                              <div className={classes.col}>
                                {pledge?.periodStart &&
                                pledge?.periodStart !== ""
                                  ? pledge?.periodStart
                                  : "-"}
                              </div>
                            </li>
                            <li>
                              <div className={classes.col}>Period end</div>
                              <div className={classes.col}>
                                {pledge?.periodEnd && pledge?.periodEnd !== ""
                                  ? pledge?.periodEnd
                                  : "-"}
                              </div>
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  )}
                </Accordion>
              ) : (
                <Alert variant="warning">
                  Ingen betalingsanmerkninger registrert.
                </Alert>
              )}
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Tabs defaultActiveKey="PaymentRemarks" className={classes.tabs}>
            <Tab eventKey="PaymentRemarks" title="Betalingsanmerkninger">
              <Row>
                <Col lg={6}>
                  <h5>Sammendrag</h5>
                  <Table className={"border responsive"}>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Antall</th>
                        <th>Totalsum</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td data-label={"Type"}>
                          Uoppgjorte betalingsanmerkninger
                        </td>
                        <td data-label={"Antall"}>
                          {
                            details?.report?.negativeInformation?.paymentRemarks?.filter(
                              (remark) =>
                                ["debt collection", "distraint"].includes(
                                  remark?.type?.toLowerCase()
                                )
                            )?.length
                          }
                        </td>
                        <td data-label={"Totalsum"}>
                          {format(
                            details?.report?.negativeInformation?.paymentRemarks
                              ?.filter((remark) =>
                                ["debt collection", "distraint"].includes(
                                  remark?.type?.toLowerCase()
                                )
                              )
                              ?.map((remark) =>
                                !isNaN(remark?.amount)
                                  ? Number(remark?.amount)
                                  : 0
                              )
                              ?.reduce((a, b) => a + b, 0) ?? 0
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td data-label={"Type"}>
                          Partly savedettled payment remarks
                        </td>
                        <td data-label={"Antall"}>
                          {
                            details?.report?.negativeInformation?.paymentRemarks?.filter(
                              (remark) =>
                                !["debt collection", "distraint"].includes(
                                  remark?.type?.toLowerCase()
                                )
                            )?.length
                          }
                        </td>
                        <td data-label={"Totalsum"}>
                          {format(
                            details?.report?.negativeInformation?.paymentRemarks
                              ?.filter(
                                (remark) =>
                                  !["debt collection", "distraint"].includes(
                                    remark?.type?.toLowerCase()
                                  )
                              )
                              ?.map((remark) =>
                                !isNaN(remark?.amount)
                                  ? Number(remark?.amount)
                                  : 0
                              )
                              ?.reduce((a, b) => a + b, 0) ?? 0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
                <Col lg={6}>
                  <h5>Betalingsanmerkninger</h5>
                  <Accordion defaultActiveKey="Note_0">
                    {details?.report?.negativeInformation?.paymentRemarks?.map(
                      (remark, index) => (
                        <Accordion.Item
                          key={index}
                          eventKey={`Note_${index}`}
                          className={classes.borderRadius0}
                        >
                          <Accordion.Header>
                            <span>
                              Saksnr: <b>{remark?.refNr}</b>
                            </span>
                          </Accordion.Header>
                          <Accordion.Body className={"p-0"}>
                            <ul
                              className={`${classes.dataList} ${classes.borderNone}`}
                            >
                              <li>
                                <div className={classes.col}>
                                  Registerenhet:{" "}
                                </div>
                                <div className={classes.col}>
                                  {remark?.source}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Dato </div>
                                <div className={classes.col}>
                                  {moment(remark?.date).format("DD/MM/YYYY")}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Ref.nr. </div>
                                <div className={classes.col}>
                                  {remark?.refNr}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Beløp </div>
                                <div className={classes.col}>
                                  {format(
                                    remark?.amount &&
                                      remark?.amount !== "" &&
                                      !isNaN(remark?.amount)
                                      ? remark?.amount
                                      : ""
                                  )}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Kreditor </div>
                                <div className={classes.col}>
                                  {remark?.creditor}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Type </div>
                                <div className={classes.col}>
                                  {translateRemarkType(remark?.type)}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Status </div>
                                <div className={classes.col}>
                                  {remark?.status ?? "-"}
                                </div>
                              </li>
                              <li>
                                <div className={classes.col}>Detaljer </div>
                                <div className={classes.col}>
                                  {remark?.detail ?? remark?.details ?? "-"}
                                </div>
                              </li>
                            </ul>
                          </Accordion.Body>
                        </Accordion.Item>
                      )
                    )}
                  </Accordion>
                  {!details?.report?.negativeInformation?.paymentRemarks ||
                  details?.report?.negativeInformation?.paymentRemarks
                    ?.length === 0 ? (
                    <Alert variant="warning">
                      Ingen betalingsanmerkninger registrert.
                    </Alert>
                  ) : null}
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="VoluntaryPledge" title="Frivillig pantstillelse">
              <Row>
                <Col lg={12}>
                  <h5>Sammendrag</h5>
                  <Table className={"border responsive"}>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Antall</th>
                        <th>Totalsum</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Frivillig pantstillelse</td>
                        <td>
                          {details?.report?.additionalInformation
                            ?.voluntaryPledges?.length ?? 0}
                        </td>
                        <td>
                          {format(
                            details?.report?.additionalInformation?.voluntaryPledges
                              ?.map((pledge) =>
                                isNaN(pledge.amount) ? 0 : Number(pledge.amount)
                              )
                              ?.reduce((a, b) => a + b, 0) ?? 0
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <h5>Frivillig pantstillelse</h5>
                  <Table className={"border responsive"}>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Dato</th>
                        <th>Kilde</th>
                        <th>Ref.nr.</th>
                        <th>Beløp</th>
                        <th>Kreditor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {details?.report?.additionalInformation?.voluntaryPledges?.map(
                        (pledge, index) => (
                          <tr key={index}>
                            <td data-label={"Type"}>{pledge.type}</td>
                            <td data-label={"Date"}>
                              {moment(pledge.date).format("DD/MM/YYYY")}
                            </td>
                            <td data-label={"Source"}>{pledge.source}</td>
                            <td data-label={"Ref Nr"}>{pledge.refNr}</td>
                            <td data-label={"Amount"}>
                              {format(pledge.amount)}
                            </td>
                            <td data-label={"Creditor"}>{pledge.creditor}</td>
                          </tr>
                        )
                      )}
                      {!details?.report?.additionalInformation
                        ?.voluntaryPledges ||
                      details?.report?.additionalInformation?.voluntaryPledges
                        ?.length === 0 ? (
                        <tr>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                          <td>-</td>
                        </tr>
                      ) : null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </>
      )}
    </React.Fragment>
  );
};

export default CompanyHistory;
