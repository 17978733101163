import { getToken } from "./Auth";

export const userCompanyDetails = (companyId, safeNo) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/CreditSafe/CompanyCreditReport?${companyId ? `companyId=${companyId}` : `safeNo=${safeNo}`}`, 
    {
        headers:{
            'Authorization': `Bearer ${JSON.parse(localStorage.getItem('auth') ?? '{}').jwToken}`
        }
    })
    .then(resp => {
        if(resp.status === 200) return resp.json();
        if(resp.status === 203) return resolve({ subscribed: false })
        else reject(resp)
    })
    .then(resolve)
    .catch(reject)
});
export const defaultCompanyReport = () => new Promise((resolve, reject) => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_API}/CreditSafe/GetDefaultCompanyReport`, {
        headers:{ 
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else if(resp.status === 404)
            return resolve({ code: 404 });
        else return reject(resp);
    })
    .then(resolve)
    .catch(reject)
})
export const saveDefaultCompany = (companyNumber) => new Promise((resolve, reject) => {
    const token = getToken();

    fetch(`${process.env.REACT_APP_API}/CreditSafe/SaveDefaultCompanyReport?companyId=${companyNumber}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json()
        else return reject(resp)
    })
    .then(resolve)
    .catch(reject)
})
export const ensureDefaultCompany = (redirect = true) => new Promise((resolve, reject) => {
    defaultCompanyReport()
    .then(resp => {
        if(resp?.code === 404){
            if(redirect) window.location.href = '/find-your-company';
            else reject({reason: 'company not selected', code: 404});
        }
        else resolve(resp)
    })
    .catch(() => reject())
})
export const searchCompanies = (keyword, regNo) => new Promise((resolve, reject) => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_API}/CreditSafe/CompanySearch?${regNo ? `regNo=${regNo}` : `companyName=${keyword}`}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json()
        else return reject();
    })
    .then(resolve)
    .catch(reject)
})
export const recentlyViewedCompanies = () => new Promise((resolve, reject) => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_API}/CreditSafe/GetCreditHistory`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json()
        else return reject(resp);
    })
    .then(resolve)
    .catch(reject)
})
export const forceUpdateCompanyScore = (companyId) => new Promise((resolve, reject) => {
    const token = getToken();
    fetch(`${process.env.REACT_APP_API}/CreditSafe/CompanyCreditReport?isForceUpdateDefaultCompany=true&companyId=${companyId}`, {
        headers: { 
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => resp.status === 200 ? resp.json() : reject(resp))
    .then(resolve)
    .catch(reject)
})
export const submitQredApplication = (application) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/Qred/Create?company=${application?.organization?.orgName}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'content-type': 'application/json'
        },
        body: JSON.stringify(application)
    })
    .then(resp => {
        if(resp.status === 201) resolve(resp)
        else reject(resp)
    })
    .catch(reject)
})
export const getQredApplications = () => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/Qred/GetByUserId`, {
        headers: {
            Authorization: `Bearer ${getToken()}`
        }
    })
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else reject(resp)
    })
    .then(resolve)
    .catch(reject)
})