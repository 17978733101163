import React from 'react';
import classes from "./index.module.scss";

const LeftImgWithText = ({ detail }) => {
  return(
      <React.Fragment>
          <div className={`${classes.colPost}`}>
              {detail.map((item, index) => (
                  <div className={classes.col} key={index}>
                      <div className={classes.box}>
                          <div className={classes.imgBox} style={{ backgroundImage: `url(${item.image})` }}></div>
                          <div className={classes.description}>
                              <h4 className={classes.title}>{item.title}</h4>
                              <p>{item.content}</p>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </React.Fragment>
  )
}

export default LeftImgWithText;