export const getComponentVersionByListOfIds = (languageId, versionIds) => new Promise((resolve, reject) => {
    if(!versionIds || versionIds.length === 0 || versionIds.some(vid => !vid)) return resolve([])
    fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetAllByListOfIds?LanguageId=${languageId}&ReactComponentVersionIds=${versionIds.join('&ReactComponentVersionIds=')}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else
            reject(resp);
    })
    .then(resp => {
        const { data } = resp;
        if(!data) resolve([]);

        resolve(data.map(d => JSON.parse(d.versionDetail ?? '{}')));
    })
    .catch(err => reject(err));
})