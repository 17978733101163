
const data = [
    {
        title : "Bedriftslån",
        description : "Invester i vekst for din befrift.",
        url: '/bedrift/bedriftslån',
    },
    {
        title : "Kredittsjekk",
        description : "Kredittsjekk selskapet ditt",
        url: '/bedrift/kredittsjekk',
    },
    {
        title : "Grønne lån",
        description : "For en grønnere verden",
        url: '/bedrift/grøntlån',
    },
];

export default data;