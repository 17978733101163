import React, { useState } from "react";
import classes from "./index.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import RangeSlider from "../RangeSlider";
import { getToken } from "../../Services/api/Auth";

const ComparisonTabsBox = ({detail, className, textSmall, ref, isBusiness, isSmall, loanForm}) => {
    const token = getToken();
    const isUserLoggedIn = token && token !== '';
    const user = JSON.parse(getToken() ? localStorage.getItem('auth') : '{}');
    const [loanAmount, setLoanAmount] = useState();
    return (
        <React.Fragment>
            <div className={`${classes.compareBox} ${className} ${textSmall ? `${classes.textSmall}` : ''} ${loanForm && classes.loanForm}`} ref={ref}>
                {isBusiness || loanForm ? (
                    <React.Fragment>
                        <div className={classes.businessCalculator}>
                            <h2 className={'text-center mb-4 font-22'}>Hvor mye ønsker du å låne?</h2>
                            <RangeSlider
                                stepValue={'5000'}
                                valueMin={'10000'}
                                valueMax={'5000000'}
                                defaultValue={'300000'}
                                unit={'kr'}
                                ensureDefault
                                btnUrl={isUserLoggedIn ? user?.hasDefaultCompany ? '/loan-application' : '/find-your-company?redirect=/loan-application' : '/login-loan-application?redirect=/loan-application'}
                                btnShow={true}
                                btnText={'Søk nå'}
                                showMinMax
                                onChange={setLoanAmount}
                            />
                            <div className={'font-12 pt-3 text-light'}>Lan mellom 10 000 og 5 000 000.</div>
                            <div className={'font-12 pt-3 text-light'}> Ved à soke om kreditt samtykker du til kredittvurdering og elektronisk kommunikasjon. Se var personvernserklaring for detaljer.</div>
                            <ul className={classes.pointList}>
                                <li>0 kr i etableringsgebyr</li>
                                <li>Ingen bindingstid</li>
                                <li>Fast månedsavgift</li>
                                <li>Utbetaling samme dag</li>
                            </ul>
                        </div>
                    </React.Fragment>
                ) : isSmall ? (
                    <React.Fragment>
                        <div className={classes.businessCalculator}>
                            <RangeSlider
                                stepValue={'5000'}
                                valueMin={'10000'}
                                valueMax={'5000000'}
                                defaultValue={'300000'}
                                unit={'kr'}
                                btnUrl={isUserLoggedIn ? user?.hasDefaultCompany ? '/loan-application' : '/find-your-company?redirect=/loan-application' : '/login?redirect=/loan-application'}
                                btnShow={true}
                                btnText={'Søk nå'}
                                showMinMax
                                onChange={setLoanAmount}
                            />
                            <div className={'font-11 pt-2 text-light text-center'}>Lan mellom 10 000 og 5 000 000.</div>
                        </div>
                    </React.Fragment>
                ) :(
                    <div className={'overflow-hidden'} style={{borderRadius: '20px'}}>
                        <Tabs defaultActiveKey="Loanwithoutcollateral" className="mb-1">
                            <Tab eventKey="Loanwithoutcollateral" title={
                                <>
                                    <i className={detail?.loanWithoutSecurity?.tabIcon}></i>
                                    <span>{detail?.loanWithoutSecurity?.tabTitle}</span>
                                </>
                            }>
                                <div className={classes.box}>
                                    <h3 className={'font-24 fw-normal'}>{detail?.loanWithoutSecurity?.cardHeading}</h3>
                                    <p className={'font-18'}>{detail?.loanWithoutSecurity?.cardSubHeading}</p>
                                    <RangeSlider
                                        stepValue={'5000'}
                                        valueMin={detail?.loanWithoutSecurity?.minAmount}
                                        valueMax={detail?.loanWithoutSecurity?.maxAmount}
                                        defaultValue={detail?.loanWithoutSecurity?.defaultAmount}
                                        unit={detail?.loanWithoutSecurity?.currencyUnit}
                                        btnShow={true}
                                        onChange={setLoanAmount}
                                        btnText={detail?.loanWithoutSecurity?.buttonText}
                                        btnUrl={detail?.loanWithoutSecurity?.buttonLink}
                                        showMinMax
                                    />
                                    <div className={classes.loanExmaple}>{ detail?.loanWithoutSecurity?.loanExample }</div>
                                </div>
                            </Tab>
                            <Tab eventKey="Creditcard" title={
                                <>
                                    <i className={detail?.creditCard?.tabIcon}></i>
                                    <span>{detail?.creditCard?.tabTitle}</span>
                                </>
                            }>
                                <div className={classes.box}>
                                    <h3 className={'font-24 fw-normal'}>{detail?.creditCard?.cardHeading}</h3>
                                    <p className={'font-18'}>{detail?.creditCard?.cardSubHeading}</p>
                                    <RangeSlider
                                        stepValue={'5000'}
                                        valueMin={detail?.creditCard?.minAmount}
                                        valueMax={detail?.creditCard?.maxAmount}
                                        defaultValue={detail?.creditCard?.defaultAmount}
                                        unit={detail?.creditCard?.currencyUnit}
                                        btnShow={true}
                                        onChange={setLoanAmount}
                                        btnText={detail?.creditCard?.buttonText}
                                        param={'annualIncome'}
                                        btnUrl={detail?.creditCard?.buttonLink}
                                        showMinMax
                                    />
                                    <div className={classes.loanExmaple}>{detail?.creditCard?.loanExample}</div>
                                </div>
                            </Tab>
                            <Tab eventKey="Loanwithsecurity" title={
                                <>
                                    <i className={detail?.loanWithSecurity?.tabIcon}></i>
                                    <span>{detail?.loanWithSecurity?.tabTitle}</span>
                                </>
                            }>
                                <div className={classes.box}>
                                    <h3 className={'font-24 fw-normal'}>{detail?.loanWithSecurity?.cardHeading}</h3>
                                    <p className={'font-18'}>{detail?.loanWithSecurity?.cardSubHeading}</p>
                                    <RangeSlider
                                        stepValue={'50000'}
                                        valueMin={detail?.loanWithSecurity?.minAmount}
                                        valueMax={detail?.loanWithSecurity?.maxAmount}
                                        defaultValue={detail?.loanWithSecurity?.defaultAmount}
                                        unit={detail?.loanWithSecurity?.currencyUnit}
                                        btnShow={true}
                                        onChange={setLoanAmount}
                                        btnText={detail?.loanWithSecurity?.buttonText}
                                        btnUrl={detail?.loanWithSecurity?.buttonLink}
                                        showMinMax
                                    />
                                    <div className={classes.loanExmaple}>{detail?.loanWithSecurity?.loanExample}</div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default ComparisonTabsBox;
