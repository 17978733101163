import React, {useEffect, useState} from "react";
import classes from "./index.module.scss";
import Header from "Components/Header";
import Footer from "../../Components/Footer";

const MainLayout = ({ children }) => {

    const [headerClass, setHeaderClass] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > scrollHeight) {
                setHeaderClass(`${classes.headerFixed}`);
            } else {
                setHeaderClass('');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollHeight]);

    return (
        <div id={classes.wrapper} className={headerClass}>
            <Header />
            <main id={classes.main}>
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default MainLayout;
