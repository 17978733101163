import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {Table} from "react-bootstrap";

const FinancialYear = ({ details }) => {
    const [financialYear, setFinancialYear] = useState([]);
    const [accountingPeriod, setAccountPeriod] = useState([]);
    const [currency, setCurrency] = useState([]);
    const [consolidatedAccounts, setConsolidatedAccounts] = useState([]);
    useEffect(() => {
        setFinancialYear(details?.financialStatements?.map(fs => moment(fs?.yearEndDate).format('YYYY')));
        setAccountPeriod(details?.financialStatements?.map(fs => fs?.numberOfWeeks));
        setCurrency(details?.financialStatements?.map(fs => fs?.currency));
        setConsolidatedAccounts(details?.financialStatements?.map(fs => fs?.consolidatedAccounts ? 'Yes' : 'No'));
    }, [details]);

    return(
        <React.Fragment>
            <h5>Regnskapsår</h5>
            <div className={'text-muted mb-3'}>MERK: Alle verdier vises i 1000</div>
            <div className={'mb-5 table-responsive'}>
                <Table className={'border'} style={{minWidth: "700px"}}>
                    <thead>
                        <tr>
                            <th>Regnskapsår</th>
                            {financialYear?.map((fy, key) => <th key={key}>{fy}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>Regnskapsperiode</th>
                        {accountingPeriod?.map((ap, key) => <td key={key} data-label={""}>{ap && ap !== '' ? ap : '-'}</td>)}
                    </tr>
                    <tr>
                        <th>Valuta</th>
                        {currency?.map((cu, key) => <td key={key}>{cu && cu !== '' ? cu : '-'}</td>)}
                    </tr>
                    <tr>
                        <th>Konsoliderte regnskap</th>
                        {consolidatedAccounts?.map((ca, key) => <td key={key}>{ca && ca !== '' ? ca : '-'}</td>)}
                    </tr>
                    {/* <tr>
                        <th>Intern kommentar</th>
                        {currency?.map((cu, key) => <td key={key}><span className={'text-danger'}>Not Mapped</span></td>)}
                    </tr>
                    <tr>
                        <th>Revisors merknader</th>
                        {currency?.map((cu, key) => <td key={key}><span className={'text-danger'}>Not Mapped</span></td>)}
                    </tr> */}
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default FinancialYear;