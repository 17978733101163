// FormWizard.js

import React, { useState, Children, cloneElement, useEffect } from 'react';
import classes from './index.module.scss';
import Button from "../Button";
import { Alert, Spinner } from 'react-bootstrap';
import { FirstStepValidator, SecondStepValidator, ThirdStepValidator } from './validation';
import { submitQredApplication } from '../../Services/api/Company';
import { getConsumerReport } from '../../Services/api/Auth';


const FormWizard = ({ onSubmit, children, darkTheme, ButtonHideLastStep, application, agreement, validate }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({});
    const [error, setError] = useState([]);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [companyEngagements, setCompanyEngagements] = useState([]);

    useEffect(() => {
        if(currentStep === 0)
        getConsumerReport()
        .then(({ companyEngagements }) => setCompanyEngagements(companyEngagements))
        .catch(() => {})
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const nextStep = () => {
        if(validate(currentStep)) setCurrentStep(currentStep + 1);
    };

    const prevStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (currentStep === Children.count(children) - 1) {
            onSubmit(formData);
        } else {
            nextStep();
        }
    };

    const _handleSubmit = () => {
        setSubmitLoading(true)
        submitQredApplication(application)
        .then(resp => {
            setCurrentStep(currentStep + 1);
        })
        .finally(() => setSubmitLoading(false))
    }

    const currentStepComponent = Children.toArray(children)[currentStep];
    const childProps = {
        formData: formData,
        onChange: handleInputChange,
    };
    const currentStepClone = cloneElement(currentStepComponent, childProps);

    return (
        <form onSubmit={handleSubmit} className={`${classes.wizardForm} ${darkTheme && classes.darkTheme}`}>
            <div className={classes.progressBar}>
                {error && error.length > 0 && error.map((err, i) => <Alert key={i} variant='danger'>{err}</Alert>)}
                {currentStep > 0 && (
                    <Button
                        text={'Tilbake'}
                        variant={'link absolute left absolute top left mt-3'}
                        icon={'fal fa-angle-left font-20'}
                        iconLeft
                        onClick={prevStep}
                    />
                )}
                <ul className={classes.progressList}>
                    {Children.map(children, (step, index) => (
                        <li key={index} className={index === currentStep ? classes.current : index < currentStep ? classes.done : ''}></li>
                    ))}
                </ul>
            </div>
            {currentStepClone}
            <div className={classes.wizardFooter}>
                <div className={'text-right'}>
                    {currentStep < 2 && companyEngagements?.length > 0 ? (
                        <Button
                            text={'Neste'}
                            icon={'fas fa-arrow-right'}
                            onClick={nextStep}

                        />
                    ) : (
                        <>
                            {currentStep === 2 && <Button
                                onClick={_handleSubmit}
                                text={submitLoading ? <Spinner /> : 'Send søknad'}
                            />}
                        </>
                    )}
                </div>
            </div>
        </form>
    );
};

export default FormWizard;
