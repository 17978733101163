import React from 'react';
import classes from './index.module.scss';
import {Container, Tabs, Tab} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import draftToHtml from 'draftjs-to-html';
import Loader from "../Loader";

const TextTabs = ({ detail}) => {
    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };
  return(
      <React.Fragment>
          <section className={'section'}>
              <Container>
                  <SectionTitle textCenter description={detail?.heading?.description}>
                      { detail?.heading?.title?.map((title, i) => <span className={title.class} key={i}>{title.title}</span>) }
                  </SectionTitle>

                  <div className={`${classes.homeFilter} ${detail?.horizontal ? classes.horizontal : ''}` }>
                      <Tabs defaultActiveKey={0}>
                          {detail?.tabs?.map((tab, index) => <Tab
                                key={index}
                              eventKey={index}
                              title={
                                  <>
                                      <div className={classes.iconBox}>
                                          <i className={tab?.tabIcon}></i>
                                      </div>
                                      <span>{tab?.tabHeading}</span>
                                  </>
                              }
                          >
                              <div dangerouslySetInnerHTML={{ __html: renderFilteredHTML(tab?.tabContent) }}/>
                          </Tab>)}
                      </Tabs>
                  </div>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default TextTabs;