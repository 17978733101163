const data = [
    {
        id: 1,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 2,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
    {
        id: 3,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 4,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
    {
        id: 5,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 6,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
    {
        id: 7,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 8,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
    {
        id: 9,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 10,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
    {
        id: 11,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 12,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
    {
        id: 13,
        title: 'You calculate for a NOK 1000 000 loan with loan terms of 1 year',
        date: '02/03/2023  12:45',
    },
    {
        id: 14,
        title: 'You calculate for a NOK 1200 000 loan with loan terms of 1 year',
        date: '02/03/2023  18:45',
    },
]

export default data;