import React from 'react';
import ComparisonTabsBox from "../ComparisonTabsBox";

const LoanForm = () => {
  return(
      <React.Fragment>
          <ComparisonTabsBox isBusiness loanForm />
      </React.Fragment>
  )
}

export default LoanForm;