import React, {useEffect, useState, useRef} from 'react';
import {Accordion, Col, Form, Modal, Row} from "react-bootstrap";
import classes from "./index.module.scss";
import FormInput from "../FormInput";
import SelectDropDown from "../SelectDropDown";
import _ from 'lodash';
import Button from "../Button"
import { saveSearch } from 'Services/api/LoanSearch';
import { useSelector } from 'react-redux';


const FilterBox = React.forwardRef(({ title, schema, setLoanObject, loanObject, className, optionsList, setCurrentSort, variant }, ref) => {
    const { settings } = useSelector(state => state.settings);
    const languageId = 1;
    const hideSchema = {
        bil: ['BoatLoanType', 'OtherLoanType'],
        bat: ['OtherLoanType', 'CarLoanType'],
        forbruk: ['CarLoanType', 'BoatLoanType'],
        caravan: ['CarLoanType', 'BoatLoanType', 'OtherLoanType'],
        motorsykkel: ['CarLoanType', 'BoatLoanType', 'OtherLoanType'],
        other_vehicles:  ['CarLoanType', 'BoatLoanType', 'OtherLoanType']
    }
    const onlyRadios = ['MarketArea', 'RequirementsForAnotherProduct', 'MembershipRequired', 'OtherLoanType', 'Student']

    const hideProperties = {
        4: ['bsu', 'bsu_etter_34_ar']
    }

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [filter, setFilter] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [searchTitle, setSearchTitle] = useState('');

    const handleAccordionToggle = (eventKey) => {
        setIsAccordionOpen(eventKey === '0');
    };

    const _handleChange = ({ target }) => {
        const {name, value, type} = target;
        const tempFilter = _.cloneDeep(filter);

        if(type === 'checkbox'){
            if(!tempFilter[name]) tempFilter[name] = [];
            if(target.checked) {
                if(Array.isArray(tempFilter[name]))
                    tempFilter[name].push(value);
                else tempFilter[name] = [...tempFilter[name]?.split(','), value]
            }
            else tempFilter[name] = tempFilter[name]?.filter(v => v !== value)
        } else if(type === 'radio') {
            tempFilter[name] = value;
        } else {
            tempFilter[name] = value;
        }
        const newFilter = {};
        setFilter(tempFilter);
        Object.entries(tempFilter).map(([key, value]) => {
            newFilter[key] = String(value)
        });
        setLoanObject({ ...loanObject, ...newFilter });
    }

    const _handleSaveSearch = () => {
        const auth = JSON.parse(localStorage.getItem('auth'));
        if(auth?.jwToken && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()){
            saveSearch({
                languageId,
                loanTypeId: Number(loanObject?.loanTypeId) ?? 1,
                loanCategoryId: Number(loanObject?.loanCategoryId) ?? 1,
                loanDataSourceId: 1,
                title: searchTitle,
                searchParams: JSON.stringify({
                    loanObject: loanObject,
                    pageUrl: window.location.href
                })
            }, auth?.jwToken)
                .then(console.log)
                .catch(console.error);
        } else {
            window.location.href = '/login';
        }
    }

    const _togglePopup = () => setShowPopup(!showPopup);

    useEffect(() => {
        const tempFilter = {};
        if(!loanObject) return;
        Object.entries(loanObject).map(([key, value]) => {
            if(['additional'].includes(key)) tempFilter[key] = String(value)?.split(',');
            else tempFilter[key] = value;
        });

        // Check for 'all' or 'alle' and set it as default if not already set
        schema?.LoanFilterProperty?.forEach(property => {
            if (onlyRadios.includes(property.Name)) {
                const allOption = property.PropertyValue.find(val => ['all', 'alle'].includes(val.Value.toLowerCase()));
                if (allOption && !tempFilter[property.Name.toLowerCase()]) {
                    tempFilter[property.Name.toLowerCase()] = allOption.Value;
                }
            }
        });

        setFilter(tempFilter);
    }, [loanObject, schema]);

    return (
        <React.Fragment>
            <Modal show={showPopup} onHide={_togglePopup}>
                <Modal.Header>
                    <Modal.Title className={'mb-0'}>Lagre søk</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={12}>
                            <FormInput
                                fieldLabel={'Lagre søk'}
                                type={'text'}
                                placeholder={'Skriv inn tittelen for lagret søk'}
                                value={searchTitle}
                                onChange={e => setSearchTitle(e.target.value)}
                            />
                            <div className={'text-center'}>
                                <Button variant={'success'} text={'lagre'} onClick={_handleSaveSearch}/>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            <Accordion ref={ref} className={`${classes.filterBox} ${className} ${variant === 'topBar' && classes.topBar}`} activeKey={isAccordionOpen ? '0' : null} onSelect={handleAccordionToggle}>
                <Accordion.Item eventKey="0">
                    {variant === 'topBar' ? <>
                            <div className={classes.filterBar}>
                                <SelectDropDown
                                    placeHolder={optionsList[loanObject?.loanTypeId]?.find(option => option?.ascending === loanObject?.IsAscendingOrder && Object.keys(loanObject ?? '{}').includes(option.property))?.label ?? settings?.find(s => s?.variableName === 'cardsFilterSortByText')?.value}
                                    options={optionsList[loanObject?.loanTypeId]}
                                    onChange={e => {
                                        const tempLoanObject = _.cloneDeep(loanObject);
                                        delete tempLoanObject['SortByMonthlyCost'];
                                        delete tempLoanObject['SortByEffectiveInterest'];
                                        delete tempLoanObject['SortByCredit'];
                                        delete tempLoanObject['SortByReturn'];
                                        delete tempLoanObject['IsAscendingOrder'];
                                        setCurrentSort({ property: e.property, ascending: e.ascending });
                                        tempLoanObject[e.property] = true;
                                        tempLoanObject['IsAscendingOrder'] = e.ascending;
                                        setLoanObject(tempLoanObject)
                                    }}
                                />
                                <Accordion.Header>
                                    {title}
                                </Accordion.Header>
                            </div>
                        </> :
                        <Accordion.Header>
                            {isAccordionOpen ?
                                <span className={'text-danger m-0 d-flex align-items-center justify-content-between w-100'}>
                                    {title}
                                    <i className={'fal fa-times'}
                                       style={{
                                           backgroundColor: '#fff',
                                           marginRight: '-32px',
                                           position: 'relative',
                                           zIndex: '1',
                                       }}
                                    ></i>
                                </span> : title
                            }
                        </Accordion.Header>
                    }
                    <Accordion.Body>
                        {variant === 'topBar' ? '' :
                        <div className={classes.widget}>
                            <Form.Group>
                                <SelectDropDown
                                    placeHolder={optionsList[loanObject?.loanTypeId]?.find(option => option?.ascending === loanObject?.IsAscendingOrder && Object.keys(loanObject ?? '{}').includes(option.property))?.label ?? settings?.find(s => s?.variableName === 'cardsFilterSortByText')?.value}
                                    options={optionsList[loanObject?.loanTypeId]}
                                    onChange={e => {
                                        const tempLoanObject = _.cloneDeep(loanObject);
                                        delete tempLoanObject['SortByMonthlyCost'];
                                        delete tempLoanObject['SortByEffectiveInterest'];
                                        delete tempLoanObject['SortByCredit'];
                                        delete tempLoanObject['SortByReturn'];
                                        delete tempLoanObject['IsAscendingOrder'];
                                        setCurrentSort({ property: e.property, ascending: e.ascending });
                                        tempLoanObject[e.property] = true;
                                        tempLoanObject['IsAscendingOrder'] = e.ascending;
                                        setLoanObject(tempLoanObject)
                                    }}
                                />
                            </Form.Group>
                        </div>}
                        {schema?.LoanFilterProperty?.filter(property => property.IsRequired === 'false')?.map((item, index) => {
                            if(item?.Name === 'AccountType' && loanObject?.loanCategoryId == 15) return;
                            if((item?.Name === 'LoanType' || item?.Name === 'InterestType') && loanObject?.loanCategoryId == 6) return;
                            if(hideSchema[loanObject?.loanType]?.includes(item?.Name)) return;
                            return <div key={index} className={classes.widget}>
                                <Form.Label className={'mb-2'}>{item?.LocaleName === '' ? item?.Name : item?.LocaleName ?? item?.Name}</Form.Label>
                                {item?.PropertyValue?.map((value, index) => {
                                    if(hideProperties[loanObject?.loanTypeId]?.includes(value.Value)) return;
                                    return <FormInput
                                        key={index}
                                        type={['alle', 'all'].includes(value?.Value?.toLowerCase()) || onlyRadios.includes(item?.Name) ? 'radio' : 'checkbox'}
                                        variant={['alle', 'all'].includes(value?.Value?.toLowerCase()) || onlyRadios.includes(item?.Name) ? 'radio' : 'checkbox'}
                                        label={value?.LocaleName}
                                        value={value?.Value}
                                        checked={onlyRadios.includes(item?.Name?.toLowerCase()) ? filter[item?.Name?.toLowerCase()] === value?.Value : filter[item?.Name?.toLowerCase()]?.includes(value?.Value)}
                                        name={item?.Name?.toLowerCase()}
                                        onChange={_handleChange}
                                        id={`${item?.LocaleName === '' ? item?.Name : item?.LocaleName ?? item?.Name}-${index}`}
                                    />
                                })}
                            </div>})
                        }
                        <Button
                            text={'Lagre søk'}
                            variant={'bg-success'}
                            iconLeft
                            icon={'far fa-save'}
                            onClick={_togglePopup}
                        />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </React.Fragment>
    )
});

export default FilterBox;
