import React, { useState, useEffect } from 'react';
import AdSectionLeftImg from "Components/AdSectionLeftImg";
import AdSectionRightImg from "Components/AdSectionRightImg";
import Banks from "Components/Banks/Banks";
import ArticleList from "Components/Blogs/Articles/ArticleList";
import BestOfWeekPosts from "Components/Blogs/BestOfWeekPosts";
import CollectionList from "Components/Blogs/CollectionList";
import EditorChoice from "Components/Blogs/EditorChoicePosts";
import LatestPosts from "Components/Blogs/LatestPosts";
import OtherBlogs from "Components/Blogs/OtherBlogs";
import TrendingPosts from "Components/Blogs/TrendingPosts";
import ColSection from "Components/ColSection";
import CompaignBanner from "Components/CompaignBanner";
import ContentWithImage from "Components/ContentWithImage";
import HomeFilterTabs from "Components/HomeFilterTabs";
import CreditCardFilter from "Components/HomeFilterTabs/Loan/CreditCardFilter";
import LoanWithSecurity from "Components/HomeFilterTabs/Loan/LoanWithSecurity";
import LoanWithoutSecurityFilter from "Components/HomeFilterTabs/Loan/LoanWithoutSecurityFilter";
import TabsWithFilter from "Pages/LoanWithSecurity/TabsWithFilter";
import NewsLetter from "../Components/NewsLetter";
import Banner from "../Components/Banner";
import LoanComparisonHome from "Components/LoanComparisonHome";
import PopularBanks from "Components/Banks/PopularBanks";
import PopularLenders from "Components/Lenders/PopularLanders";
import Dictionary from "Pages/UseFulInformation/dictionary";
import GetInTouch from "Components/GetInTouch";
import ContactForm from "Components/ContactForm";
import CompanyInfo from "Components/CompanyInfo";
import TagList from "Components/TagList";
import FAQs from "Components/FAQs";
import DescriptionSection from "../Components/DescriptionSection";
import Testimonials from "Components/Testimonials";
import TextTabs from "../Components/TextTabs";
import Loading from "../Components/Loading";

function RenderPage({ detail }){
    // const [loading, setLoading] = useState(true);
    //
    // useEffect(() => {
    //     // Simulate a data fetch
    //     setTimeout(() => {
    //         setLoading(false);
    //     }, 4000); // Adjust the timeout as per actual data fetching time
    // }, []);
    //
    // if (loading) {
    //     return <Loading />; // Replace with your actual loader component
    // }

    return <>
        {detail?.map((component, index) => {
                const name = component.ReactComponent.Name;
                const versionDetail = JSON.parse(component.ReactComponentVersion.VersionDetail);
                if(name === 'Four Column with Icon')
                    return <ColSection detail={versionDetail} key={index}/>
                else if (name === 'Ad Section with Left Image')
                    return <AdSectionLeftImg detail={versionDetail} key={index}/>
                else if(name === 'Banks')
                    return <PopularBanks detail={versionDetail} key={index}/>
                else if(name === 'Lender')
                    return <PopularLenders detail={versionDetail} key={index}/>
                else if(name === 'Ad Section With Right Image')
                    return <AdSectionRightImg detail={versionDetail} key={index}/>
                else if(name === 'Compaign Banner')
                    return <CompaignBanner detail={versionDetail} key={index}/>
                else if(name === 'Latest blog')
                    return <LatestPosts key={index} detail={versionDetail} />
                else if(name === 'News Letter')
                    return <NewsLetter key={index} detail={versionDetail} />
                else if(name === 'Content With Image')
                    return <ContentWithImage detail={versionDetail} key={index}/>
                else if(name === 'Product Page Banner')
                    return <TabsWithFilter key={index}/>
                else if(name === 'Trending today blog')
                    return <TrendingPosts detail={versionDetail} key={index}/>
                else if(name === 'Editor choice blog')
                    return <EditorChoice detail={versionDetail} key={index}/>
                else if(name === 'Best of the Week blog')
                    return <BestOfWeekPosts detail={versionDetail} key={index}/>
                else if(name === 'Other Blogs')
                    return <OtherBlogs detail={versionDetail} key={index}/>
                else if(name === 'Blogs collection')
                    return <CollectionList detail={versionDetail} key={index} />
                else if(name === 'This month articles')
                    return <ArticleList detail={versionDetail} key={index} />
                else if(name === 'Description Section')
                    return <DescriptionSection detail={versionDetail} key={index} />
                else if(name === 'Page Banner')
                    return <Banner detail={versionDetail} key={index} />
                else if(name === 'Loan Without Security')
                    return <LoanWithoutSecurityFilter detail={versionDetail} key={index} />
                else if(name === 'Loan With Security')
                    return <LoanWithSecurity detail={versionDetail} key={index} />
                else if(name === 'Credit Card')
                    return <CreditCardFilter detail={versionDetail} key={index} />
                else if(name === 'Tabs')
                    return <HomeFilterTabs detail={versionDetail} key={index}/>
                else if(name === 'Comparison Tab')
                    return <LoanComparisonHome detail={versionDetail} key={index}/>
                else if(name === 'Horizontal Tabs')
                    return <TabsWithFilter detail={versionDetail} key={index} />
                else if(name === 'Dictionary')
                    return <Dictionary />
                else if(name === 'Get in Touch')
                    return <GetInTouch detail={versionDetail} key={index}/>
                else if(name === 'Contact Form')
                    return <ContactForm detail={versionDetail} key={index}/>
                else if(name === 'Company Info')
                    return <CompanyInfo detail={versionDetail} key={index}/>
                else if(name === 'Tags')
                    return <TagList detail={versionDetail?.tags?.map(tag => ({ content: tag.tagTitle, url: tag.tagUrl }))} key={index}/>
                else if(name === 'FAQs')
                    return <FAQs detail={versionDetail} key={index}/>
                else if(name === 'Testimonial')
                    return <Testimonials detail={versionDetail} key={index}/>
                else if(name === 'Text Tabs')
                    return <TextTabs detail={versionDetail} key={index}/>
                else
                    return <h1 key={index}>{ name }</h1>
            })}
    </>
}

export default RenderPage;