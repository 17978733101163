import React, { useEffect, useState }  from 'react';
import { Container, Tab, Tabs } from 'react-bootstrap';
import classes from './index.module.scss';
import LoanWithoutSecurityFilter from './Loan/LoanWithoutSecurityFilter';
import CreditCardFilter from './Loan/CreditCardFilter';
import LoanWithSecurity from './Loan/LoanWithSecurity';
import Savings from './Saving';
import draftToHtml from 'draftjs-to-html';
import { getComponentVersionByListOfIds } from 'Services/api/LoanFilter';


const HomeFilterTabs = ({ detail }) => {
    
    const tabs = detail?.tabs;
    const languageId = 1;
    return (
        <React.Fragment>
            <section className={'section py-0 bg-light-half-bottom border-bottom-none'}>
                <Container>
                    <div className={classes.homeFilter}>
                        <Tabs defaultActiveKey={tabs ? tabs[0]?.tabTitle : ''}>
                           {tabs?.map((tab, index) => {
                            return <Tab key={index}
                                eventKey={tab.tabTitle}
                                title={
                                    <>
                                        <div className={classes.iconBox}>
                                            <i className={tab.tabIcon}></i>
                                        </div>
                                        <span>{tab.tabTitle}</span>
                                    </>
                                }
                            >
                                {tab.tabComponent.loanTypeId === 1 && <LoanWithSecurity detail={ tab?.radios }/>}
                                {tab.tabComponent.loanTypeId === 2 && <LoanWithoutSecurityFilter detail={ tab?.radios }/>}
                                {tab.tabComponent.loanTypeId === 3 && <CreditCardFilter detail={ tab?.radios }/>}
                                {tab.tabComponent.loanTypeId === 4 && <Savings detail={ tab?.radios }/>}
                                {/* {tab.tabComponent.loanTypeId === 0 && <div dangerouslySetInnerHTML={{__html: draftToHtml(JSON.parse(tab.editorState))}} />} */}
                            </Tab>})}
                        </Tabs>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default HomeFilterTabs;
