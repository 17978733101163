import React from 'react';
import classes from "./index.module.scss";
import Button from "../Button";

const Package = ({ packageItem, single, selection, activePackageId, setActivePackageId }) => {
    const { title, price, creditCheck, description, features, id } = packageItem;

    return (
        <div className={`${classes.col} ${single && classes.single} `}>
            <div className={`${classes.box} ${activePackageId === id && classes.active}`}>
                {selection && <div className={classes.selectPlan}>
                    <input type={`radio`} id={id} name="packageSelect" checked={activePackageId === id} onClick={() => setActivePackageId(id)}/>
                    <label htmlFor={id}><i className={'fal fa-check'}></i></label>
                </div>}
                <div className={classes.tag}>
                    <div className={classes.text}>{title}</div>
                </div>
                <h2 className={classes.title}>
                    {price} kr
                    <span className={'font-13'}>{creditCheck}</span>
                </h2>
                <p>{description}</p>
                <ul className={'checkList text-light fw-light font-12'}>
                    {features.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
               {/* <p className={'text-light'}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean fringilla lacus eu tortor feugiat, sed.</p>*/}
                {single ? '' : (
                    <div className={classes.pkgFooter}>
                        <Button
                            url={`/company-select?planId=${id}`}
                            text={'Kjøp nå'}
                            variant={'dark'}
                            icon={'far fa-arrow-right'}
                        />
                    </div>
                )}

            </div>
        </div>
    );
}

export default Package;
