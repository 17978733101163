import React   from "react";
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import Breadcrumbs from "../Breadcrumbs";
import ComparisonTabsBox from "../ComparisonTabsBox";
import Button from "../Button";
import Login from "../../Pages/Auth/Login";
import LoginWidget from "../../Pages/Auth/LoginWidget";

const Banner = ({detail}) => {
    const hideBreadcrumbs = window.location.pathname === '/';

    return(
        <React.Fragment>
            {detail?.businessBanner ? '' : <>{!hideBreadcrumbs && (<Breadcrumbs timeStamp={true} />)}</>}

            <div className={`
                ${classes.banner}
                ${detail?.businessBanner && classes.businessBanner}
                ${detail?.bannerWithLogin && classes.bannerWithLogin}
            `}>
                <Container>
                    <div className={classes.bannerHolder}>
                        {detail?.businessBanner ? (
                            <React.Fragment>
                                <div className={classes.col}>
                                    <div className={`${classes.textHolder}`}>
                                        <h1 id="dynamicHeader">
                                            {
                                                detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                                            }
                                        </h1>
                                        <p>{detail?.heading?.description}</p>
                                        <div className={'text-center'} style={{maxWidth: '300px'}}>
                                            <Button
                                                variant={'vipps large w-100 my-2'}
                                                text={'Logg inn med'}
                                                icon={'icon-vipps mx-2'}
                                            />
                                            {/* TODO: PRODUCTION */}
                                            {/* <Button
                                                variant={'large link my-2 text-white font-12'}
                                                text={'Logg inn med'}
                                                icon={'icon-BankID-white mx-2'}
                                            /> */}
                                        </div>

                                    </div>
                                </div>
                                <div className={classes.col}>
                                    <ComparisonTabsBox isBusiness />
                                </div>
                            </React.Fragment>
                        ) : detail?.bannerWithLogin ? (
                            <React.Fragment>
                                <React.Fragment>
                                    <div className={classes.col}>
                                        <div className={`${classes.textHolder}`}>
                                            <h1 id="dynamicHeader">
                                                {
                                                    detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                                                }
                                            </h1>
                                            <p className={'font-16'}>{detail?.heading?.description}</p>
                                        </div>
                                    </div>
                                    <div className={`${classes.col} ${classes.featuredImageCol}`}>
                                        <LoginWidget featuredImage={detail?.featuredImage} />
                                    </div>
                                </React.Fragment>
                            </React.Fragment>

                        ): (
                            <React.Fragment>
                                <div className={classes.col}>
                                    <div className={`${classes.textHolder} text-center`}>
                                        <h1 id="dynamicHeader">
                                            {
                                                detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)
                                            }
                                        </h1>
                                        <p>{detail?.heading?.description}</p>
                                    </div>
                                </div>
                            </React.Fragment>
                        )}

                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Banner;