import React, { useState } from 'react';
import {Dropdown} from "react-bootstrap";
import classes from "./index.module.scss";
import Button from "../Button";
import { useSelector } from 'react-redux';
import {useLocation} from "react-router-dom";


const UserDropDown = ({ isLoggedIn, handleLogin, handleLogout, userInfo }) => {

    const { settings } = useSelector(state => state.settings)
    const dashboardUrl = window.location.pathname === '/bedrift/' ? '/bedriftsdashbord' : "/privatdashbord"
    const location = useLocation();
    const isBusiness = location.pathname.includes('/bedrift/');

    return(
        <React.Fragment>
            
                <Dropdown className={classes.userDropdown} align={'end'}>
                    {isLoggedIn ? (
                        <>
                            <Dropdown.Toggle>
                                <div className={classes.userImg}>
                                    <i className={'fas fa-user'}></i>
                                </div>
                                <span>{ `${userInfo.firstName}` }</span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <div className={classes.userInfo}>
                                    <div className={classes.userImg}>
                                        <i className={'fas fa-user'}></i>
                                    </div>
                                    <div className={'p-3'}>
                                        <h6>{ `${userInfo.firstName} ${userInfo?.lastName}` }</h6>
                                    </div>
                                </div>
                                <Dropdown.Item href={dashboardUrl}>Dashbord</Dropdown.Item>
                                <Dropdown.Item href="/profile-setting">Kontoinnstilling</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item className={'fw-bold text-danger'} onClick={() =>{
                                    localStorage.removeItem('auth');
                                    window.location.href = window.location.pathname.includes('bedrift') ? '/bedrift/' : '/';
                                }}>Logg ut</Dropdown.Item>
                            </Dropdown.Menu>
                        </>
                    ) : (
                        <React.Fragment>
                            {isBusiness ? (
                                <Button
                                    text={settings?.find(s => s?.variableName === 'loginText')?.value ? settings?.find(s => s?.variableName === 'loginText')?.value : 'Logg Inn'}
                                    url={'/bedriftspålogging?redirect=/bedriftsdashbord'}
                                    onClick={handleLogin}
                                    variant={`light fw-normal ${classes.loginBtnBusiness}`}
                                    icon={'fal fa-user-circle'}
                                    iconLeft
                                />
                            ) : (
                                <Button
                                    text={settings?.find(s => s?.variableName === 'loginText')?.value ? settings?.find(s => s?.variableName === 'loginText')?.value : 'Logg Inn'}
                                    url={'/login'}
                                    onClick={handleLogin}
                                    variant={`light fw-normal ${classes.loginBtn}`}
                                    icon={'fal fa-user-circle'}
                                    iconLeft
                                />
                            )}
                        </React.Fragment>

                    )}
                </Dropdown>
        </React.Fragment>
    )
}

export default UserDropDown;