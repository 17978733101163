import React, { Fragment } from 'react';
import { StyleSheet, Text, View } from "@react-pdf/renderer";

const PDFTable = ({ headers, data, style, columnWidths }) => {
    const defaultWidth = 'auto';

    const styles = StyleSheet.create({
        theaderRow: {flexDirection: 'row', height: 28, backgroundColor: 'rgba(238,237,237,0.35)', border: '1px solid #e9ebef' },
        theader: { fontSize: 8,fontStyle: 'bold', padding: '7px 10px 5px', flex: 1, height: 25 },
        tbodyRow: {flexDirection: 'row' },
        tbody: { fontSize: 8, padding: '6px 10px 4px', flex: 1, borderBottom: '1px solid #e9ebef' },
        title: { color: '#013367', fontSize: 14 },
    });

    const TableHead = () => (
        <View style={[styles.theaderRow]}>
            {headers.map((header, index) => (
                <View style={[styles.theader, { width: columnWidths ? columnWidths[index] : defaultWidth }]} key={index}>
                    <Text>{header}</Text>
                </View>
            ))}
        </View>
    );

    const TableBody = () => (
        <View>
            {data.map((row, rowIndex) => (
                <Fragment key={rowIndex}>
                    <View style={styles.tbodyRow}>
                        {row.map((cell, cellIndex) => (
                            <View style={[styles.tbody, { width: columnWidths ? columnWidths : defaultWidth }]} key={cellIndex}>
                                <Text>{cell}</Text>
                            </View>
                        ))}
                    </View>
                </Fragment>
            ))}
        </View>
    );

    return (
        <View style={style}>
            <TableHead />
            <TableBody />
        </View>
    );
};

export default PDFTable;
