import React, { useState } from 'react';
import classes from '../index.module.scss';
import { useEffect } from 'react';
import { getAllBanks } from 'Services/api/Bank';
import { onError } from 'Services/api/Image';
import FiltersBanks from "../FiltersBanks";


const Banks = ({ detail }) => {
    const [banks, setBanks] = useState([]);
    
    
    const [alfabet, setAlfabet] = useState('');
    const [filter, setFilter] = useState('');

    useEffect(() => {}, [alfabet, filter]);


    useEffect(() => {
        getAllBanks(1)
        .then(resp => {
            // const selectedBanks = resp.data.filter(bank => detail?.banks?.includes(bank.id));
            setBanks(resp.data);
        })
    }, []);


    return(
        <React.Fragment>
            {!detail?.buttonText && <FiltersBanks handleAlphabetClick={setAlfabet} handleSearch={setFilter} selectedAlfabet={alfabet}/>}
            <div className={classes.logoList}>
                {banks?.filter(bank => detail?.banks?.includes(bank.id))?.filter(bank => bank?.title?.toLowerCase()?.includes(filter?.toLowerCase()) && bank?.title?.toLowerCase()?.startsWith(alfabet?.toLowerCase())).map((item, index) => {
                    const bankDetails = JSON.parse(item?.detail);
                    return <div className={classes.col} key={index}>
                        <a href={`/banker/${encodeURIComponent(item?.title?.toLowerCase().replaceAll(' ', '-'))}`} className={classes.box}>
                            <div className={classes.imgBox}>
                                <img src={bankDetails?.bankImg ?? undefined} loading="lazy" onError={onError} alt={bankDetails?.title} />
                            </div>
                        </a>
                    </div>
                })}
            </div>
        </React.Fragment>
    )
}

export default Banks;