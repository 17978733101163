import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import data from "./data";
import SectionTitle from "../SectionTitle";
import Slider from "react-slick";
import { onError } from "Services/api/Image";
import { getToken } from "../../Services/api/Auth";
import AnimatedImage from "../AnimatedImg";
import animImg from "./CreditCheckYourself.json";
import Button from "../Button";
import LoginButtons from "../../Pages/Auth/LoginButtons";



const ColSection = ({ detail }) => {
    const settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: true,
        responsive: [
            {
                breakpoint: 9999,
                settings: "unslick"
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 579,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };


    return(

        <React.Fragment>
            {detail.withOutImg ?(
                <React.Fragment>
                    <section className={'section topOverlap'}>
                        <Container>
                            <div className={'colList col3 round border-0 shadow'}>
                                {detail?.component?.map((item, index) => (
                                    <div className={'col'} key={index}>
                                        <div className={'box'}>
                                            <h2 className={'title'}>{item.title}</h2>
                                            {item.description && <p>{item.description}</p> }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </Container>
                    </section>
                </React.Fragment>
            ):(
                <React.Fragment>
                    <section className={`section border-top-none ${detail?.verticleLayoutSection ? 'bg-light' : ''} ${detail?.imgOnTop ? 'bg-light-half-top' : ''}`}>
                        <Container>
                            {detail?.verticleLayoutSection ?
                                <>
                                    <Row className={'align-items-center'}>
                                        <Col lg={6}>
                                            <Slider {...settings} className={`colList ${detail?.imgOnTop || detail?.verticleLayoutSection ? 'imgOnTop col2 round  verticleLayout' : 'fullCollapsed innerArrow'}`}>
                                                {detail?.component?.map((item, index) => (
                                                    <div className={'col'} key={index}>
                                                        <a href={getToken() && getToken() !== '' ? item.url : `/login?redirect=${item.url}`} className={'box'}>
                                                            {detail?.imgOnTop || detail?.verticleLayoutSection ?(
                                                                <>
                                                                    <div className={'imgBox'}>
                                                                        <AnimatedImage
                                                                            image={item.featuredImage}
                                                                            loop={true}
                                                                            autoplay={true}
                                                                        />
                                                                    </div>
                                                                    <h3 className={'title'}>{item.title}</h3>
                                                                    {item.description && <p>{item.description}</p>}
                                                                </>
                                                            ):(
                                                                <>
                                                                    <h3 className={'title'}>{item.title}</h3>
                                                                    {item.description &&  <p>{item.description}</p> }
                                                                    <div className={'imgBox text-right'}>
                                                                        <img onError={onError} src={item.featuredImage} alt={item.title} loading="lazy" />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </a>
                                                    </div>
                                                ))}
                                            </Slider>
                                        </Col>
                                        <Col lg={6}>
                                            <SectionTitle description={detail?.heading?.description}>
                                                {detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)}
                                            </SectionTitle>
                                            <div className={'text-center'}>
                                                <LoginButtons />
                                            </div>
                                        </Col>
                                    </Row>
                                </> :
                                <>
                                    <SectionTitle textCenter description={detail?.heading?.description}>
                                        {detail?.heading?.title?.map(title => <span className={title.class} key={title.id}>{title.title} </span>)}
                                    </SectionTitle>
                                    <Slider {...settings} className={`colList  ${detail?.imgOnTop || detail?.verticleLayoutSection ? 'imgOnTop shadow round' : 'fullCollapsed innerArrow'} `}>
                                        {detail?.component?.map((item, index) => (
                                            <div className={'col'} key={index}>
                                                {detail?.imgOnTop || detail?.verticleLayoutSection ? (
                                                    <>
                                                        <a href={getToken() && getToken() !== '' ? item.url : `/login?redirect=${item.url}`} className={'box'}>
                                                            <div className={'imgBox'}>
                                                                <AnimatedImage
                                                                    image={item.featuredImage}
                                                                    loop={true}
                                                                    autoplay={true}
                                                                />
                                                            </div>
                                                            <h3 className={'title'}>{item.title}</h3>
                                                            {item.description && <p>{item.description}</p>}
                                                        </a>
                                                    </>
                                                ):(
                                                    <>
                                                        <div className={'box'}>
                                                            <h3 className={'title'}>{item.title}</h3>
                                                            {item.description &&  <p>{item.description}</p>}
                                                            <div className={'imgBox text-right'}>
                                                                <img onError={onError} src={item.featuredImage} loading="lazy" alt={item.title} />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}

                                            </div>
                                        ))}
                                    </Slider>
                                </>
                            }
                        </Container>
                    </section>
                    <style>
                        {`
                    .slick-slider{
                        display:block;
                        margin: 0px;
                        border: none !important;
                     }
                     
                     .slick-dots{
                        padding: 20px 0 0;
                     }
                `}
                    </style>
                </React.Fragment>
            )}

        </React.Fragment>
    )
}

export default ColSection;