import React from 'react';
import { View, Image, StyleSheet } from '@react-pdf/renderer';
import logo from "../Images/Efinance.png";

// Define styles for the child component
const styles = StyleSheet.create({
    logoContainer: {
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    logoImg: {
        width: 70,
    },
});

// Define the child component
const PDFFooterLogo = ({ small }) => (
    <View style={styles.logoContainer}>
        <Image style={styles.logoImg} src={logo} />
    </View>
);

export default PDFFooterLogo;
