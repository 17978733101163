export const confirmEmail = (userId, code) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/confirm-email?userId=${userId}&code=${code}`)
    .then(resp => {
        if(resp.status === 200){
            return resp.json();
        }
        else reject(resp);
    })
    .then(resolve)
    .catch(reject);
});
export const getToken = () => {
    const auth = localStorage.getItem('auth');
    if(!auth) return;
    try{
        const data = JSON.parse(auth);
        if(data?.expiresOn && new Date(data?.expiresOn) <= new Date()) return;
        return data?.jwToken;
    }
    catch(err){
        return;
    }
}
export const getConsumerReport = () => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/CreditSafe/ConsumerReportV2`,
    {
        headers: {
            'Authorization': `Bearer ${getToken()}`
        }
    })
    .then(resp => {
        if(resp.status === 200) return resp.json()
        else throw {error: 500}
    })
    .then(resp => {
        resolve(resp)
    })
    .catch(reject)
})
export const getCurrentUser = () => new Promise((resolve, reject) =>{
    const token = getToken();
    if(!token || token === '' || new Date(token?.expiresOn) < new Date()) return;
    fetch(`${process.env.REACT_APP_IDENTITY}/get-current-user`, {
        headers:{
            Authorization: `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json()
        else return reject(resp)
    })
    .then(resolve)
    .catch(reject)
})
export const resendConfirmationEmail = (userId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/resend-confirmation-email?userId=${userId}`)
    .then(resp => resp.status === 200 ? resp.json : reject(resp))
    .then(resolve)
    .catch(reject)
})
export const consentGjeldsregisteret = () => new Promise((resolve, reject) => {
    const token = getToken();
    if(!token) reject('No token available')
    fetch(`${process.env.REACT_APP_IDENTITY}/update-concent`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })
    .then(resp => {
        if(resp.status === 200) resolve(true)
    })
.catch(reject)
})
export const deactivateAccount = () => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/deactivate-user-by-id`, {
        method: 'DELETE',
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        }
    })
    .then(resp=> {
        if(resp.status === 200) resolve({})
        else reject({})
    })
    .catch(reject)
})
export const updateUser = (user) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/update-user`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(user)
    })
    .then(resp => {
        if(resp.status === 200) resolve({})
        else reject({})
    })
    .catch(reject)
})