export const getAllCategories = (languageId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogCategory/GetAll?LanguageId=${languageId}&EnabledOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .catch(err => reject(err))
})

export const getThisMonthArticles = (languageId, count) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetCurrentMonthBlogPosts?LanguageId=${languageId}&Count=${count}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject);
})

export const getOtherBlogs = (languageId, count) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetTopNBlogPosts?LanguageId=${languageId}&Count=${count}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject);
})

export const getBestOfTheWeekBlog = (languageId, count) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetBestOfTheWeekBlogPosts?LanguageId=${languageId}&Count=${count}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject);
    
})

export const getEditorChoiceBlogs = (languageId, count) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetEditorChoiceBlogPosts?LanguageId=${languageId}&Count=${count}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject);
})

export const getTrendingBlogs = (languageId, count) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetTrendingBlogPosts?LanguageId=${languageId}&Count=${count}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject);
})

export const getBlogById = (title) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetBlogPostByTitle?Title=${title}`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json())
        else
            reject(resp)
    })
    .catch(err => reject(err))
})

export const deleteBlogPost = (id) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/Delete/${id}`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json())
        else
            reject(resp)
    })
    .catch(err => reject(err))
})

export const getLatestBlogPost = (count, languageId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogPost/GetLatestNBlogPosts?LanguageId=${languageId}&Count=${count}&PublishedOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json());
        else
            reject(resp);
    })
    .then(resp => resolve(resp))
    .catch(err => reject(err));
})

export const getBlogsByCategory = (categoryTitle, languageId) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/BlogCategory/GetAll?LanguageId=${languageId}&EnabledOnly=true`)
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else reject(resp);
    })
    .then((resp) => {
        const categoryId = resp.data.find(c => c?.title?.toLowerCase()?.replaceAll(' ', '-') === categoryTitle)?.id;
        if(!categoryId){ 
            reject();
            return;
        }
        fetch(`${process.env.REACT_APP_API}/BlogPost/GetAllBlogPosts?LanguageId=${languageId}&BlogCategoryId=${categoryId}&PublishedOnly=true`)
        .then(resp => {
            if(resp.status === 200){
                return resp.json();
            }
            else reject();
        })
        .then(resolve)
        .catch(reject)
    })
    .catch(reject)
})