import image from "../../Images/img07.jpg";

const  data1 = [
    {
        title: "Heritage",
        image : image,
        detailDescription: [
            {
                subTitle: "How they can help you find the best loan offer",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "What exactly is a loan agent?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "What can they help you with?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "What can a loan agent offer?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "What should you think about when choosing a loan agent?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "Protected against hidden costs",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "What is a good loan agent?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "Is there anything else I should think about when choosing a loan agent?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "Loan agent vs. comparison service: What's the difference?",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "Find the best loan offer for you with EasyFinance.",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },
            {
                subTitle: "Privacy and security",
                description: 'Although it can be exciting to apply for a loan, it can also be a challenging process to find the best loan offer. With so many different loan options and terms to choose from, it can be difficult to know where to start. A loan agent can be a useful resource in this situation. But what exactly is a loan agent, and what can they help you with?',
                url: 'abc.com'
            },

        ]
    },
];


export { data1 };





