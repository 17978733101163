import React from 'react';
import {Text, View, StyleSheet, Image} from '@react-pdf/renderer';
import creditSafe from '../Images/creditSafe.png';
import gjled from '../Images/logoGjeldsregisteret.png';



const AboutEasyFinance = ({refImg, shortInfo}) => {
  const styles = StyleSheet.create({
    text: {
      marginBottom: '10px',
      flexDirection: 'column',
      fontSize: 8,
    },
    logoRow: {
      display: 'inline-flex',
      flexDirection: "row",
      justifyContent: "flex-end",
      width: '100%',
      paddingTop: 30,
    },
    creditSafeBox: {
      width: 80,
      marginLeft: 50,
    },
    pageTitle: {
      fontSize: 13,
      marginBottom: 20,
      color: '#014E9E',
      fontWeight: "bold",
      display: 'block',
      padding: '0 0 15px',
    },
    borderLine: {
      width: 30,
      height: 3,
      backgroundColor: '#FFB760',
      display: 'block',
      marginTop: 0,
    },
  });
  return(
      <View>
        <View>
          <View style={styles.pageTitle}>
            <Text>Takk for at du bruker EasyFinance!</Text>
            <Text style={styles.borderLine}></Text>
          </View>
          <Text style={styles.text}>Rapporten du har lastet ned inneholder nøkkelinformasjon om din økonomiske situasjon, hentet fra eksterne og pålitelige kilder. Vennligst merk at EasyFinance ikke har mulighet til å endre eller slette dataene som presenteres i rapporten. Hvis du mener at noen av opplysningene er feil, må du kontakte den relevante instansen direkte.</Text>
          <Text style={styles.text}>For gjeldsinformasjon, henter vi data fra Gjeldsregisteret. Når det gjelder kredittscore og tilhørende informasjon, benytter vi opplysninger fra kredittopplysningsselskapet Creditsafe. Creditsafe samler og bearbeider informasjon fra mange ulike kilder for å gi en oppdatert oversikt over din økonomiske situasjon.</Text>
          <Text style={styles.text}>Rapporten kan også inkludere estimert eiendomsverdi basert på data fra flere pålitelige databaser. Disse estimatene er veiledende, og vi anbefaler at du kontakter en eiendomsmegler for en nøyaktig verdivurdering.
            Har du spørsmål eller trenger mer informasjon, kan du kontakte oss på post@easyfinance.no.</Text>
         </View>
        <View style={styles.logoRow}>
          <Image style={styles.creditSafeBox} src={gjled} />
          <Image style={styles.creditSafeBox} src={creditSafe} />
        </View>
      </View>
  )
}

export default AboutEasyFinance;