import React from "react";
import classes from "./index.module.scss";
import Button from "../../../../../Components/Button";
import data from "./data.js";
import RecommendedOffers from "../RecommendedOffers";

const HouseValueRedecorate = () => {
  return(
      <React.Fragment>
          <section className={classes.section}>
              <Button
                  text={'Tilbake'}
                  url={"/privat/boligverdi"}
                  variant={'light border'}
              />
              <RecommendedOffers data={data} />
          </section>
      </React.Fragment>
  )
}

export default HouseValueRedecorate;