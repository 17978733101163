import React, { useEffect, useState } from "react";
import data from "../data";
import SectionTitle from "../../SectionTitle";
import classes from "../index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../Button";
import { getBestOfTheWeekBlog } from "Services/api/Blog";
import { Container } from "react-bootstrap";
import { onError } from "Services/api/Image";

const BestOfWeekPosts = ({ detail }) => {
    const [bestOfWeekBlog, setBestOfWeekBlog] = useState({});
    const [blogDetails, setBlogDetails] = useState({});
    const languageId = 1;
    const count = 1;
    
    useEffect(() => {
        getBestOfTheWeekBlog(languageId, count)
        .then(resp => {
            setBestOfWeekBlog(resp.data[0]);
            setBlogDetails(JSON.parse(resp?.data[0]?.postContents ?? '{}'));
        })
    }, []);

    return (
        <React.Fragment>
            <div className={classes.blogSection}>
                <Container>
                    <SectionTitle className={'pb-0'}>
                        {detail?.heading?.title?.map((title, index) => <span key={index} className={title.class}>{title?.title}</span>)}
                    </SectionTitle>
                    <div className={`${classes.colPost} ${classes.bestOfWeek}`}>
                        {bestOfWeekBlog.length === 0 && <div className={classes.editorBlogSkeleton} />}
                        <div className={classes.col}>
                            <div className={classes.box}>
                                <a href={`/blogger/${encodeURIComponent(bestOfWeekBlog?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} className={classes.imgBox}>
                                    <img src={blogDetails?.image} onError={onError} loading="lazy" alt={bestOfWeekBlog?.title} />
                                </a>
                                <div className={classes.description}>
                                    <h4 className={classes.title}><a href={`/blogger/${encodeURIComponent(bestOfWeekBlog?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{bestOfWeekBlog?.title}</a></h4>
                                    <p>{bestOfWeekBlog?.summary}</p>
                                    {bestOfWeekBlog?.title && <div className={'py-3 overflow-hidden text-left'}>
                                        <Button
                                            text={'Les mer'}
                                            url={`/blogger/${encodeURIComponent(bestOfWeekBlog?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                            icon={'far fa-arrow-right'}
                                        />
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default BestOfWeekPosts;
