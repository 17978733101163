import React, { useEffect, useRef } from 'react';
import lottie from 'lottie-web';
import classes from './index.module.scss';
import { Alert } from 'react-bootstrap';

const AnimatedImage = ({ image, width, height, loop, autoplay }) => {
    const lottieContainerRef = useRef(null);
    const animRef = useRef(null);



    useEffect(() => {

        const initializeAnimation = () => {
            if (image && lottieContainerRef.current) {
                // Cleanup previous animation (if it exists)
                if (animRef.current) {
                    animRef.current.destroy();
                }

                // Initialize the Lottie animation
                if (typeof image === 'string' && image.endsWith('.json')) {
                    // Load animation from JSON file
                    animRef.current = lottie.loadAnimation({
                        container: lottieContainerRef.current,
                        renderer: 'svg',
                        loop: loop !== undefined ? loop : true,
                        autoplay: autoplay !== undefined ? autoplay : true,
                        path: image,
                    });
                } else {
                    // Load animation from JSON object
                    animRef.current = lottie.loadAnimation({
                        container: lottieContainerRef.current,
                        animationData: image,
                        renderer: 'svg',
                        loop: loop !== undefined ? loop : true,
                        autoplay: autoplay !== undefined ? autoplay : true,
                    });
                }
            }
        };

        initializeAnimation();

        // Cleanup the animation when the component unmounts
        return () => {
            if (animRef.current) {
                animRef.current.destroy();
            }
        };
    }, [image, loop, autoplay]);

    if (!image) {
        return (
            <Alert variant="danger">
                Ingen bildedata er oppgitt. Vennligst oppgi gyldige data for å vise animasjonen.
            </Alert>
        );
    }

    // Check if image is a JSON object
    if (typeof image === 'object' && image.assets && image.layers) {
        return (
            <div
                className={classes.animateImage}
                ref={lottieContainerRef}
                style={{
                    width: width ? `${width}px` : undefined,
                    height: height ? `${height}px` : undefined,
                }}
            ></div>
        );
    }

    // Check if image is a URL pointing to a JSON file
    if (typeof image === 'string' && image.endsWith('.json')) {
        return (
            <div
                className={classes.animateImage}
                ref={lottieContainerRef}
                style={{
                    width: width ? `${width}px` : undefined,
                    height: height ? `${height}px` : undefined,
                }}
            ></div>
        );
    }

    // Default to rendering an image
    return (
        <img
            src={image}
            alt="animated"
            loading="lazy"
            style={{
                width: width ? `${width}px` : undefined,
                height: height ? `${height}px` : undefined,
            }}
        />
    );
};

export default AnimatedImage;
