import React from "react";
import classes from "../index.module.scss";
import { Col, Row, Tabs, Tab } from "react-bootstrap";
import FinancialYear from "./FinancialYear";
import Results from "./Results";
import Assets from "./Assets";
import KeyRatios from "./KeyRatios";
import Miscellaneous from "./Miscellaneous";
import EquityLiabilities from "./EquityLiabilities";
import OperatingIncome from "./OperatingIncome";
import OperatingProfit from "./OperatingProfit";
import moment from "moment";

const Accounts = ({ details }) => {
  return (
    <React.Fragment>
      <Tabs defaultActiveKey="ProfitLoss" className={classes.tabs}>
        <Tab eventKey="ProfitLoss" title="Resultatregnskap">
          <Row>
            <Col lg={6}>
              <h5>Samlede driftsinntekter</h5>
              <OperatingIncome
                internalText={"Total operating income"}
                keyName={"profitLossTotalOperatingIncome"}
                data={details?.report?.localFinancialStatements
                  ?.map((fs) => Number(fs?.profitAndLoss?.totalOperatingIncome))
                  ?.reverse()}
                categories={details?.report?.localFinancialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
              />
            </Col>
            <Col lg={6}>
              <h5>Driftsresultat</h5>
              <OperatingProfit
                internalText={"Total Operating Profit"}
                data={details?.report?.financialStatements
                  ?.map((fs) => Math.abs(fs?.profitAndLoss?.operatingProfit))
                  ?.reverse()}
                keyName={"profitLossOperatingProfit"}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FinancialYear details={details?.report} />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <Results details={details?.report} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="BalanceSheet" title="Balanse">
          <Row>
            <Col lg={6}>
              <h5>Totale eiendeler</h5>
              <OperatingIncome
                internalText={"Totale eiendeler"}
                data={details?.report?.financialStatements
                  ?.map((fs) => Number(fs?.balanceSheet?.totalAssets))
                  .reverse()}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
                keyName={"balanceSheetOperatingIncome"}
              />
            </Col>
            <Col lg={6}>
              <h5>Total kortsiktig gjeld</h5>
              <OperatingProfit
                internalText={"Total kortsiktig gjeld"}
                data={details?.report?.financialStatements
                  ?.map((fs) =>
                    Number(fs?.balanceSheet?.totalCurrentLiabilities)
                  )
                  .reverse()}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
                keyName={"balanceSheetOperatingProfit"}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FinancialYear details={details?.report} />
            </Col>
            <Col lg={12}>
              <Assets details={details?.report} />
              <EquityLiabilities details={details?.report} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="KeyRatios" title="Nøkkeltall">
          <Row>
            <Col lg={6}>
              <h5>Resultatmargin før skatt</h5>
              <OperatingIncome
                internalText={"Totale resultatmargin før skatt"}
                data={details?.report?.financialStatements
                  ?.map((fs) => Number(fs?.ratios?.preTaxProfitMargin))
                  .reverse()}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
                keyName={"keyRatioOperatingIncome"}
              />
            </Col>
            <Col lg={6}>
              <h5>Kreditordager</h5>
              <OperatingProfit
                internalText={"Totale kreditordager"}
                data={details?.report?.financialStatements
                  ?.map((fs) => Number(fs?.ratios?.creditorDays))
                  .reverse()}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
                keyName={"keyRatioOperatingProfit"}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FinancialYear details={details?.report} />
            </Col>
            <Col lg={12}>
              <KeyRatios details={details?.report} />
            </Col>
          </Row>
        </Tab>
        <Tab eventKey="OtherInfo" title="Annen informasjon">
          <Row>
            <Col lg={6}>
              <h5>Netto formue</h5>
              <OperatingIncome
                internalText={"Totale netto formue"}
                data={details?.report?.financialStatements
                  ?.map((fs) => Number(fs?.otherFinancials?.netWorth))
                  .reverse()}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
                keyName={"otherInfoOperatingIncome"}
              />
            </Col>
            <Col lg={6}>
              <h5>Arbeidskapital</h5>
              <OperatingProfit
                internalText={"Totale Arbeidskapital"}
                data={details?.report?.financialStatements
                  ?.map((fs) => Number(fs?.otherFinancials?.workingCapital))
                  .reverse()}
                categories={details?.report?.financialStatements
                  ?.map((fs) => moment(fs.yearEndDate).format("YYYY"))
                  ?.reverse()}
                keyName={"otherInfoOperatingProfit"}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <FinancialYear details={details?.report} />
            </Col>
            <Col lg={12}>
              <Miscellaneous details={details} />
            </Col>
          </Row>
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default Accounts;
