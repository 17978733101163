import img1 from "../../../Images/img63.svg"

const adSectionRightImgData = {
  heading: {
    description: "",
    title: [
      { id: 1, title: "Hvordan", class: "fw-normal" },
      { id: 2, title: "fungerer det?", class: "fw-bold" }
      // Add more title objects as needed
    ]
  },
  content: [
    {
      title: "Søk enkelt og raskt",
      description: "Når du søker hos EasyFinance, tilbyr vi ikke bare enkel og rask søknadsprosess, men gir deg også tilgang til et eget dashbord. Her kan du kontinuerlig overvåke din bedrifts økonomiske helse og score. Start søknaden nå og få de verktøyene du trenger for å følge med på og fremme din bedrifts vekst!"
    },
    {
      title: "Tilbud samme dag",
      description: "Send inn din søknad hos EasyFinance, og bli kontaktet samme dag. Vi gir deg rask respons og enkel tilgang til dine lånesøknader, slik at du kan handle raskt for din bedrift."
    },
    {
      title: "Enkel signering av lånetilbud",
      description: "Godta tilbudet enkelt ved å signere med BankID, så blir pengene overført til kontoen din samme dag!"
    },
    {
      title: "Ingen bindningstid og etableringsgebyr",
      description: "Vi vet at dine behov kan endre seg. Derfor kan du tilbakebetale lånet når som helst uten ekstra kostnader. Med oss får du full åpenhet – ingen skjulte gebyrer eller uventede overraskelser."
    }
    // Add more content objects as needed
  ],
  featuredImage: img1,
  redirectUrl: "http://example.com",

};

export default adSectionRightImgData;
