import React, { useEffect, useState } from "react";
import { FormGroup } from "react-bootstrap";
import Button from "../../Components/Button";
import classes from './index.module.scss';
import { Alert } from "react-bootstrap";
import { signicatLogin, vippsLogin } from "../../Services/api/Login";

const RegisterPersonal = () => {
    const [authObject, setAuthObject] = useState({});
    const queryParams = new URLSearchParams(window.location.search);

    useEffect(() => {
        const authString = localStorage.getItem('auth');
        const auth = JSON.parse(authString !== '' && authString ? authString : '{}');
        setAuthObject(auth);
        if(auth?.jwToken && auth?.expiresOn && new Date(auth?.expiresOn) > new Date()){
            window.location.href = '/privatdashbord';
        }
    }, []);

    const _handleVippsLogin = () => {
        vippsLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }
    const _handleBankId = () => {
        signicatLogin()
            .then(response => {
                const redirect = queryParams.get('redirect');
                if(redirect && redirect !== '') window.localStorage.setItem('redirect', redirect);
                window.location.href = response;
            })
    }
    return (
        <React.Fragment>

            <div className={`${classes.rowHolder} ${classes.smallForm}`}>
                <div className={classes.col}>
                    <React.Fragment>
                        <h2>Logg inn</h2>
                        <p>Husk, det er helt gratis og uforpliktende å bruke grunnleggende funksjoner i EasyFinance.</p>
                        <FormGroup className={classes.btnRow}>
                            <Button
                                text={'Logg inn  med Vipps'}
                                variant={'vipps  w-100'}
                                onClick={_handleVippsLogin}
                            />
                        </FormGroup>
                        {authObject.errorResponse && authObject.errorResponse !== '' ? <Alert variant='danger'>{authObject?.errorResponse}</Alert> : <></>}
                    </React.Fragment>
                </div>
            </div>

            <div className={classes.authFooter}>
                <p>Når jeg logger inn på min konto på EasyFinance.no, vil EasyFinance automatisk oppdatere mine data fra Creditsafe. Jeg bekrefter også at jeg har lest og forstått EasyFinances brukervilkår.</p>
                <p>
                Logg inn med
                    <Button
                        onClick={_handleBankId}
                        variant={'link'}
                        icon={'icon-BankID'}
                        underline
                    />
                </p>
                <div className={'text-center font-12'}>Har du ikke en bruker? <a href={'/register'} className={'text-blue'}>Registrer</a> din bedrift her.</div>
            </div>
        </React.Fragment>
    );
}

export default RegisterPersonal;

