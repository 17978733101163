import React, { useEffect, useState } from "react";
import classes from "../../index.module.scss";
import { Row, Accordion, Spinner, Col } from "react-bootstrap";
import RecommendedOffers from "../../DashboardComponent/RecommendedOffers";
import CreditScore from "../../../../Components/Charts/CreditScore";
import iconBackID from "../../../../Images/iconBankID.svg";
import SectionTitle from "../../../../Components/SectionTitle";
import Button from "../../../../Components/Button";
import PaymentRemarksList from "../../DashboardComponent/PaymentRemarksList";
import { getConsumerReport } from "../../../../Services/api/Auth";
import Loader from "../../../../Components/Loader";
import DebtCheckConsentPopup from "../../DashboardComponent/DebtCheckConsent/DebtCheckConsentPopup";
import { loadGjeldsregisteretData } from "../../../../Services/api/Loan";
import SocialMediaShare from "../../../../Components/SocialMediaShare";
import creditCheckImg from "../../../../Images/creditCheckImg.svg";
import moment from "moment";
import { getCustomerRealEastate } from "../../../../Services/api/RealEstate";
import Loading from "../../../../Components/Loading";
import DownloadButtonCreditCheckPDF from "../../../../Components/PDF/Personal/CreditCheckPDF/DownLoadButtonCreditCheckPDF";
import PDFDownLoadButton from "../../../../Components/PDF/Personal/PDFDownLoadButton";


const CreditCheck = () => {
  const user = JSON.parse(localStorage.getItem("auth"));
  const [showBankIDModal, setShowBankIDModal] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [showPaymentInfo, setShowPaymentInfo] = useState(true);
  const [showPaymentNotes, setShowPaymentNotes] = useState(false);
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [showHouseInfo, setShowHouseInfo] = useState(false);
  const [totalDebt, setTotalDebt] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const consentProvided = user?.consent;
  const dataLoaded = user?.loadDebt;
  const [loadingDebtData, setLoadingDebtData] = useState(false);
  const [debtDataLoaded, setDebtDataLoaded] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [totalProperties, setTotalProperties] = useState();

  const togglePaymentNotes = () => {
    setShowPaymentNotes(!showPaymentNotes);
    setShowPaymentInfo(!showPaymentInfo);
    setShowCompanyInfo(false);
    setShowHouseInfo(false);
  };

  const toggleCompanyInfo = () => {
    setShowCompanyInfo(!showCompanyInfo);
    setShowPaymentInfo(!showPaymentInfo);
    setShowPaymentNotes(false);
    setShowHouseInfo(false);
  };

  const togglePaymentInfo = () => {
    setShowPaymentInfo(!showPaymentInfo);
    setShowPaymentNotes(false);
    setShowCompanyInfo(false);
    setShowHouseInfo(false);
  };

  const toggleHouseInfo = () => {
    setShowHouseInfo(!showHouseInfo);
    setShowPaymentInfo(false);
    setShowPaymentNotes(false);
    setShowCompanyInfo(false);
  };

  const formatter = Intl.NumberFormat("no-NO");
  const format = (value) => (isNaN(value) ? 0 : formatter.format(value));

  useEffect(() => {
    setLoading(true);
    if (dataLoaded && consentProvided) _loadDebtData();
    Promise.all([
      getConsumerReport().then(setData),
      getCustomerRealEastate().then(({ data }) =>
          setTotalProperties(data?.length ?? 0)
      ),
    ])
        .catch((err) => console.log("ERROR: ", err))
        .finally(() => setLoading(false));
  }, []);

  const _loadDebtData = () => {
    const auth = JSON.parse(localStorage.getItem("auth"));
    localStorage.setItem("auth", JSON.stringify({ ...auth, loadDebt: true }));
    setLoadingDebtData(true);
    loadGjeldsregisteretData()
        .then((resp) => {
          setDebtDataLoaded(true);
          const loans = resp?.debtInformation?.loanTypes
              ?.filter(({ type }) => type === "repaymentLoan")
              .flatMap(({ loans }) => loans);
          const cards = resp?.debtInformation?.loanTypes
              ?.filter(
                  ({ type }) => type === "chargeCard" || type === "creditFacility"
              )
              .flatMap(({ loans }) => loans);
          const originalBalance = loans
              ?.map(({ balance }) => (isNaN(Number(balance)) ? 0 : Number(balance)))
              ?.reduce((a, b) => a + b, 0);
          const totalCredit = cards
              ?.map(
                  ({ nonInterestBearingBalance, interestBearingBalance }) =>
                      (isNaN(interestBearingBalance)
                          ? 0
                          : Number(interestBearingBalance)) +
                      (isNaN(nonInterestBearingBalance)
                          ? 0
                          : Number(nonInterestBearingBalance))
              )
              ?.reduce((a, b) => a + b, 0);
          setTotalDebt(format(Math.round((originalBalance + totalCredit) / 100)));
        })
        .catch(console.error)
        .finally(() => {
          setLoadingDebtData(false);
        });
  };

  const openBankIDModal = () => {
    setShowBankIDModal(true);
    setShowDetails(false); // Hide details when modal is opened
  };

  const closeBankIDModal = () => {
    setShowBankIDModal(false);
    setShowDetails(true); // Show details when modal is closed
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  return (
      <React.Fragment>
        {loading ? <Loading style={{position: 'absolute'}} /> : <>
          {showBankIDModal && (
              <DebtCheckConsentPopup onCloseModal={closeBankIDModal} />
          )}
          {isMobile && (
              <Row className={classes.mobileData}>
                <Col xl={12}>
                  <div className={classes.imgBox}>
                    <img src={creditCheckImg} loading="lazy" />
                  </div>
                  <p>
                    Med "Min Score" får du en helhetlig oversikt over din økonomiske
                    status. Vi gir deg detaljert informasjon om din inntekt det siste
                    skatteåret, din formue, eventuelle betalingsanmerkninger, samt
                    dine roller i selskaper og eiendeler. I tillegg til dette tilbyr
                    vi en grundig gjennomgang av din kredittscore og dens betydning.
                    Vårt mål er å gi deg et tydelig bilde av din nåværende økonomiske
                    situasjon og hjelpe deg med å ta velinformerte økonomiske
                    beslutninger fremover.
                  </p>
                </Col>
              </Row>
          )}

          <Row
              className={`${classes.dashboardCards} ${classes.col1_2} ${classes.heightAuto}`}
          >
            <div className={classes.col}>
              <div className={classes.box}>
                {loading ? (
                    <Loader style={{ minHeight: "376px", position: "relative" }} />
                ) : (
                    <CreditScore score={data?.score} />
                )}
              </div>
            </div>
            <div className={classes.col}>
              {showPaymentInfo && (
                  <div className={classes.box}>
                    <PDFDownLoadButton />
                    <div className={"font-12 text-right mb-2"} style={{ right: 0 }}>
                      {" "}
                      Oppdatert{" "}
                      {data?.lastFetchedDate && data?.lastFetchedDate !== ""
                          ? moment(data?.lastFetchedDate).format("DD-MM-YYYY")
                          : null}
                    </div>
                    {!isMobile && (
                        <p>
                          Med "Min Score" får du en helhetlig oversikt over din
                          økonomiske status. Vi gir deg detaljert informasjon om din
                          inntekt det siste skatteåret, din formue, eventuelle
                          betalingsanmerkninger, samt dine roller i selskaper og
                          eiendeler. I tillegg til dette tilbyr vi en grundig
                          gjennomgang av din kredittscore og dens betydning. Vårt mål er
                          å gi deg et tydelig bilde av din nåværende økonomiske
                          situasjon og hjelpe deg med å ta velinformerte økonomiske
                          beslutninger fremover.
                        </p>
                    )}
                    <ul className={classes.dataList}>
                      <li>
                        <div className={classes.col1}>
                          Inntekt{" "}
                          <small>(Tax year {data?.incomes?.at(0)?.incomeYear})</small>
                        </div>
                        <div className={classes.col2}>
                    <span className={"font-22"}>
                      {format(data?.incomes?.at(0)?.netIncome)}
                    </span>
                          <span className={"mx-2"}>kr</span>
                        </div>
                      </li>
                      <li>
                        <div className={classes.col1}>Formue</div>
                        <div className={classes.col2}>
                    <span className={"font-22"}>
                      {format(data?.incomes?.at(0)?.netWealth)}
                    </span>
                          <span className={"mx-2"}>kr</span>
                        </div>
                      </li>
                      <li>
                        <div className={classes.col1}>Betalingsanmerkninger</div>
                        <div className={classes.col2}>
                          <a
                              href={"#"}
                              onClick={togglePaymentNotes}
                              className={"bg-danger"}
                          >
                            Du har{" "}
                            {data?.paymentRemarkDetails?.remarksOfPayment?.length}{" "}
                            betalingsanmerkninger
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className={classes.col1}>Dine selskaper</div>
                        <div className={classes.col2}>
                          <a
                              href={"#"}
                              onClick={toggleCompanyInfo}
                              className={"bg-success"}
                          >
                            Du er tilkoblet {data?.companyEngagements?.length} selskap
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className={classes.col1}>Dine eiendommer</div>
                        <div className={classes.col2}>
                          {/*<a href={'#'} onClick={toggleHouseInfo} className={'bg-warning'}>Du har {data?.property?.registeredProperties?.length} {data?.property?.registeredProperties?.length > 1 ? 'eiendommer' : 'eiendommer'}</a>*/}
                          <a href={"/privat/boligverdi"} className={"bg-warning"}>
                            Du har {totalProperties}{" "}
                            {totalProperties > 1 ? "eiendommer" : "eiendommer"}
                          </a>
                        </div>
                      </li>
                      <li>
                        <div className={classes.col1}>Usikret gjeld</div>
                        <div className={classes.col2}>
                          {consentProvided ? (
                              debtDataLoaded ? (
                                  <React.Fragment>
                                    <span className={"font-22"}>{totalDebt}</span>
                                    <span className={"mx-2"}>kr</span>
                                    <a
                                        href={"/privat/min-gjeld"}
                                        className={"text-blue d-inline mx-3 font-12"}
                                    >
                                      Se detaljer
                                    </a>
                                  </React.Fragment>
                              ) : (
                                  <Button
                                      variant={"border small"}
                                      text={loadingDebtData ? <Spinner /> : "Sjekk gjeld"}
                                      onClick={_loadDebtData}
                                  />
                              )
                          ) : (
                              <Button
                                  text={"Sjekk gjeld"}
                                  variant={"border small"}
                                  onClick={openBankIDModal}
                              />
                          )}
                        </div>
                      </li>
                    </ul>
                    <div className={"font-12 pt-3 text-light"}>
                      Denne informasjonen tilbys av{" "}
                      <a href={"#"} target={"_blank"} className={"text-blue"}>
                        Creditsafe
                      </a>{" "}
                    </div>
                  </div>
              )}
              {showPaymentNotes && (
                  <div className={`${classes.box} bg-light-blue`}>
                    <SectionTitle textCenter className={"pb-3"}>
                      <div className={classes.btnBlock}>
                        <Button
                            text={"Tilbake"}
                            icon={"fal fa-angle-left"}
                            iconLeft
                            variant={"link small font-16 absolute left"}
                            onClick={togglePaymentNotes}
                        />
                      </div>
                      <span className={"fw-normal"}>Du har</span>
                      <span className={"fw-semibold"}>
                  {data?.paymentRemarkDetails?.remarksOfPayment?.length}{" "}
                        betalingsanmerkninger
                </span>
                    </SectionTitle>
                    <PaymentRemarksList
                        data={data}
                        onClick={togglePaymentInfo}
                        showBackButton
                    />
                  </div>
              )}
              {showCompanyInfo && (
                  <div className={`${classes.box} bg-light-blue`}>
                    <div className={classes.paymentNote}>
                      <SectionTitle textCenter className={"pb-3"}>
                        <div className={classes.btnBlock}>
                          <Button
                              text={"Tilbake"}
                              icon={"fal fa-angle-left"}
                              iconLeft
                              variant={"link small font-16 absolute left"}
                              onClick={togglePaymentInfo}
                          />
                        </div>
                        <span className={"fw-normal"}>Du har</span>
                        <span className={"fw-semibold"}>
                    {data?.companyEngagements?.length} tilknyttede selskap
                  </span>
                      </SectionTitle>
                      <Accordion className={classes.accodiranWhite}>
                        {data?.companyEngagements?.map((company, index) => {
                          return (
                              <Accordion.Item
                                  key={index}
                                  eventKey={`companyInfo${index}`}
                              >
                                <Accordion.Header>
                                  <div className={classes.colRow}>
                                    <div className={classes.col}>Firma navn</div>
                                    <div className={classes.col}>
                                      {company?.companyName}
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className={classes.colRow}>
                                    <div className={classes.col}>Rolle</div>
                                    <div className={classes.col}>
                                      <b>{company?.function}</b>
                                    </div>
                                  </div>
                                  <div className={classes.colRow}>
                                    <div className={classes.col}>Kredittscore</div>
                                    <div className={classes.col} style={{fontSize: "12px", lineHeight: "16px"}}>
                                      <b>Merk!</b> <br />
                                      Få fullstendig oversikt over selskapet ditt. Sjekk firma score, regnskap, roller og eiere i selskapet ditt.<br />
                                      <Button
                                          url={'/bedriftsdashbord'}
                                          variant={'link underline px-0 mt-2'}
                                          text={"Sjekk firma score"}
                                      />
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                          );
                        })}
                      </Accordion>
                    </div>
                  </div>
              )}
              {showHouseInfo && (
                  <div className={`${classes.box} bg-light-blue`}>
                    <div className={classes.paymentNote}>
                      <SectionTitle textCenter className={"pb-3"}>
                        <div className={classes.btnBlock}>
                          <Button
                              text={"Tilbake"}
                              icon={"fal fa-angle-left"}
                              iconLeft
                              variant={"link small font-16 absolute left"}
                              onClick={togglePaymentInfo}
                          />
                        </div>
                        <span className={"fw-normal"}>Du har </span>
                        <span className={"fw-semibold"}>
                    {data?.property?.registeredProperties?.length} tilknyttede
                    eiendommer
                  </span>
                      </SectionTitle>
                      <Accordion className={classes.accodiranWhite}>
                        {data?.property?.registeredProperties?.map(
                            (property, index) => {
                              return (
                                  <Accordion.Item
                                      key={index}
                                      eventKey={`companyInfo${index}`}
                                  >
                                    <Accordion.Header>
                                      <div className={classes.colRow}>
                                        <div className={classes.col}>Owner</div>
                                        <div className={classes.col}>
                                          {String(property?.owners)}
                                        </div>
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div className={classes.colRow}>
                                        <div className={classes.col}>Property ID</div>
                                        <div className={classes.col}>
                                          {property?.cadastreKey}
                                        </div>
                                      </div>
                                      <div className={classes.colRow}>
                                        <div className={classes.col}>
                                          Property details
                                        </div>
                                        <div className={classes.col}>
                                          To see details Verify with
                                          <a href={"#"} className={"d-inline mx-2"}>
                                            <img src={iconBackID} loading="lazy" />
                                          </a>
                                        </div>
                                      </div>
                                    </Accordion.Body>
                                  </Accordion.Item>
                              );
                            }
                        )}
                      </Accordion>
                    </div>
                  </div>
              )}
            </div>
          </Row>
          <RecommendedOffers />
          <SocialMediaShare
              PostUrl={"https://easyfinance.no"}
              PostTitle={
                "Sjekk ut denne nettsiden! Gratis kredittsjekk, gjeldsoversikt og boligverdi. Få bedre forståelse av din økonomi!"
              }
          />
        </> }
      </React.Fragment>
  );
};

export default CreditCheck;
