import React from 'react';
import Banner from "../../Components/Banner";
import UseFulInfomation from "../../Components/UseFulInfomation";
import saveMoney from "../../Images/saveMoney.svg";
import ContentWithImage from "../../Components/ContentWithImage";

const UseFulInformation = () => {
  return(
      <React.Fragment>
          <Banner
              title={
                  <>
                      <span className={'fw-light'}>Useful </span>
                      <span className={'fw-medium'}>Information</span>
                  </>
              }
              description={'Lorem ipsum dolor sit amet consectetur. At diam in sed pellentesque sapien auctor at imperdiet urna. Sagittis risus tortor nunc sollicitudin. Tortor elementum volutpat lobortis nam turpis lorem volutpat.'}
          />
          <UseFulInfomation />
          <ContentWithImage
              title={
                  <>
                      <span className={'fw-light'}>Save </span>
                      <span className={'fw-medium'}>Money</span>
                  </>
              }
              description={
                  <>
                      Lorem ipsum dolor sit amet consectetur.
                      Proin vel proin.
                  </>
              }
              buttonText={'Go to company'}
              buttonURL={'abc.com'}
              image={saveMoney}
          />
      </React.Fragment>
  )
}

export default UseFulInformation;