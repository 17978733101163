import React from "react";
import classes from "./index.module.scss";
//import logo from "../../Images/EasyFInanceHorizontal.svg";
//import logo from "../../Images/EfinanceLogoYellowNeedleSvg.svg";
import logo from './logoIcon.json';
import { onError } from "Services/api/Image";
import AnimatedImage from "../AnimatedImg";
import { useEffect } from "react";
import {useLocation} from "react-router-dom";


const Logo = ({logoUrl, darkFooter}) => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusinessPage = decodedPathname.includes('business') || decodedPathname.includes('bedrift/');

    return (
        <>
            <div className={`${classes.logo} ${darkFooter && classes.darkFooter}`}>
                <a href={`${isBusinessPage ? "/bedrift/" : "/"}`}>
                    {/*<img src={logo} onError={onError} alt={"Easy Finance"} />*/}
                    {/* {logoUrl && logoUrl !== '' ? 
                     <AnimatedImage
                        image={logoUrl}
                        loop={true}
                        autoplay={true}
                    /> : */}
                     <AnimatedImage
                        image={logo}
                        loop={true}
                        autoplay={true}
                    />
                    {/* } */}
                </a>
            </div>
        </>
    )
}

export default Logo;