import React, { useState } from 'react';
import {Col, Row} from "react-bootstrap";
import classes from './index.module.scss';
import Button from "../Button";

const SlideBox = ({ children, title, titleAsButton, variant, openTitleText, closeTitleText, closeIcon, openIcon}) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpenClose = () => {
        setIsOpen(!isOpen);
    };


    return(
        <React.Fragment>
            <div className={classes.slideBox}>
                {titleAsButton ? (
                    <Button
                        variant={variant}
                        text={`${isOpen ? openTitleText : closeTitleText}`}
                        //icon={'fas fa-chevron-down'}
                        icon={`${isOpen ? openIcon : closeIcon}`}
                        onClick={toggleOpenClose}
                    />
                ) : (
                    <div className={classes.boxTitle} onClick={toggleOpenClose}>
                        <h3>{title}</h3>
                    </div>
                )}

                {isOpen && <div className={classes.slideContent}>{children}</div>}
            </div>
        </React.Fragment>
    )
}

export default SlideBox;