import React, { useEffect, useRef } from 'react';
import classes from './index.module.scss';
import Button from "../../Button";
import {Alert} from "react-bootstrap";
import { onError } from 'Services/api/Image';

const CompareSidebar = ({selectedData, onClose, handleDelete}) => {
    const sidebarRef = useRef(null);

    const colWidth = 400; // Set the width of your column here

    const scrollNext = () => {
        if (sidebarRef.current) {
            const currentScroll = sidebarRef.current.scrollLeft;
            sidebarRef.current.scrollTo({
                left: currentScroll + colWidth,
                behavior: 'smooth',
            });
        }
    };
    useEffect(() => {
        if(selectedData?.length === 0) onClose();
    })

    const scrollPrevious = () => {
        if (sidebarRef.current) {
            const currentScroll = sidebarRef.current.scrollLeft;
            sidebarRef.current.scrollTo({
                left: currentScroll - colWidth,
                behavior: 'smooth',
            });
        }
    };


    return(
        <React.Fragment>
            <div className={classes.compareSidebar}>
                <Button onClick={onClose} icon={'fal fa-times'} iconLeft variant={'link CloseBtn'} />
                <div className={classes.navigation}>
                    <div className={`${classes.arrowButton} ${classes.prev}`} onClick={scrollPrevious}>
                        <i className={'fas fa-long-arrow-left'}></i>
                    </div>
                    <div className={`${classes.arrowButton} ${classes.next}`} onClick={scrollNext}>
                        <i className={'fas fa-long-arrow-right'}></i>
                    </div>
                </div>
                <div className={classes.colRow} ref={sidebarRef}>

                    {selectedData?.map((item, index)=> {
                        return <div className={classes.col}  key={index} style={{maxWidth: colWidth}}>
                            <div className={classes.box}>
                                <div className={classes.imgBox}>
                                    <img src={item?.LoanSchema?.ImageSrc} loading="lazy" alt='Bank Logo'/>
                                </div>
                                <ul>{item?.LoanSchema?.Property?.map((property, index) => {

                                        if(property?.ShowInDetail === 'true' || property?.Name === 'Forbehold' || property?.Name === 'LeverandorTekst') return;

                                        return <li key={index}><span>{ property?.LocaleName && property?.LocaleName !== '' ? property.LocaleName : property?.Name }:</span><span>{property?.Value} {property?.Symbol}</span></li>
                                    })}
                                </ul>
                                <div className={classes.btnList}>
                                    <Button onClick={() => {
                                        handleDelete(index);
                                    }} variant={'danger border small w-100'} icon={'fas fa-trash-alt'} iconLeft text={'Fjerne'} />
                                    {/* TODO: set disabled to proper condition */}
                                    <Button
                                        text={'Søk'}
                                        icon={'far fa-arrow-right'}
                                        url={item?.LoanSchema?.Url}
                                        disabled={item?.LoanSchema?.CardType === 'NonCorporate'}
                                        variant={`${item?.LoanSchema?.Featured?.toLowerCase() === 'true' ? 'bg-success' : ''} small`}
                                    />
                                </div>
                                <div className={classes.loanExample}>{item?.LoanSchema?.Example}</div>
                            </div>
                        </div>
                    })}

                </div>
                <div>
                    <Alert variant={'warning'}>
                        Tilbud vil bli sammenlignet når to eller flere tilbud legges til.
                    </Alert>
                    <div className={'font-12'}>
                        <b>Merknad: </b>
                        Sammenligning av tilbud vil skje under spesifikke betingelser. I dette tilfellet vil sammenligningen skje når to eller flere tilbud kombineres eller inkluderes på en eller annen måte. Sammenligningen kan være knyttet til deres verdi, fordeler, vilkår eller andre relevante faktorer.
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CompareSidebar;