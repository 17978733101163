import { getToken } from "./Auth";
export const getCustomerRealEastate = () =>
  new Promise((resolve, reject) =>
    fetch(`${process.env.REACT_APP_API}/Eiendomsverdi/GetRealStates`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const data = await resp.json();
          resolve(data);
        } else reject({ code: resp.status });
      })
      .catch(reject)
  );
export const getMarketeEstimate = (filters) =>
  new Promise((resolve, reject) => {
    let url = `Eiendomsverdi/GetMarketEstimates`;
    if (
      filters?.organizationNumber &&
      filters?.shareNumber &&
      filters?.organizationNumber !== "" &&
      filters?.shareNumber !== ""
    ) {
      url =
        url +
        `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    } else {
      url =
        url +
        `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    }
    fetch(`${process.env.REACT_APP_API}/${url}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const data = await resp.json();
          resolve(data);
        } else reject({ code: resp.status });
      })
      .catch(reject);
  });
export const getPropertyAttributes = (filters) =>
  new Promise((resolve, reject) => {
    let url = `Eiendomsverdi/GetAttributes`;
    if (
      filters?.organizationNumber &&
      filters?.shareNumber &&
      filters?.organizationNumber !== "" &&
      filters?.shareNumber !== ""
    ) {
      url =
        url +
        `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    } else {
      url =
        url +
        `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    }
    fetch(`${process.env.REACT_APP_API}/${url}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const data = await resp.json();
          resolve(data);
        } else reject({ code: resp.status });
      })
      .catch(reject);
  });
export const getPropertyOwners = (filters) =>
  new Promise((resolve, reject) => {
    let url = `Eiendomsverdi/GetOwners`;
    if (
      filters?.organizationNumber &&
      filters?.shareNumber &&
      filters?.organizationNumber !== "" &&
      filters?.shareNumber !== ""
    ) {
      url =
        url +
        `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    } else {
      url =
        url +
        `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    }
    fetch(`${process.env.REACT_APP_API}/${url}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const data = await resp.json();
          resolve(data);
        } else reject({ code: resp.status });
      })
      .catch(reject);
  });
export const getPropertyEvEstimateBasis = (filters) =>
  new Promise((resolve, reject) => {
    let url = `Eiendomsverdi/GetEVEstimateBasis`;
    if (
      filters?.organizationNumber &&
      filters?.shareNumber &&
      filters?.organizationNumber !== "" &&
      filters?.shareNumber !== ""
    ) {
      url =
        url +
        `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    } else {
      url =
        url +
        `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    }
    fetch(`${process.env.REACT_APP_API}/${url}`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) {
          const data = await resp.json();
          resolve(data);
        } else reject({ code: resp.status });
      })
      .catch(reject);
  });
export const saveRealEstateLog = (filters, model) =>
  new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/Eiendomsverdi/SaveHistoryDate`;
    // if (filters?.organizationNumber && filters?.shareNumber && filters?.organizationNumber !== '' && filters?.shareNumber !== '') {
    //   url =
    //     url +
    //     `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    // } else {
    //   url =
    //     url +
    //     `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    // }
    // url += `&details=${JSON.stringify(model)}`
    fetch(url, {
      method: "POST",
      headers: {
        accept: "*/*",
        "content-type": "application/json",
        Authorization: `Bearer ${getToken()}`,
      },
      body: JSON.stringify({
        organizationNumber: filters.organizationNumber,
        shareNumber: filters.shareNumber,
        knr: filters.knr,
        gnr: filters.gnr,
        bnr: filters.bnr,
        fnr: filters.fnr,
        snr: filters.snr,
        details: JSON.stringify(model),
      }),
    })
      .then(resolve)
      .catch(reject);
  });
export const getRealEstateLog = (filters) =>
  new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/Eiendomsverdi/GetHistory`;
    if (
      filters?.organizationNumber &&
      filters?.shareNumber &&
      filters?.organizationNumber !== "" &&
      filters?.shareNumber !== ""
    ) {
      url =
        url +
        `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    } else {
      url =
        url +
        `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) resolve(await resp.json());
        else reject(resp);
      })
      .catch(reject);
  });
export const getEnergyConsumption = (filters) =>
  new Promise((resolve, reject) => {
    let url = `${process.env.REACT_APP_API}/Eiendomsverdi/GetEnergyEstimation`;
    if (
      filters?.organizationNumber &&
      filters?.shareNumber &&
      filters?.organizationNumber !== "" &&
      filters?.shareNumber !== ""
    ) {
      url =
        url +
        `?organizationNumber=${filters?.organizationNumber}&shareNumber=${filters?.shareNumber}`;
    } else {
      url =
        url +
        `?knr=${filters?.knr}&gnr=${filters?.gnr}&bnr=${filters?.bnr}&fnr=${filters?.fnr}&snr=${filters?.snr}`;
    }
    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then(async (resp) => {
        if (resp.status === 200) resolve(await resp.json());
        else reject(resp);
      })
      .catch(reject);
  });
export const getCompleteRealEstateData = () => new Promise(async (resolve, reject) => {
  const { data } = await getCustomerRealEastate();
  const result = [];
  if (!data || data?.length === 0) return;
  for (var i = 0; i < data?.length; i++) {
    const currentAddress = data[i];
    if (!currentAddress) return;
    const filterObj = {
      organizationNumber:
        currentAddress?.housingCooperativeShareIdentity?.housingCooperative
          ?.organizationNumber,
      shareNumber:
        currentAddress?.housingCooperativeShareIdentity?.shareNumber,
      knr: currentAddress?.cadastre?.kNr,
      gnr: currentAddress?.cadastre?.gNr,
      bnr: currentAddress?.cadastre?.bNr,
      fnr: currentAddress?.cadastre?.fNr,
      snr: currentAddress?.cadastre?.sNr,
    };
    const [
      marketeEstimates,
      propertyAttribs,
      propertyOwner,
      propertyEvEstimateBasis,
    ] = await Promise.all([
      getMarketeEstimate(filterObj)
        .then(({ data }) => data)
        .catch((err) => null),
      getPropertyAttributes(filterObj)
        .then(({ data }) => data)
        .catch(() => null),
      getPropertyOwners(filterObj)
        .then(({ data }) => data)
        .catch(() => null),
      getPropertyEvEstimateBasis(filterObj)
        .then(({ data }) => data)
        .catch((err) => null),
      getEnergyConsumption(filterObj)
        .then(({ data }) => data)
        .catch((err) => null)
    ])
    result.push({
      marketeEstimates,
      propertyAttribs,
      propertyOwner,
      propertyEvEstimateBasis,
      realEstate: currentAddress
    })
  };
  resolve(result);
})