import img1 from "../../../../Images/unofinans.svg";

const adDataDebtCheck = [
    {
        id: "1",
        title: "uno finans",
        description: "EasyFinance samarbeider med Uno Finans for at våre medlemmer skal få de beste lånevilkårene. Unofinans tilbyr en svært enkel og brukervennlig lånesøknadsprosess, hvor du med bare én søknad kan motta flere tilbud.",
        img: img1,
        btnUrl: "https://ormedbyte.com/click.track?CID=430720&AFID=529108",
        rating: "4.8",
        loanExample: "Renteeksempel: Nominell rente 10.10%, effektiv rente 10.86%, 300 000kr, o/10 år, kostnad 181 338. Totalt 481 338",
    },
];

export default adDataDebtCheck;