import React from "react";
import { Text, View, StyleSheet, Link } from '@react-pdf/renderer';
import InnerPageLayout from "../../../PDFComponent/InnerPageLayout";
import PageHeader from './PageHeader';
import Remark from './Remarks';
import CompanyEngagement from './CompanyEngagement';
import AboutEasyFinance from "../../../PDFComponent/AboutEasyFinance";
import PDFTable from "../../../PDFComponent/Tables";

const CreditCheckList = ({ creditData, totalDebt, debtData, totalProperties, userData, remainingConsumerLoan, approvedConsumerLoan }) => {
    const styles = StyleSheet.create({
        section: { marginBottom: 20 },
        creditSafeBox: { paddingTop: 10, marginLeft: 0, fontSize: 7 },
        link: { color: '#000' },
        rowTwoCol: { flexDirection: 'row', flexWrap: 'wrap', margin: '0 -5px 10px' },
        heading: {fontSize:12, marginBottom: 10},
        text: {fontSize: 7, marginBottom: 5},
        row: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 2 },
        text1: { flexDirection: 'column' },
        text2: { flexDirection: 'column', fontWeight: "700", fontSize: 11 },
        secTitle: { color: '#013367', fontSize: 11, marginBottom: 5 },
        header: { flexDirection: 'row' },
        title: { width: '50%' },
        total: { fontSize: 9, textAlign: 'right', fontWeight: "black" },
    });



    const format = (value, divideBy100 = false) => {
        if (isNaN(value)) return "N/A";
        const formattedValue = Intl.NumberFormat("no-NO").format(divideBy100 ? value / 100 : value);
        return `${formattedValue} kr`;
    };

    const formatDebt = (value, divideBy100 = true, round = false, appendZeros = true) => {
        if (isNaN(value)) return 0;
        const formatter = Intl.NumberFormat('no-NO', {
            minimumFractionDigits: appendZeros ? 2 : 0,
            maximumFractionDigits: appendZeros ? 2 : 0
        });
        const roundedValue = round ? Math.round(divideBy100 ? value / 100 : value) : divideBy100 ? value / 100 : value;
        if (isNaN(roundedValue)) return 0;
        return formatter.format(roundedValue);
    };

    const totalRemarks = creditData?.paymentRemarkDetails?.remarksOfPayment?.length || 0;
    const totalAmount = creditData?.paymentRemarkDetails?.remarksOfPayment?.reduce(
        (total, remark) => total + parseFloat(remark.amount || 0),
        0
    );



    return (
        <React.Fragment>
            <InnerPageLayout userData={userData} title={'Min kredittscore'}>
                <View>
                    <View style={[styles.section, styles.rowTwoCol]}>
                        <PageHeader title="Kredittscore" value={`${creditData?.score?.currentCreditScore?.score} / 100`} color={'#ebf3fb'} />
                        <PageHeader title={`Inntekt (Skatteåret ${creditData?.incomes?.at(0)?.incomeYear})`} value={format(creditData?.incomes?.at(0)?.netIncome)} />
                        <PageHeader title="Formue" value={format(creditData?.incomes?.at(0)?.netWealth)} />
                        <PageHeader title="Usikret gjeld" value={totalDebt || "N/A"} color={'#c8dffc'} text={'#014E9E'} />
                        <PageHeader title="Dine selskaper" value={creditData?.companyEngagements?.length ? creditData?.companyEngagements?.length : "0"} color={'#F2FAF6'} text={'#47B881'} />
                        <PageHeader title="Dine eiendommer" value={`${totalProperties}`} color={'#FFF1DF'} text={'#FF8B00'} />
                        <PageHeader title="Betalingsanmerkninger" value={totalRemarks} color={'#FEF2F2'} text={'#F64C4C'} />
                        <PageHeader title="Totalbeløp betalingsanmerkninger" value={format(Math.round(totalAmount))} />
                    </View>
                    <AboutEasyFinance />
                </View>
            </InnerPageLayout>
            <InnerPageLayout userData={userData} title={'Betalingsanmerkninger'}>
                <View>
                    <View style={[styles.section, styles.rowTwoCol]}>
                        <PageHeader title="Betalingsanmerkninger" value={totalRemarks} color={'#FEF2F2'} text={'#F64C4C'} />
                        <PageHeader title="Totalbeløp betalingsanmerkninger" value={format(Math.round(totalAmount))} />
                    </View>
                    {creditData?.paymentRemarkDetails?.remarksOfPayment?.map((remark, index) => (
                        <Remark key={index} remark={remark} />
                    ))}
                </View>
            </InnerPageLayout>
            <InnerPageLayout userData={userData} title={'Min gjeld'}>
                <View style={[styles.section, styles.rowTwoCol]}>
                    <PageHeader title="Godkjent forbrukslån" value={`${debtData ? formatDebt(approvedConsumerLoan) : '0'} kr`} color={'#fff1df'} text={'#ff8b00'} />
                    <PageHeader title="Gjenværende forbrukslån" value={`${debtData ? formatDebt(remainingConsumerLoan)  : '0'} kr`} color={'#f9f9f9'} text={'#000000'} />
                    <PageHeader title="Innvilget kredittramme" value={`${debtData ? format(debtData.loanTypes?.filter(({ type }) => type === 'creditFacility' || type === 'chargeCard')?.flatMap(({ loans }) => loans)?.map(({ creditLimit }) => isNaN(Number(creditLimit)) ? 0 : Number(creditLimit))?.reduce((a, b) => a + b, 0), true, true, false) : '0'}`} color={'#f2faf6'} text={'#47b881'} />
                    <PageHeader title="Brukt kreditt" value={`${debtData ? format(debtData.loanTypes?.filter(({ type }) => type === 'creditFacility' || type === 'chargeCard')?.flatMap(({ loans }) => loans)?.map(({ interestBearingBalance, nonInterestBearingBalance }) => (isNaN(Number(interestBearingBalance)) ? 0 : Number(interestBearingBalance)) + (isNaN(Number(nonInterestBearingBalance)) ? 0 : Number(nonInterestBearingBalance)))?.reduce((a, b) => a + b, 0), true, true, false) : '0'}`} color={'#c8dffc'} text={'#014E9E'} />
                    {/*<PageHeader title="Brukt kreditt" value={totalDebt} color={'#c8dffc'} text={'#014E9E'} />*/}
                </View>
                <View wrap={false}>
                    {debtData?.loanTypes?.find(({ type }) => type === 'repaymentLoan')?.loans?.length > 0 &&
                        <View style={styles.section1} wrap={false}>
                            <Text style={styles.secTitle}>Forbrukslån</Text>

                            {debtData?.loanTypes?.find(({ type }) => type === 'repaymentLoan')?.loans?.map((loan, index) => {
                                const tableHeader = ['Opprinnelig lånebeløp kr', 'Rentebærende saldo kr (restlån)', 'Gjenværende terminer', 'Nominell rente %', 'Gebyr kr', 'Terminlengde', 'Medlåntaker'];
                                const tableData = [
                                    !isNaN(loan?.originalBalance) ? formatDebt(loan?.originalBalance) : '-',
                                    !isNaN(loan?.balance || loan?.interestBearingBalance) ? formatDebt(loan?.balance || loan?.interestBearingBalance) : '-',
                                    !isNaN(loan?.terms) !== '' ? formatDebt(loan?.terms, false) : '-',
                                    !isNaN(loan?.nominalInterestRate) ? formatDebt(loan?.nominalInterestRate) : '-',
                                    !isNaN(loan?.installmentCharges) ? formatDebt(loan?.installmentCharges) : '-',
                                    loan?.installmentChargePeriod && loan?.installmentChargePeriod !== '' ? loan?.installmentChargePeriod?.replace('MONTHLY', 'Månedlig') : '',
                                    !isNaN(loan?.coBorrower) ? Number(loan?.coBorrower) === 0 ? 'Ingen' : formatDebt(loan?.coBorrower, false) : '-',
                                ];

                                // console.log('Forbrukslån Table Data:', tableData);

                                return (
                                    <View key={index}>
                                        <View style={styles.header}>
                                            <Text style={styles.title}>{loan?.financialInstitutionName}</Text>
                                            <Text style={[styles.title, styles.total]}>{formatDebt(loan?.balance)} kr</Text>
                                        </View>
                                        <PDFTable headers={tableHeader} data={[tableData]} style={{ marginBottom: 15 }} />
                                    </View>
                                )
                            })}
                        </View>
                    }

                    {debtData?.loanTypes?.find(({ type }) => type === 'creditFacility')?.loans?.length > 0 &&
                        <View style={styles.section1} wrap={false}>
                            <Text style={styles.secTitle}>Kredittkort</Text>
                            {debtData?.loanTypes?.find(({ type }) => type === 'creditFacility')?.loans?.map((credit, index) => {
                                const table2Header = ['Kredittgrense', 'Rentebærende saldo', 'Ikke rentebærende saldo', 'Nominell rente', 'Gebyr'];
                                const table2Data = [
                                    credit?.creditLimit && credit?.creditLimit !== '' ? `${formatDebt(credit?.creditLimit)} kr` : '0 kr',
                                    credit?.interestBearingBalance && credit?.interestBearingBalance !== '' ? `${formatDebt(credit?.interestBearingBalance)} kr` : '0 kr',
                                    credit?.nonInterestBearingBalance && credit?.nonInterestBearingBalance !== '' ? `${formatDebt(credit?.nonInterestBearingBalance)} kr` : '0 kr',
                                    !isNaN(credit?.nominalInterestRate) ? `${formatDebt(credit?.nominalInterestRate)} %` : '0 %',
                                    !isNaN(credit?.installmentCharges) ? `${formatDebt(credit?.installmentCharges)} kr` : '0 kr',
                                ];

                                // console.log('Kredittkort Table Data:', table2Data);

                                const colWidths = ['15%', '20%', '25%', '20%', '20%'];

                                return (
                                    <View key={index}>
                                        <View style={styles.header}>
                                            <Text style={styles.title}>{credit?.financialInstitutionName}</Text>
                                            <Text style={[styles.title, styles.total]}>{formatDebt(Number(credit?.interestBearingBalance) + Number(credit?.nonInterestBearingBalance))} kr</Text>
                                        </View>
                                        <PDFTable headers={table2Header} data={[table2Data]} style={{ marginBottom: 15 }} columnWidths={colWidths} />
                                    </View>
                                )
                            })}
                        </View>
                    }

                    {debtData?.loanTypes?.find(({ type }) => type === 'chargeCard')?.loans?.length > 0 &&
                        <View style={styles.section1} wrap={false}>
                            <Text style={styles.secTitle}>Faktureringskort</Text>
                            {debtData?.loanTypes?.find(({ type }) => type === 'chargeCard')?.loans?.map((credit, index) => {
                                const tableHeader3 = ['Rentebærende saldo (forfalt)', 'Ikke rentebærende saldo (utestående)'];
                                const tableData3 = [
                                    `${formatDebt(credit?.interestBearingBalance)} kr`,
                                    `${formatDebt(credit?.nonInterestBearingBalance)} kr`,
                                ];

                                const colWidths = ['40%', '60%'];

                                return (
                                    <View key={index}>
                                        <View style={styles.header}>
                                            <Text style={styles.title}>{credit?.financialInstitutionName}</Text>
                                            <Text style={[styles.title, styles.total]}>{formatDebt((isNaN(credit?.interestBearingBalance) ? 0 : Number(credit?.interestBearingBalance)) + (isNaN(credit?.nonInterestBearingBalance) ? 0 : Number(credit?.nonInterestBearingBalance)))} kr</Text>
                                        </View>
                                        <PDFTable headers={tableHeader3} data={[tableData3]} style={{ marginBottom: 15 }} columnWidths={colWidths} />
                                    </View>
                                )
                            })}
                        </View>
                    }

                    <Text style={{ fontSize: 7, marginBottom: 30 }}>
                        Oversikten er hentet fra Gjeldsregisteret.no og inneholder informasjon om alle dine usikrede lån og kreditter. Har du feil eller mangler i informasjonen om dine kreditter, må du selv rette opp i dette med det aktuelle kredittselskapet. Forbrukslån, rammekreditter og faktureringskort innhenter vi fra Gjeldsregisteret.                    </Text>
                </View>
            </InnerPageLayout>
            <InnerPageLayout userData={userData} title={'Tilknyttede selskap'}>
                <View>
                    <View style={[styles.section, styles.rowTwoCol]}>
                        <PageHeader title="Dine selskaper" value={creditData?.companyEngagements?.length} color={'#F2FAF6'} text={'#47B881'} />
                    </View>
                    {creditData?.companyEngagements?.map((company, index) => (
                        <CompanyEngagement key={index} company={company} />
                    ))}
                    <Text style={styles.creditSafeBox}>
                        Denne informasjonen tilbys av{" "}
                        <Link style={styles.link} src={"https://www.creditsafe.com/us/en.html"} target={"_blank"}>
                            Creditsafe
                        </Link>
                    </Text>
                </View>
            </InnerPageLayout>
            {/*<InnerPageLayout title={'Boligverdi'}>
                <View>
                     Add any additional information you need to display
                </View>
            </InnerPageLayout>*/}
        </React.Fragment>
    );
};

export default CreditCheckList;
