export const LoginService = (username, password) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/token`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: username, password })
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else
            reject(resp);
    })
    .then(resolve)
    .catch(reject)
});
export const signicatLogin = () => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/signicat`, {
        method: 'POST',
        mode: 'cors'
    })
    .then(response => {
        if(response.status === 200)
        {
            const { headers } = response;
            const url = headers.get('Responsestring');
            resolve(url);
        }
        else reject(response);
    })
})

export const processSignicatLogin = sessionId => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/signicat/authentication-session?sessionId=${sessionId}`)
    .then(async resp => {
        if(resp.status === 200)
        {
           const user = await resp.json();
           resolve(transformObject(user));
        }
        else reject(resp);
    })
    .catch(reject);
});

export const vippsLogin = () => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/vipps`, {
        method: 'POST',
        mode: 'cors'
    })
    .then(response => {
        if(response.status === 200)
        {
            const { headers } = response;
            const url = headers.get('Responsestring');
            resolve(url);
        }
        else reject(response);
    })
})
export const processVippsLogin = code => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/vipps/authentication-session?code=${code}`)
    .then(async resp => {
        if(resp.status === 200)
        {
           const user = await resp.json();
           resolve(transformObject(user))
        }
        else {
            try{
                const js = await resp.json();
                sendErrorMail({error: 'Response is not 200', js})
                reject(js);

            }
            catch(ex){
                sendErrorMail({error: 'Response is not 200', exception: JSON.stringify(ex)})
                reject(ex);
            }
        }
    })
    .catch(ex => {
        sendErrorMail({error: 'An error occured during the request phase.', ex})
        reject(ex);
    });
})

export const registerUser = user => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/register`, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(user)
    })
    .then(async resp => {
        if(resp.status === 200) {
            const user = await resp.json();
            resolve(transformObject(user))
        }
        else reject(resp)
    })
    .catch(reject)
})

export const sendErrorMail = error => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_IDENTITY}/temp-error-email?errorDetails=${JSON.stringify({errorDetails: JSON.stringify(error)})}`, {
        method: 'GET',
        headers: {
            'content-type': 'application/json'
        }
    })
})

function transformObject(obj){
    let transformedObject = {};
    Object.entries(obj).map(([key, value]) => {
        transformedObject = {...transformedObject, [key?.charAt(0).toLowerCase() + key?.slice(1)]: value}
    })
    return transformedObject;
}