import React, {useEffect, useState} from "react";
import classes from "./index.module.scss";
import data from "./data";
import DetailPopUp from "../Card/Popup";
import CardList from "../Card";
import Button from "../Button";
import CompareSidebar from "./CompareSidebar";
import { getSecuredData } from "Services/api/Loan";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import SortFilter from "./SortFilter";
import { getConsumerReport, getToken } from "../../Services/api/Auth";



const ProductListing = React.forwardRef(({className, products, handleShowMoreClick, loading, sorting, currentSort, handleSort}, ref) => {
    const { settings } = useSelector(state => state?.settings);
    const [user, setUser] = useState({});

    useEffect(() => {
        if(getToken() && getToken() !== ''){
            getConsumerReport()
            .then(setUser)
            .catch(() => {})
        }
    }, []);

    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => {
        setModalShow(false);
    };

    const [selectedDataType, setSelectedDataType] = useState('');
    const [selectedData, setSelectedData] = useState([]);
    const [isCompareSidebarOpen, setIsCompareSidebarOpen] = useState(false);
    const [compareCount, setCompareCount] = useState(0); // Initialize compareCount to 0
    const [showScrollDownText, setShowScrollDownText] = useState(false);

    const handleCompare = (item) => {
        if(selectedData.map(i => JSON.stringify(i)).includes(JSON.stringify(item))) return;
        setSelectedData((prevSelectedData) => [...prevSelectedData, item]);
        setCompareCount(prevCompareCount => prevCompareCount + 1); // Update the compareCount
    };

    const handleOpenCompareSidebar = () => {
        setIsCompareSidebarOpen(true);
    }

    const handleCloseCompareSidebar = () => {
        setIsCompareSidebarOpen(false);
        //setCompareCount(0);
    };

    const handleDelete = (index) => {
        const updatedData = [...selectedData];
        updatedData.splice(index, 1);
        setSelectedData(updatedData);
        setCompareCount(prevCompareCount => prevCompareCount - 1); // Update the compareCount
    };

    const handleShowMoreButtonClick = () => {
        handleShowMoreClick();
       // setShowScrollDownText(true);

    };



    return(
        <React.Fragment>
            <div className={`${classes.contentSection} ${className}`} ref={ref}>
                <SortFilter 
                    sorting={sorting} 
                    currentSorting={currentSort} 
                    handleSort={handleSort}
                />
                <div className={classes.productList}>
                    {products?.map((item, index) => {
                        return <CardList
                            user={user}
                            key={index}
                            item={item}
                            index={index}
                            data={data}
                            setSelectedDataTypeProp={setSelectedDataType}
                            setModalShow={setModalShow}
                            handleCompare={handleCompare}
                        />
                    })}
                </div>
                {products?.length > 0 && <div className={classes.showMore}>
                    {/*{showScrollDownText && <div className={classes.scrollDown}><span>Scroll down within card</span></div>}*/}
                    <Button
                        text={loading ? <><Spinner  size="sm" className={'mx-2'}  />Laster...</> : 'Vis mer'}
                        variant={' '}
                        disabled={loading}
                        icon={loading ? '' : 'far fa-arrow-right'}
                        onClick={loading ? () => {} : handleShowMoreButtonClick}
                    />
                </div>}
            </div>
            {modalShow &&
                <DetailPopUp
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    dataType={selectedDataType}
                    // selectedDataType={selectedDataType}
                    handleClose={handleModalClose}
                />
            }
            {selectedData.length > 0 && (
                <Button onClick={handleOpenCompareSidebar} text={`${settings?.find(s => s?.variableName === 'compareBannerText')?.value} ${compareCount}`}  iconLeft variant={'verticalFixed'} />
            )}
            {isCompareSidebarOpen && (
                <CompareSidebar
                    selectedData={selectedData}
                    onClose={handleCloseCompareSidebar}
                    handleDelete={handleDelete}
                />
            )}
        </React.Fragment>
    )
});

export default ProductListing;