import React from "react";
import classes from "./index.module.scss";
import { Container } from "react-bootstrap";
import SectionTitle from "../SectionTitle";

const StaticTagList = ({ data }) => {
    return (
        <React.Fragment>
            {data.map((item, index) => (
                <section className={'section'} key={index}>
                    <Container>
                        <SectionTitle textCenter className={'pb-0'} description={item.heading.description}>
                            {item.heading.title.map(title => (
                                <span key={title.id} className={title.class}>{title.title} </span>
                            ))}
                        </SectionTitle>
                        <ul className={classes.tags}>
                            {item.tag.map((tag, tagIndex) => (
                                <li key={tagIndex}><a href={tag.url}>{tag.title}</a></li>
                            ))}
                        </ul>
                    </Container>
                </section>
            ))}
        </React.Fragment>
    );
};

export default StaticTagList;
