import img1 from '../../../Images/img42.svg';
import img2 from '../../../Images/img43.svg';
import img3 from '../../../Images/img44.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hvem kan søke om bedriftslån?", class: "" },
            ]
        },
        content: 'Bedriftslån kan søkes av virksomheter som er registrert som ENK, AS, DA, ANS, eller ASA. Søkeren må ha et gyldig norsk personnummer eller D-nummer og tilgang til norsk BankID. Det er også et krav at lånesøkeren er bosatt i Norge.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: false,
        bgColor: '#EBF3FB'

    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Bedrifstlånkostnader?", class: "" },
            ]
        },
        content: 'Vi har verken etableringsgebyr eller innløsningsgebyr, så du betaler kun for det du låner, og kan innfri lånet når som helst uten ekstra kostnad. Start din søknad i dag og styrk din bedrifts økonomi med vår skreddersydde finansiering!',
        btnText: '',
        redirectUrl: "",
        featuredImage: img2,
        horizontal: true
    },
    {
        id: 3,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Hva kreves av sikkerhet?", class: "" },
            ]
        },
        content: 'Vi krever ingen tradisjonell sikkerhet som pant i eiendom eller bedriftens aktiva for våre bedriftslån. I stedet krever vi at du, eller andre relevante personer i bedriften som daglig leder eller styremedlem, stiller som selvskyldnerkausjonist. Dette innebærer at kausjonisten personlig garanterer for tilbakebetaling av lånet. Flere personer kan stille som kausjonister for samme lån. En kredittsjekk vil bli utført for å vurdere lånesøknaden.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img3,
        horizontal: false
    },
];

export default contentWithImgData;
