import React, { useState, useEffect } from 'react';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import ScoreDocument from './ScoreDocument';
import { getCurrentUser, getConsumerReport } from "../../../../Services/api/Auth";

const DownLoadButtonPaymentRemarksPDF = ({ buttonName, fileName }) => {
    const [userData, setUserData] = useState({ firstName: '', lastName: '', userName: '' });
    const [remarks, setRemarks] = useState({});

    useEffect(() => {
        getCurrentUser().then(user => setUserData(user));
        getConsumerReport().then(data => setRemarks(data));
    }, []);

    const totalRemarks = remarks?.paymentRemarkDetails?.remarksOfPayment?.length || 0;
    const totalAmount = remarks?.paymentRemarkDetails?.remarksOfPayment?.reduce((total, remark) => total + parseFloat(remark.amount || 0), 0);

    const downloadPDF = async () => {
        if (userData) {
            const asBlob = await pdf(
                <ScoreDocument
                    userData={userData}
                    remarks={remarks}
                    totalRemarks={totalRemarks}
                    totalAmount={totalAmount}
                />
            ).toBlob();
            saveAs(asBlob, `${fileName}.pdf`);
        }
    };

    return (
        <button onClick={downloadPDF}>{buttonName}</button>
    );
};

export default DownLoadButtonPaymentRemarksPDF;
