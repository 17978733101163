import React, { useEffect, useState } from 'react';
import { PDFViewer, StyleSheet } from '@react-pdf/renderer';
import { Container } from "react-bootstrap";
import ScoreDocument from "./ScoreDocument";
import { getConsumerReport, getCurrentUser } from "../../../../Services/api/Auth";

const PaymentRemarksPDF = () => {
    const [userData, setUserData] = useState({ firstName: '', lastName: '', userName: '' });
    const [remarks, setRemarks] = useState({});

    useEffect(() => {
        getCurrentUser().then(user => setUserData(user));
        getConsumerReport().then(data => setRemarks(data));
    }, []);

    const totalRemarks = remarks?.paymentRemarkDetails?.remarksOfPayment?.length || 0;
    const totalAmount = remarks?.paymentRemarkDetails?.remarksOfPayment?.reduce((total, remark) => total + parseFloat(remark.amount || 0), 0);

    const styles = StyleSheet.create({
        pdfView: {
            width: '100%',
            height: '1200px',
        }
    });

    return (
        <Container>
            <PDFViewer style={styles.pdfView}>
                <ScoreDocument
                    userData={userData}
                    remarks={remarks}
                    totalRemarks={totalRemarks}
                    totalAmount={totalAmount}
                />
            </PDFViewer>
        </Container>
    );
}

export default PaymentRemarksPDF;
