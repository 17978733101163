import React, { Fragment } from 'react'
import { Image, Text, View, Font, Page, Document, StyleSheet } from '@react-pdf/renderer';
import FrontPage from "../PDFComponent/FrontPage";
import AboutEasyFinance from "../PDFComponent/AboutEasyFinance";
import TableOfContent from "../PDFComponent/TableOfContent";
import Identification from "../PDFComponent/Identification";
import ScoreInfo from "../PDFComponent/ScoreInfo";
import ScoreDescription from "../PDFComponent/ScoreDescription";

const ScoreDocument = () => {

    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 40,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column',position: 'relative' },
        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },
        titleContainer: {flexDirection: 'row',marginTop: 24},
        logo: { width: 90 },
    });



    return (
        <Document style={styles.document}>
            <FrontPage title={'Lafacil AS'} ssnNumber={'Org.nr.: 823 302 762'} date={'26.01.2024'} />
            <AboutEasyFinance />
            <TableOfContent />
            <Identification />
            <ScoreInfo />
            <ScoreDescription />
        </Document>
          
    )
}

export default ScoreDocument