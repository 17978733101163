export const subscribeNewsletter = email => new Promise((resolve, reject) =>{
    fetch(`${process.env.REACT_APP_API}/NewsletterSubscription/Post`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, isActive: true })
    })
    .then(resp => {
        if(resp.status === 200)
            return resp.json();
        else reject(resp);
    })
    .then(resolve)
    .catch(reject)
})