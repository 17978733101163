import _ from "lodash"
import { CATEGORIES_LOADED } from "../actions/blogs"

const initialState = {
    categories: []
}

export default function blogsReducer(state = initialState, action){
    const newState = _.cloneDeep(state);
    switch(action.type){
        case CATEGORIES_LOADED:
            newState.categories = action.payload;
            return newState;
        default:
            return newState;
    }
}