import React from "react";
import SectionTitle from "../../Components/SectionTitle";
import Button from "../../Components/Button";
import Thankyou from "../../Images/Thankyou.svg";

function Welcome({ message }){
    return <React.Fragment>
    <SectionTitle textCenter description={message}>
        <span className={'fw-normal'}>Thank you</span>
        <span className={'fw-light'}>for</span>
        <span className={'fw-semibold'}>Register</span>
    </SectionTitle>

    <div className={'p-3'}>
        <div className={'position-relative text-center'}>
            <div className={'text-center mb-5'}>
                <img src={Thankyou} loading="lazy" alt={''} />
            </div>
            <Button
                text={'Go to Login'}
                url={'/login'}
                variant={'darkBorder'}
            />
        </div>
    </div>
</React.Fragment>
}
export default Welcome;