import React from "react";
import classes from './index.module.scss';
import {Col, Container, Row} from "react-bootstrap";
import Button from "../Button";
import { onError } from "Services/api/Image";
import draftToHtml from "draftjs-to-html";
import imgA01 from '../../Images/imgA01.svg';

const ContentWithImage = ({ detail }) => {
    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };



    return(
        <React.Fragment>
            <section className={`section`} style={{backgroundColor: `white`}}>
                <Container>
                    <Row className={`${classes.colList} ${detail?.horizontal ? classes.alignRightImage : ''}`}>
                        <Col md={5}>
                            <div className={classes.imgBox}>
                                <img src={detail?.component?.featuredImage} loading="lazy" onError={onError} alt={"Section Image"} />
                            </div>
                        </Col>
                        <Col md={7}>
                            <h3>{detail?.heading?.title?.map(title => <span key={title.id} className={title.class}>{title.title} </span>)}</h3>
                            <div className={classes.text}>
                                { detail?.subHeading && (<div className={'mb-3'}><b>{ detail?.subHeading }</b></div>)}
                                {/*<div dangerouslySetInnerHTML={{ __html: detail?.heading?.description}} />*/}
                                <p dangerouslySetInnerHTML={{ __html: renderFilteredHTML(detail?.heading?.description) }} />
                            </div>
                            {detail?.component?.linkText &&
                                <Button
                                    variant={'border'}
                                    text={detail?.component?.linkText}
                                    icon={'far fa-arrow-right'}
                                    url={detail?.component?.linkUrl}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}

export default ContentWithImage;
