import React, { useEffect, useMemo, useState } from 'react';
import SectionTitle from '../../../../Components/SectionTitle';
import DataTable from 'react-data-table-component';
import FormInput from '../../../../Components/FormInput';
import { Modal, Spinner, Alert } from 'react-bootstrap';
import Button from "../../../../Components/Button";
import { ensureDefaultCompany, getQredApplications } from '../../../../Services/api/Company';
import moment from 'moment';
import { getConsumerReport } from '../../../../Services/api/Auth';
import { useNavigate } from "react-router-dom";
import CompanyNotExist from "../../DashboardComponent/CompanyNotExist";
import Loading from "../../../../Components/Loading";

const MyLoanApplication = () => {
    const [filterText, setFilterText] = useState('');
    const [loading, setLoading] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState([]);
    const [user, setUser] = useState();
    const [companyEngagements, setCompanyEngagements] = useState([{}]);
    const navigate = useNavigate();
    const formatter = Intl.NumberFormat('no-NO');
    const format = value => isNaN(value) ? 0 : formatter.format(value)

    const filteredData = useMemo(() => {
        return data.filter(item => {
            const { applicationId, organization, createdOn, status, purposeOfLoan, amount } = item;
            const { nationalOrganizationNumber } = organization;
            const searchTerm = filterText.toLowerCase();
            return (
                applicationId?.toLowerCase()?.includes(searchTerm) ||
                nationalOrganizationNumber?.toLowerCase()?.includes(searchTerm) ||
                createdOn?.toLowerCase()?.includes(searchTerm) ||
                status?.toLowerCase()?.includes(searchTerm) ||
                purposeOfLoan?.toLowerCase()?.includes(searchTerm) ||
                amount?.toLowerCase()?.includes(searchTerm)
            );
        });
    }, [data, filterText]);

    useEffect(() => {
        setLoading(true);
        getConsumerReport()
            .then(({ companyEngagements }) => {
                setCompanyEngagements(companyEngagements);
                const user = JSON.parse(localStorage.getItem('auth'));
                setUser(user);
                if (companyEngagements && companyEngagements.length > 0 && !user?.hasDefaultCompany)
                    navigate('/find-your-company')
                getQredApplications()
                    .then(resp => {
                        setData(resp?.data?.map((application) => ({ applicationId: application.applicationId, status: application.applicationStatus, createdOn: application.createdOn, ...JSON.parse(application?.applicationRequest ?? '{}') })) || []);
                })
                .finally(() => setLoading(false))
            })
            .catch(() => {
                setLoading(false)
             })
    }, []);
    const purposes = {
        PurchaseOfEquipment: 'Purchase of equipment',
        Refinancing: 'Refinancing',
        HireEmployees: 'Hire employees',
        Marketing: 'Marketing',
        InvestInInventory: 'Invest in inventory',
        Growth: 'Growth',
        WorkingCapital: 'Working capital',
        Other: 'Other'
    }

    const columns = [
        {
            name: 'Firma navn',
            selector: 'applicationId',
        },
        {
            name: 'Organisasjonsnummer',
            cell: row => row.organization.nationalOrganizationNumber
        },
        {
            name: 'Beløp',
            cell: row => (<>{format(row?.amount)} kr</>)
        },
        {
            name: 'Dato',
            cell: row => moment(row.createdOn).format('DD-MM-YYYY')
        },
        {
            name: 'Status',
            cell: row => (
                <span>{row.status === "REGISTERED" ? <span className={'text-success'}>{row.status}</span> : <span className={'text-danger'}>{row.status}</span>}</span>
            ),
        },
        {
            name: 'Låneformål',
            cell: row => purposes[row.purposeOfLoan],
        },
    ];

    const paginationOptions = {
        rowsPerPageText: 'Rows per page:',
        rangeSeparatorText: 'of',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'All',
    };

    const customStyles = {
        rows: {
            spacing: '20px',
        },
    };

    const handleCloseModal = () => setShowModal(false)

    return (
        <React.Fragment>
            {loading ? <Loading style={{position: 'absolute'}} /> : <>
            {user?.hasDefaultCompany ? <div className={'contentBox'}>
                <h4>Mine lånesøknader</h4>
                {/*  <FormInput
                    type={'search'}
                    placeholder="Firmanavn / firmanummer / safenummer"
                    value={filterText}
                    onChange={handleFilterChange}
                />*/}
                <DataTable
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    persistTableHead
                    customStyles={customStyles}
                />
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>View Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {selectedRowData && (
                            <dl className={'dataList'}>
                                <dt>Ref ID:</dt> <dd>{selectedRowData.refID}</dd>
                                <dt>Org #:</dt> <dd> {selectedRowData.orgNo}</dd>
                                <dt>Amount:</dt> <dd> {selectedRowData.amount}</dd>
                                <dt>Date:</dt> <dd> {selectedRowData.date}</dd>
                                <dt>Status:</dt> <dd> {selectedRowData.status ? 'Active' : 'Inactive'}</dd>
                                {/* Add extra data here */}
                                <dt>Extra Data:</dt> <dd> {selectedRowData.extraData}</dd>
                            </dl>
                        )}
                    </Modal.Body>
                </Modal>
            </div> : !companyEngagements || companyEngagements.length === 0 ? <CompanyNotExist /> : <Spinner animation='border' />}
            </>}
        </React.Fragment>
    );
};

export default MyLoanApplication;
