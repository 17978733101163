import CardList from "Components/Card";
import { getAllAffiliateCards } from "Services/api/AffiliateCards";
import { useEffect } from "react";
import { useState } from "react";

function Affiliate({ detail }){
    const [cards, setCards] = useState([]);
    useEffect(() => {
        getAllAffiliateCards()
        .then(resp => {
            setCards(resp?.filter(r => detail?.cards?.includes(r.id)))
            // setCards(resp);
        })
    }, [])
    return cards?.map((item, index) => {
        const d = JSON.parse(item.detail);
        return <CardList
            key={index}
            item={d}
            index={index}
            // data={data}
            // setSelectedDataTypeProp={setSelectedDataType}
            // setModalShow={setModalShow}
            // handleCompare={handleCompare}
        />
    })
}

export default Affiliate;