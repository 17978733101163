export const getDictionary = (languageId) => new Promise((resolve, reject) => fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetAllDictionary?LanguageId=${languageId}`)
.then(resp => {
    if(resp.status === 200) {
        resolve(resp.json());
    }
    else{
        reject(resp);
    }
})
.catch(err => reject(err))
)