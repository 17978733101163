export const getAllBanks = (language) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/Bank/GetAll?LanguageId=${language}`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json())
        else 
            reject(resp.json())
    })
    .catch(err => reject(err))
})

export const getBankByTitle = (title) => new Promise((resolve, reject) => {
    fetch(`${process.env.REACT_APP_API}/Bank/GetBankByTitle?Title=${title}`)
    .then(resp => {
        if(resp.status === 200)
            resolve(resp.json())
        else
            reject(resp)
    })
    .catch(err => reject(err))
})