import React from 'react';
import InnerPageLayout from "./InnerPageLayout";
import {Page, Text, View, Svg, Line, StyleSheet, Image} from '@react-pdf/renderer';



const TableOfContent = () => {
  const styles = StyleSheet.create({
    contentList:{ fontSize : 9, flexDirection: 'column', width: '100%', color: '#013367'},
    list:{ padding:'7px 10px 17px',flex:1, flexDirection: 'row', width: '100%', justifyContent:'space-between',borderRadius: '5px',marginBottom: '5px'},
    bgGray: {backgroundColor: '#F8F8F7'},
    bgLightYellow: {backgroundColor: '#FEF8F0'},
  });
  return(
      <InnerPageLayout title={'Innholdsfortegnelse'}>
        <View style={styles.contentList}>
          <View style={[styles.list, styles.bgGray]}>
            <Text>Identifikasjon</Text>
            <Text>1</Text>
          </View>
          <View style={[styles.list, styles.bgLightYellow]}>
            <Text>Poengsum (vurdering)</Text>
            <Text>2</Text>
          </View>
          <View style={[styles.list, styles.bgGray]}>
            <Text>Poenghistorie og forklaring av poengsum</Text>
            <Text>3</Text>
          </View>
          <View style={[styles.list, styles.bgLightYellow]}>
            <Text>Om eier og styre</Text>
            <Text>4</Text>
          </View>
          <View style={[styles.list, styles.bgGray]}>
            <Text>Frivillig pantsettelser</Text>
            <Text>5</Text>
          </View>
          <View style={[styles.list, styles.bgLightYellow]}>
            <Text>Regnskap Resultat og tap</Text>
            <Text>6</Text>
          </View>
          <View style={[styles.list, styles.bgGray]}>
            <Text>Balanse</Text>
            <Text>7</Text>
          </View>
          <View style={[styles.list, styles.bgLightYellow]}>
            <Text>Nøkkeltall</Text>
            <Text>8</Text>
          </View>
          <View style={[styles.list, styles.bgGray]}>
            <Text>Annen informasjon</Text>
            <Text>9</Text>
          </View>
        </View>
      </InnerPageLayout>
  )
}

export default TableOfContent;