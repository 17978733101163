import React, { useEffect, useState } from "react";

import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import ElementControl from "Components/ElementControl";
import routes from "./routes";
import { getBySlug } from "Services/api/Page";
import RenderPage from "RenderPage";
import MainLayout from "../Layout/MainLayout";
import { Col, Container, Row } from "react-bootstrap";
import BlogCategories from "../Components/Blogs/BlogCategories";
import ComparisonTabsBox from "../Components/ComparisonTabsBox";
import CompaignBanner from "../Components/CompaignBanner";
import CollectionList from "../Components/Blogs/CollectionList";
import { MetaTags } from "react-meta-tags";
import PrivateRoutes from "./protectedRoutes";

const ClientRoutes = () => {
    const location = useLocation();
    const [pageDetails, setPageDetails] = useState();
    const [notFound, setNotFound] = useState(false);
    const [layout, setLayout] = useState('standard');

    const [metaTags, setMetaTags] = useState([]);
    const [metaDescription, setMetaDescription] = useState('');
    const [pageTitle, setPageTitle] = useState('');
    useEffect(() => {
        getBySlug(location.pathname, 1)
            .then(resp => {
                setMetaTags(resp?.data?.seoTags?.split(','));
                setMetaDescription(resp?.data?.seoDescription);
                setPageTitle(resp?.data?.title);

                const details = JSON.parse(resp?.data?.detail);
                setPageDetails(details);
                setLayout(resp?.data?.layout);
            })
            .catch(err => setNotFound(true))
    }, [location]);
    return !notFound ? (
        <React.Fragment>
            {layout === 'blogs' ? <MainLayout>
                <section className={'section'}>
                    <Container>
                        <Row>
                            <Col lg={9} className={'blogMainPage'}>
                                <RenderPage detail={pageDetails?.filter(d => {
                                    const parsed = JSON.parse(d?.ReactComponentVersion?.VersionDetail ?? '{}');
                                    return !parsed?.useInBlogs
                                })} />
                            </Col>
                            <Col lg={3}>
                                <div className={'blogSideBar'}>
                                    {pageDetails?.map((details, index) => {
                                        const version = details?.ReactComponentVersion;
                                        const parsed = JSON.parse(version?.VersionDetail ?? '{}');
                                        if (parsed?.useInBlogs) {
                                            if (details?.ReactComponent?.Name === 'Blogs collection')
                                                return <CollectionList key={index} categories detail={parsed} />
                                            if (details?.ReactComponent?.Name === 'Comparison Tab')
                                                return <ComparisonTabsBox key={index} sidebar detail={parsed} />
                                            if (details?.ReactComponent?.Name === 'Compaign Banner')
                                                return <CompaignBanner key={index} sidebar detail={parsed} />
                                        }
                                        else return null;
                                    })}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </MainLayout> :
                <MainLayout>
                    <MetaTags>
                        <title>{pageTitle && pageTitle !== '' ? pageTitle : 'Easyfinance'}</title>
                        {metaTags.map((tag, index) => <meta key={index} name="keywords" content={tag} />)}
                        <meta name="keywords" content={metaDescription} />
                    </MetaTags>
                    <RenderPage detail={pageDetails} />
                </MainLayout>}
        </React.Fragment>
    ) :
        <Routes>
            {routes.map((route, index) => {
                const { path, subRoutes, component, layout, isProtected } = route;
                if (subRoutes && subRoutes.length > 0) {
                    return (
                        <Route key={`route_${index}`}>
                            {subRoutes.map((subRoute, subIndex) => {
                                return (
                                    isProtected ? <Route element={<PrivateRoutes />}>
                                        <Route
                                            key={`subroute_${subIndex}`}
                                            exact
                                            path={`${route.path}${subRoute.path}`}
                                            element={<ElementControl Component={subRoute.component} Layout={layout} />}
                                        />
                                    </Route> :
                                        <Route
                                            key={`subroute_${subIndex}`}
                                            exact
                                            path={`${route.path}${subRoute.path}`}
                                            element={<ElementControl Component={subRoute.component} Layout={layout} />}
                                        />
                                );
                            })}
                        </Route>
                    );
                }
                return (
                    isProtected ? <Route element={<PrivateRoutes />}>
                        <Route
                            key={`route_${index}`}
                            exact
                            path={path}
                            element={<ElementControl Component={component} Layout={layout} />}
                        />
                    </Route> :
                        <Route
                            key={`route_${index}`}
                            exact
                            path={path}
                            element={<ElementControl Component={component} Layout={layout} />}
                        />
                );
            })}
        </Routes>
};

export default ClientRoutes;
