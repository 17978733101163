import React, { useEffect, useState } from 'react';
import {Container, Tab, Tabs, Nav, Row, Col} from "react-bootstrap";
import classes from "../index.module.scss";
import FormInput from "../../../../Components/FormInput";
import { Link } from "react-router-dom";
import { getDictionary } from 'Services/api/Dictionary';
import _ from 'lodash';

const FilterDictionary = () => {
    const [dictionaryData, setDictionaryData] = useState();
    const [categories, setCategories] = useState([]);
    const [words, setWords] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All');
    const languageId = 1;
    const [selectedAlphabet, setSelectedAlphabet] = useState('All');
    const [searchKeywords, setSearchKeywords] = useState('');

    const handleAlphabetClick = (char) => {
        setSelectedAlphabet(char);
    };

    const convertToSlug = (text) => {
        return text?.toLowerCase()?.replace(/ /g, '-');
    };

    const loadData = () => {
        getDictionary(languageId)
            .then(resp => {
                const dictionary = JSON.parse(_.first(resp.data).versionDetail);
                setCategories(dictionary.categories);
                setWords(dictionary.words);
                setDictionaryData(dictionary);
            });
    };

    const handleSearch = e => {
        setSearchKeywords(e.target.value);
    };

    useEffect(() => {
        loadData();
    }, []);

    const sortWords = (wordsArray) => {
        return wordsArray.slice().sort((a, b) => a.keyword.localeCompare(b.keyword));
    };

    useEffect(() => {
        const handleFilter = () => {
            if (!dictionaryData) return;
            let filteredWords = _.cloneDeep(dictionaryData.words);
            if (selectedCategory !== 'All') {
                filteredWords = filteredWords.filter(word => word?.category?.includes(selectedCategory));
            }
            if (selectedAlphabet !== 'All') {
                filteredWords = filteredWords.filter(word => word?.keyword?.toLowerCase()?.startsWith(selectedAlphabet?.toLowerCase()));
            }
            if (searchKeywords !== '') {
                filteredWords = filteredWords.filter(word => word?.keyword?.toLowerCase()?.includes(searchKeywords?.toLowerCase()));
            }
            // Sort the filtered words array
            filteredWords = sortWords(filteredWords);
            setWords(filteredWords);
        };
        handleFilter();
    }, [selectedAlphabet, selectedCategory, searchKeywords, dictionaryData]);

    const groupWordsByFirstLetter = () => {
        const groupedWords = {};
        words.forEach(word => {
            const firstLetter = word.keyword.charAt(0).toUpperCase();
            if (!groupedWords[firstLetter]) {
                groupedWords[firstLetter] = [];
            }
            groupedWords[firstLetter].push(word);
        });
        return groupedWords;
    };

    const renderWordList = () => {
        const groupedWords = groupWordsByFirstLetter();

        return Object.entries(groupedWords).map(([letter, wordList], index) => (
            <Col md={3} xs={6} key={index} className={'mb-3'}>
                <h3>{letter}</h3>
                <ul className={classes.dataList}>
                    {wordList.map((item, i) => (
                        <li key={i}>
                            <Link to={`/nyttig-informasjon/ordboken/${convertToSlug(item.title)}`}>
                                {item.keyword}
                            </Link>
                        </li>
                    ))}
                </ul>
            </Col>
        ));
    };

    const handleResetFilters = () => {
        setSelectedAlphabet('All');
        // Add any other reset logic for filters here if needed
    };

    return (
        <React.Fragment>
            <section>
                <Container>
                    <div className={classes.searchBox}>
                        <FormInput
                            type={'search'}
                            placeholder={'Hva lurer du på?'}
                            //fieldIcon={'fal fa-search'}
                            onChange={handleSearch}
                        />
                    </div>
                    <div className={classes.pageSection}>
                        <Tab.Container defaultActiveKey="All">
                            <Nav variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="All" onClick={() => setSelectedCategory('All')}>Alt</Nav.Link>
                                </Nav.Item>
                                {categories.map((category, index) => (
                                    <Nav.Item key={index}>
                                        <Nav.Link eventKey={category.title} onClick={() => setSelectedCategory(category.title)}>{category.title}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                            <ul className={classes.filterList}>
                                <li>
                                    <span
                                        className={selectedAlphabet === 'All' ? classes.active : ''}
                                        onClick={() => handleResetFilters()}
                                    >
                                        Alt
                                    </span>
                                </li>
                                {Array.from(Array(26), (_, i) => String.fromCharCode(65 + i)).map((char) => {
                                    const isClickable = dictionaryData?.words?.some(item => item.keyword.charAt(0).toUpperCase() === char.toUpperCase() &&
                                        (selectedCategory === 'All' || item.category.includes(selectedCategory)));
                                    return (
                                        <li key={char}>
                                            <span
                                                className={ isClickable ? selectedAlphabet.toUpperCase() === char.toUpperCase() ? classes.active : '' : classes.disable }
                                                onClick={() => isClickable && handleAlphabetClick(char)}
                                            >
                                                {char}
                                            </span>
                                        </li>
                                    );
                                })}
                            </ul>

                            <Tab.Content>
                                <Tab.Pane eventKey={selectedCategory}>
                                    <Row>
                                        {renderWordList()}
                                    </Row>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
};

export default FilterDictionary;
