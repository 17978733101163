import React from 'react';
import classes from '../../index.module.scss';
import draftToHtml from 'draftjs-to-html';

const Text = ({ detail }) => {
    const renderFilteredHTML = (content) => {
        // Convert Draft.js content to HTML
        const htmlContent = draftToHtml(content);
        // Apply filtering: remove empty tags and styles
        const filteredHTML = htmlContent
            .replace(/<[^\/>][^>]*><\/[^>]+>/g, '') // Remove empty tags
            .replace(/style="[^"]*"/g, ''); // Remove inline styles
        return filteredHTML;
    };
    const __html = renderFilteredHTML(detail?.editor);

  return(
      <React.Fragment>
        <h1 id={detail?.heading?.toLowerCase()?.replaceAll(' ', '-')}>{detail?.heading}</h1>
        <p>{detail?.description}</p>
              <div className={`${classes.editorText}`} dangerouslySetInnerHTML={{__html}} />
      </React.Fragment>
  )
}

export default Text;