import React, { useState, useEffect }  from "react";
import classes from "./index.module.scss";
import SocialIcon from "./SocialIcons/SocialIcon";
import { loadSettings } from "Services/api/SettingsService";
import { useSelector } from "react-redux";


const icons = {
    linkedIn: 'linkedin-in',
    tikTok: 'tiktok',
    facebook: 'facebook-f',
    youTube: 'youtube',
    twitter: 'twitter',
    instagram: 'instagram',
}

const SocialLinks=({ detail, filled })=>{
    
    // const [settings, setSettings] = useState();
    const { settings } = useSelector(state => state.components);
    const languageId = 1;
    // useEffect(() => {
    //     if(components.length > 0){
    //         loadSettings(languageId, components)
    //         .then(setSettings);
    //     }
    // }, [components]);
    return(
        <ul className={`${classes.socialLinks} ${filled ? `${classes.filled}` : ""}`}>
            <li className="px-2">{detail?.component?.followUsText}</li>
            {/* {detail?.component?.links?.map((link, index) => <SocialIcon  key={index} url={settings?.social[link?.toLowerCase()]} icon={`fab fa-${link?.toLowerCase()}`} />)} */}
            {Object.entries(settings?.social ?? '{}').map(([key, value], index) => {
                if(value && value !== '')
                    return <SocialIcon  key={index} url={value} icon={`fab fa-${key?.toLowerCase()}`} />
                else return;
            })}
        </ul>

    )
}

export default SocialLinks;
