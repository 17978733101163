import React, { useEffect, useState } from "react";
import classes from "./index.module.scss";
import {Container, Nav} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Logo from "../Logo";
import {NavLink} from "react-router-dom";
import Navigation from "../Navigation";
import SubHeader from "./SubHeader";
import { getConsumerReport } from "../../Services/api/Auth";


const UserDashboardHeader = ({hideBottomHeader, subHeader}) => {

    const [subHeadersData, setSubheaderData] = useState([
        { url: '/privatdashbord', text: 'Privat', icon: 'fas fa-user' },
        { url: '/bedriftsdashbord', text: 'Bedrift', icon: 'fas fa-briefcase' },
        // Add more instances as needed
    ]);

    useEffect(() => {
        const auth = localStorage.getItem('auth');
        try{
            getConsumerReport()
            .then(({ companyEngagements }) => {
                const parsed = JSON.parse(auth);
                if(companyEngagements && companyEngagements.length > 0 && String(parsed?.HasDefaultCompany ?? parsed?.hasDefaultCompany)?.toLowerCase() === 'false'){
                    setSubheaderData([
                        { url: '/privatdashbord', text: 'Privat', icon: 'fas fa-user' },
                        { url: '/find-your-company', text: 'Bedrift', icon: 'fas fa-briefcase' },
                    ])
                }
            })
        }
        catch(ex){

        }
    }, []);

    return (
        <header id={classes.header} className={classes.userDashboardHeader}>
            <Container>
                <Logo isBusinessPage={window.location.pathname.includes('bedrift')}/>
                <Navigation userDashboardNav />
            </Container>
            {hideBottomHeader  ? '' :
                <SubHeader data={subHeadersData} />
            }



        </header>
    );
};

export default UserDashboardHeader;
