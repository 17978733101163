import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import Highcharts3D from 'highcharts/highcharts-3d';
import HCMore from 'highcharts/highcharts-more';
import data from './data.json';
import classes from "../index.module.scss";

// Initialize Highcharts modules
Highcharts3D(Highcharts);
HCMore(Highcharts);

const InterestRate = ({ debt }) => {
    const [averageInterestRate, setAverageInterestRate] = useState('');
    const chartRef = useRef(null);

    useEffect(() => {
        const loans= debt?.loanTypes?.flatMap(({ loans }) => loans);
        const interestRates = loans?.map(({ nominalInterestRate }) => nominalInterestRate);
        const average = ((interestRates?.reduce((a, b) => (!isNaN(Number(a)) ? Number(a) : 0) + (!isNaN(Number(b)) ? Number(b) : 0)) / interestRates?.length) / 100).toFixed(2);
        setAverageInterestRate(average);
        const chartOptions = {
            chart: {
                type: 'column',
                renderTo: chartRef.current,
                backgroundColor: null,
                options3d: {
                    enabled: true,
                    alpha: 21,
                    beta: -27,
                    depth: 60,
                    viewDistance: 0,
                },
            },
            xAxis: {
                visible: false, // Hide x-axis
            },
            yAxis: {
                visible: false, // Hide y-axis
            },
            tooltip: {
                headerFormat: '<b></b><br>',
                pointFormat: `<b>{point.series.name}</b>: {point.y}%`,
            },
            title: {
                text: '',
                align: 'left',
            },
            subtitle: {
                text: '',
                align: 'left',
            },
            legend: {
                enabled: false,
            },
            plotOptions: {
                column: {
                    depth: 60,
                    colorByPoint: true,
                    dataLabels: {
                        enabled: true,
                        format: '{y}%',
                        style: {
                            textOutline: false,
                            color: '#2c3548',
                            fontSize: '28px',
                            align: 'right',
                            opacity: 1,
                            fontWeight: 'normal'
                        },
                       rotation: -10, // Apply rotation to the data labels
                        y: -30,
                        x: -10,
                    },
                    // borderWidth: 1, // Set border width for columns
                    // borderStyle: 'solid',
                    // borderColor: '#000',
                },
            },
            series: [
                {
                    name: data.interestRateYouShouldHaveTitle,
                    data: [{
                        y: data.interestRateYouShouldHave,
                        color: '#92CE16',
                        value: data.interestRateYouShouldHave, // Add value property
                    }],
                },
                {
                    name: data.interestRateCurrentTitle,
                    data: [{
                        y: !isNaN(Number(average)) ? Number(average) : 0,
                        color: '#4F2DFF',
                        value: !isNaN(Number(average)) ? Number(average) : 0,
                    }],
                },
            ],

        };

        if (chartRef && chartRef.current) {
            new Highcharts.Chart(chartOptions);
        }
    }, [debt]);

    const legendItems = [
        { name: data.interestRateYouShouldHaveTitle, color: '#92CE16', textColor: "#6a9a07", value: data.interestRateYouShouldHave },
        { name: data.interestRateCurrentTitle, color: '#4F2DFF', textColor: "#371dbd", value: averageInterestRate },
    ];

    const RenderLegend = () => {
        return (
            <ul className={`${classes.legendList} mb-3`}>
                {legendItems.map((item, index) => (
                    <li key={index} style={{ '--background': item.color }}>
                        <span>{item.name}</span>
                        <h4 className={'text-center pt-2'} style={{ '--background': item.textColor }}>{item.value}%</h4>
                    </li>
                ))}
            </ul>
        );
    };


    return (
        <div>
            <div ref={chartRef} id="container" style={{ width: '100%', height: '400px' }}></div>
            {RenderLegend()}
            <p className={'font-12'} style={{lineHeight: '16px'}}>Med en gjennomsnittsrente på {averageInterestRate}% på dine nåværende lån, er det stor sannsynlighet for at du kan oppnå betydelige besparelser. Hvorfor betale mer enn nødvendig? Utforsk helt uforpliktende og kostnadsfritt hvilke gunstigere rentevilkår du kan sikre deg. Ta kontroll over din økonomi i dag – sjekk ut dine muligheter!</p>

        </div>
    );
};

export default InterestRate;
