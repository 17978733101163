import React from "react";
import SectionTitle from "../../SectionTitle";
import classes from "../index.module.scss";
import { Link } from "react-router-dom";
import Button from "../../Button";
import { useState } from "react";
import { useEffect } from "react";
import { getOtherBlogs } from "Services/api/Blog";
import { Container } from "react-bootstrap";

const OtherBlogs = ({ detail }) => {
    const [otherBlogs, setOtherBlogs] = useState([]);
    const languageId = 1;
    const count = 3;

    useEffect(() => {
        if(!detail?.count || detail?.count === '') return;
        getOtherBlogs(languageId, detail?.count)
        .then(resp => setOtherBlogs(resp.data));
    }, [detail])


    return (
        <React.Fragment>
            <div className={classes.blogSection}>
                <Container>
                    <SectionTitle className={'pb-0'}>
                        {detail?.heading?.title?.map(title => <span key={title.id} className={title.class}>{title.title}</span>)}
                    </SectionTitle>
                    <div className={classes.colPost}>
                        {otherBlogs.map((item, index) => {
                            const details = JSON.parse(item?.postContents);
                            return <div className={classes.col} key={index}>
                                <div className={classes.box}>
                                    <a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`} className={classes.imgBox} style={{ backgroundImage: `url(${details?.image})` }}></a>
                                    <div className={classes.description}>
                                        <h4 className={classes.title}><a href={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}>{item?.title}</a></h4>
                                        <p>{item.summary}</p>
                                        <Button
                                            url={`/blogger/${encodeURIComponent(item?.title?.toLowerCase()?.replaceAll(' ', '-'))}`}
                                            icon={'far fa-arrow-right'}
                                            text={'Les mer'}
                                            variant={'link'}
                                        />
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                    {Number(detail?.count) <= 15 && <div className={'pt-3 text-center'}>
                        <Button
                            url={detail?.buttonUrl}
                            text={detail?.buttonText}
                            icon={'far fa-arrow-right'}
                        />
                    </div>}
                </Container>
            </div>
        </React.Fragment>
    )
}

export default OtherBlogs;
