import React, { useState } from 'react';
import classes from './index.module.scss';
import { Modal} from 'react-bootstrap';
import Logo from "../../../Logo";
import DownLoadButtonCreditCheckPDF from "../CreditCheckPDF/DownLoadButtonCreditCheckPDF";
import DownLoadButtonDebtCheckPDF from "../DebtCheckPDF/DownLoadButtonDebtCheckPDF";
import DownLoadButtonPaymentRemarksPDF from "../PaymentRemarksPDF/DownLoadButtonPaymentRemarksPDF";
import Button from "../../../Button";


const PDFDownLoadButton = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  return(
      <React.Fragment>
       <div className={'text-right mb-3'}>
         <Button text={'Last ned PDF'} onClick={handleShow} variant={'link underline'} />
       </div>
        <Modal
            show={show}
            onHide={handleClose}
            keyboard={false}
            centered
            className={classes.pdfDownloadPopup}
        >
          <Modal.Body className={'text-center'}>
            <Button variant="circle light closeBtn" onClick={handleClose} icon={'fas fa-times'} />
            <Logo />
            <p className={'font-14'}>Last ned innsynsrapporter</p>
            <div className={classes.btnBox}>
              <DownLoadButtonCreditCheckPDF buttonName={'Kredittsjekk'} fileName={'kredittsjekk'} />
              <DownLoadButtonDebtCheckPDF buttonName={'Min gjeld'} fileName={'min_gjeld'} />
              <DownLoadButtonPaymentRemarksPDF buttonName={'Betalingsanmerkninger'} fileName={'betalingsanmerkninger'} />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
  )
}

export default PDFDownLoadButton;