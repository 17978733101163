import moment from 'moment';
import React, { useEffect, useState } from 'react';
import {Table} from "react-bootstrap";

const KeyRatios = ({ details }) => {
    const formatter = Intl.NumberFormat('no-NO');
    const format = value => isNaN(value) ? 0 : formatter.format(value)
    const [dates, setDates] = useState([]);
    const [pretaxProfitMargin, setPretaxProfitMargin] = useState([]);
    const [returnOnCapital, setReturnOnCapital] = useState([]);
    const [returnOnTotalAssets, setReturnOnTotalAssets] = useState([]);
    const [returnOnNetAssets, setReturnOnNetAssets] = useState([]);
    const [netWorkingCapital, setNetWorkingCapital] = useState([]);
    const [stockTurnOnver, setStockTurnOver] = useState([]);

    const [debtorDays, setDebtorDays] = useState([]);
    const [creditorDays, setCreditorDays] = useState([]);
    const [currentRatio, setCurrentRatio] = useState([]);
    const [liquidityRadio, setLiquidityRatio] = useState([]);
    const [currentDebtRatio, setCurrentDebtRatio] = useState([]);
    const [equity, setEquity] = useState([]);
    const [totalDebtRatio, setTotalDebtRatio] = useState([]);

    useEffect(() => {
        setDates(details?.localFinancialStatements?.map(fs => moment(fs?.yearEndDate).format('DD/MM/YYYY')));
        setPretaxProfitMargin(details?.localFinancialStatements?.map(fs => fs?.ratios?.preTaxProfitMargin));
        setReturnOnCapital(details?.localFinancialStatements?.map(fs => fs?.ratios?.returnOnCapitalEmployed));
        setReturnOnTotalAssets(details?.localFinancialStatements?.map(fs => fs?.ratios?.returnOnTotalAssetsEmployed));
        setReturnOnNetAssets(details?.localFinancialStatements?.map(fs => fs?.ratios?.returnOnNetAssetsEmployed));
        setNetWorkingCapital(details?.localFinancialStatements?.map(fs => fs?.ratios?.salesOrNetWorkingCapital));
        setStockTurnOver(details?.localFinancialStatements?.map(fs => fs?.ratios?.stockTurnoverRatio));
        setDebtorDays(details?.localFinancialStatements?.map(fs => fs?.ratios?.debtorDays));
        setCreditorDays(details?.financialStatements?.map(fs => fs?.ratios?.creditorDays));
        setCurrentRatio(details?.localFinancialStatements?.map(fs => fs?.ratios?.currentRatio));
        setLiquidityRatio(details?.localFinancialStatements?.map(fs => fs?.ratios?.liquidityRatioOrAcidTest));
        setCurrentDebtRatio(details?.localFinancialStatements?.map(fs => fs?.ratios?.currentDebtRatio));
        setEquity(details?.localFinancialStatements?.map(fs => fs?.ratios?.equityInPercentage));
        setTotalDebtRatio(details?.localFinancialStatements?.map(fs => fs?.ratios?.totalDebtRatio));
    }, [details]);
    const getPercentage = (number, array, index, attachSymbol = false) => {
        if (index < 0 || index >= array.length - 1) {
            return null; // Index out of range or no next index
        }
    
        const currentValue = Math.abs(array[index]);
        const nextValue = Math.abs(array[index + 1]);
    
        let percentageValue = ((currentValue - nextValue) / nextValue) * 100;
        // const percentageValue = ((array[index - 1] - number) / number) * 100;
        if(isNaN(percentageValue) || percentageValue === 0 || percentageValue === Infinity || percentageValue === -Infinity)
        return '-'
        percentageValue = percentageValue.toFixed(1);
        if(Math.abs(percentageValue) > 1000) percentageValue = (percentageValue / 1000).toFixed(1) + (attachSymbol ? 'K' : 0)
        return attachSymbol ? percentageValue + ' %' : percentageValue;
    }
    return(
        <React.Fragment>
            <h5>Nøkkeltall</h5>
            <div className={'mb-5 table-responsive'}>
                <Table className={'border'} style={{minWidth: "850px"}}>
                    <thead>
                    <tr>
                        <th>Navn</th>
                        {dates?.map((date, index) => (
                            <React.Fragment key={index}>
                                <th colSpan={1}>{date}</th>
                                {index !== dates?.length - 1 && <th colSpan={1}></th>}
                            </React.Fragment>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <th>Resultatgrad</th>
                        {pretaxProfitMargin?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < pretaxProfitMargin?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, pretaxProfitMargin, index)) ? '' : getPercentage(number, pretaxProfitMargin, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, pretaxProfitMargin, index, true)}<i className={isNaN(getPercentage(number, pretaxProfitMargin, index)) ? '' : getPercentage(number, pretaxProfitMargin, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Kapitalavkastning</th>
                        {returnOnCapital?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < returnOnCapital?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, returnOnCapital, index)) ? '' : getPercentage(number, returnOnCapital, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, returnOnCapital, index, true)}<i className={isNaN(getPercentage(number, returnOnCapital, index)) ? '' : getPercentage(number, returnOnCapital, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Avkastning på totalkapitalen</th>
                        {returnOnTotalAssets?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < returnOnTotalAssets?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, returnOnTotalAssets, index)) ? '' : getPercentage(number, returnOnTotalAssets, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, returnOnTotalAssets, index, true)}<i className={isNaN(getPercentage(number, returnOnTotalAssets, index)) ? '' : getPercentage(number, returnOnTotalAssets, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Totalkapitalens rentabilitet</th>
                        {returnOnNetAssets?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < returnOnNetAssets?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, returnOnNetAssets, index)) ? '' : getPercentage(number, returnOnNetAssets, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, returnOnNetAssets, index, true)}<i className={isNaN(getPercentage(number, returnOnNetAssets, index)) ? '' : getPercentage(number, returnOnNetAssets, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Netto arbeidskapital</th>
                        {netWorkingCapital?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < netWorkingCapital?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, netWorkingCapital, index)) ? '' : getPercentage(number, netWorkingCapital, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, netWorkingCapital, index, true)}<i className={isNaN(getPercentage(number, netWorkingCapital, index)) ? '' : getPercentage(number, netWorkingCapital, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Varelagerets omløpshastighet</th>
                        {stockTurnOnver?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < stockTurnOnver?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, stockTurnOnver, index)) ? '' : getPercentage(number, stockTurnOnver, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, stockTurnOnver, index, true)}<i className={isNaN(getPercentage(number, stockTurnOnver, index)) ? '' : getPercentage(number, stockTurnOnver, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>DSO/Gjennomsnittlig dager utestående</th>
                        {debtorDays?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < debtorDays?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, debtorDays, index)) ? '' : getPercentage(number, debtorDays, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, debtorDays, index, true)}<i className={isNaN(getPercentage(number, debtorDays, index)) ? '' : getPercentage(number, debtorDays, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Kreditt-tid leverandører</th>
                        {creditorDays?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < creditorDays?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, creditorDays, index)) ? '' : getPercentage(number, creditorDays, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, creditorDays, index, true)}<i className={isNaN(getPercentage(number, creditorDays, index)) ? '' : getPercentage(number, creditorDays, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Likviditetsgrad 1</th>
                        {currentRatio?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < currentRatio?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, currentRatio, index)) ? '' : getPercentage(number, currentRatio, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, currentRatio, index, true)}<i className={isNaN(getPercentage(number, currentRatio, index)) ? '' : getPercentage(number, currentRatio, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Likviditetsgrad 2</th>
                        {liquidityRadio?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < liquidityRadio?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, liquidityRadio, index)) ? '' : getPercentage(number, liquidityRadio, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, liquidityRadio, index, true)}<i className={isNaN(getPercentage(number, liquidityRadio, index)) ? '' : getPercentage(number, liquidityRadio, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Gjeldsgrad</th>
                        {currentDebtRatio?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < currentDebtRatio?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, currentDebtRatio, index)) ? '' : getPercentage(number, currentDebtRatio, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, currentDebtRatio, index, true)}<i className={isNaN(getPercentage(number, currentDebtRatio, index)) ? '' : getPercentage(number, currentDebtRatio, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Egenkapitalandel</th>
                        {equity?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < equity?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, equity, index)) ? '' : getPercentage(number, equity, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, equity, index, true)}<i className={isNaN(getPercentage(number, equity, index)) ? '' : getPercentage(number, equity, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    <tr>
                        <th>Gjeldsgrad</th>
                        {totalDebtRatio?.map((number, index) => (
                            <React.Fragment key={index}>
                                <td key={`${index}-number`} colSpan={1}>
                                    {format(number) ?? '-'}
                                </td>
                                {index < totalDebtRatio?.length - 1 && (
                                    <td key={`${index}-percentage`} colSpan={1}>
                                        {/* Calculate and display percentage between current and next number */}
                                        <span className={isNaN(getPercentage(number, totalDebtRatio, index)) ? '' : getPercentage(number, totalDebtRatio, index) > 0 ? 'text-success' : 'text-danger'}>{getPercentage(number, totalDebtRatio, index, true)}<i className={isNaN(getPercentage(number, totalDebtRatio, index)) ? '' : getPercentage(number, totalDebtRatio, index) > 0 ? 'fa fa-caret-up' : 'fa fa-caret-down'}></i></span>
                                    </td>
                                )}
                            </React.Fragment>
                        ))}
                    </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    )
}

export default KeyRatios;