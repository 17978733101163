export const getActiveFooter = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetAllNavigational`)
        .then(resp => resp.json())
        .then(({data}) => {
            const activeFooter = data?.filter(footer => {
                const parsed = JSON.parse(footer.versionDetail);
                return parsed.type === 'footer' && footer.publish;
            }).map(footer => JSON.parse(footer.versionDetail))
            resolve(activeFooter[0]);
        })
        .catch(err => reject(err))
    })
}
export const getActiveHeader = () => {
    return new Promise((resolve, reject) => {
        fetch(`${process.env.REACT_APP_API}/ReactComponentVersion/GetAllNavigational`)
        .then(resp => resp.json())
        .then(({data}) => {
            const activeHeader = data?.filter(header => {
                const parsed = JSON.parse(header.versionDetail);
                return parsed.type === 'header' && header.publish;
            }).map(header => JSON.parse(header.versionDetail))
            resolve(activeHeader[0]);
        })
        .catch(err => reject(err))
    })
}