import unofinans from "../../../Images/unofinans.svg";
import axo from "../../../Images/logo3.svg";
import lendoLogo from "../../../Images/lendoLogo.svg";
import tfBank from '../../../Images/tf-bank-mastercard.png';
import remember from '../../../Images/remember.jpg';
import morrowBank from '../../../Images/morrowbank_mastercard1.svg';
import instaBank from '../../../Images/instabank_instapay.png';
import bankNorwegian from '../../../Images/banknorwegian.svg';
import creditcardcollection from '../../../Images/creditcardcollection.svg';
import spareBank from "../../../Images/SpareBank.svg";
import nordea from "../../../Images/nordeaLogo.svg";

const data = [
    {
        dataType: 'Forbrukslån',
        averageRent: "9,59",
        defaultActive: true,
        childData: [
            {
                name: "",
                image: unofinans,
                leverandorTekst: 'EasyFinance samarbeider med Uno Finans for at våre medlemmer skal få de beste lånevilkårene. Unofinans tilbyr en svært enkel og brukervennlig lånesøknadsprosess, hvor du med bare én søknad kan motta flere tilbud.',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '7.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '9.59%',
                featured: true,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 12.71% ved lån på 150 000 kr over 5 år. Totalt: kr 199 237.',
                btnUrl: "https://ormedbyte.com/click.track?CID=430720&AFID=529108",
            },
            {
                name: "",
                image: axo,
                leverandorTekst: 'Vi anbefaler Axo Finans for dine lånebehov. Gjennom Axo Finans kan du søke lån hos 16 banker samtidig og velge det beste tilbudet. Dette er gratis og uforpliktende, og du får raskt svar. Med Axo Finans kan du også spare penger ved refinansiering.Spar tid og penger med Axo Finans!',
                rating: '4.5',
                loanAmount:'10 000 kr - 800 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '10.30%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 13,33% ved lån på 150 000 kr over 5 år. Totalt: kr 202 496.',
                btnUrl: "https://go.axofinans.no/aff_c?offer_id=834&aff_id=1527",
            },
            {
                name: "",
                image: lendoLogo,
                leverandorTekst: "Vi anbefaler Lendo fordi de gjør det enkelt å finne det beste lånet. Med Lendo kan du søke én gang og få oversikt over lånebeløp og renter fra flere banker. Velg det lånet som passer best for deg.",
                rating: '4.5',
                loanAmount:'10 000 kr - 600 000 kr',
                pricePerMonths: 'NOK 16,607',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                effectiveInterestRate: '4.55%',
                maxiRepaymentPeriod: '30 years',
                nominalInterestRate: '11.60%',
                featured: false,
                affiliateType: "Annonse",
                loanExample: 'Låneeksempel: Effektiv rente 12,73% ved lån på 130 000 kr over 5 år. Totalt: kr 173 382.',
                btnUrl: "https://track.adtraction.com/t/t?a=1081817788&as=1746558926&t=2&tk=1",

            },
        ]
    },
]

export default data;