import React from 'react';
import classes from "./index.module.scss";

const TwoColListWithCheckList = ({ detail }) => {
  return(
      <React.Fragment>
          <div className={`${classes.colPost}`}>
              {detail.map((item, index) => (
                  <div className={classes.col} key={index}>
                      <div className={classes.box}>
                          <div className={classes.description}>
                              <h4 className={classes.title}>{item.title}</h4>
                              <ul>
                                  {item.content.map((content, i) => (
                                    <li key={i}>{content}</li>
                                  ))}
                              </ul>
                          </div>
                      </div>
                  </div>
              ))}
          </div>
      </React.Fragment>
  )
}

export default TwoColListWithCheckList;