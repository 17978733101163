import React from "react";
import classes from "./index.module.scss";

const SectionTitle = ({ children, textCenter, textRight, textLeft, description, className }) => {
    return (
        <React.Fragment>
            <div className={`
                ${classes.secHeader} 
                ${textCenter ? "text-center" : ""}
                ${textRight ? "text-right" : ""}
                ${textLeft ? "text-left" : ""}
                ${className ? `${className}` : ''}
                
            `}>
                {children && <h2 className={`${description || children ? 'mb-2' : 'mb-0'}`}>{children}</h2>}
                {description && <p>{description}</p>}
            </div>
        </React.Fragment>

    );
};

export default SectionTitle;