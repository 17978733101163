import img1 from '../../../Images/img70.svg';
import img2 from '../../../Images/img69.svg';

const contentWithImgData = [
    {
        id: 1,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Maksimer mulighetene med riktig informasjon", class: "" },
            ]
        },
        content: 'Når vi vurderer kundenes kredittverdighet, streber vi etter å være så nøyaktige som mulig. Men av og til kan vurderingene våre være feil. Vi bruker data fra Creditsafe, og vårt mål er å tilby tilgang til informasjon til rimelige priser, slik at bedrifter kan ta informerte beslutninger.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img1,
        horizontal: true,
        bgColor: '#EBF3FB',
        dataList: [
            {title: "Likviditetsutfordringer:", description: "Opptil 40 prosent av norske bedrifter, inkludert små og mellomstore selskaper, står overfor likviditetsproblemer. Ved å prioritere kredittsjekk, kan disse selskapene redusere risikoen for tap og opprettholde sunn økonomisk drift."},
            {title: "Overlevelsesrisiko for små bedrifter:", description: "Opptil 30 prosent av småbedrifter risikerer overlevelse på grunn av manglende betaling fra kunder. Ved å bruke kredittsjekk, kan disse bedriftene redusere risikoen for tap og sikre langsiktig bærekraft."},
            {title: "Kontroll på rollehavere:", description: "Kjennskap til beslutningstakere i bedriften og deres roller er avgjørende for å ta informerte beslutninger. Kredittopplysninger som inkluderer informasjon om rollehavere, gir små og mellomstore bedrifter muligheten til å bedre forstå og håndtere risikoen i forbindelse med sine forretningsforbindelser."},
        ]
    },
    {
        id: 2,
        heading: {
            description: "",
            title: [
                { id: 1, title: "Er kredittvurderingen alltid riktig?", class: "" },
            ]
        },
        content: 'Når vi vurderer kundenes kredittverdighet, streber vi etter å være så nøyaktige som mulig. Men av og til kan vurderingene våre være feil. Vi bruker data fra Creditsafe, og vårt mål er å tilby tilgang til informasjon til rimelige priser, slik at bedrifter kan ta informerte beslutninger.',
        btnText: '',
        redirectUrl: "",
        featuredImage: img2,
        horizontal: false,
        bgColor: '#f9fafc',
        dataList: [
            {
                title: "",
                description: "",
            },
        ]
    },
];

export default contentWithImgData;
