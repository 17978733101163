import { combineReducers } from "redux";
import componentReducer from "./componentReducer";
import componentVersionReducer from "./componentVersionReducer";
import blogsReducer from "./blogsReducer";
import banksReducer from "./banksReducer";
import appSettingsReducer from "./appSettings";

const rootReducer = combineReducers({
    components: componentReducer,
    versions: componentVersionReducer,
    blogs: blogsReducer,
    banks: banksReducer,
    settings: appSettingsReducer
})

export default rootReducer;