const bannerData =  [
    {
      id: 1,
      subTitle: "",
      title: [
        { id: 1, title: "Trenger din", class: "fw-normal" },
        { id: 2, title: "bedrift et løft?", class: "fw-bold" }
      ],
      description:
          <>
            <ul className={'checkList font-18 circle'}>
              <li>Invester i nytt utstyr</li>
              <li>Utvid lokalene dine</li>
              <li>Styrk daglig drift</li>
              <li>Øk varelageret</li>
            </ul>
          </>
    }
  ]


export default bannerData;
