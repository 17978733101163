import React from 'react';
import SectionTitle from "../../../../Components/SectionTitle";
import classes from './index.module.scss';
import data from "./data.js";


const HouseValueRecommendations = () => {

  return(
      <React.Fragment>
          <section className={classes.section}>
              <SectionTitle textCenter>
                  <span className={'fw-normal'}>Få mest mulig </span>
                  <span className={'fw-semibold'}>ut av boligverdien din</span>
              </SectionTitle>
              <div className={classes.recomendedCards}>
                  {data.map((item) => (
                      <div key={item.id} className={classes.col}>
                          <a href={item.url} className={classes.box}>
                              <div className={classes.imgBox}>
                                  <img src={item.imgSrc} loading="lazy" alt={item.title} />
                              </div>
                              <h4>{item.title}</h4>
                              <p>{item.description}</p>
                          </a>
                      </div>
                  ))}
              </div>
          </section>
      </React.Fragment>
  )
}

export default HouseValueRecommendations;