import React from 'react';
import {useLocation} from "react-router-dom";
import BusinesStaticPageBanner from "../../../Components/Banner/BusinesStaticPageBanner";
import StaticAdSectionRightImg from "../../../Components/GetStarted/StaticAdSectionRightImg";
import adSectionRightImgData from './adSectionRightImgData.js';
import contentWithImgData from "./ContentWithImgData";
import StaticContentWithImg from "../../../Components/ContentWithImage/StaticContentWithImg";
import bannerData from "./bannerData";
import faqData from "../faqData";
import FAQs from "../../../Components/FAQs";
import tagList from "../tagList";
import StaticTagList from "../../../Components/TagList/StaticTagList";

const FinanceGuide = () => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = decodedPathname === '/bedrift/finansieringsguiden';

    return(
        <React.Fragment>
            <BusinesStaticPageBanner data={bannerData} isBusiness={isBusiness} />
            <StaticAdSectionRightImg detail={adSectionRightImgData[0]} />
            <StaticContentWithImg detail={contentWithImgData} />
            <FAQs faqData={faqData}  />
            <StaticTagList data={tagList} />
        </React.Fragment>
    )
}

export default FinanceGuide;