import React, {useState, useEffect} from "react";
import {Alert, Col, Container, Form, Row, Spinner} from "react-bootstrap";
import FormInput from "../../Components/FormInput";
import SectionTitle from "../../Components/SectionTitle";
import Button from "../../Components/Button";
import {Link, useNavigate} from "react-router-dom";
import _ from "lodash";
import UserValidator from "./Validator";
import { registerUser } from "../../Services/api/Login";
import Welcome from "./Welcome";
import classes from "./index.module.scss";


const Signup = () => {

    const [authObject, setAuthObject] = useState({});
    const [errors, setErrors] = useState([]);
    const [registerSuccess, setRegisterSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [termsAgreed, setTermsAgreed] = useState(false);
    const [smsAgreement, setSmsAgreement] = useState(true);
    const [user, setUser] = useState({
        firstName: '',
        lastName: '',
        email: '',
        userName: '',
        password: '',
        confirmPassword: '',
        roles: ['Customer'],
        phoneNo: '',
        dob: '',
        address: '',
        postalCode: '',
        country: '',
        gender: ''
    });
    const navigate = useNavigate();

    useEffect(() => {
        const obj = localStorage.getItem('auth');
        if(obj){
            const tempUser = _.cloneDeep(user);
            const parsed = JSON.parse(obj);
            setAuthObject(parsed);
            if(parsed?.nin && parsed?.nin !== '' && parsed?.nin?.toLowerCase() !== 'null')
                tempUser['userName'] = parsed.nin;
            if(parsed?.firstName && parsed?.firstName !== '' && parsed?.firstName?.toLowerCase() !== 'null')
                tempUser['firstName'] = parsed.firstName;
            if(parsed?.lastName && parsed?.lastName !== '' && parsed?.lastName?.toLowerCase() !== 'null')
                tempUser['lastName'] = parsed.lastName;
            if(parsed?.address && parsed?.address !== '' && parsed?.address?.toLowerCase() !== 'null')
                tempUser['address'] = parsed.address;
            if(parsed?.country && parsed?.country !== '' && parsed?.country?.toLowerCase() !== 'null')
                tempUser['country'] = parsed.country;
            if(parsed?.dob && parsed?.dob !== '' && parsed?.dob?.toLowerCase() !== 'null')
                tempUser['dob'] = parsed.dob;
            if(parsed?.email && parsed?.email !== '' && parsed?.email?.toLowerCase() !== 'null')
                tempUser['email'] = parsed.email;
            if(parsed?.gender && parsed?.gender !== '' && parsed?.gender?.toLowerCase() !== 'null')
                tempUser['gender'] = parsed?.gender;
            if(parsed?.phoneNumber && parsed?.phoneNumber !== '' && parsed?.phoneNumber?.toLowerCase() !== 'null')
                tempUser['phoneNo'] = parsed?.phoneNumber;
            if(parsed?.postalCode && parsed?.postalCode !== '' && parsed?.postalCode?.toLowerCase() !== 'null')
                tempUser['postalCode'] = parsed?.postalCode;

            setUser(tempUser)
        }
    }, []);
    const _handleRegister = () => {
        const tempUser = _.cloneDeep(user);
        const validator = new UserValidator();
        const result = validator.validate(tempUser);
        if(JSON.stringify(result) !== '{}'){
            setErrors(Object.values(result));
            return;
        }
        else{
            if(!termsAgreed){
                setErrors(['Vennligst godta vilkårene og betingelsene for å kunne registrere deg.']);
                return;
            }
            else setErrors([]);
        }
        setLoading(true);
        Object.entries(tempUser).map(([key, value]) => {
            if(tempUser[key] === '' || tempUser[key] === 'null')
            delete tempUser[key];
        });
        registerUser(tempUser)
        .then(resp => {
            localStorage.setItem('auth', JSON.stringify(resp));
            let redirect = localStorage.getItem('redirect');
            redirect = !redirect || redirect === '' || redirect === 'null' || redirect === 'undefined' ? '/privatdashbord' : redirect
            navigate(redirect);
        })
        .catch(async err => {
            try{
                const js = await err.json();
                setErrors([js.Message && js.Message !== '' ? js.Message : 'An error occurred while registering. Please try again.'])
            }
            catch(err){setErrors(['An error occurred while registering. Please try again.'])}
        })
        .finally(() => setLoading(false));
    }
    return( registerSuccess ? <Welcome message={'Din konto har blitt opprettet vellykket. Du vil motta en e-postbekreftelse snart. Vennligst bekreft e-posten din for å logge inn.'}/> :
        <React.Fragment>
            <SectionTitle textCenter description={'Enter your email and password to login'}>
                <span className={'fw-normal'}>Signup</span>
                <span className={'fw-light'}>to</span>
                <span className={'fw-semibold'}>easyfinance</span>
            </SectionTitle>
            <div className={'bg-white'}>
                <Form>
                    {errors.map((err, index) => <Alert variant='danger' key={index}>{err}</Alert>)}
                    <Row>
                        <Col lg={6}>
                            <FormInput
                                disabled
                                fieldLabel={'First Navn'}
                                type={'text'}
                                value={user.firstName}
                                onChange={e => setUser({ ...user, firstName: e.target.value })}
                            />
                        </Col>
                        <Col lg={6}>
                            <FormInput
                                disabled
                                fieldLabel={'Last Navn'}
                                type={'text'}
                                value={user.lastName}
                                onChange={e => setUser({ ...user, lastName: e.target.value })}
                            />
                        </Col>
                        <Col lg={6}>
                            <FormInput
                                fieldLabel={'E-post'}
                                type={'text'}
                                disabled={authObject.email && authObject.email !== '' && authObject.email !== 'null'}
                                value={user.email}
                                onChange={e => setUser({ ...user, email: e.target.value })}
                            />
                        </Col>
                        <Col lg={6}>
                            <FormInput
                                fieldLabel={'Mobil'}
                                type={'tel'}
                                value={user.phoneNo}
                                onChange={e => setUser({ ...user, phoneNo: e.target.value })}
                            />
                        </Col>
                        {/*<Col lg={6}>*/}
                        {/*    <FormInput*/}
                        {/*        fieldLabel={'Password'}*/}
                        {/*        type={'password'}*/}
                        {/*        value={user.password}*/}
                        {/*        onChange={e => setUser({ ...user, password: e.target.value })}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                        {/*<Col lg={6}>*/}
                        {/*    <FormInput*/}
                        {/*        fieldLabel={'Confirm Password'}*/}
                        {/*        type={'password'}*/}
                        {/*        value={user.confirmPassword}*/}
                        {/*        onChange={e => setUser({ ...user, confirmPassword: e.target.value })}*/}
                        {/*    />*/}
                        {/*</Col>*/}
                    </Row>
                    <FormInput
                        checked={smsAgreement}
                        onChange={() => setSmsAgreement(!smsAgreement)}
                        label={'Jeg godtar at EasyFinance og deres partnere kan sende meg nyhetsbrev på e-post og SMS.'}
                        type={'checkbox'}
                        id={'id1'}
                    />
                    <FormInput
                        checked={termsAgreed}
                        onChange={() => setTermsAgreed(!termsAgreed)}
                        label={'Når du ber om tilbud, samtykker du til vår personvernerklæring.'}
                        type={'checkbox'}
                        id={'id2'}
                    />
                    {<Button
                        text={loading ? <Spinner /> : 'Signup'}
                        disabled={loading}
                        type={'submit'}
                        variant={' w-100'}
                        onClick={loading ? () => {} : _handleRegister}
                    />}
                </Form>
                <div className={'py-4 text-center'}>
                    <span className={'font-12'}><i className={'fal fa-shield mx-2 text-blue'}></i> Safe and non-binding</span>
                </div>
            </div>
            <div className={'pt-0 font-14 text-center'}>
                If you have already an account ?
                <Link to={'/login'} className={'text-blue'}> Login</Link>
            </div>
        </React.Fragment>
    )
}

export default Signup;