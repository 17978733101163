import { createVippsPayment } from "../../Services/api/Payment";
import Button from "../Button";

export default function StartPayment({packageId}){
    const _payWithVipps = () => {
        createVippsPayment(packageId)
        .then(resp => {
            window.location.href = resp?.redirectUrl;
        })
        .catch(() => alert('Error occured while creating payment. Please try again.'))
    }
    const _payWithNexi = () => {
        window.location.href = `/betaling/nexi?plan=${packageId}`;
    }
    return <>
        <div className={'mb-3 text-center'}>
            <Button
                text={'Pay with'}
                variant={' w-50 m-2 vipps'}
                onClick={_payWithVipps}
                icon={'icon-vipps mx-2'}
            />
            <Button text={'Pay with Nexi'} variant={' w-50 m-2'} onClick={_payWithNexi}/>
        </div>
    </>
}