import React from 'react';
import {useLocation} from "react-router-dom";
import BusinesStaticPageBanner from "../../../Components/Banner/BusinesStaticPageBanner";
import StaticAdSectionRightImg from "../../../Components/GetStarted/StaticAdSectionRightImg";
import adSectionRightImgData from './adSectionRightImgData.js';
import contentWithImgData from "./ContentWithImgData";
import StaticContentWithImg from "../../../Components/ContentWithImage/StaticContentWithImg";
import bannerData from "./bannerData";
import tagList from "../tagList";
import StaticTagList from "../../../Components/TagList/StaticTagList";

const BusinessLoanLanding = ({detail}) => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);
    const isBusiness = decodedPathname === '/bedrift/bedriftslån';

    return(
        <React.Fragment>
            <BusinesStaticPageBanner data={bannerData} isBusiness={isBusiness} loginBtn />
            <StaticAdSectionRightImg detail={adSectionRightImgData[0]} />
            <StaticContentWithImg detail={contentWithImgData} />
            <StaticTagList data={tagList} />
        </React.Fragment>
    )
}

export default BusinessLoanLanding;