import React from 'react';
import {View, StyleSheet, Text, Link} from '@react-pdf/renderer';
import FooterLogo from "./FooterLogo";

// Define styles for the child component
const styles = StyleSheet.create({
    footer: {
        position: 'absolute',
        bottom: 0, // Adjust this value as needed
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 9,
        letterSpacing: 2,
        display: "flex",
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'row',
        padding: '10px 40px ',
        backgroundColor: '#F5F6F5',
    },
    link: {
        color: '#000',
        textDecoration: 'none',
    },
    paging: {fontSize:7}
});

// Define the child component
const PDFFooter = () => (
    <View style={styles.footer}>
        <FooterLogo />
        <Text style={styles.paging} render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
        )} fixed />
        <Text>
            <Link style={styles.link} src={"https://easyfinance.no/"} target={"_blank"}>www.easyfinance.no</Link>
        </Text>
    </View>
);

export default PDFFooter;