import MainLayout from "Layout/MainLayout";
import LoanWithSecurity from "../Pages/LoanWithSecurity";

import AuthLayout from "../Layout/AuthLayout";
import Login from "../Pages/Auth/Login";
import Signup from "../Pages/Auth/Signup";
import UserDashboard from "../Pages/UserDashboard";
import LenderDetail from "../Components/Lenders/LenderDetail";
import PostReviews from "../Components/Lenders/PostReviews";
import BankDetail from "../Components/Banks/BankDetail";
import LoanWithoutSecurity from "../Pages/LoanWithoutSecurity";
import Blogs from "../Pages/Blogs";
import PostDetails from "../Components/Blogs/PostDetails";
import AllTrendingPage from "../Pages/Blogs/AllTrending";
import AllEditorChoicePage from "../Pages/Blogs/AllEditorChoice";
import blogsCategory from "../Pages/Blogs/BlogsCategory";
import SingleCategory from "../Components/Blogs/BlogCategories/SingleCategory";
import DashboardLayout from "../Layout/DashboardLayout";
import ProfileSetting from "../Pages/UserDashboard/DashboardComponent/ProfileSetting";
import UseFulInformation from "../Pages/UseFulInformation";
import NotFound from "../Pages/NotFound";
import CreditCheck from "../Pages/UserDashboard/Pages/CreditCheck";
import Dictionary from "../Pages/UseFulInformation/dictionary";
import DictionaryDetail from "../Pages/UseFulInformation/dictionary/DictionaryDetail";
import EmailConfirm from "../Pages/Auth/EmailConfirm";
import LoginProcess from "../Pages/Auth/LoginProcess";
import DebtCheck from "../Pages/UserDashboard/Pages/DebtCheck";
import OfferStep from "../Pages/UserDashboard/DashboardComponent/RecommendedOffers/OfferStep";
import BusinessDashboard from "../Pages/UserDashboard/Pages/BusinessDashboard";
import FindCompany from "../Pages/UserDashboard/Pages/FindCompany";
import BusinessLanding from "../Pages/BusinessLanding";
import LoginPersonal from "../Pages/Auth/LoginPersonal";
import LoginBusiness from "../Pages/Auth/LoginBusiness";
import FindYourCompany from "../Pages/UserDashboard/Pages/BusinessDashboard/FindYourCompany";
import RegistorBusiness from "../Pages/Auth/RegistorBusiness";
import SavedSearches from "../Pages/UserDashboard/DashboardComponent/SavedSearches";
import NotificationList from "../Components/Notification/NotificationList";
import PaymentRemaks from "../Pages/UserDashboard/Pages/PaymentRemaks";
import PlainLayout from "../Layout/PlainLayout";
import EmailConfirmation from "../Pages/Auth/EmailConfirmation";
import LoginForPackage from "../Pages/Auth/LoginForPackage";
import SelectCompany from "../Components/PackagesPlan/SelectCompany";
import PlainLayoutWithoutHeader from "../Layout/PlainLayoutWithoutHeader";
import CreditCheckForOtherCompany from "../Pages/UserDashboard/Pages/CreditCheckForOtherCompany";
import ViewedCompanies from "../Pages/UserDashboard/Pages/ViewedCompanies";
import StartPayment from "../Components/Payment/StartPayment";
import ProcessPayment from "../Components/Payment/ProcessPayment";
import ThankYou from "../Components/Payment/ThankYou";
import NexiCheckout from "../Components/Payment/NexiCheckout";
import FailedPayment from "../Components/Payment/FailedPayment";
import LoanApplications from "../Components/LoanApplications";
import LoanForm from "../Components/LoanForm";
import MyLoanApplication from "../Pages/UserDashboard/Pages/MyLoanApplication";
import BusinessLoanLanding from "../Pages/BusinessLanding/BusinessLoan";
import CreditCheckLandingPage from "../Pages/BusinessLanding/CreditCheckLandingPage";
import GreenLoan from "../Pages/BusinessLanding/GreenLoan";
import RecommendedOffersDetail
    from "../Pages/UserDashboard/DashboardComponent/RecommendedOffers/RecommendedOffersDetail";
import QuestionnaireRecommendation from "../Pages/UserDashboard/DashboardComponent/QuestionnaireRecommendation/QuestionnaireRecommendationSteps";
import FinanceGuide from "../Pages/BusinessLanding/FinanceGuide";
import RegisterPersonal from "../Pages/Auth/RegisterPersonal";
import LoanApplicationLogin from "../Pages/Auth/LoanApplicationLogin";
import HouseValue from "../Pages/UserDashboard/Pages/HouseValue";
import HouseValueRefinance from "../Pages/UserDashboard/DashboardComponent/HouseValueRecommendations/Refinance";
import HouseValueRedecorate from "../Pages/UserDashboard/DashboardComponent/HouseValueRecommendations/Redecorate";
import HouseValueMortgage from "../Pages/UserDashboard/DashboardComponent/HouseValueRecommendations/Mortgage";
import WhoGetBusinessLoan from "../Pages/BusinessLanding/WhoGetBusinessLoan";
import NotGetBusinessLoan from "../Pages/BusinessLanding/NotGetBusinessLoan";
import DoesYourBusinessNeedBost from "../Pages/BusinessLanding/DoesYourBusinessNeedBost";
import CreditCheckAvoidLossesBadPayers from "../Pages/BusinessLanding/CreditCheckAvoidLossesBadPayers";
import CompanyPDF from "../Components/PDF/CompanyPDF";
import PaymentRemarksPDF from "../Components/PDF/Personal/PaymentRemarksPDF";
import BusinessLoansSoleProprietorships from "../Pages/BusinessLanding/BusinessLoansSoleProprietorships";
import DebtCheckPDF from "../Components/PDF/Personal/DebtCheckPDF";
import HouseValuePDF from "../Components/PDF/Personal/HouseValuePDF";
import CreditCheckPDF from "../Components/PDF/Personal/CreditCheckPDF";
import RefinanceConsumerLoan from "../Pages/RefinanceConsumerLoan";
import PlainLayoutOnlyHeader from "../Layout/PlainLayoutOnlyHeader";

const routes = [
    {
        path: "/loan-with-security",
        layout: MainLayout,
        subRoutes: [
            {
                path: "/",
                component: LoanWithSecurity,
            },
        ],
    },
    {
        path: "/loan-without-security",
        layout: MainLayout,
        subRoutes: [
            {
                path: "/",
                component: LoanWithoutSecurity,
            },
        ],
    },
    {
        path: "/",
        layout: AuthLayout,
        subRoutes: [
            {
                path: "/login",
                component: LoginPersonal,
            },
            {
                path: "/register",
                component: RegisterPersonal,
            },
            {
                path: "/bedriftspålogging",
                component: LoginBusiness,
            },
            {
                path: "/login-loan-application",
                component: LoanApplicationLogin,
            },
            {
                path: "/signup",
                component: Signup,
            },
            {
                path: '/api/identity/confirm-email',
                component: EmailConfirm
            },
            {
                path: '/logg-inn/arbeider/authentication-session',
                component: LoginProcess
            },
            {
                path: '/logg-inn/arbeider',
                component: LoginProcess,
            },
            {
                path: '/e-postbekreftelse',
                component: EmailConfirmation,
            },
            {
                path: '/login-for-package',
                component: LoginForPackage,
            },
            // TODO: PRODUCTION
            // {
            //     path: '/start-payment',
            //     component: StartPayment
            // },
            // {
            //     path: '/innbetaling/arbeider',
            //     component: ProcessPayment
            // },
            // {
            //     path: '/betaling/suksess',
            //     component: ThankYou
            // },
            // {
            //     path: '/betaling/nexi',
            //     component: NexiCheckout
            // },
            // {
            //     path: '/betaling/feil',
            //     component: FailedPayment
            // }
        ],
    },
    {
        path: "/privatdashbord",
        layout: DashboardLayout,
        subRoutes: [
            {
                path: "/",
                component: UserDashboard,
            },
        ],
    },
    {
        path: "/profile-setting",
        layout: DashboardLayout,
        component: ProfileSetting,
    },
    {
        path: "/privat/kredittsjekk",
        layout: DashboardLayout,
        isProtected: true,
        component: CreditCheck,
    },
    {
        path: "/privat/min-gjeld",
        layout: DashboardLayout,
        component: DebtCheck,
    },
    {
        path: "/privat/boligverdi",
        layout: DashboardLayout,
        component: HouseValue,
    },
    {
        path: "/house-value-refinance",
        layout: PlainLayout,
        component: HouseValueRefinance,
    },
    {
        path: "/house-value-renovation",
        layout: PlainLayout,
        component: HouseValueRedecorate,
    },
    {
        path: "/house-value-mortgage",
        layout: PlainLayout,
        component: HouseValueMortgage,
    },
    {
        path: "/recommened-offers",
        layout: PlainLayout,
        component: RecommendedOffersDetail,
    },
    {
        path: "/bedriftsdashbord",
        isProtected: true,
        layout: DashboardLayout,
        component: BusinessDashboard,
    },
    // TODO: PRODUCTION
    {
        path: "/bedriftsdashbord/business-company-credit-check",
        layout: DashboardLayout,
        component: CreditCheckForOtherCompany,
    },
    {
        path: "/bedriftsdashbord/sett-selskaper",
        layout: DashboardLayout,
        component: ViewedCompanies,
    },
    // TODO: PRODUCTION
    {
        path: "/find-your-company",
        layout: DashboardLayout,
        component: FindCompany,
    },
    {
        path: "/register-business",
        layout: AuthLayout,
        component: RegistorBusiness,
    },
    {
        path: "/saved-searches",
        layout: DashboardLayout,
        component: SavedSearches,
    },
    {
        path: "/notifications",
        layout: DashboardLayout,
        component: NotificationList,
    },
    {
        path: "/privat/betalingsanmerkninger",
        layout: DashboardLayout,
        component: PaymentRemaks,
    },
    {
        path: "/refinancing-recommendation",
        layout: PlainLayout,
        component: QuestionnaireRecommendation,
    },

    {
        path: "/",
        layout: MainLayout,
        subRoutes: [
            {
                path: "/långivere/:lenderTitle",
                component: LenderDetail,
            },
            {
                path: "/langivere/:id/post-reviews",
                component: PostReviews,
            },
        ],
    },
    {
        path: "/",
        layout: MainLayout,
        subRoutes: [
            {
                path: "/banker",
            },
            {
                path: "/banker/:bankTitle",
                component: BankDetail,
            },
            {
                path: "/banker/:id/post-reviews",
                component: PostReviews,
            },
        ],
    },
    {
        path: '/',
        layout: MainLayout,
        subRoutes: [
            {
                path: '/blogs',
                component: Blogs,
            },
            {
                path: "/blogger/:id",
                component: PostDetails,
            },


            {
                path: '/all-trending',
                component: AllTrendingPage,
            },
            {
                path: '/all-trending/:id',
                component: PostDetails,
            },


            {
                path: '/all-editor-choice',
                component: AllEditorChoicePage,
            },
            {
                path: '/all-editor-choice/:id',
                component: PostDetails,
            }
        ]
    },
    { path: "/",
        layout: MainLayout,
        subRoutes: [
            {
                path: '/useful-information/',
                component: UseFulInformation,
            },
            {
                path: '/nyttig-informasjon/ordboken',
                component: Dictionary,
            },
            {
                path: "/nyttig-informasjon/ordboken/:title",
                component: DictionaryDetail,
            },
        ]
    },
    {
        path: '/',
        layout: MainLayout,
        subRoutes: [
            {
                path: '/bloggkategorier/',
                component: blogsCategory,
            },
            {
                path: '/bloggkategorier/:categoryId', // Change the parameter name to categoryId
                component: SingleCategory,
            },
            {
                path: '/bloggkategorier/:categoryId/:id', // Use a different parameter name for post ID
                component: PostDetails,
            },
        ]
    },
    {
        path: "/loan-application",
        layout: PlainLayoutWithoutHeader,
        component: LoanApplications,
    },
    {
        path: "/bedriftsdashbord/mine-lånesøknader",
        layout: DashboardLayout,
        component: MyLoanApplication,
    },
    {
        path: "/pdf-company",
        layout: MainLayout,
        component: CompanyPDF,
    },
    {
        path: "/pdf-payment-remarks",
        layout: MainLayout,
        component: PaymentRemarksPDF,
    },
    {
        path: "/pdf-debtcheck",
        layout: MainLayout,
        component: DebtCheckPDF,
    },
    {
        path: "/pdf-housevalue",
        layout: MainLayout,
        component: HouseValuePDF,
    },
    {
        path: "/pdf-creditcheck",
        layout: MainLayout,
        component: CreditCheckPDF,
    },
    {
        path: "/company-select",
        layout: PlainLayoutWithoutHeader,
        component: SelectCompany,
    },

    // Business Side URLS
    {
        path: "/bedrift",
        layout: MainLayout,
        component: BusinessLanding,
    },
    {
        path: "/bedrift/bedriftslån",
        layout: MainLayout,
        component: BusinessLoanLanding,
    },
    {
        path: "/bedrift/kredittsjekk",
        layout: MainLayout,
        component: CreditCheckLandingPage,
    },
    {
        path: "/bedrift/finansieringsguiden",
        layout: MainLayout,
        component: FinanceGuide,
    },
    {
        path: "/bedrift/grøntlån",
        layout: MainLayout,
        component: GreenLoan,
    },
    {
        path: "/bedrift/hvem-kan-få-bedriftslån",
        layout: MainLayout,
        component: WhoGetBusinessLoan,
    },
    {
        path: "/bedrift/fikk-du-ikke-bedriftslån",
        layout: MainLayout,
        component: NotGetBusinessLoan,
    },
    {
        path: "/bedrift/trenger-din-bedrift-et-løft",
        layout: MainLayout,
        component: DoesYourBusinessNeedBost,
    },
    {
        path: "/bedrift/kredittsjekk-for-å-unngå-tap-på-dårlige-betalere",
        layout: MainLayout,
        component: CreditCheckAvoidLossesBadPayers,
    },
    {
        path: "/bedrift/bedriftslån-enkeltmannsforetak",
        layout: MainLayout,
        component: BusinessLoansSoleProprietorships,
    },
    {
        path: "/bedrift/lån-fra",
        layout: PlainLayoutWithoutHeader,
        component: LoanForm,
    },
    {
        path: "/refinansier-forbrukslån",
        layout: PlainLayoutOnlyHeader,
        component: RefinanceConsumerLoan,
    },
    { path: "*", component: NotFound, layout: MainLayout },

    // {
    //   path: "/user",
    //   layout: MainLayout,
    //   subRoutes: [
    //     {
    //       path: "/",
    //       component: User,
    //     },
    //     {
    //       path: "/profile",
    //       component: Profile,
    //     },
    //   ],
    // },
];

export default routes;
