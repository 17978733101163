import React from 'react';
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import efShortLogo from "../../Images/LogoSmallW.svg";
import welcomeText from "../../Images/welcomeText.svg";



const AuthLayout = ({children}) => {
  return(
      <React.Fragment>
          <div id={classes.authWrapper}>
              <main id={classes.main}>
                  <Container>
                      <section className={classes.section}>
                          <div className={classes.col1}>
                              <div className={classes.holder}>
                                  <div className={classes.loginLogo}>
                                      <a href={'/'}>
                                          <img src={efShortLogo} loading="lazy" alt={'title'} />
                                      </a>
                                  </div>
                                  <div className={classes.welcomeTextImg}>
                                      <img src={welcomeText} loading="lazy" alt={"Welcome"} />
                                  </div>
                              </div>
                          </div>
                          <div className={classes.col2}>
                              <div className={classes.holder}>
                                  {children}
                              </div>
                          </div>

                      </section>
                  </Container>
              </main>
          </div>
      </React.Fragment>
  )
}

export default AuthLayout;