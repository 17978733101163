import React from 'react';
import classes from './index.module.scss';
import {Container} from "react-bootstrap";
import SectionTitle from "../SectionTitle";
import eiendo from "../../Images/eiendo.svg";
import aprilaBank from "../../Images/aprilaBank.svg";
import instaBank from "../../Images/instaBank.svg";
import qred from "../../Images/qred.svg";
import vipps from "../../Images/vippsOrange.svg";
import creditSafe from "../../Images/creditSafe.svg";
import bankID from "../../Images/bankIDL.svg";

const PartnerBanks = () => {
  return(
      <React.Fragment>
          <section className={'section'} id={"partner"}>
              <Container>
                  <div className={classes.colList}>
                      <div className={classes.col}>
                          <SectionTitle>
                              <span className={'fw-light'}>Oppdag trygghet og effektivitet</span> <br />
                              <span className={'fw-semibold'}>med EasyFinance! </span>
                          </SectionTitle>
                          <p>Vi samarbeider med  Vipps, Creditsafe, BankID og Qred for å sikre deg en sømløs og sikker finansopplevelse. Hos EasyFinance er du i trygge hender. Vår søknadsprosess er designet for å være rask og brukervennlig, slik at du kan nå dine finansielle mål med minimal innsats.</p>
                          <p>Velg EasyFinance – hvor trygghet møter hastighet.</p>
                      </div>
                      <div className={classes.col}>
                          <ul className={classes.bankList}>
                              <li>
                                  <div className={classes.imgBox}>
                                      <a href={'#'}>
                                          <img src={vipps} loading="lazy" alt={'vipps'} />
                                      </a>
                                  </div>
                              </li>
                              <li>
                                  <div className={classes.imgBox}>
                                      <a href={'#'}>
                                          <img src={qred} loading="lazy" alt={'qred'} />
                                      </a>
                                  </div>
                              </li>
                              <li>
                                  <div className={classes.imgBox}>
                                      <a href={'#'}>
                                          <img src={creditSafe} loading="lazy" alt={'creditSafe'} />
                                      </a>
                                  </div>
                              </li>
                              <li>
                                  <div className={classes.imgBox}>
                                      <a href={'#'}>
                                          <img src={bankID} loading="lazy" alt={'bankID'} />
                                      </a>
                                  </div>
                              </li>
                          </ul>
                      </div>
                  </div>
              </Container>
          </section>
      </React.Fragment>
  )
}

export default PartnerBanks;