import { onError } from "Services/api/Image";
import icon1 from "../../Images/eb1.jpg";
import icon2 from "../../Images/eb2.jpg";
import icon3 from "../../Images/eb3.jpg";
import icon4 from "../../Images/eb4.jpg";
import icon5 from "../../Images/eb5.jpg";
import icon6 from "../../Images/eb6.jpg";
import icon7 from "../../Images/eb7.jpg";
import icon8 from "../../Images/eb8.jpg";




const data = [
    {
        id: '1',
        category: 'Debt',
        categoryImg: icon1,
        postDate: 'August 7, 2023 7:00 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/cfr0z3n_a_bespectacled_man_tapes_a_smartphone_screen_and_it_ext_f078ce4b-d091-4090-ac32-20f8a2bbd566.png?fit=750%2C420&strip=all',
        title : "SEC’s controversial cybersecurity disclosure warning: What enterprises need to do now",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '10',
        choice: true,
        detailData: [
            <h2>Additional Content Title</h2>,
            <p>This is some additional HTML content for the post.</p>,
            <div>
                <h1>How to hire the best software developers?</h1>
                <time className="time">Last update on: 2:00 pm</time>
                <img className="ful-width"
                    onError={onError}
                     src="https://www.softwarealliance.io/wp-content/uploads/2020/06/how-to-hire-best-software-developers-1024x536.jpg"
                     alt="How to hire the best software developers?" />

                <ol>
                    <li> <p>Outsourcing different employees like software developers, <strong>website
                        developers</strong> have become a trend in today’s digital world. The technology demand and
                        talent are increasing day by day and the pool within some areas is shrinking fast. Companies are
                        looking for someone who is available for them except their teams in the office.&nbsp;</p></li>
                    <li>Also, the defined designation and set of skills will help you to start looking at the right
                        place. You need to clarify your notifications in further communication. For example: are you
                        looking for a Laravel developer or a WordPress developer or you are looking for a front-end or
                        back-end developer?</li>
                    <li>These profiles also help you to promote your company and culture through existing employees.
                        Moreover, the interesting videos, eye-catching ads and photos, and hashtags will make a lot of
                        difference.</li>
                    <li>These profiles also help you to promote your company and culture through existing employees.
                        Moreover, the interesting videos, eye-catching ads and photos, and hashtags will make a lot of
                        difference.</li>
                </ol>

                    <p>Outsourcing different employees like software developers, <strong>website
                        developers</strong> have become a trend in today’s digital world. The technology demand and
                        talent are increasing day by day and the pool within some areas is shrinking fast. Companies are
                        looking for someone who is available for them except their teams in the office.&nbsp;</p>
                <div className="card" >
                    <img src="..." onError={onError} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">Some quick example text to build on the card title and make up the
                                bulk of the card's content.</p>
                            <a href="#" className="btn btn-primary">Go somewhere</a>
                        </div>
                </div>

                    <p>Are you an entrepreneur who has the ability to understand the extent and depth of hiring
                        challenges?&nbsp; It is a truth that <strong>software developers</strong> are very difficult to
                        evaluate on certain criteria. There are many reasons for that, like how to explore the
                        candidate’s ability to think and innovate? How to determine if they can work as a team? Or how
                        to investigate someone’s moral fiber. These are the facts you should need to care about before
                        outsourcing the best software developers.&nbsp;</p>

                <div className="alert alert-info" role="alert">
                    A simple info alert—check it out!
                </div>
                    <p>In this article, we will discuss the <strong>tips to hire the best software
                        engineers</strong> for your business.&nbsp;</p>


                    <p><strong>Always know who you need:</strong></p>
                <figure className="figure">
                    <img onError={onError} src="https://t4.ftcdn.net/jpg/02/97/96/69/360_F_297966906_NEFbQG1Le3acxi19Ji0b9k9WDOBlQkPe.jpg" className="figure-img img-fluid rounded" alt="..." />
                        <figcaption className="figure-caption text-end">A caption for the above image.</figcaption>
                </figure>

                    <p>To<strong> <a href="https://www.softwarealliance.io/hire-developer/" target="_blank"
                                     rel="noreferrer noopener">hire software engineers</a></strong>, you need to know
                        the proper requirements. It is important because the more specific you are, the better filters
                        you will have.&nbsp;</p>


                    <p>Also, the defined designation and set of skills will help you to start looking at the right
                        place. You need to clarify your notifications in further communication. For example: are you
                        looking for a Laravel developer or a WordPress developer or you are looking for a front-end or
                        back-end developer?</p>


                    <p><strong>Where to look for developers?</strong></p>


                    <p>Once the needs and requirements are defined clearly, and you have gathered all inputs from the
                        stockholders and agreed on the candidate specification, you need to set-up the search strategy
                        now. The sourcing process includes identifying people who are actively looking for a job or not
                        actively looking for a job.&nbsp;</p>


                    <p>You can use the following legit option for that purpose.&nbsp;</p>


                    <p><strong>Linkedin company profile</strong></p>


                    <p>Linkedin is one of the best recruitment and professional platforms to hire people. Create your
                        company profile and create careers pages. Keep posting engaging content and look for
                        testimonials and recommendations. You can also use free ads to advertise your
                        company.&nbsp; profile. It is a great platform to create a referral chain</p>


                    <p><strong>Social media accounts</strong></p>


                    <p>Likewise Linkedin you can create company profiles on Instagram, Twitter, and Facebook. The next
                        step is to post new jobs and strengthen the company presence by posting events, and other
                        employee achievements.</p>


                    <p>These profiles also help you to promote your company and culture through existing employees.
                        Moreover, the interesting videos, eye-catching ads and photos, and hashtags will make a lot of
                        difference.&nbsp;</p>


                    <p><strong>Stack overflow</strong></p>


                    <p>Use the job listing portal to look for the candidates having the specific skills according to
                        your requirements.&nbsp;</p>


                    <p><strong>Github</strong></p>


                    <p>Filter search results based on the relevant criteria of people’s interest.&nbsp;</p>


                    <p><strong>Reddit</strong></p>


                    <p>You can also create the Reddit personal and company profile for that purpose. Just look for the
                        relevant subreddits to post things by using keywords.&nbsp;</p>


                    <p><strong>Developer events</strong></p>


                    <p>Make connections with developers in meetup and developer’s functions to build a mutually
                        beneficial relationship.&nbsp;</p>


                    <p><strong>Interview</strong></p>


                    <p>The interview is the 2-way street, the interviewer and candidate both have to make a decision.
                        After getting the ideal candidate, you can specify many things in the interview like communicate
                        effectively, work in teams, or have a better grasp of the area of expertise and many more
                        things.&nbsp;</p>


                    <p>Explore their area of expertise, online presence, and flexibility along with their knowledge.
                        Engage the candidate in the conversation to know about their mental agility and problem-solving
                        techniques.&nbsp;</p>


                    <p><strong>Communication skills</strong></p>


                    <p>You have to choose the best software engineer, and the tech-savvy fact is just not enough. You
                        have to consider the communication and soft skills. These professionals should be able to
                        communicate properly, both verbally and in writing.&nbsp;</p>


                    <p>You can ask for their technical writing samples to know about their communication skills and
                        technical clarity.&nbsp;</p>


                    <p><strong>Conclusion</strong></p>


                    <p>Start by ensuring the product needs and how much time it takes to complete. Do your research as
                        it will help you to find the best software engineer for your project. Follow the steps we
                        mentioned above to <strong>hire software developers</strong> as it is not rocket science.&nbsp;
                    </p>


                    <p>Find the way that is best for your company and execute it properly. Success will be
                        guaranteed.&nbsp;</p>


                    <p>Also read: <a rel="noreferrer noopener"
                                     href="https://medium.com/@softwarealliance/how-blockchain-development-is-changing-web-designs-1ebd7bfbeb4b"
                                     target="_blank"><b>How </b></a><strong><a
                        href="https://medium.com/@softwarealliance/how-blockchain-development-is-changing-web-designs-1ebd7bfbeb4b"
                        target="_blank" rel="noreferrer noopener">Block-chain Development is Changing Web
                        Designs?</a></strong></p>
            </div>
        ]
    },
    {
        id: '2',
        category: 'Budget',
        categoryImg: icon2,
        postDate: 'August 7, 2023 6:30 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/Untitled-design-2023-08-07T124529.194.png?fit=750%2C469&strip=all',
        title : "Multiplayer raises $3M for AI-based distributed software development ",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '5',
        choice: true,
    },
    {
        id: '3',
        category: 'Residence',
        categoryImg: icon3,
        postDate: 'August 7, 2023 6:20 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/nuneybits_esign_a_central_abstract_symbol_that_represents_the__98f6f507-075f-42dc-9bae-b86ce6fe72ea.png?fit=750%2C600&strip=all',
        title : "Delivering on sustainable promises: Making sustainability a tangible company KPI",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '20',
        choice: true,
    },
    {
        id: '4',
        category: 'Credit check',
        categoryImg: icon4,
        postDate: 'August 7, 2023 10:53 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/multiplayer.jpg?fit=750%2C440&strip=all',
        title : "Freedom of choice? How recent Zoom AI policy changes betrayed consumer trust ",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '1',
        choice: true,
    },
    {
        id: '5',
        category: 'Refinancing',
        categoryImg: icon5,
        postDate: 'August 7, 2023 6:20 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/AdobeStock_455801976.jpeg?fit=750%2C324&strip=all',
        title : "Delivering on sustainable promises: Making sustainability a tangible company KPI",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '7',
        choice: true,
    },
    {
        id: '6',
        category: 'Price increase',
        categoryImg: icon6,
        postDate: 'August 7, 2023 6:13 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/cfr0z3n_glowing_3D_wireframe_view_of_industrial_robotic_arms_in_2e7d0d91-f1d5-45ce-b8e6-1e42a6c642b5.png?fit=750%2C420&strip=all',
        title : "Tractian gets $45M to expand AI monitoring of industrial machinery",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '30',
        choice: true,
    },
    {
        id: '7',
        category: 'Inflation',
        categoryImg: icon7,
        postDate: 'August 7, 2023 6:00 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/nuneybits_A_colorful_vector_illustration_depicts_the_WSO2_for__d44aca59-2cee-4e13-9a0a-a04d8c2f4d1e.png?fit=750%2C420&strip=all',
        title : "WSO2 launches a new program to help startups build better apps faster and cheaper ",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '22',
        choice: true,
    },
    {
        id: '8',
        category: 'Financial tips',
        categoryImg: icon8,
        postDate: 'August 6, 2023 10:10 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/annevb_the_end_of_programming._colorful_futuristic_tech_3d6db463-375e-4d84-9324-2b045c20313a.png?fit=750%2C375&strip=all',
        title : "Don’t quit your day job: Generative AI and the end of programming",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '18',
        choice: false,
    },
    {
        id: '9',
        category: 'Financial tips',
        categoryImg: icon8,
        postDate: 'August 6, 2023 10:10 AM',
        postImg : 'https://venturebeat.com/wp-content/uploads/2023/08/annevb_the_end_of_programming._colorful_futuristic_tech_3d6db463-375e-4d84-9324-2b045c20313a.png?fit=750%2C375&strip=all',
        title : "Don’t quit your day job: Generative AI and the end of programming",
        description: 'Most of us want to have a secure economy and know how to manage our money. That to…',
        icon : icon1,
        views: '18',
        choice: false,
    },


];

export default data;