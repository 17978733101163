import unofinans from "../../Images/unofinans.svg";
import finansportalen from "../../Images/logoFinansportalen.svg";
import okidaLogo from "../../Images/okidaLogo.svg";
import ZenFinans from "../../Images/ZenFinans.svg";
import TienesteTorget from "../../Images/TienesteTorget.svg";
import langivere from "../../Images/langivere.svg";

const data = [
    {
        dataType: 'Annonse',
        image: unofinans,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        featured: true,
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Not cooperate',
        image: okidaLogo,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                loanType: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: ZenFinans,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: TienesteTorget,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: langivere,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Annonse',
        image: unofinans,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Not cooperate',
        image: okidaLogo,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
                Lånegrense: '500000 - 800000',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: ZenFinans,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: TienesteTorget,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: langivere,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Annonse',
        image: unofinans,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Not cooperate',
        image: okidaLogo,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: ZenFinans,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: TienesteTorget,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },
    {
        dataType: 'Cooperation',
        image: langivere,
        leverandorTekst: 'Utnytt den ledige verdien i boligen din til å samle dyr gjeld. Bli kvitt betalingsproblemer og få en ny start!',
        rating: '4.5',
        loanAmount:'100 000 kr - 10 000 000 kr',
        pricePerMonths: 'NOK 16,607',
        age: '18 år',
        estTotalCost: 'NOK 4,982,081',
        effectiveInterestRate: '4.55%',
        maxiRepaymentPeriod: '30 years',
        nominalInterestRate: '4.46%',
        childData: [
            {
                produktpakke_tekst: 'Mortgage within 60%',
                Lånetype: 'Standard mortgage',
                membershipRequire: 'No',
                otherProducts: 'No',
                maxLoanValueRatio: '60%',
                maxiRepaymentPeriod: '30 years',
                maxiInterestFreeYears: '0 years',
                ageRestricted: '18 - 100 years',
                nominalInterestRate: '4.46%',
                effectiveInterestRate: '4.55%',
                interestType: 'Floating interest rate',
                pricePerMonths: 'NOK 16,607',
                establishmentFree: 'NOK 0',
                custodyFee: 'NOK 0',
                poweredBy: finansportalen,
                poweredByTitle: 'finansportalen',
                paymentNote: 'Godtar',
                EffInterestFrom: '5,16 % ',
                requireSecurity: 'Ja',
                age: '18 år',
                estTotalCost: 'NOK 4,982,081',
                loanLimit: '500000 - 800000',
                debtInsurance: 'Yes',
                marketArea : 'Local',
                termFee: '50kr',
                disclaimer: 'Boliglånet Boliglån fra Landkreditt Bank er et vanlig boliglån med flytende rente.',
            },
        ]
    },

]

export default data;