import React  from 'react';
import classes from "./index.module.scss";
import {Container} from "react-bootstrap";
import Logo from "../Logo";
import { loadSettings } from "Services/api/SettingsService";
import { useSelector } from "react-redux";
import {useLocation} from "react-router-dom";
import Navigation from "../Navigation";
import SubHeader from "./SubHeader";



const Header = ({hideTopHeader, hideNavigation}) => {
    const location = useLocation();
    const decodedPathname = decodeURIComponent(location.pathname);


    /*const [headerClass, setHeaderClass] = useState('');
    const [scrollHeight, setScrollHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > scrollHeight) {
                setHeaderClass(`${classes.headerFixed}`);
            } else {
                setHeaderClass('');
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollHeight]);*/

  // const [settings, setSettings] = useState();
  const { settings } = useSelector(state => state.components);
  const languageId = 1;
  // useEffect(() => {
  //   if(components.length > 0)
  //   loadSettings(languageId, components)
  //   .then(setSettings);
  // }, [components])

    const subHeadersData = [
        { url: '/', text: 'Privat', icon: 'fas fa-user' },
        { url: '/bedrift/', text: 'Bedrift', icon: 'fas fa-briefcase' },
        // Add more instances as needed
    ];


    const isBusinessPage = [
        '/bedrift/',
        '/bedrift/bedriftslån',
        '/bedrift/grøntlån',
        '/bedrift/finansieringsguiden',
        '/bedrift/who-can-get-a-business-loan',
        '/fbedrift/ikk-du-ikke-bedriftslån',
        '/bedrift/kredittsjekk',
        '/bedrift/trenger-din-bedrift-et-løft',
        '/bedrift/hvem-kan-få-bedriftslån',
        '/bedrift/kredittsjekk-for-å-unngå-tap-på-dårlige-betalere',
    ].some(path => decodedPathname.includes(path));



    return (
    <header id={classes.header} className={`${isBusinessPage && classes.businessHeader}`}>
        {/* TODO: PRODUCTION / Change revert bt rizwan */}
        {hideTopHeader ? '' : <SubHeader isBusinessPage={isBusinessPage} data={subHeadersData} />}
      <Container>
          {/*<Logo logoUrl={location.pathname.includes('business') ? '/bedrift' : settings?.site?.siteLogo} isBusinessPage={isBusinessPage} />*/}
          <Logo isBusinessPage={isBusinessPage} />
          <Navigation isBusinessPage={isBusinessPage} hideNavigation={hideNavigation} />
      </Container>
    </header>
  );
};

export default Header;
